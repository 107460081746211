import React, { useState } from 'react'
import {
  FormHelperText,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import PersonOutlineIcon from '@material-ui/icons/PersonOutlined'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import postalCodes from 'postal-codes-js'

import { CountryDropdown, InputTextFieldNew, Progress, RoundedButton } from '../common'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled } from '../utils'
import { EDIT_USER_PROFILE } from './mutations'
import { GET_USER_DATA } from '../Home/queries'

export default function CreateProfile({ acceptedInvite, user }) {
  const [formState, { text }] = useFormState({ country: '', postalCode: '', state: '', city: '' })
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const formClasses = useFormStyles()
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const { loading, execute: handleSubmit } = useMutation(EDIT_USER_PROFILE, {
    onCompleted: ({ editUser }) =>
      editUser.id &&
      enqueueSnackbar('Individual profile Information stored. Your progress has been saved.', {
        variant: 'success',
        autoHideDuration: 4500
      }),
    refetchQueries: [{ query: GET_USER_DATA, variables: { id: user.id } }]
  })

  const inputs = {
    country: {
      autoFocus: true,
      InputComponent: CountryDropdown,
      inputType: text,
      label: 'Country of residence',
      menuPlacement: 'bottom',
      name: 'country',
      setField: formState.setField,
      setFieldError: formState.setFieldError
    },
    postalCode: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12, sm: 6 },
      name: 'postalCode',
      label: 'ZIP / Postal code',
      inputType: text,
      onChange: e => postalCodeOnChange(e.target.value, formState),
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    state: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12, sm: 6 },
      name: 'state',
      label: 'State / Province',
      inputType: text,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    city: {
      InputComponent: InputTextFieldNew,
      name: 'city',
      label: 'City',
      inputType: text,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    }
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  const callHandleSubmit = e => {
    e.preventDefault()

    handleSubmit({
      id: user.id,
      values: { signUpStep: acceptedInvite ? 5 : 3, shippingAddress: { ...formState.values } }
    })
  }

  return (
    <form onSubmit={callHandleSubmit}>
      <div className={classes.header}>
        <Typography className={formClasses.heading} role="heading" variant="h3">
          <span className={classes.userIconWrapper}>
            <PersonOutlineIcon className={classes.userIcon} fontSize="large" />
          </span>{' '}
          Your Individual Profile
        </Typography>
        <Typography>Enter the location where you reside.</Typography>
        <Tooltip
          open={tooltipOpen}
          placement="top"
          title="This allows us to notify you of TIP events and activities specific to your region."
        >
          <Typography
            className={classes.tooltipToggle}
            component="span"
            onClick={() => isMobile && setTooltipOpen(!tooltipOpen)}
            onMouseEnter={() => !isMobile && setTooltipOpen(true)}
            onMouseLeave={() => !isMobile && setTooltipOpen(false)}
          >
            Why do we ask for this?
            {tooltipOpen ? (
              <InfoIcon className={classes.tooltipIcon} fontSize="small" />
            ) : (
              <InfoOutlinedIcon
                className={clsx(classes.tooltipIcon, classes.tooltipClosed)}
                fontSize="small"
              />
            )}
          </Typography>
        </Tooltip>
      </div>
      <Grid className={formClasses.spacingBottom} container spacing={2}>
        {Object.entries(inputs).map(
          ([name, { gridWidth = { xs: 12 }, InputComponent, ...args }]) => (
            <Grid key={name} item {...gridWidth}>
              <InputComponent
                {...args}
                disabled={name !== 'country' && !formState.values['country'] ? true : false}
                error={typeof formState.validity[name] !== 'undefined' && !formState.validity[name]}
                errorHelperText={formState.errors[name]}
                value={formState.values[name]}
              />
            </Grid>
          )
        )}
      </Grid>
      <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
        <RoundedButton
          fullWidth
          size="medium"
          disabled={disableSubmit}
          data-testid="CreateAccountBtn"
          type="submit"
        >
          {loading ? (
            <div className={formClasses.progress}>
              <Progress size={20} />
            </div>
          ) : acceptedInvite ? (
            'Next: Summary'
          ) : (
            'Next: Organization Profile'
          )}
        </RoundedButton>
        <FormHelperText className={classes.helperText}>Your progress will be saved</FormHelperText>
      </div>
    </form>
  )
}

export const countryOnChange = (value, formState) => {
  formState.setField('country', value)

  if (!value) {
    formState.setFieldError('country', 'Country is required')
  }

  if (formState.values.postalCode) {
    formState.setField('postalCode', '')
    formState.setFieldError('postalCode', 'ZIP / Postal Code is required')
  }

  if (formState.values.state) {
    formState.setField('state', '')
    formState.setFieldError('state', 'State / Province is required')
  }

  if (formState.values.city) {
    formState.setField('city', '')
    formState.setFieldError('city', 'City is required')
  }
}

export const postalCodeOnChange = (value, formState) => {
  const supportedCountries = [
    'AD',
    'AR',
    'AS',
    'AT',
    'AU',
    'BD',
    'BE',
    'BG',
    'BR',
    'CA',
    'CH',
    'CZ',
    'DE',
    'DK',
    'DO',
    'ES',
    'FI',
    'FO',
    'FR',
    'GB',
    'GF',
    'GG',
    'GL',
    'GP',
    'GT',
    'GU',
    'GY',
    'HR',
    'HU',
    'IM',
    'IN',
    'IS',
    'IT',
    'JE',
    'JP',
    'LI',
    'LK',
    'LT',
    'LU',
    'MC',
    'MD',
    'MH',
    'MK',
    'MP',
    'MQ',
    'MX',
    'MY',
    'NL',
    'NO',
    'NZ',
    'PH',
    'PK',
    'PL',
    'PM',
    'PR',
    'PT',
    'RE',
    'RU',
    'SE',
    'SI',
    'SJ',
    'SK',
    'SM',
    'TH',
    'TR',
    'US',
    'VA',
    'VI',
    'YT',
    'ZA'
  ]

  formState.setField('postalCode', value)

  const valid = postalCodes.validate(formState.values.country, value)

  if (supportedCountries.includes(formState.values.country) && valid === true) {
    fetch(`https://zip.getziptastic.com/v2/${formState.values.country}/${value}`)
      .then(response => response.json())
      .then(data => {
        const { city, state_short } = data

        formState.setField('state', state_short)
        formState.setField('city', city)
      })
  } else if (typeof valid === 'string') {
    formState.setFieldError('postalCode', valid)
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  buttonContainer: {
    flexDirection: 'column'
  },
  helperText: {
    marginLeft: 10
  },
  stepCountText: {
    color: theme.palette.gray9.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  tooltipIcon: {
    marginLeft: 3,
    verticalAlign: 'top'
  },
  tooltipOpen: {
    color: theme.palette.linkHover.main
  },
  tooltipToggle: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: 14,
    marginBottom: 20
  },
  userIcon: {
    fontSize: '1.625rem',
    verticalAlign: 'top'
  },
  userIconWrapper: {
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    height: 36,
    padding: 4,
    verticalAlign: 'bottom',
    width: 36
  }
}))
