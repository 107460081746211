import gql from 'graphql-tag'

export const GET_ORGANIZATION_DATA = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      approvalStatus
      logo
      website
      name
      emailAddress
      phoneNumber
      type
      serviceOfferings
      operatingRegions
      affiliateOf
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      organizationRoles {
        authorizedSigners {
          user {
            id
            firstname
            lastname
            email
          }
        }
      }
    }
  }
`

export const GET_ORGANIZATION_DATA_NAV = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      approvalStatus
      logo
      website
      name
      emailAddress
      phoneNumber
      type
      serviceOfferings
      operatingRegions
      affiliateOf
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      membership {
        membershipLevel(id: $id) {
          id
          canAccessRFI
        }
      }
    }
  }
`

export const GET_NAV_ITEMS = gql`
  query {
    nav {
      name
      label
      to
      mobileOnly
      hidden
      subItems {
        name
        label
        to
        mobileOnly
        megaMenu
        hidden
        mobileOpen
        subItems {
          name
          label
          to
          mobileOnly
          hidden
          mobileOpen
          subItems {
            name
            label
            to
            mobileOnly
            hidden
            mobileOpen
          }
        }
      }
    }
  }
`
