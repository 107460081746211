import React, { useEffect, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { useFormState } from 'react-use-form-state'
import equal from 'fast-deep-equal'
import clsx from 'clsx'

import orgFormFields from './FormFields'
import { ErrorSection, Progress, RoundedButton } from '../../common'
import { useSession } from '../../auth/queries'
import { EDIT_ORG } from './mutations'
import { isFormSubmitDisabled } from '../../utils'
import { useMutation } from '../../hooks'
import { tabStyles } from '../../User/Profile/UserInfo'
import { Divider } from '@material-ui/core'

function CompanyProfile({
  activeTab,
  organization = {},
  isReadOnly = false,
  onCompleted,
  onUpdate,
  setHasUnsavedChanges,
  tabLoopStart,
  tabLoopEnd,
  tabLoopSubmit
}) {
  const classes = tabStyles()
  const { isSystemAdmin, isReadOnlySystemAdmin } = useSession()

  const organizationProfile = {
    name: organization.name || '',
    // affiliateName: organization.affiliateName || '',
    description: organization.description || '',
    type: organization.type || [],
    serviceOfferings: organization.serviceOfferings || [],
    operatingRegions: organization.operatingRegions || [],
    address1: organization.shippingAddress.address1 || '',
    address2: organization.shippingAddress.address2 || '',
    country: organization.shippingAddress.country || '',
    postalCode: organization.shippingAddress.postalCode || '',
    state: organization.shippingAddress.state || '',
    city: organization.shippingAddress.city || '',
    phoneNumber: organization.phoneNumber || '',
    website: organization.website || '',
    emailAddress: organization.emailAddress || '',
    showOnPublicSite: !!organization.showOnPublicSite,
    hideFromOrganizationSelect: !!organization.hideFromOrganizationSelect
  }

  const [formState, inputTypes] = useFormState(organizationProfile)

  const { loading, execute: handleSubmit, error } = useMutation(EDIT_ORG, {
    onCompleted: ({ editOrganization }) => onCompleted(editOrganization),
    update: onUpdate
  })

  const hasUnsavedChanges = !equal(formState.values, organizationProfile)
  const disableSubmit = isFormSubmitDisabled(orgFormFields(), formState, true)

  let errorMessage

  useEffect(() => {
    if (!activeTab && hasUnsavedChanges) {
      Object.entries(organizationProfile).forEach(o => formState.setField(o[0], o[1]))
    }
  }, [activeTab, formState, hasUnsavedChanges, organizationProfile])

  useEffect(() => {
    if (activeTab) {
      setHasUnsavedChanges(hasUnsavedChanges)
    }
  }, [hasUnsavedChanges, setHasUnsavedChanges, activeTab])

  if (error) {
    errorMessage = 'Something went wrong.'
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()

        handleSubmit({
          id: organization.id,
          values: {
            name: formState.values.name,
            affiliateName: formState.values.affiliateName,
            description: formState.values.description,
            type: formState.values.type,
            serviceOfferings: formState.values.serviceOfferings,
            operatingRegions: formState.values.operatingRegions,
            shippingAddress: {
              address1: formState.values.address1,
              address2: formState.values.address2,
              country: formState.values.country,
              postalCode: formState.values.postalCode,
              state: formState.values.state,
              city: formState.values.city
            },
            phoneNumber: formState.values.phoneNumber,
            website: formState.values.website,
            showOnPublicSite: formState.values.showOnPublicSite,
            hideFromOrganizationSelect: formState.values.hideFromOrganizationSelect
          }
        })
      }}
    >
      <Grid container spacing={4} className={clsx({ [classes.readOnly]: isReadOnly })}>
        {Object.entries(
          orgFormFields(
            formState,
            tabLoopStart,
            tabLoopEnd,
            isSystemAdmin,
            isReadOnlySystemAdmin,
            organization.membership
          )
        ).map(
          ([name, { gridWidth = { xs: 12 }, hidden, InputComponent, inputType, ...args }]) =>
            !hidden && (
              <Fragment key={`${name}-divider`}>
                <Grid item {...gridWidth}>
                  <InputComponent
                    {...args}
                    error={
                      typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                    }
                    errorHelperText={formState.errors[name]}
                    inputType={inputTypes[inputType]}
                    margin="none"
                    value={formState.values[name]}
                  />
                </Grid>
                {(name === 'description' || name === 'operatingRegions') && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </Fragment>
            )
        )}
        {!isReadOnly && (
          <Grid container justify="center">
            <Grid item xs={12} sm={5} className={classes.buttonContainer}>
              <RoundedButton
                className={classes.roundedButton}
                color="primary"
                disabled={!hasUnsavedChanges || loading || disableSubmit}
                ref={tabLoopSubmit}
                type="submit"
              >
                {loading ? <Progress size={25} delay={0} /> : 'Update Info'}
              </RoundedButton>
              {errorMessage && <ErrorSection>{errorMessage}</ErrorSection>}
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  )
}

export default CompanyProfile
