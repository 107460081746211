import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import LinkField from '../common/LinkField'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%', // this value may need to change if menu content modified
    position: 'absolute',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.navDarkGray.main
  },
  ul: {
    listStyle: 'none',
    padding: 0
  },
  item: {
    minWidth: 230 // this value may need to change if menu content modified
  },
  parentItem: {
    fontWeight: '600',
    width: '33.33%'
  },
  childItem: {
    fontWeight: '400'
  },
  link: {
    paddingLeft: 15,
    color: 'white',
    display: 'block',
    '&:hover': {
      background: theme.palette.navHoverGray.main
    }
  }
}))

const MegaMenu = ({ items, megaMenuLinkPadding }) => {
  const classes = useStyles()
  return (
    <ul className={clsx(classes.root, classes.ul)}>
      {items
        .filter(item => !item.hidden)
        .map((parentItem, index) => (
          <li key={index} className={clsx(classes.item, classes.parentItem)}>
            {parentItem.to ? (
              <LinkField
                aria-haspopup="true"
                hideIcon={true}
                hideBorder={true}
                label={parentItem.label}
                to={parentItem.to}
                className={classes.link}
                style={{
                  paddingTop: megaMenuLinkPadding,
                  paddingBottom: megaMenuLinkPadding
                }}
              />
            ) : (
              parentItem.label
            )}
            <ul className={classes.ul}>
              {parentItem.subItems
                .filter(item => !item.hidden)
                .map((childItem, index) => (
                  <li key={index} className={clsx(classes.item, classes.childItem)}>
                    <LinkField
                      aria-haspopup="true"
                      hideIcon={true}
                      hideBorder={true}
                      label={childItem.label}
                      to={childItem.to}
                      className={classes.link}
                      style={{
                        paddingTop: megaMenuLinkPadding,
                        paddingBottom: megaMenuLinkPadding
                      }}
                    />
                  </li>
                ))}
            </ul>
          </li>
        ))}
    </ul>
  )
}

export default MegaMenu
