export default {
  API_ROOT: process.env.REACT_APP_API_ROOT,
  VERSION: process.env.REACT_APP_VERSION,
  CLIENT_ENV: process.env.REACT_APP_CLIENT_ENV || 'demo',
  MEDIA_CDN_DOMAIN: process.env.REACT_APP_MEDIA_CDN_DOMAIN,
  WORKPLACE_URL: process.env.REACT_APP_WORKPLACE_URL,
  WORKPLACE_SSO_URL: process.env.REACT_APP_WORKPLACE_SSO_URL,
  WIKI_URL: process.env.REACT_APP_WIKI_URL,
  EXCHANGE_URL: process.env.REACT_APP_EXCHANGE_URL,
  LOCK_DATE: process.env.REACT_APP_LOCK_DATE,
  PLACES_API_KEY: process.env.REACT_APP_GOOGLE_PLACES,
  RECAPTCHA_PUBKEY: process.env.REACT_APP_RECAPTCHA_PUBKEY,
  GITHUB_URL: process.env.REACT_APP_GITHUB_URL,
  ATLASSIAN_URL: process.env.REACT_APP_ATLASSIAN_URL,
  STRIPE_API_PUBKEY: process.env.REACT_APP_STRIPE_PUBKEY,
  DOCUSIGN_URL: process.env.REACT_APP_DOCUSIGN_URL,
  INCLUDE_BLOCKED_DOMAINS: process.env.REACT_APP_CLIENT_INCLUDE_BLOCKED_DOMAINS || 'FALSE'
}
