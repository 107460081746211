import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import { ErrorSection, RoundedButton, Progress } from '../../common'
import { CANCEL_PROJECT_GROUP_AGREEMENT } from './mutations'
import { GET_ORGANIZATION_PROJECT_GROUPS } from '../../ProjectGroups'
import { GET_PROJECT_GROUP_AGREEMENTS } from '../../Organization/Agreements/ProjectGroupAgreements/queries'
import { useMutation } from '../../hooks'

export default function CancelAgreementConfirmationModal({
  organizationId,
  projectGroupId,
  envelopeId,
  onSuccess,
  children
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)

  const { execute: cancelAgreement, loading } = useMutation(CANCEL_PROJECT_GROUP_AGREEMENT, {
    awaitRefetchQueries: true,
    onCompleted: data => {
      if (data.revokeProjectGroupAgreementSignature.result) {
        if (onSuccess) {
          onSuccess()
        }
      } else {
        // an error occured
        setError('Unable to cancel agreement')
      }
    },
    refetchQueries: [
      {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: organizationId }
      },
      {
        query: GET_PROJECT_GROUP_AGREEMENTS,
        variables: { id: organizationId }
      }
    ]
  })

  function handleClickOpen() {
    setError(null)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleConfirm() {
    cancelAgreement({
      values: {
        organizationId,
        projectGroupId,
        envelopeId
      }
    })
  }

  return (
    <>
      {children(handleClickOpen)}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.header} id="responsive-dialog-title">
          Cancel Signature Request
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentText}>
            <WarningIcon className={classes.warningIcon} />
            <div>
              <Typography className={classes.bold}>
                Are you sure you would like to cancel this signature&nbsp;request?
              </Typography>
              <Typography className={classes.message}>
                You will be able to send a new request later, if you wish.
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {error && <ErrorSection includeSupport>{error}</ErrorSection>}
          <RoundedButton
            variant="outlined"
            onClick={handleConfirm}
            fullWidth={false}
            className={classes.button}
            classes={{ outlined: classes.outlined }}
            disabled={loading}
          >
            {loading ? (
              <Progress classes={{ colorPrimary: classes.progress }} size={25} delay={0} />
            ) : (
              'Yes'
            )}
          </RoundedButton>
          <RoundedButton
            fullWidth={false}
            variant="outlined"
            onClick={handleClose}
            className={classes.button}
            classes={{ root: classes.root }}
          >
            No
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  bold: {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 5
  },
  button: {
    border: `thin solid ${theme.palette.red.main}`,
    '&:hover': {
      border: `thin solid ${theme.palette.red.main}`
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  header: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  message: {
    fontSize: 18
  },
  outlined: {
    color: theme.palette.red.main,
    border: `thin solid ${theme.palette.red.main}`,
    marginRight: 10,
    marginLeft: 10
  },
  progress: {
    color: theme.palette.red.main
  },
  root: {
    color: 'white',
    borderColor: theme.palette.red.main,
    backgroundColor: theme.palette.red.main
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  }
}))
