import config from '../config'
import { history } from '../store'
import ApolloClient from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'

const graphqlRoot = config.API_ROOT

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors'
  },
  link: ApolloLink.from([
    onError(({ graphQLErrors }) => {
      const authenticationError =
        graphQLErrors && graphQLErrors.find(e => e.extensions.code === 'UNAUTHENTICATED')
      if (authenticationError) {
        console.log('authenticationError thrown')
        clearStore()
        history.push('/logout?state=session_expired')
      }
      // TODO: Normalize 403 errors into a convenient Unauthorized warning (No redirect)
    }),
    createUploadLink({ uri: graphqlRoot, credentials: 'include' })
  ])
})

export function resetStore() {
  return apolloClient.resetStore()
}

export function clearStore() {
  return apolloClient.clearStore()
}
