import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

export default function AnchorMenu({
  formState,
  handleRemoveOrgAdmin,
  isSystemAdmin,
  isReadOnlySystemAdmin,
  setBillingExemptId,
  setOpenBillingExempt,
  setBillingExemptCurrent,
  setDeactivateData,
  setDeactivateModalOpen,
  rowData,
  software
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showRemoveOrg = rowData.users.length === 1 && isSystemAdmin
  const showBillingExempt = !(
    software || ['deactivated', 'declined'].includes(rowData.approvalStatus.toLowerCase())
  )

  if (isReadOnlySystemAdmin) {
    return null
  }

  if (!showRemoveOrg && !showBillingExempt) {
    return null
  }

  return (
    <>
      <IconButton aria-label="admin options" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {showRemoveOrg && (
          <MenuItem
            onClick={() => {
              setDeactivateData({
                orgId: rowData.id,
                userId: rowData.users[0].id
              })
              setDeactivateModalOpen(true)
              handleClose()
            }}
          >
            <Typography>Remove Organization</Typography>
          </MenuItem>
        )}
        {showBillingExempt && (
          <MenuItem
            onClick={() => {
              setBillingExemptId(rowData.id)

              if (rowData.billingExemptReason) {
                if (
                  rowData.billingExemptReason !== 'tipFoundation' &&
                  rowData.billingExemptReason !== 'boardOfDirectors' &&
                  rowData.billingExemptReason !== 'vendor' &&
                  rowData.billingExemptReason !== 'subsidiary'
                ) {
                  setBillingExemptCurrent('other')
                } else {
                  setBillingExemptCurrent(rowData.billingExemptReason)
                }
              } else {
                setBillingExemptCurrent('none')
              }

              if (!rowData.billingExempt) {
                formState.setField('exemptReason', 'none')
              } else {
                // if not one of the default reasons, then 'other' option was selected
                if (
                  rowData.billingExemptReason !== 'tipFoundation' &&
                  rowData.billingExemptReason !== 'boardOfDirectors' &&
                  rowData.billingExemptReason !== 'vendor' &&
                  rowData.billingExemptReason !== 'subsidiary'
                ) {
                  formState.setField('exemptReason', 'other')
                  formState.setField('otherText', rowData.billingExemptReason)
                } else {
                  formState.setField('exemptReason', rowData.billingExemptReason)
                }
              }

              setOpenBillingExempt(true)
              handleClose()
            }}
          >
            <Typography>Billing Exempt</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
