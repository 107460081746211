import React, { useState } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import ProjectGroupChangeEmailModal from '../ProjectGroupChangeEmailModal'
import ChairInput from './ChairInput'
import { ADD_PROJECT_GROUP_CHAIR, REMOVE_PROJECT_GROUP_CHAIR } from './mutations'
import { useMutation } from '../../hooks'
import { GET_ALL_PROJECT_GROUPS } from '../queries'

const ProjectGroupChairs = ({ projectGroup, isReadOnly = false }) => {
  const classes = useStyles()
  const [adding, setAdding] = useState(false)
  const [pgEmailModalOpen, setPgEmailModalOpen] = useState(false)
  const [chairValues, setChairValues] = useState({})

  const chairs = projectGroup.chairs
    ? Object.keys(projectGroup.chairs).map(key => projectGroup.chairs[key])
    : []

  const { execute: handleRemoveChair } = useMutation(REMOVE_PROJECT_GROUP_CHAIR, {
    update: (cache, data) => removeProjectChairFromCache(cache, data, projectGroup.id)
  })
  const { execute: handleAddChair } = useMutation(ADD_PROJECT_GROUP_CHAIR, {
    update: (cache, data) => addProjectChairToCache(cache, data, projectGroup.id)
  })

  function handleSave(obj) {
    setPgEmailModalOpen(true)
    setChairValues(obj)
  }

  function submitChairNotifications(values) {
    const obj = {
      ...chairValues,
      orgAdmins: values.orgAdmins,
      primaryContacts: values.primaryContacts,
      authorizedRepresentatives: values.authorizedRepresentatives
    }

    setAdding(false)
    handleAddChair({ id: projectGroup.id, values: obj })
    setChairValues({})
    setPgEmailModalOpen(false)
  }

  function handleDelete(obj) {
    let data
    data = { email: obj.email }
    handleRemoveChair({ id: projectGroup.id, values: data })
  }

  return (
    <form>
      <Grid container className={clsx({ [classes.readOnly]: isReadOnly })}>
        <Grid container spacing={1} className={classes.header}>
          <Grid item xs={12}>
            <Typography className={classes.tabSubTitle} paragraph>
              Designate the project group chairs.
            </Typography>
          </Grid>
        </Grid>
        {chairs.map((chair, index) => {
          return (
            <Grid key={index} item xs={12} lg={8} className={classes.chair}>
              <div className={classes.details}>
                <Typography className={classes.chairName}>
                  {chair.firstname} {chair.lastname}
                </Typography>
                <Typography>{chair.email}</Typography>
                <Typography>{chair.company}</Typography>
              </div>
              <div className={classes.deleteIcon}>
                <Tooltip title="Delete Representative">
                  <IconButton
                    style={{ marginTop: 16 }} // MUI textfelds have margin top 16
                    onClick={() => handleDelete(chair)}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          )
        })}
        {chairs.length > 0 && !adding && (
          <Grid item xs={12}>
            <Button style={{ marginTop: 20 }} color="primary" onClick={() => setAdding(true)}>
              <Icon>add</Icon>Add More
            </Button>
          </Grid>
        )}
        {chairs.length < 5 && (chairs.length === 0 || adding) && (
          <Grid item xs={12} lg={12} className={classes.addForm}>
            <ChairInput
              gridWidth={{ xs: 12, sm: 12 }}
              onSave={handleSave}
              showCancel={chairs.length > 0}
              onCancel={obj => setAdding(false)}
              isReadOnly={isReadOnly}
            />
          </Grid>
        )}
        <ProjectGroupChangeEmailModal
          open={pgEmailModalOpen}
          setOpen={setPgEmailModalOpen}
          pgId={projectGroup.id}
          submitChairNotifications={submitChairNotifications}
          chairsModal
        />
      </Grid>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  columnTitle: {
    fontWeight: 500
  },
  chairName: {
    fontWeight: 500
  },
  addForm: {
    border: `1px solid ${theme.palette.borderGray.main}`,
    margin: '20px 0px',
    padding: 20,
    borderRadius: 5
  },
  chair: {
    display: 'flex',
    color: theme.palette.gray.main,
    padding: 20,
    margin: '15px 0px',
    borderRadius: 5,
    backgroundColor: theme.palette.gray5.main
  },
  deleteIcon: {
    flex: '1 0 auto',
    textAlign: 'right'
  },
  readOnly: {
    cursor: 'not-allowed !important',
    '& *': {
      pointerEvents: 'none'
    }
  }
}))

export default ProjectGroupChairs

// update call after remove project group chair mutation is complete to update cache
const removeProjectChairFromCache = (
  cache,
  { data: { removeProjectGroupChair: data } = {} },
  projectGroupId
) => {
  const { projectGroups } = cache.readQuery({
    query: GET_ALL_PROJECT_GROUPS
  })

  let newPgs = [...projectGroups]
  let updatedIndex = projectGroups.findIndex(u => u.id === projectGroupId)

  let newChairs = [...newPgs[updatedIndex].chairs]
  const removeIndex = newChairs.findIndex(u => u.email === data.email)
  newChairs.splice(removeIndex, 1)

  newPgs[updatedIndex] = {
    ...newPgs[updatedIndex],
    chairs: newChairs
  }

  cache.writeQuery({
    query: GET_ALL_PROJECT_GROUPS,
    data: { projectGroups: newPgs }
  })
}

// update call after add project group chair mutation is complete to update cache
const addProjectChairToCache = (
  cache,
  { data: { addProjectGroupChair: data } = {} },
  projectGroupId
) => {
  const { projectGroups } = cache.readQuery({
    query: GET_ALL_PROJECT_GROUPS
  })

  let newPgs = [...projectGroups]
  let updatedIndex = projectGroups.findIndex(u => u.id === projectGroupId)

  let newChairs = [...newPgs[updatedIndex].chairs, data]
  newPgs[updatedIndex] = {
    ...newPgs[updatedIndex],
    chairs: newChairs
  }

  cache.writeQuery({
    query: GET_ALL_PROJECT_GROUPS,
    data: { projectGroups: newPgs }
  })
}
