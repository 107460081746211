import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SwipeableViews from 'react-swipeable-views'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import RouteGuard from '../RouteGuard/RouteGuard'
import ProjectGroupItem from './ProjectGroupItem'
import ProjectGroupChairs from './Admin/ProjectGroupChairs'
import { history } from '../store'
import { tabStyles } from '../User/Profile/UserInfo'
import PreventTransitionPrompt from '../RouteGuard/PreventTransitionPrompt'
import { useSession } from '../auth/queries'

const styles = makeStyles({
  tabContainer: { padding: 8 * 3 }
})

function TabContainer({ children, dir }) {
  const classes = styles()
  return <div className={classes.tabContainer}>{children}</div>
}

function getDefinedObject(obj) {
  return Object.entries(obj).reduce((acc, [name, val]) => {
    if (val && name !== '__typename') {
      if (typeof val === 'object' && val instanceof Object) {
        acc[name] = getDefinedObject(val)
      } else {
        acc[name] = val
      }
    }
    return acc
  }, {})
}

const ProjectGroupInfo = ({ projectGroup, onUpdate, add, returnUrl, onSuccess }) => {
  const [activeTabId, setActiveTabId] = useState(0)
  const [desiredActiveTabId, setDesiredActiveTabId] = useState(0)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [path, setPath] = useState(null)
  const { isSystemAdmin } = useSession()

  const projectGroupValues = getDefinedObject(projectGroup)

  const classes = tabStyles()
  const theme = useTheme()

  const isReadOnly = !isSystemAdmin // making a const because other roles may be able to eventually

  useEffect(() => {
    if (path) {
      // used to redirect the user after confirm dialog
      history.push(path)
    }
  }, [path])

  function handleTabChange(event, newValue) {
    if (hasUnsavedChanges) {
      setModalVisible(true)
      setDesiredActiveTabId(newValue)
    } else {
      setActiveTabId(newValue)
    }
  }

  function handleTabChangeIndex(index) {
    if (hasUnsavedChanges) {
      setModalVisible(true)
      setDesiredActiveTabId(index)
    } else {
      setActiveTabId(index)
    }
  }

  function closeModal() {
    setModalVisible(false)
  }

  function handleConfirmNavigationClick(index) {
    closeModal()
    setActiveTabId(desiredActiveTabId)
    setHasUnsavedChanges(false)
  }

  const options = {
    update: onUpdate
  }

  const sections = [
    {
      label: 'Project Group Information',
      component: (
        <ProjectGroupItem
          add={add}
          closeAfterSnackbar={onSuccess}
          hasUnsavedChanges={hasUnsavedChanges}
          projectGroup={projectGroup}
          queryOptions={options}
          setHasUnsavedChanges={setHasUnsavedChanges}
          isReadOnly={isReadOnly}
        />
      )
    },
    {
      label: 'Manage Chair(s)',
      disabled: add,
      component: (
        <ProjectGroupChairs
          activeTab={activeTabId === 1}
          projectGroup={projectGroupValues}
          isReadOnly={isReadOnly}
        />
      )
    }
  ]

  const title = 'Unsaved Changes'
  const message = 'Are you sure you want to leave?'

  return (
    <>
      <PreventTransitionPrompt
        title={title}
        message={message}
        visible={modalVisible}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
      <RouteGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        title={title}
        message={message}
        when={hasUnsavedChanges}
        // Navigate function
        navigate={path => {
          setPath(path)
        }}
      />
      <div className={classes.root}>
        <Grid container>
          <Typography className={classes.profileMeta} variant="h6">
            {projectGroupValues.name}
          </Typography>
        </Grid>
        <div className={classes.tabRoot}>
          <AppBar position="relative" color="inherit">
            <Tabs
              value={activeTabId}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabRoot}
            >
              {sections.map(section => {
                return (
                  <Tab
                    disabled={section.disabled}
                    classes={{ root: classes.tabTitle }}
                    key={section.label}
                    label={section.label}
                  />
                )
              })}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeTabId}
            onChangeIndex={handleTabChangeIndex}
          >
            {sections.map(section => {
              return (
                <TabContainer key={section.label} dir={theme.direction}>
                  {section.component}
                </TabContainer>
              )
            })}
          </SwipeableViews>
        </div>
      </div>
    </>
  )
}

export default ProjectGroupInfo
