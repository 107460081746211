import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import makeStyles from '@material-ui/core/styles/makeStyles'

const RestrictedSubGroupRequestsDialog = ({ denyRequest, open, setOpen }) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handleYes = () => {
    setOpen(false)
    denyRequest()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.titleContainer}>
        <div className={classes.titleWrapper}>
          Confirm
          <IconButton className={classes.buttonClose} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div className={classes.dialogPadding}>
        <DialogContent>
          <DialogContentText className={classes.dialogContentText}>
            <WarningIcon className={classes.warningIcon} fontSize="large" />
            Are you sure you wish to decline group access?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.buttonYes} variant="outlined" onClick={handleYes}>
            Yes
          </Button>
          <Button className={classes.buttonCancel} variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  buttonCancel: {
    background: theme.palette.denyButton.main,
    color: 'white'
  },
  buttonClose: {
    color: 'white'
  },
  buttonYes: {
    border: `1px solid ${theme.palette.denyButton.main}`,
    color: theme.palette.denyButton.main
  },
  dialogPadding: {
    padding: theme.spacing(3)
  },
  dialogContentText: {
    alignItems: 'flex-start',
    color: 'black',
    display: 'flex',
    fontWeight: 'bold'
  },
  titleContainer: {
    background: theme.palette.denyButton.main,
    color: 'white'
  },
  titleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  warningIcon: {
    color: theme.palette.denyButton.main,
    marginRight: theme.spacing(2)
  }
}))

export default RestrictedSubGroupRequestsDialog
