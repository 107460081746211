import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'

import RequestSignatureModel from './Admin/RequestSignatureModel'
import RequestSignatureSuccessModal from './Admin/RequestSignatureSuccessModal'
import RoundedButton from '../common/RoundedButton'
import Status from '../common/Status'
import LinkField from '../common/LinkField'
import ConfirmLeaveDialog from './ConfirmLeaveDialog'
import CancelAgreementConfirmationModal from './Admin/CancelAgreementConfirmationModal'
import ConfirmJoinDialog from './ConfirmJoinDialog'
import ConfirmCancelPendingDialog from './ConfirmCancelPendingDialog'
import { getAgreementUrl } from '../utils'
import ActivateSupportMustVerifySubgroupModal from './Admin/ActivateSupportMustVerifySubgroupModal'
import { TooltipHelper } from '../SignUp/GetStartedInfo'

export default function SubGroupCard({
  agreement,
  approvalStatus,
  canAccess,
  description,
  githubTeam,
  handleJoinProjectGroup,
  handleLeaveGroup,
  id,
  isAvailable,
  isNew,
  isOrgAdmin,
  jira,
  joinedDate,
  joinLoading,
  joinedPg = false,
  leaveGroupLoading,
  name,
  onParticipationRequestSuccess,
  orgAdminId,
  orgId,
  organizationAgreements,
  parentClasses,
  parentIsAvailable,
  parentAgreement,
  parentPending,
  parentProjectGroupId,
  parentProjectGroupName,
  parentSelectedOrJoined,
  pending,
  restricted,
  supportMustVerify,
  isReadOnly,
  openToMembers
}) {
  const classes = useStyles()

  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const orgAdminOpenSubgroup = isOrgAdmin && !restricted && !supportMustVerify

  const onRequestSignatureSuccess = () => {
    setSuccessModalOpen(true)
  }

  let joinedDateString = 'Joined'
  let joinedDateTooltip

  if (joinedPg) {
    if (joinedDate) {
      let date = new Date(joinedDate)
      const year = date.getFullYear()
      const month = date.toLocaleString('default', { month: 'long' })
      const day = date.getDate().toString()

      if (orgAdminOpenSubgroup) {
        joinedDateString = `Automatically ${
          isOrgAdmin ? 'Activated' : 'Joined'
        } ${month} ${day}, ${year}`
        joinedDateTooltip = `You were automatically added to this Sub Group .`
      } else {
        joinedDateString = `${isOrgAdmin ? 'Activated' : 'Joined'} ${month} ${day}, ${year}`
      }
    }
  }

  let agreementLocation = null

  if (agreement && isOrgAdmin) {
    agreementLocation = getAgreementUrl(agreement.fileName)
  }

  const currentAgreement =
    agreement && !isOrgAdmin ? organizationAgreements.find(a => a.name === agreement.name) : null

  return (
    <>
      <RequestSignatureSuccessModal
        isOrgAdmin={isOrgAdmin}
        onParticipationRequestSuccess={onParticipationRequestSuccess}
        orgId={orgId}
        projectGroupId={id}
        projectGroupName={name}
        successModalOpen={successModalOpen}
        successJoinAsIndividual={true}
        supportMustVerify={supportMustVerify}
      />
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.section}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9} className={classes.leftColumn}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={clsx(classes.subGroupName, parentClasses.name)}
              >
                {name}
              </Typography>
              <div
                className={clsx(parentClasses.heading, { [parentClasses.clickable]: !joinedPg })}
              >
                {/* status pills */}
                {githubTeam && (
                  <Status
                    icon="github"
                    label="GitHub"
                    color="white"
                    styles={classes.status}
                    tooltip=""
                  />
                )}
                {jira && (
                  <Status
                    icon="jira"
                    label="Jira Software"
                    color="white"
                    styles={classes.status}
                    tooltip=""
                  />
                )}

                {isOrgAdmin && pending ? (
                  supportMustVerify ? (
                    <Status
                      icon="warning"
                      label="Participation is pending support verification"
                      color="orangeSeeThrough"
                      styles={classes.status}
                      tooltip=""
                    />
                  ) : (
                    <Status
                      icon="warning"
                      label="Participation is pending agreement signature."
                      color="orangeSeeThrough"
                      styles={classes.status}
                      tooltip=""
                    />
                  )
                ) : (
                  <>
                    {joinedPg ? (
                      <Status
                        icon="checkCircle"
                        label={`${joinedDateString}`}
                        tooltip={joinedDateTooltip}
                        color="blue"
                        styles={parentClasses.status}
                      />
                    ) : (
                      isOrgAdmin &&
                      parentSelectedOrJoined &&
                      !parentPending && (
                        <Status
                          icon="openCircle"
                          label={`Not ${isOrgAdmin ? 'Activated' : 'Joined'}`}
                          color="gray"
                          styles={parentClasses.status}
                          tooltip={
                            orgAdminOpenSubgroup
                              ? 'The parent group must be activated first.'
                              : 'This Subgroup is not available for participation by individual participants because your organization has not activated it. Click “Activate Group” to begin the activation process.'
                          }
                        />
                      )
                    )}

                    {isNew && (
                      <Status icon="new" label="New" color="yellow" styles={parentClasses.status} />
                    )}
                    {restricted && (!openToMembers || isOrgAdmin) && !joinedPg && (
                      <Status
                        icon="lock"
                        label="Restricted"
                        color="salmon"
                        styles={parentClasses.status}
                        tooltip={
                          isOrgAdmin
                            ? 'Participation in this Subgroup has additional requirements. Click “Activate Group” to begin the activation process.'
                            : 'Participation in this Subgroup has additional requirements. You may send a participation request by clicking “Join Group.”'
                        }
                      />
                    )}
                  </>
                )}
              </div>
              <Typography className={parentClasses.description}>{description}</Typography>
              {agreementLocation && (
                <div className={parentClasses.footer}>
                  <div className={parentClasses.links}>
                    <LinkField
                      to={agreementLocation}
                      className={parentClasses.link}
                      label="Sub-Group agreement"
                      target="_blank"
                    />
                  </div>
                </div>
              )}
            </Grid>
            {!isReadOnly && (
              <Grid item xs={12} sm={12} md={3} className={classes.joinButtonContainer}>
                <Grid container className={classes.marginTop}>
                  <Grid
                    item
                    xs={12}
                    className={clsx(classes.buttonContainer, parentClasses.buttonContainer)}
                  >
                    {isOrgAdmin ? (
                      restricted || supportMustVerify ? (
                        joinedPg ? (
                          pending ? (
                            approvalStatus === 'requestSupportVerify' ||
                            (!restricted && supportMustVerify) ? (
                              <Typography className={classes.pending}>
                                Awaiting Verification
                              </Typography>
                            ) : (
                              <CancelAgreementConfirmationModal
                                organizationId={orgId}
                                projectGroupId={id}
                              >
                                {onClick => (
                                  <div className={classes.pendingButtonContainer}>
                                    <div className={classes.pendingLogo}>
                                      <WarningIcon className={classes.warning} />
                                      <Tooltip
                                        title="This Subgroup will be activated once the group agreement has been signed."
                                        placement="top"
                                        arrow
                                      >
                                        <Typography className={classes.pending}>
                                          Pending signature
                                        </Typography>
                                      </Tooltip>
                                    </div>
                                    <RoundedButton
                                      className={classes.selectionButton}
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      fullWidth={false}
                                      onClick={onClick}
                                    >
                                      Cancel
                                    </RoundedButton>
                                  </div>
                                )}
                              </CancelAgreementConfirmationModal>
                            )
                          ) : (
                            <ConfirmLeaveDialog
                              id={id}
                              leaveGroupLoading={leaveGroupLoading}
                              isOrgAdmin={isOrgAdmin}
                              orgId={orgId}
                              onConfirm={handleLeaveGroup}
                              projectGroupName={name}
                              type="Sub Group"
                            />
                          )
                        ) : restricted || !parentSelectedOrJoined ? (
                          <RequestSignatureModel
                            agreementLabel="Agreement"
                            agreementLink={
                              parentSelectedOrJoined
                                ? getAgreementUrl(parentAgreement.fileName)
                                : agreementLocation
                            }
                            projectGroupId={id}
                            projectGroupName={
                              parentSelectedOrJoined ? parentProjectGroupName : name
                            }
                            restricted={false}
                            supportMustVerify={supportMustVerify}
                            onSuccess={onRequestSignatureSuccess}
                          >
                            {onClick => (
                              <div>
                                {!canAccess && (
                                  <Typography paragraph className={classes.notAvailableLabel}>
                                    <TooltipHelper
                                      iconClass={classes.notAvailableIcon}
                                      label="Not available at your tier"
                                      labelFirst={false}
                                      title="To access this group, your organization must upgrade its participation tier."
                                    />
                                  </Typography>
                                )}
                                <RoundedButton
                                  className={classes.selectionButton}
                                  color="primary"
                                  disabled={!canAccess}
                                  size="small"
                                  fullWidth={false}
                                  onClick={onClick}
                                >
                                  Activate Group
                                </RoundedButton>
                              </div>
                            )}
                          </RequestSignatureModel>
                        ) : (
                          <ActivateSupportMustVerifySubgroupModal
                            projectGroupId={id}
                            projectGroupName={name}
                            onSuccess={onRequestSignatureSuccess}
                          />
                        )
                      ) : parentPending ? (
                        <CancelAgreementConfirmationModal
                          organizationId={orgId}
                          projectGroupId={parentProjectGroupId}
                        >
                          {onClick => (
                            <div className={classes.pendingButtonContainer}>
                              <div className={classes.pendingLogo}>
                                <WarningIcon className={classes.warning} />
                                <Tooltip
                                  title="This Subgroup will be activated once the parent project group agreement has been signed."
                                  placement="top"
                                  arrow
                                >
                                  <Typography className={classes.pending}>
                                    Pending signature
                                  </Typography>
                                </Tooltip>
                              </div>
                              <RoundedButton
                                className={classes.selectionButton}
                                color="primary"
                                variant="outlined"
                                size="small"
                                fullWidth={false}
                                onClick={onClick}
                              >
                                Cancel
                              </RoundedButton>
                            </div>
                          )}
                        </CancelAgreementConfirmationModal>
                      ) : parentSelectedOrJoined && joinedPg ? (
                        <ConfirmLeaveDialog
                          id={parentProjectGroupId}
                          leaveGroupLoading={leaveGroupLoading}
                          isOrgAdmin={isOrgAdmin}
                          orgId={orgId}
                          onConfirm={handleLeaveGroup}
                          projectGroupName={name}
                          type="Sub Group"
                        />
                      ) : (
                        <RequestSignatureModel
                          projectGroupName={name}
                          projectGroupId={parentProjectGroupId}
                          agreementLink={agreementLocation}
                          onSuccess={onRequestSignatureSuccess}
                          agreementLabel="Agreement"
                          supportMustVerify={supportMustVerify}
                        >
                          {onClick => (
                            <div>
                              {!canAccess && (
                                <Typography paragraph className={classes.notAvailableLabel}>
                                  <TooltipHelper
                                    iconClass={classes.notAvailableIcon}
                                    label="Not available at your tier"
                                    labelFirst={false}
                                    title="To access this group, your organization must upgrade its participation tier."
                                  />
                                </Typography>
                              )}
                              <RoundedButton
                                className={classes.selectionButton}
                                color="primary"
                                disabled={!canAccess}
                                size="small"
                                fullWidth={false}
                                onClick={onClick}
                              >
                                Activate Group
                              </RoundedButton>
                            </div>
                          )}
                        </RequestSignatureModel>
                      )
                    ) : joinedPg ? (
                      <ConfirmLeaveDialog
                        id={id}
                        leaveGroupLoading={leaveGroupLoading}
                        isOrgAdmin={isOrgAdmin}
                        orgId={orgId}
                        onConfirm={handleLeaveGroup}
                        projectGroupName={name}
                        type="Sub Group"
                      />
                    ) : approvalStatus === 'requestAccess' ? (
                      <div className={classes.pendingButtonContainer}>
                        <div className={classes.pendingLogo}>
                          <WarningIcon className={classes.warning} />
                          <Tooltip
                            title="Access will be granted pending approval by your account administrator."
                            placement="top"
                            arrow
                          >
                            <Typography className={classes.pending}>Pending approval</Typography>
                          </Tooltip>
                        </div>
                        <ConfirmCancelPendingDialog
                          id={id}
                          leaveGroupLoadin={leaveGroupLoading}
                          isOrgAdmin={isOrgAdmin}
                          onConfirm={() => handleLeaveGroup(id, true)}
                          orgId={orgId}
                          projectGroupName={name}
                          type="Sub Group"
                        />
                      </div>
                    ) : approvalStatus === 'requestParticipation' &&
                      supportMustVerify &&
                      isOrgAdmin &&
                      currentAgreement &&
                      currentAgreement.signedDate ? (
                      <Typography className={classes.pending}>Awaiting Verification</Typography>
                    ) : approvalStatus === 'requestParticipation' ? (
                      <div className={classes.pendingButtonContainer}>
                        <div className={classes.pendingLogo}>
                          <WarningIcon className={classes.warning} />
                          <Tooltip
                            title="Access will be granted pending activation by your account administrator."
                            placement="top"
                            arrow
                          >
                            <Typography className={classes.pending}>Pending activation</Typography>
                          </Tooltip>
                        </div>
                        <ConfirmCancelPendingDialog
                          id={id}
                          leaveGroupLoading={leaveGroupLoading}
                          isOrgAdmin={isOrgAdmin}
                          onConfirm={() => handleLeaveGroup(id, true)}
                          orgId={orgId}
                          projectGroupName={name}
                          type="Project Group"
                        />
                      </div>
                    ) : (
                      <ConfirmJoinDialog
                        canAccess={canAccess}
                        handleJoinProjectGroup={handleJoinProjectGroup}
                        isAvailable={isAvailable}
                        isOrgAdmin={isOrgAdmin}
                        joinLoading={joinLoading}
                        onParticipationRequestSuccess={onParticipationRequestSuccess}
                        openToMembers={openToMembers}
                        orgAdminId={orgAdminId}
                        orgId={orgId}
                        parentIsAvailable={parentIsAvailable}
                        parentProjectGroupId={parentProjectGroupId}
                        parentProjectGroupName={parentProjectGroupName}
                        parentSelectedOrJoined={parentSelectedOrJoined}
                        projectGroupName={name}
                        projectGroupId={id}
                        restricted={restricted}
                        supportMustVerify={supportMustVerify}
                        type="Project Group"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600
  },
  buttonContainer: {
    justifyContent: 'flex-end !important'
  },
  container: {
    // border: `1px solid ${theme.palette.borderGray.main}`,
    backgroundColor: 'white'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    paddingTop: 5,
    // borderTop: `1px solid ${theme.palette.borderGray.main}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 0
    }
  },
  joinButtonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 35
  },
  leftColumn: {
    paddingRight: 50,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 'unset'
    }
  },
  link: {
    marginLeft: -3,
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.linkHover.main
    },
    [theme.breakpoints.down('sm')]: {
      margin: 5,
      fontSize: 12,
      backgroundColor: theme.palette.gray3.main
    }
  },
  marginTop: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 30
    }
  },
  notAvailableLabel: {
    color: '#2A5589',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  notAvailableIcon: {
    verticalAlign: 'bottom'
  },
  pending: {
    fontWeight: 'bold',
    marginLeft: 10
  },
  pendingButtonContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  pendingLogo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    justifyContent: 'center'
  },
  selectionButton: {
    width: 200,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: 'auto'
    }
  },
  section: {
    padding: '20px !important',
    [theme.breakpoints.down('xs')]: {
      // padding: '15px  5px !important'
      border: `1px solid ${theme.palette.borderGray.main}`,
      background: '#f5f5f5',
      padding: 2,
      marginBottom: 12
    }
  },
  status: {
    marginRight: 10
  },
  subGroupName: {
    marginBottom: 20
  },
  warning: {
    color: theme.palette.denyButton.main
  },
  warningIcon: {
    fontSize: '1rem',
    marginRight: 10
  }
}))
