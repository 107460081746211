import gql from 'graphql-tag'

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      atlassianId
      firstname
      lastname
      jobTitle
      phoneNumber
      email
      githubId
      logo
      slackUserId
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      percentageComplete
    }
  }
`

export const GET_ORG = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      name
      organizationRoles {
        organizationAdmin {
          id
        }
        exchangeListingAdmin {
          id
        }
        primaryContact {
          id
        }
        authorizedSigners {
          user {
            id
          }
        }
      }
    }
  }
`

export const GET_ATLASSIAN_PROFILE = gql`
  query($input: GetAtlassianProfileInput!) {
    getAtlassianProfile(input: $input) {
      username
    }
  }
`
