import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { useFormState } from 'react-use-form-state'
import { InputTextFieldNew } from '../../common'
import { inputRegexes } from '../../../src/utils/formUtils'
import { useGetAllBlockedDomains } from '../../utils/getBlockedDomains'
import isEmailBlacklisted from '../../utils/emailBlacklist'

export default function InviteUserInput({
  inviteEmail,
  inviteFirstName,
  inviteLastName,
  handleInviteChange,
  handleInviteRemove,
  setEmailAdded
}) {
  const [formState, { email, text }] = useFormState({
    email: inviteEmail,
    firstName: inviteFirstName,
    lastName: inviteLastName
  })

  const [error, setError] = useState(false)
  const { blockedDomainsLoading, blockedDomains } = useGetAllBlockedDomains()

  useEffect(() => {
    if (
      formState.values.email &&
      !blockedDomainsLoading &&
      blockedDomains.hasOwnProperty('allBlockedDomains')
    ) {
      let isError = false

      const isBlacklisted = isEmailBlacklisted(
        formState.values.email,
        blockedDomains.allBlockedDomains
      )

      const errorMessage = isBlacklisted
        ? "Please use your organization's email domain."
        : 'Invalid Email'
      isError = isBlacklisted || !inputRegexes.email.test(formState.values.email)

      if (isError) {
        setError(errorMessage)
        setEmailAdded(false)
      } else {
        setError('')
        setEmailAdded(true)
      }
    } else {
      setError('Email is required')
      setEmailAdded(false)
    }
  }, [formState.values.email, setEmailAdded, blockedDomains, blockedDomainsLoading])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={5}>
        <InputTextFieldNew
          error={!!error}
          errorHelperText={error}
          inputType={email}
          label="Email"
          name="email"
          value={formState.values.email}
          onBlur={() => handleInviteChange(formState)}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={3}>
        <InputTextFieldNew
          inputType={text}
          label="First Name"
          name="firstName"
          value={formState.values.firstName}
          onBlur={() => handleInviteChange(formState)}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={3}>
        <InputTextFieldNew
          inputType={text}
          label="Last Name"
          name="lastName"
          value={formState.values.lastName}
          onBlur={() => handleInviteChange(formState)}
        />
      </Grid>
      <Grid item xs={1}>
        {handleInviteRemove && (
          <IconButton
            style={{ marginTop: 16 }} // MUI textfelds have margin top 16
            onClick={handleInviteRemove}
            aria-label="Delete"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}
