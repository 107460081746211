import React from 'react'
import { Checkbox, Tooltip, Typography } from '@material-ui/core'

export const hubTypeLookup = {
  Organization: 'Organization',
  'Project Group': 'Project Group',
  User: 'User'
}

export const columnDef = ({
  deprovisionDispatch = () => null,
  deprovisionState = [],
  filter = [],
  filterValues = {}
}) =>
  [
    {
      defaultFilter: filterValues.current.hubId || '',
      field: 'hubId',
      render: rowData =>
        (rowData.hubId && (
          <Tooltip arrow title={rowData.hubId}>
            <Typography>{rowData.hubId}</Typography>
          </Tooltip>
        )) ||
        '',
      title: 'Hub Id'
    },
    {
      defaultFilter: filterValues.current.integrationId || '',
      field: 'integrationId',
      render: rowData =>
        (rowData.integrationId && (
          <Tooltip arrow title={rowData.integrationId}>
            <Typography>{rowData.integrationId}</Typography>
          </Tooltip>
        )) ||
        '',
      title: 'Integration Id'
    },
    {
      defaultFilter: filterValues.current.hubName || '',
      field: 'hubName',
      render: rowData =>
        (rowData.hubName && (
          <Tooltip arrow title={rowData.hubName}>
            <Typography>{rowData.hubName}</Typography>
          </Tooltip>
        )) ||
        '',
      title: 'Hub Name or Email'
    },
    {
      defaultFilter: filterValues.current.hubType || [],
      field: 'hubType',
      lookup: hubTypeLookup,
      render: rowData => (
        <Tooltip arrow title={rowData.hubType}>
          <Typography>{rowData.hubType}</Typography>
        </Tooltip>
      ),
      title: 'Hub Type'
    },
    {
      customFilterAndSearch: (value, rowData) =>
        rowData.parent && rowData.parent.hubId.toLowerCase().includes(value.toLowerCase()),
      customSort: (a, b) => {
        if (!a.parent || !b.parent) {
          let value = 0

          if (!a.parent) {
            value = -1
          }

          if (!b.parent) {
            value += 1
          }

          return value
        }

        return a.parent.hubId.toLowerCase().localeCompare(b.parent.hubId.toLowerCase())
      },
      defaultFilter: filterValues.current.parentHubId || '',
      field: 'parentHubId',
      render: rowData =>
        (rowData.parent && (
          <Tooltip arrow title={rowData.parent.hubId}>
            <Typography>{rowData.parent.hubId}</Typography>
          </Tooltip>
        )) ||
        '',
      title:
        (filter.some(f => f.field === 'parentHubId') &&
          filter.find(f => f.field === 'parentHubId').title) ||
        'Parent Hub Id'
    },
    {
      customFilterAndSearch: (value, rowData) =>
        rowData.parent && rowData.parent.hubName.toLowerCase().includes(value.toLowerCase()),
      customSort: (a, b) => {
        if (!a.parent || !b.parent) {
          let value = 0

          if (!a.parent) {
            value = -1
          }

          if (!b.parent) {
            value += 1
          }

          return value
        }

        return a.parent.hubName.toLowerCase().localeCompare(b.parent.hubName.toLowerCase())
      },
      defaultFilter: filterValues.current.parentHubName || '',
      field: 'parentHubName',
      render: rowData =>
        (rowData.parent && (
          <Tooltip arrow title={rowData.parent.hubName}>
            <Typography>{rowData.parent.hubName}</Typography>
          </Tooltip>
        )) ||
        '',
      title:
        (filter.some(f => f.field === 'parentHubName') &&
          filter.find(f => f.field === 'parentHubName').title) ||
        'Parent Hub Name'
    },
    {
      defaultFilter: filterValues.current.parentHubType || [],
      field: 'parentHubType',
      lookup: hubTypeLookup,
      render: rowData =>
        (rowData.parent && (
          <Tooltip arrow title={rowData.parent.hubType}>
            <Typography>{rowData.parent.hubType}</Typography>
          </Tooltip>
        )) ||
        '',
      title:
        (filter.some(f => f.field === 'parentHubType') &&
          filter.find(f => f.field === 'parentHubType').title) ||
        'Parent Hub Type'
    },
    {
      customFilterAndSearch: () => true,
      field: 'exempt',
      filterComponent: props => (
        <Tooltip
          arrow
          title={`Set all records to ${
            deprovisionState.some(d => !d.exempt) ? 'exempt' : 'unexempt'
          }`}
        >
          <Checkbox
            checked={deprovisionState.length > 0 && !deprovisionState.some(d => !d.exempt)}
            disabled={deprovisionState.length === 0}
            onChange={e =>
              deprovisionDispatch({
                type: `set_exempt_${deprovisionState.some(d => !d.exempt) ? 'all' : 'none'}`
              })
            }
          />
        </Tooltip>
      ),
      render: rowData => (
        <Tooltip
          arrow
          title={`This record will be ${
            rowData.exempt ? 'exempt from' : 'included in'
          } deprovisioning`}
        >
          <Checkbox
            checked={rowData.exempt}
            onChange={e =>
              deprovisionDispatch({
                exempt: e.target.checked,
                hubId: rowData.hubId,
                integrationId: rowData.integrationId,
                type: 'set_exempt'
              })
            }
          />
        </Tooltip>
      ),
      title: 'Exempt',
      type: 'boolean'
    },
    {
      defaultFilter: filterValues.current.error || '',
      field: 'error',
      render: rowData => (
        <Tooltip arrow title={rowData.error}>
          <Typography>{rowData.error}</Typography>
        </Tooltip>
      ),
      title: 'Error'
    }
  ]
    .filter(c => filter.some(f => f.field === c.field))
    .sort(
      (a, b) =>
        filter.findIndex(f => f.field === a.field) - filter.findIndex(f => f.field === b.field)
    )
