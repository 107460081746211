import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useSession } from './queries'

function PrivateRoute({ component: Component, requiredRoles, ...rest }) {
  const { loading, session, isAuthed } = useSession()

  if (loading) {
    return null
  }

  const hasAccess =
    !requiredRoles || (isAuthed && session.user.roles.some(r => requiredRoles.includes(r.roleId)))

  return (
    <Route
      {...rest}
      render={props =>
        isAuthed && hasAccess ? (
          <Component {...props} />
        ) : isAuthed && !hasAccess ? (
          <div>You are not authorized to view this page</div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
