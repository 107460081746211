import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLazyQuery } from '@apollo/react-hooks'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { GET_ORGANIZATION_PG_USERS } from './queries'

import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'

export default function ConfirmLeaveDialog({
  children,
  leaveGroupLoading,
  onConfirm,
  overrideButton,
  projectGroupName,
  type,
  isOrgAdmin = false,
  orgId,
  id
}) {
  const [open, setOpen] = useState(false)
  const [leavePgData, setLeavePgData] = useState({})
  const [leavePgConfirmed, setLeavePgConfirm] = useState(false)
  const [queryCount, setCount] = useState(0)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const [getCount, { loading, data }] = useLazyQuery(GET_ORGANIZATION_PG_USERS, {
    variables: { input: leavePgData }
  })

  useEffect(() => {
    // trigger count query
    if (Object.entries(leavePgData).length > 0) {
      getCount()
    }

    if (data) {
      setCount(data.getOrganizationProjectGroupUsers.count)
    }
  }, [leavePgData, getCount, data])

  function handleClickOpen() {
    if (isOrgAdmin) {
      setLeavePgData({
        orgId: orgId,
        projectGroupId: id
      })
    }
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }
  function handleConfirm() {
    // setOpen(false)
    onConfirm(id)
  }

  function updateConfirmText(e) {
    if (
      (isOrgAdmin && e.target.value) === 'DEACTIVATE' ||
      (!isOrgAdmin && e.target.value === 'LEAVE')
    ) {
      setLeavePgConfirm(true)
    } else {
      setLeavePgConfirm(false)
    }
  }

  const inputProps = { style: { height: '40px', padding: '0 10px', fontWeight: 600 } }

  return (
    <>
      {overrideButton ? (
        children(handleClickOpen)
      ) : (
        <div className={classes.leaveButton}>
          <RoundedButton
            className={classes.selectionButton}
            variant="outlined"
            size="small"
            fullWidth={false}
            onClick={handleClickOpen}
          >
            {isOrgAdmin ? 'Deactivate Group' : 'Leave Group'}
          </RoundedButton>
        </div>
      )}
      {!loading ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle className={classes.header} id="responsive-dialog-title">
            Are you sure?
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.dialogContentText}>
              <WarningIcon className={classes.warningIcon} />
              <div>
                <Typography className={classes.bold}>Please confirm this action</Typography>
                <Typography className={classes.warningText}>
                  {isOrgAdmin ? (
                    <>
                      <span className={classes.bold}>
                        Deactivating will remove all ({queryCount}) organization participants from
                        this group
                      </span>
                      <br />
                      Are you sure you wish to deactivate this group?
                    </>
                  ) : (
                    <>
                      Do you wish to leave this group? You will lose access to all related
                      activities, tools, and resources.
                    </>
                  )}
                </Typography>
              </div>
            </div>
          </DialogContent>
          <Typography className={classes.leaveMessage}>
            {isOrgAdmin ? 'To confirm, type DEACTIVATE' : 'Type the word LEAVE to confirm'}:
          </Typography>
          <DialogActions className={classes.actions}>
            <TextField
              className={classes.leaveInput}
              margin="none"
              inputProps={inputProps}
              color="secondary"
              variant="outlined"
              onChange={updateConfirmText}
            />
            <Button
              onClick={handleConfirm}
              variant="outlined"
              className={isOrgAdmin ? classes.buttonOrgAdminAlt : classes.buttonAlt}
              disabled={!leavePgConfirmed}
              classes={{ outlined: classes.outlined }}
            >
              {leaveGroupLoading ? (
                <Progress classes={{ colorPrimary: classes.progress }} size={30} delay={0} />
              ) : isOrgAdmin ? (
                'Deactivate'
              ) : (
                'Leave'
              )}
            </Button>
            <Button
              fullWidth={false}
              onClick={handleClose}
              className={isOrgAdmin ? classes.buttonOrgAdmin : classes.button}
              classes={{ root: classes.root }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: 20,
    marginRight: 20,
    paddingLeft: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 30
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },

  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.red.main
    }
  },
  buttonAlt: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  buttonOrgAdmin: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.red.main
    }
  },
  buttonOrgAdminAlt: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    height: 40
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  header: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  leaveButton: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    }
  },
  leaveInput: {
    marginRight: 'auto'
  },
  leaveMessage: {
    paddingLeft: 90,
    fontWeight: 600
  },
  outlined: {
    color: theme.palette.red.main,
    border: `thin solid ${theme.palette.red.main}`
  },
  progress: {
    color: theme.palette.red.main
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.red.main
  },
  selectionButton: {
    width: 200
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  warningText: {
    marginBottom: 5
  }
}))
