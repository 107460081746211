import React from 'react'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles'

import Progress from '../common/Progress'
import BorderLinearProgress from '../common/BorderLinearProgress'
import { useQuery } from '../hooks'
import { useSession } from '../auth/queries'
import LinkField from '../common/LinkField'
import { getImageUrl } from '../utils'
import { BasicEmailModal } from '../EmailModal'

export default function CompanyCard({ orgId }) {
  const classes = useStyles()
  const { loading: loadingSession, isSystemAdmin, isOrgAdmin, session } = useSession()

  const parameters = { variables: { id: orgId }, skip: !orgId }
  const {
    data: { organization = {} },
    loading
  } = useQuery(GET_ORG, parameters)

  if (loading || loadingSession) {
    return <Progress />
  }

  if (!loading && !loadingSession && !orgId) {
    return null
  }

  const {
    name,
    logo,
    percentageComplete,
    organizationRoles: { organizationAdmin } = {}
  } = organization

  let orgAdminString
  if (organizationAdmin) {
    orgAdminString = `${organizationAdmin.firstname} ${organizationAdmin.lastname}`
  }

  return (
    <Paper>
      <div data-testid="CompanyCardSection" className={classes.container}>
        <div className={classes.avatar}>
          {logo ? (
            <Avatar
              alt="Organization Icon"
              src={getImageUrl(logo)}
              className={classes.bigAvatar}
              imgProps={{ className: classes.avatarImg }}
            />
          ) : (
            <Avatar alt="Organization Icon" className={classes.bigAvatar}>
              <Icon className={classes.iconBlank}>business</Icon>
            </Avatar>
          )}
        </div>
        <Typography variant="h5">{name}</Typography>
      </div>
      {(isSystemAdmin || isOrgAdmin) && (
        <div data-testid="CompanyCardPercentageSection" className={classes.subSection}>
          <Typography variant="subtitle2">
            Company profile {percentageComplete}% complete
          </Typography>
          <BorderLinearProgress className={classes.progressBar} value={percentageComplete} />
          {percentageComplete !== 100 && (
            <Typography>
              Complete your
              <LinkField
                linkClasses={classes.link}
                label="Company Profile"
                to={`/organization/${orgId}`}
              />
            </Typography>
          )}
        </div>
      )}
      {!isOrgAdmin && orgAdminString && (
        <div className={classes.subSection}>
          <Typography variant="subtitle2">
            Participation account administrator: {orgAdminString}
          </Typography>
          <BasicEmailModal
            to={organizationAdmin.email}
            sentToLine={`Mail will be sent to ${orgAdminString}`}
            sender={session.user.email}
            title="Email Participation Account Administrator"
            hideConfirm={true}
          >
            {onClick => <LinkField label="Contact By Email" to="#" onClick={onClick} />}
          </BasicEmailModal>
        </div>
      )}
    </Paper>
  )
}

export const GET_ORG = gql`
  query getOrganizationForCompanyTile($id: ID) {
    organization(id: $id) {
      id
      name
      website
      logo
      organizationRoles {
        organizationAdmin {
          id
          firstname
          lastname
          email
        }
      }
      percentageComplete
    }
  }
`

const useStyles = makeStyles(theme => ({
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarContainer: {
    display: 'flex'
  },
  iconBlank: {
    fontSize: 90
  },
  name: {
    margin: 'auto 15px',
    fontSize: '0.875rem'
  },
  progressBar: {
    height: 20,
    margin: '5px 0'
  },
  gray: {
    color: theme.palette.gray.main
  },
  subSection: {
    borderTop: `1px solid ${theme.palette.borderGray.main}`,
    padding: 15
  },
  link: {
    margin: '0 5px'
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    margin: 10,
    width: 125,
    height: 125,
    borderRadius: 125,
    border: '1px solid gray',
    color: 'gray',
    backgroundColor: 'white'
  },
  avatarImg: {
    height: 'auto',
    width: '100%'
  }
}))
