import React, { useCallback, useEffect, useState } from 'react'
import { Grid, IconButton, InputAdornment, makeStyles, Typography } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useFormState } from 'react-use-form-state'
import { ReCaptcha } from 'react-recaptcha-v3'
import { useSnackbar } from 'notistack'
import zxcvbn from 'zxcvbn'

import { resetStore } from '../middleware/api'
import { CheckboxField, LinkField, InputTextFieldNew, Progress, RoundedButton } from '../common'
import { useMutation, useFormStyles } from '../hooks'
import { CREATE_ACCOUNT, VERIFY_TOKEN } from './mutations'
import { LOGIN_USER } from '../auth/mutations'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../utils'
import { CONTACT_SUPPORT_URL, SUPPORT_EMAIL } from '../utils/constants'
import config from '../config'

export default function CreateAccount(props) {
  const { enqueueSnackbar } = useSnackbar()

  const { loading, execute: handleCreateAccount, data, error } = useMutation(CREATE_ACCOUNT, {
    onCompleted: result => {
      if (result.createAccount.result) {
        enqueueSnackbar('New account created! Your progress has been saved.', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    }
  })
  const {
    loading: loginLoading,
    execute: loginUser,
    data: loginData,
    error: loginError
  } = useMutation(LOGIN_USER)
  const {
    loading: verifyTokenLoading,
    execute: handleVerifyToken,
    data: verifyTokenResult,
    error: verifyTokenError
  } = useMutation(VERIFY_TOKEN)

  const [formState, inputTypes] = useFormState({
    firstname: '',
    lastname: '',
    jobTitle: '',
    password: '',
    confirmPassword: ''
  })

  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [organizationId, setOrganizationId] = useState('')
  const [passwordRequirements, setPasswordRequirements] = useState([false, false, false, false])
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordStrength, setShowPasswordStrength] = useState(false)
  const [verifyCaptcha, setVerifyCaptcha] = useState(false)

  const formClasses = useFormStyles()
  const classes = useStyles(passwordStrength)

  const acceptedInvite = props.acceptedInvite
  const token = props.params.get('token')

  const loginUserCallback = useCallback(loginUser, [])
  const handleVerifyTokenCallback = useCallback(handleVerifyToken, [])

  useEffect(() => {
    enqueueSnackbar('Email address confirmed. Your progress has been saved.', {
      variant: 'success',
      autoHideDuration: 2000
    })
  }, [enqueueSnackbar])

  useEffect(() => {
    if (token) {
      handleVerifyTokenCallback({ values: { token } })
    }
  }, [token, handleVerifyTokenCallback])

  useEffect(() => {
    if (data && !error && !loading) {
      loginUserCallback({ values: { email, password: formState.values.password } })
      resetStore()
    }
  }, [data, error, loading, email, formState.values.password, loginUserCallback])

  useEffect(() => {
    if (!loginLoading && loginData) {
      // resetStore()
    }
  }, [loginData, loginError, loginLoading])

  useEffect(() => {
    if (
      (formState.values.password === '' || !formState.validity.password) &&
      formState.values.confirmPassword !== ''
    ) {
      formState.setField('confirmPassword', '')
    }
  }, [
    formState,
    formState.values.password,
    formState.values.confirmPassword,
    formState.validity.password
  ])

  useEffect(() => {
    // Manually update confirmPassword validation when changing password
    const confirm = formState.values.confirmPassword
    const password = formState.values.password

    if (confirm !== password && formState.validity.confirmPassword) {
      formState.setFieldError('confirmPassword', 'Passwords must match')
    } else if (confirm === password && !formState.validity.confirmPassword) {
      formState.setField('confirmPassword', formState.values.confirmPassword)
    }
  }, [formState, formState.errors.confirmPassword, formState.values])

  useEffect(() => {
    if (formState.values.password) {
      /*
          At least one letter (A-Z/a-z)
          At least one digit (0-9)
          At least one symbol (including space)
          At least 8 characters long
      */
      setPasswordRequirements([
        new RegExp('[A-Za-z]').test(formState.values.password),
        new RegExp('\\d').test(formState.values.password),
        new RegExp('[ -/:-@[-`{-~]').test(formState.values.password),
        formState.values.password.length >= 8
      ])
      setPasswordStrength(zxcvbn(formState.values.password).score)
    } else {
      setPasswordRequirements([false, false, false, false])
      setPasswordStrength(-1)
    }
  }, [formState.values.password])

  useEffect(() => {
    if (verifyTokenResult && !errorMessage) {
      setEmail(verifyTokenResult.verifyToken.email)
      setOrganizationId(verifyTokenResult.verifyToken.organizationId)
    }
  }, [verifyTokenResult, errorMessage])

  useEffect(() => {
    if (!verifyTokenLoading && verifyTokenError) {
      setErrorMessage('Sorry! Unable to create account.')
    }
  }, [verifyTokenLoading, verifyTokenError])

  function createAccount(e) {
    e.preventDefault()

    if (acceptedInvite) {
      handleCreateAccount({
        values: {
          ...formState.values,
          email,
          organizationId,
          token
        }
      })
    } else {
      handleCreateAccount({
        values: {
          ...formState.values,
          email,
          token
        }
      })
    }
  }

  function verifyCallback(recaptchaToken) {
    setVerifyCaptcha(true)
  }

  const inputFields = createAccountFields(
    classes,
    formState,
    setShowPassword,
    setShowPasswordStrength,
    showPassword
  )

  const disableSubmit = isFormSubmitDisabled(inputFields, formState)

  if (!verifyCaptcha) {
    return (
      <div>
        <ReCaptcha
          sitekey={config.RECAPTCHA_PUBKEY}
          action="action_name"
          verifyCallback={verifyCallback}
        />
        <Progress size={30} delay={5} />
      </div>
    )
  } else {
    return (
      <form onSubmit={createAccount} data-testid="CreateAccountContainer">
        {verifyTokenLoading ? (
          <Progress />
        ) : (
          <>
            <div className={classes.header}>
              <Typography role="heading" variant="h3" className={formClasses.heading}>
                Create Account
              </Typography>
              {errorMessage ? (
                <>
                  <Typography role="alert" color="error">
                    {errorMessage}
                  </Typography>
                  Please contact{' '}
                  <LinkField
                    color="primary"
                    label="TIP Support"
                    to={CONTACT_SUPPORT_URL}
                    hideIcon={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                  or email{' '}
                  <LinkField
                    color="primary"
                    label={SUPPORT_EMAIL}
                    to={`mailto:${SUPPORT_EMAIL}`}
                    hideIcon={true}
                  />
                </>
              ) : !error ||
                (error &&
                  error.graphQLErrors[0].message.toLowerCase() !== 'the user already exists.') ? (
                <>
                  <Typography className={classes.emailLabel} variant="subtitle2">
                    Registering as: <span className={classes.emailLabelSpan}>{email}</span>
                    <br />
                    This email address has been confirmed.
                  </Typography>
                  {error && (
                    <Typography role="alert" color="error">
                      {errorMessages.TIPSupport}
                    </Typography>
                  )}
                </>
              ) : (
                error.graphQLErrors[0].message.toLowerCase() === 'the user already exists.' && (
                  <Typography role="alert" color="error">
                    {`The user ${email} already exists`}
                    <br />
                    <LinkField label="Please Login" to="/login" rel="noopener noreferrer" />
                  </Typography>
                )
              )}
            </div>
            {!errorMessage && (
              <>
                <Grid className={formClasses.spacingBottom} container spacing={2}>
                  {Object.entries(inputFields).map(
                    ([
                      name,
                      { disabled, gridWidth = { xs: 12 }, InputComponent, inputType, ...args }
                    ]) =>
                      !disabled && (
                        <Grid key={name} item {...gridWidth}>
                          <InputComponent
                            {...args}
                            error={
                              typeof formState.validity[name] !== 'undefined' &&
                              !formState.validity[name]
                            }
                            errorHelperText={formState.errors[name]}
                            inputType={inputTypes[inputType]}
                            value={formState.values[name]}
                          />
                          {name === 'password' && (
                            <div className={classes.passwordStrengthContainer}>
                              {(showPasswordStrength || formState.values.password) && (
                                <>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <span
                                        className={
                                          passwordStrength >= 0
                                            ? classes.passwordStrengthMeterFilled
                                            : classes.passwordStrengthMeterEmpty
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <span
                                        className={
                                          passwordStrength >= 2
                                            ? classes.passwordStrengthMeterFilled
                                            : classes.passwordStrengthMeterEmpty
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <span
                                        className={
                                          passwordStrength >= 4
                                            ? classes.passwordStrengthMeterFilled
                                            : classes.passwordStrengthMeterEmpty
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <div className={classes.passwordStrengthLabelContainer}>
                                    {showPasswordStrength && (
                                      <Typography
                                        className={classes.passwordStrengthLabel}
                                        variant="caption"
                                      >
                                        Create a strong password
                                      </Typography>
                                    )}
                                    <Typography
                                      className={classes.passwordStrengthLabelRating}
                                      variant="caption"
                                    >
                                      {passwordStrength === 0 || passwordStrength === 1
                                        ? 'Weak'
                                        : passwordStrength === 2 || passwordStrength === 3
                                        ? 'Medium'
                                        : passwordStrength === 4
                                        ? 'Strong'
                                        : ''}
                                    </Typography>
                                  </div>
                                </>
                              )}
                              {showPasswordStrength && (
                                <ul className={classes.passwordRequirementsList}>
                                  <Typography
                                    className={
                                      passwordRequirements[0]
                                        ? classes.passwordRequirementsListItemComplete
                                        : classes.passwordRequirementsListItem
                                    }
                                    component="li"
                                  >
                                    One letter
                                  </Typography>
                                  <Typography
                                    className={
                                      passwordRequirements[1]
                                        ? classes.passwordRequirementsListItemComplete
                                        : classes.passwordRequirementsListItem
                                    }
                                    component="li"
                                  >
                                    One number
                                  </Typography>
                                  <Typography
                                    className={
                                      passwordRequirements[2]
                                        ? classes.passwordRequirementsListItemComplete
                                        : classes.passwordRequirementsListItem
                                    }
                                    component="li"
                                  >
                                    One special case character
                                  </Typography>
                                  <Typography
                                    className={
                                      passwordRequirements[3]
                                        ? classes.passwordRequirementsListItemComplete
                                        : classes.passwordRequirementsListItem
                                    }
                                    component="li"
                                  >
                                    8 characters minimum
                                  </Typography>
                                </ul>
                              )}
                            </div>
                          )}
                        </Grid>
                      )
                  )}
                </Grid>
                <div className={formClasses.buttonContainer}>
                  <RoundedButton
                    fullWidth
                    data-testid="CreateAccountBtn"
                    disabled={errorMessage || disableSubmit}
                    size="medium"
                    type="submit"
                    onClick={createAccount}
                  >
                    {loading ? (
                      <div className={formClasses.progress}>
                        <Progress size={20} />
                      </div>
                    ) : (
                      'Next: Individual Profile'
                    )}
                  </RoundedButton>
                </div>
                <div className={classes.progressText}>
                  <Typography variant="caption">Your progress will be saved</Typography>
                </div>
              </>
            )}
          </>
        )}
      </form>
    )
  }
}

function TipCheckboxLabel({ classes }) {
  return (
    <>
      <Typography className={classes.checkboxLabel} variant="subtitle2">
        I understand and agree to the TIP{' '}
        <LinkField
          className={classes.linkField}
          label="Privacy Policy"
          hideIcon={true}
          to="https://telecominfraproject.com/legal-and-privacy-policies/"
          target="_blank"
          rel="noopener noreferrer"
        />
        .
      </Typography>
    </>
  )
}

const createAccountFields = (
  classes,
  formState,
  setShowPassword,
  setShowPasswordStrength,
  showPassword
) => ({
  firstname: {
    autoFocus: true,
    gridWidth: { xs: 12, sm: 6 },
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'First Name',
    name: 'firstname',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 50
    }
  },
  lastname: {
    autoComplete: 'lastname',
    gridWidth: { xs: 12, sm: 6 },
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Last Name',
    name: 'lastname',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 50
    }
  },
  jobTitle: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Job Title',
    name: 'jobTitle',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 50
    }
  },
  password: {
    autoComplete: 'new-password',
    InputComponent: InputTextFieldNew,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={e => e.preventDefault()}
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      )
    },
    inputType: showPassword ? 'text' : 'password',
    label: 'Password',
    name: 'password',
    validator: {
      required: true,
      regex: inputRegexes.password,
      regexMessage: 'Must be 8 characters long and have at least one letter, number, and symbol.'
    },
    onBlur: () => {
      if (!formState.validity.hasOwnProperty('password') || formState.validity.password) {
        setShowPasswordStrength(false)
      }

      if (
        (formState.values.password === '' || !formState.validity.password) &&
        formState.values.confirmPassword !== ''
      ) {
        formState.setField('confirmPassword', '')
      }
    },
    onFocus: () => setShowPasswordStrength(true)
  },
  confirmPassword: {
    autoComplete: 'new-password',
    disabled: formState.values.password === '' || !formState.validity.password,
    InputComponent: InputTextFieldNew,
    inputType: showPassword ? 'text' : 'password',
    label: 'Confirm Password',
    name: 'confirmPassword',
    validator: {
      required: true,
      mustMatchKey: 'password',
      mustMatchMessage: 'Passwords must match'
    }
  },
  tipPolicyCheckbox: {
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: <TipCheckboxLabel classes={classes} />,
    labelPlacement: 'end',
    name: 'tipPolicyCheckbox',
    testid: 'tipPolicyCheckbox',
    validator: {
      required: true
    }
  }
})

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    fontWeight: 'bold'
  },
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  emailLabel: {
    fontWeight: 'bold'
  },
  emailLabelSpan: {
    color: theme.palette.primary.main
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  linkField: {
    display: 'inline-block'
  },
  passwordRequirementsList: {
    listStyle: 'none',
    padding: 0,
    '& li:before': {
      borderRadius: '50%',
      content: '""',
      display: 'inline-block',
      height: theme.spacing(1),
      marginRight: theme.spacing(2),
      width: theme.spacing(1)
    }
  },
  passwordRequirementsListItem: {
    '&:before': {
      backgroundColor: theme.palette.primary.main
    }
  },
  passwordRequirementsListItemComplete: {
    color: '#E0E0E0',
    '&:before': {
      backgroundColor: '#E0E0E0'
    }
  },
  passwordStrengthContainer: {
    margin: `-${theme.spacing(2)}px 0`,
    textAlign: 'left'
  },
  passwordStrengthLabel: {
    flexGrow: 1,
    fontSize: 10
  },
  passwordStrengthLabelContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  passwordStrengthLabelRating: strength => ({
    color:
      strength === 0 || strength === 1
        ? '#F44134'
        : strength === 2 || strength === 3
        ? '#FFA900'
        : '#309D00',
    fontWeight: 'bold'
  }),
  passwordStrengthMeterEmpty: {
    backgroundColor: '#E0E0E0',
    display: 'inline-block',
    height: 6,
    width: '100%'
  },
  passwordStrengthMeterFilled: strength => ({
    backgroundColor:
      strength === 0 || strength === 1
        ? '#F44134'
        : strength === 2 || strength === 3
        ? '#FFA900'
        : strength === 4
        ? '#309D00'
        : '#E0E0E0',
    display: 'inline-block',
    height: 6,
    width: '100%'
  }),
  progressText: {
    color: theme.palette.gray9.main,
    marginLeft: theme.spacing(2),
    marginTop: -theme.spacing(1),
    textAlign: 'left'
  }
}))
