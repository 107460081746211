import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Fade, Grid, makeStyles, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { useSession } from '../auth/queries'
import { useMutation } from '../hooks'
import { Progress } from '../common'

const BigRedButton = () => {
  const classes = useStyles()
  const { isSystemAdmin, isReadOnlySystemAdmin } = useSession()
  const { enqueueSnackbar } = useSnackbar()
  const [textVisible, setTextVisible] = useState(false)

  const { execute, loading } = useMutation(TERMINATE_ORGS, {
    onCompleted: ({ terminateOrgs = {} } = {}) => {
      if (terminateOrgs.success) {
        enqueueSnackbar(
          `Congratulations Crosby! You have successfully initiated the deactivation of ${
            terminateOrgs.resultCount
          } organization(s)! This process will take a while, so the results will be stored in MongoDB upon completion.`,
          {
            variant: 'success',
            autoHideDuration: 15000
          }
        )
      } else {
        enqueueSnackbar('Uh oh... Something went wrong :/', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    }
  })

  const enabled = !loading && !isReadOnlySystemAdmin && isSystemAdmin

  return (
    <Grid container alignItems="center" direction="column">
      <Grid
        container
        item
        alignItems="center"
        className={classes.selectButton}
        justify="center"
        onClick={() => {
          if (enabled) {
            execute()
          }
        }}
        onMouseEnter={() => setTextVisible(true)}
        onMouseLeave={() => setTextVisible(false)}
        status={enabled ? 'enabled' : 'disabled'}
      >
        {loading ? (
          <Grid item className={classes.progress}>
            <Progress />
          </Grid>
        ) : (
          <Fade in={textVisible}>
            <Typography className={classes.label} status={enabled ? 'enabled' : 'disabled'}>
              Terminate Orgs
            </Typography>
          </Fade>
        )}
      </Grid>
      <Grid item>
        <Typography className={classes.title}>Crosby</Typography>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  // Styles adapted from https://codepen.io/JoeHastings/pen/MwoOeW
  label: {
    color: '#aa0808',
    fontSize: 22,
    fontWeight: 'bold',
    transform: 'scaleY(0.9)',
    userSelect: 'none',
    '&[status="disabled"]': {
      color: '#808080'
    }
  },
  progress: {
    display: 'inherit',
    transform: 'scaleY(0.9)'
  },
  selectButton: {
    borderRadius: '50%',
    cursor: 'pointer',
    height: 135,
    lineHeight: 150,
    margin: 32,
    marginTop: 0,
    position: 'relative',
    textAlign: 'center',
    width: 150,
    transition: '0.1s all ease-out',
    '&[status="disabled"]': {
      background: '#a9a9a9',
      backgroundImage: 'linear-gradient(to bottom, #a9a9a9, #b5b5b5)',
      boxShadow: '0 10px #a9a9a9, 0 15px 5px black',
      transform: 'translate(0px,5px)'
    },
    '&[status="enabled"]': {
      background: '#f74d4d',
      backgroundImage: 'linear-gradient(to bottom, #f74d4d, #f86d6d)',
      boxShadow: '0 15px #e24f4f, 0 20px 5px black',
      '&:hover': {
        boxShadow: '0 20px #e24f4f, 0 25px 5px black',
        transform: 'translate(0px,-5px)'
      },
      '&:active': {
        boxShadow: '0 0 #e24f4f, 0 5px 5px black',
        transform: 'translate(0px,15px)'
      }
    }
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    userSelect: 'none'
  }
}))

const TERMINATE_ORGS = gql`
  mutation {
    terminateOrgs {
      resultCount
      success
    }
  }
`

export default BigRedButton
