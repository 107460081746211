import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import RoundedButton from '../../common/RoundedButton'
import AuthorizedSignerInput from './AuthorizedSignerInput'
import LegalDesigneeInput from './LegalDesigneeInput'
import { GET_ORG } from './queries'
import { EDIT_AUTHORIZED_SIGNER, ADD_LEGAL_DESIGNEE, REMOVE_LEGAL_DESIGNEE } from './mutations'
import { useMutation } from '../../hooks'
import { Progress } from '../../common'

export default function AuthorizedRepresentatives({
  organization = {},
  activeTab,
  onFormChange,
  onCompleted,
  onUpdate,
  users,
  userId,
  isSystemAdmin,
  isReadOnly = false
}) {
  const classes = useStyles()

  // convert to an array that we can use
  const authorizedSigners = Object.keys(organization.organizationRoles.authorizedSigners).map(
    key => organization.organizationRoles.authorizedSigners[key]
  )

  const legalDesignee = !organization.organizationRoles.legalDesignee
    ? null
    : Object.keys(organization.organizationRoles.legalDesignee).length > 0
    ? organization.organizationRoles.legalDesignee
    : null

  const { execute: handleEditSigner, loading: editSignerLoading } = useMutation(
    EDIT_AUTHORIZED_SIGNER,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_ORG, variables: { id: organization.id } }]
    }
  )

  const { execute: handleRemoveLegalDesignee } = useMutation(REMOVE_LEGAL_DESIGNEE, {
    refetchQueries: [{ query: GET_ORG, variables: { id: organization.id } }]
  })
  const { execute: handleAddLegalDesignee } = useMutation(ADD_LEGAL_DESIGNEE, {
    refetchQueries: [{ query: GET_ORG, variables: { id: organization.id } }]
  })

  function handleSave(obj) {
    setEditing(false)
    handleEditSigner({ id: organization.id, values: obj })
  }

  function handleSaveLegal(obj) {
    setAddingLegal(false)
    handleAddLegalDesignee({ id: organization.id, values: obj })
  }

  function handleDeleteLegal(obj) {
    let data
    if (obj.user) {
      // user chosen from list we delete by userId
      data = { userId: obj.user.id }
    } else {
      // manual entry we will delete by email
      data = { email: obj.email }
    }

    handleRemoveLegalDesignee({ id: organization.id, values: data })
  }

  const [editing, setEditing] = useState(false)
  const [addingLegal, setAddingLegal] = useState(false)

  let signer

  if (authorizedSigners.length > 0) {
    signer = authorizedSigners[0].user || authorizedSigners[0]
  }

  return (
    <form>
      <Grid container>
        <Grid container spacing={1} className={classes.header}>
          <Grid item xs={12}>
            <Typography className={classes.sectionTitle}>
              Authorized Representative <span className={classes.normalText}>(Required)</span>
            </Typography>
            <Typography className={classes.tabSubTitle} paragraph>
              The Authorized Representative is an individual employed by Applicant who has the
              authority to bind Applicant. All legal notices from TIP will be sent to the Authorized
              Representative of record based on the contact information provided by the Applicant as
              set forth in the TIP General Participation Agreement.
            </Typography>
          </Grid>
        </Grid>
        {!editing ? (
          <Grid container item xs={12} lg={8} className={classes.signer}>
            {editSignerLoading ? (
              <Grid item xs>
                <Progress />
              </Grid>
            ) : (
              <>
                <Grid item xs>
                  <div className={classes.details}>
                    {signer ? (
                      <>
                        <Typography className={classes.signerName}>
                          {signer.firstname} {signer.lastname}
                        </Typography>
                        <Typography>{signer.email}</Typography>
                        <Typography>{signer.jobTitle}</Typography>
                      </>
                    ) : (
                      <Typography>No signer listed</Typography>
                    )}
                  </div>
                </Grid>
                {!isReadOnly && (
                  <Grid item xs="auto">
                    <RoundedButton
                      className={classes.editButton}
                      fullWidth={false}
                      onClick={setEditing}
                      color="primary"
                      variant="outlined"
                    >
                      Edit
                    </RoundedButton>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        ) : (
          !isReadOnly && (
            <Grid item xs={12} lg={8} className={classes.addForm}>
              <AuthorizedSignerInput
                users={users}
                gridWidth={{ xs: 12, sm: 6 }}
                name="authorizedSigner"
                label="Email address"
                title="Authorized Representative"
                subtitle="(Required for Sign Participation Agreement step)"
                onSave={handleSave}
                showCancel={authorizedSigners.length > 0}
                onCancel={obj => setEditing(false)}
                value={signer}
              />
            </Grid>
          )
        )}
      </Grid>
      <Grid container>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.header}>
          <Grid item xs={12}>
            <Typography className={classes.sectionTitle}>
              Legal Notice Designee <span className={classes.normalText}>(Optional)</span>
            </Typography>
            <Typography className={classes.tabSubTitle} paragraph>
              The Legal Notice Designee is an individual employed by Applicant, optionally
              designated as an additional individual who TIP will copy on all legal notices in
              addition to the Applicant’s Authorized Representative.
            </Typography>
          </Grid>
        </Grid>
        {legalDesignee && (
          <Grid item xs={12} lg={8} className={classes.signer}>
            <div className={classes.details}>
              <Typography className={classes.signerName}>
                {legalDesignee.firstname} {legalDesignee.lastname}
              </Typography>
              <Typography>{legalDesignee.email}</Typography>
              <Typography>{legalDesignee.jobTitle}</Typography>
            </div>
            {!isReadOnly && (
              <div className={classes.deleteIcon}>
                <Tooltip title="Delete Legal Notice Designee">
                  <IconButton onClick={() => handleDeleteLegal(legalDesignee)} aria-label="Delete">
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Grid>
        )}
        {!legalDesignee && !addingLegal && !isReadOnly && (
          <Grid item xs={12}>
            <Button style={{ marginTop: 20 }} color="primary" onClick={() => setAddingLegal(true)}>
              <Icon>add</Icon>Add
            </Button>
          </Grid>
        )}
        {addingLegal && !isReadOnly && (
          <Grid item xs={12} lg={8} className={classes.addForm}>
            <LegalDesigneeInput
              users={users}
              gridWidth={{ xs: 12, sm: 6 }}
              name="legalDesignee"
              label="Email address"
              title="Legal Notice Designee"
              subtitle="(Required for Sign Participation Agreement step)"
              onSave={handleSaveLegal}
              showCancel={legalDesignee}
              onCancel={obj => setAddingLegal(false)}
            />
          </Grid>
        )}
      </Grid>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  columnTitle: {
    fontWeight: 500
  },
  signerName: {
    fontWeight: 500
  },
  addForm: {
    border: `1px solid ${theme.palette.borderGray.main}`,
    margin: '20px 0px',
    padding: 20,
    borderRadius: 5
  },
  editButton: {
    fontSize: 12,
    height: 32
  },
  signer: {
    display: 'flex',
    color: theme.palette.gray.main,
    padding: 20,
    margin: '15px 0px',
    borderRadius: 3,
    backgroundColor: theme.palette.gray5.main,
    alignItems: 'center',
    border: '1px solid #D2D2D2',
    justifyContent: 'space-between'
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5
  },
  deleteIcon: {
    flex: '1 0 auto',
    textAlign: 'right'
  },
  divider: {
    width: '100%',
    marginTop: 15,
    marginBottom: 25
  },
  normalText: {
    fontWeight: 'normal'
  }
}))
