import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import { RoundedButton } from '../common'

const ResentOrgRequestModal = ({ isOpen, setOpen, handleSubmit }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
  }

  function handleClick() {
    handleSubmit()
    setOpen(false)
  }

  return (
    <Dialog
      className={classes.modalContainer}
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Are you sure?
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <div>
            <Typography>
              Are you sure you wish to send another request to your Account Administrator?
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <RoundedButton fullWidth={false} onClick={handleClick} variant="outlined">
          Yes
        </RoundedButton>
        <RoundedButton fullWidth={false} onClick={handleClose}>
          No
        </RoundedButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  modalContainer: {
    position: 'relative',
    top: '-150px'
  },
  bold: {
    fontWeight: 600
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  }
}))

export default ResentOrgRequestModal
