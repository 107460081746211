import React, { useEffect } from 'react'
import { loadCSS } from 'fg-loadcss'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import Icon from '@material-ui/core/Icon'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import OpenCircleIcon from '@material-ui/icons/RadioButtonUnchecked'
import LockIcon from '@material-ui/icons/Lock'
import Warning from '@material-ui/icons/Warning'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import GitHubIcon from '@material-ui/icons/GitHub'
import Tooltip from '@material-ui/core/Tooltip'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  red: {
    backgroundColor: theme.palette.deactivated.main
  },
  yellow: {
    backgroundColor: theme.palette.new.main
  },
  green: {
    backgroundColor: theme.palette.success.main
  },
  blue: {
    backgroundColor: theme.palette.graduated.main
  },
  gray: {
    backgroundColor: theme.palette.declined.main
  },
  salmon: {
    backgroundColor: theme.palette.invited.main
  },
  white: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D2D2D2'
  },
  orangeSeeThrough: {
    backgroundColor: '#FFF8F2',
    border: 'thin solid #F1C1B7',

    '& .MuiChip-icon': {
      color: '#E15D47'
    }
  }
}))

// icon support
function getIcon(icon) {
  switch (icon) {
    case 'checkCircle':
      return <CheckCircleIcon />
    case 'openCircle':
      return <OpenCircleIcon />
    case 'lock':
      return <LockIcon />
    case 'new':
      return <NewReleasesIcon />
    case 'unavailable':
      return <NotInterestedIcon />
    case 'warning':
      return <Warning />
    case 'github':
      return <GitHubIcon />
    case 'jira':
      return <Icon style={{ color: '#2785FF' }} className="fab fa-jira" />
    default:
      break
  }
}

export default function Status({ label, tooltip, icon, color, styles, custom }) {
  const classes = useStyles()

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )

    return () => {
      node.parentNode.removeChild(node)
    }
  }, [])

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top" enterTouchDelay={-300} leaveTouchDelay={2500}>
        <Chip
          icon={icon ? getIcon(icon) : null}
          label={label}
          size="small"
          className={clsx(classes[color], styles)}
        />
      </Tooltip>
    )
  } else {
    return (
      <Chip
        icon={icon ? getIcon(icon) : null}
        label={label}
        size="small"
        className={clsx(classes[color], styles)}
      />
    )
  }
}
