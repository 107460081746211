import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

import { ErrorSection, Progress, RoundedButton } from '../../common'

import { useSession, useUserOrganization } from '../../auth/queries'
import { REQUEST_PROJECT_GROUP_SUPPORT_MUST_VERIFY } from './mutations'
import { useMutation } from '../../hooks'
import { GET_ORGANIZATION_PROJECT_GROUPS } from '../../ProjectGroups'

const ActivateSupportMustVerifySubgroupModal = ({
  children,
  projectGroupId,
  projectGroupName,
  onSuccess
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { userId } = useSession()

  const {
    organization: { orgId }
  } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { execute, error, loading } = useMutation(REQUEST_PROJECT_GROUP_SUPPORT_MUST_VERIFY, {
    update: (cache, { data: { requestProjectGroupSupportMustVerify: { success } = {} } }) => {
      if (success) {
        setOpen(false)

        onSuccess()
      } else {
        setErrorMessage('Something went wrong.')
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: orgId }
      }
    ]
  })

  useEffect(() => {
    if (error && errorMessage !== 'Something went wrong.') {
      setErrorMessage('Something went wrong.')
    }
  }, [error, errorMessage])

  return (
    <>
      {children ? (
        children(() => setOpen(true))
      ) : (
        <RoundedButton
          className={classes.selectionButton}
          fullWidth={false}
          size="small"
          onClick={() => setOpen(true)}
        >
          Activate Group
        </RoundedButton>
      )}
      <Dialog
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle className={classes.titleRow}>
          Confirmation
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                To activate the <b>{projectGroupName}</b> subgroup, TIP must confirm your
                organization's participation in O-RAN Alliance. Click "Send Verification Request" to
                send a verification request to TIP for review.
              </Typography>
            </Grid>
            {errorMessage && (
              <Grid item xs={12} className={classes.errorSection}>
                <ErrorSection includeSupport>{errorMessage}</ErrorSection>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <RoundedButton
            className={clsx(classes.button, classes.cancelButton)}
            fullWidth={false}
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className={classes.button}
            disabled={loading}
            fullWidth={false}
            onClick={() => {
              execute({
                values: {
                  organizationId: orgId,
                  projectGroupId
                }
              })
            }}
          >
            {loading ? <Progress size={25} /> : 'Send Verification Request'}
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  actionButtons: {
    padding: 25,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  cancelButton: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      marginRight: 0
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  errorSection: {
    marginBottom: 20
  },
  selectionButton: {
    width: 200,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: 'auto'
    }
  },
  titleRow: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}))

export default ActivateSupportMustVerifySubgroupModal
