import React, { useRef, useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, Grid, Avatar, useMediaQuery } from '@material-ui/core'
import ReactCrop from 'react-image-crop'
import clsx from 'clsx'

import TIPDialog from '../common/TIPDialog'
import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'

const ImageCropperModal = ({ open, src, handleClose, handleUpload, aspect, allowPng }) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  let imageRef = useRef()
  const [loading, setLoading] = useState(false)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const [crop, setCrop] = useState({
    aspect: aspect || 1 / 1,
    unit: '%',
    width: 100,
    height: 100
  })

  let fileUrl
  let imageStyles = { maxWidth: '100%', maxHeight: 450, width: 'auto', height: 'auto' }
  if (fullScreen) {
    imageStyles = { maxWidth: '80vw', maxHeight: '80vh', width: 'auto', height: 'auto' }
  }

  useEffect(() => {
    setLoading(false)
    setCroppedImageUrl(null)
    imageRef.current = null
  }, [open])

  function onSubmit() {
    setLoading(true)
    handleUpload(croppedImageUrl)
  }

  // If you setState the crop in here you should return false.
  function onImageLoaded(image) {
    imageRef.current = image
  }

  function onCropComplete(crop) {
    makeClientCrop(crop)
  }

  function onCropChange(crop, percentCrop) {
    setCrop(percentCrop)
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = crop.width * scaleX
    canvas.height = crop.height * scaleY

    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            console.error('Canvas is empty')
            return
          }
          blob.name = fileName
          window.URL.revokeObjectURL(fileUrl)
          fileUrl = window.URL.createObjectURL(blob)
          resolve(fileUrl)
        },
        allowPng ? 'image/png' : 'image/jpeg',
        1
      )
    })
  }

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop, 'newFile.jpeg')

      setCroppedImageUrl(croppedImageUrl)
    }
  }

  return (
    <TIPDialog
      open={open}
      title="Image Preview"
      handleClose={handleClose}
      paperClass={classes.container}
      contentClass={classes.content}
    >
      <div>
        {src && (
          <Grid container justify="center">
            <Grid item xs={12}>
              <Typography paragraph variant="subtitle1" className={classes.directions}>
                To crop this image, drag the region below and then click "Save".
              </Typography>
            </Grid>
            {/* <Grid container className={classes.bodyContainer}> */}
            <Grid item xs={12} sm={8} className={classes.test}>
              <div className={classes.imageSelection}>
                <ReactCrop
                  className={clsx({ [classes.whiteBackground]: allowPng })}
                  src={src}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  imageStyle={imageStyles}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              className={clsx(
                classes.previewContainer,
                classes.avatarPreview,
                classes.previewBorder
              )}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Avatar
                    alt="Profile Preview"
                    src={croppedImageUrl}
                    className={classes.previewImage}
                  />
                  <div>
                    <Typography paragraph variant="subtitle1" className={classes.imageText}>
                      Profile Preview
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>{' '}
          </Grid>
          // </Grid>
        )}
      </div>
      <Grid container justify="flex-end">
        <Grid item xs={12} sm={4} className={classes.buttonContainer}>
          <RoundedButton
            fullWidth={false}
            onClick={() => handleClose(true)}
            className={classes.cancelButton}
            color="primary"
            variant="outlined"
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            onClick={onSubmit}
            disabled={!croppedImageUrl || loading}
            color="primary"
            fullWidth={false}
            className={classes.roundedButton}
          >
            {loading ? <Progress size={25} delay={0} /> : 'Save'}
          </RoundedButton>
        </Grid>
      </Grid>
    </TIPDialog>
  )
}
export default ImageCropperModal

const styles = makeStyles(theme => ({
  test: {
    maxWidth: 'fit-content'
  },
  container: {
    width: 1000
  },
  content: {
    textAlign: 'center',
    paddingBottom: 40
  },
  marginTop: {
    marginTop: 20
  },
  avatarPreview: {
    paddingLeft: 20,
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      marginTop: 40
    }
  },
  previewBorder: {
    borderTop: `thin solid #b3b3b3`,
    borderRight: `thin solid #b3b3b3`,
    borderBottom: `thin solid #b3b3b3`,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      borderTop: `none`,
      borderRight: `none`,
      borderBottom: `none`
    }
  },
  previewImage: {
    width: 150,
    height: 150,
    margin: 'auto',
    border: `thin solid #b3b3b3`
  },
  previewContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  buttonContainer: {
    marginTop: 40,
    justifyContent: 'flex-end',
    display: 'flex'
  },
  cancelButton: {
    marginRight: 15
  },
  whiteBackground: {
    background: 'white'
  },
  imageSelection: {
    textAlign: 'right',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '500px',
    width: 'auto',
    height: 'auto'
  },
  directions: {
    textAlign: 'left',
    marginBottom: 30
  },
  imageText: {
    marginTop: 10
  }
}))
