import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import config from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '70px 0 200px',
    background: `url(${
      config.MEDIA_CDN_DOMAIN
    }/media/glossies/glossy-header-large2x.png) bottom right no-repeat rgb(217, 217, 217)`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '70px 30px 200px',
      background: `url(${
        config.MEDIA_CDN_DOMAIN
      }/media/glossies/glossy-header-medium2x.png) bottom right no-repeat rgb(217, 217, 217)`,
      backgroundSize: 'cover'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '70px 30px 200px',
      background: `url(${
        config.MEDIA_CDN_DOMAIN
      }/media/glossies/glossy-header-small2x.png) bottom right no-repeat rgb(217, 217, 217)`,
      backgroundSize: 'cover'
    }
  },
  container: {
    color: '#FFF',
    maxWidth: '1140px'
  },
  heroTitle: {
    fontSize: 60,
    [theme.breakpoints.down('sm')]: {
      fontSize: 48
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 42,
      marginBottom: 15
    }
  },
  heroDescription: {
    fontSize: 24,
    maxWidth: 720,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  }
}))

const GlossiesHero = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid container item spacing={0} className={classes.container}>
        <Typography className={classes.heroTitle}>At a glance: TIP materials</Typography>
        <Typography className={classes.heroDescription}>
          Find and download all the latest summary information on TIP's exciting project groups and
          technologies.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default GlossiesHero
