import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { DropDownField, InputTextFieldNew, Progress, RoundedButton } from '../../common'
import { inputRegexes } from '../../utils'

import MembersTable from './MembersTable'
import { SYS_ADMIN_GET_MEMBERS } from './queries'
import { useSession } from '../../auth/queries'

export default function SysAdminManageMembers({ match, location }) {
  const [searchData, setSearchData] = useState({})
  const { isSystemAdmin, isReadOnlySystemAdmin } = useSession()

  const [loadUsers, { loading, data: { systemAdminSearchUsers = [] } = {} }] = useLazyQuery(
    SYS_ADMIN_GET_MEMBERS,
    {
      variables: { input: searchData }
    }
  )

  const classes = useStyles()
  const [formState, inputTypes] = useFormState({})
  const { enqueueSnackbar } = useSnackbar()

  let disableInfoSubmit = true
  let disableStatusSubmit = true

  useEffect(() => {
    // trigger the search if search data has changed
    if (Object.entries(searchData).length > 0) {
      loadUsers()
    }
  }, [searchData, loadUsers])

  const editMemberUpdate = (cache, { data: result }) => {
    const { systemAdminSearchUsers: users } = cache.readQuery({
      query: SYS_ADMIN_GET_MEMBERS,
      variables: { input: searchData }
    })

    let updatedUser
    let updatedUserIndex

    if (result.editUser) {
      // if profile editing
      const { firstname, lastname, id } = result.editUser
      updatedUserIndex = users.findIndex(u => u.id === id)

      updatedUser = {
        ...users[updatedUserIndex],
        firstname: firstname,
        lastname: lastname
      }
    } else if (result.uploadFile) {
      // if image editing
      const { name, id } = result.uploadFile
      updatedUserIndex = users.findIndex(u => u.id === id)

      updatedUser = {
        ...users[updatedUserIndex],
        logo: name
      }
    } else if (result.updateUserInOrganization) {
      // if image editing
      const { status, id } = result.updateUserInOrganization
      updatedUserIndex = users.findIndex(u => u.id === id)

      updatedUser = {
        ...users[updatedUserIndex],
        status: status
      }
    } else if (result.removeUserFromOrganization) {
      const { userId } = result.removeUserFromOrganization
      updatedUserIndex = users.findIndex(u => u.id === userId)
      updatedUser = {
        ...users[updatedUserIndex],
        orgId: null,
        status: 'registered'
      }

      // show alert for user removal
      enqueueSnackbar('User Removed From Organization', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }

    let newUsers = [...users]
    newUsers[updatedUserIndex] = { ...updatedUser }

    cache.writeQuery({
      query: SYS_ADMIN_GET_MEMBERS,
      data: { systemAdminSearchUsers: newUsers }
    })
  }

  if (
    (formState.values.email || formState.values.firstname || formState.values.lastname) &&
    (!formState.errors.email && !formState.errors.firstname && !formState.errors.lastname)
  ) {
    disableInfoSubmit = false
  }

  if (formState.values.status) {
    disableStatusSubmit = false
  }

  function search(type) {
    let data
    if (type === 'info') {
      data = {
        email: formState.values.email ? formState.values.email.trim() : '',
        firstname: formState.values.firstname ? formState.values.firstname.trim() : '',
        lastname: formState.values.lastname ? formState.values.lastname.trim() : ''
      }
    } else {
      data = {
        status: formState.values.status
      }
    }

    setSearchData(data)
  }

  const memberData = systemAdminSearchUsers

  return (
    <Grid container spacing={3} direction="row" justify="flex-start" alignItems="stretch">
      <Grid item xs={12}>
        <Typography variant="h4">Manage Participants</Typography>
      </Grid>
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="stretch" />
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.section, classes.borderRight)}>
        <Typography>
          Serach by firstname, lastname, and email (only one field is required to search)
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(userProfileFields).map(
            ([name, { gridWidth = { xs: 12 }, InputComponent, inputType = 'text', ...args }]) => (
              <Grid key={name} item {...gridWidth}>
                <InputComponent
                  {...args}
                  error={
                    typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                  }
                  errorHelperText={formState.errors[name]}
                  inputType={inputTypes[inputType]}
                  value={formState.values[name]}
                />
              </Grid>
            )
          )}
          <Grid container alignItems="flex-start">
            <Grid item className={classes.searchButton}>
              <RoundedButton
                disabled={loading || disableInfoSubmit}
                fullWidth={false}
                type="submit"
                onClick={() => search('info')}
              >
                {loading ? <Progress size={30} delay={0} /> : 'Search'}
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.section}>
        <Typography>OR search by Participant Status</Typography>
        <Grid container spacing={2}>
          <Grid item className={classes.dropdownContainer}>
            <DropDownField
              value={formState.values.status || ''}
              name="Status"
              label="Status"
              handleChange={e => formState.setField('status', e.target.value)}
              options={[`approved`, `deactivated`, `declined`, `requested`, `suspended`]}
            />
          </Grid>
          <Grid container alignItems="flex-start">
            <Grid item className={classes.searchButton}>
              <RoundedButton
                disabled={loading || disableStatusSubmit}
                fullWidth={false}
                type="submit"
                onClick={() => search('status')}
              >
                {loading ? <Progress size={30} delay={0} /> : 'Search'}
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.resultsContainer}>
        {loading ? (
          <Progress size={30} delay={0} />
        ) : (
          memberData.length > 0 && (
            <MembersTable
              data={memberData}
              onUpdate={editMemberUpdate}
              returnUrl={location.pathname}
              isSystemAdmin={isSystemAdmin}
              isReadOnly={isReadOnlySystemAdmin}
              searchData={searchData}
            />
          )
        )}
      </Grid>
    </Grid>
  )
}

const userProfileFields = {
  firstname: {
    gridWidth: { xs: 12, sm: 6 },
    InputComponent: InputTextFieldNew,
    label: 'First Name',
    name: 'firstname',
    validator: {
      minLength: 2,
      maxLength: 100
    }
  },
  lastName: {
    gridWidth: { xs: 12, sm: 6 },
    InputComponent: InputTextFieldNew,
    label: 'Last Name',
    name: 'lastname',
    validator: {
      minLength: 2
    }
  },
  email: {
    InputComponent: InputTextFieldNew,
    label: 'Email',
    name: 'email',
    validator: {
      maxLength: 100,
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email'
    }
  }
}

const useStyles = makeStyles(theme => ({
  borderRight: {
    borderRight: `1px solid ${theme.palette.borderGray.main}`,
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none'
    }
  },
  searchButton: {
    paddingTop: 20,
    paddingLeft: 10
  },
  section: {
    height: '100%'
  },
  resultsContainer: {
    marginTop: 50,
    borderTop: `1px solid ${theme.palette.navDarkGray.main}`
  },
  dropdownContainer: {
    width: '100%',
    marginTop: 16
  }
}))
