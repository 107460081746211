import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_ALL_PROJECT_GROUP_AGREEMENTS = gql`
  query getAllAgreements {
    agreements(type: "ProjectGroupAgreement") {
      id
      name
      type
    }
  }
`

const GET_ALL_PROJECT_GROUP_CHARTERS = gql`
  query getAllProjectGroupCharters {
    agreements(type: "ProjectGroupCharter") {
      id
      name
      type
    }
  }
`

const GET_ALL_AGREEMENTS = gql`
  query getAllAgreements {
    agreements {
      id
      name
      type
      version
    }
  }
`

function useProjectGroupAgreements(parameters) {
  const { loading, error, data } = useQuery(GET_ALL_PROJECT_GROUP_AGREEMENTS, parameters)

  return {
    loading,
    error,
    agreements: data && data.agreements
  }
}

function useProjectGroupCharters(parameters) {
  const { loading, error, data } = useQuery(GET_ALL_PROJECT_GROUP_CHARTERS, parameters)

  return {
    loading,
    error,
    charters: data && data.agreements
  }
}

function useAgreements(parameters) {
  const { loading, error, data } = useQuery(GET_ALL_AGREEMENTS, parameters)

  return {
    loading,
    error,
    agreements: data && data.agreements
  }
}

export { useProjectGroupAgreements, useProjectGroupCharters, useAgreements, GET_ALL_AGREEMENTS }
