import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import BorderLinearProgress from './BorderLinearProgress'
const styles = makeStyles(theme => ({
  margin: {
    marginLeft: theme.spacing(1)
  },
  completenessLabel: {
    marginLeft: theme.spacing(1),
    fontSize: '.9rem',
    fontWeight: 500
  }
}))

const PercentageComplete = ({ percentageComplete }) => {
  const classes = styles()

  return (
    <>
      <Typography variant="subtitle1" className={classes.completenessLabel}>
        Profile completeness: {percentageComplete}%
      </Typography>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={percentageComplete}
      />
    </>
  )
}

export default PercentageComplete
