import React, { useState, useEffect, useRef } from 'react'
import { useFormState } from 'react-use-form-state'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MaterialTable from 'material-table'
import { useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import gql from 'graphql-tag'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'

import EditOrganizationModal from '../Profile'
import EditUserModal from '../../User/Profile'
import { Progress } from '../../common'
import StatusDropdown from '../../common/table/StatusDropdown'
import { GET_ORGANIZATION_DATA } from './queries'
import { GET_MEMBERSHIP_LEVELS } from '../Profile/queries'
import { REMOVE_ORGANIZATION_ADMIN } from './mutations'
import {
  CHANGE_ORGANIZATION_STATUS,
  CHANGE_ORGANIZATION_PARTICIPATION_TIER,
  UPDATE_BILLING_EXEMPT_REASON
} from './mutations'
import { useQuery, useMutation } from '../../hooks'
import ConfirmStatusChangeModal from '../ManageMembers/ConfirmStatusChangeModal'
import BillingExemptModal from './BillingExemptModal'
import { useSession } from '../../auth/queries'
import ManageOrganizationsFilterFields from './ManageOrganizationsFilterFields'
import AnchorMenu from './AnchorMenu'
import ConfirmDeactivateOrgModal from './ConfirmDeactivateOrgModal'

const useStyles = makeStyles(theme => ({
  toolbarItem: {
    paddingTop: 15,
    margin: 'auto'
  },
  rightAlign: {
    textAlign: 'right'
  },
  alignMiddle: {
    margin: 'auto'
  },
  toolbar: {
    marginBottom: 40
  },
  filterIcon: {
    marginRight: 5
  },
  stats: {
    margin: '40px 5px'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  gray: {
    color: theme.palette.gray.main
  },
  progress: {
    textAlign: 'center',
    padding: 20
  },
  authorizedSigner: {
    cursor: 'pointer'
  },
  removeOrgButton: {
    fontSize: 12
  },
  orgNameStyle: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}))

export const approveSuspendedActions = { 0: `approved`, 1: `suspended`, 2: 'deactivated' }
export const pendingActions = { 0: `approved`, 3: `in progress`, 4: `declined`, 6: 'pre-approved' }
export const otherStatus = { 5: 'pending' }

const orgStatusLookup = {
  0: `approved`,
  1: `suspended`,
  2: 'deactivated',
  3: `in progress`,
  4: `declined`,
  5: `pending`,
  6: 'pre-approved'
}

const participationTierLookup = {
  0: 'full',
  1: 'associate',
  2: 'software'
}

const participationTierLookupBlank = {
  ...participationTierLookup,
  3: 'none'
}

function getStatusOptions(approvalStatus) {
  let options = null
  switch (approvalStatus) {
    case 'suspended':
    case 'approved':
      options = approveSuspendedActions
      break
    case 'in progress':
    case 'pre-approved':
      options = pendingActions
      break
    default:
      break
  }
  return options
}

export default function ManageOrganizations({ location }) {
  const { data: { membershipLevels = [] } = {} } = useQuery(GET_MEMBERSHIP_LEVELS)

  const { execute: handleUpdateExemptReason } = useMutation(UPDATE_BILLING_EXEMPT_REASON, {
    update: (
      cache,
      {
        data: {
          updateBillingExemptReason: { billingExempt, billingExemptReason, orgId, result } = {}
        } = {}
      }
    ) => {
      if (result) {
        cache.writeFragment({
          id: `Organization:${orgId}`,
          fragment: gql`
            fragment org on Organization {
              billingExempt
              billingExemptReason
            }
          `,
          data: {
            __typename: 'Organization',
            billingExempt,
            billingExemptReason
          }
        })
      }
    }
  })

  const billingExemptFields = {
    exemptReason: null
  }

  const [formState, { radio, text }] = useFormState(billingExemptFields)

  const {
    data: { editOrganizationStatus: { result } = {} } = {},
    execute: changeOrgApprovalStatusExecute,
    loading: changeOrgApprovalStatusLoading,
    error: changeOrgApprovalStatusError
  } = useMutation(CHANGE_ORGANIZATION_STATUS, {
    update: (
      cache,
      { data: { editOrganizationStatus: { result, id, status, showOnPublicSite } = {} } }
    ) => {
      if (result) {
        cache.writeFragment({
          id: `Organization:${id}`,
          fragment: gql`
            fragment org on Organization {
              approvalStatus
              showOnPublicSite
            }
          `,
          data: {
            __typename: 'Organization',
            approvalStatus: status,
            showOnPublicSite
          }
        })
      }
    }
  })

  const { execute: handleRemoveOrgAdmin } = useMutation(REMOVE_ORGANIZATION_ADMIN, {
    update: (
      cache,
      { data: { removeOrganizationAdmin: { approvalStatus, name, result, id } = {} } }
    ) => {
      if (result) {
        cache.writeFragment({
          id: `Organization:${id}`,
          fragment: gql`
            fragment org on Organization {
              approvalStatus
              name
              users
            }
          `,
          data: {
            __typename: 'Organization',
            approvalStatus,
            name,
            users: []
          }
        })

        enqueueSnackbar('Organization Deactivated', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    }
  })

  const [openBillingExempt, setOpenBillingExempt] = useState(false)
  const [billingExemptId, setBillingExemptId] = useState(null)
  const [billingExemptCurrent, setBillingExemptCurrent] = useState('')
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)
  const [deactivateData, setDeactivateData] = useState({})
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmData, setConfirmData] = useState({})

  const { isSystemAdmin, isReadOnlySystemAdmin } = useSession()

  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  let filterValues = useRef({})

  const [
    getOrgData,
    { data: { organizations: orgData = [] } = {}, error: orgDataError, loading: orgDataLoading }
  ] = useLazyQuery(GET_ORGANIZATION_DATA, {
    fetchPolicy: 'cache-and-network'
  })

  const editOrgLogoUpdate = (cache, { data: { uploadFile: { name, id } = {} } = {} } = {}) => {
    if (id) {
      cache.writeFragment({
        id: `Organization:${id}`,
        fragment: gql`
          fragment org on Organization {
            logo
          }
        `,
        data: {
          __typename: 'Organization',
          logo: name
        }
      })
    }
  }

  const {
    loading: editOrgParticipationTierLoading,
    execute: editOrgParticipationTierExecute
  } = useMutation(CHANGE_ORGANIZATION_PARTICIPATION_TIER, {
    onCompleted: ({ editOrganizationParticipationTier: { result } = {} } = {}) => {
      if (result) {
        enqueueSnackbar('Participation Tier Updated', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }

      setConfirmData({})
    },
    update: (
      cache,
      {
        data: {
          editOrganizationParticipationTier: { result, id, membership, membershipLevel } = {}
        }
      }
    ) => {
      if (result) {
        cache.writeQuery({
          query: gql`
            query($id: ID) {
              organization(id: $id) {
                id
                showOnPublicSite
                membership {
                  membershipLevel(id: $id) {
                    id
                    name
                    price
                    atlassianAccountsAllowed
                    canAccessRFI
                    canAccessTSS
                    canAccessProjectGroups
                    canAccessSoftwareGroups
                  }
                }
              }
            }
          `,
          data: {
            organization: {
              __typename: 'Organization',
              id,
              showOnPublicSite: false,
              membership: {
                __typename: 'OrganizationMembership',
                membershipLevel
              }
            }
          },
          variables: {
            id
          }
        })

        cache.writeFragment({
          id: `Organization:${id}`,
          fragment: gql`
            fragment org on Organization {
              membership {
                membershipLevelId
                paid
                renewalDate
                startDate
                status
              }
            }
          `,
          data: {
            __typename: 'Organization',
            membership
          }
        })
      }
    }
  })

  useEffect(() => {
    // on success show snack bar
    if (result && !changeOrgApprovalStatusError && !changeOrgApprovalStatusLoading) {
      // close modals
      enqueueSnackbar('Status Updated', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [result, changeOrgApprovalStatusError, changeOrgApprovalStatusLoading, enqueueSnackbar])

  if (orgDataError) {
    return (
      <Typography role="alert" color="error">
        Error: {orgDataError.message}
      </Typography>
    )
  }

  const columnDef = [
    {
      title: 'Name',
      searchable: true,
      defaultSort: 'asc',
      field: 'name',
      defaultFilter: filterValues.current.name || '',
      render: rowData => {
        return (
          <EditOrganizationModal
            returnUrl={location.pathname}
            id={rowData.id}
            onUpdate={editOrgLogoUpdate}
          >
            {onClick => (
              <Typography className={classes.orgNameStyle} variant="body1" onClick={onClick}>
                {rowData.name}
              </Typography>
            )}
          </EditOrganizationModal>
        )
      },
      customSort: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    },
    {
      title: 'Join Date',
      customFilterAndSearch: (value, rowData) => {
        if (!value) {
          return true
        }

        if (!rowData.joinDate) {
          return false
        }

        return new Date(value).toDateString() === new Date(rowData.joinDate).toDateString()
      },
      filterComponent: props => (
        <DatePicker
          clearable
          format="MM/dd/yyyy"
          value={props.columnDef.tableData.filterValue}
          onChange={value => props.onFilterChanged(props.columnDef.tableData.id, value)}
        />
      ),
      field: 'joinDate',
      type: 'date',
      defaultFilter: filterValues.current.joinDate || null,
      render: rowData => (rowData.joinDate ? format(rowData.joinDate, 'MM/dd/yyyy') : null)
    },
    {
      title: 'Renewal Date',
      customFilterAndSearch: (value, rowData) => {
        if (!value) {
          return true
        }

        if (!rowData.renewalDate) {
          return false
        }

        return new Date(value).toDateString() === new Date(rowData.renewalDate).toDateString()
      },
      filterComponent: props => (
        <DatePicker
          clearable
          format="MM/dd/yyyy"
          value={props.columnDef.tableData.filterValue}
          onChange={value => props.onFilterChanged(props.columnDef.tableData.id, value)}
        />
      ),
      field: 'renewalDate',
      type: 'date',
      defaultFilter: filterValues.current.renewalDate || null,
      render: rowData => (rowData.renewalDate ? format(rowData.renewalDate, 'MM/dd/yyyy') : null)
    },
    {
      title: 'Tier',
      field: 'membershipLevelValue',
      defaultFilter: filterValues.current['membershipLevelValue'] || '',
      lookup: participationTierLookupBlank,
      searchable: false,
      render: rowData =>
        rowData.id === confirmData.orgId && editOrgParticipationTierLoading ? (
          <Progress />
        ) : (
          <StatusDropdown
            name={rowData.membershipLevelLabel}
            options={
              isSystemAdmin
                ? rowData.membershipLevelValue === '3'
                  ? participationTierLookupBlank
                  : participationTierLookup
                : null
            }
            value={rowData.membershipLevelValue}
            onChange={e => {
              if (
                e.target.value !== '3' &&
                rowData.membershipLevelLabel !== participationTierLookup[e.target.value]
              ) {
                setConfirmData({
                  statusChange: false,
                  to: participationTierLookup[e.target.value],
                  from: rowData.membershipLevelLabel,
                  name: rowData.name,
                  orgId: rowData.id
                })

                setConfirmOpen(true)
              }
            }}
          />
        )
    },
    {
      title: 'Org Admin',
      searchable: true,
      defaultSort: 'asc',
      field: 'orgAdmin',
      defaultFilter: filterValues.current.orgAdmin || '',
      render: rowData => {
        return rowData.organizationRoles.organizationAdmin ? (
          <EditUserModal id={rowData.organizationRoles.organizationAdmin.id} orgId={rowData.id}>
            {onClick => (
              <Typography className={classes.orgNameStyle} variant="body1" onClick={onClick}>
                {`${rowData.organizationRoles.organizationAdmin.firstname} ${
                  rowData.organizationRoles.organizationAdmin.lastname
                }`.trim()}
              </Typography>
            )}
          </EditUserModal>
        ) : (
          <Typography>No Org Admin</Typography>
        )
      },
      customSort: (a, b) => a.orgAdmin.toLowerCase().localeCompare(b.orgAdmin.toLowerCase())
    },
    {
      title: 'Auth Rep',
      field: 'authorizedSigner.firstname',
      defaultFilter: filterValues.current['authorizedSigner.firstname'] || '',
      customSort: (a, b) => {
        if (
          !(
            a.organizationRoles &&
            a.organizationRoles.authorizedSigners &&
            a.organizationRoles.authorizedSigners.length > 0
          )
        ) {
          return 1
        }

        if (
          !(
            b.organizationRoles &&
            b.organizationRoles.authorizedSigners &&
            b.organizationRoles.authorizedSigners.length > 0
          )
        ) {
          return -1
        }

        const aSigner = a.organizationRoles.authorizedSigners[0].user
          ? a.organizationRoles.authorizedSigners[0].user
          : a.organizationRoles.authorizedSigners[0]
        const bSigner = b.organizationRoles.authorizedSigners[0].user
          ? b.organizationRoles.authorizedSigners[0].user
          : b.organizationRoles.authorizedSigners[0]

        return `${aSigner.firstname} ${aSigner.lastname}`
          .toLowerCase()
          .localeCompare(`${bSigner.firstname} ${bSigner.lastname}`.toLowerCase())
      },
      customFilterAndSearch: (value, rowData) => {
        if (
          !rowData.organizationRoles ||
          (rowData.organizationRoles.authorizedSigners &&
            rowData.organizationRoles.authorizedSigners.length === 0)
        ) {
          return false
        }

        const signer = rowData.organizationRoles.authorizedSigners[0]
        const firstSigner = signer.user ? signer.user : signer

        if ((!firstSigner.firstname && !firstSigner.lastname) || !value) {
          return false
        }

        const valueSplit = value
          .replace(/\s\s+/g, ' ')
          .trim()
          .split(' ')

        if (valueSplit.length === 1) {
          return (
            firstSigner.firstname.toLowerCase().includes(valueSplit[0].toLowerCase()) ||
            firstSigner.lastname.toLowerCase().includes(valueSplit[0].toLowerCase())
          )
        }

        if (valueSplit.length === 2) {
          return (
            firstSigner.firstname.toLowerCase() === valueSplit[0].toLowerCase() &&
            firstSigner.lastname.toLowerCase().includes(valueSplit[1].toLowerCase())
          )
        }

        return false
      },
      render: rowData => {
        if (
          rowData.organizationRoles.authorizedSigners &&
          rowData.organizationRoles.authorizedSigners.length > 0
        ) {
          const signer = rowData.organizationRoles.authorizedSigners[0]

          if (signer.user || rowData.authRepId) {
            return (
              <EditUserModal
                id={signer.user ? signer.user.id : rowData.authRepId}
                orgId={rowData.id}
              >
                {onClick => (
                  <Typography className={classes.orgNameStyle} variant="body1" onClick={onClick}>
                    {signer.user
                      ? `${signer.user.firstname} ${signer.user.lastname}`.trim()
                      : `${signer.firstname} ${signer.lastname}`.trim()}
                  </Typography>
                )}
              </EditUserModal>
            )
          } else {
            return (
              <Typography variant="body1">
                {`${signer.firstname} ${signer.lastname}`.trim()}
              </Typography>
            )
          }
        } else {
          return ''
        }
      }
    },
    {
      title: 'Status',
      field: 'approvalStatusValue',
      defaultFilter: filterValues.current.approvalStatusValue || '',
      lookup: orgStatusLookup,
      searchable: false,
      render: rowData => {
        return (
          <StatusDropdown
            value={rowData.approvalStatusValue}
            name={rowData.approvalStatus}
            options={isSystemAdmin ? getStatusOptions(rowData.approvalStatus) : null}
            onChange={e => {
              if (rowData.approvalStatus !== orgStatusLookup[e.target.value]) {
                setConfirmData({
                  statusChange: true,
                  to: orgStatusLookup[e.target.value],
                  from: rowData.approvalStatus,
                  name: `${rowData.name}`,
                  orgId: rowData.id
                })

                setConfirmOpen(true)
              }
            }}
          />
        )
      }
    },
    {
      title: 'Actions',
      hidden: isReadOnlySystemAdmin,
      render: rowData => {
        let software = false

        if (rowData.membershipLevelValue === '2') {
          software = true
        }

        return (
          <div>
            <AnchorMenu
              formState={formState}
              setBillingExemptId={setBillingExemptId}
              setBillingExemptCurrent={setBillingExemptCurrent}
              setOpenBillingExempt={setOpenBillingExempt}
              setDeactivateData={setDeactivateData}
              setDeactivateModalOpen={setDeactivateModalOpen}
              handleRemoveOrgAdmin={handleRemoveOrgAdmin}
              isSystemAdmin={isSystemAdmin}
              isReadOnlySystemAdmin={isReadOnlySystemAdmin}
              rowData={rowData}
              software={software}
            />
          </div>
        )
      }
    }
  ]

  return (
    <>
      <BillingExemptModal
        billingExemptCurrent={billingExemptCurrent}
        formState={formState}
        radio={radio}
        text={text}
        handleUpdateExemptReason={handleUpdateExemptReason}
        open={openBillingExempt}
        orgId={billingExemptId}
        setBillingExemptCurrent={setBillingExemptCurrent}
        setOpen={setOpenBillingExempt}
      />
      <ConfirmDeactivateOrgModal
        deactivateData={deactivateData}
        open={deactivateModalOpen}
        handleConfirm={handleRemoveOrgAdmin}
        handleClose={() => {
          setDeactivateModalOpen(false)
          setDeactivateData({})
        }}
      />

      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Organizations
        </Typography>
      </Grid>
      <Grid item className={classes.toolbar} xs={12} md={6}>
        <ManageOrganizationsFilterFields
          getOrgData={getOrgData}
          getOrgDataLoading={orgDataLoading}
          orgStatusLookup={orgStatusLookup}
          participationTierLookup={participationTierLookupBlank}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MaterialTable
            columns={columnDef}
            components={{
              Toolbar: props => {
                props.columns.forEach(c => {
                  const field = c.field
                  const filterValue = c.tableData.filterValue

                  if (filterValue) {
                    filterValues.current[field] = filterValue
                  } else if (filterValues.current[field]) {
                    filterValues.current[field] = null
                  }
                })

                return null
              }
            }}
            data={orgData.map((obj, index) => {
              const approvalStatusValue = Object.keys(orgStatusLookup).find(
                key => orgStatusLookup[key] === obj.approvalStatus
              )

              let renewalDate = null

              if (obj.membership.renewalDate) {
                renewalDate = obj.membership.renewalDate
              }

              let membershipLevelLabel = 'none'
              let membershipLevelValue = '3'

              if (membershipLevels && membershipLevels.length > 0) {
                const membershipLevel = membershipLevels.find(
                  m => m.id === obj.membership.membershipLevelId
                )

                if (membershipLevel) {
                  const membershipLevelName = membershipLevel.name
                    .substring(0, membershipLevel.name.indexOf(' '))
                    .toLowerCase()

                  membershipLevelLabel = membershipLevelName
                  membershipLevelValue = Object.keys(participationTierLookup).find(
                    key => participationTierLookup[key] === membershipLevelName
                  )
                }
              }

              return {
                ...obj,
                approvalStatusValue,
                membershipLevelLabel,
                membershipLevelValue,
                renewalDate,
                orgAdmin: obj.organizationRoles.organizationAdmin
                  ? `${obj.organizationRoles.organizationAdmin.firstname} ${
                      obj.organizationRoles.organizationAdmin.lastname
                    }`.trim()
                  : 'No Org Admin'
              }
            })}
            isLoading={orgDataLoading}
            options={{
              filterCellStyle: {
                verticalAlign: 'bottom'
              },
              filtering: true,
              pageSize: 10,
              // selection: true, // checkboxes in design but no actions so removing
              showTitle: false,
              draggable: false
            }}
            title="Manage Organizations"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <ConfirmStatusChangeModal
        data={confirmData}
        open={confirmOpen}
        handleConfirm={() => {
          if (confirmData.statusChange) {
            changeOrgApprovalStatusExecute({
              id: confirmData.orgId,
              values: {
                status: confirmData.to,
                beforeStatus: confirmData.from
              }
            })
          } else {
            let membershipLevel

            if (membershipLevels && membershipLevels.length > 0) {
              membershipLevel = membershipLevels.find(m =>
                m.name.toLowerCase().includes(confirmData.to)
              )
            }

            editOrgParticipationTierExecute({
              id: confirmData.orgId,
              values: {
                tierId: membershipLevel ? membershipLevel.id : null
              }
            })
          }

          setConfirmData({ orgId: confirmData.orgId })
          setConfirmOpen(false)
        }}
        handleClose={() => {
          setConfirmData({ orgId: confirmData.orgId })
          setConfirmOpen(false)
        }}
      />
    </>
  )
}
