import gql from 'graphql-tag'

export const GET_ALL_PROJECT_GROUPS = gql`
  query {
    projectGroups(includeAll: true) {
      id
      agreement {
        id
        name
      }
      atlassianGroup
      charterTitle
      charterAgreement {
        id
        name
      }
      chairs {
        firstname
        lastname
        email
        company
      }
      createdDate
      description
      detailLink
      githubTeam
      graduated
      jira
      joinedOrganizations
      name
      new
      parentProjectGroupId
      restricted
      sfId
      softwareProjectGroup
      status
      supportMustVerify
      openToMembers
      updatedBy
      updatedDate
      workplaceId
      approvedOrgs
      slackTeamId
    }
  }
`

export const GET_ALL_VISIBLE_PROJECT_GROUPS = gql`
  query($id: String!) {
    visibleProjectGroups(includeAll: true, orgId: $id) {
      id
      agreement {
        id
        name
      }
      atlassianGroup
      charterTitle
      charterAgreement {
        id
        name
      }
      chairs {
        firstname
        lastname
        email
        company
      }
      createdDate
      description
      detailLink
      githubTeam
      graduated
      jira
      joinedOrganizations
      name
      new
      parentProjectGroupId
      restricted
      sfId
      softwareProjectGroup
      status
      supportMustVerify
      openToMembers
      updatedBy
      updatedDate
      workplaceId
      approvedOrgs
    }
  }
`

export const GET_ALL_ACTIVE_PROJECT_GROUPS = gql`
  query {
    projectGroups {
      id
      atlassianGroup
      charterTitle
      createdDate
      description
      detailLink
      githubTeam
      graduated
      jira
      joinedOrganizations
      name
      new
      parentProjectGroupId
      restricted
      sfId
      softwareProjectGroup
      status
      supportMustVerify
      openToMembers
      updatedBy
      updatedDate
      workplaceId
      approvedOrgs
    }
  }
`

export const GET_PARENT_PROJECT_GROUPS = gql`
  query getParentPgs {
    projectGroups(parentsOnly: true) {
      id
      atlassianGroup
      charterTitle
      charterAgreement {
        id
        name
        fileName
      }
      createdDate
      description
      detailLink
      githubTeam
      graduated
      jira
      joinedOrganizations
      name
      new
      restricted
      sfId
      softwareProjectGroup
      status
      workplaceId
      approvedOrgs
      subProjectGroups {
        id
        agreement {
          id
          name
          fileName
        }
        atlassianGroup
        charterTitle
        createdDate
        description
        detailLink
        githubTeam
        graduated
        jira
        joinedOrganizations
        name
        new
        parentProjectGroupId
        restricted
        sfId
        softwareProjectGroup
        status
        supportMustVerify
        openToMembers
        workplaceId
      }
    }
  }
`

export const GET_VISIBLE_PARENT_PROJECT_GROUPS = gql`
  query getParentPgs($id: String!) {
    visibleProjectGroups(parentsOnly: true, orgId: $id) {
      id
      atlassianGroup
      charterTitle
      charterAgreement {
        id
        name
        fileName
      }
      createdDate
      description
      detailLink
      githubTeam
      graduated
      jira
      joinedOrganizations
      name
      new
      restricted
      sfId
      softwareProjectGroup
      status
      workplaceId
      approvedOrgs
      subProjectGroups(orgId: $id) {
        id
        agreement {
          id
          name
          fileName
        }
        atlassianGroup
        charterTitle
        createdDate
        description
        detailLink
        githubTeam
        graduated
        jira
        joinedOrganizations
        name
        new
        parentProjectGroupId
        restricted
        sfId
        softwareProjectGroup
        status
        supportMustVerify
        openToMembers
        workplaceId
      }
    }
  }
`

export const GET_PROJECT_GROUP = gql`
  query($id: ID!) {
    projectGroups(id: $id) {
      id
      sfId
      workplaceId
      status
      name
      description
      detailLink
      charterTitle
      createdDate
      updatedBy
      updatedDate
      jira
      joinedOrganizations
      graduated
      new
      restricted
      supportMustVerify
      openToMembers
      parentProjectGroupId
      approvedOrgs
      softwareProjectGroup
    }
  }
`

export const GET_ORGANIZATION_PROJECT_GROUPS = gql`
  query($id: ID!) {
    findProjectGroupsByOrganizationId(id: $id) {
      projectGroupId
      joinedDate
      approvalStatus
      pending
    }
  }
`

export const GET_USER_PROJECT_GROUPS = gql`
  query($id: ID!) {
    findProjectGroupsByUserId(id: $id) {
      projectGroupId
      joinedDate
      approvalStatus
    }
  }
`

export const GET_ORGANIZATION_PG_USERS = gql`
  query($input: OrgProjectGroupInput) {
    getOrganizationProjectGroupUsers(input: $input) {
      count
    }
  }
`
