import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import clsx from 'clsx'

import { useMutation, useFormStyles } from '../hooks'

export const RESEND_REGISTER_EMAIL = gql`
  mutation($input: UserRegisterInput!) {
    resendRegisterEmail(input: $input) {
      data
      result
    }
  }
`

export default function CheckYourInbox({ email, setEmail, setEmailResent }) {
  const { loading, execute: resendEmail, data: registerUserResult } = useMutation(
    RESEND_REGISTER_EMAIL
  )
  const formClasses = useFormStyles()
  const classes = useStyles()

  function sendEmail() {
    resendEmail({ values: { email: email } })
  }

  useEffect(() => {
    const sentEmail =
      registerUserResult &&
      registerUserResult.resendRegisterEmail &&
      registerUserResult.resendRegisterEmail.result

    if (!loading && sentEmail) {
      setEmailResent(true)
    }
  }, [registerUserResult, loading, setEmailResent])

  return (
    <>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Check Your Inbox
        </Typography>
        <Typography className={formClasses.description}>
          A verification email has been sent to the address you provided.
        </Typography>
        <Typography className={formClasses.paragraphBold}>
          Be sure to check your junk or spam folder.
        </Typography>
      </div>
      <div className={formClasses.divider}>
        <Divider variant="middle" classes={{ root: classes.divider }} />
      </div>
      <Typography paragraph className={formClasses.paragraphBold}>
        Haven't received the email yet?
      </Typography>
      <Button
        data-testid="ResendEmailBtn"
        color="primary"
        className={classes.button}
        onClick={sendEmail}
      >
        Resend verification email
      </Button>
      <Button color="primary" className={classes.button} onClick={setEmail}>
        Use a different email address
      </Button>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'unset',
    display: 'block',
    margin: 'auto'
  },
  divider: {
    backgroundColor: theme.palette.gray7.main
  }
}))
