import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import config from '../../../config'

export default function RecipientInfo({ email }) {
  const classes = useStyles()

  return (
    <Grid container className={classes.section}>
      <Typography className={clsx(classes.message, classes.messageInfo)}>
        The request will be sent from Docusign to:
      </Typography>
      <Typography className={classes.message}>{email}</Typography>
      <img
        alt="DocuSign Logo"
        className={classes.docusignLogo}
        src={`${config.MEDIA_CDN_DOMAIN}/media/docusign_new.png`}
      />
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'block',
    textAlign: 'left'
  },
  message: {
    fontSize: 14
  },
  messageInfo: {
    marginBottom: 5
  },
  docusignLogo: {
    marginTop: 15,
    marginBottom: 5,
    width: 90,
    height: 'auto'
  }
}))
