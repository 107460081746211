import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import {
  ErrorSection,
  InputTextFieldNew,
  LinkField,
  Progress,
  RoundedButton
} from '../../../../common'
import { useMutation } from '../../../../hooks'
import { GITHUB_LINK_ACCOUNT, GITHUB_UNLINK_ACCOUNT } from '../../mutations'
import config from '../../../../config'

export default function Github({ user, isReadOnly = false, tabLoopStart }) {
  const { id: userId, githubId } = user
  const classes = useStyles()
  const [formState, inputTypes] = useFormState({ githubId: user.githubId || '' })
  const [githubAccountLinked, setGithubAccountLinked] = useState(!!githubId)
  const [error, setError] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (user.githubId && formState.values.githubId !== user.githubId) {
      formState.setField('githubId', user.githubId)
    }
  }, [formState, user.githubId])

  const { execute: githubLinkAccount, loading: githubLinkAccountLoading } = useMutation(
    GITHUB_LINK_ACCOUNT,
    {
      onCompleted: data => {
        if (data.linkGithubAccount && data.linkGithubAccount.result) {
          enqueueSnackbar('Github invitation sent to your email', {
            variant: 'success',
            autoHideDuration: 4500
          })

          setGithubAccountLinked(true)
        } else {
          setError('Unable to link Github account')
        }
      }
    }
  )

  const { execute: githubUnLinkAccount, loading: githubUnLinkAccountLoading } = useMutation(
    GITHUB_UNLINK_ACCOUNT,
    {
      onCompleted: data => {
        if (data.unlinkGithubAccount && data.unlinkGithubAccount.result) {
          enqueueSnackbar('Github account unlinked', {
            variant: 'success',
            autoHideDuration: 4500
          })
          setGithubAccountLinked(false)
        }

        // do we want same message or different message if unlinked from TIP while already unlinked from Github UI
        if (!data.unlinkGithubAccount) {
          enqueueSnackbar('Github account already unlinked through GitHub', {
            variant: 'success',
            autoHideDuration: 4500
          })
          setGithubAccountLinked(false)
        }
      }
    }
  )

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12}>
        <Typography className={classes.title}>Github Connection</Typography>
        <Typography paragraph>Enter your Github username below to link your account.</Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.inputField}>
        <InputTextFieldNew
          disabled={githubAccountLinked || isReadOnly}
          error={typeof formState.validity.githubId !== 'undefined' && !formState.validity.githubId}
          errorHelperText={formState.errors.githubId}
          inputRef={tabLoopStart}
          inputType={inputTypes.text}
          label="Github Username"
          margin="none"
          name="githubId"
          value={formState.values.githubId}
        />
      </Grid>
      {!isReadOnly && (
        <Grid item xs={12} sm={6}>
          {githubAccountLinked ? (
            <RoundedButton
              className={classes.button}
              fullWidth={false}
              color="primary"
              variant="outlined"
              onClick={() => {
                setError(null)
                githubUnLinkAccount({
                  id: userId
                })
              }}
              disabled={githubUnLinkAccountLoading}
            >
              UnLink Github Account
            </RoundedButton>
          ) : (
            <RoundedButton
              className={classes.button}
              fullWidth={false}
              color="primary"
              variant="outlined"
              onClick={() => {
                setError(null)
                githubLinkAccount({
                  id: userId,
                  values: {
                    githubId: formState.values.githubId
                  }
                })
              }}
              disabled={githubLinkAccountLoading || !formState.values.githubId}
            >
              Link Github Account
            </RoundedButton>
          )}
          {(githubLinkAccountLoading || githubUnLinkAccountLoading) && (
            <Progress size={20} delay={0} />
          )}
        </Grid>
      )}
      {githubAccountLinked && (
        <Grid item xs={12} className={classes.linkSection}>
          <Typography className={classes.message}>Your account has been connected: </Typography>
          <LinkField
            color="primary"
            label="Go To Github"
            target="_blank"
            rel="noopener noreferrer"
            to={config.GITHUB_URL}
          />
        </Grid>
      )}

      {error && (
        <Grid item xs={12} className={classes.errorSection}>
          <ErrorSection includeSupport>{error}</ErrorSection>
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: 5,
    fontWeight: 600
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  errorSection: {
    marginTop: 20
  },
  inputField: {
    marginRight: 10
  },
  linkSection: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  message: {
    marginRight: 5
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  }
}))
