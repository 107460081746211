import React, { forwardRef } from 'react'

import {
  CountryDropdown,
  InputTextFieldNew,
  PhoneNumberInput,
  PlacesAutocomplete
} from '../../common'
import { inputRegexes } from '../../utils'

const formFields = (formState, tabLoopStart, tabLoopEnd) =>
  formState
    ? {
        firstname: {
          autoComplete: 'given-name',
          autoFocus: true,
          gridWidth: { xs: 12, sm: 6 },
          InputComponent: tabLoopStart ? TextFieldForwardRef : InputTextFieldNew,
          inputType: 'text',
          label: 'First Name',
          name: 'firstname',
          ref: tabLoopStart,
          validator: {
            required: true,
            minLength: 2,
            maxLength: 100
          }
        },
        lastname: {
          autoComplete: 'family-name',
          gridWidth: { xs: 12, sm: 6 },
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'Last Name',
          name: 'lastname',
          validator: {
            required: true,
            minLength: 2,
            maxLength: 100
          }
        },
        jobTitle: {
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'Work Title',
          name: 'jobTitle',
          validator: {
            required: true,
            minLength: 2,
            maxLength: 100
          }
        },
        address1: {
          autoComplete: 'address-line1',
          currentCity: formState.values.city,
          currentCountry: formState.values.country,
          currentPostalCode: formState.values.postalCode,
          currentState: formState.values.state,
          includePhone: true,
          InputComponent: PlacesAutocomplete,
          inputType: 'text',
          label: 'Street Address',
          name: 'address1',
          setField: formState.setField,
          setFieldError: formState.setFieldError
        },
        address2: {
          autoComplete: 'address-line2',
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'Apartment / Suite # (optional)',
          name: 'address2',
          validator: {}
        },
        country: {
          autoComplete: 'country',
          includePhone: true,
          InputComponent: CountryDropdown,
          inputType: 'text',
          label: 'Country',
          name: 'country',
          setField: formState.setField,
          setFieldError: formState.setFieldError
        },
        postalCode: {
          autoComplete: 'postal-code',
          gridWidth: { xs: 12, sm: 6 },
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'ZIP / Postal code',
          name: 'postalCode',
          validator: {
            required: true
          }
        },
        state: {
          autoComplete: 'address-level1',
          gridWidth: { xs: 12, sm: 6 },
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'State / Province',
          name: 'state',
          validator: {
            required: true
          }
        },
        city: {
          autoComplete: 'address-level2',
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'City',
          name: 'city',
          validator: {
            required: true
          }
        },
        phoneNumber: {
          autoComplete: 'tel',
          currentCountry: formState.values.country,
          InputComponent: tabLoopEnd ? PhoneForwardRef : PhoneNumberInput,
          inputType: 'text',
          label: 'Phone Number',
          name: 'phoneNumber',
          ref: tabLoopEnd,
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true,
            regex: inputRegexes.phone,
            regexMessage: 'Must be a valid phone number'
          }
        }
      }
    : {
        firstname: {
          validator: {
            required: true
          }
        },
        lastname: {
          validator: {
            required: true
          }
        },
        jobTitle: {
          validator: {
            required: true
          }
        },
        address1: {
          validator: {
            required: true
          }
        },
        address2: {},
        country: {
          validator: {
            required: true
          }
        },
        postalCode: {
          validator: {
            required: true
          }
        },
        state: {
          validator: {
            required: true
          }
        },
        city: {
          validator: {
            required: true
          }
        },
        phoneNumber: {
          validator: {
            required: true
          }
        }
      }

const TextFieldForwardRef = forwardRef((props, ref) => (
  <InputTextFieldNew {...props} inputRef={ref} />
))
const PhoneForwardRef = forwardRef((props, ref) => <PhoneNumberInput {...props} inputRef={ref} />)

export default formFields
