import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { ErrorSection, LinkField, Progress, RoundedButton } from '../../../../common'
import { useMutation, useQuery } from '../../../../hooks'
import { ATLASSIAN_LINK_ACCOUNT } from '../../mutations'
import { GET_ATLASSIAN_PROFILE } from '../../queries'
import config from '../../../../config'

export default function Atlassian({ user, isReadOnly = false }) {
  const { id: userId, atlassianId, email } = user
  const classes = useStyles()
  const [atlassianAccountLinked, setAtlassianAccountLinked] = useState(!!atlassianId)
  const [error, setError] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { getAtlassianProfile }
  } = useQuery(GET_ATLASSIAN_PROFILE, {
    variables: {
      input: {
        atlassianId
      }
    }
  })
  const { execute: atlassianProvisionAccount, loading: atlassianLinkAccountLoading } = useMutation(
    ATLASSIAN_LINK_ACCOUNT,
    {
      onCompleted: data => {
        if (data.atlassianProvisionAccount && data.atlassianProvisionAccount.result) {
          enqueueSnackbar('Atlassian account linked', {
            variant: 'success',
            autoHideDuration: 4500
          })

          setAtlassianAccountLinked(true)
        } else {
          setError('Unable to link Atlassian account')
        }
      }
    }
  )

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12}>
        <Typography paragraph className={classes.title}>
          Atlassian Connection
        </Typography>
        {atlassianAccountLinked && (
          <>
            <Typography paragraph>
              <LinkField
                className={classes.link}
                color="primary"
                label="Go To Atlassian"
                target="_blank"
                rel="noopener noreferrer"
                to={config.ATLASSIAN_URL}
              />
            </Typography>
            <Typography paragraph>
              You will log into Atlassian with your TIP account:
              <br />
              <span className={classes.gray}>{email}</span>
            </Typography>
            {getAtlassianProfile && (
              <Typography>
                Your Atlassian email alias is:
                <br />
                <span className={classes.gray}>{getAtlassianProfile.username}</span>
              </Typography>
            )}
          </>
        )}
      </Grid>
      {!isReadOnly && !atlassianAccountLinked && (
        <Grid item xs={12} sm={6}>
          <div className={classes.align}>
            <RoundedButton
              className={classes.button}
              fullWidth={false}
              color="primary"
              variant="outlined"
              onClick={() => {
                setError(null)
                atlassianProvisionAccount({
                  id: userId
                })
              }}
              disabled={atlassianLinkAccountLoading}
            >
              Link Atlassian Account
            </RoundedButton>
            {atlassianLinkAccountLoading && <Progress size={20} delay={0} />}
          </div>
        </Grid>
      )}
      {error && (
        <Grid item xs={12} className={classes.errorSection}>
          <ErrorSection includeSupport>{error}</ErrorSection>
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: 5,
    fontWeight: 600
  },
  container: {
    marginLeft: 10
  },
  errorSection: {
    marginTop: 20
  },
  gray: {
    color: theme.palette.gray9.main
  },
  link: {
    display: 'inline-block'
  },
  message: {
    marginRight: 5
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  }
}))
