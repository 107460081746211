import React, { useState } from 'react'
import {
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import InfoIcon from '@material-ui/icons/Info'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import logoImage from '../Navigation/tip-header-logo.svg'
import bgImage from '../assets/images/Get-Started-BG-Tile.svg'
import clsx from 'clsx'

const GetStartedInfo = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  return (
    <>
      {isMobile && (
        <Grid item xs={12}>
          <Typography className={classes.infoHeaderMobile} variant="h5">
            <span>
              <ArrowDownwardIcon className={classes.infoHeaderMobileIcon} />
            </span>{' '}
            Steps to join TIP
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.infoContainer} xs={12} md={5}>
        {!isMobile && (
          <>
            <img src={logoImage} className={classes.tipLogo} alt="TIP logo" />
            <Typography gutterBottom className={classes.infoTitle}>
              Steps to join TIP
            </Typography>
          </>
        )}
        <Typography variant="caption">You will be asked to:</Typography>
        <ol className={classes.infoList}>
          <Typography component="li">Confirm your work email address</Typography>
          <Typography component="li">Provide your own profile information</Typography>
          <Divider className={classes.infoDivider} />
          <Typography paragraph variant="caption">
            If your organization is NOT already a participant of TIP, you will also need to provide:
          </Typography>
          <Typography component="li">Your organization's contact information</Typography>
          <Typography component="li">
            <TooltipHelper
              label="A Billing Contact"
              title="The Billing Contact is an individual employed by Applicant, who will receive the invoices sent by TIP with the Applicant's Annual Dues 60 days prior to the date on which payment is due. If no individual is specified, invoices will be submitted to Applicant's Account Administrator."
            />
          </Typography>
          <Typography component="li">Desired participation tier</Typography>
          <Typography component="li">
            An{' '}
            <TooltipHelper
              label="Authorized Representative"
              title="The Authorized Representative is an individual employed by Applicant who has the authority to bind Applicant. All legal notices from TIP will be sent to the Authorized Representative of record based on the contact information provided by the Applicant as set forth in the TIP General Participation Agreement."
            />{' '}
            to be sent the{' '}
            <Typography
              className={classes.infoListTextLink}
              component="a"
              href="https://cdn.brandfolder.io/D8DI15S7/as/q7rnyo-fv487k-2jvm4m/General_Participation_Agreement_-_Telecom_Infra_Project.pdf"
              target="_blank"
              variant="body2"
            >
              General Participation Agreement
            </Typography>{' '}
            for signature
          </Typography>
          <Typography paragraph variant="caption">
            After the participation agreement is signed, TIP will review your application and send
            an invoice for any participation dues.
          </Typography>
        </ol>
      </Grid>
    </>
  )
}

export const TooltipHelper = ({ iconClass, label = '', labelFirst = true, title = '' }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <Tooltip arrow open={tooltipOpen} title={title}>
      <span
        onClick={isMobile ? () => setTooltipOpen(!tooltipOpen) : null}
        onMouseLeave={!isMobile ? () => setTooltipOpen(false) : null}
        onMouseEnter={!isMobile ? () => setTooltipOpen(true) : null}
      >
        {labelFirst && `${label} `}
        {tooltipOpen ? (
          <InfoIcon className={clsx(classes.infoIcon, iconClass)} />
        ) : (
          <InfoOutlinedIcon className={clsx(classes.infoIcon, iconClass)} />
        )}
        {!labelFirst && ` ${label}`}
      </span>
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  infoContainer: {
    backgroundColor: '#25707A',
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    color: 'white',
    marginTop: 50,
    maxWidth: 800,
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: theme.spacing(4),
      width: 'calc(100% + 10px)'
    }
  },
  infoDivider: {
    backgroundColor: 'rgb(255, 255, 255, 0.12)',
    margin: `${theme.spacing(2)}px 0`
  },
  infoHeaderMobile: {
    backgroundColor: '#25707A',
    color: 'white',
    height: theme.spacing(5),
    margin: `-1px ${theme.spacing(5)}px`,
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    width: `calc(100% - ${theme.spacing(10)}px)`
  },
  infoHeaderMobileIcon: {
    border: '1px solid white',
    borderRadius: '50%',
    display: 'inline',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  },
  infoIcon: {
    display: 'inline',
    fontSize: '1.2rem',
    verticalAlign: 'text-top'
  },
  infoList: {
    counterReset: 'item',
    listStyle: 'none',
    padding: 0,
    '& li': {
      counterIncrement: 'item',
      fontWeight: 'bold',
      marginBottom: 14,
      paddingLeft: theme.spacing(4)
    },
    '& li:before': {
      backgroundColor: '#A5CF4C',
      borderRadius: '50%',
      content: 'counter(item)',
      display: 'inline-block',
      fontSize: 12,
      height: theme.spacing(3),
      marginLeft: -theme.spacing(4),
      marginRight: theme.spacing(1),
      paddingTop: 3,
      textAlign: 'center',
      verticalAlign: 'bottom',
      width: theme.spacing(3)
    }
  },
  infoListTextLink: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  infoTitle: {
    fontSize: 28,
    fontWeight: 'lighter'
  },
  tipLogo: {
    height: 26,
    width: 'auto'
  }
}))

export default GetStartedInfo
