import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import LinkField from '../common/LinkField'
import MegaMenu from './MegaMenu'

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none'
  },
  open: {
    marginLeft: 15
  },
  item: {
    fontSize: '.9rem',
    textDecoration: 'none !important',
    color: 'white',
    textTransform: 'unset',
    padding: '0px 16px',
    whiteSpace: 'inherit',
    borderTop: 'none !important',
    borderBottom: `${theme.palette.navBorderGray.main} solid 1px`,
    '&:hover': {
      background: theme.palette.navHoverGray.main
    }
  },
  rootLevel: {
    borderTop: '2px solid ' + theme.palette.darkGray.main,
    borderBottom: `${theme.palette.navBorderGray.main} solid 1px`,
    '&:hover': {
      background: theme.palette.navHoverGray.main
    }
  },
  popUpLabel: {
    borderBottom: `${theme.palette.navBorderGray.main} solid 1px`,
    '&:hover': {
      background: theme.palette.navHoverGray.main
    }
  },
  popUpContainer: {
    position: 'relative',
    width: 0,
    left: -15
  },
  popUpContent: {
    position: 'absolute',
    backgroundColor: theme.palette.navDarkGray.main,
    top: -48,
    width: 170, // this value may need to change if menu content modified
    boxShadow: '0px 0px 10px rgba(0,0,0,.5)'
  },
  popUpContentLeft: {
    right: 0,
    zIndex: 1
  },
  popUpContentRight: {
    left: 126, // this value may need to change if menu content modified
    zIndex: 1
  },
  megaMenu: {
    background: `${theme.palette.navHoverGray.main} !important`
  },
  subMenuContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  subMenuLabel: {
    marginRight: 7
  },
  megaMenuLi: {
    width: 780,
    position: 'relative'
  },
  mobileOpenHeader: {
    fontWeight: '600'
  }
}))

const SubMenu = React.forwardRef(
  ({ type, items, onClose, megaMenuLinkPadding, openPopUpLeft, nestedLevel = 1 }, ref) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(new Set())

    function toggleOpen(name, clearOtherItems) {
      const isOpen = open.has(name)
      let newSet = clearOtherItems ? new Set() : open

      if (isOpen) {
        newSet.delete(name)
      } else {
        newSet.add(name)
      }

      setOpen(new Set(newSet))
    }

    return (
      <>
        {items.map(
          (
            {
              subItems,
              name,
              label,
              to,
              hidden,
              disabled,
              megaMenu,
              mobileOpen,
              headerProps = { hideBorder: true }
            },
            index
          ) => {
            if (hidden || disabled) {
              return false
            }

            if (megaMenu && type !== 'mobile') {
              return (
                <li key={name} className={classes.megaMenuLi}>
                  <MegaMenu items={subItems} megaMenuLinkPadding={megaMenuLinkPadding} />
                </li>
              )
            } else if (subItems && type !== 'mobile') {
              const isOpen = open.has(name)
              return (
                <li key={name}>
                  <MenuItem
                    innerRef={ref}
                    aria-haspopup="true"
                    component="div"
                    onClick={event => {
                      event.stopPropagation()
                      toggleOpen(name, true)
                    }}
                    className={clsx(
                      {
                        [classes.popUpLabel]: nestedLevel === 1 && !isOpen && index > 0
                      },
                      classes.item
                    )}
                  >
                    <div className={classes.subMenuContainer}>
                      <div className={classes.subMenuLabel}>{label}</div>
                      <Icon fontSize="small">keyboard_arrow_right</Icon>
                    </div>
                  </MenuItem>

                  <div
                    className={clsx(classes.popUpContainer, isOpen ? classes.open : classes.hidden)}
                  >
                    <div
                      className={clsx(
                        classes.popUpContent,
                        openPopUpLeft ? classes.popUpContentLeft : classes.popUpContentRight
                      )}
                    >
                      <SubMenu
                        type={type}
                        items={subItems}
                        nestedLevel={nestedLevel + 1}
                        onClose={onClose}
                      />
                    </div>
                  </div>
                </li>
              )
            } else if (subItems) {
              const isOpen = mobileOpen || open.has(name)
              return (
                <div key={name}>
                  <MenuItem
                    innerRef={ref}
                    aria-haspopup="true"
                    onClick={event => {
                      event.stopPropagation()
                      toggleOpen(name)
                    }}
                    className={clsx(
                      {
                        [classes.rootLevel]:
                          type !== 'mobile' && nestedLevel === 1 && !isOpen && index > 0
                      },
                      classes.item
                    )}
                  >
                    <div className={classes.subMenuContainer}>
                      <div
                        className={clsx(
                          classes.subMenuLabel,
                          mobileOpen ? classes.mobileOpenHeader : null
                        )}
                      >
                        {label}
                      </div>
                      {nestedLevel === 1 ? (
                        isOpen ? (
                          <Icon fontSize="small">keyboard_arrow_up</Icon>
                        ) : (
                          <Icon fontSize="small">keyboard_arrow_down</Icon>
                        )
                      ) : null}
                    </div>
                  </MenuItem>

                  <div className={isOpen ? classes.open : classes.hidden}>
                    <SubMenu
                      type={type}
                      items={subItems}
                      nestedLevel={nestedLevel + 1}
                      onClose={onClose}
                    />
                  </div>
                </div>
              )
            } else {
              return (
                <div key={index}>
                  <MenuItem
                    innerRef={ref}
                    key={name}
                    label={label}
                    onClick={onClose}
                    className={clsx(classes.item, {
                      [classes.rootLevel]: type !== 'mobile' && nestedLevel === 1 && index > 0
                    })}
                    component={LinkField}
                    hideIcon={true}
                    to={to}
                    {...headerProps}
                  />
                </div>
              )
            }
          }
        )}
      </>
    )
  }
)

export default SubMenu
