import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export default function SignatureRequestModal({ isOpen, setOpen, handleSubmit, cancel }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen({ isOpen: false, cancel: cancel })
  }

  function handleClick() {
    handleSubmit()
    setOpen({ isOpen: false, cancel: cancel })
  }

  return (
    <Dialog
      className={classes.modalContainer}
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Are you sure?
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <div>
            <Typography>
              {cancel
                ? 'Are you sure you wish to cancel the General Participation Agreement you sent previously?'
                : 'Are you sure you wish to re-send the Docusign notification email?'}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          fullWidth={false}
          onClick={handleClick}
          className={classes.button}
          classes={{ root: classes.root }}
        >
          Yes
        </Button>
        <Button
          fullWidth={false}
          onClick={handleClose}
          className={classes.button}
          classes={{ root: classes.root }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  modalContainer: {
    position: 'relative',
    top: '-150px'
  },
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.8
    }
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  }
}))
