import gql from 'graphql-tag'

export const LOGIN_USER = gql`
  mutation($input: UserLoginInput!) {
    login(input: $input) {
      data
      result
    }
  }
`

export const REFRESH_SESSION = gql`
  mutation {
    refreshSession {
      data
      result
    }
  }
`

export const SELECT_LOGGED_IN_STATUS = gql`
  mutation setStayLoggedIn($input: StayLoggedInInput) {
    selectLoggedInStatus(input: $input) {
      data
      result
    }
  }
`

export const ACTIVATE_NEW_EMAIL = gql`
  mutation($id: ID!, $input: UserActivateNewEmailInput!) {
    activateNewEmail(id: $id, input: $input) {
      result
      data
    }
  }
`

export const VERIFY_TOKEN_ACTIVATE_EMAIL = gql`
  mutation($input: TokenVerifyInput!) {
    verifyActivateNewEmailToken(input: $input) {
      result
      data
      email
      currentEmail
      userId
    }
  }
`

export const AUTHORIZE_ENV = gql`
  mutation($input: AuthorizeEnvInput!) {
    authorizeEnv(input: $input)
  }
`

export const RESET_PASSWORD = gql`
  mutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      result
      data
    }
  }
`
