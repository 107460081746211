import React, { useEffect, useReducer, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import parse from 'csv-parse/lib/sync'
import { useSnackbar } from 'notistack'
import { apolloClient } from '../../middleware/api'

import InviteMemberInput from './InviteMemberInput'
import { InputTextFieldNew, RoundedButton } from '../../common'
import { useMutation } from '../../hooks'
import { SEND_ORGANIZATION_INVITES } from './mutations'
import { GET_MEMBER_TABLE_ORGANIZATION_DATA } from '../ManageMembers/queries'

const useStyles = makeStyles(theme => ({
  left: {
    justifyContent: 'flex-start',
    padding: 20
  },
  inviteContainer: {
    marginLeft: 30,
    padding: 2
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderTop: '1px solid ' + theme.palette.gray2.main
  },
  content: {
    minHeight: 275
  },
  fileInput: {
    minHeight: 25
  }
}))

const initialState = {
  inviteUsers: [],
  inviteMethod: 'email',
  personalizedMessage: '',
  file: null,
  fileError: false,
  inviteError: false,
  open: false
}

function reducer(state, action) {
  let index, updatedInvites
  switch (action.type) {
    case 'SET_INVITE_METHOD':
      return {
        ...state,
        inviteMethod: action.payload
      }
    case 'UPDATE_INVITE_USER':
      index = action.payload.index
      updatedInvites = [...state.inviteUsers]
      updatedInvites[action.payload.index] = action.payload.invite

      return {
        ...state,
        inviteUsers: updatedInvites
      }
    case 'REMOVE_INVITE':
      index = action.payload.index
      updatedInvites = [...state.inviteUsers.slice(0, index), ...state.inviteUsers.slice(index + 1)]

      return {
        ...state,
        inviteUsers: updatedInvites
      }
    case 'ADD_INVITE_USER':
      return {
        ...state,
        inviteUsers: [...state.inviteUsers, action.payload]
      }
    case 'SET_INVITE_MESSAGE':
      return {
        ...state,
        personalizedMessage: action.payload
      }
    case 'SET_INVITE_FILE':
      return {
        ...state,
        file: action.payload
      }
    case 'SET_INVITE_FILE_ERROR':
      return {
        ...state,
        fileError: action.payload
      }
    case 'SET_INVITE_ERROR':
      return {
        ...state,
        inviteError: action.payload
      }
    case 'SET_INVITE_MODAL_OPEN':
      return {
        ...state,
        open: true,
        inviteUsers: [{ email: '', firstName: '', lastName: '' }]
      }
    case 'SET_INVITE_MODAL_CLOSE':
      return {
        ...state,
        open: false,
        inviteUsers: []
      }
    case 'RESET_INVITE_FORM': {
      return { ...initialState }
    }
    default:
      throw new Error()
  }
}

export default function InviteUsers({ orgId, addInvites, children }) {
  const organizationInviteOptions = {
    update: () => {
      const { organization } = apolloClient.cache.readQuery({
        query: GET_MEMBER_TABLE_ORGANIZATION_DATA,
        variables: { id: orgId }
      })

      const invitations = inviteUsers.map(obj => {
        return {
          email: obj.email,
          firstName: obj.firstName,
          lastName: obj.lastName,
          userId: null,
          organizationId: orgId,
          status: 'invited',
          sentDate: Date.now(),
          __typename: 'OrganizationInvite'
        }
      })

      invitations.forEach(invitation => {
        if (invitation.email !== '') {
          organization.invitations.push(invitation)
        }
      })

      apolloClient.cache.writeQuery({
        query: GET_MEMBER_TABLE_ORGANIZATION_DATA,
        data: { organization: { ...organization } }
      })
    }
  }

  const { data, error, loading, execute } = useMutation(
    SEND_ORGANIZATION_INVITES,
    organizationInviteOptions
  )
  const [
    { inviteUsers, inviteMethod, personalizedMessage, file, fileError, inviteError, open },
    dispatch
  ] = useReducer(reducer, initialState)

  const [emailAdded, setEmailAdded] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (error) {
      console.error(error.graphQLErrors[0].message)
      dispatch({ type: 'SET_INVITE_ERROR', payload: error.graphQLErrors[0].message })
    } else if (!loading && data) {
      dispatch({ type: 'RESET_INVITE_FORM' })
      setEmailAdded(false)

      const filtered = data.generateOrganizationInvitation.filter(Boolean)

      addInvites(currentState => {
        let properResult = filtered
          .concat(currentState)
          .filter(item => item['organizationId'] !== undefined)
        return properResult
      })

      enqueueSnackbar('Invitations Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [data, loading, error, addInvites, enqueueSnackbar]) //invitationData

  const handleClose = () => {
    dispatch({ type: 'RESET_INVITE_FORM' })
    dispatch({ type: 'SET_INVITE_MODAL_CLOSE' })
    setEmailAdded(false)
  }

  let isDisabled = false

  if (file) {
    if (inviteMethod === 'csv') {
      isDisabled = fileError
    }
  } else {
    if (!emailAdded) {
      isDisabled = true
    }
  }

  return (
    <React.Fragment>
      <RoundedButton
        fullWidth={false}
        color="primary"
        onClick={() => dispatch({ type: 'SET_INVITE_MODAL_OPEN' })}
      >
        {children}
      </RoundedButton>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
      >
        <DialogTitle id="form-dialog-title">Invite organization participants to join</DialogTitle>

        <DialogContent className={classes.content}>
          <Typography role="alert" color="error">
            {inviteError}
          </Typography>
          <RadioGroup
            aria-label="Invite Method"
            name="inviteMethod"
            className={classes.group}
            value={inviteMethod}
            onChange={event => dispatch({ type: 'SET_INVITE_METHOD', payload: event.target.value })}
          >
            <FormControlLabel
              value="email"
              control={<Radio />}
              label="Enter the emails of people you'd like to add."
            />
            {inviteMethod === 'email' && (
              <div className={classes.inviteContainer}>
                {inviteUsers.map(({ email, firstName, lastName }, index) => {
                  return (
                    <InviteMemberInput
                      key={`form-${index}`}
                      inviteEmail={email}
                      inviteFirstName={firstName}
                      inviteLastName={lastName}
                      handleInviteChange={state => {
                        const invite = {
                          email: state.values.email.toLowerCase(),
                          firstName: state.values.firstName,
                          lastName: state.values.lastName,
                          error: Object.keys(state.errors).length > 0
                        }

                        dispatch({ type: 'UPDATE_INVITE_USER', payload: { invite, index } })

                        if (!invite.email) {
                          setEmailAdded(false)
                        }
                      }}
                      handleInviteRemove={
                        index > 0
                          ? () => dispatch({ type: 'REMOVE_INVITE', payload: { index } })
                          : null
                      }
                      setEmailAdded={setEmailAdded}
                    />
                  )
                })}
                <Button
                  style={{ marginTop: 20 }}
                  color="primary"
                  onClick={evt =>
                    dispatch({
                      type: 'ADD_INVITE_USER',
                      payload: { email: '', firstName: '', lastName: '' }
                    })
                  }
                >
                  <Icon>add</Icon>Add More
                </Button>
              </div>
            )}
            <div className={classes.divider} />
            <FormControlLabel
              value="csv"
              control={<Radio />}
              label="Upload a CSV file. The file should contain name and email pairs, one per line."
            />
            {inviteMethod === 'csv' && (
              <div className={classes.inviteContainer}>
                <Typography role="alert" color="error">
                  {fileError}
                </Typography>
                <Typography color="primary" paragraph>
                  Expected: Email, First name, Last name
                </Typography>
                <TextField
                  variant="outlined"
                  type="file"
                  inputProps={{
                    accept: '.csv',
                    className: classes.fileInput
                  }}
                  onChange={({
                    target: {
                      files: [file]
                    }
                  }) => {
                    if (file) {
                      if (file.type === 'text/csv' || 'application/vnd.ms-excel') {
                        //windows flags the second MIME type
                        dispatch({
                          type: 'SET_INVITE_FILE_ERROR',
                          payload: null
                        })
                        dispatch({ type: 'SET_INVITE_FILE', payload: file })
                      } else {
                        dispatch({
                          type: 'SET_INVITE_FILE_ERROR',
                          payload: 'Only CSV files are allowed.'
                        })
                      }
                    }
                  }}
                />
              </div>
            )}
          </RadioGroup>
          <div className={classes.divider} />
          <Typography>Personalize your invitation message. (Optional)</Typography>
          <InputTextFieldNew
            multiline={true}
            name="personalizedMessage"
            rows={4}
            rowsMax={12}
            value={personalizedMessage}
            onChange={e => dispatch({ type: 'SET_INVITE_MESSAGE', payload: e.target.value })}
          />
        </DialogContent>
        <DialogActions className={classes.left}>
          <RoundedButton fullWidth={false} onClick={handleClose} variant="outlined" color="default">
            Cancel
          </RoundedButton>
          <RoundedButton
            fullWidth={false}
            onClick={() => {
              // send the invitations
              dispatch({ type: 'SET_INVITE_ERROR', payload: false })

              if (inviteMethod === 'email') {
                const inviteList = inviteUsers
                  .filter(i => !i.removed && i.email !== '' && !i.error)
                  .map(obj => {
                    return { email: obj.email, firstName: obj.firstName, lastName: obj.lastName }
                  })

                execute({
                  values: {
                    organizationId: orgId,
                    type: 'invite',
                    message: personalizedMessage,
                    invites: inviteList
                  }
                })
              } else {
                let reader = new FileReader()
                reader.readAsText(file)
                reader.onload = async function() {
                  const invites = parse(reader.result, {
                    columns: false,
                    skip_empty_lines: true
                  })

                  let formattedInvites = invites.map(i => {
                    return {
                      email: i[0],
                      firstName: i[1],
                      lastName: i[2]
                    }
                  })

                  // Strip header row if exists
                  if (formattedInvites && formattedInvites[0].email.toLowerCase() === 'email') {
                    formattedInvites.shift()
                  }
                  let emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
                  const hasInvalidEmails = await import('../../utils/emailBlacklist').then(
                    checkBlacklist =>
                      formattedInvites.find(
                        e => checkBlacklist.default(e.email) || !emailRegex.test(e.email)
                      )
                  )

                  formattedInvites.forEach(f => {
                    dispatch({
                      type: 'ADD_INVITE_USER',
                      payload: { email: f.email, firstName: f.firstName, lastName: f.lastName }
                    })
                  })

                  if (hasInvalidEmails) {
                    dispatch({
                      type: 'SET_INVITE_FILE_ERROR',
                      payload: 'Only approved organization email domains are allowed.'
                    })
                  } else {
                    execute({
                      values: {
                        organizationId: orgId,
                        type: 'invite',
                        message: personalizedMessage,
                        invites: formattedInvites
                      }
                    })
                  }
                }
                reader.onerror = function(error) {
                  console.log('Error: ', error)
                }
              }
            }}
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Send Invites
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
