import React, { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Progress from '../../common/Progress'
import RoundedButton from '../../common/RoundedButton'
import { useMutation } from '../../hooks'
import { useSession } from '../../auth/queries'
import {
  ADD_PROJECT_GROUP_PARTICIPATION_REQUEST,
  GET_ORGANIZATION_PROJECT_GROUPS
} from '../../ProjectGroups'

export default function RequestSignatureSuccessModal({
  agreementType = 'agreement',
  isOrgAdmin,
  onParticipationRequestSuccess,
  orgId,
  projectGroupId,
  projectGroupName,
  setSuccessSupportMustVerify,
  successModalOpen,
  successJoinAsIndividual,
  supportMustVerify,
  type = 'Sub-Group'
}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(successModalOpen)
  const { userId } = useSession()

  useEffect(() => {
    setOpen(successModalOpen)
  }, [successModalOpen])

  const handleClose = () => {
    setOpen(false)
  }

  const updateProjectGroups = (
    cache,
    { data: { addProjectGroupParticipationRequest: { result } = {} } }
  ) => {
    if (result) {
      setOpen(false)
      if (!isOrgAdmin) {
        onParticipationRequestSuccess('requestParticipation')
      }
    } else {
      console.log('error')
      // setErrorMessage('Something went wrong.')
    }
  }

  const refetchQueries = [
    {
      query: GET_ORGANIZATION_PROJECT_GROUPS,
      variables: { id: orgId }
    }
  ]

  const { execute, loading: requestLoading } = useMutation(
    ADD_PROJECT_GROUP_PARTICIPATION_REQUEST,
    {
      update: updateProjectGroups,
      onCompleted: onCompleted,
      awaitRefetchQueries: true,
      refetchQueries: refetchQueries
    }
  )

  function handleSubmit() {
    execute({
      values: {
        userId: userId,
        projectGroupId: projectGroupId,
        organizationId: orgId,
        type: 'requestParticipation'
      }
    })
  }

  function onCompleted() {
    if (setSuccessSupportMustVerify) {
      setSuccessSupportMustVerify(false)
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth={'md'}
      onClose={handleClose}
      className={classes.margin}
    >
      <DialogTitle className={classes.titleRow}>
        {successJoinAsIndividual ? 'Join as individual' : 'Success'}
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.margin}>
        <Grid container>
          <Grid item xs={12}>
            <Typography paragraph className={classes.bold}>
              {supportMustVerify
                ? `The request for TIP verification has been sent.`
                : `The ${agreementType} for the ${projectGroupName} ${type} has been sent for signature.`}
            </Typography>
            {successJoinAsIndividual ? (
              <Typography paragraph>Would you like to join the group as an individual?</Typography>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        {successJoinAsIndividual ? (
          <RoundedButton
            className={classes.button}
            fullWidth={false}
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            No
          </RoundedButton>
        ) : null}
        <RoundedButton
          className={classes.button}
          fullWidth={false}
          onClick={successJoinAsIndividual ? handleSubmit : handleClose}
          variant="contained"
          color="primary"
        >
          {requestLoading ? (
            <Progress size={30} delay={0} />
          ) : successJoinAsIndividual ? (
            'Yes'
          ) : (
            'Ok'
          )}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  titleRow: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  cancelButton: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      marginRight: 0
    }
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  actionButtons: {
    padding: 25,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  errorSection: {
    marginBottom: 20
  },
  bold: {
    fontWeight: 600
  }
}))
