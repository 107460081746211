export default {
  Cassini: {
    name: 'Cassini',
    description:
      'A new open packet/optical transponder contributed by Edge-core. Cassini integrates 100GbE switching with Layer-1 optical transport functions as line-card modules, and covers data center interconnect, metro and access backhaul use cases.',
    projectGroup: 'Open Optical & Packet Transport',
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-8wg0g7/Cassini_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'Cassini_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  DCSG: {
    name: 'DCSG',
    description:
      'DCSG is a 1RU fully-featured cell site router with a wide range of Ethernet connectivity options for client and network sides. As a cell site gateway, DCSG supports Layer-2, Layer-3 and MPLS features – with native time synchronization protocols such as IEEE-1588 v2 and Synchronous Ethernet for the mobile base stations.',
    projectGroup: 'Open Optical & Packet Transport',
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-6xq8uk/DCSG_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'DCSG_at_a_Glance_-_Telecom_Infra_Project.png',
    subDocuments: [
      {
        name: 'ADVA',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-7can5o/DCSG_ADVA_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Alpha Networks',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-88vihv/DCSG_Alpha_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Altran',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-bggg2j/DCSG_Altran_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Delta',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-2ahslr/DCSG_Delta_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Edgecore',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-f2i5dl/DCSG_Edgecore_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Exaware',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-9lqub3/DCSG_Exaware_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Infinera (Hardware)',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-88oxmd/DCSG_InfineraHW_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Infinera (Software)',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-3y11z3/DCSG_Infinera_SW_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'IP Infusion',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-baump6/DCSG_IPInfusion_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'UFiSpace',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-9eh9ld/DCSG_Ufi_Space_Glossy_-_Telecom_Infra_Project.pdf'
      },
      {
        name: 'Volta Networks',
        link:
          'https://cdn.brandfolder.io/D8DI15S7/as/q5yv7c-3xas88-8js6nu/DCSG_Volta_Glossy_-_Telecom_Infra_Project.pdf'
      }
    ]
  },
  Phoenix: {
    name: 'Phoenix',
    description:
      'Phoenix is the first open and disaggregated 400G-capable transponder/muxponder solution for large scale DWDM networks. Compatible with any line system, Phoenix increases MNOs and ISPs transport layer capacity and scalability.',
    projectGroup: 'Open Optical & Packet Transport',
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q5ykp3-faufu0-cq7rk6/OOPT_Phoenix_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'OOPT_Phoenix_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  'Wireless Backhaul': {
    name: 'Wireless Backhaul',
    description:
      'The Wireless Backhaul Project Group is working to provide a white box wireless backhaul system that is dual frequency band, multi core, field upgradable, and modular, with SDN capabilities achieved by combining optical and wireless functionalities.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-4eyxcp/Open_Box_Microwave_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'Wireless_Backhaul_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  OpenCellular: {
    name: 'OpenCellular',
    description:
      'The goal of the OpenCellular Project Group is to connected in the unconnected in the rural areas, by empowering communities with tools to build and operate sustainable community cellular infrastructure, using and building open-source technologies in an open ecosystem.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-dvtypr/OpenCellular_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'OpenCellular_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  'OpenRAN 5G NR': {
    name: 'OpenRAN 5G NR',
    description:
      'The OpenRAN 5G NR Project Group will collaborate via TIP to define a whitebox platform for a 5G NR access point that is easy to configure and deploy.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-9ksbz8/OpenRAN_5G_NR_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'OpenRAN_5G_NR_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  OpenRAN: {
    name: 'OpenRAN',
    description:
      'The OpenRAN Project Group is an initiative to define and build 2G, 3G, and 4G RAN solutions based on a general-purpose vendor-neutral hardware and software-defined technology.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-fbcpjq/OpenRAN_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'OpenRAN_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  PlugFest: {
    name: 'PlugFest',
    description:
      'The purpose of the PlugFest Project Group is to define and accelerate the development of test materials, test plans, or other documents that will support a TIP sponsored PlugFest for interoperability among products. Effectively, the PlugFest Project Group will quantify the readiness of TIP sponsored technologies for integration into operator networks.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-616zyb/PlugFest_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'PlugFest_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  'Total Site Solution': {
    name: 'Total Site Solution',
    description:
      'Working to create economically sustainable, optimized, pre-tested, and remotely managed rural site configurations that leverage existing COTS products and passive elements, catalyzing the industry to connect more people in rural areas.',
    projectGroup: 'PlugFest',
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q5yk2x-1001eo-e3ukbc/Total_Site_Solution_-_Telecom_Infra_Project.pdf',
    image: 'Total_Site_Solution_-_Telecom_Infra_Project.png'
  },
  'Wi-Fi': {
    name: 'Wi-Fi',
    description:
      'The goal of the Wi-Fi Project Group is to foster collaboration, exploration, and standardization among organizations deploying Wi-Fi, infrastructure vendors, and service providers.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-4y942x/Wi-Fi_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'Wi-Fi_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  'mmWave Networks': {
    name: 'mmWave Networks',
    description:
      'The goal of the mmWave Networks Project Group is to define and advance wireless networking solutions that utilize millimeter-wave spectrum (~30 to 300 GHz) to address the constantly growing demand for bandwidth by delivering gigabits of capacity more quickly, easily, and cost-effectively compared to deploying fiber.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q3wkdg-476u4o-2kbfhj/mmWave_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'mmWave_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  NTCS: {
    name: 'NTCS',
    description:
      'The goal of the Non-Terrestrial Connectivity Solutions Project Group is to accelerate the innovation and deployment of non-terrestrial solutions to provide coverage to areas that terrestrial solutions cannot cover efficiently, with an initial focus on low density environments and quick response solutions for use in emergency situations.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q5yjpc-eid3so-b56x5x/NTCS_at_a_Glance_-_Telecom_Infra_Project.pdf',
    image: 'NTCS_at_a_Glance_-_Telecom_Infra_Project.png'
  },
  'TIP Community Lab': {
    name: 'TIP Community Lab',
    description:
      'TIP Community Labs enable rapid real-world pilots that we hope lead to the adoption of new solutions at scale. With the labs, we aim to provide a welcoming environment for project groups to develop tech solutions tailored to address use cases defined by operators.',
    projectGroup: null,
    link:
      'https://cdn.brandfolder.io/D8DI15S7/as/q5ysdu-bvq5c-csmmxw/Community_Labs_Glossy_-_Telecom_Infra_Project.pdf',
    image: 'Community_Labs_Glossy_-_Telecom_Infra_Project.png'
  }
}
