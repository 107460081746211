import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'

const InputTextField = ({
  blockInputTypeOnChange = false,
  error,
  errorHelperText,
  helperText,
  hideLabel,
  inputType = () => ({
    onBlur: () => null,
    onChange: () => null,
    value: null
  }),
  label,
  name,
  onBlur = () => null,
  onChange = () => null,
  onFocus = () => null,
  touched,
  touchOnChange = true,
  validateOnBlur = false,
  validator,
  value,
  ...rest
}) => {
  const {
    onBlur: inputTypeOnBlur,
    onChange: inputTypeOnChange,
    value: inputTypeValue,
    ...inputTypeRest
  } = inputType({
    name,
    touchOnChange,
    validate: (value, values) => {
      if (validator) {
        const {
          cantMatchKey,
          cantMatchMessage,
          caseInsensitive,
          maxLength,
          minLength,
          mustMatchKey,
          mustMatchMessage,
          mustContainValue,
          mustContainValueMessage,
          regex,
          regexMessage,
          required
        } = validator

        if ((!value && required) || (!value && values[mustMatchKey])) {
          return `${label} is required`
        } else if (!required && value === '') {
          return true // if not required and is empty don't do rest of validation
        }

        if (value !== '' && !value.replace(/\s/g, '').length) {
          return `${label} can not be only whitespace`
        }

        if (value.length > maxLength) {
          return `${label} must be shorter than ${maxLength} characters`
        }

        if (value.length < minLength) {
          return `${label} must be at least ${minLength} characters`
        }

        if (regex && !regex.test(value)) {
          return regexMessage
        }

        if (mustMatchKey && value !== values[mustMatchKey]) {
          if (caseInsensitive) {
            if (
              value &&
              values[mustMatchKey] &&
              value.toLowerCase() !== values[mustMatchKey].toLowerCase()
            ) {
              return mustMatchMessage
            }
          } else {
            return mustMatchMessage
          }
        }

        if (mustContainValue && !value.includes(mustContainValue)) {
          return mustContainValueMessage || `${label} must contain '${mustContainValue}'`
        }

        if (value === values[cantMatchKey]) {
          return cantMatchMessage
        }

        return true
      }
    },
    validateOnBlur
  })

  const [showError, setShowError] = useState(value || inputTypeValue ? true : false)

  useEffect(() => {
    if (typeof touched !== 'undefined' && !touched && showError) {
      setShowError(false)
    }
  }, [touched, showError])

  return (
    <TextField
      {...inputTypeRest}
      fullWidth
      color="secondary"
      error={showError && error}
      helperText={showError && error && errorHelperText ? errorHelperText : helperText}
      label={!hideLabel && label}
      margin="normal"
      name={name}
      placeholder={(hideLabel && label) || ''}
      value={value || inputTypeValue || ''}
      variant="outlined"
      onBlur={(...props) => {
        const trimmed = props[0].target.value.trim().replace(/\s\s+/g, ' ')

        if (trimmed !== props[0].target.value || props[0].target.type === 'email') {
          /*
            In any browsers based on chromium, an HTML input of type 'email'
            will not emit change events when the entered character is whitespace,
            and the input element's value has all whitespace trimmed from the ends
            everywhere but in the element's visuals.
            Forcing the browser to rerender the element by manually setting its value
            is necessary until that bug is resolved in browsers.

            NOT an issue with FireFox

            Confirmed for Chrome 86.0.4240.111, FireFox 82.0

            See https://bugs.chromium.org/p/chromium/issues/detail?id=423785 for reference
          */

          props[0].target.value = '_'
          props[0].target.value = trimmed

          if (!blockInputTypeOnChange) {
            inputTypeOnChange(...props)
          }

          onChange(...props)
        }

        if ((value || inputTypeValue) && !showError) {
          setShowError(true)
        }

        inputTypeOnBlur(...props)
        onBlur(...props)
      }}
      onChange={(...props) => {
        if (!blockInputTypeOnChange) {
          inputTypeOnChange(...props)
        }

        onChange(...props)
      }}
      onFocus={(...props) => {
        if ((value || inputTypeValue) && !showError) {
          setShowError(true)
        }

        onFocus(...props)
      }}
      {...rest}
    />
  )
}

export default InputTextField
