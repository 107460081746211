import React, { useState } from 'react'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { useFormState } from 'react-use-form-state'
import {
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'

import { TooltipHelper } from '../../SignUp/GetStartedInfo'
import { LinkField, InputTextFieldNew, Progress, RoundedButton } from '../../common'
import { useQuery, useFormStyles, useMutation } from '../../hooks'
import { formatDate } from '../../utils/dateTime'
import { inputRegexes, isFormSubmitDisabled } from '../../utils'
import { GET_ORG_BILLING_DATA, GET_ORG_USERS } from './queries'
import { EDIT_BILLING_CONTACT } from './mutations'
import { GET_MEMBERSHIP_LEVELS } from '../Profile/queries'
import { CONTACT_SUPPORT_URL } from '../../utils/constants'

export default function Billing(props) {
  const orgId = props.match.params.id

  const classes = useStyles()
  const formClasses = useFormStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [contactEditing, setContactEditing] = useState(false)
  const [billingContactChanged, setBillingContactChanged] = useState(false)
  const [searchItemSelected, setSearchItemSelected] = useState(false)

  const { data, loading: loadingData } = useQuery(GET_ORG_BILLING_DATA, {
    variables: { id: orgId }
  })

  // need to get org users for search
  const { data: organizationUserData, loading: loadingOrganizationUserData } = useQuery(
    GET_ORG_USERS,
    {
      variables: { id: orgId }
    }
  )

  const { data: membershipLevelData, loading: membershipLevelLoading } = useQuery(
    GET_MEMBERSHIP_LEVELS,
    {
      variables: { id: orgId }
    }
  )

  const { loading: editContactLoading, execute: handleEditBillingContact } = useMutation(
    EDIT_BILLING_CONTACT,
    {
      onCompleted: data =>
        enqueueSnackbar('Billing contact updated!', {
          variant: 'success',
          autoHideDuration: 4500
        })
    }
  )

  const billingInformationFields = {
    firstName: '',
    lastName: '',
    email: '',
    billingSearch: 'no',
    searchedUser: ''
  }

  const [formState, { radio, text, email }] = useFormState(billingInformationFields)

  const billingInformationInputs = {
    firstName: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 6 },
      name: 'firstName',
      label: 'First name',
      inputType: text,
      styleProps: {
        paddingRight: 3
      },
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    lastName: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 6 },
      name: 'lastName',
      label: 'Last name',
      inputType: text,
      styleProps: {
        paddingLeft: 3
      },
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    email: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12 },
      name: 'email',
      label: 'Email',
      inputType: email,
      validateOnBlur: false,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      }
    }
  }

  if (loadingData || membershipLevelLoading || loadingOrganizationUserData) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  const renewalDateString =
    data && data.organization && data.organization.membership.renewalDate
      ? formatDate(data.organization.membership.renewalDate)
      : 'No renewal date set'
  const membershipLevel =
    data && data.organization && membershipLevelData
      ? membershipLevelData.membershipLevels.find(
          m => m.id === data.organization.membership.membershipLevelId
        )
      : null

  const membershipLevelString = membershipLevel
    ? membershipLevel.price === 0
      ? `${membershipLevel.name.split(' ')[0]} Free`
      : `${membershipLevel.name.split(' ')[0]} $${membershipLevel.price} Annual`
    : null

  function handleSubmit() {
    const values = {
      email: formState.values.email,
      firstname: formState.values.firstName,
      lastname: formState.values.lastName
    }

    handleEditBillingContact({
      id: orgId,
      values: values
    })
    setBillingContactChanged(true)
    setContactEditing(false)
  }

  function saveSearchedUser() {
    const values = {
      userId: formState.values.searchedUser.id
    }

    formState.setField('firstName', formState.values.searchedUser.firstname)
    formState.setField('lastName', formState.values.searchedUser.lastname)
    formState.setField('email', formState.values.searchedUser.email)

    handleEditBillingContact({
      id: orgId,
      values: values
    })
    setBillingContactChanged(true)
    setContactEditing(false)
    setSearchItemSelected(false)
  }

  function cancelUserSearch() {
    formState.setField('searchedUser', '')
    formState.setField('billingSearch', 'no')
    setContactEditing(false)
    setSearchItemSelected(false)
  }

  function handleSearchSelect(value) {
    formState.setField('searchedUser', value)

    setSearchItemSelected(true)
  }

  function renderAuthRepRows() {
    if (data.organization.organizationRoles.authorizedSigners.length > 0) {
      const signer = data.organization.organizationRoles.authorizedSigners[0]

      if (signer.user) {
        return <Typography>{signer.user.email}</Typography>
      } else {
        return <Typography>{signer.email}</Typography>
      }
    } else {
      return <Typography>None listed</Typography>
    }
  }

  function renderLegalDesignee() {
    if (data.organization.organizationRoles.legalDesignee) {
      const legal = data.organization.organizationRoles.legalDesignee

      if (legal.user) {
        return <Typography>{legal.user.email}</Typography>
      } else {
        return <Typography>{legal.email}</Typography>
      }
    } else {
      return <Typography>None listed</Typography>
    }
  }

  const disableSubmit = isFormSubmitDisabled(billingInformationInputs, formState)
  let billingContact

  if (
    data &&
    data.organization &&
    data.organization.organizationRoles.billingContact &&
    (data.organization.organizationRoles.billingContact.email ||
      data.organization.organizationRoles.billingContact.user)
  ) {
    billingContact = data.organization.organizationRoles.billingContact
  }

  if (!data || !membershipLevelData) {
    return null
  }

  return (
    <Grid container item direction="column" xs={12}>
      <Grid className={classes.header} item xs={12}>
        <Typography className={classes.headerText} gutterBottom variant="h4">
          Account
        </Typography>
        <Divider />
      </Grid>

      <Grid container item direction="row" xs={12}>
        <Grid className={classes.leftSection} item md={8} sm={12}>
          <Typography className={classes.subHeader} gutterBottom variant="h5">
            Participation Information
          </Typography>
          <div className={classes.informationBox}>
            <Grid container direction="row">
              <Grid className={classes.iconContainer} item xs={1}>
                <InsertInvitationIcon className={classes.icon} />
              </Grid>
              <Grid item className={classes.membershipData} xs={11}>
                <div className={classes.membershipDataSection}>
                  <Typography className={classes.rowHeader}>Renewal Date</Typography>
                  <Typography>{renewalDateString}</Typography>
                </div>
                <div className={classes.membershipDataSection}>
                  <Typography className={classes.rowHeader}>Participation Tier</Typography>
                  {membershipLevelString && (
                    <Typography gutterBottom>{membershipLevelString}</Typography>
                  )}
                  <div>
                    <LinkField
                      className={classes.link}
                      color="primary"
                      label="Review tiers and benefits"
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://telecominfraproject.com/apply-for-membership/"
                    />
                  </div>
                  <RoundedButton
                    fullWidth={false}
                    href="https://forms.monday.com/forms/b593ef9b702f2d1bc0efdd518dee911d?r=use1"
                    target="_blank"
                    variant="outlined"
                    size="small"
                  >
                    Change Request
                  </RoundedButton>
                </div>
                <div className={classes.membershipDataSection}>
                  <Typography className={classes.rowHeader}>
                    Authorized Representative (Required)
                  </Typography>
                  {renderAuthRepRows()}
                </div>
                <div>
                  <Typography className={classes.rowHeader}>
                    Legal Notice Designee (Optional)
                  </Typography>
                  {renderLegalDesignee()}
                </div>
              </Grid>
            </Grid>
          </div>
          <Typography className={classes.infoText}>
            If you have questions about your TIP participation tier, renewal date, billing/payments,
            legal agreements, or project group participation, please consult our{' '}
            <LinkField
              color="primary"
              hideIcon
              label="FAQs"
              target="_blank"
              rel="noopener noreferrer"
              to="https://telecominfraproject.com/faq/"
            />{' '}
            or contact{' '}
            <LinkField
              color="primary"
              hideIcon
              label="TIP Support"
              target="_blank"
              rel="noopener noreferrer"
              to={CONTACT_SUPPORT_URL}
            />
            .
          </Typography>
          <Typography className={classes.infoText}>
            <span className={classes.bold}>All payments must be in US dollars.</span>
          </Typography>
        </Grid>
        <Grid container item direction="row" wrap="nowrap" md={4} sm={12}>
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <Grid item className={classes.billingSectionContainer}>
            <div className={classes.flexRow}>
              <Typography className={classes.subHeader} gutterBottom variant="h5">
                Billing Contact <span>(Required)</span>
              </Typography>
              <TooltipHelper
                iconClass={classes.tooltipIcon}
                label=""
                title={
                  'The Billing Contact is an individual employed by Applicant, who will receive the invoices sent by TIP with the Applicant’s Annual Dues 60 days prior to the date on which payment is due. If no individual is specified, invoices will be submitted to Applicant’s Account Administrator.'
                }
              />
            </div>

            {contactEditing && (
              <RadioGroup
                className={classes.radioGroup}
                {...radio('billingSearch')}
                value={formState.values.billingSearch}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Search for User" />
                <FormControlLabel value="no" control={<Radio />} label="Enter email and name" />
              </RadioGroup>
            )}

            <Grid container className={clsx(formClasses.spacingBottom, classes.billingContactForm)}>
              {!contactEditing ? (
                <div className={classes.billingContactOverviewContainer}>
                  <div className={classes.billingContactOverviewInfo}>
                    {billingContact || billingContactChanged ? (
                      <>
                        <Typography variant="body1" className={classes.overviewName}>
                          {billingContactChanged
                            ? formState.values.firstName + ' ' + formState.values.lastName
                            : billingContact.user
                            ? billingContact.user.firstname + ' ' + billingContact.user.lastname
                            : billingContact.firstname + ' ' + billingContact.lastname}
                        </Typography>
                        <Typography variant="body1">
                          {billingContactChanged
                            ? formState.values.email
                            : billingContact.user
                            ? billingContact.user.email
                            : billingContact.email}
                        </Typography>
                        <Typography variant="body1">
                          {billingContactChanged
                            ? formState.values.jobTitle
                            : billingContact.user
                            ? billingContact.user.jobTitle
                            : billingContact.jobTitle}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1">
                        Please provide a Billing Contact for your organization
                      </Typography>
                    )}
                  </div>
                  <div className={classes.billingContactOverviewDelete}>
                    <RoundedButton
                      color="primary"
                      variant="outlined"
                      fullWidth={false}
                      onClick={() => setContactEditing(true)}
                    >
                      Edit
                    </RoundedButton>
                  </div>
                </div>
              ) : formState.values.billingSearch === 'no' ? (
                <>
                  <Typography variant="body1" className={classes.billingContactFormHeader}>
                    Please enter the billing contact information for your organization.
                  </Typography>
                  <Grid container>
                    {Object.entries(billingInformationInputs).map(
                      ([name, { gridWidth, styleProps, InputComponent, inputType, ...args }]) => (
                        <Grid key={name} item {...gridWidth} style={{ ...styleProps }}>
                          <InputComponent
                            key={name}
                            inputType={inputType}
                            {...args}
                            error={!!formState.errors[name]}
                            helperText={formState.errors[name]}
                            inputProps={{
                              style: { background: '#FFFFFF' }
                            }}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid className={classes.billingContactButtonContainer} item xs={12}>
                    <RoundedButton
                      className={classes.cancelButton}
                      color="primary"
                      variant="outlined"
                      fullWidth={false}
                      onClick={() => setContactEditing(false)}
                    >
                      Cancel
                    </RoundedButton>
                    <RoundedButton
                      color="primary"
                      fullWidth={false}
                      disabled={disableSubmit}
                      onClick={handleSubmit}
                    >
                      {editContactLoading ? <Progress size={25} delay={0} /> : 'Save'}
                    </RoundedButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {!searchItemSelected ? (
                    <>
                      {' '}
                      <Typography>Search for a user</Typography>
                      <Autocomplete
                        id="org-billing-user-search"
                        freeSolo
                        options={organizationUserData.organizationUsers}
                        onChange={(e, v) => handleSearchSelect(v)}
                        getOptionLabel={option => `${option.firstname} ${option.lastname}`}
                        renderInput={params => (
                          <InputTextFieldNew
                            {...params}
                            label="User search"
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                        renderOption={option => (
                          <div className={classes.listOption}>
                            <Typography className={classes.bold}>
                              {option.firstname + ' ' + option.lastname}
                            </Typography>
                            <Typography>{option.email}</Typography>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <Grid className={classes.searchedUserConfirm} direction="column" container>
                      <Grid item>
                        <Typography className={classes.bold}>
                          {formState.values.searchedUser.firstname +
                            ' ' +
                            formState.values.searchedUser.lastname}
                        </Typography>
                        <Typography> {formState.values.searchedUser.email}</Typography>
                      </Grid>
                      <Grid className={classes.searchedUserButtons} item>
                        <RoundedButton
                          className={classes.searchedUserCancel}
                          color="primary"
                          variant="outlined"
                          fullWidth={false}
                          onClick={cancelUserSearch}
                        >
                          Cancel
                        </RoundedButton>
                        <RoundedButton
                          className={classes.searchedUserSave}
                          color="primary"
                          fullWidth={false}
                          onClick={saveSearchedUser}
                        >
                          {editContactLoading ? <Progress size={25} delay={0} /> : 'Save'}
                        </RoundedButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  billingContactForm: {
    background: theme.palette.gray10.main,
    border: `1px solid ${theme.palette.gray11.main}`,
    borderRadius: 3,
    padding: '5px 10px'
  },
  billingContactFormHeader: {
    padding: 8
  },
  billingContactHeader: {
    fontWeight: 'bold',
    marginBottom: 15
  },
  billingContactOverviewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  billingContactButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0'
  },
  billingContactOverviewInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 5,
    position: 'relative'
  },
  billingContactOverviewDelete: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    paddingRight: 10
  },
  billingSectionContainer: {
    flexGrow: 1,
    paddingLeft: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  cancelButton: {
    marginRight: '10px'
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  header: {
    marginBottom: 30
  },
  headerText: {
    marginBottom: 25,
    fontWeight: 'bold'
  },
  icon: {
    color: '#29818C'
  },
  iconContainer: {
    maxWidth: 25
  },
  informationBox: {
    background: '#F7FEFF',
    border: '1px solid #AEDDE2',
    borderRadius: 3,
    padding: 20,
    marginBottom: 25,
    marginTop: 10
  },
  infoText: {
    fontSize: 12,
    marginBottom: 15
  },
  leftSection: {
    paddingRight: 16,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15
    }
  },
  link: {
    marginBottom: 15
  },
  listOption: {
    display: 'flex',
    flexDirection: 'column'
  },
  membershipData: {
    paddingLeft: 10
  },
  membershipDataSection: {
    marginBottom: 20
  },
  overviewName: {
    fontWeight: 'bold'
  },
  radioGroup: {
    marginBottom: 15
  },
  rowHeader: {
    fontWeight: 'bold'
  },
  searchedUserButtons: {
    marginTop: 15
  },
  searchedUserConfirm: {
    padding: 10
  },
  searchedUserCancel: {},
  searchedUserSave: {
    marginLeft: 10
  },
  subHeader: {
    fontWeight: 'bold',
    marginBottom: 0,
    marginRight: 5
  },
  tooltipIcon: {
    color: theme.palette.primary.main
  }
}))
