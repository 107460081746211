import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import makeStyles from '@material-ui/core/styles/makeStyles'

import RestrictedSubGroupRequestsCard from './RestrictedSubGroupRequestsCard'
import { useQuery } from '../../../hooks'
import { GET_RESTRICTED_SUB_GROUP_REQUESTS } from '../queries'
import { useSession } from '../../../auth/queries'

const RestrictedSubGroupRequests = ({ orgId, setOpen }) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const { isImpersonating, isSystemAdmin } = useSession()
  const canModify = !isImpersonating || (isImpersonating && isSystemAdmin)

  const { data: { getRestrictedSubGroupRequests: restrictedRequests = [] } = {} } = useQuery(
    GET_RESTRICTED_SUB_GROUP_REQUESTS,
    {
      fetchPolicy: 'network-only',
      variables: { id: orgId }
    }
  )

  const onResult = useCallback(
    text => {
      enqueueSnackbar(`Sub-Group access ${text}`, {
        variant: 'success',
        autoHideDuration: 4500
      })
    },
    [enqueueSnackbar]
  )

  return (
    <ExpansionPanel defaultExpanded className={classes.expansionPanel} elevation={0}>
      <ExpansionPanelSummary
        classes={{
          root: classes.panelSummaryRoot,
          content: classes.panelSummaryContent,
          expandIcon: classes.expandIcon
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container>
          <Typography className={classes.title}>
            Restricted subgroup participation requests
          </Typography>
          {restrictedRequests.length > 0 ? (
            <div className={classes.notificationCountWrapper}>
              <div className={classes.notificationCount}>{restrictedRequests.length}</div>
            </div>
          ) : null}
        </Grid>
        <Grid container>
          <Typography>
            Participants of your organization need approval to join requested subgroups
          </Typography>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.container}>
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12}>
              <Divider />
              <Grid container className={classes.headerWrapper} spacing={2}>
                <Grid item xs={5}>
                  <Typography className={classes.header}>Participant</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className={classes.header}>Subgroup name</Typography>
                </Grid>
                <Grid className={classes.flexEnd} item xs={2}>
                  <Typography className={classes.header}>Actions</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          {restrictedRequests.map(request => (
            <Grid item key={request.requestId} xs={12}>
              <Divider />
              <RestrictedSubGroupRequestsCard
                onResult={onResult}
                orgId={orgId}
                request={request}
                isReadOnly={!canModify}
                setErrorOpen={setOpen}
              />
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0
  },
  expandIcon: {
    position: 'relative',
    bottom: '10px'
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`,
    margin: '0 !important',
    marginBottom: '32px !important'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray12.main
  },
  headerWrapper: {
    padding: theme.spacing(2)
  },
  notificationCount: {
    alignItems: 'center',
    background: theme.palette.dashboardNotification.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: 25,
    justifyContent: 'center',
    width: 25
  },
  notificationCountWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  panelSummaryContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  panelSummaryRoot: {
    background: theme.palette.gray13.main
  },
  title: {
    fontWeight: 'bold'
  }
}))

export default RestrictedSubGroupRequests
