import { useMutation as mutation } from '@apollo/react-hooks'

export default function useMutation(m, options) {
  const [func, { loading, error, data }] = mutation(m, options)

  const execute = ({ id, values } = {}) => {
    const executeVariables = { variables: {} }

    if (id) {
      executeVariables.variables.id = id
    }

    if (values) {
      executeVariables.variables.input = { ...values }
    }

    return func(executeVariables)
  }

  return {
    loading,
    data,
    error,
    execute
  }
}
