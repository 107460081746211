import React, { useState, useEffect, useRef } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import MaterialTable, { MTableToolbar } from 'material-table'
import { InputAdornment, Typography, Tooltip } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import Search from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import EditUserModal from '../../User/Profile'
import EditOrganizationModal from '../Profile'
import { InputTextFieldNew } from '../../common'
import StatusDropdown from '../../common/table/StatusDropdown'
import { CHANGE_MEMBER_STATUS, REMOVE_USER_FROM_ORG, DELETE_USER } from './mutations'
import { useMutation } from '../../hooks'
import { searchTable } from '../../utils'
import { useSession } from '../../auth/queries'
import DeleteModal from './DeleteModal'
import AnchorMenu from './AnchorMenu'
import ConfirmStatusChangeModal from './ConfirmStatusChangeModal'
import ConfirmRemoveOrgModal from './ConfirmRemoveOrgModal'
import EmailModal from '../../EmailModal/EmailModal'
import { SYS_ADMIN_GET_MEMBERS } from './queries'

export default function MembersTable({
  returnUrl,
  data,
  orgId,
  orgRoles = {},
  isSystemAdmin = false,
  onUpdate,
  filterPending = false,
  isReadOnly,
  searchData
}) {
  const { session } = useSession()
  const classes = useStyles()
  const changeMemberStatusMutation = useMutation(CHANGE_MEMBER_STATUS, { update: onUpdate })
  const removeUserFromOrgMutation = useMutation(REMOVE_USER_FROM_ORG, { update: onUpdate })
  const deleteUserMutation = useMutation(DELETE_USER, {
    refetchQueries: [{ query: SYS_ADMIN_GET_MEMBERS, variables: { input: searchData } }],
    awaitRefetchQueries: true
  })

  const [formState, { text }] = useFormState({ search: '' })
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmData, setConfirmData] = useState({})
  const [removeOrgOpen, setRemoveOrgOpen] = useState(false)
  const [removeOrgData, setRemoveOrgData] = useState({})
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteUserData, setDeleteUserData] = useState({})

  let filterValues = useRef({})

  if (filterPending) {
    filterValues.current = {
      ...filterValues.current,
      statusValue: ['3']
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { updateUserInOrganization = {} } = {},
    loading,
    error
  } = changeMemberStatusMutation
  useEffect(() => {
    // on success show snack bar
    if (updateUserInOrganization.status && !error && !loading) {
      // close modals
      enqueueSnackbar('Status Updated', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [updateUserInOrganization, error, loading, enqueueSnackbar])

  const memberStatusLookup = {
    1: 'approved',
    2: 'suspended',
    3: 'pending', // requested on backend
    4: 'deactivated',
    5: 'declined',
    6: 'active'
  }
  const memberActions = {
    1: 'approved',
    2: 'suspended'
  }
  const suspendedMemberActions = {
    1: 'approved',
    2: 'suspended'
  }
  const activeMemberActions = {
    2: 'suspended',
    6: 'active'
  }

  if (isSystemAdmin) {
    suspendedMemberActions['7'] = 'registered'
    memberStatusLookup['7'] = 'registered'
    memberActions['4'] = 'deactivated'
    suspendedMemberActions['4'] = 'deactivated'
    activeMemberActions['4'] = 'deactivated'
  }

  const approveRequestedActions = { 1: `approved`, 3: `pending`, 5: `declined` }
  const declinedRequestedActions = { 7: `registered`, 5: `declined` } // only available for system admins

  const toLowerCaseOrBlank = text => {
    if (text) {
      return text.toLowerCase()
    } else {
      return ''
    }
  }

  const sortByFirstname = (a, b) => {
    let aName = toLowerCaseOrBlank(a.firstname)
    let bName = toLowerCaseOrBlank(b.firstname)

    return aName.localeCompare(bName)
  }

  const memberColumns = [
    {
      title: 'Name',
      searchable: true,
      defaultSort: 'asc',
      field: 'firstname',
      customSort: sortByFirstname,
      defaultFilter: filterValues.current.firstname || '',
      customFilterAndSearch: (searchText, row, columnDef) => {
        let value = row.firstname + ' ' + row.lastname

        return value
          .toString()
          .toUpperCase()
          .includes(searchText.toUpperCase())
      },
      render: rowData => {
        return (
          <EditUserModal
            returnUrl={returnUrl}
            id={rowData.id}
            orgId={orgId || rowData.orgId}
            onUpdate={onUpdate}
          >
            {onClick => (
              <Typography className={classes.orgNameLink} onClick={onClick}>
                {`${rowData.firstname} ${rowData.lastname}`}
              </Typography>
            )}
          </EditUserModal>
        )
      }
    },
    {
      title: 'Job Title',
      field: 'jobTitle',
      searchable: true,
      defaultFilter: filterValues.current.jobTitle || ''
    },
    {
      title: 'Org Name',
      searchable: true,
      hidden: !isSystemAdmin,
      defaultSort: 'asc',
      field: 'orgName',
      defaultFilter: filterValues.current.orgName || '',
      render: rowData => {
        if (orgId || rowData.orgId) {
          return (
            <EditOrganizationModal id={orgId || rowData.orgId} returnUrl={returnUrl}>
              {onClick => (
                <Typography className={classes.orgNameLink} onClick={onClick}>
                  {rowData.orgName}
                </Typography>
              )}
            </EditOrganizationModal>
          )
        } else {
          return null
        }
      },
      customSort: (a, b) => {
        if (!a.orgName) {
          return -1
        }

        if (!b.orgName) {
          return 1
        }

        return a.orgName.toLowerCase().localeCompare(b.orgName.toLowerCase())
      }
    },
    {
      title: 'Email',
      field: 'email',
      searchable: true,
      defaultFilter: filterValues.current.email || ''
    },
    {
      title: 'NRF Step',
      field: 'signUpStep',
      searchable: true,
      defaultFilter: filterValues.current.signUpStep || '',
      customFilterAndSearch: (searchText, row, columnDef) => {
        let value = row.signUpStep

        if (!value) {
          value = 'Completed'
        }

        return value
          .toString()
          .toUpperCase()
          .includes(searchText.toUpperCase())
      },
      render: rowData => {
        let tooltipText = ''

        if (!rowData.signUpStep) {
          tooltipText = 'Finished Sign Up'
        } else {
          switch (rowData.signUpStep) {
            case 1:
              tooltipText = 'Create User'
              break
            case 2:
              tooltipText = 'User Profile'
              break
            case 3:
              tooltipText = 'Select Organization'
              break
            case 4:
              tooltipText = 'Organization Profile'
              break
            case 5:
              tooltipText = 'Summary'
              break
            case 6:
              tooltipText = 'Billing Information'
              break
            case 7:
              tooltipText = 'Membership Tier'
              break
            case 8:
              tooltipText = 'GPA Authorized Representative'
              break
            case 9:
              tooltipText = 'Review/Send GPA'
              break
            case 10:
              tooltipText = 'GPA Pending'
              break
            case 11:
              tooltipText = 'Request Pending'
              break
            case 12:
              tooltipText = 'Welcome Modal'
              break
            default:
              tooltipText = 'No Step Listed'
          }
        }

        return (
          <div className={classes.capitalize}>
            <Tooltip title={tooltipText}>
              <div>{rowData.signUpStep ? rowData.signUpStep : 'Completed'}</div>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Role',
      // we are showing this now for both sys admins and account admins
      // hidden: !isSystemAdmin,
      lookup: {
        1: 'Account Admin',
        2: 'Billing Contact',
        3: 'Legal Notice Designee',
        4: 'Authorized Signer'
      },
      field: 'roles',
      searchable: false,
      defaultFilter: filterValues.current.roles || '',
      customSort: (a, b) => {
        const aName = a.roles.toLowerCase()
        const bName = b.roles.toLowerCase()

        if (aName < bName) {
          return -1
        }
        if (aName > bName) {
          return 1
        }

        return 0
      },
      customFilterAndSearch: (terms, rowData) => {
        if (terms.length === 0) {
          return true
        }

        if (rowData.roleValues && rowData.roleValues.length > 0) {
          // if user has any of the roles selected display them
          if (terms.some(r => rowData.roleValues.includes(r))) {
            return true
          }
        }
      },
      render: rowData => {
        let tooltipText = ''

        if (rowData.orgRoles && rowData.orgRoles.length > 0) {
          if (rowData.orgRoles.includes('AA')) {
            tooltipText += 'AA: Account Admin,'
          }

          if (rowData.orgRoles.includes('BC')) {
            tooltipText += ' BC: Billing Contact,'
          }

          if (rowData.orgRoles.includes('AR')) {
            tooltipText += ' AR: Authorized Representative,'
          }

          if (rowData.orgRoles.includes('LND')) {
            tooltipText += ' LND: Legal Notice Designee'
          }

          tooltipText = tooltipText.replace(/,\s*$/, '')

          return (
            <div className={classes.capitalize}>
              <Tooltip title={tooltipText}>
                <div>{rowData.orgRoles ? rowData.orgRoles.join(',') : ''}</div>
              </Tooltip>
            </div>
          )
        } else if (rowData.roleValues && rowData.roleValues.length > 0) {
          let roles = []

          if (rowData.roleValues.includes('1')) {
            tooltipText += 'AA: Account Admin,'
            roles.push('AA')
          }

          if (rowData.roleValues.includes('2')) {
            tooltipText += ' BC: Billing Contact,'
            roles.push('BC')
          }

          if (rowData.roleValues.includes('3')) {
            tooltipText += ' LND: Legal Notice Designee'
            roles.push('LND')
          }

          if (rowData.roleValues.includes('4')) {
            tooltipText += ' AR: Authorized Representative,'
            roles.push('AR')
          }

          tooltipText = tooltipText.replace(/,\s*$/, '')

          return (
            <div className={classes.capitalize}>
              <Tooltip title={tooltipText}>
                <div>{roles.length > 0 ? roles.join(',') : ''}</div>
              </Tooltip>
            </div>
          )
        } else {
          return null
        }
      }
    },
    {
      title: 'Join Date',
      customFilterAndSearch: (value, rowData) => {
        if (!value) {
          return true
        }

        if (!rowData.joinDate) {
          return false
        }

        return new Date(value).toDateString() === new Date(rowData.joinDate).toDateString()
      },
      filterComponent: props => (
        <DatePicker
          clearable
          format="MM/dd/yyyy"
          value={props.columnDef.tableData.filterValue}
          onChange={value => props.onFilterChanged(props.columnDef.tableData.id, value)}
        />
      ),
      field: 'joinDate',
      type: 'date',
      defaultFilter: filterValues.current.joinDate || null,
      render: rowData => (rowData.joinDate ? format(rowData.joinDate, 'MM/dd/yyyy') : null)
    },
    {
      title: 'Status',
      field: 'statusValue',
      lookup: memberStatusLookup,
      searchable: false,
      defaultFilter: filterValues.current.statusValue || '',
      render: rowData => {
        return (
          <StatusDropdown
            value={rowData.statusValue}
            name={rowData.status}
            options={!isReadOnly ? rowData.statusOptions : null}
            isActive={rowData.isActive}
            onChange={e => {
              if (rowData.status !== memberStatusLookup[e.target.value]) {
                setConfirmData({
                  toStatus: memberStatusLookup[e.target.value],
                  fromStatus: rowData.status,
                  name: `${rowData.firstname} ${rowData.lastname}`,
                  email: `${rowData.email}`,
                  orgId: orgId || rowData.orgId,
                  userId: rowData.id
                })

                setConfirmOpen(true)
              }
            }}
          />
        )
      }
    },
    {
      title: 'Actions',
      hidden: !isSystemAdmin,
      render: rowData => {
        return (
          <div>
            <AnchorMenu
              orgId={orgId || rowData.orgId}
              setRemoveOrgData={setRemoveOrgData}
              setRemoveOrgOpen={setRemoveOrgOpen}
              setDeleteUserData={setDeleteUserData}
              setDeleteModalOpen={setDeleteModalOpen}
              rowData={rowData}
            />
          </div>
        )
      }
    }
  ]

  const columnDef = memberColumns

  let searchText = formState.values.search

  const formattedData = searchTable(data, searchText, columnDef).map(obj => {
    let s = obj.status === 'requested' ? 'pending' : obj.status

    if (obj.status === 'approved' && obj.percentComplete === 100 && obj.projectGroupCount > 0) {
      s = 'active'
    }
    const value = Object.keys(memberStatusLookup).find(key => memberStatusLookup[key] === s)

    let roles = ''
    let roleValues = []

    if (orgRoles) {
      if (orgRoles.organizationAdmin && orgRoles.organizationAdmin.id === obj.id) {
        roles = 'Account Admin'
        roleValues.push('1')
      }

      if (
        orgRoles.billingContact &&
        (orgRoles.billingContact.id === obj.id ||
          (orgRoles.billingContact.user && orgRoles.billingContact.user.id === obj.id) ||
          orgRoles.billingContact.email === obj.email)
      ) {
        roles += ', Billing Contact'
        roleValues.push('2')
      }

      if (
        orgRoles.legalDesignee &&
        (orgRoles.legalDesignee.id === obj.id ||
          (orgRoles.legalDesignee.user && orgRoles.legalDesignee.user.id === obj.id) ||
          orgRoles.legalDesignee.email === obj.email)
      ) {
        roles += ', Legal Notice Designee'
        roleValues.push('3')
      }

      if (
        orgRoles.authorizedSigners &&
        (orgRoles.authorizedSigners.some(u => u.user && u.user.id === obj.id) ||
          orgRoles.authorizedSigners.some(u => u.email === obj.email))
      ) {
        roles += ', Authorized Signer'
        roleValues.push('4')
      }
    }

    if (roles.startsWith(', ')) {
      roles = roles.substr(2)
    }

    const statusOptions = roleValues.length === 0 || s === 'pending' ? getStatusOptions(s) : null

    return { ...obj, status: s, statusValue: value, statusOptions, roleValues, roles }
  })

  function handleCloseConfirm() {
    setConfirmData({})
    setConfirmOpen(false)
  }

  function handleStatusConfirm() {
    changeMemberStatusMutation.execute({
      values: {
        orgId: confirmData.orgId,
        userId: confirmData.userId,
        status: confirmData.toStatus,
        beforeStatus: confirmData.fromStatus
      }
    })

    // TODO send (put this on the callback)
    setConfirmData({})
    setConfirmOpen(false)
  }

  function handleCloseRemoveOrgConfirm() {
    setRemoveOrgOpen(false)
  }

  function handleRemoveOrgConfirm() {
    removeUserFromOrgMutation.execute({
      values: {
        id: removeOrgData.orgId,
        userId: removeOrgData.userId
      }
    })

    setRemoveOrgOpen(false)
  }

  function handleDeleteUser() {
    deleteUserMutation.execute({
      values: {
        userId: deleteUserData.userId
      }
    })

    setDeleteModalOpen(false)
  }

  function getStatusOptions(inviteStatus) {
    let options = null
    switch (inviteStatus) {
      case 'pending': //requested
        options = approveRequestedActions
        break
      case 'declined':
        if (isSystemAdmin) {
          options = declinedRequestedActions
        }
        break
      case 'active':
        options = activeMemberActions
        break
      case 'approved':
        options = memberActions
        break
      case 'suspended':
        options = suspendedMemberActions
        break
      default:
        break
    }
    return options
  }

  return (
    <>
      <DeleteModal
        handleDeleteUser={handleDeleteUser}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
      />
      <Grid className={classes.toolbar} item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <InputTextFieldNew
              autoComplete="new-password" // don't want email autofill
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              inputType={text}
              label="Search"
              name="search"
              value={formState.values.search}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MaterialTable
            title="Manage Participants"
            columns={columnDef}
            data={formattedData}
            options={{
              filtering: true,
              filterCellStyle: {
                verticalAlign: 'bottom'
              },
              pageSize: 10,
              search: false,
              selection: !isReadOnly,
              showTitle: false,
              draggable: false
            }}
            actions={
              !isReadOnly && [
                rowData => ({
                  icon: () => (
                    <EmailModal
                      toList={rowData.map(u => u.email)}
                      sendCopy={true}
                      sender={session.user.email}
                      basicEmail={false}
                    >
                      {onClick => <MailIcon onClick={onClick} />}
                    </EmailModal>
                  ),
                  tooltip: 'Email Selected Participants'
                })
              ]
            }
            components={{
              Toolbar: props => {
                props.columns.forEach(c => {
                  const field = c.field
                  const filterValue = c.tableData.filterValue

                  if (filterValue) {
                    filterValues.current[field] = filterValue
                  } else if (filterValues.current[field]) {
                    filterValues.current[field] = null
                  }
                })

                // could get filter values here and re apply
                if (props.selectedRows.length > 0) {
                  return <MTableToolbar {...props} />
                }
                return null
              }
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <ConfirmStatusChangeModal
        data={confirmData}
        open={confirmOpen}
        handleConfirm={handleStatusConfirm}
        handleClose={handleCloseConfirm}
      />
      <ConfirmRemoveOrgModal
        open={removeOrgOpen}
        handleConfirm={handleRemoveOrgConfirm}
        handleClose={handleCloseRemoveOrgConfirm}
      />
    </>
  )
}

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline',
    marginRight: 30
  },
  toolbarItem: {
    paddingTop: 15,
    margin: 'auto'
  },
  rightAlign: {
    textAlign: 'right'
  },
  alignMiddle: {
    margin: 'auto'
  },
  bulkButton: {
    width: 200,
    marginRight: 50
  },
  toolbar: {
    paddingBottom: 0
  },
  filterIcon: {
    marginRight: 5
  },
  orgNameLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  stats: {
    margin: '40px 5px'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  progress: {
    textAlign: 'center',
    padding: 20
  },
  emailIconRoot: {
    backgroundColor: 'green',
    ' &:hover': {
      backgroundColor: 'red'
    }
  },
  removeOrgButton: {
    fontSize: 12
  }
}))
