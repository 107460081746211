import React, {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import gql from 'graphql-tag'
import { Chip, Divider, makeStyles, Tooltip, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { VariableSizeList } from 'react-window'
import clsx from 'clsx'

import { InputTextFieldNew, Progress } from '.'
import { useQuery } from '../hooks'

const filter = createFilterOptions()

const OrganizationAutocomplete = ({
  affiliate,
  allowNewOrgs = true,
  disabled,
  hideListHeader = false,
  idList,
  includePreApprovedOrgs = false,
  InputProps,
  multiple = false,
  name,
  onChange = () => null,
  onInputChange = () => null,
  setField,
  value,
  ...rest
}) => {
  const classes = useStyles()

  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const { data: { publicOrganizations } = {}, loading } = useQuery(
    gql`
      query($idList: [String], $includePreApprovedOrgs: Boolean) {
        publicOrganizations(idList: $idList, includePreApprovedOrgs: $includePreApprovedOrgs) {
          id
          approvalStatus
          name
          shippingAddress {
            address1
            address2
            city
            state
            country
            postalCode
          }
        }
      }
    `,
    {
      variables: { idList, includePreApprovedOrgs }
    }
  )

  useEffect(() => {
    if (!loading && publicOrganizations) {
      const suggestions = publicOrganizations.map(
        ({ name, id, shippingAddress, approvalStatus }) => ({
          value: id,
          label: name,
          address: shippingAddress.address1 ? shippingAddress : null,
          approvalStatus
        })
      )

      suggestions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

      setSuggestions(suggestions)
    }
  }, [loading, publicOrganizations])

  if (loading) {
    return (
      <div className={classes.progress}>
        <Progress />
      </div>
    )
  }

  return (
    <Autocomplete
      autoHighlight
      freeSolo
      fullWidth
      selectOnFocus
      autoSelect={!multiple}
      classes={{
        inputRoot: classes.inputRoot
      }}
      clearOnBlur={multiple}
      disabled={disabled}
      disableCloseOnSelect={multiple}
      filterOptions={(options, params) => {
        params.inputValue = params.inputValue.replace(/\s+/g, ' ').trim()

        const filtered = filter(options, params)

        if (multiple) {
          value.forEach(v => {
            const index = filtered.findIndex(f => v === f.value)

            if (index >= 0) {
              filtered.splice(index, 1)
            }
          })
        } else {
          if (
            allowNewOrgs &&
            !affiliate &&
            params.inputValue !== '' &&
            !options.some(
              option => option.label.toLowerCase() === params.inputValue.toLowerCase()
            ) &&
            params.inputValue.replace(/\s/g, '').length > 0
          ) {
            const newValue = params.inputValue.replace(/\s+/g, ' ').trim()

            filtered.push({
              label: newValue,
              viewLabel: `Add "${newValue}"`
            })
          }
        }

        return filtered
      }}
      getOptionLabel={option => (option.label ? option.label : '')}
      getOptionSelected={(option, value) =>
        multiple ? option.value === value : option.label === value.label
      }
      inputValue={inputValue}
      ListboxComponent={ListboxComponent}
      ListboxProps={{ hideListHeader }}
      multiple={multiple}
      options={suggestions}
      value={value}
      renderInput={({ inputProps, InputProps: InputPropsParams, ...inputRest }) => (
        <InputTextFieldNew
          {...inputRest}
          blockInputTypeOnChange={true}
          InputProps={{
            ...InputPropsParams,
            ...InputProps,
            inputProps: {
              ...inputProps,
              className: clsx(inputProps.className, classes.inputTextField)
            }
          }}
          name={name}
          value={inputProps.value}
          {...rest}
        />
      )}
      renderOption={option => (
        <div className={classes.optionContainer}>
          <Typography noWrap className={classes.option}>
            {boldLabel(option.viewLabel ? option.viewLabel : option.label, inputValue)}
            <br />
            <Typography className={classes.optionAddress} variant="caption">
              {option.value
                ? option.address
                  ? `${option.address.address1}, ${option.address.city}, ${option.address.state}, ${
                      option.address.country
                    }`
                  : 'No Address Listed'
                : 'New Organization'}
            </Typography>
          </Typography>
          <Divider />
        </div>
      )}
      renderTags={(value, getTagProps) =>
        multiple
          ? suggestions
              .filter(s => value.includes(s.value))
              .map(option => (
                <Tooltip arrow key={option.value} title={option.label || ''}>
                  <Chip
                    {...getTagProps({ index: value.findIndex(v => v === option.value) })}
                    className={classes.chip}
                    deleteIcon={<ClearIcon />}
                    label={option.label}
                    size="small"
                  />
                </Tooltip>
              ))
          : value
      }
      onChange={(e, newValue) => {
        const result = multiple ? [] : { id: '', label: '' }

        if (multiple) {
          newValue.forEach(n => result.push(typeof n !== 'string' ? n.value : n))
        } else if (newValue && (typeof newValue !== 'string' || newValue === value.label)) {
          if (newValue.hasOwnProperty('label')) {
            result.id = newValue.value || ''
            result.label = newValue.label
          } else {
            const newOrg = suggestions.find(s => s.label === newValue)

            if (newOrg) {
              result.id = newOrg.value
              result.label = newOrg.label
            } else {
              result.label = newValue
            }
          }
        }

        setField(name, result)
        onChange(newValue)
      }}
      onInputChange={(e, newValue) => {
        setInputValue(newValue)
        onInputChange()
      }}
    />
  )
}

const OuterElementContext = createContext({})

const ListboxComponent = forwardRef((props, ref) => {
  const classes = useStyles()

  const { children, hideListHeader, ...other } = props
  const itemData = Children.toArray(children)
  const itemSize = 65

  const gridRef = useResetCache(itemData.length)

  return (
    <div ref={ref}>
      {!hideListHeader && (
        <Typography className={classes.listSubheader} variant="body2">
          Select a current TIP participant or keep typing
        </Typography>
      )}
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          innerElementType="ul"
          itemData={itemData}
          height={(itemData.length > 3 ? 3 : itemData.length) * itemSize + 16}
          outerElementType={forwardRef((props, ref) => (
            <div ref={ref} {...props} {...useContext(OuterElementContext)} />
          ))}
          ref={gridRef}
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemData.length}
        >
          {({ data, index, style }) =>
            cloneElement(data[index], {
              style: {
                ...style,
                top: style.top + 8
              }
            })
          }
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const useResetCache = data => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])

  return ref
}

const boldLabel = (label, inputValue) => {
  const newValue = inputValue.replace(/\s+/g, ' ').trim()
  const index = label.toLowerCase().indexOf(newValue.toLowerCase())

  return (
    <>
      {label.substring(0, index)}
      <b>{label.substring(index, index + newValue.length)}</b>
      {label.substring(index + newValue.length)}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: 2,
    margin: 2,
    maxWidth: '100%',
    zIndex: 1
  },
  inputRoot: ({ size }) => ({
    minHeight: size === 'small' ? 40 : 56
  }),
  inputTextField: {
    padding: '4.5px !important'
  },
  listSubheader: {
    backgroundColor: theme.palette.gray.main,
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  option: {
    lineHeight: '20px',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`
  },
  optionAddress: {
    color: theme.palette.gray8.main
  },
  optionContainer: {
    margin: `0 -${theme.spacing(2)}px`,
    width: `calc(100% + ${theme.spacing(4)}px)`
  },
  progress: {
    textAlign: 'center'
  }
}))

export default OrganizationAutocomplete
