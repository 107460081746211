import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { Redirect } from 'react-router-dom'
import clsx from 'clsx'
import gql from 'graphql-tag'

import WelcomeMessage from './WelcomeMessage'
import CompanyCard from '../Organization/CompanyCard'
import UserCard from '../User/UserCard'
import WelcomeModal from '../SignUp/WelcomeModal'
import MembershipDeclinedModal from '../SignUp/MembershipDeclinedModal'
import OrganizationVerificationRequests from '../Organization/FirstUseLanding/OrganizationVerificationRequests'
import RoundedButton from '../common/RoundedButton'
import LinkField from '../common/LinkField'
import Progress from '../common/Progress'
import {
  useUserOrganization,
  useOrganization,
  useSession,
  getSessionQueries
} from '../auth/queries'
import { GET_ORGANIZATION_DATA } from '../Navigation/queries'
import { EDIT_USER_PROFILE } from '../SignUp/mutations'
import ResendOrgRequestModal from '../SignUp/ResendOrgRequestModal'
import { REMOVE_DECLINED_USER_FROM_ORG } from './mutations'
import { GET_USER_DATA } from './queries'
import { useQuery, useMutation } from '../hooks'
import { CONTACT_SUPPORT_URL } from '../utils/constants'
import G5Alert from './G5Alert'

import OrgFirstUseLanding from '../Organization/FirstUseLanding/FirstUseLanding'

const useStyles = makeStyles(theme => ({
  gridItem: {
    margin: 15
  },
  gridBorder: {
    border: `1px solid ${theme.palette.borderGray.main}`
  },
  content: {
    overflow: 'hidden'
  },
  dialogWarningContainer: {
    display: 'flex',
    background: '#FFF8F2 0% 0% no-repeat padding-box',
    border: '1px solid #F1C1B7',
    padding: 20,
    marginBottom: 20
  },
  warningIcon: {
    color: '#EA7055'
  },
  warningTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  warningTextContainer: {
    marginLeft: 15
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 20px'
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    border: `1px solid ${theme.palette.gray7.main}`,
    padding: 25,
    borderRadius: '6px'
  },
  actionTitle: {
    fontWeight: 600,
    marginBottom: 10
  },
  actionText: {
    fontSize: 14,
    marginBottom: 15
  },
  marginRight: {
    marginRight: 30
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    marginTop: 'auto'
  }
}))

const Home = () => {
  const classes = useStyles()
  const {
    loading,
    userId,
    isOrgAdmin,
    isPrimaryContact,
    isSystemAdmin,
    isReadOnlySystemAdmin
  } = useSession()

  const [welcomeModalOpen, setWelcomeModalOpen] = useState(true)
  const [requestModalOpen, setRequestModalOpen] = useState(false)

  const { loading: orgUserLoading, organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { orgId, orgStatusPending, membershipStatus } = organizationUser

  const { execute: updateUser, loading: updateUserLoading } = useMutation(EDIT_USER_PROFILE, {
    refetchQueries: [{ query: GET_USER_DATA, variables: { id: userId } }]
  })

  const { execute: removeUserFromOrg } = useMutation(REMOVE_DECLINED_USER_FROM_ORG)

  const options = userId
    ? {
        refetchQueries: getSessionQueries(userId),
        awaitRefetchQueries: true
      }
    : {}

  const { loading: joinOrgLoading, execute: requestToJoin } = useMutation(
    REQUEST_TO_JOIN_ORGANIZATION,
    {
      ...options,
      onCompleted: data =>
        data.requestToJoinOrganization.result &&
        updateUser({ id: userId, values: { signUpStep: 5 } })
    }
  )

  const { loading: useOrgLoading, organization: useOrg } = useOrganization({
    variables: { id: orgId },
    skip: !orgId
  })

  const {
    data: { organization = { organizationRoles: { authorizedSigners: [] } } } = {},
    loading: organizationLoading
  } = useQuery(GET_ORGANIZATION_DATA, {
    variables: { id: orgId },
    skip: (!isPrimaryContact && !isOrgAdmin) || !orgId
  })
  const { data: { user = {} } = {}, loading: userLoading } = useQuery(GET_USER_DATA, {
    variables: { id: userId },
    skip: !userId
  })

  if (loading || orgUserLoading || organizationLoading || userLoading || useOrgLoading) {
    return null
  }

  let mainDisplay = !isSystemAdmin && !isReadOnlySystemAdmin ? <WelcomeMessage /> : null
  // if youre an org admin or primary contact
  // you see org first use workflow if not (suspended, declined, or deactivated)
  const orgApprovalStatus = organization.approvalStatus
  const blockedStatuses = ['suspended', 'declined', 'deactivated']
  if (orgId && !blockedStatuses.includes(orgApprovalStatus) && isOrgAdmin) {
    mainDisplay = <OrgFirstUseLanding userId={userId} orgId={orgId} />
  }

  let isAuthorizedSigner = false

  const signers =
    organization && organization.organizationRoles
      ? organization.organizationRoles.authorizedSigners
      : null

  if (signers) {
    for (let s of signers) {
      if (s.user) {
        if (s.user.id === userId) {
          isAuthorizedSigner = true
          break
        }
      }
    }
  }

  const resetToSelectOrg = () => {
    updateUser({
      id: userId,
      values: { signUpStep: 3 }
    })
    removeUserFromOrg({
      values: {
        id: orgId,
        userId: userId
      }
    })
  }

  if (user.signUpStep && user.signUpStep < 12) {
    return <Redirect to="/sign-up" />
  }

  return (
    <div className={classes.content}>
      <Grid container spacing={4}>
        <Grid item md={12} lg={8} className={classes.gridBorder}>
          {user && !user.g5AlertConfirmed && <G5Alert userId={userId} />}
          {isSystemAdmin && <OrganizationVerificationRequests orgId={orgId} />}
          {mainDisplay}
          {organizationUser.membershipStatus === 'declined' && (
            <>
              <div style={{ padding: '0 20px' }}>
                <div className={classes.dialogWarningContainer}>
                  <WarningIcon className={classes.warningIcon} />
                  <div className={classes.warningTextContainer}>
                    <Typography className={classes.warningTitle}>
                      Participation request declined
                    </Typography>
                    <Typography>
                      Your request to join the TIP participation account of {useOrg.name} has been
                      declined.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.actionContainer}>
                <div className={clsx(classes.actionBox, classes.marginRight)}>
                  <Typography className={classes.actionTitle}>Re-send request</Typography>
                  <Typography className={classes.actionText}>
                    {organizationUser.requestResent
                      ? 'You have already re-submitted your participation request. Contact TIP Support for more information.'
                      : "You may re-send your request to the organization's Account Administrator."}
                  </Typography>
                  {organizationUser.requestResent ? (
                    <LinkField
                      className={classes.supportLink}
                      color="primary"
                      label={'Contact TIP Support'}
                      to={CONTACT_SUPPORT_URL}
                      hideIcon={true}
                      hideBorder={true}
                    />
                  ) : (
                    <RoundedButton
                      className={classes.button}
                      fullWidth={false}
                      color="primary"
                      onClick={() =>
                        requestToJoin({
                          values: {
                            orgId: orgId,
                            userId
                          }
                        })
                      }
                    >
                      {joinOrgLoading ? <Progress size={30} delay={0} /> : 'Re-send'}
                    </RoundedButton>
                  )}
                </div>
                <div className={classes.actionBox}>
                  <Typography className={classes.actionTitle}>
                    Select a different organization
                  </Typography>
                  <Typography className={classes.actionText}>
                    You may request participation with a different organization. You must be an
                    employee of that organization.
                  </Typography>
                  <RoundedButton
                    className={classes.button}
                    fullWidth={false}
                    color="primary"
                    onClick={() => resetToSelectOrg()}
                  >
                    {updateUserLoading ? <Progress size={30} delay={0} /> : 'Go to selection'}
                  </RoundedButton>
                </div>
              </div>
            </>
          )}
        </Grid>
        <Grid item md={12} lg={4} className={classes.gridBorder}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {!loading && userId && (
                <UserCard userId={userId} orgId={!orgStatusPending && orgId} />
              )}
            </Grid>
            <Grid item xs={12}>
              {!loading && orgId && !orgStatusPending && membershipStatus !== 'declined' && (
                <CompanyCard orgId={orgId} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {user.signUpStep === 12 && (
        <>
          {organizationUser.membershipStatus === 'approved' && (
            <WelcomeModal
              userId={userId}
              userRoles={user.roles}
              orgId={orgId}
              isOrgAdmin={isOrgAdmin}
              isOpen={welcomeModalOpen}
              isAuthorizedSigner={isAuthorizedSigner}
              setOpen={setWelcomeModalOpen}
            />
          )}
          {organizationUser.membershipStatus === 'declined' && (
            <MembershipDeclinedModal
              userId={userId}
              orgName={useOrg.name}
              isOpen={welcomeModalOpen}
              setOpen={setWelcomeModalOpen}
              requestResent={organizationUser.requestResent}
              joinOrgLoading={joinOrgLoading}
              updateUserLoading={updateUserLoading}
              resetToSelectOrg={resetToSelectOrg}
              handleSubmit={() =>
                requestToJoin({
                  values: {
                    orgId: orgId,
                    userId
                  }
                })
              }
            />
          )}
        </>
      )}
      <ResendOrgRequestModal
        isOpen={requestModalOpen}
        setOpen={setRequestModalOpen}
        handleSubmit={() =>
          requestToJoin({
            values: {
              orgId: orgId,
              userId
            }
          })
        }
      />
    </div>
  )
}

export const REQUEST_TO_JOIN_ORGANIZATION = gql`
  mutation($input: OrganizationUserInput!) {
    requestToJoinOrganization(input: $input) {
      data
      result
    }
  }
`

export default Home
