import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useFormState } from 'react-use-form-state'
import { makeStyles } from '@material-ui/styles'
import RoundedButton from '../common/RoundedButton'
import Typography from '@material-ui/core/Typography'
import GlossiesForm from './GlossiesForm'
import GlossyDocumentEntry from './GlossyDocumentEntry'
import GlossiesSuccessModal from './GlossiesSuccessModal'
import documents from './glossiesDocumentList'

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: '1140px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '0 auto',
    marginTop: '-75px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 30px'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '0'
    }
  },
  documentListContainer: {
    background: '#FFF',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      marginBottom: 20
    }
  },
  glossiesForm: {
    position: 'sticky',
    top: 100,
    background: '#F6F6F6',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    marginLeft: 50,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      position: 'static',
      top: 0
    }
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      padding: '15px 15px 15px 35px'
    }
  },
  formTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  sectionText: {
    fontSize: 14,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  numberCircle: {
    display: 'flex',
    flex: 'none',
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: '#aecd60',
    color: '#FFF',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resetButton: {
    width: 200,
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      marginTop: 20
    }
  }
}))

function validateReset(documents, formState) {
  let resetValid = false
  let docArray = []
  for (let doc in documents) {
    docArray.push(doc)
    if (documents[doc].subDocuments) {
      documents[doc].subDocuments.forEach(sd => {
        docArray.push(sd.name)
      })
    }
  }

  for (let i = 0; i < docArray.length; i++) {
    if (formState.values[docArray[i]]) {
      resetValid = true
      break
    }
  }
  return !resetValid
}

function resetForm(formState) {
  formState.reset()
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

const GlossiesContent = () => {
  const classes = useStyles()
  const [modalIsOpen, setModalOpen] = useState(false)

  let glossiesForm = {
    firstName: '',
    lastName: '',
    workEmail: '',
    privacyPolicy: false,
    selectAllDCSG: false
  }

  for (let doc in documents) {
    if (!glossiesForm.hasOwnProperty(doc)) {
      glossiesForm[doc] = false
    }
    if (documents[doc].subDocuments) {
      documents[doc].subDocuments.forEach(sd => {
        if (!glossiesForm.hasOwnProperty(sd.name)) {
          glossiesForm[sd.name] = false
        }
      })
    }
  }

  const [formState, inputTypes] = useFormState(glossiesForm)

  let disableSubmit = validateReset(documents, formState)

  return (
    <Grid container spacing={0} className={classes.content}>
      <Grid container item spacing={0} className={classes.documentListContainer}>
        <div className={classes.sectionTitleContainer}>
          <div className={classes.numberCircle}>1</div>
          <Typography className={classes.sectionTitle}>
            Select the TIP Documents you would like to download
          </Typography>
        </div>
        {Object.keys(documents).map(d => (
          <GlossyDocumentEntry
            description={documents[d].description}
            formState={formState}
            image={documents[d].image}
            inputType={inputTypes['checkbox']}
            key={documents[d].name}
            name={documents[d].name}
            projectGroup={documents[d].projectGroup}
            subDocuments={documents[d].subDocuments}
            selected={formState.values[documents[d].name]}
          />
        ))}
        <RoundedButton
          onClick={() => resetForm(formState)}
          disabled={disableSubmit}
          color="primary"
          className={classes.resetButton}
        >
          Start Over
        </RoundedButton>
      </Grid>
      <Grid container item spacing={0} className={classes.glossiesForm}>
        <div className={classes.formTitleContainer}>
          <div className={classes.numberCircle}>2</div>
          <Typography className={classes.sectionTitle}>Fill out the form below</Typography>
        </div>
        <Typography className={classes.sectionText}>
          We will send an email with links to the documents you've selected.
        </Typography>
        <GlossiesForm
          formState={formState}
          inputTypes={inputTypes}
          documents={documents}
          setOpen={setModalOpen}
        />
      </Grid>
      <GlossiesSuccessModal isOpen={modalIsOpen} setOpen={setModalOpen} />
    </Grid>
  )
}

export default GlossiesContent
