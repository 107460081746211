import React, { forwardRef } from 'react'
import { IconButton } from '@material-ui/core'

import {
  CheckboxDropdown,
  CheckboxField,
  CountryDropdown,
  InputTextFieldNew,
  PhoneNumberInput,
  PlacesAutocomplete
} from '../../common'
import LaunchIcon from '@material-ui/icons/Launch'
import InputAdornment from '@material-ui/core/InputAdornment'
import { inputRegexes } from '../../utils'

function validateUrl(url) {
  var prefix = 'http'
  if (url.substr(0, prefix.length) !== prefix) {
    url = 'http://' + url
  }
  return url
}

const formFields = (
  formState,
  tabLoopStart,
  tabLoopEnd,
  isSystemAdmin,
  isReadOnlySystemAdmin,
  membership
) =>
  formState
    ? {
        name: {
          autoFocus: true,
          InputComponent: tabLoopStart ? TextFieldForwardRef : InputTextFieldNew,
          inputType: 'text',
          label: 'Organization Name',
          name: 'name',
          ref: tabLoopStart,
          validator: {
            required: true,
            minLength: 2,
            maxLength: 100
          }
        },
        // affiliateName: {},
        description: {
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'Organization Description (Optional)',
          multiline: true,
          name: 'description',
          rows: 4,
          validator: {
            minLength: 2,
            maxLength: 1000
          }
        },
        type: {
          InputComponent: CheckboxDropdown,
          inputType: 'checkbox',
          label: 'Type of organization',
          name: 'type',
          options: [
            'Academic Institution',
            'Alliance or Association',
            'Consultancy',
            'Enterprise',
            'Government Agency',
            'Hardware Vendor',
            'Industry Analyst',
            'Infrastructure Provider or Owner',
            'Non-Government Agency',
            'Press',
            'Research and Development',
            'Reseller or Technology Provider',
            'Semiconductor Vendor',
            'Software or App Development',
            'System Integrator',
            'Telecom or Network Operator',
            'Venture Capital / Investment',
            'Webscale',
            'Other'
          ],
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true
          }
        },
        serviceOfferings: {
          InputComponent: CheckboxDropdown,
          inputType: 'checkbox',
          label: 'Service offerings',
          name: 'serviceOfferings',
          options: [
            'Automation',
            'Chip Manufacturing',
            'Computer Component Manufacturing',
            'Consulting Services',
            'Fiber Provider',
            'Hardware Design',
            'Installation Services',
            'Membership Services (e.g. Events, Convening, Reports)',
            'Mobile Device Manufacturing',
            'Network Management & Operations',
            'Policy Development / Advocacy',
            'Professional Services / Project Management',
            'Routers',
            'SIP Trunking',
            'Software Development',
            'Switches',
            'Test & Compliance',
            'VoIP'
          ],
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true
          }
        },
        operatingRegions: {
          InputComponent: CheckboxDropdown,
          inputType: 'checkbox',
          label: 'Operating regions',
          name: 'operatingRegions',
          options: [
            'Africa',
            'APAC - Australia and New Zealand',
            'APAC - North Asia',
            'APAC - South and Southeast Asia',
            'Europe',
            'LATAM',
            'Middle East',
            'North America'
          ],
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true
          }
        },
        address1: {
          autoComplete: 'address-line1',
          currentCity: formState.values.city,
          currentCountry: formState.values.country,
          currentPostalCode: formState.values.postalCode,
          currentState: formState.values.state,
          helperText: 'Your organization headquarters location',
          includePhone: true,
          InputComponent: PlacesAutocomplete,
          inputType: 'text',
          label: 'Headquarters Address',
          name: 'address1',
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true
          }
        },
        address2: {
          autoComplete: 'address-line2',
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'Address Line 2 (Optional)',
          name: 'address2',
          validator: {}
        },
        country: {
          autoComplete: 'country',
          includePhone: true,
          InputComponent: CountryDropdown,
          inputType: 'text',
          label: 'Country',
          name: 'country',
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true
          }
        },
        postalCode: {
          autoComplete: 'postal-code',
          gridWidth: { xs: 12, sm: 6 },
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'ZIP / Postal code',
          name: 'postalCode',
          validator: {
            required: true
          }
        },
        state: {
          autoComplete: 'address-level1',
          gridWidth: { xs: 12, sm: 6 },
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'State / Province',
          name: 'state',
          validator: {
            required: true
          }
        },
        city: {
          autoComplete: 'address-level2',
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'City',
          name: 'city',
          validator: {
            required: true
          }
        },
        phoneNumber: {
          autoComplete: 'tel',
          currentCountry: formState.values.country,
          InputComponent: PhoneNumberInput,
          inputType: 'text',
          label: 'Organization Contact Phone Number',
          name: 'phoneNumber',
          setField: formState.setField,
          setFieldError: formState.setFieldError,
          validator: {
            required: true,
            regex: inputRegexes.phone,
            regexMessage: 'Must be a valid phone number'
          }
        },
        website: {
          helperText: 'Example: www.organization.com',
          InputComponent: !isSystemAdmin && tabLoopStart ? TextFieldForwardRef : InputTextFieldNew,
          inputType: 'text',
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => window.open(validateUrl(formState.values.website))}>
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            )
          },
          label: 'Organization Website',
          name: 'website',
          ref: !isSystemAdmin ? tabLoopEnd : undefined,
          validator: {
            required: true,
            maxLength: 50,
            regex: inputRegexes.domain,
            regexMessage: 'Must be a valid domain'
          }
        },
        showOnPublicSite: {
          hidden:
            !isSystemAdmin ||
            (membership.membershipLevel &&
              membership.membershipLevel.name !== 'Software participation'),
          InputComponent: CheckboxField,
          inputType: 'checkbox',
          label: 'Show On Public Site',
          name: 'showOnPublicSite',
          validator: {}
        },
        hideFromOrganizationSelect: {
          hidden: !isSystemAdmin && !isReadOnlySystemAdmin,
          disabled: isReadOnlySystemAdmin,
          InputComponent: isSystemAdmin && tabLoopStart ? CheckboxForwardRef : CheckboxField,
          inputType: 'checkbox',
          label: 'Hide from Organization Select',
          name: 'hideFromOrganizationSelect',
          ref: isSystemAdmin ? tabLoopEnd : undefined,
          validator: {}
        }
      }
    : {
        name: {
          validator: {
            required: true
          }
        },
        // affiliateName: {},
        description: {},
        type: {
          validator: {
            required: true
          }
        },
        serviceOfferings: {
          validator: {
            required: true
          }
        },
        operatingRegions: {
          validator: {
            required: true
          }
        },
        address1: {
          validator: {
            required: true
          }
        },
        address2: {},
        country: {
          validator: {
            required: true
          }
        },
        postalCode: {
          validator: {
            required: true
          }
        },
        state: {
          validator: {
            required: true
          }
        },
        city: {
          validator: {
            required: true
          }
        },
        phoneNumber: {
          validator: {
            required: true
          }
        },
        website: {
          validator: {
            required: true
          }
        },
        showOnPublicSite: {},
        hideFromOrganizationSelect: {}
      }

const TextFieldForwardRef = forwardRef((props, ref) => (
  <InputTextFieldNew {...props} inputRef={ref} />
))
const CheckboxForwardRef = forwardRef((props, ref) => <CheckboxField {...props} inputRef={ref} />)

export default formFields
