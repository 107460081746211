import React, { useRef } from 'react'
import MaterialTable from 'material-table'
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import Search from '@material-ui/icons/Search'
import { useFormState } from 'react-use-form-state'

import EditProjectGroupForm from './EditProjectGroupForm'
import { InputTextFieldNew, LinkField, Progress, RoundedButton } from '../common'
import Status from '../common/Status'
import AvatarColumn from '../common/table/AvatarColumn'
import { GET_ALL_PROJECT_GROUPS } from './queries'
import { useQuery } from '../hooks'
import { searchTable, getImageUrl } from '../utils'
import { useSession } from '../auth/queries'

const useStyles = makeStyles(theme => ({
  adminButtonWrapper: {
    width: 226
  },
  progress: {
    textAlign: 'center',
    padding: 20
  },
  status: {
    padding: 10,
    paddingLeft: 15,
    borderRadius: '20px !important',
    margin: 5,
    textTransform: 'capitalize',
    fontSize: 14,
    color: theme.palette.gray.main,
    minWidth: 130
  },
  toolbar: {
    marginBottom: theme.spacing(2)
  }
}))

export const pgStatusOptions = { 0: 'Active', 1: 'Inactive' }
const pgStatusOptionColors = {
  active: 'green',
  inactive: 'red'
}

export default function ManageProjectGroups({ location }) {
  const { isSystemAdmin } = useSession()
  const canAddNewGroups = isSystemAdmin // making a const because other roles may be able to eventually

  const filterValues = useRef({})

  const {
    loading,
    error,
    data: { projectGroups = [] }
  } = useQuery(GET_ALL_PROJECT_GROUPS)
  let parentProjectGroupNameList = []

  if (projectGroups !== undefined) {
    for (let row in projectGroups) {
      parentProjectGroupNameList.push(projectGroups[row].name)
    }
  }

  function findParentProjectGroupName(id) {
    if (id) {
      let pg = projectGroups.find(pg => pg.id === id)
      if (pg) {
        return pg.name
      }
    }
  }

  const projectGroupUpdate = (cache, payload) => {
    const { projectGroups } = cache.readQuery({
      query: GET_ALL_PROJECT_GROUPS
    })
    let newPgs = [...projectGroups]

    if (payload.data.addProjectGroup) {
      const newPg = {
        ...payload.data.addProjectGroup,
        agreementId: payload.data.agreementId || null,
        chairs: payload.data.chairs || [],
        charterAgreementId: payload.data.charterAgreementId || null,
        joinedOrganizations: payload.data.joinedOrganizations || null,
        parentProjectGroupId: payload.data.parentProjectGroupId || null,
        restricted: payload.data.restricted || null,
        new: payload.data.new || null
      }
      // if adding we need to add the item to the cache
      newPgs.push(newPg)
    } else {
      // if updating we need to modify the item in cache
      const updatedPg = payload.data.updateProjectGroup
      let updatedIndex = projectGroups.findIndex(u => u.id === updatedPg.id)

      const newData = {
        ...projectGroups[updatedIndex],
        ...updatedPg
      }

      newPgs[updatedIndex] = newData
    }
    cache.writeQuery({
      query: GET_ALL_PROJECT_GROUPS,
      data: { projectGroups: newPgs }
    })
  }
  const [formState, { text }] = useFormState({ search: '' })
  const classes = useStyles()

  let pgData = projectGroups

  if (loading) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  if (error) {
    return (
      <Typography role="alert" color="error">
        Error: {error.message}
      </Typography>
    )
  }

  const columnDef = [
    {
      title: 'Name',
      searchable: true,
      defaultSort: 'desc',
      field: 'name',
      defaultFilter: filterValues.current.name || '',
      render: rowData => {
        return (
          <EditProjectGroupForm
            projectGroups={pgData}
            returnUrl={location.pathname}
            id={rowData.id}
            onUpdate={projectGroupUpdate}
          >
            {onClick => (
              <div onClick={onClick}>
                <AvatarColumn
                  url={rowData.logo ? getImageUrl(rowData.logo) : null}
                  name={rowData.name}
                  icon="business"
                />
              </div>
            )}
          </EditProjectGroupForm>
        )
      },
      customSort: (a, b) => {
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()

        if (aName < bName) {
          return -1
        }
        if (aName > bName) {
          return 1
        }

        return 0
      }
    },
    {
      title: 'Parent Project Group',
      field: 'parentProjectGroup',
      type: 'string',
      defaultFilter: filterValues.current.parentProjectGroup || '',
      render: rowData => {
        return findParentProjectGroupName(rowData.parentProjectGroupId)
      },
      customFilterAndSearch: (value, rowData) => {
        if (!rowData.parentProjectGroupId) {
          return false
        }

        let parent = findParentProjectGroupName(rowData.parentProjectGroupId)
        return value ? parent.toLowerCase().includes(value.toLowerCase()) : false
      },
      customSort: (a, b) => {
        const aName = a.parentProjectGroupId
          ? findParentProjectGroupName(a.parentProjectGroupId).toLowerCase()
          : null
        const bName = b.parentProjectGroupId
          ? findParentProjectGroupName(b.parentProjectGroupId).toLowerCase()
          : null

        if (!bName) {
          return -1
        }
        if (!aName) {
          return 1
        }

        if (aName < bName) {
          return -1
        }
        if (aName > bName) {
          return 1
        }

        return 0
      }
    },
    {
      title: 'GitHub Team',
      field: 'githubTeam',
      type: 'string',
      defaultFilter: filterValues.current.githubTeam || '',
      render: rowData => {
        return rowData.githubTeam
      }
    },
    {
      title: 'Atlassian Group',
      field: 'atlassianGroup',
      type: 'string',
      defaultFilter: filterValues.current.atlassianGroup || '',
      render: rowData => {
        return rowData.atlassianGroup
      }
    },
    {
      title: 'Detail Link',
      field: 'detailLink',
      type: 'string',
      defaultFilter: filterValues.current.detailLink || '',
      render: rowData => {
        return (
          <LinkField
            hideIcon={true}
            className={classes.linkStyle}
            to={rowData.detailLink}
            label={rowData.detailLink}
          />
        )
      }
    },
    {
      title: 'Status',
      field: 'statusValue',
      type: 'string',
      defaultFilter: filterValues.current.statusValue || '',
      lookup: pgStatusOptions,
      render: rowData => {
        let value = rowData.status
        let color = pgStatusOptionColors[rowData.status.toLowerCase()]
        return <Status label={value} color={color} styles={classes.status} />
      }
    },
    {
      title: 'Created Date',
      field: 'createdDate',
      type: 'date',
      defaultFilter: filterValues.current.createdDate || '',
      render: rowData => {
        return new Date(rowData.createdDate).toDateString()
      }
    },
    {
      title: 'Graduated',
      field: 'graduated',
      type: 'boolean',
      defaultFilter: filterValues.current.graduated || false,
      render: rowData => {
        let boolGraduated = rowData.graduated == null ? false : rowData.graduated
        return (
          <Checkbox
            classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
            checked={boolGraduated}
            disabled={true}
            color="default"
          />
        )
      }
    },
    {
      title: 'New?',
      field: 'new',
      type: 'boolean',
      defaultFilter: filterValues.current.new || false,
      render: rowData => {
        let boolNew = rowData.new == null ? false : rowData.new
        return (
          <Checkbox
            classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
            checked={boolNew}
            disabled={true}
            color="default"
          />
        )
      }
    },
    {
      render: rowData => {
        return (
          <EditProjectGroupForm
            projectGroups={pgData}
            returnUrl={location.pathname}
            id={rowData.id}
            onUpdate={projectGroupUpdate}
          >
            {onClick => (
              <Tooltip title="Edit">
                <IconButton onClick={onClick}>
                  <CreateIcon />
                </IconButton>
              </Tooltip>
            )}
          </EditProjectGroupForm>
        )
      }
    }
  ]

  let searchedData = pgData
  let searchText = formState.values.search

  searchedData = searchTable(pgData, searchText, columnDef)

  let formattedData = searchedData.map((obj, index) => {
    const value = Object.keys(pgStatusOptions).find(key => pgStatusOptions[key] === obj.status)

    return { ...obj, statusValue: value }
  })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Project Groups
        </Typography>
      </Grid>
      {canAddNewGroups && (
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={12}>
            <EditProjectGroupForm
              add={true}
              projectGroups={pgData}
              returnUrl={location.pathname}
              id="0"
              onUpdate={projectGroupUpdate}
            >
              {onClick => (
                <div className={classes.adminButtonWrapper}>
                  <RoundedButton onClick={onClick}>Add Project Group</RoundedButton>
                </div>
              )}
            </EditProjectGroupForm>
          </Grid>
        </Grid>
      )}
      <Grid item className={classes.toolbar} xs={12} md={4}>
        <InputTextFieldNew
          autoComplete="new-password" // don't want email autofill
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          inputType={text}
          label="Search"
          name="search"
          value={formState.values.search}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title="Manage Participants"
          columns={columnDef}
          data={formattedData}
          options={{
            filtering: true,
            pageSize: 10,
            // selection: true, // checkboxes in design but no actions so removing
            showTitle: false,
            draggable: false
          }}
          components={{
            Toolbar: props => {
              props.columns.forEach(c => {
                const field = c.field
                const filterValue = c.tableData.filterValue

                if (filterValue) {
                  filterValues.current[field] = filterValue
                } else if (filterValues.current[field]) {
                  filterValues.current[field] = null
                }
              })

              return null
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
