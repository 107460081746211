import React, { useState } from 'react'
import { Grid, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import equal from 'fast-deep-equal'

import { isFormSubmitDisabled, inputRegexes } from '../../utils'
import { InputTextFieldNew, RoundedButton } from '../../common'
import OrgMemberDropdown from './OrgMemberDropdown'

export default function BillingContactInput({
  onSave,
  onCancel,
  showCancel,
  value = { id: '', firstname: '', lastname: '', email: '', jobTitle: '' },
  inputId,
  users,
  selectedUserId,
  ...rest
}) {
  const billingContact = {
    userId: value.id,
    firstname: value.firstname,
    email: value.email,
    lastname: value.lastname,
    jobTitle: value.jobTitle
  }

  const [billingContactRadio, setBillingContactRadio] = useState(
    billingContact.userId || !billingContact.email ? 'dropdown' : 'manual'
  )

  const classes = useStyles()
  const [formState, inputTypes] = useFormState(
    billingContact.userId
      ? {
          userId: billingContact.userId
        }
      : {
          email: billingContact.email,
          firstname: billingContact.firstname,
          lastname: billingContact.lastname,
          jobTitle: billingContact.jobTitle,
          userId: undefined
        }
  )

  const inputs = inputFields()

  return (
    <Grid container>
      <Grid item xs={12} sm={8} lg={9} xl={8}>
        <RadioGroup
          aria-label="Select Authorized Signer"
          name="authorizedSignerRadio"
          className={classes.group}
          value={billingContactRadio}
          onChange={e => setBillingContactRadio(e.target.value)}
        >
          <FormControlLabel value="dropdown" control={<Radio />} label="Search for User" />
          {billingContactRadio === 'dropdown' && (
            <div className={classes.radioContent}>
              <OrgMemberDropdown
                inputId={inputId}
                users={users}
                selectedUserId={formState.values.userId}
                onChange={value => formState.setField('userId', value)}
                {...rest}
              />
            </div>
          )}
          <FormControlLabel value="manual" control={<Radio />} label="Enter email and name" />
          {billingContactRadio === 'manual' && (
            <Grid container spacing={2} className={classes.radioContent}>
              {Object.entries(inputs).map(
                ([name, { gridWidth = { xs: 12 }, InputComponent, inputType, ...args }]) => (
                  <Grid item key={name} {...gridWidth}>
                    <InputComponent
                      {...args}
                      error={
                        typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                      }
                      errorHelperText={formState.errors[name]}
                      inputType={inputTypes[inputType]}
                      margin="none"
                      value={formState.values[name]}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}
        </RadioGroup>
        <Grid item xs={6} className={classes.button}>
          <RoundedButton
            fullWidth={false}
            disabled={
              billingContactRadio === 'manual'
                ? isFormSubmitDisabled(inputs, formState, true) ||
                  equal(billingContact, formState.values)
                : billingContact.userId === formState.values.userId
            }
            onClick={() =>
              onSave(
                billingContactRadio === 'manual'
                  ? {
                      firstname: formState.values.firstname,
                      lastname: formState.values.lastname,
                      jobTitle: formState.values.jobTitle,
                      email: formState.values.email
                    }
                  : {
                      userId: formState.values.userId
                    }
              )
            }
          >
            Save
          </RoundedButton>
        </Grid>
        {showCancel && (
          <Grid item xs={6} className={classes.button}>
            <RoundedButton fullWidth={false} variant="outlined" onClick={onCancel}>
              Cancel
            </RoundedButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const inputFields = () => ({
  firstname: {
    InputComponent: InputTextFieldNew,
    gridWidth: { xs: 12, sm: 6 },
    name: 'firstname',
    label: 'First Name',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  lastname: {
    InputComponent: InputTextFieldNew,
    gridWidth: { xs: 12, sm: 6 },
    name: 'lastname',
    label: 'Last Name',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  jobTitle: {
    InputComponent: InputTextFieldNew,
    name: 'jobTitle',
    label: 'Work Title',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  email: {
    InputComponent: InputTextFieldNew,
    name: 'email',
    label: 'Email',
    inputType: 'email',
    validator: {
      required: true,
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email'
    }
  }
})

const useStyles = makeStyles(theme => ({
  radioContent: {
    paddingLeft: 33
  },
  button: {
    marginTop: 20,
    marginRight: 10,
    display: 'inline-block'
  }
}))
