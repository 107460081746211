import React, { useEffect } from 'react'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Progress from '../../../common/Progress'
import { GET_USER } from '../../../User/Profile/queries'
import { GET_ORG } from '../../Profile/queries'
import { useQuery } from '../../../hooks'
import { useSession } from '../../../auth/queries'

export default function SendAgreementSection({
  handleSendSignatureRequest,
  orgId,
  selectSigner,
  sendGpaLoading,
  signer,
  isReadOnly,
  showingAuthRepDropdown,
  setShowingAuthRepDropdown
}) {
  const { userId } = useSession()
  const classes = useStyles()
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const {
    data: { user = {} },
    loading: userLoading
  } = useQuery(GET_USER, { variables: { id: userId } })

  const {
    data: { organization = {} },
    loading: orgLoading
  } = useQuery(GET_ORG, { variables: { id: orgId } })

  const orgInfoValid =
    !userLoading &&
    user &&
    user.firstname &&
    user.lastname &&
    user.jobTitle &&
    user.shippingAddress &&
    user.shippingAddress.address1 &&
    user.shippingAddress.city &&
    user.shippingAddress.country &&
    user.shippingAddress.postalCode &&
    user.shippingAddress.state &&
    user.phoneNumber &&
    !orgLoading &&
    organization &&
    organization.name &&
    organization.phoneNumber &&
    organization.shippingAddress &&
    organization.shippingAddress.address1 &&
    organization.shippingAddress.city &&
    organization.shippingAddress.country &&
    organization.shippingAddress.postalCode &&
    organization.shippingAddress.state &&
    organization.website &&
    organization.type &&
    organization.type.length > 0 &&
    organization.serviceOfferings &&
    organization.serviceOfferings.length > 0 &&
    organization.operatingRegions &&
    organization.operatingRegions.length > 0 &&
    organization.organizationRoles &&
    organization.organizationRoles.authorizedSigners &&
    organization.organizationRoles.authorizedSigners.filter(a => a.user || a.email).length > 0 &&
    organization.organizationRoles.billingContact &&
    (organization.organizationRoles.billingContact.user ||
      organization.organizationRoles.billingContact.email)
      ? true
      : false

  useEffect(() => {
    if (orgInfoValid !== showingAuthRepDropdown) {
      setShowingAuthRepDropdown(orgInfoValid)
    }
  }, [orgInfoValid, setShowingAuthRepDropdown, showingAuthRepDropdown])

  useEffect(() => {
    if (
      organization &&
      organization.organizationRoles &&
      organization.organizationRoles.authorizedSigners &&
      signer.email &&
      !organization.organizationRoles.authorizedSigners.find(
        a => a.email === signer.email || (a.user && a.user.email === signer.email)
      )
    ) {
      selectSigner(null)
    }
  }, [organization, signer, selectSigner])

  if (userLoading || orgLoading) {
    return null
  }

  return (
    <Grid container className={classes.section}>
      {signer.email ? (
        <>
          <Grid container alignItems="center" justify="center">
            <Grid item xs>
              <Typography paragraph className={classes.message}>
                The request will be sent from Docusign to: {signer.firstname} {signer.lastname}{' '}
                {signer.email}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                disabled={!signer.email}
                onClick={() => selectSigner(null)}
                className={classes.editSignerButton}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
          {signer.jobTitle ? (
            <div className={classes.sendGpaContainer}>
              <Button
                disabled={!signer.email || sendGpaLoading}
                onClick={handleSendSignatureRequest}
                variant="contained"
                className={classes.sendGpaButton}
              >
                Send signature request
              </Button>
              {sendGpaLoading && <Progress size={25} delay={0} />}
            </div>
          ) : (
            <Typography role="alert" color="error">
              This user has no job title, please modify the user profile before trying to send
              agreement
            </Typography>
          )}
        </>
      ) : !orgInfoValid ? (
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography>
              Please complete the TIP Participation application for your organization
            </Typography>
          </Grid>
          <Grid item xs="auto">
            {largeScreen ? (
              <ArrowForwardIcon fontSize="large" />
            ) : (
              <ArrowDownwardIcon fontSize="large" />
            )}
          </Grid>
        </Grid>
      ) : (
        !isReadOnly && (
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="authorized-signer" classes={{ shrink: classes.shrink }}>
                  Select representative
                </InputLabel>

                <Select
                  value=""
                  onChange={selectSigner}
                  className={classes.select}
                  input={<OutlinedInput name="authorizedSigner" id="authorized-signer" />}
                >
                  {organization.organizationRoles.authorizedSigners.map(signer => {
                    let val = signer.user ? signer.user : signer
                    return (
                      <MenuItem key={val.email} value={val.email}>
                        <div>
                          <Typography>
                            {val.firstname} {val.lastname}
                          </Typography>
                          <Typography className={classes.subtext}>{val.jobTitle}</Typography>
                          <Typography className={classes.subtext}>{val.email}</Typography>
                        </div>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="auto">
              <ArrowBackIcon fontSize="large" />
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  formControl: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 12,
      width: '100%'
    }
  },
  sendGpaContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  editSignerButton: {
    border: '1px solid',
    borderRadius: '50%',
    color: '#EB6F53'
  },
  message: {
    fontSize: 14,
    paddingRight: 15
  },
  sendGpaButton: {
    backgroundColor: '#EB6F53',
    borderRadius: 5,
    boxShadow: 'unset',
    fontWeight: 600,
    color: 'white',
    marginTop: 10,
    marginBottom: 15,
    marginRight: 15,

    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    }
  },
  select: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  },
  subtext: {
    color: theme.palette.gray.main,
    fontSize: '.8rem'
  },
  shrink: {
    bottom: 10,
    position: 'relative'
  },
  notificationContainer: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  closeIcon: {
    marginLeft: 5,
    cursor: 'pointer'
  }
}))
