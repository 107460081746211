import React, { useEffect, useState } from 'react'
import { makeStyles, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import TimerIcon from '@material-ui/icons/Timer'
import { useIdleTimer } from 'react-idle-timer'
import { useSnackbar } from 'notistack'

import { useSession } from './queries'

const AuthIdleTimer = ({ children }) => {
  const { isAuthed } = useSession()
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const [showSnackbar, setShowSnackbar] = useState(false)
  const [timer, setTimer] = useState()
  const [timeRemaining, setTimeRemaining] = useState(300)

  const { isIdle: isIdleLong, pause: pauseLong, reset: resetLong } = useIdleTimer({
    stopOnIdle: true,
    timeout: 1500000,
    onIdle: e => {
      setShowSnackbar(true)
      setTimer(setInterval(() => setTimeRemaining(timeRemaining => timeRemaining - 1), 1000))
      resetShort()
    }
  })

  const { pause: pauseShort, reset: resetShort } = useIdleTimer({
    startOnMount: false,
    stopOnIdle: true,
    timeout: 300000,
    onAction: e => {
      if (isIdleLong()) {
        enqueueSnackbar('Welcome back!', {
          autoHideDuration: 4500,
          variant: 'success'
        })
        setShowSnackbar(false)
        clearInterval(timer)
        setTimeRemaining(300)
        pauseShort()
        resetLong()
      }
    },
    onIdle: e => (window.location = '/logout?state=session_expired')
  })

  useEffect(() => {
    if (!isIdleLong()) {
      if (isAuthed) {
        resetLong()
      } else {
        pauseLong()
      }

      pauseShort()
    }
  }, [isAuthed, isIdleLong, pauseLong, pauseShort, resetLong])

  return (
    <>
      {children}
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={showSnackbar}>
        <Alert className={classes.snackbar} icon={<TimerIcon className={classes.snackbarIcon} />}>
          You will be logged out in {Math.floor(timeRemaining / 60)}:
          {('0' + Math.floor(timeRemaining % 60)).slice(-2)} due to inactivity
        </Alert>
      </Snackbar>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  snackbar: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  snackbarIcon: {
    color: 'white'
  }
}))

export default AuthIdleTimer
