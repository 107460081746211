export const roles = {
  systemAdmin: '0',
  orgPrimaryContact: '1',
  orgExchangeAdmin: '2',
  orgBillingContact: '3',
  orgMemberAdmin: '4',
  orgMember: '5',
  registeredUser: '6',
  anonymousUser: '7',
  readOnlySystemAdmin: '8',
  exchangeListingsAdmin: '9'
}
