import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'

import RoundedButton from '../common/RoundedButton'
import { useFormStyles, useMutation } from '../hooks'
import { useSession, getSessionQueries } from './queries'
import { SELECT_LOGGED_IN_STATUS } from './mutations'

export default function StayLoggedIn({ location = {} }) {
  const { session } = useSession()
  const [checked, setChecked] = useState(false)
  const formClasses = useFormStyles()
  const classes = useStyles()

  const options =
    session.user && session.user.id
      ? {
          refetchQueries: getSessionQueries(session.user.id),
          awaitRefetchQueries: true
        }
      : {}

  const { execute: selectLoggedInStatus } = useMutation(SELECT_LOGGED_IN_STATUS, options)

  function checkTheBox() {
    setChecked(!checked)
  }

  function confirmSelection(selection) {
    selectLoggedInStatus({
      values: {
        selection: selection,
        showAgain: !checked
      }
    })
  }

  if (session.user && session.user.stayLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={12} md={7} lg={6} className={formClasses.container}>
        <div className={formClasses.header}>
          {session && session.user ? (
            <Typography paragraph className={classes.userEmail}>
              {session.user.email}
            </Typography>
          ) : null}
          <Typography role="heading" variant="h3" className={classes.heading}>
            Stay Logged In?
          </Typography>
          <Typography paragraph className={classes.description}>
            Do this to reduce the number of times you are asked to sign in on this device.
          </Typography>
          <Typography paragraph className={classes.descriptionWarning}>
            To keep your account secure, use this option only on your personal devices.
          </Typography>
          <Typography paragraph className={classes.descriptionCheckbox}>
            <Checkbox
              classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
              checked={checked}
              color="default"
              onClick={checkTheBox}
            />
            Don't show this again.
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <RoundedButton
            className={classes.rejectButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => confirmSelection(false)}
            data-testid="RejectSession" // for tests only
            type="button"
          >
            No
          </RoundedButton>
          <RoundedButton
            className={classes.confirmButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => confirmSelection(true)}
            data-testid="ConfirmSession" // for tests only
            type="button"
          >
            Yes
          </RoundedButton>
        </div>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rejectButton: {
    background: '#FFF',
    color: '#29818C',
    width: 100,
    border: '1px solid #29818C',
    '&:hover': {
      background: 'none'
    }
  },
  confirmButton: {
    width: 100,
    marginLeft: 5
  },
  userEmail: {
    fontSize: '1.15rem',
    textAlign: 'left',
    marginBottom: 25
  },
  description: {
    fontSize: '1.15rem',
    textAlign: 'left'
  },
  descriptionWarning: {
    fontSize: '1.15rem',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  descriptionCheckbox: {
    fontSize: 10,
    textAlign: 'left'
  },
  checkbox: {
    padding: 0,
    marginRight: 5
  },
  heading: {
    marginBottom: 30,
    fontSize: '2rem',
    fontWeight: 500,
    textAlign: 'left'
  }
}))
