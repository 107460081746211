import React, { useState, useEffect } from 'react'
import { IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import RefreshIcon from '@material-ui/icons/Refresh'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { useSnackbar } from 'notistack'

import { ApprovedOrgMembersModal } from '../../Organization/InviteOrganizationMembers'
import ResendEmailModal from './ResendEmailModal'
import CancelAgreementConfirmationModal from './CancelAgreementConfirmationModal'
import ConfirmLeaveDialog from '../ConfirmLeaveDialog'
import RequestSignatureModal from './RequestSignatureModel'
import { SEND_ORG_MEMBERS_INVITATION_EMAIL, RESEND_TO_ORG_APPROVED_MEMBERS } from './mutations'
import { useMutation } from '../../hooks'

export default function ProjectGroupAdminOptions({
  cancelOptions,
  id,
  joinedPg,
  joinOptions,
  leaveOptions,
  orgId,
  pending
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showApprovedOrgMembersModal, setApprovedOrgMembersModal] = useState(false)
  const [showResendEmailModal, setResendEmailModal] = useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { enqueueSnackbar } = useSnackbar()

  // Approved Org Members
  const {
    execute: executeInvitationEmail,
    error: errorInvitationEmail,
    loading: loadingInvitationEmail,
    data: { sendOrgMembersInvitationEmail: dataInvitationEmail } = {}
  } = useMutation(SEND_ORG_MEMBERS_INVITATION_EMAIL)

  const handleCloseApprovedOrgMembersModal = () => {
    setApprovedOrgMembersModal(false)
  }

  const handleConfirmApprovedOrgMembersModal = value => {
    sendInvitationEmail(value)
    setApprovedOrgMembersModal(false)
  }

  function sendInvitationEmail({ message, users }) {
    // send invitation emails
    const members = users.map(u => {
      return {
        email: u.email,
        firstName: u.firstname,
        lastName: u.lastname
      }
    })

    const obj = {
      members: members,
      message,
      orgId: orgId,
      pgId: id
    }
    executeInvitationEmail({ values: obj })
  }

  // Resend Email
  const {
    execute: executeResendEmail,
    error: errorResendEmail,
    loading: loadingResendEmail,
    data: { resendToOrgApprovedMembers: dataResendEmail } = {}
  } = useMutation(RESEND_TO_ORG_APPROVED_MEMBERS)

  const handleCloseResendEmailModal = () => {
    setResendEmailModal(false)
  }

  const handleConfirmResendEmailModal = () => {
    const obj = {
      orgId: orgId,
      pgId: id
    }
    setResendEmailModal(false)
    executeResendEmail({ values: obj })
  }

  const approvedOrgMembersModal = showApprovedOrgMembersModal ? (
    <ApprovedOrgMembersModal
      handleClose={handleCloseApprovedOrgMembersModal}
      handleConfirm={handleConfirmApprovedOrgMembersModal}
      orgId={orgId}
      pgId={id}
    />
  ) : null

  const resendEmailModal = showResendEmailModal ? (
    <ResendEmailModal
      handleClose={handleCloseResendEmailModal}
      handleConfirm={handleConfirmResendEmailModal}
    />
  ) : null

  // output
  useEffect(() => {
    // on success show snack bar
    if (dataInvitationEmail && !errorInvitationEmail && !loadingInvitationEmail) {
      // close modals
      setApprovedOrgMembersModal(false)

      enqueueSnackbar('Email Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [dataInvitationEmail, errorInvitationEmail, loadingInvitationEmail, enqueueSnackbar])

  useEffect(() => {
    // on success show snack bar
    if (dataResendEmail && !errorResendEmail && !loadingResendEmail) {
      // close modals
      setResendEmailModal(false)

      enqueueSnackbar('Email Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [dataResendEmail, errorResendEmail, loadingResendEmail, enqueueSnackbar])

  if (errorInvitationEmail) {
    return (
      <Typography role="alert" color="error">
        Sending Invitation Error: {errorInvitationEmail.message}
      </Typography>
    )
  } else if (errorResendEmail) {
    return (
      <Typography role="alert" color="error">
        Resend Email Error: {errorResendEmail.message}
      </Typography>
    )
  }

  const menuItems = joinedPg
    ? pending
      ? [
          {
            Icon: BlockIcon,
            text: 'Cancel Signature Request',
            Wrapper: CancelAgreementConfirmationModal,
            wrapperOptions: cancelOptions
          }
        ]
      : [
          {
            Icon: RemoveCircleOutlineIcon,
            text: 'Deactivate Group',
            Wrapper: ConfirmLeaveDialog,
            wrapperOptions: leaveOptions
          },
          {
            Icon: RefreshIcon,
            onClick: () => {
              setResendEmailModal(true)
              handleClose()
            },
            text: 'Resend Participant Notification'
          },
          {
            Icon: MailOutlineIcon,
            onClick: () => {
              // show Approved Org Members modal
              setApprovedOrgMembersModal(true)
              handleClose()
            },
            text: 'Invite Organization Participants'
          }
        ]
    : [
        {
          Icon: PowerSettingsNewIcon,
          text: 'Activate Group',
          Wrapper: RequestSignatureModal,
          wrapperOptions: joinOptions
        }
      ]

  function handleWrapperClick(wrapperOnClick) {
    handleClose()
    wrapperOnClick()
  }

  return (
    <div>
      {approvedOrgMembersModal}
      {resendEmailModal}
      <IconButton aria-label="admin options" size="small" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="admin-options"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(({ Icon, onClick, text, Wrapper, wrapperOptions }, index) =>
          Wrapper ? (
            <div key={index}>
              <Wrapper overrideButton {...wrapperOptions}>
                {wrapperOnClick => (
                  <MenuItem onClick={() => handleWrapperClick(wrapperOnClick)}>
                    <Icon className={classes.icon} />
                    <Typography className={classes.option}>{text}</Typography>
                  </MenuItem>
                )}
              </Wrapper>
            </div>
          ) : (
            <MenuItem key={index} onClick={onClick}>
              <Icon className={classes.icon} />
              <Typography className={classes.option}>{text}</Typography>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  )
}

export const useStyles = makeStyles(theme => ({
  option: {
    fontSize: '.9rem'
  },
  icon: {
    marginRight: 10
  }
}))
