import React, { useEffect, useCallback, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SwipeableViews from 'react-swipeable-views'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'

import { GET_USER_ORGANIZATIONS } from '../../auth/queries'
import RouteGuard from '../../RouteGuard'
import CompanyProfile from './OrganizationProfile'
import OrganizationRoles from './OrganizationRoles'
import AuthorizedRepresentatives from './AuthorizedRepresentatives'
import BillingContact from './BillingContact'
import Header from './Header'
import { useSession } from '../../auth/queries'
import { useQuery } from '../../hooks'
import { history } from '../../store'
import { tabStyles } from '../../User/Profile/UserInfo'
import PreventTransitionPrompt from '../../RouteGuard/PreventTransitionPrompt'
import { ImpersonateUserField } from '../../Organization/Impersonation'
import { TabLoop } from '../../common'

const styles = makeStyles({
  tabContainer: { padding: 8 * 3 }
})

function TabContainer({ children, dir }) {
  const classes = styles()
  return <div className={classes.tabContainer}>{children}</div>
}

function getDefinedObject(obj) {
  return Object.entries(obj).reduce((acc, [name, val]) => {
    if (val && name !== '__typename') {
      if (typeof val === 'object' && val instanceof Object) {
        acc[name] = getDefinedObject(val)
      } else {
        acc[name] = val
      }
    }
    return acc
  }, {})
}

const OrganizationInfo = ({ organization, onUpdate, returnUrl, tabIndex = 0 }) => {
  const { isSystemAdmin, isOrgAdmin, isImpersonating, userId } = useSession()
  const [activeTabId, setActiveTabId] = useState(tabIndex)
  const [desiredActiveTabId, setDesiredActiveTabId] = useState(tabIndex)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [path, setPath] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const organizationValues = getDefinedObject(organization)

  const classes = tabStyles()
  const theme = useTheme()

  const { data: { user: { organizations: userOrganizations = [] } = {} } = {} } = useQuery(
    GET_USER_ORGANIZATIONS,
    {
      variables: { id: userId }
    }
  )

  const canEditOrg =
    isSystemAdmin ||
    (isOrgAdmin && organization.id === userOrganizations[0].organizationId && !isImpersonating)

  useEffect(() => {
    if (path) {
      // used to redirect the user after confirm dialog
      history.push(path)
    }
  }, [path])

  const onCompleted = useCallback(
    data => {
      if (data && data.id) {
        enqueueSnackbar('Changes Saved', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    },
    [enqueueSnackbar]
  )

  function onFormChange(hasUnsavedChanges) {
    setHasUnsavedChanges(hasUnsavedChanges)
  }

  function handleTabChange(event, newValue) {
    if (hasUnsavedChanges) {
      setModalVisible(true)
      setDesiredActiveTabId(newValue)
    } else {
      setActiveTabId(newValue)
    }
  }

  function handleTabChangeIndex(index) {
    if (index > 3) {
      index = 3
    }

    if (hasUnsavedChanges) {
      setModalVisible(true)
      setDesiredActiveTabId(index)
    } else {
      setActiveTabId(index)
    }
  }

  function closeModal() {
    setModalVisible(false)
  }

  function handleConfirmNavigationClick(index) {
    closeModal()
    setActiveTabId(desiredActiveTabId)
    setHasUnsavedChanges(false)
  }

  const sections = [
    {
      label: 'Organization Profile',
      component: (
        <TabLoop>
          <CompanyProfile
            activeTab={activeTabId === 0}
            isReadOnly={!canEditOrg}
            onCompleted={onCompleted}
            onUpdate={onUpdate}
            organization={organization}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </TabLoop>
      )
    },
    {
      label: 'Account Roles',
      component: (
        <OrganizationRoles
          activeTab={activeTabId === 1}
          organization={organizationValues}
          users={organization.users}
          onUpdate={onUpdate}
          onFormChange={onFormChange}
          onCompleted={onCompleted}
          isSystemAdmin={isSystemAdmin}
          userId={userId}
          isReadOnly={!canEditOrg}
        />
      )
    },
    {
      label: 'Authorized Representative',
      component: (
        <AuthorizedRepresentatives
          activeTab={activeTabId === 2}
          organization={organizationValues}
          users={organization.users}
          onUpdate={onUpdate}
          onFormChange={onFormChange}
          onCompleted={onCompleted}
          isSystemAdmin={isSystemAdmin}
          userId={userId}
          isReadOnly={!canEditOrg}
        />
      )
    },
    {
      label: 'Billing Contact',
      component: (
        <BillingContact
          activeTab={activeTabId === 3}
          organization={organizationValues}
          users={organization.users}
          onUpdate={onUpdate}
          onFormChange={onFormChange}
          onCompleted={onCompleted}
          isSystemAdmin={isSystemAdmin}
          userId={userId}
          isReadOnly={!canEditOrg}
        />
      )
    }
  ]

  const title = 'Unsaved Changes'
  const message = 'Are you sure you want to leave?'
  const orgAdminId = organizationValues.organizationRoles
    ? organizationValues.organizationRoles.organizationAdminId
    : null

  return (
    <>
      <PreventTransitionPrompt
        title={title}
        message={message}
        visible={modalVisible}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
      <RouteGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        title={title}
        message={message}
        when={hasUnsavedChanges}
        // Navigate function
        navigate={path => {
          setPath(path)
        }}
      />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={8}>
            <Header
              parentId={organization.id}
              type="organization"
              logo={organization.logo}
              name={organization.name}
              percentageComplete={organization.percentageComplete}
              onUpdate={onUpdate}
              isReadOnly={!canEditOrg}
            />
          </Grid>
          {orgAdminId &&
            [
              '/admin/manage-organizations',
              '/admin/manage-billing',
              '/admin/manage-members'
            ].includes(returnUrl) && (
              <Grid item xs={4} className={classes.impersonateContainer}>
                <ImpersonateUserField
                  returnUrl={returnUrl}
                  userId={orgAdminId}
                  orgId={organization.id}
                />
              </Grid>
            )}
        </Grid>
        <div className={classes.tabRoot}>
          <AppBar position="relative" color="inherit">
            <Tabs
              value={activeTabId}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabRoot}
            >
              {sections.map(section => {
                return (
                  <Tab
                    classes={{ root: classes.tabTitle }}
                    key={section.label}
                    label={section.label}
                  />
                )
              })}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeTabId}
            onChangeIndex={handleTabChangeIndex}
          >
            {sections.map(section => {
              return (
                <TabContainer key={section.label} dir={theme.direction}>
                  {section.component}
                </TabContainer>
              )
            })}
          </SwipeableViews>
        </div>
      </div>
    </>
  )
}

export default OrganizationInfo
