import gql from 'graphql-tag'

export const CHANGE_MEMBER_STATUS = gql`
  mutation($input: OrganizationUserInput) {
    updateUserInOrganization(input: $input) {
      id
      status
    }
  }
`
export const REMOVE_USER_FROM_ORG = gql`
  mutation($input: OrganizationUserRemovalInput) {
    removeUserFromOrganization(input: $input) {
      id
      userId
    }
  }
`

export const DELETE_USER = gql`
  mutation($input: DeleteUserInput) {
    deleteAndRemoveUser(input: $input) {
      result
      data
    }
  }
`
