import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Icon } from '@material-ui/core'
import clsx from 'clsx'

import { useQuery } from '../../../hooks'
import ProjectGroupAgreement from './ProjectGroupAgreement'

import { GET_PROJECT_GROUP_AGREEMENTS, GET_ALL_PROJECT_GROUPS_FOR_AGREEMENTS } from './queries'

export default function ProjectGroupCharters({ organizationId, isReadOnly }) {
  const parameters = { variables: { id: organizationId }, fetchPolicy: 'network-only' }
  const classes = useStyles()

  // 1 Get all agreements of type ProjectGroupCharter && ProjectGroupAgreement
  const {
    loading: organizationAgreementsLoading,
    data: { organization: { signedAgreements: organizationAgreements = [] } = {} },
    error: getOrganizationAgreementsError,
    refetch: refetchAgreements
  } = useQuery(GET_PROJECT_GROUP_AGREEMENTS, parameters)

  // 2 Get all project groups the org is in
  const {
    loading: loadingProjectGroups,
    error: getProjectGroupsError,
    data: { projectGroups = [] }
  } = useQuery(GET_ALL_PROJECT_GROUPS_FOR_AGREEMENTS, {})

  useEffect(() => {
    setInterval(() => {
      refetchAgreements()
    }, 1500000)
  }, [refetchAgreements])

  if (organizationAgreementsLoading || loadingProjectGroups) {
    return null
  }

  // 3 Link them
  const agreements = organizationAgreements
    .filter(
      a =>
        a.agreementType &&
        a.agreementType === 'ProjectGroupCharter' &&
        projectGroups.some(pg => pg.id === a.referenceId)
    )
    .map(agreement => {
      return {
        ...agreement,
        projectGroup: projectGroups.find(pg => pg.id === agreement.referenceId)
      }
    })

  if (getOrganizationAgreementsError || getProjectGroupsError) {
    return (
      <div>
        <Typography className={classes.agreementTitle} color="error">
          An error occured
        </Typography>
      </div>
    )
  }

  return (
    <div className={clsx(classes.content, classes.section)}>
      <div className={classes.header}>
        <Icon className={classes.icon}>description</Icon>
        <Typography className={classes.agreementTitle} variant="h4">
          Project Group Charters
        </Typography>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={clsx(classes.row, classes.rowHeader)}>
          <Typography className={classes.headerLeft}>Document</Typography>
          <Typography className={classes.headerRight}>Status</Typography>
        </Grid>
        {agreements.length > 0 ? (
          agreements.map((agreement, index) => (
            <Grid key={index} item xs={12} className={classes.row}>
              <ProjectGroupAgreement
                organizationId={organizationId}
                agreement={agreement}
                isReadOnly={isReadOnly}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={classes.row}>
            <Typography className={classes.noAgreements}>
              Currently you have no project group charter agreements
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: 50
  },
  noAgreements: {
    fontSize: 14,
    padding: 20
  },
  row: {
    border: `1px solid #E6E6E6`
  },
  rowHeader: {
    padding: 20,
    display: 'flex'
  },
  headerRight: {
    fontSize: 12,
    color: '#717171',
    fontWeight: 600
  },
  headerLeft: {
    flex: 1,
    fontSize: 12,
    color: '#717171',
    fontWeight: 600
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20
    }
  },
  icon: {
    marginRight: 10,
    fontSize: 24,
    color: '#737373'
  },
  agreementTitle: {
    fontSize: 16,
    fontWeight: 600
  },
  content: {
    padding: 20,
    background: '#FDFDFD 0% 0% no-repeat padding-box',
    border: '1px solid #E6E6E6',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}))
