import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Icon, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'

import { LinkField, RoundedButton, Progress } from '../common'
import { useFormStyles, useMutation, useQuery } from '../hooks'
import { useSession } from '../auth'
import ResendOrgRequestModal from './ResendOrgRequestModal'
import { StatusSection, PendingRequestSection } from '../Organization/Agreements/StatusSections'
import { SEND_REQUEST_TO_JOIN_ORGANIZATION_EMAIL } from './mutations'
import { GET_MEMBERSHIP_REQUEST_SENT_DATE } from './queries'
import { CONTACT_SUPPORT_URL } from '../utils/constants'

const RequestPending = ({ org }) => {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { userId } = useSession()

  const [modalOpen, setModalOpen] = useState(false)

  const {
    data: { getMembershipRequestSentDate = {} } = {},
    loading: getMembershipRequestLoading
  } = useQuery(GET_MEMBERSHIP_REQUEST_SENT_DATE, {
    variables: {
      input: {
        userId,
        orgId: org.id
      }
    },
    skip: !userId || !(org && org.id)
  })

  const { execute: sendRequest, loading: sendEmailLoading } = useMutation(
    SEND_REQUEST_TO_JOIN_ORGANIZATION_EMAIL,
    {
      awaitRefetchQueries: true,
      onCompleted: result => {
        const { sendRequestToJoinOrganizationEmail } = result

        if (sendRequestToJoinOrganizationEmail.success) {
          enqueueSnackbar('Request Sent', {
            variant: 'success',
            autoHideDuration: 4500
          })
        }
      },
      refetchQueries: [
        {
          query: GET_MEMBERSHIP_REQUEST_SENT_DATE,
          variables: {
            input: {
              userId,
              orgId: org.id
            }
          }
        }
      ]
    }
  )

  if (getMembershipRequestLoading) {
    return null
  }

  return (
    <>
      <form>
        <div className={clsx(formClasses.header, classes.header)}>
          <Typography role="heading" variant="h3" className={formClasses.heading}>
            Request Pending
          </Typography>
          <Typography className={classes.subHeading}>
            Your request to join the TIP participation account for {org.name} has been sent to your
            organization's TIP Account Administrator.
          </Typography>
        </div>

        <div className={classes.pendingSignature}>
          <StatusSection
            disableMinHeight
            hideBorder
            sentDate={getMembershipRequestSentDate.emailSentDate || new Date()}
            colors={['orange']}
            icons={['av_timer']}
            titles={['Pending Approval']}
            Sections={[PendingRequestSection]}
            SubSection={
              getMembershipRequestSentDate.emailResentCount >= 3 ? (
                <>
                  <Typography paragraph>Having trouble joining an organization?</Typography>
                  <RoundedButton
                    color="primary"
                    className={classes.button}
                    href={CONTACT_SUPPORT_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Support
                  </RoundedButton>
                </>
              ) : sendEmailLoading ? (
                <Progress />
              ) : (
                <Grid container alignContent="center">
                  <Grid item xs="auto">
                    <Icon className={classes.smallIcon}>mail_outline</Icon>
                  </Grid>
                  <Grid item xs>
                    <LinkField label="Resend Request" to="#" onClick={() => setModalOpen(true)} />
                  </Grid>
                </Grid>
              )
            }
          />
        </div>
        <Typography variant="body2">
          You can check your participation status later by logging in to TIP with your email and
          password
        </Typography>
      </form>
      <ResendOrgRequestModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        handleSubmit={() =>
          sendRequest({
            values: {
              orgId: org.id
            }
          })
        }
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  header: {
    marginBottom: 20
  },
  subHeading: {
    marginBottom: 5,
    textAlign: 'left'
  },
  smallIcon: {
    marginRight: 10,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  pendingSignature: {
    textAlign: 'left',
    marginBottom: 25
  }
}))

export default RequestPending
