import React, { useState } from 'react'
import { Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { loadStripe } from '@stripe/stripe-js'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'

import { useFormStyles, useMutation, useQuery } from '../hooks'
import { errorMessages, isFormSubmitDisabled, inputRegexes } from '../utils'
import { CONTACT_SUPPORT_URL } from '../utils/constants'
import {
  InputTextFieldNew,
  LinkField,
  OrganizationAutocomplete,
  Progress,
  RoundedButton
} from '../common'
import { CREATE_STRIPE_SESSION } from './mutations'
import { GET_PARTICIPATION_TIER_PRICES } from './queries'

import config from '../config'

const stripePromise = loadStripe(config.STRIPE_API_PUBKEY)

const CheckoutForm = ({ setPaymentComplete = () => null }) => {
  const formClasses = useFormStyles()
  const classes = useStyles()

  const [formState, inputTypes] = useFormState({
    email: '',
    invoice: '',
    organization: { id: '', label: '' },
    price: {}
  })

  /*
    Error codes
    auth  - Failed to authenticate invoice information
    strp  - an error occured redirecting to stripe checkout
    srvr  - An error was thrown on the server
  */
  const [errorState, setErrorState] = useState('')
  const [showPriceDropdown, setShowPriceDropdown] = useState(false)

  const { execute: createStripeSessionExecute, loading: createStripeSessionLoading } = useMutation(
    CREATE_STRIPE_SESSION,
    {
      onCompleted: async ({
        createCheckoutSession: { paid, result, stripeSessionId } = {}
      } = {}) => {
        if (result) {
          if (paid) {
            // Invoice has already been paid
            // Show success page
            setPaymentComplete(true)
          } else {
            const stripeCheckout = await (await stripePromise).redirectToCheckout({
              sessionId: stripeSessionId
            })

            if (stripeCheckout.error) {
              // Failed to redirect to stripe checkout
              console.error(stripeCheckout.error.message)

              setErrorState('strp')
            }
          }
        } else {
          // Failed to authenticate invoice data
          setErrorState('auth')
          formState.setFieldError('email', '')
          formState.setFieldError('organization', '')
        }
      },
      onError: e => {
        // Error thrown in resolver
        console.error(e)

        setErrorState('srvr')
      }
    }
  )

  return (
    <>
      <Typography className={clsx(formClasses.heading, classes.heading)} variant="h3">
        Make a Payment
      </Typography>
      {errorState && (
        <Grid container className={classes.errorContainer}>
          <Grid item xs="auto">
            <ErrorOutlineIcon className={classes.errorIcon} />
          </Grid>
          <Grid item xs>
            <Typography className={classes.errorText}>
              {getErrorText({ errorCode: errorState })}{' '}
              <LinkField label="Contact support" to={CONTACT_SUPPORT_URL} />
            </Typography>
          </Grid>
        </Grid>
      )}
      <form
        onSubmit={e => {
          e.preventDefault()

          createStripeSessionExecute({
            values: {
              email: formState.values.email,
              invoice: formState.values.invoice,
              organizationId: formState.values.organization.id,
              priceIds: {
                membershipLevelId: formState.values.price.membershipLevelId,
                membershipLevelSubId: formState.values.price.membershipLevelSubId
              }
            }
          })
        }}
      >
        <Grid container spacing={4}>
          {Object.entries(
            inputs({ setField: formState.setField, showPriceDropdown, setShowPriceDropdown })
          )
            .filter(([name, inputData]) => !inputData.hidden)
            .map(
              ([
                name,
                {
                  gridWidth = { xs: 12 },
                  InputComponent = InputTextFieldNew,
                  inputType = 'text',
                  ...rest
                }
              ]) => (
                <Grid item {...gridWidth} key={name}>
                  <InputComponent
                    {...rest}
                    error={
                      typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                    }
                    errorHelperText={formState.errors[name]}
                    inputType={inputTypes[inputType]}
                    margin="none"
                    value={formState.values[name]}
                  />
                </Grid>
              )
            )}
          <Grid item xs={12}>
            <RoundedButton
              disabled={
                isFormSubmitDisabled(inputs(), formState, true) ||
                !formState.values.organization.id ||
                createStripeSessionLoading
              }
              type="submit"
            >
              {createStripeSessionLoading ? <Progress /> : 'Pay Now'}
            </RoundedButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

// Conditional error text
const getErrorText = ({ errorCode }) => {
  switch (errorCode) {
    case 'auth':
      return 'The email and organization entered do not match our records.'
    case 'strp':
    case 'srvr':
    default:
      return errorMessages.TIPSupport.substring(0, errorMessages.TIPSupport.indexOf('Contact') - 1)
  }
}

// Invoice form inputs
const inputs = ({
  setField = () => null,
  showPriceDropdown = false,
  setShowPriceDropdown = () => null
} = {}) => ({
  email: {
    helperText: 'The email address that appears on your invoice',
    inputType: 'email',
    label: 'Invoice email',
    name: 'email',
    validator: {
      required: true,
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email'
    }
  },
  organization: {
    allowNewOrgs: false,
    includePreApprovedOrgs: true,
    InputComponent: OrganizationAutocomplete,
    label: 'Organization name',
    name: 'organization',
    onChange: newValue => {
      if (typeof newValue !== 'string') {
        if (newValue && newValue.approvalStatus === 'approved') {
          setShowPriceDropdown(true)
        } else {
          setShowPriceDropdown(false)
        }
      }
    },
    setField,
    validator: {
      required: true
    }
  },
  invoice: {
    label: 'Invoice number',
    name: 'invoice',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 100
    }
  },
  price: {
    hidden: !showPriceDropdown,
    InputComponent: MembershipLevelsPriceDropdown,
    label: 'Participation Due Amount',
    name: 'price',
    setField,
    validator: {
      required: true
    }
  }
})

const MembershipLevelsPriceDropdown = ({ label, name, setField, value = {} }) => {
  const classes = useStyles()
  const { data: { membershipLevels = [] } = {}, loading: membershipLevelsLoading } = useQuery(
    GET_PARTICIPATION_TIER_PRICES
  )
  const options = membershipLevels
    .reduce((prev, cur) => {
      if (cur.price > 0) {
        prev.push({
          label: `$${cur.price}`,
          membershipLevelId: cur.id
        })
      }

      if (cur.changeRequestPrices) {
        cur.changeRequestPrices.forEach(crp => {
          if (crp.price > 0) {
            prev.push({
              label: `$${crp.price}`,
              membershipLevelId: cur.id,
              membershipLevelSubId: crp.id
            })
          }
        })
      }

      return prev
    }, [])
    .sort((a, b) => parseInt(b.label.substring(1)) - parseInt(a.label.substring(1)))
    .map((o, i) => ({ ...o, value: i + 1 }))
  const [showError, setShowError] = useState(false)
  const [errorText, setErrorText] = useState('')

  if (membershipLevelsLoading) {
    return <Progress />
  }

  const valueIndex =
    options.findIndex(
      o =>
        o.membershipLevelId === value.membershipLevelId &&
        o.membershipLevelSubId === value.membershipLevelSubId
    ) + 1

  return (
    <TextField
      fullWidth
      select
      className={classes.priceDropdown}
      error={showError && errorText.length > 0}
      helperText={showError ? errorText : ''}
      label={label}
      value={valueIndex > 0 ? valueIndex : ''}
      variant="outlined"
      onChange={e => {
        if (!showError) {
          setShowError(true)
        }

        if (!e.target.value) {
          setErrorText(`${label} is required`)
        } else {
          setErrorText('')
        }

        const selected = options[e.target.value - 1]

        if (selected) {
          setField(name, {
            membershipLevelId: selected.membershipLevelId,
            membershipLevelSubId: selected.membershipLevelSubId
          })
        } else {
          setField(name, {})
        }
      }}
    >
      {options.map(o => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

const useStyles = makeStyles(theme => ({
  errorContainer: {
    backgroundColor: '#FCEFEC',
    border: '1px solid #F5B6A8',
    borderRadius: 4,
    marginBottom: 36,
    padding: 16
  },
  errorIcon: {
    color: '#EB6F53',
    marginRight: 16
  },
  errorText: {
    color: '#972A12',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  heading: {
    fontSize: '2.25rem',
    marginBottom: 32
  },
  priceDropdown: {
    textAlign: 'start'
  }
}))

export default CheckoutForm
