import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import { CheckboxField, InputTextFieldNew, Progress, RoundedButton } from '../common'
import { inputRegexes } from '../utils'
import { useMutation } from '../hooks'
import { REQUEST_GLOSSIES_EMAIL } from './mutations'

const useStyles = makeStyles(theme => ({
  nameInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  emailInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20
    }
  },
  inputContainer: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5
  },
  emailHelper: {
    fontSize: 12,
    paddingLeft: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  checkboxContainer: {
    paddingLeft: 15,
    marginBottom: 20
  },
  linkText: {
    color: theme.palette.primary.main
  },
  privacyLabel: {
    fontSize: 11,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }
}))

function validateForm(formState, documents, subDocuments) {
  const inputs = ['firstName', 'lastName', 'workEmail']

  let selectedDocuments = []
  for (let i in formState.values) {
    if (
      (documents.hasOwnProperty(i) || subDocuments.hasOwnProperty(i)) &&
      formState.values[i] === true
    ) {
      selectedDocuments.push(i)
    }
  }

  let inputsValid = true

  for (let i = 0; i < inputs.length; i++) {
    if (!formState.validity[inputs[i]]) {
      inputsValid = false
      break
    }
  }

  if (selectedDocuments.length > 0 && inputsValid && formState.values.privacyPolicy) {
    return false
  } else {
    return true
  }
}

const GlossiesForm = ({ formState, inputTypes, documents, setOpen }) => {
  const classes = useStyles()
  const { loading, execute: requestEmail } = useMutation(REQUEST_GLOSSIES_EMAIL)

  let subDocuments = {}
  for (let doc in documents) {
    if (documents[doc].subDocuments) {
      documents[doc].subDocuments.forEach(sd => {
        subDocuments[sd.name] = sd
      })
    }
  }

  function submitForm(formState) {
    let requestedDocuments = []

    Object.keys(formState.values).forEach(v => {
      if ((documents.hasOwnProperty(v) || subDocuments.hasOwnProperty(v)) && formState.values[v]) {
        // add documents to request list
        requestedDocuments.push({
          name: v,
          link: documents[v] ? documents[v].link : subDocuments[v].link
        })
      }
    })

    requestEmail({
      values: {
        firstName: formState.values.firstName,
        lastName: formState.values.lastName,
        workEmail: formState.values.workEmail,
        requestedDocuments: requestedDocuments
      }
    }).then(() => {
      formState.reset()
      setOpen(true)
    })
  }

  let disableSubmit = validateForm(formState, documents, subDocuments)

  const privacyPolicylabel = (
    <span className={classes.privacyLabel}>
      I understand and agree to the&nbsp;
      <a
        className={classes.linkText}
        href="https://telecominfraproject.com/legal-and-privacy-policies/"
        rel="noopener noreferrer"
        target="_blank"
      >
        TIP Privacy Policy
      </a>
    </span>
  )

  // TODO - REFACTOR WITH MUI GRID
  return (
    <form id="glossies-form">
      <div className={classes.nameInputsContainer}>
        <div className={classes.inputContainer}>
          <InputTextFieldNew
            error={
              typeof formState.validity.firstName !== 'undefined' && !formState.validity.firstName
            }
            errorHelperText={formState.errors.firstName}
            inputType={inputTypes.text}
            label="First Name"
            name="firstName"
            style={{ background: '#FFF' }}
            validator={{
              required: true,
              minLength: 2,
              maxLength: 100
            }}
            value={formState.values.firstName}
          />
        </div>
        <div className={classes.inputContainer}>
          <InputTextFieldNew
            error={
              typeof formState.validity.lastName !== 'undefined' && !formState.validity.lastName
            }
            errorHelperText={formState.errors.lastName}
            style={{ background: '#FFF' }}
            name="lastName"
            label="Last Name"
            inputType={inputTypes.text}
            validator={{
              required: true,
              minLength: 2,
              maxLength: 100
            }}
            value={formState.values.lastName}
          />
        </div>
      </div>
      <div className={classes.emailInputContainer}>
        <div className={classes.inputContainer}>
          <InputTextFieldNew
            error={
              typeof formState.validity.workEmail !== 'undefined' && !formState.validity.workEmail
            }
            errorHelperText={formState.errors.workEmail}
            inputType={inputTypes.email}
            label="Work Email"
            name="workEmail"
            style={{ background: '#FFF' }}
            validator={{
              required: true,
              regex: inputRegexes.email,
              regexMessage: 'Must be a valid email'
            }}
            value={formState.values.workEmail}
          />
        </div>
        <Typography className={classes.emailHelper}>Your company or organization email</Typography>
      </div>
      <div className={classes.checkboxContainer}>
        <CheckboxField
          error={
            typeof formState.validity.privacyPolicy !== 'undefined' &&
            !formState.validity.privacyPolicy
          }
          errorHelperText={formState.errors.privacyPolicy}
          inputType={inputTypes.checkbox}
          label={privacyPolicylabel}
          name="privacyPolicy"
          value={formState.values.privacyPolicy}
        />
      </div>
      <div className={classes.buttonContainer}>
        <RoundedButton
          onClick={() => submitForm(formState)}
          disabled={disableSubmit}
          color="primary"
          fullWidth={true}
        >
          {loading ? <Progress size={25} delay={0} /> : 'Send me this info'}
        </RoundedButton>
      </div>
    </form>
  )
}

export default GlossiesForm
