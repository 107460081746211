import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { formatLongDateTime } from '../../../utils/dateTime'

export default function WeRecievedYourSignature({ signedDate, signedBy }) {
  const classes = useStyles()

  return (
    <Grid container className={classes.section}>
      <Typography className={classes.message}>On: {formatLongDateTime(signedDate)}</Typography>
      <Typography className={classes.message}>Signed By: {signedBy}</Typography>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'block'
  },
  message: {
    fontSize: 14
  }
}))
