import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import { InputTextFieldNew, Progress, RoundedButton } from '../common'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../utils'

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation($input: EmailInput!) {
    sendResetPasswordEmail(input: $input) {
      result
      data
    }
  }
`

export default function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar()

  const formClasses = useFormStyles()
  

  const [formState, inputTypes] = useFormState({ email: '' })

  const {
    data: { sendResetPasswordEmail: sendResetPasswordEmailData = { result: false } } = {},
    loading,
    execute: handleResetPassword,
    error
  } = useMutation(SEND_RESET_PASSWORD_EMAIL, {
    onCompleted: ({ sendResetPasswordEmail }) => {
      if (sendResetPasswordEmail.result) {
        enqueueSnackbar('Password Reset Request Submitted', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    }
  })

  const inputs = inputFields()

  const disableSubmit = isFormSubmitDisabled(inputs, formState) || sendResetPasswordEmailData.result

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        handleResetPassword({ values: formState.values })
      }}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={7} lg={6} className={formClasses.container}>
          <div className={formClasses.header}>
            <Typography role="heading" variant="h3" className={formClasses.heading}>
              Forgot Password?
            </Typography>
          </div>
          <div className={formClasses.alert}>
            {error && (
              <Typography role="alert" color="error">
                {console.error(error.graphQLErrors[0].message)}
                {errorMessages.TIPSupport}
              </Typography>
            )}
          </div>
          {Object.entries(inputs).map(([name, { inputType, ...rest }]) => (
            <InputTextFieldNew
              key={name}
              {...rest}
              error={
                typeof formState.validity[name] !== 'undefined' ? !formState.validity[name] : false
              }
              errorHelperText={formState.errors[name]}
              inputType={inputTypes[inputType]}
              value={formState.values[name]}
            />
          ))}
          <div className={formClasses.buttonContainer}>
            {loading ? (
              <Progress size={30} delay={0} />
            ) : (
              <RoundedButton
                disabled={disableSubmit}
                data-testid="send-email" // for tests ONLY
                fullWidth={false}
                type="submit"
              >
                Send Email
              </RoundedButton>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

const inputFields = () => ({
  email: {
    autoFocus: true,
    inputType: 'email',
    label: 'Email',
    name: 'email',
    validator: {
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email',
      required: true
    }
  }
})


