import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-use-form-state'
import equal from 'fast-deep-equal'
import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import {
  CheckboxField,
  DropDownField,
  ErrorSection,
  InputTextFieldNew,
  Progress,
  RoundedButton,
  OrganizationAutocomplete,
  TIPDialog
} from '../common'
import ProjectGroupChangeEmailModal from './ProjectGroupChangeEmailModal'
import { useSession } from '../auth/queries'
import { isFormSubmitDisabled, useProjectGroupAgreements, useProjectGroupCharters } from '../utils'
import { useMutation, useQuery } from '../hooks'
import {
  CREATE_PROJECT_GROUP,
  EDIT_PROJECT_GROUP,
  ATLASSIAN_CREATE_SECURITY_GROUP
} from './mutations'
import { GET_ALL_ACTIVE_PROJECT_GROUPS, GET_ALL_PROJECT_GROUPS } from './queries'

const ProjectGroupItem = ({
  add = false,
  closeAfterSnackbar,
  hasUnsavedChanges,
  projectGroup,
  queryOptions,
  setHasUnsavedChanges,
  isReadOnly = false
}) => {
  const classes = useStyles()
  const userId = useSession().userId

  const projectGroupProfile = {
    approvedOrgs: projectGroup.approvedOrgs || [],
    atlassianGroup: projectGroup.atlassianGroup || '',
    charterTitle: projectGroup.charterTitle || '',
    detailLink: projectGroup.detailLink || '',
    description: projectGroup.description || '',
    githubTeam: projectGroup.githubTeam || '',
    graduated: projectGroup.graduated || false,
    jira: projectGroup.jira || false,
    new: projectGroup.new || false,
    name: projectGroup.name || '',
    parentProjectGroupId: projectGroup.parentProjectGroupId || '',
    softwareProjectGroup: projectGroup.softwareProjectGroup || false,
    supportMustVerify: projectGroup.supportMustVerify,
    openToMembers: projectGroup.openToMembers,
    sfId: projectGroup.sfId || '',
    workplaceId: '0'
  }

  if (projectGroupProfile.parentProjectGroupId) {
    projectGroupProfile.restricted = projectGroup.restricted || false

    if (projectGroupProfile.restricted) {
      projectGroupProfile.agreementName = projectGroup.agreement ? projectGroup.agreement.name : ''
    }
  } else {
    projectGroupProfile.charterAgreementName = projectGroup.charterAgreement
      ? projectGroup.charterAgreement.name
      : ''
  }

  const [pgEmailModalOpen, setPgEmailModalOpen] = useState(false)
  const [atlassianSecurityGroupId, setAtlassianSecurityGroupId] = useState('')
  const [atlassianSecurityGroupIdKnownError, setAtlassianSecurityGroupIdKnownError] = useState('')
  const [
    openAtlassianSecurityGroupConfirmation,
    setOpenAtlassianSecurityGroupConfirmation
  ] = useState(false)
  const [formState, inputTypes] = useFormState(projectGroupProfile)

  useEffect(() => {
    if (formState.values.atlassianGroup) {
      // run on component mount and check if value already exist. if so, setting it disables the control
      setAtlassianSecurityGroupId(formState.values.atlassianGroup || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let atlassianSecurityGroupErrorMessage = null

  useEffect(() => {
    const isDiff = !equal(formState.values, projectGroupProfile)
    if (hasUnsavedChanges !== isDiff) {
      setHasUnsavedChanges(isDiff)
    }
  }, [formState.values, hasUnsavedChanges, projectGroupProfile, setHasUnsavedChanges])

  const {
    data: { projectGroups = [] },
    loading: projectGroupsLoading
  } = useQuery(GET_ALL_ACTIVE_PROJECT_GROUPS, {})

  const {
    agreements: projectGroupAgreements = [],
    loading: projectGroupAgreementsLoading
  } = useProjectGroupAgreements()

  const {
    charters: projectGroupCharters = [],
    loading: projectGroupChartersLoading
  } = useProjectGroupCharters()

  const { loading, execute: executeSubmit, error } = useMutation(
    add ? CREATE_PROJECT_GROUP : EDIT_PROJECT_GROUP,
    {
      awaitRefetchQueries: true,
      ...queryOptions,
      refetchQueries: [
        ...(queryOptions.refetchQueries ? queryOptions.refetchQueries : []),
        { query: GET_ALL_PROJECT_GROUPS },
        { query: GET_ALL_ACTIVE_PROJECT_GROUPS }
      ],
      onCompleted: data => {
        if (data.updateProjectGroup) {
          closeAfterSnackbar(false)
        } else if (data.addProjectGroup) {
          closeAfterSnackbar(true)
        }
      }
    }
  )

  const {
    loading: atlassianCreateSecurityGroupLoading,
    execute: atlassianCreateSecurityGroupExecute,
    error: atlassianCreateSecurityGroupError
  } = useMutation(ATLASSIAN_CREATE_SECURITY_GROUP, {
    onCompleted: data => {
      const atlassianSecurityGroupId = data.atlassianCreateSecurityGroup.atlassianGroupId

      formState.setField('atlassianGroup', atlassianSecurityGroupId)
      setAtlassianSecurityGroupId(atlassianSecurityGroupId || '')
      setAtlassianSecurityGroupIdKnownError(data.atlassianCreateSecurityGroup.error)
      setOpenAtlassianSecurityGroupConfirmation(false)
    }
  })

  // set UI msg for error returned when calling atlassian api to create new security group
  if (atlassianSecurityGroupIdKnownError) {
    atlassianSecurityGroupErrorMessage = atlassianSecurityGroupIdKnownError
  } else if (atlassianCreateSecurityGroupError) {
    atlassianSecurityGroupErrorMessage = `Error creating security group`
  }

  const projectGroupsMap = projectGroups
    .filter(pg => !pg.parentProjectGroupId)
    .map(pg => ({
      id: pg.id,
      name: pg.name,
      approvedOrgs: pg.approvedOrgs
    }))

  const handleSubmit = () => {
    const submitData = {
      ...formState.values,
      updatedBy: userId
    }

    delete submitData.agreementName
    delete submitData.charterAgreementName

    if (submitData.parentProjectGroupId) {
      submitData.softwareProjectGroup = null

      if (submitData.restricted) {
        submitData.agreementId = projectGroupAgreements.find(
          a => a.name === formState.values.agreementName
        ).id
      } else {
        submitData.agreementId = null

        delete formState.values.agreementName
      }

      delete formState.values.charterAgreementName
      delete formState.values.softwareProjectGroup
    } else {
      submitData.charterAgreementId = projectGroupCharters.find(
        a => a.name === formState.values.charterAgreementName
      ).id

      submitData.agreementId = null
      submitData.parentProjectGroupId = null
      submitData.restricted = null

      delete formState.values.agreementName
      delete formState.values.restricted
    }

    if (add) {
      executeSubmit({ values: submitData })
    } else {
      executeSubmit({ id: projectGroup.id, values: submitData })
    }

    setPgEmailModalOpen(false)
  }
  const handleConfirmAtlassianSecurityGroupConfirmation = () => {
    atlassianCreateSecurityGroupExecute({
      values: {
        pgName: formState.values.name
      }
    })
  }

  if (projectGroupsLoading || projectGroupAgreementsLoading || projectGroupChartersLoading) {
    return null
  }

  const projectGroupFieldsObject = projectGroupFields(
    classes,
    add,
    formState,
    projectGroupAgreements,
    projectGroupCharters,
    projectGroupsMap,
    atlassianCreateSecurityGroupLoading,
    atlassianSecurityGroupErrorMessage,
    atlassianSecurityGroupId,
    setAtlassianSecurityGroupId,
    setOpenAtlassianSecurityGroupConfirmation
  )

  const disableSubmit = isFormSubmitDisabled(projectGroupFieldsObject, formState, true)

  return (
    <Grid container spacing={2} className={clsx({ [classes.readOnly]: isReadOnly })}>
      {Object.entries(projectGroupFieldsObject).map(
        ([name, { gridWidth = { xs: 12 }, hidden, InputComponent, inputType, ...rest }]) => {
          let Component = null
          if (!hidden && InputComponent === RoundedButton) {
            Component = (
              <RoundedButton disabled={isReadOnly} value={formState.values[name]} {...rest} />
            )
          } else if (!hidden) {
            Component = (
              <InputComponent
                disabled={isReadOnly}
                error={typeof formState.validity[name] !== 'undefined' && !formState.validity[name]}
                errorHelperText={formState.errors[name]}
                inputType={inputTypes[inputType]}
                value={formState.values[name]}
                {...rest}
              />
            )
          }
          if (Component) {
            return (
              <Grid item key={name} {...gridWidth}>
                {Component}
              </Grid>
            )
          } else {
            return null
          }
        }
      )}
      {!isReadOnly && (
        <Grid container item justify="center">
          <Grid item sm={5} xs={12}>
            <RoundedButton
              fullWidth
              className={classes.roundedButton}
              color="primary"
              disabled={!hasUnsavedChanges || loading || disableSubmit}
              onClick={() => setPgEmailModalOpen(true)}
            >
              {loading ? (
                <Progress delay={0} size={25} />
              ) : add ? (
                'Create Project Group'
              ) : (
                'Update Project Group'
              )}
            </RoundedButton>
            {error &&
              ((error.graphQLErrors[0].message.includes('duplicate key') &&
                error.graphQLErrors[0].message.includes('name_1')) ||
              error.graphQLErrors[0].message.includes('Project Group name exists') ? (
                <ErrorSection>The Project Group name already exists</ErrorSection>
              ) : (
                <ErrorSection>Something went wrong</ErrorSection>
              ))}
            <ProjectGroupChangeEmailModal
              add={add}
              detailLink={formState.values.detailLink}
              graduated={formState.values.graduated}
              open={pgEmailModalOpen}
              parentPgId={formState.values.parentProjectGroupId}
              pgDesc={formState.values.description}
              pgId={projectGroup.id}
              pgName={formState.values.name}
              setOpen={setPgEmailModalOpen}
              submitAfterEmailSelection={handleSubmit}
            />
            <TIPDialog
              title="Create Atlassian Security Group"
              bodyText="Are you sure you want to create an Atlassian Security Group?"
              open={openAtlassianSecurityGroupConfirmation}
              showClose
              closeLabel="Cancel"
              handleClose={() => setOpenAtlassianSecurityGroupConfirmation(false)}
              showConfirm
              handleConfirm={handleConfirmAtlassianSecurityGroupConfirmation}
              loadingConfirm={atlassianCreateSecurityGroupLoading}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  roundedButton: {
    borderRadius: 5,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  readOnly: {
    cursor: 'not-allowed !important',
    '& *': {
      pointerEvents: 'none'
    }
  },
  errorText: {
    color: theme.palette.error.main
  }
}))

const projectGroupFields = (
  classes,
  add,
  formState,
  projectGroupAgreements,
  projectGroupCharters,
  projectGroupsMap,
  atlassianCreateSecurityGroupLoading,
  atlassianSecurityGroupErrorMessage,
  atlassianSecurityGroupId,
  setAtlassianSecurityGroupId,
  setOpenAtlassianSecurityGroupConfirmation
) => ({
  name: {
    autoFocus: true,
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Project Group Name*',
    name: 'name',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 100
    }
  },
  workplaceId: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Workplace Id*',
    name: 'workplaceId',
    hidden: true,
    validator: {
      required: false
    }
  },
  sfId: {
    helperText: add
      ? 'If you do not enter an id a Project Group will be created in salesforce.'
      : '',
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: add ? 'Salesforce Id' : 'Salesforce Id*',
    name: 'sfId',
    validator: {
      required: !add
    }
  },
  githubTeam: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'GitHub Team',
    name: 'githubTeam'
  },
  atlassianGroup: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Atlassian Group',
    name: 'atlassianGroup',
    value: atlassianSecurityGroupId ? atlassianSecurityGroupId : '',
    disabled: atlassianSecurityGroupId ? true : false,
    helperText: atlassianSecurityGroupErrorMessage ? (
      <span className={classes.errorText}>{atlassianSecurityGroupErrorMessage}</span>
    ) : null
  },
  jira: {
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'JIRA',
    name: 'jira'
  },
  atlassianCreateSecurityGroup: {
    InputComponent: RoundedButton,
    name: 'atlassianCreateSecurityGroup',
    children: atlassianCreateSecurityGroupLoading ? (
      <Progress size={25} delay={0} />
    ) : (
      'CREATE SECURITY GROUP'
    ),
    disabled:
      formState.values.name && (!formState.values.atlassianGroup && !atlassianSecurityGroupId)
        ? false
        : true,
    onClick: () => {
      setOpenAtlassianSecurityGroupConfirmation(true)
    }
  },
  description: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Project Group Description',
    multiline: true,
    name: 'description',
    rows: 4,
    validator: {
      minLength: 2,
      maxLength: 1000
    }
  },
  detailLink: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Detail Link',
    name: 'detailLink',
    validator: {
      minLength: 2,
      maxLength: 1000
    }
  },
  softwareProjectGroup: {
    hidden: formState.values.parentProjectGroupId,
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'Software Project Group',
    name: 'softwareProjectGroup'
  },
  new: {
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'New',
    name: 'new'
  },
  graduated: {
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'Graduated',
    name: 'graduated'
  },
  parentProjectGroupName: {
    autoComplete: 'parentProjectGroupId',
    clearText: 'Remove Parent Project Group',
    InputComponent: DropDownField,
    isClearable: true,
    label: `Parent Project Group Name`,
    name: 'parentProjectGroupName',
    options: projectGroupsMap.map(pg => pg.name),
    value: formState.values.parentProjectGroupId
      ? (
          projectGroupsMap.find(pg => pg.id === formState.values.parentProjectGroupId) || {
            name: ''
          }
        ).name
      : '',
    handleChange: e =>
      formState.setField(
        'parentProjectGroupId',
        e.target.value ? projectGroupsMap.find(pg => pg.name === e.target.value).id : ''
      )
  },
  charterAgreementName: {
    hidden: formState.values.parentProjectGroupId,
    InputComponent: DropDownField,
    label: 'Charter Agreement*',
    menuPlacement: 'top',
    name: 'charterAgreement',
    options: projectGroupCharters.map(t => t.name),
    validator: {
      required: !formState.values.parentProjectGroupId
    },
    value: formState.values.charterAgreementName || '',
    handleChange: e => formState.setField('charterAgreementName', e.target.value)
  },
  charterTitle: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Charter Title',
    name: 'charterTitle',
    validator: {
      minLength: 2,
      maxLength: 1000
    }
  },
  restricted: {
    helperText:
      'If selected the organization will be required to sign a Project Group Agreement before they can join, and by default all participants will have to request access',
    hidden: !formState.values.parentProjectGroupId,
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'Restricted',
    name: 'restricted'
  },
  openToMembers: {
    helperText:
      'If selected once the organization is approved participants can freely join instead of requesting access and needing approval',
    hidden: !formState.values.restricted,
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'Restricted/ Open to Participants',
    name: 'openToMembers'
  },
  supportMustVerify: {
    helperText:
      'If enabled "SELECT" will send an email to tipsupport. (designed for ORAN ALLIANCE)',
    hidden: !formState.values.parentProjectGroupId,
    InputComponent: CheckboxField,
    inputType: 'checkbox',
    label: 'Support Must Verify (ORAN ALLIANCE)',
    name: 'supportMustVerify'
  },
  agreementName: {
    hidden: !formState.values.parentProjectGroupId || !formState.values.restricted,
    InputComponent: DropDownField,
    isClearable: true,
    label: 'Project Group Agreement*',
    menuPlacement: 'top',
    name: 'agreementName',
    options: projectGroupAgreements.map(t => t.name),
    value: formState.values.agreementName || '',
    validator: {
      required: !!formState.values.restricted
    },
    handleChange: e => formState.setField('agreementName', e.target.value)
  },
  approvedOrgs: {
    helperText: 'Leave blank to make this project group visible to all organizations',
    hideListHeader: true,
    idList: (
      projectGroupsMap.find(pg => pg.id === formState.values.parentProjectGroupId) || {
        approvedOrgs: []
      }
    ).approvedOrgs,
    InputComponent: OrganizationAutocomplete,
    label: 'Approved Organizations',
    multiple: true,
    name: 'approvedOrgs',
    setField: formState.setField
  }
})

export default ProjectGroupItem
