import React, { useRef, useState } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable from 'material-table'
import clsx from 'clsx'

import RenewingOrgsListActions from './RenewingOrgsListActions'
import { useSession } from '../../auth/queries'
import EditOrganizationModal from '../Profile'
import { useQuery } from '../../hooks'
import { GET_RENEWING_ORGS } from './queries'
import { formatDate } from '../../utils/dateTime'

const RenewingOrgsList = ({
  getTierLabel = () => null,
  location = {},
  TooltipHelper = () => null
}) => {
  const { isReadOnlySystemAdmin } = useSession()
  const classes = useStyles()
  const filterValues = useRef({})
  const errorState = useState([])

  const { data: { getRenewingOrgs: orgData = [] } = {}, loading: orgLoading } = useQuery(
    GET_RENEWING_ORGS
  )

  return (
    <ExpansionPanel
      classes={{ expanded: classes.expansionPanelExpanded }}
      className={classes.expansionPanel}
      elevation={0}
    >
      <ExpansionPanelSummary
        classes={{ root: classes.expansionPanelSummaryRoot }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container direction="column">
          <Grid container item>
            <Grid item>
              <Typography className={classes.title}>Renewing Organizations Payments</Typography>
            </Grid>
            {orgData.length > 0 && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.notificationCount}
                justify="center"
              >
                <Grid item>{orgData.length}</Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Typography>
              Existing Organizations that are due to pay their participation tier 60 days prior to
              their renewal date.
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        <MaterialTable
          columns={columnDef({
            classes,
            errorState,
            filterValues,
            getTierLabel,
            isReadOnlySystemAdmin,
            location,
            TooltipHelper
          })}
          components={{
            Toolbar: props => {
              props.columns.forEach(c => {
                const field = c.field
                const filterValue = c.tableData.filterValue

                if (filterValue) {
                  filterValues.current[field] = filterValue
                } else if (filterValues.current[field]) {
                  filterValues.current[field] = null
                }
              })

              return null
            }
          }}
          data={orgData}
          isLoading={orgLoading}
          options={{
            draggable: false,
            filtering: true,
            pageSize: 10,
            showTitle: false
          }}
          title="Renewing Organizations Payments"
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const tierLookup = { 0: 'Software', 1: 'Associate', 2: 'Full', 3: 'None' }
const statusLookup = { 0: 'Approved', 1: 'Suspended' }

const columnDef = ({
  classes,
  errorState,
  filterValues,
  getTierLabel,
  isReadOnlySystemAdmin,
  location,
  TooltipHelper
}) => {
  const columns = [
    {
      customSort: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      defaultFilter: filterValues.current.name || '',
      field: 'name',
      render: rowData =>
        errorState[0].includes(rowData.id) ? (
          <Typography className={classes.errorSection}>
            Encountered an error updating {rowData.name}
          </Typography>
        ) : (
          <EditOrganizationModal id={rowData.id} returnUrl={location.pathname}>
            {onClick => (
              <TooltipHelper
                componentProps={{
                  className: classes.editOrgLink,
                  onClick
                }}
                content={rowData.name}
              />
            )}
          </EditOrganizationModal>
        ),
      searchable: true,
      title: 'Name'
    },
    {
      customFilterAndSearch: (value, { membership: { membershipLevelId } = {} } = {}) =>
        value.length === 0 ||
        value.some(
          v =>
            (tierLookup[v] === 'None' && !membershipLevelId) ||
            getTierLabel({ membershipLevelId })
              .long.toLowerCase()
              .startsWith(tierLookup[v].toLowerCase())
        ),
      customSort: (
        { membership: { membershipLevelId: membershipLevelIdA } = {} } = {},
        { membership: { membershipLevelId: membershipLevelIdB } = {} } = {}
      ) =>
        getTierLabel({ membershipLevelId: membershipLevelIdA })
          .short.toLowerCase()
          .localeCompare(
            getTierLabel({ membershipLevelId: membershipLevelIdB }).short.toLowerCase()
          ),
      defaultFilter: filterValues.current.tier || '',
      field: 'tier',
      lookup: tierLookup,
      render: ({ id: orgId, membership: { membershipLevelId } = {} } = {}) => {
        if (errorState[0].includes(orgId)) {
          return null
        }

        const tierLabel = getTierLabel({ membershipLevelId })

        return <TooltipHelper content={tierLabel.short} tooltipTitle={tierLabel.long} />
      },
      title: 'Tier'
    },
    {
      customFilterAndSearch: (value, { organizationRoles: { organizationAdmin } = {} } = {}) => {
        if (!organizationAdmin) {
          return false
        }

        const valueSplit = value
          .replace(/\s\s+/g, ' ')
          .trim()
          .split(' ')

        if (valueSplit.length === 1) {
          return (
            organizationAdmin.firstname.toLowerCase().includes(valueSplit[0].toLowerCase()) ||
            organizationAdmin.lastname.toLowerCase().includes(valueSplit[0].toLowerCase())
          )
        }

        if (valueSplit.length === 2) {
          return (
            organizationAdmin.firstname.toLowerCase() === valueSplit[0].toLowerCase() &&
            organizationAdmin.lastname.toLowerCase().includes(valueSplit[1].toLowerCase())
          )
        }

        return false
      },
      customSort: (
        { organizationRoles: { organizationAdmin: orgAdminA } = {} } = {},
        { organizationRoles: { organizationAdmin: orgAdminB } = {} } = {}
      ) => {
        if (!orgAdminA) {
          return 1
        }

        if (!orgAdminB) {
          return -1
        }

        return `${orgAdminA.firstname} ${orgAdminA.lastname}`
          .toLowerCase()
          .localeCompare(`${orgAdminB.firstname} ${orgAdminB.lastname}`.toLowerCase())
      },
      defaultFilter: filterValues.current.orgAdmin || '',
      field: 'orgAdmin',
      render: ({ id: orgId, organizationRoles: { organizationAdmin } = {} } = {}) =>
        !errorState[0].includes(orgId) && (
          <TooltipHelper content={`${organizationAdmin.firstname} ${organizationAdmin.lastname}`} />
        ),
      searchable: true,
      title: 'Account Admin'
    },
    {
      customFilterAndSearch: (
        value,
        { organizationRoles: { authorizedSigners = [] } = {} } = {}
      ) => {
        if (authorizedSigners.length === 0) {
          return false
        }

        const authRep = authorizedSigners[0].user ? authorizedSigners[0].user : authorizedSigners[0]
        const valueSplit = value
          .replace(/\s\s+/g, ' ')
          .trim()
          .split(' ')

        if (valueSplit.length === 1) {
          return (
            authRep.firstname.toLowerCase().includes(valueSplit[0].toLowerCase()) ||
            authRep.lastname.toLowerCase().includes(valueSplit[0].toLowerCase())
          )
        }

        if (valueSplit.length === 2) {
          return (
            authRep.firstname.toLowerCase() === valueSplit[0].toLowerCase() &&
            authRep.lastname.toLowerCase().includes(valueSplit[1].toLowerCase())
          )
        }

        return false
      },
      customSort: (
        { organizationRoles: { authorizedSigners: authorizedSignersA } = {} } = {},
        { organizationRoles: { authorizedSigners: authorizedSignersB } = {} } = {}
      ) => {
        if (authorizedSignersA.length === 0) {
          return 1
        }

        if (authorizedSignersB.length === 0) {
          return -1
        }

        const authRepA = authorizedSignersA[0].user
          ? authorizedSignersA[0].user
          : authorizedSignersA[0]
        const authRepB = authorizedSignersB[0].user
          ? authorizedSignersB[0].user
          : authorizedSignersB[0]

        return `${authRepA.firstname} ${authRepA.lastname}`
          .toLowerCase()
          .localeCompare(`${authRepB.firstname} ${authRepB.lastname}`.toLowerCase())
      },
      defaultFilter: filterValues.current.authRep || '',
      field: 'authRep',
      render: ({ id: orgId, organizationRoles: { authorizedSigners = [] } = {} } = {}) => {
        if (errorState[0].includes(orgId)) {
          return null
        }

        const authRep = authorizedSigners[0].user ? authorizedSigners[0].user : authorizedSigners[0]

        return <TooltipHelper content={`${authRep.firstname} ${authRep.lastname}`} />
      },
      searchable: true,
      title: 'Auth Rep'
    },
    {
      customFilterAndSearch: (value, { organizationRoles: { billingContact } = {} } = {}) => {
        if (!billingContact) {
          return false
        }

        const billContact = billingContact.user ? billingContact.user : billingContact
        const valueSplit = value
          .replace(/\s\s+/g, ' ')
          .trim()
          .split(' ')

        if (valueSplit.length === 1) {
          return (
            billContact.firstname.toLowerCase().includes(valueSplit[0].toLowerCase()) ||
            billContact.lastname.toLowerCase().includes(valueSplit[0].toLowerCase())
          )
        }

        if (valueSplit.length === 2) {
          return (
            billContact.firstname.toLowerCase() === valueSplit[0].toLowerCase() &&
            billContact.lastname.toLowerCase().includes(valueSplit[1].toLowerCase())
          )
        }

        return false
      },
      customSort: (
        { organizationRoles: { billingContact: billingContactA } = {} } = {},
        { organizationRoles: { billingContact: billingContactB } = {} } = {}
      ) => {
        if (!billingContactA) {
          return 1
        }

        if (!billingContactB) {
          return -1
        }

        const billContactA = billingContactA.user ? billingContactA.user : billingContactA
        const billContactB = billingContactB.user ? billingContactB.user : billingContactB

        return `${billContactA.firstname} ${billContactA.lastname}`
          .toLowerCase()
          .localeCompare(`${billContactB.firstname} ${billContactB.lastname}`.toLowerCase())
      },
      defaultFilter: filterValues.current.billingContact || '',
      field: 'billingContact',
      render: ({ id: orgId, organizationRoles: { billingContact } = {} } = {}) => {
        if (errorState[0].includes(orgId)) {
          return null
        }

        const billContact = billingContact.user ? billingContact.user : billingContact

        return <TooltipHelper content={`${billContact.firstname} ${billContact.lastname}`} />
      },
      searchable: true,
      title: 'Billing Contact'
    },
    {
      customFilterAndSearch: (value, { approvalStatus } = {}) =>
        value.length === 0 ||
        value.some(v => approvalStatus.toLowerCase() === statusLookup[v].toLowerCase()),
      customSort: (
        { approvalStatus: approvalStatusA } = {},
        { approvalStatus: approvalStatusB } = {}
      ) => {
        if (!approvalStatusA) {
          return 1
        }

        if (!approvalStatusB) {
          return -1
        }

        return approvalStatusA.toLowerCase().localeCompare(approvalStatusB.toLowerCase())
      },
      defaultFilter: filterValues.current.approvalStatus || '',
      field: 'approvalStatus',
      lookup: statusLookup,
      render: ({ approvalStatus, id: orgId } = {}) =>
        !errorState[0].includes(orgId) && (
          <TooltipHelper
            componentProps={{ className: clsx(classes.approvalStatus, classes[approvalStatus]) }}
            content={approvalStatus}
          />
        ),
      title: 'Status'
    },
    {
      customFilterAndSearch: (value, { membership: { renewalDate } = {} } = {}) => {
        if (!renewalDate) {
          return false
        }

        return formatDate(renewalDate).includes(value)
      },
      customSort: (
        { membership: { renewalDate: renewalDateA } = {} } = {},
        { membership: { renewalDate: renewalDateB } = {} } = {}
      ) => {
        if (!renewalDateA) {
          return 1
        }

        if (!renewalDateB) {
          return -1
        }

        return new Date(renewalDateA) - new Date(renewalDateB)
      },
      defaultFilter: filterValues.current.renewalDate || '',
      field: 'renewalDate',
      render: ({ id: orgId, membership: { renewalDate } = {} } = {}) =>
        !errorState[0].includes(orgId) && <TooltipHelper content={formatDate(renewalDate)} />,
      searchable: true,
      title: 'Renewal Date'
    }
  ]

  if (!isReadOnlySystemAdmin) {
    columns.push({
      render: rowData =>
        !errorState[0].includes(rowData.id) && (
          <RenewingOrgsListActions
            organization={rowData}
            setErrorState={() => {
              const updatedErrorState = [...errorState[0]]

              updatedErrorState.push(rowData.id)

              errorState[1](updatedErrorState)
            }}
          />
        ),
      title: 'Actions'
    })
  }

  return columns
}

const useStyles = makeStyles(theme => ({
  approvalStatus: {
    borderRadius: 22,
    padding: 10,
    textAlign: 'center'
  },
  approved: {
    backgroundColor: theme.palette.success.main
  },
  suspended: {
    backgroundColor: theme.palette.suspended.main
  },
  columnOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  editOrgLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  errorSection: {
    color: theme.palette.error.main
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`
  },
  expansionPanelDetails: {
    display: 'initial',
    padding: 0
  },
  expansionPanelExpanded: {
    marginTop: '0 !important'
  },
  expansionPanelSummaryRoot: {
    background: theme.palette.gray13.main
  },
  headerContainer: {
    padding: theme.spacing(2)
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.gray12.main
  },
  notificationCount: {
    background: theme.palette.dashboardNotification.main,
    borderRadius: '50%',
    color: 'white',
    height: 25,
    marginLeft: theme.spacing(2),
    width: 25
  },
  title: {
    fontWeight: 'bold'
  }
}))

export default RenewingOrgsList
