import gql from 'graphql-tag'

export const SAVE_AGREEMENT = gql`
  mutation($input: SaveAgreementInput!) {
    saveAgreement(input: $input) {
      id
      name
      type
      notes
      version
      versionDate
      fileName
      templateId
    }
  }
`
