import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Paper, Grid } from '@material-ui/core'
import RoundedButton from '../../common/RoundedButton'
import useStyles from './landingStyles'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const LandingCardDone = ({
  topText,
  bottomText,
  Icon,
  iconClass,
  CheckSign,
  redirectPath,
  disabled,
  hideEditButton,
  hoverButton,
  editModal,
  ModalComponent
}) => {
  const [isHovering, setHovering] = useState(false)

  const classes = useStyles()

  return (
    <Paper
      className={clsx({
        [classes.paper]: !disabled,
        [classes.paperDisabled]: disabled,
        [classes.paperHover]: isHovering
      })}
      square={true}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Grid container alignItems="center" justify="flex-start" className={classes.gridContainer}>
        <Grid item xs="auto">
          <Icon className={iconClass} />
        </Grid>
        <Grid item xs className={classes.cardTextContainer}>
          <Typography className={classes.paperTextTop}>{topText}</Typography>
          <Grid container className={!editModal ? classes.checkContainer : null}>
            <Grid item xs="auto">
              <CheckSign
                xs={1}
                className={clsx({
                  [classes.noSign]: disabled,
                  [classes.noSignHover]: isHovering && disabled,
                  [classes.check]: !disabled
                })}
              />
            </Grid>
            <Grid item xs>
              <Typography
                className={clsx([
                  classes.paperTextBot,
                  {
                    [classes.paperTextBotHover]: isHovering && disabled
                  }
                ])}
              >
                {bottomText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {editModal ? (
          <Grid
            className={
              hoverButton ? classes.paperButtonContainer : classes.paperButtonContainerNoHover
            }
            item
            xs="auto"
          >
            <ModalComponent>
              {onClick => (
                <RoundedButton className={classes.paperButton} onClick={onClick}>
                  Edit
                </RoundedButton>
              )}
            </ModalComponent>
          </Grid>
        ) : (
          redirectPath &&
          !hideEditButton &&
          (!hoverButton || isHovering) && (
            <Grid
              className={
                hoverButton ? classes.paperButtonContainer : classes.paperButtonContainerNoHover
              }
              item
              xs="auto"
            >
              <Link to={redirectPath} className={classes.link}>
                <RoundedButton className={classes.paperButton}>Edit</RoundedButton>
              </Link>
            </Grid>
          )
        )}
      </Grid>
    </Paper>
  )
}

export default LandingCardDone
