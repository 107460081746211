import gql from 'graphql-tag'

export const GET_ORG_BILLING_DATA = gql`
  query($id: ID!) {
    organization(id: $id) {
      id
      name
      membership {
        membershipLevelId
        status
        startDate
        renewalDate
        paid
      }
      organizationRoles {
        authorizedSigners {
          user {
            id
            firstname
            lastname
            email
          }
          email
          firstname
          lastname
          jobTitle
        }
        billingContact {
          user {
            id
            email
            firstname
            lastname
            jobTitle
          }
          email
          firstname
          lastname
          jobTitle
        }
        legalDesignee {
          user {
            id
            email
            firstname
            lastname
            jobTitle
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
    }
  }
`

export const GET_ORG_USERS = gql`
  query($id: ID!) {
    organizationUsers(id: $id) {
      id
      firstname
      lastname
      email
      jobTitle
    }
  }
`

export const GET_ORGS_FOR_PRE_APPROVE = gql`
  query {
    getOrganizationsForPreApprove {
      id
      name
      website
      shippingAddress {
        country
      }
      membership {
        membershipLevelId
      }
      organizationRoles {
        organizationAdmin {
          id
          email
          firstname
          lastname
        }
        authorizedSigners {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
    }
  }
`

export const GET_PRE_APPROVED_ORGS = gql`
  query {
    getPreApprovedOrgs {
      id
      name
      website
      shippingAddress {
        country
      }
      signedAgreements {
        name
        envelopeId
        sentDate
        signedDate
        counterSignedBy
        signedBy
        agreementType
        sentTo {
          firstname
          lastname
          email
        }
        counterSignedDate
        storageUrl
      }
      membership {
        membershipLevelId
        renewalDate
      }
      organizationRoles {
        organizationAdmin {
          id
          email
          firstname
          lastname
        }
        authorizedSigners {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
        billingContact {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
    }
  }
`

export const GET_RENEWING_ORGS = gql`
  query {
    getRenewingOrgs {
      id
      approvalStatus
      name
      membership {
        membershipLevelId
        renewalDate
      }
      organizationRoles {
        organizationAdmin {
          id
          email
          firstname
          lastname
        }
        authorizedSigners {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
        billingContact {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
    }
  }
`
