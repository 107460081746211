import gql from 'graphql-tag'

export const GET_ORG_DOCUMENTS = gql`
  query getOrganizationDocuments {
    organizationDocuments {
      id
      access
      location
      name
      rank
      url
    }
  }
`
