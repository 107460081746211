import React from 'react'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/styles'
import LinkField from '../LinkField'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  avatarContainer: {
    display: 'block'
  },
  bigAvatar: {
    margin: 10,
    width: 50,
    height: 50
  },
  name: {
    margin: 'auto 15px',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem'
  },
  iconBlank: {
    fontSize: 35,
    position: 'relative',
    top: 7,
    left: 7,
    color: 'gray'
  },
  gray: {
    color: theme.palette.gray.main
  }
}))

export default function AvatarColumn({ url, handleImpersonate, name, link, icon }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.avatarContainer}>
        {url ? (
          <Avatar alt="Profile Icon" src={url} className={classes.bigAvatar} />
        ) : (
          <Avatar alt="Profile Icon" className={classes.bigAvatar}>
            <Icon>{icon}</Icon>
          </Avatar>
        )}
      </div>
      <div className={classes.name}>
        <LinkField
          linkClasses={classes.gray}
          label={`${name}`}
          to="#"
          onClick={handleImpersonate}
          hideBorder={true}
        />
      </div>
    </div>
  )
}
