import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

export default function AnchorMenu({
  orgId,
  rowData,
  setRemoveOrgData,
  setRemoveOrgOpen,
  setDeleteUserData,
  setDeleteModalOpen
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (rowData.status !== 'registered' && (rowData.orgRoles && rowData.orgRoles.length !== 0)) {
    return null
  }

  return (
    <>
      <IconButton aria-label="admin options" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {rowData.status === 'registered' && (
          <MenuItem
            onClick={() => {
              setDeleteUserData({
                userId: rowData.id
              })
              setDeleteModalOpen(true)
              handleClose()
            }}
          >
            <Typography>Delete User</Typography>
          </MenuItem>
        )}
        {rowData.orgId &&
        rowData.orgRoles &&
        rowData.orgRoles.length === 0 &&
        rowData.status !== 'registered' ? (
          <MenuItem
            onClick={() => {
              setRemoveOrgData({
                orgId: orgId || rowData.orgId,
                userId: rowData.id
              })
              setRemoveOrgOpen(true)
              handleClose()
            }}
          >
            <Typography>Disconnect From Organization</Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}
