import React, { Fragment, useEffect } from 'react'
import { Divider, Grid } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'
import equal from 'fast-deep-equal'
import clsx from 'clsx'

import { ErrorSection, InputTextFieldNew, Progress, RoundedButton } from '../../../common'
import userFormFields from '../FormFields'
import { isFormSubmitDisabled, errorMessages } from '../../../utils'
import { useMutation } from '../../../hooks'
import { EDIT_USER } from '../mutations'
import { tabStyles } from '../UserInfo'

function UserProfile({
  activeTab,
  isReadOnly = false,
  onUpdate,
  setHasUnsavedChanges,
  tabLoopStart,
  tabLoopEnd,
  tabLoopSubmit,
  user = {}
}) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = tabStyles()

  const userProfile = {
    firstname: user.firstname || '',
    lastname: user.lastname || '',
    jobTitle: user.jobTitle || '',
    address1: user.shippingAddress.address1 || '',
    address2: user.shippingAddress.address2 || '',
    country: user.shippingAddress.country || '',
    postalCode: user.shippingAddress.postalCode || '',
    state: user.shippingAddress.state || '',
    city: user.shippingAddress.city || '',
    phoneNumber: user.phoneNumber || ''
  }

  const [formState, inputTypes] = useFormState(userProfile)

  const { loading, execute: handleSubmit, error } = useMutation(EDIT_USER, {
    onCompleted: ({ editUser }) => {
      if (editUser && editUser.id) {
        enqueueSnackbar('Changes Saved', {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    },
    update: onUpdate
  })

  const hasUnsavedChanges = !equal(formState.values, userProfile)

  useEffect(() => {
    if (!activeTab && hasUnsavedChanges) {
      Object.entries(userProfile).forEach(o => formState.setField(o[0], o[1]))
    }
  }, [activeTab, formState, hasUnsavedChanges, userProfile])

  useEffect(() => {
    if (activeTab) {
      setHasUnsavedChanges(hasUnsavedChanges)
    }
  }, [activeTab, hasUnsavedChanges, setHasUnsavedChanges])

  const disableSubmit = isFormSubmitDisabled(userFormFields(), formState, true)

  return (
    <form
      onSubmit={e => {
        e.preventDefault()

        handleSubmit({
          id: user.id,
          values: {
            firstname: formState.values.firstname,
            lastname: formState.values.lastname,
            jobTitle: formState.values.jobTitle,
            phoneNumber: formState.values.phoneNumber,
            shippingAddress: {
              address1: formState.values.address1,
              address2: formState.values.address2,
              country: formState.values.country,
              postalCode: formState.values.postalCode,
              state: formState.values.state,
              city: formState.values.city
            }
          }
        })
      }}
    >
      <Grid container className={clsx({ [classes.readOnly]: isReadOnly })} spacing={4}>
        {Object.entries(userFormFields(formState, tabLoopStart, tabLoopEnd)).map(
          ([name, { gridWidth = { xs: 12 }, InputComponent, inputType, ...args }]) => (
            <Fragment key={`${name}-divider`}>
              <Grid item {...gridWidth}>
                <InputComponent
                  {...args}
                  disabled={isReadOnly}
                  error={
                    typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                  }
                  errorHelperText={formState.errors[name]}
                  inputType={inputTypes[inputType]}
                  margin="none"
                  value={formState.values[name]}
                />
              </Grid>
              {name === 'jobTitle' && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </Fragment>
          )
        )}
        <Grid item xs={12}>
          <InputTextFieldNew
            disabled
            label="Organization Name"
            margin="none"
            value={user.organizationName || ''}
          />
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12} sm={5}>
            <RoundedButton
              fullWidth
              color="primary"
              className={classes.roundedButton}
              disabled={!hasUnsavedChanges || loading || disableSubmit}
              ref={tabLoopSubmit}
              type="submit"
            >
              {loading ? <Progress size={25} delay={0} /> : 'Update Info'}
            </RoundedButton>
            {error && <ErrorSection>{errorMessages.TIPSupport}</ErrorSection>}
          </Grid>
        )}
      </Grid>
    </form>
  )
}

export default UserProfile
