import gql from 'graphql-tag'

export const GET_USER_DATA = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      firstname
      lastname
      jobTitle
      roles {
        roleId
      }
      signUpStep
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      g5AlertConfirmed
    }
  }
`
