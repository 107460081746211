import React from 'react'
import { useSnackbar } from 'notistack'
import { Grid } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import DatePicker from '../../common/DatePicker'
import ClearIcon from '@material-ui/icons/Clear'

import {
  CheckboxDropdown,
  CheckboxField,
  InputTextFieldNew,
  Progress,
  RoundedButton
} from '../../common'
import { useQuery } from '../../hooks'
import { GET_ACTIVE_PROJECT_GROUPS } from './queries'

const ManageOrganizationsFilterFields = ({
  getOrgData = () => null,
  getOrgDataLoading = false,
  orgStatusLookup = {},
  participationTierLookup = {}
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [formState, { checkbox, text }] = useFormState({
    activatedProjectGroups: [],
    approvalStatus: [],
    billingExempt: false,
    name: '',
    participationTier: [],
    renewalDateStart: null,
    renewalDateEnd: null
  })

  const { data: { projectGroups: activePgs = [] } = {}, loading: activePgsLoading } = useQuery(
    GET_ACTIVE_PROJECT_GROUPS
  )

  function validateDate(formField, val) {
    if (formField === 'renewalDateStart') {
      if (!formState.values.renewalDateEnd) {
        return true
      } else {
        if (val > formState.values.renewalDateEnd) {
          enqueueSnackbar(`Start date must be before end date.`, {
            variant: 'success',
            autoHideDuration: 3000
          })
          return false
        } else {
          return true
        }
      }
    } else {
      if (!formState.values.renewalDateStart) {
        return true
      } else {
        if (val < formState.values.renewalDateStart) {
          enqueueSnackbar(`Start date must be before end date.`, {
            variant: 'warning',
            autoHideDuration: 3000
          })
          return false
        } else {
          return true
        }
      }
    }
  }

  return (
    <Grid
      container
      component="form"
      spacing={2}
      onSubmit={e => {
        e.preventDefault()

        getOrgData({
          variables: {
            input: {
              activatedProjectGroups: formState.values.activatedProjectGroups.map(
                pgName => activePgs.find(a => a.name === pgName).id
              ),
              approvalStatus: formState.values.approvalStatus,
              billingExempt: formState.values.billingExempt,
              name: formState.values.name,
              participationTier: formState.values.participationTier,
              renewalDateStart: formState.values.renewalDateStart,
              renewalDateEnd: formState.values.renewalDateEnd
            }
          }
        })

        return false
      }}
    >
      <Grid item xs={12} md={8}>
        <InputTextFieldNew
          autoComplete="new-password" // don't want email autofill
          inputType={text}
          label="Name"
          margin="none"
          name="name"
          value={formState.values.name}
        />
      </Grid>
      <Grid container item xs={12} md={4}>
        <Grid item xs={10}>
          <DatePicker
            formField="renewalDateStart"
            validateDate={validateDate}
            handleDateChange={formState.setField}
            label="Renewal Date Start"
            selectedDate={formState.values.renewalDateStart}
          />
          <DatePicker
            formField="renewalDateEnd"
            validateDate={validateDate}
            handleDateChange={formState.setField}
            label="Renewal Date End"
            selectedDate={formState.values.renewalDateEnd}
          />
        </Grid>
        <Grid container item alignItems="center" justify="center" xs={2}>
          <Grid item>
            <ClearIcon
              onClick={() => {
                formState.setField('renewalDateStart', null)
                formState.setField('renewalDateEnd', null)
              }}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <CheckboxDropdown
          inputType={checkbox}
          label="Participation Tier"
          margin="none"
          name="participationTier"
          options={Object.entries(participationTierLookup)
            .map(value => value[1])
            .sort()}
          setField={formState.setField}
          setFieldError={formState.setFieldError}
          value={formState.values.participationTier}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CheckboxDropdown
          inputType={checkbox}
          label="Approval Status"
          margin="none"
          name="approvalStatus"
          options={Object.entries(orgStatusLookup)
            .map(value => value[1])
            .sort()}
          setField={formState.setField}
          setFieldError={formState.setFieldError}
          value={formState.values.approvalStatus}
        />
      </Grid>
      <Grid container item alignItems="center" xs={12} md={4}>
        <Grid item>
          <CheckboxField
            inputType={checkbox}
            label="Billing Exempt Orgs Only"
            labelPlacement="end"
            name="billingExempt"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        {activePgsLoading ? (
          <Progress />
        ) : (
          <CheckboxDropdown
            inputType={checkbox}
            label="Activated Project Groups"
            margin="none"
            name="activatedProjectGroups"
            options={activePgs.map(pg => pg.name)}
            setField={formState.setField}
            setFieldError={formState.setFieldError}
            value={formState.values.activatedProjectGroups}
          />
        )}
      </Grid>
      <Grid container alignItems="center" direction="row" item xs={12}>
        <RoundedButton
          disabled={
            getOrgDataLoading ||
            Object.entries(formState.values).every(([key, value]) =>
              Array.isArray(value) ? value.length === 0 : !value
            )
          }
          fullWidth={false}
          type="submit"
        >
          {getOrgDataLoading ? <Progress /> : 'Search'}
        </RoundedButton>
      </Grid>
    </Grid>
  )
}

export default ManageOrganizationsFilterFields
