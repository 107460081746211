import gql from 'graphql-tag'

export const SYS_ADMIN_GET_MEMBERS = gql`
  query getMembers($input: MemberSearchInput) {
    systemAdminSearchUsers(input: $input) {
      id
      email
      firstname
      lastname
      logo
      status
      orgId
      orgRoles
    }
  }
`

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    users {
      id
      email
      firstname
      lastname
      #status
      #projectGroups
      projectGroupCount
      roles {
        roleId
      }
      logo
      organizations {
        organizationId
        name
        status
      }
    }
  }
`

export const GET_APPROVED_MEMBERS_NOT_IN_PROJECT_GROUP = gql`
  query($input: approvedMembersNotInProjectGroupInput) {
    getApprovedMembersNotInProjectGroup(input: $input) {
      id
      email
      firstname
      lastname
      logo
    }
  }
`

export const GET_MEMBER_TABLE_ORGANIZATION_DATA = gql`
  query($id: ID!) {
    organization(id: $id) {
      id
      name
      users {
        id
        email
        firstname
        lastname
        status
        percentComplete
        projectGroupCount
        logo
      }
      invitations {
        email
        firstName
        lastName
        userId
        organizationId
        status
        sentDate
      }
      organizationRoles {
        organizationAdmin {
          id
        }
        exchangeListingAdmin {
          id
        }
        primaryContact {
          id
        }
        authorizedSigners {
          user {
            id
          }
          email
        }
      }
    }
  }
`
