import React, { cloneElement, useRef } from 'react'

const TabLoop = ({ children }) => {
  const tabLoopStart = useRef(null)
  const tabLoopEnd = useRef(null)
  const tabLoopSubmit = useRef(null)

  return (
    <>
      <div
        tabIndex="0"
        onFocus={() => {
          const endInput = tabLoopEnd.current && (tabLoopEnd.current.inputRef || tabLoopEnd.current)
          const submitInput =
            tabLoopSubmit.current && (tabLoopSubmit.current.inputRef || tabLoopSubmit.current)

          if (submitInput && !submitInput.disabled) {
            submitInput.focus()
          } else if (endInput) {
            endInput.focus()
          }
        }}
      />
      {cloneElement(children, { tabLoopStart, tabLoopEnd, tabLoopSubmit })}
      <div
        tabIndex="0"
        onFocus={() => {
          const startInput =
            tabLoopStart.current && (tabLoopStart.current.inputRef || tabLoopStart.current)

          if (startInput) {
            startInput.focus()
          }
        }}
      />
    </>
  )
}

export default TabLoop
