import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export default function RequestPendingModal({
  isOpen,
  onClose,
  restricted,
  setRequestPendingRestricted,
  setRequestPendingSupportMustVerify,
  supportMustVerify,
  type
}) {
  const [open, setOpen] = React.useState(isOpen)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    if (setRequestPendingRestricted) {
      setRequestPendingRestricted(false)
      setRequestPendingSupportMustVerify(false)
    }
    setOpen(false)
    onClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Your request is pending
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <div>
            <Typography className={classes.subTitleText}>
              {supportMustVerify && !restricted && type !== 'requestParticipation'
                ? 'O-RAN Alliance participation verification'
                : type === 'requestParticipation'
                ? 'Group activation is required'
                : 'Participation approval is required'}
            </Typography>
            <Typography>
              {supportMustVerify && !restricted && type !== 'requestParticipation'
                ? "TIP will confirm your organization's participation in O-RAN Alliance."
                : type === 'requestParticipation'
                ? 'The group you wish to join has not been activated for participation yet. An activation request has been sent to your Account Administrator. Once the group is activated, you will automatically join and receive a welcome email with more information.'
                : 'The group you wish to join requires approval from your Account Administrator. A participation request has been sent.'}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} className={classes.button} classes={{ root: classes.root }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  subTitleText: {
    fontWeight: 600
  }
}))
