import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import BlockIcon from '@material-ui/icons/Block'
import { makeStyles } from '@material-ui/styles'

export default function AccountMembershipRequestsActions({ executeApprove, setOpen, userId }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton aria-label="admin options" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="org-verification-request-options"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            executeApprove(userId)
            handleClose()
          }}
        >
          <PersonAddIcon className={classes.icon} />
          <Typography className={classes.option}>Add person to account</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpen(true)
            handleClose()
          }}
        >
          <BlockIcon className={classes.icon} />
          <Typography className={classes.option}>Decline</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export const useStyles = makeStyles(theme => ({
  option: {
    fontSize: '.9rem',
    color: theme.palette.gray8.main
  },
  icon: {
    marginRight: 10,
    color: theme.palette.gray8.main
  }
}))
