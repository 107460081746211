import gql from 'graphql-tag'

export const EDIT_USER = gql`
  mutation($id: ID!, $input: UserUpdateInput!) {
    editUser(id: $id, input: $input) {
      id
      firstname
      lastname
      jobTitle
      phoneNumber
      email
      logo
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      percentageComplete
    }
  }
`

export const ATLASSIAN_LINK_ACCOUNT = gql`
  mutation($id: ID!) {
    atlassianProvisionAccount(id: $id) {
      result
      data
    }
  }
`

export const GITHUB_UNLINK_ACCOUNT = gql`
  mutation($id: ID!) {
    unlinkGithubAccount(id: $id) {
      result
      data
    }
  }
`

export const GITHUB_LINK_ACCOUNT = gql`
  mutation($id: ID!, $input: GithubAccountLinkInput!) {
    linkGithubAccount(id: $id, input: $input) {
      result
      data
    }
  }
`

export const JOIN_SLACK_CHANNELS = gql`
  mutation($id: ID!) {
    joinSlackChannels(id: $id) {
      result
      data
    }
  }
`

export const REFRESH_SLACK_CHANNELS = gql`
  mutation($id: ID!) {
    refreshSlackChannels(id: $id) {
      result
      data
    }
  }
`

export const LEAVE_SLACK_CHANNELS = gql`
  mutation($id: ID!) {
    leaveSlackChannels(id: $id) {
      result
      data
    }
  }
`

export const CHANGE_USER_PASSWORD = gql`
  mutation($id: ID!, $input: UserChangePasswordInput!) {
    changePassword(id: $id, input: $input) {
      result
      data
    }
  }
`

export const CHANGE_USER_EMAIL = gql`
  mutation($id: ID!, $input: UserChangeEmailInput!) {
    sendChangedEmailAddressEmails(id: $id, input: $input) {
      result
      data
    }
  }
`
