import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0
  },
  input: {
    display: 'flex',
    height: 'auto',
    '& div': {
      padding: 0
    }
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  }
})

const DropDownField = ({
  inputType = () => null,
  name,
  label,
  handleChange,
  options = [],
  required,
  value = '',
  isClearable = false,
  menuPlacement = 'bottom'
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const selectStyles = {
    // Fixes the overlapping problem of the component
    menu: provided => ({ ...provided, zIndex: 5 }),
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  }

  function onChange(e) {
    // to prevent dropdown components from breaking where used because they are expecintg an object target
    handleChange({
      target: {
        value: e ? e.value : null
      }
    })
  }

  const optionsObject = options.map(val => ({
    value: val,
    label: val
  }))

  let selectedValue = null
  if (value) {
    selectedValue = optionsObject.find(c => c.value === value)
  }

  // TODO - REFACTOR WITH MUI SELECT
  return (
    <Select
      {...inputType(name)}
      classes={classes}
      styles={selectStyles}
      TextFieldProps={{
        label: label,
        InputLabelProps: {
          shrink: true
        },
        variant: 'outlined'
      }}
      options={optionsObject}
      components={customComponents}
      value={selectedValue}
      onChange={onChange}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
    />
  )
}

const customComponents = {
  Control,
  Option
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      <div>
        <Typography>{props.label}</Typography>
      </div>
    </MenuItem>
  )
}

export default DropDownField
