export const isFormSubmitDisabled = (inputs, formState, isEditing) => {
  const requiredFields = Object.entries(inputs).flatMap(([name, args]) => {
    if (args.validator && args.validator.required) {
      return [name]
    }

    return []
  })

  const touchedRequiredFields = requiredFields.every(item => formState.touched[item])

  const requiredFieldsHaveValue = requiredFields.every(item => {
    const temp = Array.isArray(formState.values[item])
      ? formState.values[item].length > 0
      : formState.values[item]

    return temp
  })

  const errors = Object.keys(formState.errors).some(
    e => formState.errors[e] && Object.keys(inputs).includes(e) // note: both true and undefined represent a valid input
  )

  const disableSubmit = isEditing
    ? !requiredFieldsHaveValue || errors
    : !touchedRequiredFields || errors

  return disableSubmit
}

export const inputRegexes = {
  phone: /^[ ()-+?]*([0-9][ =()-=+]*){1,15}$/,
  email: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}/,
  domain: /^(?:http(s)?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})/,
  url: /https?:\/\/.+/,
  password: /(?=.*[A-Za-z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}/
}
