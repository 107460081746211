import gql from 'graphql-tag'

export const SYS_ADMIN_GET_MEMBERS = gql`
  query getMembers($input: MemberSearchInput) {
    systemAdminSearchUsers(input: $input) {
      id
      email
      firstname
      lastname
      logo
      jobTitle
      createdDate
      signUpStep
      status
      orgId
      orgRoles
      orgName
    }
  }
`

export const GET_MEMBER_TABLE_ORGANIZATION_DATA = gql`
  query($id: ID!) {
    organization(id: $id) {
      id
      name
      users {
        id
        email
        firstname
        jobTitle
        lastname
        status
        percentComplete
        projectGroupCount
        logo
        joinDate
      }
      invitations {
        email
        firstName
        lastName
        userId
        organizationId
        status
        sentDate
      }
      organizationRoles {
        organizationAdmin {
          id
        }
        exchangeListingAdmin {
          id
        }
        primaryContact {
          id
        }
        billingContact {
          user {
            id
            email
          }
          email
        }
        legalDesignee {
          user {
            id
            email
          }
          email
        }
        authorizedSigners {
          user {
            id
          }
          email
        }
      }
    }
  }
`

export const GET_ALL_ORGANIZATION_DATA = gql`
  query {
    organizations {
      name
      id
    }
  }
`

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    users {
      id
      email
      firstname
      lastname
      #status
      #projectGroups
      projectGroupCount
      roles {
        roleId
      }
      logo
      organizations {
        organizationId
        name
        status
      }
    }
  }
`
