import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { useMutation } from '../hooks'
import { GET_USER_DATA } from '../Home/queries'
import { EDIT_USER_PROFILE } from './mutations'
import RoundedButton from '../common/RoundedButton'

export default function WelcomeModal({
  userId,
  userRoles,
  orgId,
  isOrgAdmin,
  isAuthorizedSigner,
  isOpen,
  setOpen
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const { execute: updateUser } = useMutation(EDIT_USER_PROFILE, {
    refetchQueries: [{ query: GET_USER_DATA, variables: { id: userId } }]
  })

  function handleClose() {
    setOpen(false)

    updateUser({
      id: userId,
      values: { signUpStep: null }
    })
  }

  const actionConfig = {
    registeredUser: [
      {
        title: 'Join project groups',
        message:
          'Be sure to select and join at least one project group to participate in. You must take this step in order to gain access to project group meetings, tools, and resources.',
        buttonText: 'See Project Groups',
        buttonLink: `/user/${userId}/project-groups/${orgId}`
      }
    ],
    orgAdmin: [
      {
        title: 'Enable project groups for participation',
        message:
          'As Account Administrator, be sure to enable at least one project group for participation. For each project group, you will need to send a charter agreement to your Authorized Representative for signature.',
        buttonText: 'See Project Groups',
        buttonLink: `/organization/${orgId}/project-groups`
      },
      {
        title: 'Invite individuals',
        message:
          'Send individual invitations to help other employees join your organization’s TIP Participation account.',
        buttonText: 'Get started',
        buttonLink: `/organization/${orgId}/manage-invites`
      }
    ],
    orgAdminAndAuth: [
      {
        title: 'Enable project groups for participation',
        message:
          'As Account Administrator, be sure to enable at least one project group for participation. You are also the Authorized Representative, so you will need to sign the charter agreement for each project group of interest.',
        buttonText: 'See Project Groups',
        buttonLink: `/organization/${orgId}/project-groups`
      },
      {
        title: 'Invite individuals',
        message:
          'Send individual invitations to help other employees join your organization’s TIP Participation account.',
        buttonText: 'Get started',
        buttonLink: `/organization/${orgId}/manage-invites`
      }
    ]
  }

  let actionSet = ''

  if (isOrgAdmin) {
    if (isAuthorizedSigner) {
      actionSet = 'orgAdminAndAuth'
    } else {
      actionSet = 'orgAdmin'
    }
  } else {
    actionSet = 'registeredUser'
  }

  if (actionSet === '' && userRoles.find(r => r.roleId === '0')) {
    // rare case of users being super admins and not org admins
    actionSet = 'registeredUser'
  }

  let isMobile = false

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        <Typography className={classes.headerText}>Welcome to TIP</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <Typography>
            We look forward to collaborating with you. Please take a moment to review and take
            action on the following:
          </Typography>
        </div>
        <div className={classes.actionContainer}>
          {actionConfig[actionSet].map(a => (
            <div
              key={a.title}
              className={classes.actionBox}
              style={{ width: isMobile ? '100%' : actionConfig.length === 1 ? 500 : 250 }}
            >
              <Typography className={classes.actionTitle}>{a.title}</Typography>
              <Typography className={classes.actionText}>{a.message}</Typography>
              <RoundedButton
                className={classes.button}
                fullWidth={false}
                color="primary"
                component={Link}
                to={a.buttonLink}
              >
                {a.buttonText}
              </RoundedButton>
            </div>
          ))}
        </div>
        <Typography className={classes.closeText}>
          Skip this.{' '}
          <span className={classes.closeWindowLink} onClick={handleClose}>
            Close Window
          </span>
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 860,
    paddingBottom: 50
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    marginTop: 'auto'
  },
  buttonRoot: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  header: {
    paddingTop: 60,
    textAlign: 'center'
  },
  headerText: {
    fontSize: 34,
    fontWeight: 600
  },
  dialogContent: {
    padding: '0 100px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px'
    }
  },
  dialogContentText: {
    marginBottom: 25
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.actionBoxes.background,
    boxShadow: '0 0 5px #00000029',
    border: `1px solid ${theme.palette.gray7.main}`,
    textAlign: 'center',
    padding: 25,
    borderRadius: '6px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20
    }
  },
  actionTitle: {
    fontWeight: 600,
    marginBottom: 10
  },
  actionText: {
    fontSize: 14,
    marginBottom: 15
  },
  closeText: {
    textAlign: 'center',
    marginTop: 20
  },
  closeWindowLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
}))
