import React, { useState } from 'react'
import { makeStyles, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import PreApproveOrganizations from './PreApproveOrganizations'
import PreApprovedList from './PreApprovedList'
import RenewingOrgsList from './RenewingOrgsList'
import { useQuery } from '../../hooks'
import { GET_MEMBERSHIP_LEVELS } from '../Profile/queries'

export default function SysAdminManageBilling({ match, location }) {
  // const classes = useStyles()

  const { data, loading } = useQuery(GET_MEMBERSHIP_LEVELS)

  if (loading) {
    return null
  }

  const { membershipLevels } = data

  const getTierLabel = ({ membershipLevelId }) => {
    const result = {
      long: '',
      short: ''
    }

    if (membershipLevelId) {
      result.long = membershipLevels.find(m => m.id === membershipLevelId).name

      result.short = result.long.substring(0, result.long.indexOf(' '))

      if (result.short.length > 5) {
        result.short = `${result.short.substring(0, 5)}.`
      }
    }

    return result
  }

  return (
    <>
      <PreApproveOrganizations getTierLabel={getTierLabel} TooltipHelper={TooltipHelper} />
      <PreApprovedList
        getTierLabel={getTierLabel}
        location={location}
        TooltipHelper={TooltipHelper}
      />
      <RenewingOrgsList
        getTierLabel={getTierLabel}
        location={location}
        TooltipHelper={TooltipHelper}
      />
    </>
  )
}

const TooltipHelper = ({
  componentProps = {},
  content = '',
  TextComponent = Typography,
  tooltipTitle = ''
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <Tooltip arrow open={tooltipOpen} placement="bottom-start" title={tooltipTitle || content}>
      <div className={classes.columnOverflow}>
        <TextComponent
          onClick={isMobile ? () => setTooltipOpen(!tooltipOpen) : null}
          onMouseEnter={!isMobile ? () => setTooltipOpen(true) : null}
          onMouseLeave={!isMobile ? () => setTooltipOpen(false) : null}
          {...componentProps}
        >
          {content}
        </TextComponent>
      </div>
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  columnOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& *': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  container: {
    fontSize: 16
  }
}))
