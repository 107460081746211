import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { Redirect } from 'react-router-dom'

import { InputTextFieldNew, LinkField, Progress, RoundedButton } from '../common'
import { useMutation, useQuery, useFormStyles } from '../hooks'
import { RESET_PASSWORD } from './mutations'
import { VERIFY_TOKEN_RESET_PASSWORD } from './queries'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../utils'

export default function ResetPassword({ location = {} }) {
  const [formState, { password }] = useFormState({
    email: '',
    password: '',
    confirmPassword: '',
    token: ''
  })

  const { loading: verifyTokenLoading, error: verifyTokenError } = useQuery(
    VERIFY_TOKEN_RESET_PASSWORD,
    {
      onCompleted: ({ verifyResetPasswordToken = {} }) => {
        if (verifyResetPasswordToken.result) {
          formState.setField('email', verifyResetPasswordToken.email)
        }
      },
      skip: !formState.values.token,
      variables: {
        input: {
          token: formState.values.token
        }
      }
    }
  )

  const {
    loading: resetPasswordLoading,
    execute: resetPasswordExecute,
    data: resetPasswordData,
    error: resetPasswordError
  } = useMutation(RESET_PASSWORD)

  const classes = useFormStyles()

  useEffect(() => {
    if (formState.values.token === '') {
      formState.setField('token', new URLSearchParams(location.search).get('token'))
    }
  }, [formState, location])

  const inputs = {
    password: {
      autoFocus: true,
      name: 'password',
      label: 'New Password',
      inputType: password,
      validateOnBlur: false,
      validator: {
        required: true,
        regex: inputRegexes.password,
        regexMessage: 'Must be 8 characters long and have at least one letter, number, and symbol.'
      },
      touchOnChange: true,
      variant: 'outlined'
    },
    confirmPassword: {
      name: 'confirmPassword',
      label: 'Confirm Password',
      inputType: password,
      autoComplete: 'new-password',
      validateOnBlur: false,
      validator: {
        required: true,
        mustMatchKey: 'password',
        mustMatchMessage: 'Passwords must match'
      },
      helperText: 'Re-type your password.',
      variant: 'outlined'
    }
  }

  let errorMessage

  if (resetPasswordError) {
    console.error(resetPasswordError.graphQLErrors[0].message)
    errorMessage = errorMessages.TIPSupport
  }

  if (
    resetPasswordData &&
    resetPasswordData.resetPassword &&
    resetPasswordData.resetPassword.result
  ) {
    return <Redirect to="/login" /> // redirect to login after succesful reset
  }

  if (verifyTokenLoading) {
    return <Progress />
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  return (
    <form
      data-testid="ResetPasswordContainer"
      onSubmit={e => {
        e.preventDefault()
        resetPasswordExecute({
          values: formState.values
        })
      }}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={7} lg={6} className={classes.container}>
          <div className={classes.header}>
            <Typography role="heading" variant="h3" className={classes.heading}>
              Reset Password
            </Typography>
          </div>
          <div className={classes.alert}>
            {resetPasswordData && resetPasswordData.resetPassword.result && (
              <Typography role="alert" classes={classes.success}>
                {resetPasswordData.resetPassword.data}
              </Typography>
            )}
            {resetPasswordError && (
              <Typography role="alert" color="error">
                {errorMessage}
              </Typography>
            )}
          </div>
          {verifyTokenError && verifyTokenError.message ? (
            verifyTokenError.message.includes('token is expired') ? (
              <>
                <Typography role="alert" color="error">
                  Your password reset token has expired.
                </Typography>
                <div className={classes.buttonContainer}>
                  <LinkField
                    className={classes.linkStyle}
                    to={'/forgot-password'}
                    label="Forgot password?"
                  />
                </div>
              </>
            ) : (
              <Typography role="alert" color="error">
                {errorMessage}
              </Typography>
            )
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputTextFieldNew disabled label="Email" value={formState.values.email} />
                </Grid>
                {Object.entries(inputs).map(([name, { gridWidth = { xs: 12 }, ...rest }]) => (
                  <Grid key={name} item {...gridWidth}>
                    <InputTextFieldNew
                      key={name}
                      {...rest}
                      error={
                        typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                      }
                      errorHelperText={formState.errors[name]}
                      value={formState.values[name]}
                    />
                  </Grid>
                ))}
              </Grid>
              <div className={classes.buttonRightContainer}>
                {resetPasswordLoading ? (
                  <Progress size={30} delay={0} />
                ) : (
                  <RoundedButton
                    disabled={disableSubmit}
                    data-testid="SubmitBtn"
                    fullWidth={false}
                    type="submit"
                  >
                    Change Password
                  </RoundedButton>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
