import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import MembersTable, { useStyles } from './MembersTable'
import LinkField from '../../common/LinkField'
import RoundedButton from '../../common/RoundedButton'
import InfoBlock from './InfoBlock'
import Progress from '../../common/Progress'
import { GET_MEMBER_TABLE_ORGANIZATION_DATA } from './queries'
import { useSession } from '../../auth/queries'
import { useQuery } from '../../hooks'
import { history } from '../../store'

export default function OrgAdminManageMembers({ match, location }) {
  const orgId = match.params.id

  const parameters = { variables: { id: orgId } }
  const { data: { organization } = {}, loading: isOrgLoading, error: orgError } = useQuery(
    GET_MEMBER_TABLE_ORGANIZATION_DATA,
    parameters
  )

  const classes = useStyles()
  const orgUsers = organization ? organization.users : []
  const [filterOnPending = false, setFilterOnPending] = useState(false)

  const { isSystemAdmin, isImpersonating } = useSession()
  const canModify = !isImpersonating || (isImpersonating && isSystemAdmin)

  const editMemberUpdate = (cache, { data: result }) => {
    const { organization } = cache.readQuery({
      query: GET_MEMBER_TABLE_ORGANIZATION_DATA,
      variables: { id: orgId }
    })

    const { editUser, uploadFile } = result
    if (editUser) {
      const { firstname, lastname, id, logo, email } = editUser

      let updatedUserIndex = organization.users.findIndex(u => u.id === id)

      const newuser = {
        ...organization.users[updatedUserIndex],
        id,
        firstname,
        lastname,
        logo,
        email
      }

      let newUsers = [...organization.users]
      newUsers[updatedUserIndex] = { ...newuser }

      cache.writeQuery({
        query: GET_MEMBER_TABLE_ORGANIZATION_DATA,
        data: { organization: { ...organization, users: newUsers } }
      })
    } else if (uploadFile) {
      const { name, id } = result.uploadFile

      let updatedUserIndex = organization.users.findIndex(u => u.id === id)

      const newuser = {
        ...organization.users[updatedUserIndex],
        logo: name
      }

      let newUsers = [...organization.users]
      newUsers[updatedUserIndex] = { ...newuser }

      cache.writeQuery({
        query: GET_MEMBER_TABLE_ORGANIZATION_DATA,
        data: { organization: { ...organization, users: newUsers } }
      })
    }
  }

  if (isOrgLoading) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  if (orgError) {
    return (
      <Typography role="alert" color="error">
        Error: {orgError.message}
      </Typography>
    )
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography className={classes.header} variant="h4">
          Participants
        </Typography>

        {canModify && (
          <RoundedButton
            fullWidth={false}
            color="primary"
            component={Link}
            to={`/organization/${orgId}/manage-invites`}
          >
            Invite Participants
          </RoundedButton>
        )}
      </div>
      <Grid justify="center" container spacing={5} direction="row" alignItems="stretch">
        <Grid className={classes.stats} justify="space-between" spacing={4} container>
          <Grid item xs={12} sm={12} md={3}>
            <InfoBlock label="Total participants" number={orgUsers.length} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <InfoBlock
              onClick={event => {
                setFilterOnPending(true)
              }}
              label={
                <LinkField
                  label="Total pending"
                  to={`#`}
                  onClick={event => {
                    setFilterOnPending(true)
                  }}
                />
              }
              number={orgUsers.filter(i => i.status === 'requested').length}
            >
              Things
            </InfoBlock>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <InfoBlock
              onClick={event => {
                history.push(`/organization/${orgId}/manage-invites`)
              }}
              label={
                <LinkField
                  label="Total invited"
                  to={`/organization/${orgId}/manage-invites`}
                  onClick={event => {
                    setFilterOnPending(!filterOnPending)
                  }}
                />
              }
              number={organization.invitations.filter(i => i.status === 'invited').length}
            />
          </Grid>
        </Grid>
        <MembersTable
          data={orgUsers}
          isSystemAdmin={isSystemAdmin && !isImpersonating}
          orgId={orgId}
          orgRoles={organization.organizationRoles}
          onUpdate={editMemberUpdate}
          returnUrl={location.pathname}
          filterPending={filterOnPending}
          isReadOnly={!canModify}
        />
      </Grid>
    </>
  )
}
