import React from 'react'

import Grid from '@material-ui/core/Grid'

import ParticipationAgreement from './ParticipationAgreement'
import { ProjectGroupCharters, SubGroupAgreements } from './ProjectGroupAgreements'
import { useSession } from '../../auth/queries'

export default function Agreements({ match, location }) {
  const { isImpersonating, isSystemAdmin } = useSession()
  const canModify = !isImpersonating || (isImpersonating && isSystemAdmin)

  return (
    <Grid container>
      <Grid item xs={12}>
        <ParticipationAgreement
          organizationId={match.params.id}
          isReadOnly={!canModify}
          match={match}
          location={location}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ProjectGroupCharters organizationId={match.params.id} isReadOnly={!canModify} />
        <SubGroupAgreements organizationId={match.params.id} isReadOnly={!canModify} />
      </Grid>
    </Grid>
  )
}
