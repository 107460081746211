import React, { useState } from 'react'
import { FormHelperText, Grid, Typography } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import draftToHtml from 'draftjs-to-html'

import TIPDialog from '../../common/TIPDialog'
import {
  CheckboxDropdown,
  CheckboxField,
  ErrorSection,
  InputFileField,
  InputImageField,
  InputTextFieldNew,
  MultiSelectDropDownField,
  Progress,
  RoundedButton,
  OrganizationAutocomplete,
  TIPChip
} from '../../common'
import { dropdownElevation, DropdownElevationContext } from '../../common/CheckboxDropdown'
import LogoInput from '../../Logo'
import OfferingDescriptionRichText from './OfferingDescriptionRichText'
import { isFormSubmitDisabled, errorMessages, inputRegexes } from '../../utils'
import {
  useOfferingBadges,
  useOfferingBadgeRecords,
  useOfferingCategories,
  useOfferingTags,
  useOfferingTypes
} from '../../utils/offeringHelper'
import { useProjectGroups } from '../../utils/projectGroupHelper'
import { GET_OFFERING_DATA } from './queries'
import { SAVE_OFFERING } from './mutations'
import { GET_ORG } from '../Profile/queries'
import { useMutation, useQuery } from '../../hooks'

const EditOfferingForm = ({ offeringId, org = {}, children, isReadOnly, showAllOrgs }) => {
  const [open, setOpen] = useState(false)
  const { badges, loading: badgesLoading } = useOfferingBadges()
  const { badgeRecords, loading: badgeRecordsLoading } = useOfferingBadgeRecords()
  const { tags, loading: tagsLoading } = useOfferingTags()
  const { projectGroups, loading: projectGroupsLoading } = useProjectGroups()
  const { categories, loading: categoriesLoading } = useOfferingCategories()
  const { types, loading: typesLoading } = useOfferingTypes()
  const [errorMessage, setErrorMessage] = useState()

  const defaultFormState = {
    name: '',
    orgDescription: '',
    orgLogo: '',
    showOnMarketplace: false,
    searchTags: [],
    previewOnMarketplace: false,
    ocpCertified: false,
    shortDescription: '',
    longDescription: '',
    githubLink: '',
    technicalSpecPdf: {
      file: null,
      removed: false
    },
    primaryImage: {
      file: null,
      removed: false
    },
    image2: {
      file: null,
      removed: false
    },
    image3: {
      file: null,
      removed: false
    },
    image4: {
      file: null,
      removed: false
    },
    image5: {
      file: null,
      removed: false
    },
    videoUrl01: '',
    videoUrl02: '',
    videoUrl03: '',
    videoUrl04: '',
    videoUrl05: '',
    type: [],
    badges: [],
    badgeRecords: [],
    tags: [],
    categories: [],
    projectGroups: []
  }

  if (showAllOrgs) {
    defaultFormState.organization = {
      id: '',
      label: ''
    }
  } else {
    defaultFormState.orgName = org.name || ''
  }

  const [formState, { text, checkbox, selectMultiple }] = useFormState(defaultFormState)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { organization: selectedOrganization = {} } = {},
    loading: loadingSelectedOrg
  } = useQuery(GET_ORG, {
    variables: {
      id: formState.values.organization ? formState.values.organization.id : org.id
    },
    skip: !(formState.values.organization ? formState.values.organization.id : org.id) || !open,
    onCompleted: ({ organization } = {}) => {
      if (showAllOrgs) {
        formState.setField('organization', { id: organization.id, label: organization.name })
      } else {
        formState.setField('orgName', organization.name)
      }

      formState.setField('orgDesc', organization.description)
      formState.setField('orgLogo', organization.logo)
    }
  })

  const { data: { offering = {} } = {}, loading: loadingOffering } = useQuery(GET_OFFERING_DATA, {
    variables: {
      id: offeringId
    },
    skip: !offeringId || !open,
    fetchPolicy: 'network-only',
    onCompleted: ({ offering } = {}) => {
      formState.setField('name', offering.name)
      if (showAllOrgs) {
        formState.setField('organization', {
          id: offering.organization.id || '',
          label: offering.organization.name || ''
        })
      } else {
        formState.setField('orgName', offering.organization.name || '')
      }
      formState.setField('orgDesc', offering.organization.description || '')
      formState.setField('orgLogo', offering.organization.logo || '')
      formState.setField('shortDescription', offering.shortDescription || '')
      formState.setField('longDescription', offering.longDescription || '')
      formState.setField('shortDescriptionHTML', offering.shortDescription || '')
      formState.setField('longDescriptionHTML', offering.longDescription || '')
      formState.setField('type', offering.type || [])
      formState.setField('showOnMarketplace', offering.showOnMarketplace || false)
      formState.setField('searchTags', offering.searchTags || [])
      formState.setField('previewOnMarketplace', offering.previewOnMarketplace || false)
      formState.setField('ocpCertified', offering.ocpCertified || false)
      formState.setField('githubLink', offering.githubLink || '')
      formState.setField('videoUrl01', offering.videoUrls[0] || '')
      formState.setField('videoUrl02', offering.videoUrls[1] || '')
      formState.setField('videoUrl03', offering.videoUrls[2] || '')
      formState.setField('videoUrl04', offering.videoUrls[3] || '')
      formState.setField('videoUrl05', offering.videoUrls[4] || '')
      formState.setField('projectGroups', offering.projectGroups.map(a => a.id))
      formState.setField('categories', offering.categories.map(a => a.id))
      formState.setField('badges', offering.badges.map(a => a.id))
      formState.setField('badgeRecords', offering.badgeRecords.map(a => a.id))
      formState.setField('tags', offering.tags.map(a => a.id))
    }
  })

  const { execute: saveOffering, loading } = useMutation(SAVE_OFFERING, {
    onCompleted: () => {
      enqueueSnackbar('Offering Saved', {
        variant: 'success',
        autoHideDuration: 4500
      })

      setOpen(false)
    },
    onError: error => {
      if (
        error.message.includes('Offering name already exists') ||
        error.message.includes('duplicate key error')
      ) {
        setErrorMessage('Offering name already exists')
        formState.setFieldError('name', 'Offering name already exists')
      } else {
        setErrorMessage(errorMessages.TIPSupport)
      }
    }
  })

  function handleClickOpen() {
    Object.entries(defaultFormState).forEach(([name, value]) => {
      formState.setField(name, value)
    })
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleSubmit() {
    // TODO manipulate images/formstate to be an array of files

    const reg = /(<ul>\\n)|(\\n<\/ul>)/g
    const replaceFunction = value => value.replace(/\\n/g, '')

    const shortDescriptionHTML = JSON.stringify(formState.values.shortDescriptionHTML).replace(
      reg,
      replaceFunction
    )
    const longDescriptionHTML = JSON.stringify(formState.values.longDescriptionHTML).replace(
      reg,
      replaceFunction
    )

    let sendObj = {
      orgId: selectedOrganization.id,
      name: formState.values.name,
      organization: {
        id: selectedOrganization.id || org.id,
        description: formState.values.orgDesc
        // orgLogo is updated via its own component
      },
      shortDescription: JSON.parse(shortDescriptionHTML),
      longDescription: JSON.parse(longDescriptionHTML),
      showOnMarketplace: formState.values.showOnMarketplace,
      searchTags: formState.values.searchTags,
      previewOnMarketplace: formState.values.previewOnMarketplace,
      ocpCertified: formState.values.ocpCertified,
      githubLink: formState.values.githubLink,
      type: formState.values.type,
      badges: formState.values.badges,
      badgeRecords: formState.values.badgeRecords,
      tags: formState.values.tags,
      categories: formState.values.categories,
      projectGroups: formState.values.projectGroups,
      technicalSpecPdf: formState.values.technicalSpecPdf,
      images: ['primaryImage', 'image2', 'image3', 'image4', 'image5'].map((name, index) => ({
        ...formState.values[name]
      })),
      videoUrls: ['videoUrl01', 'videoUrl02', 'videoUrl03', 'videoUrl04', 'videoUrl05']
        .filter(name => {
          if (formState.values[name] !== '') {
            return true
          }
          return false
        })
        .map(name => (formState.values[name] !== '' ? formState.values[name] : null))
    }

    if (offeringId) {
      sendObj.offeringId = offeringId
    }

    saveOffering({ values: sendObj })
  }

  if (
    tagsLoading ||
    badgesLoading ||
    badgeRecordsLoading ||
    projectGroupsLoading ||
    categoriesLoading ||
    typesLoading ||
    loadingOffering
  ) {
    return null
  }

  let companyOfferingFields = {
    name: {
      autoFocus: true,
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Offering Name*',
      name: 'name',
      validator: {
        required: true,
        minLength: 2,
        maxLength: 100
      }
    },
    organization: {
      allowNewOrgs: false,
      disabled: loadingSelectedOrg,
      hidden: !showAllOrgs,
      InputComponent: OrganizationAutocomplete,
      inputType: text,
      label: 'Organization Name*',
      name: 'organization',
      validator: {
        required: showAllOrgs
      },
      setField: formState.setField
    },
    orgName: {
      hidden: showAllOrgs,
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Organization Name*',
      name: 'orgName',
      validator: {
        required: !showAllOrgs
      },
      disabled: !showAllOrgs
    },
    orgLogo: {
      disabled: loadingSelectedOrg,
      InputComponent: LogoInput,
      label: 'Logo',
      name: 'orgLogo',
      changeText: 'Change Logo',
      removeText: 'Remove Logo',
      onUpdate: null,
      logo: formState.values.orgLogo,
      type: 'organization',
      parentId: formState.values.organization
        ? formState.values.organization.id
        : selectedOrganization.id,
      avatarClass: null,
      isReadOnly: false
    },
    orgDesc: {
      disabled: loadingSelectedOrg,
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Organization Description',
      name: 'orgDesc',
      multiline: true,
      rowsMax: 6,
      validator: {
        maxLength: 1000
      }
    },
    showOnMarketplace: {
      InputComponent: CheckboxField,
      inputType: checkbox,
      label: 'Show on marketplace',
      labelPlacement: 'end',
      name: 'showOnMarketplace',
      validator: {}
    },
    searchTags: {
      InputComponent: TIPChip,
      // label: 'Specify Marketplace search tags, separated by commas:',
      name: 'searchTags',
      data: formState.values.searchTags || [],
      showAutoCompleteInput: true,
      autoCompleteOptions: {
        options:
          formState.values.searchTags && Array.isArray(formState.values.searchTags)
            ? formState.values.searchTags.map(tag => ({ title: tag }))
            : [],
        label: 'Specify Marketplace search tags'
      },
      onDelete: data => {
        formState.setField('searchTags', formState.values.searchTags.filter(tag => tag !== data))
      },
      onAdd: data => {
        formState.setField('searchTags', [
          ...formState.values.searchTags,
          data.trim().toLowerCase()
        ])
      },
      validator: {}
    },
    previewOnMarketplace: {
      InputComponent: CheckboxField,
      inputType: checkbox,
      label: 'Preview on marketplace (show on marketplace overrides this)',
      labelPlacement: 'end',
      name: 'previewOnMarketplace',
      validator: {}
    },
    ocpCertified: {
      InputComponent: CheckboxField,
      inputType: checkbox,
      label: 'OCP Certified',
      labelPlacement: 'end',
      name: 'ocpCertified',
      validator: {}
    },
    shortDescription: {
      InputComponent: OfferingDescriptionRichText,
      inputType: text,
      label: 'Short Description*',
      name: 'shortDescription',
      // set form field to plain text with spaces removed for validation purposes, passing converted html to server for rich text
      handleChange: (plainText, converted) => {
        formState.setField('shortDescription', plainText)

        if (plainText) {
          formState.setField('shortDescriptionHTML', draftToHtml(converted))
        } else {
          formState.setField('shortDescriptionHTML', '')
          formState.setFieldError('shortDescription', 'Short Description is required')
        }
      },
      validator: {
        required: true,
        maxLength: 1000
      }
    },
    longDescription: {
      InputComponent: OfferingDescriptionRichText,
      inputType: text,
      label: 'Long Description*',
      name: 'longDescription',
      // set form field to plain text with spaces removed for validation purposes, passing converted html to server for rich text
      handleChange: (plainText, converted) => {
        formState.setField('longDescription', plainText)

        if (plainText) {
          formState.setField('longDescriptionHTML', draftToHtml(converted))
        } else {
          formState.setField('longDescriptionHTML', '')
          formState.setFieldError('longDescription', 'Short Description is required')
        }
      },
      validator: {
        required: true,
        maxLength: 5000
      }
    },
    githubLink: {
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Github Link',
      name: 'githubLink',
      validator: {}
    },
    technicalSpecPdf: {
      acceptTypes: 'application/pdf',
      InputComponent: InputFileField,
      label: 'Technical Spec PDF',
      name: 'technicalSpecPdf',
      preview:
        !formState.values.technicalSpecPdf.removed && offering ? offering.techSpecName : null,
      showPreview: true,
      handleChange: e => formState.setField('technicalSpecPdf', e),
      validator: {}
    },
    primaryImage: {
      acceptTypes: 'image/*',
      gridWidth: {
        xs: 12,
        md: 6
      },
      InputComponent: InputImageField,
      label: 'Primary Image',
      name: 'primaryImage',
      preview: !formState.values.primaryImage.removed && offering.media ? offering.media[0] : null,
      handleChange: e => {
        formState.setField('primaryImage', e)
      },
      validator: {
        // required: formState.values.showOnMarketplace
      }
    },
    image2: {
      acceptTypes: 'image/*',
      gridWidth: {
        xs: 12,
        md: 6
      },
      InputComponent: InputImageField,
      label: 'Second Image',
      name: 'image2',
      preview: !formState.values.image2.removed && offering.media ? offering.media[1] : null,
      handleChange: e => formState.setField('image2', e),
      validator: {}
    },
    image3: {
      acceptTypes: 'image/*',
      gridWidth: {
        xs: 12,
        md: 6
      },
      InputComponent: InputImageField,
      label: 'Third Image',
      name: 'image3',
      preview: !formState.values.image3.removed && offering.media ? offering.media[2] : null,
      handleChange: e => formState.setField('image3', e),
      validator: {}
    },
    image4: {
      acceptTypes: 'image/*',
      gridWidth: {
        xs: 12,
        md: 6
      },
      InputComponent: InputImageField,
      label: 'Fourth Image',
      name: 'image4',
      preview: !formState.values.image4.removed && offering.media ? offering.media[3] : null,
      handleChange: e => formState.setField('image4', e),
      validator: {}
    },
    image5: {
      acceptTypes: 'image/*',
      gridWidth: {
        xs: 12,
        md: 6
      },
      InputComponent: InputImageField,
      label: 'Fifth Image',
      name: 'image5',
      preview: !formState.values.image5.removed && offering.media ? offering.media[4] : null,
      handleChange: e => formState.setField('image5', e),
      validator: {}
    },
    videoUrl01: {
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Video URL 1',
      name: 'videoUrl01',
      //handleChange: e => formState.setField('videoUrl01', e),
      validator: {
        mustContainValue: 'player.vimeo',
        mustContainValueMessage: 'Whoops! Your URL must be a Vimeo embed link',
        regex: inputRegexes.domain,
        regexMessage: 'Video URL must be a valid URL'
      }
    },
    videoUrl02: {
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Video URL 2',
      name: 'videoUrl02',
      // handleChange: e => formState.setField('videoUrl02', e),
      validator: {
        mustContainValue: 'player.vimeo',
        mustContainValueMessage: 'Whoops! Your URL must be a Vimeo embed link',
        regex: inputRegexes.domain,
        regexMessage: 'Video URL must be a valid URL'
      }
    },
    videoUrl03: {
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Video URL 3',
      name: 'videoUrl03',
      // handleChange: e => formState.setField('videoUrl03', e),
      validator: {
        mustContainValue: 'player.vimeo',
        mustContainValueMessage: 'Whoops! Your URL must be a Vimeo embed link',
        regex: inputRegexes.domain,
        regexMessage: 'Video URL must be a valid URL'
      }
    },
    videoUrl04: {
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Video URL 4',
      name: 'videoUrl04',
      // handleChange: e => formState.setField('videoUrl04', e),
      validator: {
        mustContainValue: 'player.vimeo',
        mustContainValueMessage: 'Whoops! Your URL must be a Vimeo embed link',
        regex: inputRegexes.domain,
        regexMessage: 'Video URL must be a valid URL'
      }
    },
    videoUrl05: {
      InputComponent: InputTextFieldNew,
      inputType: text,
      label: 'Video URL 5',
      name: 'videoUrl05',
      // handleChange: e => formState.setField('videoUrl05', e),
      validator: {
        mustContainValue: 'player.vimeo',
        mustContainValueMessage: 'Whoops! Your URL must be a Vimeo embed link',
        regex: inputRegexes.domain,
        regexMessage: 'Video URL must be a valid URL'
      }
    },
    type: {
      InputComponent: CheckboxDropdown,
      inputType: checkbox,
      label: 'Type*',
      name: 'type',
      options: types.map(t => t.name),
      setField: formState.setField,
      setFieldError: formState.setFieldError,
      validator: {
        required: true
      }
    },
    badges: {
      InputComponent: MultiSelectDropDownField,
      inputType: selectMultiple,
      label: 'Badges',
      name: 'badges',
      options: badges.sort((a, b) => (a.order > b.order ? 1 : -1)),
      handleChange: e => formState.setField('badges', e.target.value),
      validator: {}
    },
    badgeRecords: {
      InputComponent: MultiSelectDropDownField,
      inputType: selectMultiple,
      label: 'Badge Records',
      name: 'badgeRecords',
      options: { badgeRecords, badges, projectGroups },
      handleChange: e => formState.setField('badgeRecords', e.target.value),
      validator: {}
    },
    tags: {
      InputComponent: MultiSelectDropDownField,
      inputType: selectMultiple,
      label: 'Tags',
      name: 'tags',
      options: tags,
      handleChange: e => formState.setField('tags', e.target.value),
      validator: {}
    },
    categories: {
      InputComponent: MultiSelectDropDownField,
      inputType: selectMultiple,
      label: 'Categories',
      name: 'categories',
      options: categories,
      handleChange: e => formState.setField('categories', e.target.value),
      validator: {}
    },
    projectGroups: {
      InputComponent: MultiSelectDropDownField,
      inputType: selectMultiple,
      label: 'Project Groups',
      name: 'projectGroups',
      options: projectGroups,
      handleChange: e => formState.setField('projectGroups', e.target.value),
      validator: {}
    }
  }

  const disableSubmit = isFormSubmitDisabled(companyOfferingFields, formState, true)

  const shortDescriptionCharactersLeft = formState.values.shortDescription
    ? '1000' - formState.values.shortDescription.length
    : '1000'
  const longDescriptionCharactersLeft = formState.values.longDescription
    ? '5000' - formState.values.longDescription.length
    : '5000'

  return (
    <>
      {children(handleClickOpen)}
      <TIPDialog open={open} handleClose={handleClose}>
        <DropdownElevationContext.Provider value={dropdownElevation.raised}>
          <Grid container className={classes.modal}>
            <Grid item xs={12} className={classes.header}>
              {offeringId ? (
                <Typography variant="h3"> Edit Offering </Typography>
              ) : (
                <Typography variant="h3"> Create Offering </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <form className={classes.form}>
                  <Grid container spacing={2}>
                    {Object.entries(companyOfferingFields).map(
                      ([
                        name,
                        { hidden = false, InputComponent, gridWidth = { xs: 12 }, ...args }
                      ]) =>
                        !hidden && (
                          <Grid key={name} item {...gridWidth}>
                            <InputComponent
                              {...args}
                              error={
                                typeof formState.validity[name] !== 'undefined' &&
                                !formState.validity[name]
                              }
                              errorHelperText={formState.errors[name]}
                              value={formState.values[name]}
                            />
                            {name === 'shortDescription' || name === 'longDescription' ? (
                              <FormHelperText>
                                {name === 'shortDescription'
                                  ? `${shortDescriptionCharactersLeft} characters remaining`
                                  : `${longDescriptionCharactersLeft} characters remaining`}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                        )
                    )}
                    {!isReadOnly && (
                      <Grid container justify="flex-end">
                        <Grid item xs={12} sm={5} className={classes.buttonContainer}>
                          {/* // TODO IF EDITING HAVE A CANCEL BUTTON */}
                          <RoundedButton
                            onClick={handleSubmit}
                            disabled={loading || disableSubmit || loadingSelectedOrg}
                            color="primary"
                            fullWidth={true}
                            className={classes.roundedButton}
                          >
                            {loading ? <Progress size={25} delay={0} /> : 'Save'}
                          </RoundedButton>
                          {errorMessage && <ErrorSection> {errorMessage} </ErrorSection>}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </DropdownElevationContext.Provider>
      </TIPDialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 20
  },
  modal: {
    width: 900,
    maxWidth: '100vw'
  },
  form: {
    width: '100%',
    margin: 20,
    marginBottom: 30
  },
  buttonContainer: {
    marginTop: 20
  }
}))

export default EditOfferingForm
