import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useSession, useUser, useUserOrganization, useOrganization } from './queries'

function ProtectedOrganizationRoute({
  component: Component,
  requiredRoles,
  requiredStates,
  ...rest
}) {
  const { loading, userId, session, isAuthed, isSystemAdmin } = useSession()

  const { loading: userLoading } = useUser({
    variables: { id: userId },
    skip: !userId
  })

  const { loading: userOrgLoading, organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { orgId } = organizationUser

  const { loading: orgLoading, organization } = useOrganization({
    variables: { id: orgId },
    skip: !orgId
  })

  if (loading || userLoading || userOrgLoading || orgLoading) {
    return null
  }

  const hasRoleAccess =
    !requiredRoles || (isAuthed && session.user.roles.some(r => requiredRoles.includes(r.roleId)))

  let hasOrganizationAccess =
    isSystemAdmin ||
    !requiredStates ||
    (organization && requiredStates.includes(organization.approvalStatus))

  return (
    <Route
      {...rest}
      render={props =>
        isAuthed && hasRoleAccess && hasOrganizationAccess ? (
          <Component {...props} />
        ) : isAuthed && !(hasRoleAccess && hasOrganizationAccess) ? (
          <div>You are not authorized to view this page</div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default ProtectedOrganizationRoute
