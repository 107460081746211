import React from 'react'
import { useSession } from '../auth/queries'

import { toolbarHeight, systemAdminToolbarHeight } from '.'
export default function NavSpacer() {
  const {
    loading,
    isSystemAdmin,
    isReadOnlySystemAdmin,
    isImpersonating,
    isExchangeListingsAdmin
  } = useSession()
  if (loading) {
    return null
  }

  return (
    <div
      data-testid={`NavSpacer-${!!isSystemAdmin}`}
      style={{
        height:
          isSystemAdmin || isReadOnlySystemAdmin || isImpersonating || isExchangeListingsAdmin
            ? toolbarHeight + systemAdminToolbarHeight
            : toolbarHeight
      }}
    />
  )
}
