import gql from 'graphql-tag'

export const GET_ORG = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      name
      description
      type
      serviceOfferings
      operatingRegions
      phoneNumber
      website
      logo
      workTitle
      emailAddress
      membership {
        membershipLevel(id: $id) {
          id
          name
          price
          atlassianAccountsAllowed
          canAccessRFI
          canAccessTSS
          canAccessProjectGroups
          canAccessSoftwareGroups
        }
        status
        startDate
        renewalDate
        paid
      }
      users {
        id
        firstname
        lastname
        email
        status
      }
      billingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      organizationRoles {
        organizationAdmin {
          id
          email
          firstname
          lastname
        }
        exchangeListingAdmin {
          id
          email
          firstname
          lastname
        }
        primaryContact {
          id
          email
          firstname
          lastname
        }
        billingContact {
          user {
            id
            email
            jobTitle
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
        legalDesignee {
          user {
            id
            email
            jobTitle
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
        authorizedSigners {
          user {
            id
            email
            firstname
            lastname
            jobTitle
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
      capabilities
      percentageComplete
      showOnPublicSite
      hideFromOrganizationSelect
    }
  }
`

export const GET_MEMBERSHIP_LEVELS = gql`
  query($id: ID) {
    membershipLevels(id: $id) {
      id
      name
      price
      atlassianAccountsAllowed
    }
  }
`

export const GET_MEMBERSHIP_LEVEL = gql`
  query($id: ID) {
    membershipLevel(id: $id) {
      id
      name
      price
      atlassianAccountsAllowed
      canAccessRFI
      canAccessTSS
      canAccessProjectGroups
      canAccessSoftwareGroups
    }
  }
`
