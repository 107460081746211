import React, { useEffect, useCallback } from 'react'
import gql from 'graphql-tag'

import Progress from '../common/Progress'
import { useMutation } from '../hooks'
import useImpersonation from '../Organization/Impersonation'
import { clearStore } from '../middleware/api'

export const LOGOUT_USER = gql`
  mutation {
    logout {
      data
      result
    }
  }
`
export default function Logout() {
  const { execute } = useMutation(LOGOUT_USER)
  const callApiLogout = useCallback(execute, [])
  const { clearImpersonation } = useImpersonation()
  const clearImpersonationCallback = useCallback(clearImpersonation, [])

  const isSessionExpiredState = window.location.search.includes('session_expired')

  useEffect(() => {
    clearImpersonationCallback(false)
    callApiLogout().then(() => {
      // Should wait for logout call to finish to avoid race condition
      // that sometimes cancels logout when clearStore is called
      clearStore().then(() => {
        if (isSessionExpiredState) {
          window.location = '/login?state=session_expired'
        } else {
          window.location = '/login'
        }
        // history.push('/login')
      })
    })
  }, [callApiLogout, clearImpersonationCallback, isSessionExpiredState])

  return <Progress size={30} />
}
