import { createMuiTheme } from '@material-ui/core/styles'
const muiTheme = createMuiTheme()

const theme = {
  typography: {
    fontFamily: ['Montserrat', 'Roboto']
  },
  // TableCell: {
  //   //color: '#707072'
  // },
  palette: {
    approveButton: {
      background: '#ebf7f8'
    },
    actionBoxes: {
      background: '#ebf3f4'
    },
    background: {
      default: '#FFFFFF'
    },
    borderGray: {
      main: '#EAEAEA'
    },
    darkGray: {
      main: '#414141'
    },
    dashboardNotification: {
      main: '#C65133'
    },
    denyButton: {
      background: '#fdf1ee',
      hover: '#CC4B39',
      main: '#EB6F53'
    },
    gray: {
      main: '#414141'
    },
    gray2: {
      main: '#F2F2F2'
    },
    gray3: {
      main: '#F3F3F3'
    },
    gray4: {
      main: '#B6B6B6'
    },
    gray5: {
      main: '#F7F7F7'
    },
    gray6: {
      main: '#9e9e9e'
    },
    gray7: {
      main: '#e6e6e6'
    },
    gray8: {
      main: '#737373'
    },
    gray9: {
      main: '#939393'
    },
    gray10: {
      main: '#F6F6F6'
    },
    gray11: {
      main: '#D2D2D2'
    },
    gray12: {
      main: '#717171'
    },
    gray13: {
      main: '#FCFCFC'
    },
    navDarkGray: {
      main: '#414141'
    },
    navHoverGray: {
      main: '#505050'
    },
    navHoverLightGray: {
      main: '#b2b2b2'
    },
    navBorderGray: {
      main: '#616161'
    },
    primary: {
      main: '#29818C'
    },
    red: {
      main: '#F1634E'
    },
    redPending: {
      main: '#D64726'
    },
    secondary: {
      main: '#29818C' // intentionally the same as primary
    },
    text: {
      primary: '#414141'
      // secondary: '#414141'
    },
    // status colors
    deactivated: {
      main: '#E18888'
    },
    declined: {
      main: '#EDEDED'
    },
    graduated: {
      main: '#D4E5FD'
    },
    invited: {
      main: '#F8D4AD'
    },
    lead: {
      main: '#DDEAFB'
    },
    linkHover: {
      main: '#19666E'
    },
    linkGrey: {
      main: '#999999'
    },
    new: {
      main: '#FFD200'
    },
    nofly: {
      main: '#FFFFFF'
    },
    pending: {
      main: '#F9E9A3'
    },
    success: {
      main: '#E6EFC9'
    },
    suspended: {
      main: '#FAE1EB'
    },
    indicatorGreen: {
      main: '#90D500'
    },
    error: {
      main: '#f44336'
    }
  },
  breakpoints: {
    values: {
      ...muiTheme.breakpoints.values,
      md: 1080
    }
  },
  overrides: {
    MuiTableCell: {
      body: {
        color: '#414141'
      }
    },
    MuiIcon: {
      root: {
        fontSize: 16
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px !important'
      }
    }
  }
}

export default theme
