import React from 'react'
import Button from '@material-ui/core/Button'

import { history } from '../store'

export default function useImpersonation() {
  function setImpersonation(obj, returnUrl) {
    localStorage.setItem('impersonation', obj.userId)

    if (!localStorage.getItem('returnUrl')) {
      // so we jump back to the original window
      localStorage.setItem('returnUrl', returnUrl)
    }

    if (obj.orgId) {
      return history.push(`/organization/${obj.orgId}`)
    } else {
      return history.push(`/user/${obj.userId}`)
    }
  }

  function clearImpersonation(redirect) {
    localStorage.removeItem('impersonation')

    const returnUrl = localStorage.getItem('returnUrl')
    localStorage.removeItem('returnUrl')
    if (redirect) {
      history.push(returnUrl)
    }
  }

  return {
    clearImpersonation,
    setImpersonation,
    impersonation: localStorage.getItem('impersonation')
  }
}

export function ImpersonateUserField({ userId, orgId, returnUrl }) {
  const { impersonation, setImpersonation } = useImpersonation()
  if (impersonation === userId) {
    return null
  }
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={e => {
        setImpersonation({ userId, orgId }, returnUrl)
      }}
    >
      Advanced Manage
    </Button>
  )
}
