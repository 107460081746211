import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useFormState } from 'react-use-form-state'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { RoundedButton } from '../common'
import CheckboxField from '../common/CheckboxField'
import { useMutation } from '../hooks'
import {
  SEND_ADD_PROJECT_GROUP_UPDATE_EMAILS,
  SEND_GRADUATED_PROJECT_GROUP_UPDATE_EMAILS
} from './mutations'

export default function ProjectGroupChangeEmailModal({
  open,
  setOpen,
  submitAfterEmailSelection,
  submitChairNotifications,
  add,
  pgName,
  pgDesc,
  parentPgId,
  detailLink,
  graduated,
  pgId,
  chairsModal
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  let emailForm = {
    orgAdmins: false,
    primaryContacts: false,
    authorizedRepresentatives: false,
    selectAll: false
  }

  const { execute: sendAddUpdateEmails } = useMutation(SEND_ADD_PROJECT_GROUP_UPDATE_EMAILS, {})

  const { execute: sendGraduatedUpdateEmails } = useMutation(
    SEND_GRADUATED_PROJECT_GROUP_UPDATE_EMAILS,
    {}
  )

  const [formState, inputTypes] = useFormState(emailForm)

  function handleItemClick(item) {
    if (item === 'selectAll') {
      if (formState.values['selectAll']) {
        formState.setField('orgAdmins', false)
        formState.setField('primaryContacts', false)
        formState.setField('authorizedRepresentatives', false)
      } else {
        formState.setField('orgAdmins', true)
        formState.setField('primaryContacts', true)
        formState.setField('authorizedRepresentatives', true)
      }
      formState.setField('selectAll', !formState.values[item])
    } else {
      formState.setField(item, !formState.values[item])
      if (formState.values['selectAll']) {
        if (
          !formState.values['orgAdmins'] ||
          formState.values['primaryContacts'] ||
          formState.values['authorizedRepresentatives']
        ) {
          formState.setField('selectAll', false)
        }
      }
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.header} id="responsive-dialog-title">
          Project Group update notifications
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.dialogContentText}>
                <div>
                  <Typography className={classes.subTitleText}>
                    Select who you wish to notify about these Project Group updates.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.checklistContainer}>
                <div className={classes.checklistItem} onClick={() => handleItemClick('selectAll')}>
                  <div className={classes.flexColumnCenter}>
                    <CheckboxField
                      name="selectAll"
                      inputType={inputTypes.checkbox}
                      value={formState.values.selectAll}
                    />
                  </div>
                  <div className={classes.flexColumnCenter}>Select all</div>
                </div>
                <div className={classes.checklistItem} onClick={() => handleItemClick('orgAdmins')}>
                  <div className={classes.flexColumnCenter}>
                    <CheckboxField
                      name="orgAdmins"
                      inputType={inputTypes.checkbox}
                      value={formState.values.orgAdmins}
                    />
                  </div>
                  <div className={classes.flexColumnCenter}>Account admins</div>
                </div>
                <div
                  className={classes.checklistItem}
                  onClick={() => handleItemClick('primaryContacts')}
                >
                  <div className={classes.flexColumnCenter}>
                    <CheckboxField
                      name="primaryContacts"
                      inputType={inputTypes.checkbox}
                      value={formState.values.primaryContacts}
                    />
                  </div>
                  <div className={classes.flexColumnCenter}>Primary contacts</div>
                </div>
                <div
                  className={classes.lastChecklistItem}
                  onClick={() => handleItemClick('authorizedRepresentatives')}
                >
                  <div className={classes.flexColumnCenter}>
                    <CheckboxField
                      name="authorizedRepresentatives"
                      inputType={inputTypes.checkbox}
                      value={formState.values.authorizedRepresentatives}
                    />
                  </div>
                  <div className={classes.flexColumnCenter}>Authorized representatives</div>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <RoundedButton
            onClick={() => {
              if (
                formState.values.orgAdmins ||
                formState.values.primaryContacts ||
                formState.values.authorizedRepresentatives
              ) {
                if (chairsModal) {
                  submitChairNotifications(formState.values)
                } else {
                  if (add) {
                    sendAddUpdateEmails({
                      values: {
                        orgAdmins: formState.values.orgAdmins,
                        primaryContacts: formState.values.primaryContacts,
                        authorizedRepresentatives: formState.values.authorizedRepresentatives,
                        pgName: pgName,
                        pgDesc: pgDesc,
                        parentPgId: parentPgId,
                        detailLink: detailLink
                      }
                    }).then(() => {
                      submitAfterEmailSelection()
                      formState.reset()
                    })
                  } else {
                    sendGraduatedUpdateEmails({
                      values: {
                        orgAdmins: formState.values.orgAdmins,
                        primaryContacts: formState.values.primaryContacts,
                        authorizedRepresentatives: formState.values.authorizedRepresentatives,
                        pgId: pgId,
                        graduated: graduated
                      }
                    }).then(() => {
                      submitAfterEmailSelection()
                      formState.reset()
                    })
                  }
                }
              } else {
                if (chairsModal) {
                  submitChairNotifications(formState.values)
                } else {
                  submitAfterEmailSelection()
                  formState.reset()
                }
              }
            }}
            fullWidth={false}
            variant="outlined"
            className={classes.button}
          >
            Continue
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 5
  },
  outlined: {
    color: theme.palette.primary.main
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  warningIcon: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  subTitleText: {
    fontWeight: 600
  },
  errorSection: {
    marginBottom: 20
  },
  checklistContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid lightgray',
    marginTop: 25
  },
  checklistItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 20px',
    borderBottom: '1px solid lightgray',
    cursor: 'pointer'
  },
  lastChecklistItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 20px',
    cursor: 'pointer'
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    pointerEvents: 'none'
  }
}))
