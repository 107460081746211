import React from 'react'
import { makeStyles } from '@material-ui/styles'
import GlossiesHero from './GlossiesHero'
import GlossiesContent from './GlossiesContent'

const useStyles = makeStyles(theme => ({
  content: {
    margin: '-15px -15px -30px -15px'
  }
}))

const Glossies = () => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <GlossiesHero />
      <GlossiesContent />
    </div>
  )
}

export default Glossies
