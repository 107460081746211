import React, { Fragment, useEffect } from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { Redirect } from 'react-router-dom'
import clsx from 'clsx'

import { useSession } from '../auth/queries'
import { useFormStyles, useMutation, useQuery } from '../hooks'
import { GET_USER_DATA } from '../Home/queries'
import { GET_USER_ORGANIZATIONS } from '../auth/queries'
import { GET_ORGANIZATION_DATA } from './queries'
import { EDIT_USER_PROFILE } from './mutations'
import { Progress } from '../common'
import BillingInformation from './BillingInformation'
import CreateAccount from './CreateAccount'
import CreateProfile from './CreateProfile'
import SelectOrganization from './SelectOrganization'
import CreateOrgProfile from './CreateOrgProfile'
import Summary from './Summary'
import MembershipTiers from './MembershipTiers'
import ParticipationAgreement from './ParticipationAgreement'
import ReviewAndSendAgreement from './ReviewAndSendAgreement'
import SignaturePending from './SignaturePending'
import RequestPending from './RequestPending'
import { signUpStepHelper } from '../utils'
import { pushVirtualPageView } from '../utils/googleAnalytics'

const SignUpMain = props => {
  const { userId, loading: sessionLoading } = useSession()
  const formClasses = useFormStyles()

  const { data: { user = {} } = {}, loading: userLoading } = useQuery(GET_USER_DATA, {
    variables: { id: userId },
    skip: !userId
  })

  const { data: { user: userOrg = {} } = {}, loading: userOrgLoading } = useQuery(
    GET_USER_ORGANIZATIONS,
    {
      variables: { id: userId },
      skip: !userId
    }
  )

  const orgId =
    userOrg.organizations && userOrg.organizations.length > 0
      ? userOrg.organizations[0].organizationId
      : null

  const { data: { organization = {} } = {}, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_DATA,
    {
      variables: { id: orgId },
      skip: !orgId
    }
  )

  const { execute: updateUser } = useMutation(EDIT_USER_PROFILE, {
    refetchQueries: [{ query: GET_USER_DATA, variables: { id: userId } }]
  })

  const params = new URLSearchParams(props.location.search)
  const acceptedInvite =
    params.get('acceptedInvite') ||
    (userOrg.organizations && organization && organization.approvalStatus === 'approved'
      ? userOrg.organizations.some(o => o.status === 'approved')
      : false)

  const creatingOrg =
    organization &&
    (organization.approvalStatus === 'in progress' ||
      organization.approvalStatus === 'pre-approved')
  const stepNumber = userId
    ? getStepNumber({ acceptedInvite, creatingOrg, signUpStep: user.signUpStep })
    : 1
  const numberOfSteps = acceptedInvite ? 2 : creatingOrg ? 7 : 4

  const classes = useStyles({ numberOfSteps })

  useEffect(() => {
    if (stepNumber >= 1 || user.signUpStep) {
      const step = user.signUpStep ? user.signUpStep : stepNumber
      const virtualPage = signUpStepHelper[step]
        ? signUpStepHelper[step].virtualPage
        : `step-${step}`
      pushVirtualPageView(`/vpv/sign-up/${virtualPage}`, `SignUp: ${signUpStepHelper[step].name}`)
    }
  }, [stepNumber, user.signUpStep])

  if (sessionLoading || userLoading || userOrgLoading || organizationLoading) {
    return <Progress />
  }

  return (
    <>
      <div className={classes.stepCount}>
        {Array.from({ length: numberOfSteps }, (x, i) => ++i).map(value =>
          value === numberOfSteps ? (
            <Typography
              component="span"
              key={numberOfSteps}
              visited-step={`${stepNumber >= numberOfSteps}`}
            >
              {stepNumber > numberOfSteps ? <CheckIcon fontSize="small" /> : numberOfSteps}
            </Typography>
          ) : (
            <Fragment key={value}>
              <Typography component="span" visited-step={`${stepNumber >= value}`}>
                {stepNumber > value ? <CheckIcon fontSize="small" /> : value}
              </Typography>
              <Divider variant="middle" visited-step={`${stepNumber >= value + 1}`} />
            </Fragment>
          )
        )}
      </div>
      <Grid container justify="center">
        <Grid item xs={12} md={7} lg={6} className={clsx(formClasses.container, classes.container)}>
          {sessionLoading ? (
            <Progress />
          ) : (
            <>
              <Typography className={classes.stepCountText} variant="h6">
                Step {stepNumber} of {numberOfSteps}
              </Typography>
              {!userId ? (
                <CreateAccount acceptedInvite={acceptedInvite} params={params} />
              ) : (
                getCurrentStepComponent({
                  acceptedInvite,
                  org: user.signUpStep > 3 && organization,
                  orgLoading: organizationLoading,
                  signUpStep: user.signUpStep,
                  updateUser:
                    user.signUpStep > 2 &&
                    (signUpStep =>
                      updateUser({
                        id: userId,
                        values: { signUpStep }
                      })),
                  user,
                  userLoading
                })
              )}
            </>
          )}
        </Grid>
      </Grid>
      {!userId && (
        <Typography className={classes.recaptchaRemark}>
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            href="https://policies.google.com/privacy"
            className={classes.recaptchaLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://policies.google.com/terms"
            className={classes.recaptchaLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          apply.
        </Typography>
      )}
    </>
  )
}

const getStepNumber = ({ acceptedInvite, creatingOrg, signUpStep }) => {
  /*
      INVITED USER
    _ 1  create user     - name, title, password
 2 |  2  user profile    - address
   |_ 5  summary         - edit user and created org info
      12 Welcome modal   - dashboard welcome modal

      NEW USER, EXISTING ORG
      1  create user     - name, title, password
    _ 2  user profile    - address
 3 |  3  select org      - select/create org
   |_ 5  summary         - edit user and created org info
 4 |_ 11 Request pending - user waiting to be approved by org admin
      12 Welcome modal   - dashboard welcome modal

      NEW USER, NEW ORG
      1  create user     - name, title, password
    _ 2  user profile    - address
   |  3  select org      - select/create org
 3 |  4  org profile     - name, address, phone, website
   |_ 5  summary         - edit user and created org info
 4 |_ 6  billing info    - add billing contact
 5 |_ 7  membership tier - select membership tier
 6 |  8  GPA auth rep    - select auth rep
   |_ 9  review/send GPA - review/send org participation agreement
 7 |_ 10  GPA pending     - send org participation agreement
      12 Welcome modal   - dashboard welcome modal

      null User has completed sign up and is a full member of TIP
  */

  // All routes
  if (signUpStep <= 2) {
    return signUpStep
  }

  // Invited member past signUpStep 2
  if (acceptedInvite) {
    return 2
  }

  // Member joining/creating organization and summary
  if (signUpStep <= 5) {
    return 3
  }

  // Member creating new organization
  if (creatingOrg) {
    switch (signUpStep) {
      case 6:
        return 4
      case 7:
        return 5
      case 8:
      case 9:
        return 6
      case 10:
        return 7
      default:
        return signUpStep
    }
  }

  // Member joining existing organization
  return 4
}

const getCurrentStepComponent = ({ signUpStep, ...rest }) => {
  switch (signUpStep) {
    case 1:
      return <CreateAccount {...rest} />
    case 2:
      // Continues to step 3 if new user
      // Skips to step 5 if invited
      return <CreateProfile {...rest} />
    case 3:
      // Continues to step 4 if new org
      // Skips to step 5 if existing org
      return <SelectOrganization {...rest} />
    case 4:
      return <CreateOrgProfile {...rest} />
    case 5:
      // Continues to step 6 if new org
      // Skips to step 11 if joining existing org
      // Skips to step 12 if invited user
      return <Summary {...rest} />
    case 6:
      return <BillingInformation {...rest} />
    case 7:
      return <MembershipTiers {...rest} />
    case 8:
      return <ParticipationAgreement {...rest} />
    case 9:
      return <ReviewAndSendAgreement {...rest} />
    case 10:
      return <SignaturePending {...rest} />
    case 11:
      return <RequestPending {...rest} />
    case 12:
    // Welcome Modal falls through
    default:
      return <Redirect to="/" />
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  recaptchaRemark: {
    fontSize: 12,
    marginTop: theme.spacing(6),
    textAlign: 'center'
  },
  recaptchaLink: {
    color: '#737373'
  },
  stepCount: ({ numberOfSteps }) => ({
    marginBottom: -theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'center',
    '& hr': {
      display: 'inline-block',
      height: 2,
      width: numberOfSteps > 4 ? '5%' : '10%'
    },
    '& hr[visited-step="true"]': {
      backgroundColor: '#A5CF4C'
    },
    '& span': {
      backgroundColor: theme.palette.gray6.main,
      borderRadius: '50%',
      color: 'white',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'bold',
      height: theme.spacing(3),
      paddingTop: 3,
      textAlign: 'center',
      verticalAlign: 'middle',
      width: theme.spacing(3)
    },
    '& span[visited-step="true"]': {
      backgroundColor: '#A5CF4C'
    }
  }),
  stepCountText: {
    color: theme.palette.gray9.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  }
}))

export default SignUpMain
