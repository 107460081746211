import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const getUrlFromLocation = location => {
  return `${location.pathname}${location.search}${location.hash}`
}

export const pushVirtualPageView = (location, title = 'Membership Portal - TIP') => {
  if (window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      event: 'sendVPV',
      virtualPageURL: location.pathname ? '/vpv' + getUrlFromLocation(location) : location,
      virtualPageTitle: title
    })
  }
}

export const useGoogleAnalytics = () => {
  const location = useSelector(state => state.router.location)
  useEffect(() => {
    pushVirtualPageView(location)
  }, [location])
}
