import React from 'react'
import { Switch } from 'react-router-dom'

import { ProtectedOrgRoute } from '../auth'
import { ORGANIZATION_APPROVED } from '../auth/constants'
import { roles } from '../utils/roleHelper'

import {
  Billing,
  Profile,
  ManageInvitations,
  OrgAdminManageMembers,
  Agreements,
  OrganizationProjectGroups,
  ManageOfferings
} from './'

function OrganizationRouter({ match }) {
  return (
    <Switch>
      <ProtectedOrgRoute
        path={`${match.path}/:id/billing`}
        requiredRoles={[roles.systemAdmin, roles.orgMemberAdmin]}
        component={Billing}
      />
      <ProtectedOrgRoute
        path={`${match.path}/:id/manage-members`}
        requiredRoles={[roles.systemAdmin, roles.orgMemberAdmin]}
        requiredStates={[ORGANIZATION_APPROVED]}
        component={OrgAdminManageMembers}
      />
      <ProtectedOrgRoute
        path={`${match.path}/:id/manage-invites`}
        requiredRoles={[roles.systemAdmin, roles.orgMemberAdmin]}
        requiredStates={[ORGANIZATION_APPROVED]}
        component={ManageInvitations}
      />
      <ProtectedOrgRoute
        path={`${match.path}/:id/agreements`}
        requiredRoles={[roles.systemAdmin, roles.orgPrimaryContact, roles.orgMemberAdmin]}
        component={Agreements}
      />
      <ProtectedOrgRoute
        path={`${match.path}/:id/project-groups`}
        requiredRoles={[roles.systemAdmin, roles.orgMemberAdmin]}
        requiredStates={[ORGANIZATION_APPROVED]}
        component={OrganizationProjectGroups}
      />
      <ProtectedOrgRoute
        path={`${match.path}/:id/tip-exchange`}
        requiredRoles={[roles.systemAdmin, roles.orgMemberAdmin]}
        requiredStates={[ORGANIZATION_APPROVED]}
        component={ManageOfferings}
      />
      <ProtectedOrgRoute
        path={`${match.path}/:id`}
        requiredRoles={[roles.systemAdmin, roles.orgMemberAdmin]}
        component={Profile}
      />
    </Switch>
  )
}

export default OrganizationRouter
