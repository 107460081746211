import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export default function GlossiesSuccessModal({ isOpen, setOpen }) {
  // const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      className={classes.modalContainer}
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Success
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <div>
            <Typography className={classes.bold}>Please check your email</Typography>
            <Typography>
              Link(s) to the document(s) you selected have been emailed to the address you've
              provided.
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          fullWidth={false}
          onClick={handleClose}
          className={classes.button}
          classes={{ root: classes.root }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  modalContainer: {
    position: 'relative',
    top: '-150px'
  },
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  root: {
    color: 'white',
    backgroundColor: '#29818C'
  },
  header: {
    backgroundColor: '#29818C',
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  }
}))
