import React from 'react'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import Divider from '@material-ui/core/Divider'

import { useQuery } from '../../../../hooks'
import { Progress } from '../../../../common'

import { GET_ALL_PROJECT_GROUPS, GET_USER_PROJECT_GROUPS } from '../../../../ProjectGroups'
import Atlassian from './Atlassian'
import Github from './Github'
import Slack from './Slack'

export default function ConnectedAccounts({ user, tabLoopStart, isReadOnly = false }) {
  const { id: userId, atlassianId: userAtlassianId } = user
  const classes = useStyles()

  const {
    loading: projectGroupsLoading,
    data: { projectGroups: projectGroups = [] } = {}
  } = useQuery(GET_ALL_PROJECT_GROUPS)

  const {
    loading: userProjectGroupsLoading,
    data: { findProjectGroupsByUserId: userProjectGroups = [] } = {}
  } = useQuery(GET_USER_PROJECT_GROUPS, { variables: { id: userId } })

  if (userProjectGroupsLoading || projectGroupsLoading) {
    return <Progress size={30} delay={0} />
  }

  const { showGithub, showSlack } = userProjectGroups
    .filter(userPg => !userPg.approvalStatus)
    .reduce(
      (prev, cur) => {
        const pg = projectGroups.find(pg => pg.id === cur.projectGroupId)

        if (pg.githubTeam) {
          prev.showGithub = true
        }

        if (pg.slackTeamId) {
          prev.showSlack = true
        }

        return prev
      },
      {
        showGithub: false,
        showSlack: false
      }
    )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography paragraph>
          Connect your TIP Participation Account account to TIP's authorized 3rd party integrations.
          These integrations may only apply to specific PG participation.
        </Typography>
      </Grid>
      <Grid container className={classes.container}>
        {!showGithub && !userAtlassianId && !showSlack && (
          <Typography paragraph>
            You are not part of any project groups that use these tools.
          </Typography>
        )}
        {showGithub && (
          <>
            <Divider classes={{ root: classes.divider }} />
            <Github user={user} tabLoopStart={tabLoopStart} isReadOnly={isReadOnly} />
          </>
        )}
        {userAtlassianId && (
          <>
            <Divider classes={{ root: classes.divider }} />
            <Atlassian user={user} isReadOnly={isReadOnly} />
          </>
        )}
        {showSlack && (
          <>
            <Divider variant="middle" classes={{ root: classes.divider }} />
            <Slack user={user} isReadOnly={isReadOnly} />
          </>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  message: { marginRight: 5 },
  linkSection: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  inputField: { marginRight: 10 },
  align: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  button: {
    marginRight: 5,
    fontWeight: 600
  },
  errorSection: {
    marginTop: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  },
  divider: {
    backgroundColor: theme.palette.gray7.main,
    margin: '20px 0',
    marginLeft: -34,
    width: 'calc(100% + 58px)'
  }
}))
