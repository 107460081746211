import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useLazyQuery } from '@apollo/react-hooks'

import SyncIntegrationDeprovisionList from './SyncIntegrationDeprovisionList'
import SyncIntegrationErrorList from './SyncIntegrationErrorList'
import { Progress, RoundedButton } from '../common'
import { useSession } from '../auth/queries'
import { GET_LATEST_SYNC_RESULT_INFO, GET_SYNC_RESULTS } from './queries'
import { SYNC_INTEGRATION } from './mutations'
import { useMutation, useQuery } from '../hooks'
import SyncIntegrationMultipleList from './SyncIntegrationMultipleList'

const SyncIntegration = ({
  includeDeprovisionList = true,
  includeMultipleList = true,
  integrationType = '',
  deprovisionProps = {},
  errorProps = {},
  multipleProps = {}
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { userId, isReadOnlySystemAdmin } = useSession()
  const classes = useStyles()

  const [currentSyncResults, setCurrentSyncResults] = useState({ results: [] })
  const [currentSyncResultInfo, setCurrentSyncResultInfo] = useState({})
  const [intervalId, setIntervalId] = useState()

  const {
    data: { getLatestSyncResultInfo: newSyncResultInfo = {} } = {},
    loading: syncResultInfoLoading,
    refetch: syncResultInfoRefetch
  } = useQuery(GET_LATEST_SYNC_RESULT_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        currentResultsId: currentSyncResultInfo.id,
        currentResultsUpdatedDate:
          currentSyncResults.updatedDate || currentSyncResultInfo.updatedDate,
        integrationType
      }
    }
  })

  const [
    loadResults,
    { data: { getSyncResults: syncResultsData } = {}, loading: syncResultsLoading }
  ] = useLazyQuery(GET_SYNC_RESULTS, {
    fetchPolicy: 'cache-and-network',
    onError: e => {
      console.error(e)

      enqueueSnackbar(`${integrationType} Sync: Unable to load results`, {
        autoHideDuration: 4500,
        variant: 'warning'
      })
    }
  })

  const { execute: syncIntegrationExecute, loading: syncIntegrationLoading } = useMutation(
    SYNC_INTEGRATION,
    {
      onCompleted: ({ syncIntegration: syncIntegrationData }) => {
        if (syncIntegrationData) {
          enqueueSnackbar(
            `${integrationType} Sync: Task queued successfully. To be notified when the task has completed, please remain on this page`,
            {
              autoHideDuration: 4500,
              variant: 'success'
            }
          )
        }
      },
      variables: {
        input: {
          userId,
          integrationType
        }
      }
    }
  )

  useEffect(() => {
    if (syncResultsData) {
      setCurrentSyncResults(syncResultsData)
    }
  }, [syncResultsData])

  useEffect(() => {
    if (
      newSyncResultInfo.dataNew &&
      (newSyncResultInfo.data.id !== currentSyncResultInfo.id ||
        (currentSyncResultInfo.updatedDate &&
          new Date(newSyncResultInfo.data.updatedDate).getTime() !==
            new Date(currentSyncResultInfo.updatedDate).getTime()))
    ) {
      if (newSyncResultInfo.data) {
        setCurrentSyncResultInfo(newSyncResultInfo.data)
        setCurrentSyncResults({ results: [] })
      }

      if (currentSyncResultInfo.id) {
        enqueueSnackbar(`${integrationType} Sync: New results have been found`, {
          autoHideDuration: 4500,
          variant: 'success'
        })
      }
    }
  }, [currentSyncResultInfo, enqueueSnackbar, integrationType, newSyncResultInfo])

  useEffect(() => {
    if (!intervalId && !syncResultsLoading) {
      setIntervalId(setInterval(() => syncResultInfoRefetch(), 30000))
    }

    return () => clearInterval(intervalId)
  }, [intervalId, syncResultInfoRefetch, syncResultsLoading])

  return (
    <Grid container spacing={4}>
      <Grid container item className={classes.container} spacing={4} xs={12}>
        {!isReadOnlySystemAdmin && (
          <Grid item xs={12} sm={5}>
            <RoundedButton
              disabled={syncIntegrationLoading}
              onClick={() => syncIntegrationExecute()}
            >
              {syncIntegrationLoading ? <Progress /> : `Execute Sync`}
            </RoundedButton>
          </Grid>
        )}
        <Grid container item alignContent="center" justify="center" xs={12} sm={7}>
          {syncResultInfoLoading ? (
            <Grid item xs="auto">
              <Progress />
            </Grid>
          ) : Object.keys(currentSyncResultInfo).length > 0 ? (
            <>
              <Grid item xs>
                <Typography
                  className={classes.syncResultsTypography}
                  component="div"
                  variant="caption"
                >
                  Latest {integrationType} results: {currentSyncResultInfo.trigger},{' '}
                  {new Date(
                    currentSyncResultInfo.createdDate - currentSyncResultInfo.executionMilliseconds
                  ).toLocaleString()}
                  , {formatDuration(new Date(currentSyncResultInfo.executionMilliseconds))}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <div className={classes.syncButtonWrapper}>
                  {currentSyncResults && currentSyncResults.id === currentSyncResultInfo.id ? (
                    <RoundedButton
                      disabled={syncResultsLoading}
                      fullWidth={false}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        const dataWindow = window.open()

                        dataWindow.document.open()
                        dataWindow.document.write(
                          `<html><body><pre>${JSON.stringify(
                            currentSyncResults,
                            (key, value) =>
                              value === null || key === '__typename'
                                ? undefined
                                : key === 'createdDate'
                                ? new Date(value).toDateString()
                                : value,
                            2
                          )}</pre></body></html>`
                        )
                        dataWindow.document.close()
                      }}
                    >
                      View Results
                    </RoundedButton>
                  ) : (
                    <RoundedButton
                      disabled={syncResultsLoading}
                      fullWidth={false}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        clearInterval(intervalId)
                        setIntervalId(null)
                        loadResults({
                          variables: {
                            id: currentSyncResultInfo.id
                          }
                        })
                      }}
                    >
                      {syncResultsLoading ? <Progress /> : 'Load Results'}
                    </RoundedButton>
                  )}
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs="auto">
              <Typography variant="caption">No results found</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {includeDeprovisionList && (
        <Grid item xs={12}>
          <SyncIntegrationDeprovisionList
            {...deprovisionProps}
            containsNonExemptDeprovisionData={
              currentSyncResultInfo.containsNonExemptDeprovisionData
            }
            syncResults={currentSyncResults}
            title={integrationType}
            triggerSyncResultInfoRefetch={() => {
              clearInterval(intervalId)
              syncResultInfoRefetch()
              setIntervalId(setInterval(() => syncResultInfoRefetch(), 30000))
            }}
          />
        </Grid>
      )}
      {includeMultipleList && (
        <Grid item xs={12}>
          <SyncIntegrationMultipleList
            {...multipleProps}
            containsMultipleData={currentSyncResultInfo.containsMultipleData}
            syncResults={currentSyncResults}
            title={integrationType}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SyncIntegrationErrorList
          {...errorProps}
          containsErrorData={currentSyncResultInfo.containsErrorData}
          syncResults={currentSyncResults}
        />
      </Grid>
    </Grid>
  )
}

const formatDuration = duration => {
  const minutes = duration.getMinutes()
  const seconds = duration.getSeconds()

  let result = ''

  if (minutes > 0) {
    result = `${minutes}m:`

    if (seconds < 10) {
      result += '0'
    }
  }

  result += `${seconds}s`

  return result
}

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 722
  },
  syncButtonWrapper: {
    maxWidth: 250
  },
  syncResultsTypography: {
    marginRight: 16
  }
}))

export default SyncIntegration
