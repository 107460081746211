import gql from 'graphql-tag'

export const GET_AUTHORIZED_SIGNERS_DATA = gql`
  query getAuthorizedSignersByOrg($id: ID) {
    organization(id: $id) {
      id
      organizationRoles {
        authorizedSigners {
          user {
            id
            email
            firstname
            lastname
            jobTitle
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
    }
  }
`

export const GET_ORG_DATA_FOR_SIGNATURE = gql`
  query getOrgDataForSignature($id: ID) {
    organization(id: $id) {
      id
      name
      shippingAddress {
        address1
        city
        state
        country
        postalCode
      }
      website
      phoneNumber
    }
  }
`
