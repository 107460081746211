import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography'
import LinkField from '../common/LinkField'
import { GET_ORG_DOCUMENTS } from './queries'

export default function OrganizationDocuments() {
  const classes = useStyles()

  const { data } = useQuery(GET_ORG_DOCUMENTS)

  // const link = 'https://telecominfraproject.com/wp-content/uploads'
  // const cdnLink = 'https://cdn.brandfolder.io'

  // const documents = [
  //   {
  //     label: 'ByLaws',
  //     to: `${link}/Telecom-Infra-Project-Bylaws-as-adopted.pdf`
  //   },
  //   {
  //     label: 'TIP Antitrust Guidelines',
  //     to: `${cdnLink}/D8DI15S7/as/xgmqv6p42frp25jvqg7mjvpm/Telecom_Infra_Project_-_Antitrust_Policy.pdf`
  //   },
  //   {
  //     label: 'Intellectual Property Rights Policy (IPR)',
  //     to: `${link}/IPR-Policy-Adopted-May-27-2016.pdf`
  //   },
  //   {
  //     label: 'General Participation Agreement (GPA)',
  //     to: `${link}/TIP_PA-July2018-Aug20-clean-Final.pdf`
  //   },
  //   {
  //     label: 'TIP Project Groups Procedures',
  //     to: `${cdnLink}/D8DI15S7/as/7q3mckwvtmjgf57k9bj9r949/Telecom_Infra_Project_-_PG_Processes.pdf`
  //   },
  //   {
  //     label: 'Liaison Policy',
  //     to: `${link}/TIP-Liaison-Policy_final.pdf`
  //   },
  //   {
  //     label: 'Supplemental Copyright Policy',
  //     to: `${link}/Supplemental-Copyright-Policy_final.pdf`
  //   },
  //   {
  //     label: 'Project Group Charter Revision Policy',
  //     to: `${link}/PG-Charter-Revision-Policy_final.pdf`
  //   },
  //   {
  //     label: 'TIP Document IPR Policy',
  //     to: `${link}/TIP-Document-IPR-Policy-May_BODapproved.pdf`
  //   },
  //   {
  //     label: 'TIP Software Contribution and License Agreement',
  //     to: `${link}/TIP-Software-CLA-Final-Form_Jul24.pdf`
  //   },
  //   {
  //     label: 'Hackathon License Agreement',
  //     to: `${link}/TIP-Hackathon-License-Agreement_BODapproval_6_26_19.pdf`
  //   },
  //   {
  //     label: 'TIP Community Lab Policy',
  //     to: `${cdnLink}/D8DI15S7/as/qd7y3r-327zzs-5d6rod/TIP-Community-Lab-Policy-Final.pdf`
  //   },
  //   {
  //     label: 'TEAC Policy',
  //     to: `${cdnLink}/D8DI15S7/as/f9hkwp5rwfk9bcw93x835q/TIP_TEAC_Policy.pdf`
  //   }
  // ]

  if (!data) {
    return null
  }

  const { organizationDocuments } = data

  const DocumentLink = props => {
    return (
      <Grid item xs={12} className={classes.documentList}>
        <div>
          <Typography variant="h6" className={classes.documentTitle}>
            {props.title}
          </Typography>
          <LinkField
            className={classes.documentLink}
            hideIcon={true}
            hideBorder={true}
            label="here"
            to={props.link}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.pageContainer}>
      <Grid item xs={12}>
        <Typography role="heading" variant="h3" className={classes.pageTitle}>
          Organizational Documents
        </Typography>
        <Typography variant="h5">
          Here you will find the most recent Telecom Infra Project organizational documents for
          reference.
        </Typography>
      </Grid>
      {organizationDocuments
        .sort((a, b) => (a.rank > b.rank ? 1 : -1))
        .map(d => (
          <DocumentLink key={d.name} title={d.name} link={d.url} />
        ))}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  pageContainer: {
    padding: 20
  },
  pageTitle: {
    marginBottom: 25
  },
  documentList: {
    paddingTop: 20
  },
  documentTitle: {
    display: 'inline-block',
    marginRight: 5
  },
  documentLink: {
    fontSize: 20
  }
}))
