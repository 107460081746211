import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import clsx from 'clsx'

export default function StatusSection({
  disableMinHeight = false,
  hideBorder = false,
  message,
  titles,
  colors,
  icons,
  Sections,
  SubSection,
  ...rest
}) {
  const classes = useStyles({ disableMinHeight, hideBorder })

  return (
    <Grid container className={classes.section}>
      <Grid item xs={12}>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
      {Sections.map((Section, index) => (
        <Grid
          key={index}
          item
          xs={12}
          className={clsx(classes.innerSection, classes[`${colors[index]}Section`])}
        >
          <div className={classes.header}>
            <Icon className={clsx(classes.icon, classes[`${colors[index]}Icon`])}>
              {icons[index]}
            </Icon>
            <Typography className={classes.title}>{titles[index]}</Typography>
          </div>
          <div className={classes.body}>
            <Section {...rest} />
          </div>
        </Grid>
      ))}
      {SubSection && (
        <Grid item xs={12} className={classes.subSection}>
          {SubSection}
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  section: ({ hideBorder }) => ({
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: hideBorder ? '' : '1px solid #E6E6E6',
    padding: hideBorder ? '' : '13px 20px',
    [theme.breakpoints.down('xs')]: {
      borderLeft: 'none',
      borderRight: 'none'
    }
  }),
  innerSection: ({ disableMinHeight }) => ({
    padding: '16px 20px',
    borderRadius: 3,
    marginBottom: 20,
    minHeight: disableMinHeight ? 0 : 145
  }),
  orangeSection: {
    background: '#FFF8F2 0% 0% no-repeat padding-box',
    border: '1px solid #F1C1B7'
  },
  blueSection: {
    background: `#F5FEFFCC 0% 0% no-repeat padding-box`,
    border: `1px solid #AEDDE2`
  },
  graySection: {
    background: `#F6F6F6 0% 0% no-repeat padding-box`,
    border: `1px solid #D2D2D2`
  },
  subSection: {
    // marginTop: 20
  },
  icon: {
    fontSize: 24,
    marginRight: 5
  },
  blueIcon: {
    color: theme.palette.primary.main
  },
  orangeIcon: {
    color: `#EB6F53`
  },
  grayIcon: {
    color: '#8A8A8A'
  },
  message: {
    fontSize: 14,
    marginBottom: 20
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18
  },
  body: {
    paddingLeft: 27,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 1
    }
  }
}))
