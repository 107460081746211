import gql from 'graphql-tag'

export const CONFIRM_BILLING_ALERT = gql`
  mutation($id: ID!) {
    confirmBillingAlert(id: $id) {
      result
      data
    }
  }
`

export const EDIT_BILLING_CONTACT = gql`
  mutation($id: ID!, $input: BillingContactInput!) {
    editBillingContact(id: $id, input: $input)
  }
`

export const INCREMENT_RENEWAL_DATE = gql`
  mutation($id: ID!) {
    incrementRenewalDate(id: $id) {
      approvalStatus
      renewalDate
      result
    }
  }
`
