import React from 'react'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { useSession } from '../auth/queries'
import Progress from '../common/Progress'
import LinkField from '../common/LinkField'
import RoundedButton from '../common/RoundedButton'
import { useFormStyles, useMutation, useQuery } from '../hooks'
import { GET_ORG } from '../Organization/Profile/queries'
import { ADD_MEMBERSHIP_TIER } from '../Organization/Profile/mutations'
import { GET_MEMBERSHIP_LEVELS } from '../Organization/Profile/queries'

export default function MembershipTiers({ updateUser }) {
  const { enqueueSnackbar } = useSnackbar()
  const formClasses = useFormStyles()
  const classes = useStyles()

  const { isOrgAdmin } = useSession()

  let orgId
  if (isOrgAdmin) {
    orgId = isOrgAdmin.scopes[0].split(':')[1]
  }

  const { data, loading: queryLoading } = useQuery(GET_MEMBERSHIP_LEVELS, {
    variables: { id: orgId }
  })

  const { loading, execute: handleSelectTier } = useMutation(ADD_MEMBERSHIP_TIER, {
    onCompleted: data => {
      enqueueSnackbar('Participation tier updated! Your progress has been saved.', {
        variant: 'success',
        autoHideDuration: 4500
      })
      updateUser(8)
    },
    refetchQueries: [{ query: GET_ORG, variables: { id: orgId } }]
  })

  const [formState, { radio }] = useFormState()

  function handleSave() {
    handleSelectTier({
      id: orgId,
      values: { tier: formState.values.membershipTier }
    })
  }

  function renderFlexRow(id, name) {
    return (
      <div key={name} className={classes.flexRow}>
        <FormControlLabel
          classes={{ label: classes.bold }}
          value={id}
          control={<Radio />}
          label={name.split(' ')[0]}
        />
      </div>
    )
  }

  if (queryLoading) {
    return null
  }

  return (
    <form>
      <div className={classes.header}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Participation Tier
        </Typography>
        <Typography className={classes.subHeading}>Select your participation tier.</Typography>
        <LinkField
          className={classes.link}
          color="primary"
          label="Review tier benefits"
          target="_blank"
          rel="noopener noreferrer"
          to="https://telecominfraproject.com/apply-for-membership/"
        />
      </div>
      <Grid
        className={clsx(formClasses.spacingBottom, classes.radioContainer)}
        container
        spacing={2}
      >
        <RadioGroup {...radio('membershipTier')} value={formState.values.membershipTier}>
          {data.membershipLevels.map(m => renderFlexRow(m.id, m.name))}
        </RadioGroup>
      </Grid>

      <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
        <RoundedButton
          onClick={handleSave}
          disabled={formState.values.membershipTier ? false : true}
          fullWidth
          size="medium"
          color="primary"
        >
          {loading ? (
            <div className={formClasses.progress}>
              <Progress size={20} />
            </div>
          ) : (
            'Next: Participation Agreement'
          )}
        </RoundedButton>
        <FormHelperText className={classes.helperText}>Your progress will be saved</FormHelperText>
      </div>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  buttonContainer: {
    flexDirection: 'column'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  helperText: {
    marginLeft: 10
  },
  link: {
    fontWeight: 'bold',
    marginBottom: 15
  },
  radioContainer: {
    padding: 10
  },
  subHeading: {
    marginBottom: 10
  },
  tooltipOpen: {
    color: theme.palette.linkHover.main,
    height: 20,
    width: 20,
    cursor: 'pointer'
  },
  tooltipClosed: {
    color: theme.palette.secondary.main,
    height: 20,
    width: 20,
    cursor: 'pointer'
  }
}))
