import {
  InputTextFieldNew,
  CountryDropdown,
  PlacesAutocomplete,
  PhoneNumberInput,
  CheckboxDropdown
} from '../common'
import { inputRegexes } from '../utils'

export const organizationProfileFields = (formState = { values: {} }) => ({
  organizationType: {
    InputComponent: CheckboxDropdown,
    inputType: 'checkbox',
    label: 'Type of organization',
    name: 'organizationType',
    options: [
      'Academic Institution',
      'Alliance or Association',
      'Consultancy',
      'Enterprise',
      'Government Agency',
      'Hardware Vendor',
      'Industry Analyst',
      'Infrastructure Provider or Owner',
      'Non-Government Agency',
      'Press',
      'Research and Development',
      'Reseller or Technology Provider',
      'Semiconductor Vendor',
      'Software or App Development',
      'System Integrator',
      'Telecom or Network Operator',
      'Venture Capital / Investment',
      'Webscale',
      'Other'
    ],
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  serviceOfferings: {
    InputComponent: CheckboxDropdown,
    inputType: 'checkbox',
    label: 'Service offerings',
    name: 'serviceOfferings',
    options: [
      'Automation',
      'Chip Manufacturing',
      'Computer Component Manufacturing',
      'Consulting Services',
      'Fiber Provider',
      'Hardware Design',
      'Installation Services',
      'Membership Services (e.g. Events, Convening, Reports)',
      'Mobile Device Manufacturing',
      'Network Management & Operations',
      'Policy Development / Advocacy',
      'Professional Services / Project Management',
      'Routers',
      'SIP Trunking',
      'Software Development',
      'Switches',
      'Test & Compliance',
      'VoIP'
    ],
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  operatingRegions: {
    InputComponent: CheckboxDropdown,
    inputType: 'checkbox',
    label: 'Operating regions',
    name: 'operatingRegions',
    options: [
      'Africa',
      'APAC - Australia and New Zealand',
      'APAC - North Asia',
      'APAC - South and Southeast Asia',
      'Europe',
      'LATAM',
      'Middle East',
      'North America'
    ],
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  address1: {
    autoComplete: 'street-address',
    currentCity: formState.values.city,
    currentCountry: formState.values.country,
    currentPostalCode: formState.values.postalCode,
    currentState: formState.values.state,
    includePhone: true,
    InputComponent: PlacesAutocomplete,
    inputType: 'text',
    label: 'Headquarters address',
    name: 'address1',
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  address2: {
    autoComplete: 'address-level2',
    label: 'Apartment / Suite # (optional)',
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    name: 'address2',
    validator: {}
  },
  country: {
    autoComplete: 'country',
    includePhone: true,
    InputComponent: CountryDropdown,
    label: 'Country',
    name: 'country',
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  postalCode: {
    autoComplete: 'postal-code',
    gridWidth: { xs: 12, sm: 6 },
    label: 'ZIP / Postal code',
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    name: 'postalCode',
    validator: {
      required: true
    }
  },
  state: {
    gridWidth: { xs: 12, sm: 6 },
    InputComponent: InputTextFieldNew,
    name: 'state',
    label: 'State / Province',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  city: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'City',
    name: 'city',
    validator: {
      required: true
    }
  },
  organizationPhone: {
    currentCountry: formState.values.country,
    InputComponent: PhoneNumberInput,
    inputType: 'text',
    label: 'Organization contact phone #',
    name: 'organizationPhone',
    setField: formState.setField,
    setFieldError: formState.setFieldError,
    validator: {
      required: true,
      regex: inputRegexes.phone,
      regexMessage: 'Must be a valid Phone Number'
    }
  },
  organizationWebsite: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Organization website',
    name: 'organizationWebsite',
    validator: {
      required: true,
      maxLength: 50,
      regex: inputRegexes.domain,
      regexMessage: 'Must be a valid domain'
    }
  }
})
