import React from 'react'
import { Divider, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useFormStyles } from '../hooks'

const Success = () => {
  const formClasses = useFormStyles()
  const classes = useStyles()

  return (
    <>
      <Typography className={clsx(formClasses.heading, classes.heading)} variant="h3">
        Thank You!
      </Typography>
      <Typography className={classes.content}>
        Thank you for making a payment. Confirmation has been sent to the invoice email.
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.footer}>
        Your organization's TIP participation will continue until your next renewal date.
      </Typography>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    fontSize: 18
  },
  divider: {
    margin: '32px 0'
  },
  footer: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  heading: {
    fontSize: '2.25rem',
    marginBottom: 32
  }
}))

export default Success
