import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'

import { useQuery } from '../hooks'
import { useSession, useUserOrganization } from '../auth/queries'
import { systemAdminToolbarHeight } from '.'
import useImpersonation from '../Organization/Impersonation'
import { GET_ORG } from '../User/Profile/queries'

export default function ImpersonationToolbar() {
  const classes = useStyles()
  const { clearImpersonation } = useImpersonation()

  const { loading, isImpersonating, session, userId, isOrgAdmin } = useSession()

  const { organization } = useUserOrganization({
    variables: { id: userId },
    skip: !userId || !isOrgAdmin
  })

  const { orgId } = organization

  // get user org info (name)
  const { data: { organization: { name: orgName } = {} } = {} } = useQuery(GET_ORG, {
    variables: { id: orgId },
    skip: !orgId || !isOrgAdmin
  })

  if (loading || !isImpersonating) {
    return null
  }

  const { firstname, lastname, email } = session ? session.user : {}

  return (
    <Toolbar
      data-testid="ImpersonationToolbar"
      classes={{ root: classes.navContainerRootImpersonation }}
    >
      <div className={clsx(classes.grow, classes.impersonationNav)}>
        <div>
          <Hidden className={classes.desktop} only={['xs', 'sm']}>
            <Typography className={classes.label}>
              Managing user: {firstname} {lastname} - {email} {orgName && ` -- Admin of ${orgName}`}
            </Typography>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Typography className={classes.label}>
              <Typography className={classes.label}>Managing: {email}</Typography>
            </Typography>
          </Hidden>
        </div>
        <div className={classes.exitButtonContainer}>
          <Hidden className={classes.desktop} only={['xs', 'sm']}>
            <Button onClick={() => clearImpersonation(true)} classes={{ root: classes.exitRoot }}>
              Exit Management
            </Button>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Button onClick={() => clearImpersonation(true)} classes={{ root: classes.exitRoot }}>
              Exit
            </Button>
          </Hidden>
        </div>
      </div>
    </Toolbar>
  )
}

export const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  label: {
    marginLeft: 20,
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      marginLeft: 5
    }
  },
  exitRoot: {
    backgroundColor: theme.palette.borderGray.main
  },
  exitButtonContainer: {
    textAlign: 'right',
    flex: 1
  },
  navContainerRootImpersonation: {
    borderTop: '2px solid ' + theme.palette.gray2.main,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    minHeight: systemAdminToolbarHeight,
    height: systemAdminToolbarHeight
  },
  impersonationNav: {
    backgroundColor: theme.palette.gray2.main,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}))
