import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'

export default function DeleteModal({ handleDeleteUser, open, setOpen }) {
  const [confirm, setConfirm] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function updateConfirmText(e) {
    if (e.target.value === 'DELETE') {
      setConfirm(true)
    } else {
      setConfirm(false)
    }
  }

  function handleClose() {
    setOpen(false)
  }

  const inputProps = { style: { height: '40px', padding: '0 10px', fontWeight: 600 } }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.header} id="responsive-dialog-title">
          Are you sure?
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentText}>
            <WarningIcon className={classes.warningIcon} />
            <div>
              <Typography className={classes.bold}>Please confirm this action</Typography>
              <Typography className={classes.warningText}>
                Deleting the user will remove access to all the associated integrated tools. Are you
                sure you want to proceed?
              </Typography>
            </div>
          </div>
        </DialogContent>
        <Typography className={classes.leaveMessage}>Type the word DELETE to confirm</Typography>
        <DialogActions className={classes.actions}>
          <TextField
            className={classes.leaveInput}
            margin="none"
            inputProps={inputProps}
            color="secondary"
            variant="outlined"
            onChange={updateConfirmText}
          />
          <Button
            onClick={() => {
              handleDeleteUser()
              handleClose()
            }}
            variant="outlined"
            className={classes.buttonAlt}
            disabled={!confirm}
            classes={{ outlined: classes.outlined }}
          >
            Delete
          </Button>
          <Button
            fullWidth={false}
            onClick={handleClose}
            className={classes.button}
            classes={{ root: classes.root }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: 20,
    marginRight: 20,
    paddingLeft: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 30
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },

  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.red.main
    }
  },
  buttonAlt: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  buttonOrgAdmin: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.red.main
    }
  },
  buttonOrgAdminAlt: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    height: 40
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  header: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  leaveButton: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    }
  },
  leaveInput: {
    marginRight: 'auto'
  },
  leaveMessage: {
    paddingLeft: 90,
    fontWeight: 600
  },
  outlined: {
    color: theme.palette.red.main,
    border: `thin solid ${theme.palette.red.main}`
  },
  progress: {
    color: theme.palette.red.main
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.red.main
  },
  selectionButton: {
    width: 200
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  warningText: {
    marginBottom: 5
  }
}))
