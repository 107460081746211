import gql from 'graphql-tag'

export const UPLOAD_FILE = gql`
  mutation uploadFile($input: UploadInput!) {
    uploadFile(input: $input) {
      name
      id
    }
  }
`

export const REMOVE_FILE = gql`
  mutation removeFile($input: RemoveInput!) {
    removeFile(input: $input) {
      id
    }
  }
`
