import React, { useState, useEffect } from 'react'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { useSession } from '../auth/queries'

import Progress from '../common/Progress'
import { InputTextFieldNew } from '../common'
import RoundedButton from '../common/RoundedButton'
import { inputRegexes, isFormSubmitDisabled } from '../utils'
import { useFormStyles, useMutation } from '../hooks'
import { TooltipHelper } from './GetStartedInfo'

import { GET_ORG } from '../Organization/Profile/queries'
import { ADD_BILLING_CONTACT } from '../Organization/Profile/mutations'

export default function BillingInformation({ updateUser }) {
  const { enqueueSnackbar } = useSnackbar()
  const formClasses = useFormStyles()
  const classes = useStyles()
  const [billingSaved, setBillingSaved] = useState(false)

  const { isOrgAdmin, session } = useSession()

  let orgId
  if (isOrgAdmin) {
    orgId = isOrgAdmin.scopes[0].split(':')[1]
  }

  const { loading, execute: handleAddContact } = useMutation(ADD_BILLING_CONTACT, {
    onCompleted: data => {
      enqueueSnackbar('Billing information updated! Your progress has been saved.', {
        variant: 'success',
        autoHideDuration: 4500
      })
      data.addBillingContact.user
        ? data.addBillingContact.user.email && updateUser(7)
        : data.addBillingContact.email && updateUser(7)
    },
    refetchQueries: [{ query: GET_ORG, variables: { id: orgId } }]
  })

  const billingInformationFields = {
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: ''
  }

  const [formState, { radio, text, email }] = useFormState(billingInformationFields)

  const billingInformationInputs = {
    firstName: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12, sm: 6 },
      name: 'firstName',
      label: 'First name',
      inputType: text,
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    lastName: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12, sm: 6 },
      name: 'lastName',
      label: 'Last name',
      inputType: text,
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    email: {
      InputComponent: InputTextFieldNew,
      name: 'email',
      label: 'Email',
      inputType: email,
      validateOnBlur: false,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      }
    },
    jobTitle: {
      InputComponent: InputTextFieldNew,
      name: 'jobTitle',
      label: 'Work title',
      inputType: text,
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    }
  }

  function clearBillingFields() {
    for (let input in billingInformationInputs) {
      formState.clearField(billingInformationInputs[input].name)
    }
    setBillingSaved(false)
  }

  function handleSave() {
    let values = {}
    if (formState.values.billingContact === 'yes') {
      values = { userId: session.user.id }
    } else {
      values = {
        firstname: formState.values.firstName,
        lastname: formState.values.lastName,
        email: formState.values.email,
        jobTitle: formState.values.jobTitle
      }
    }

    handleAddContact({
      id: orgId,
      values: values
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const disableSubmit = isFormSubmitDisabled(billingInformationInputs, formState)

  return (
    <form>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Billing Information
        </Typography>
        <Typography className={classes.subHeading}>
          Please provide the contact information of the organization Billing{' '}
          <span className={classes.noWrap}>
            Contact
            <TooltipHelper
              iconClass={classes.tooltipIcon}
              label=""
              title={
                'The Billing Contact is an individual employed by Applicant, who will receive the invoices sent by TIP with the Applicant’s Annual Dues 60 days prior to the date on which payment is due. If no individual is specified, invoices will be submitted to Applicant’s Account Administrator.'
              }
            />
          </span>
        </Typography>
      </div>
      <Grid
        className={clsx(formClasses.spacingBottom, classes.radioContainer)}
        container
        spacing={2}
      >
        <RadioGroup {...radio('billingContact')} value={formState.values.billingContact}>
          <Typography variant="body2" className={classes.radioHeader}>
            Are you the organization Billing Contact?
          </Typography>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>

      {formState.values.billingContact === 'no' ? (
        <Grid
          className={clsx(formClasses.spacingBottom, classes.billingContactForm)}
          container
          spacing={2}
        >
          {billingSaved ? (
            <div className={classes.billingContactOverviewContainer}>
              <div className={classes.billingContactOverviewInfo}>
                <Typography variant="body1" className={classes.overviewName}>
                  {formState.values.firstName + ' ' + formState.values.lastName}
                </Typography>
                <Typography variant="body1">{formState.values.email}</Typography>
                <Typography variant="body1">{formState.values.jobTitle}</Typography>
              </div>
              <div className={classes.billingContactOverviewDelete}>
                <RoundedButton
                  color="primary"
                  variant="outlined"
                  fullWidth={false}
                  onClick={() => setBillingSaved(false)}
                >
                  Edit
                </RoundedButton>
              </div>
            </div>
          ) : (
            <>
              <Typography variant="body1" className={classes.billingContactFormHeader}>
                Enter the organization Billing Contact information.
              </Typography>
              {Object.entries(billingInformationInputs).map(
                ([name, { gridWidth = { xs: 12 }, InputComponent, inputType, ...args }]) => (
                  <Grid key={name} item {...gridWidth}>
                    <InputComponent
                      key={name}
                      inputType={inputType}
                      {...args}
                      error={
                        typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                      }
                      errorHelperText={formState.errors[name]}
                      inputProps={{
                        style: { background: '#FFFFFF' }
                      }}
                      value={formState.values[name]}
                    />
                  </Grid>
                )
              )}
              {!disableSubmit && (
                <Grid className={classes.billingContactButtonContainer} item xs={12}>
                  <RoundedButton
                    className={classes.cancelButton}
                    color="primary"
                    variant="outlined"
                    fullWidth={false}
                    onClick={clearBillingFields}
                  >
                    Cancel
                  </RoundedButton>
                  <RoundedButton
                    color="primary"
                    fullWidth={false}
                    onClick={() => setBillingSaved(true)}
                  >
                    Save
                  </RoundedButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      ) : null}

      {(billingSaved && formState.values.billingContact === 'no') ||
      formState.values.billingContact === 'yes' ? (
        <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
          <RoundedButton
            onClick={handleSave}
            disabled={formState.values.billingContact === 'yes' ? false : disableSubmit}
            fullWidth
            size="medium"
            color="primary"
          >
            {loading ? (
              <div className={formClasses.progress}>
                <Progress size={20} />
              </div>
            ) : (
              'Next: Pick Participation Tier'
            )}
          </RoundedButton>
          <FormHelperText className={classes.helperText}>
            Your progress will be saved
          </FormHelperText>
        </div>
      ) : null}
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  subHeading: {
    marginBottom: 15,
    textAlign: 'center'
  },
  buttonContainer: {
    flexDirection: 'column'
  },
  helperText: {
    marginLeft: 10
  },
  radioHeader: {
    fontWeight: 'bold'
  },
  billingContactForm: {
    background: theme.palette.gray10.main,
    border: `1px solid ${theme.palette.gray11.main}`,
    borderRadius: 3,
    padding: '5px 10px'
  },
  billingContactFormHeader: {
    padding: 8
  },
  billingContactOverviewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  billingContactButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  billingContactOverviewInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 5,
    position: 'relative'
  },
  cancelButton: {
    marginRight: '10px'
  },
  overviewName: {
    fontWeight: 'bold'
  },
  billingContactOverviewDelete: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    paddingRight: 10
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  radioContainer: {
    padding: 10
  },
  tooltipIcon: {
    color: theme.palette.primary.main
  }
}))
