import React, { useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import clsx from 'clsx'

import { ErrorSection, LinkField, Progress, RoundedButton } from '../common'
import { useFormStyles, useQuery, useMutation } from '../hooks'
import { useSession } from '../auth/queries'

import { RecipientInfo, StatusSection } from '../Organization/Agreements/StatusSections'
import { GET_ORG } from '../Organization/Profile/queries'
import { GET_ORGANIZATION_GPA_DATA } from '../Organization/Agreements/queries'
import { REMOVE_AUTHORIZED_SIGNER } from './mutations'

import { SEND_PARTICIPATION_AGREEMENT_FOR_SIGNATURE } from '../Organization/Agreements/mutations'

export default function ReviewAndSendAgreement({ updateUser }) {
  const formClasses = useFormStyles()
  const classes = useStyles()

  const { isOrgAdmin } = useSession()
  const [updateUserReturnTarget, setUpdateUserReturnTarget] = useState(8)

  let orgId
  if (isOrgAdmin) {
    orgId = isOrgAdmin.scopes[0].split(':')[1]
  }

  const refetchQueries = [
    {
      query: GET_ORGANIZATION_GPA_DATA,
      variables: { id: orgId }
    }
  ]

  const {
    data: { organization },
    loading: organizationQueryLoading
  } = useQuery(GET_ORG, { variables: { id: orgId } })

  const {
    data: { requestParticipationAgreementSignature = {} } = {},
    error,
    execute: sendGPA,
    loading: sendGpaLoading
  } = useMutation(SEND_PARTICIPATION_AGREEMENT_FOR_SIGNATURE, {
    onCompleted: data => data.requestParticipationAgreementSignature.success && updateUser(10),
    refetchQueries: refetchQueries
  })

  const { execute: removeSigner } = useMutation(REMOVE_AUTHORIZED_SIGNER, {
    onCompleted: ({ removeAuthorizedSigner }) =>
      removeAuthorizedSigner.result
        ? updateUser(updateUserReturnTarget)
        : console.log(removeAuthorizedSigner),
    refetchQueries: [{ query: GET_ORG, variables: { id: orgId } }]
  })

  const signer = organization ? organization.organizationRoles.authorizedSigners[0] : null

  let emailToSend = ''

  if (signer) {
    if (signer.user) {
      emailToSend = signer.user.email
    } else {
      emailToSend = signer.email
    }
  }

  function handleSendSignatureRequest() {
    if (signer.user) {
      sendGPA({
        values: { organizationId: orgId, userId: signer.user.id }
      })
    } else {
      sendGPA({
        values: { organizationId: orgId, email: emailToSend }
      })
    }
  }

  if (!organization || organizationQueryLoading) {
    return null
  }

  return (
    <form>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Review and Send participation agreement
        </Typography>
        <Typography className={classes.subHeading}>
          Please send the{' '}
          <LinkField
            to={
              'https://telecominfraproject.com/wp-content/uploads/TIP_PA-July2018-Aug20-clean-Final.pdf'
            }
            hideIcon={true}
            label="General Participation Agreement"
            target="_blank"
            rel="noopener noreferrer"
          />{' '}
          to the Authorized Representative for signature. Signing the GPA will allow your
          organization to participate in TIP. Without this signed agreement your organization cannot
          participate in TIP.
        </Typography>
        {error && error.message.includes('Missing Required Fields') && (
          <ErrorSection>
            You're missing some required details to send the <u>General Participation Agreement</u>.
            <br />
            Please complete your own or your organization's profile information.
            <div className={classes.summaryButtonWrapper}>
              <RoundedButton
                classes={{ root: classes.root }}
                color="secondary"
                fullWidth={false}
                onClick={() => {
                  setUpdateUserReturnTarget(5)
                  removeSigner({
                    id: orgId,
                    values: signer.user
                      ? {
                          userId: signer.user.userId
                        }
                      : {
                          email: signer.email,
                          firstname: signer.firstname,
                          lastname: signer.lastname,
                          jobTitle: signer.jobTitle
                        }
                  })
                }}
              >
                Return to Profile Summary
              </RoundedButton>
            </div>
          </ErrorSection>
        )}
      </div>

      <StatusSection
        hideBorder
        colors={['gray']}
        icons={['mail']}
        titles={['Recipient info']}
        Sections={[RecipientInfo]}
        email={emailToSend}
      />

      <div className={classes.linkContainer}>
        <SettingsBackupRestoreIcon className={classes.revertIcon} />
        <LinkField
          className={classes.linkStyle}
          to={'#'}
          label="Go back and designate a different Authorized Representative"
          onClick={() => {
            setUpdateUserReturnTarget(8)
            removeSigner({
              id: orgId,
              values: signer.user
                ? {
                    userId: signer.user.userId
                  }
                : {
                    email: signer.email,
                    firstname: signer.firstname,
                    lastname: signer.lastname,
                    jobTitle: signer.jobTitle
                  }
            })
          }}
        />
      </div>
      <div className={classes.linkContainer}>
        <OpenInNewIcon className={classes.revertIcon} />
        <LinkField
          className={classes.linkStyle}
          to={'/documents'}
          hideIcon={true}
          label="Review TIP Organizational documents"
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>

      <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
        <RoundedButton
          onClick={handleSendSignatureRequest}
          disabled={sendGpaLoading || requestParticipationAgreementSignature.success}
          size="medium"
          data-testid="ReviewAndSubmitBtn"
          fullWidth
          type="button"
        >
          {sendGpaLoading ? (
            <div className={formClasses.progress}>
              <Progress size={20} />
            </div>
          ) : (
            'Send Participation Agreement'
          )}
        </RoundedButton>
      </div>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  header: {
    marginBottom: 20
  },
  subHeading: {
    marginBottom: 5,
    textAlign: 'left'
  },
  buttonContainer: {
    flexDirection: 'column'
  },
  linkContainer: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 25
  },
  linkStyle: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textDecoration: 'none',
    borderBottom: `1px solid #d2d2d2`,
    textAlign: 'left'
  },
  revertIcon: {
    color: theme.palette.secondary.main,
    marginRight: 5
  },
  summaryButtonWrapper: {
    marginTop: theme.spacing(1)
  },
  root: {
    backgroundColor: theme.palette.red.main,
    color: 'white',
    '&:hover': {
      backgroundColor: '#d95945'
    }
  }
}))
