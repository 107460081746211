import React from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

export default function BorderLinearProgress({ value, ...rest }) {
  const classes = useStyles()

  return (
    <LinearProgress
      variant="determinate"
      color="secondary"
      value={value}
      classes={{ root: classes.root, bar: classes.bar }}
      {...rest}
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 10,
    backgroundColor: lighten('#9bc807', 0.5),
    borderRadius: 20
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#9bc807'
  }
}))
