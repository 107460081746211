import React, { useEffect } from 'react'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'
import { FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import clsx from 'clsx'

import RoundedButton from '../common/RoundedButton'
import Progress from '../common/Progress'
import { isFormSubmitDisabled } from '../utils'
import { useMutation, useFormStyles } from '../hooks'
import { organizationProfileFields } from './OrganizationProfileFields'
// import formFields from '../Organization/Profile/FormFields'
import { EDIT_ORGANIZATION_PROFILE } from './mutations'
import { GET_ORGANIZATION_DATA } from './queries'

const organizationProfile = {
  organizationType: [],
  serviceOfferings: [],
  operatingRegions: [],
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  organizationPhone: '',
  organizationWebsite: ''
}

export default function CreateOrgProfile({ org = {}, updateUser }) {
  const { enqueueSnackbar } = useSnackbar()
  const formClasses = useFormStyles()
  const classes = useStyles()

  const [formState, inputTypes] = useFormState(organizationProfile)

  const {
    loading,
    execute: handleSubmit,
    data: { editOrganizationProfile = {} } = {}
  } = useMutation(EDIT_ORGANIZATION_PROFILE, {
    refetchQueries: [{ query: GET_ORGANIZATION_DATA, variables: { id: org.id } }],
    onCompleted: data => data.editOrganizationProfile.id && updateUser(5)
  })

  useEffect(() => {
    if (editOrganizationProfile.id) {
      enqueueSnackbar('Organization profile information stored. Your progress has been saved.', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [enqueueSnackbar, editOrganizationProfile])

  let disableSubmit = isFormSubmitDisabled(organizationProfileFields(), formState, true)

  return (
    <form>
      <div className={classes.header}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          <span className={classes.orgIconWrapper}>
            <BusinessIcon className={classes.orgIcon} fontSize="large" />
          </span>{' '}
          Organization Profile
        </Typography>
        <Typography className={classes.subHeading}>
          Enter profile information for your organization.
        </Typography>
        <Typography className={classes.caption} variant="caption">
          Organization name: {org.name}
          {org.affiliateOf && (
            <>
              <br />
              Affiliate of: {org.affiliateOf}
            </>
          )}
        </Typography>
      </div>
      <Grid className={formClasses.spacingBottom} container spacing={2}>
        {Object.entries(organizationProfileFields(formState)).map(
          ([
            name,
            { gridWidth = { xs: 12 }, InputComponent, inputType, manualChange, ...args }
          ]) => (
            <Grid key={name} item {...gridWidth}>
              <InputComponent
                {...args}
                inputType={inputTypes[inputType]}
                error={typeof formState.validity[name] !== 'undefined' && !formState.validity[name]}
                errorHelperText={formState.errors[name]}
                value={formState.values[name]}
              />
            </Grid>
          )
        )}
      </Grid>
      <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
        <RoundedButton
          onClick={e => {
            e.preventDefault()
            handleSubmit({
              id: org.id,
              values: {
                shippingAddress: {
                  address1: formState.values.address1,
                  address2: formState.values.address2,
                  city: formState.values.city,
                  state: formState.values.state,
                  country: formState.values.country,
                  postalCode: formState.values.postalCode
                },
                type: formState.values.organizationType,
                serviceOfferings: formState.values.serviceOfferings,
                operatingRegions: formState.values.operatingRegions,
                website: formState.values.organizationWebsite,
                phoneNumber: formState.values.organizationPhone
              }
            })
          }}
          size="medium"
          disabled={disableSubmit}
          data-testid="CreateOrgProfileBtn"
          fullWidth
          type="button"
        >
          {loading ? (
            <div className={formClasses.progress}>
              <Progress size={20} />
            </div>
          ) : (
            'Next: Review Summary'
          )}
        </RoundedButton>
        <FormHelperText className={classes.helperText}>Your progress will be saved</FormHelperText>
      </div>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    flexDirection: 'column'
  },
  caption: {
    fontWeight: 'bold'
  },
  helperText: {
    marginLeft: 10
  },
  orgIcon: {
    fontSize: '1.625rem',
    verticalAlign: 'top'
  },
  orgIconWrapper: {
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    height: 36,
    padding: 4,
    verticalAlign: 'bottom',
    width: 36
  },
  subHeading: {
    marginBottom: 16
  }
}))
