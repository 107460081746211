import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import { LinkField, InputTextFieldBak, Progress, RoundedButton } from '../common'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes } from '../utils'
import { pushVirtualPageView } from '../utils/googleAnalytics'
import { SUPPORT_EMAIL } from '../utils/constants'
import { useGetAllBlockedDomains } from '../utils/getBlockedDomains'
import isEmailBlacklisted from '../utils/emailBlacklist'

export const REGISTER_USER = gql`
  mutation($input: UserRegisterInput!) {
    register(input: $input) {
      data
      result
    }
  }
`

export const CHECK_USER_EMAIL = gql`
  mutation($input: CheckUserEmailInput!) {
    checkUserEmail(input: $input) {
      result
    }
  }
`

export default function GetStarted({ setEmail }) {
  const [formState, { email }] = useFormState()
  const { loading, execute: handleContinue, data: registerUserResult } = useMutation(REGISTER_USER)
  const { emailCheckLoading, execute: handleEmailCheck } = useMutation(CHECK_USER_EMAIL)
  const [blacklistError, setBlacklistError] = useState(null)
  const [emailFocus, setEmailFocus] = useState(true)
  const [confirmEmailFocus, setConfirmEmailFocus] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showConfirmError, setShowConfirmError] = useState(false)
  const [emailPresentError, setEmailPresentError] = useState(false)
  const formClasses = useFormStyles()
  const classes = useStyles()

  const { blockedDomainsLoading, blockedDomains } = useGetAllBlockedDomains()

  useEffect(() => {
    if (
      formState.values.email &&
      !blockedDomainsLoading &&
      blockedDomains.hasOwnProperty('allBlockedDomains')
    ) {
      const isBlacklisted = isEmailBlacklisted(formState.values.email, blockedDomains.allBlockedDomains)
      if (isBlacklisted) {
        setBlacklistError("Please use your organization's email domain.")
      } else {
        setBlacklistError(null)
      }
    }
  }, [formState.values.email, blockedDomainsLoading, blockedDomains])

  useEffect(() => {
    if (
      !loading &&
      registerUserResult &&
      registerUserResult.register &&
      registerUserResult.register.result
    ) {
      setEmail(registerUserResult.register.data)
    }
  }, [registerUserResult, loading, setEmail])

  function handleSubmit(e) {
    e.preventDefault()
    handleEmailCheck({ values: { email: formState.values.email.toLowerCase() } }).then(data => {
      const present = data.data.checkUserEmail.result

      if (present) {
        setEmailPresentError(true)
      } else {
        setEmailPresentError(false)
        // virtual page view for action that does not change the url
        pushVirtualPageView('/vpv/get-started/email-verification')

        handleContinue({ values: { email: formState.values.email.toLowerCase() } })
      }
    })
  }

  useEffect(() => {
    // Manually update confirmEmail validation when changing email
    if (showConfirmError) {
      const confirm = formState.values.confirmEmail.toLowerCase()
      const email = formState.values.email.toLowerCase()

      if (confirm !== email && formState.errors.confirmEmail !== 'Emails must match') {
        formState.setFieldError('confirmEmail', 'Emails must match')
      } else if (confirm === email && formState.errors.confirmEmail !== '') {
        formState.setFieldError('confirmEmail', '')
      }
    }
  }, [formState, formState.errors.confirmEmail, formState.values, showConfirmError])

  // if error invalid show it, otherwise show if blacklisted
  const formError = blacklistError || formState.errors.email
  const confirmEmailError = formState.errors.confirmEmail
  const errorMessage = formError ? (
    blacklistError ? (
      <>
        <span>{blacklistError}</span>{' '}
        <a href="mailto:support@telecominfraproject.com">Click here to Contact Support</a>
      </>
    ) : (
      formState.errors.email
    )
  ) : null

  const confirmEmailErrorMessage = confirmEmailError ? formState.errors.confirmEmail : null

  const inputs = {
    email: {
      autoFocus: true,
      error: showEmailError ? !!formError : false,
      gridWidth: { xs: 12 },
      helperText:
        (showEmailError && errorMessage) || (emailFocus && 'Your company or organization email'),
      inputType: email,
      label: 'Work Email',
      name: 'email',
      validateOnBlur: false,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      },
      onBlur: () => {
        setEmailFocus(false)
        setShowEmailError(formState.touched.email)
      },
      onFocus: () => setEmailFocus(true)
    },
    confirmEmail: {
      id: 'ConfirmEmail',
      error: showConfirmError ? !!confirmEmailError : false,
      gridWidth: { xs: 12 },
      helperText:
        (showConfirmError && confirmEmailErrorMessage) ||
        (confirmEmailFocus && 'We will send a verification email to this address'),
      inputType: email,
      label: 'Confirm Work Email',
      name: 'confirmEmail',
      validateOnBlur: false,
      validator: {
        required: true,
        requiredMatch: true,
        mustMatchKey: 'email',
        mustMatchMessage: 'Emails must match',
        caseInsensitive: true
      },
      onBlur: () => {
        setConfirmEmailFocus(false)
        setShowConfirmError(formState.touched.confirmEmail)
      },
      onFocus: () => setConfirmEmailFocus(true),
      onPaste: e => {
        e.preventDefault()
      }
    }
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  return (
    <form onSubmit={handleSubmit}>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h4" className={classes.title}>
          Let's get started
        </Typography>
        {emailCheckLoading && <Progress size={30} />}
        {emailPresentError && (
          <Typography variant="body1" className={classes.error}>
            Something went wrong. Questions?{' '}
            <LinkField
              color="primary"
              label={'Contact TIP Support'}
              to={`mailto:${SUPPORT_EMAIL}`}
              hideIcon={true}
            />
            .
          </Typography>
        )}
      </div>
      <Grid className={formClasses.spacingBottom} container spacing={2}>
        {Object.entries(inputs).map(([name, args]) => (
          <Grid key={name} item {...args.gridWidth}>
            <InputTextFieldBak
              key={name}
              className={classes.textField}
              FormHelperTextProps={{
                classes: {
                  root: classes.formHelperTextRoot
                }
              }}
              {...args}
            />
          </Grid>
        ))}
      </Grid>
      <div className={formClasses.buttonContainer}>
        {loading ? (
          <div className={formClasses.progress}>
            <Progress size={30} />
          </div>
        ) : (
          <RoundedButton
            data-testid="SignUpSubmitBtn"
            disabled={disableSubmit || blacklistError}
            size="medium"
            type="submit"
            onClick={handleSubmit}
          >
            NEXT: VERIFY EMAIL
          </RoundedButton>
        )}
      </div>
      <div className={clsx(classes.loginLinkContainer, formClasses.buttonContainer)}>
        <LinkField to={'/login'} label="Already a TIP participant? Sign In" />
      </div>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  formHelperTextRoot: {
    position: 'absolute',
    top: '100%'
  },
  header: {
    marginBottom: 40
  },
  textField: {
    marginBottom: theme.spacing(3)
  },
  tipMemberMessage: {
    marginTop: 20
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4)
  },
  loginLinkContainer: {
    justifyContent: 'flex-end'
  },
  error: {
    color: theme.palette.red.main,
    fontSize: 14
  }
}))
