import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

import ProfileCompleteness from '../../common/ProfileCompleteness'
import EditOrganizationModal from '../../Organization/Profile'
import LogoInput from '../../Logo'

const styles = makeStyles(theme => ({
  profileMeta: {
    display: 'block',
    fontSize: '1rem'
  },
  subMeta: {
    fontWeight: 100,
    fontSize: '.9rem'
  },
  margin: {
    margin: theme.spacing(1)
  },
  button: {
    color: '#317e88',
    cursor: 'pointer',
    display: 'inline-block',
    width: '100px'
  },
  percentageComplete: {
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  },
  organizationName: {
    cursor: 'pointer'
  }
}))

const Header = ({
  type,
  parentId,
  logo,
  firstname,
  lastname,
  percentageComplete,
  jobTitle,
  orgName,
  isSystemAdmin,
  isReadOnly = false,
  orgId,
  onUpdate
}) => {
  const classes = styles()

  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={6}>
        <LogoInput
          changeText={logo ? 'Change Profile Photo' : 'Add Profile Photo'}
          removeText="Remove Photo"
          onUpdate={onUpdate}
          logo={logo}
          type={type}
          parentId={parentId}
          isReadOnly={isReadOnly}
        >
          <Typography className={classes.profileMeta} variant="h6">
            {firstname} {lastname}
          </Typography>
          {jobTitle && (
            <Typography className={clsx(classes.profileMeta, classes.subMeta)}>
              {jobTitle}
            </Typography>
          )}
          {orgName && (
            <Typography className={clsx(classes.profileMeta, classes.subMeta)}>
              {isSystemAdmin ? (
                <EditOrganizationModal returnUrl={'/admin/manage-members'} id={orgId}>
                  {onClick => (
                    <span onClick={onClick} className={classes.organizationName}>
                      {orgName}
                    </span>
                  )}
                </EditOrganizationModal>
              ) : (
                orgName
              )}
            </Typography>
          )}
        </LogoInput>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.percentageComplete}>
          {percentageComplete && <ProfileCompleteness percentageComplete={percentageComplete} />}
        </div>
      </Grid>
    </Grid>
  )
}

export default Header
