import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

export default function SomethingWentWrongModal({ open, setOpen }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Sorry
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <WarningIcon className={classes.warningIcon} />
          <div>
            <Typography className={classes.warningText}>
              Something went wrong, please try again
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          fullWidth={false}
          onClick={handleClose}
          className={classes.button}
          classes={{ root: classes.root }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  selectionButton: {
    width: 200
  },
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.red.main
  },
  outlined: {
    color: theme.palette.red.main,
    border: `thin solid ${theme.palette.red.main}`
  },
  header: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20,
    paddingLeft: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 30
    }
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  warningText: {
    marginBottom: 5
  }
}))
