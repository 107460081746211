import gql from 'graphql-tag'

export const GET_ORGANIZATION_OFFERING_DATA = gql`
  query getOrganizationOfferings($id: ID!) {
    organization(id: $id) {
      id
      name
      offerings {
        id
        name
      }
    }
  }
`

export const GET_ALL_OFFERINGS_DATA = gql`
  query {
    offerings(showAll: true) {
      id
      name
      organizationId
      organization {
        id
        name
      }
    }
  }
`

export const GET_OFFERING_DATA = gql`
  query getOrganizationOfferings($id: ID!) {
    offering(id: $id) {
      id
      name
      organization {
        id
        name
        description
        logo
      }
      shortDescription
      longDescription
      type
      techSpecName
      imageName
      showOnMarketplace
      previewOnMarketplace
      ocpCertified
      githubLink
      videoUrls
      searchTags
      projectGroups {
        id
        name
      }
      categories {
        name
        id
      }
      badges {
        name
        id
      }
      badgeRecords {
        id
      }
      tags {
        name
        id
      }
      media {
        type
        imageName
        thumbName
      }
      # media: [OfferingMedia]
      # modelNumber
      # serialNumber
      # manufactureDate
      # longDescription
      # specifications
      # faqs
      # featured: Boolean
    }
  }
`
