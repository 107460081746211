import React, { useState } from 'react'
import { Backdrop, CircularProgress, Hidden, Typography, Grid } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import AccountMembershipRequestsActions from './AccountMembershipRequestsActions'
import AccountMembershipRequestsConfirmDialog from './AccountMembershipRequestsConfirmDialog'
import EditUserModal from '../../../User/Profile'
import { CHANGE_MEMBER_STATUS } from '../../ManageMembers/mutations'
import { GET_MEMBER_TABLE_ORGANIZATION_DATA } from '../../ManageMembers/queries'
import { useMutation } from '../../../hooks'

export default function AccountMembershipRequestsCard({ orgId, onResult, request, setErrorOpen }) {
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const approveOnCompleted = data => {
    const status = data.updateUserInOrganization.status

    if (status === 'approved') {
      onResult('approved')
    } else if (!status) {
      setError(true)
    }
  }

  const changeMemberStatusMutation = useMutation(CHANGE_MEMBER_STATUS, {
    onCompleted: approveOnCompleted,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_MEMBER_TABLE_ORGANIZATION_DATA,
        variables: { id: orgId }
      }
    ]
  })

  const { execute, loading, error: changeMemberError } = changeMemberStatusMutation

  function executeApprove(userId) {
    execute({
      values: {
        orgId: orgId,
        userId: userId,
        status: 'approved',
        beforeStatus: 'requested'
      }
    })
  }

  function executeDeny(userId) {
    execute({
      values: {
        orgId: orgId,
        userId: userId,
        status: 'declined',
        beforeStatus: 'requested'
      }
    })
    setOpen(false)
  }

  if (changeMemberError) {
    setErrorOpen(true)
  }

  return (
    <div className={classes.root}>
      <AccountMembershipRequestsConfirmDialog
        open={open}
        setOpen={setOpen}
        executeDeny={executeDeny}
        userId={request.id}
      />
      <Grid container alignItems="center" direction="column" className={classes.card} spacing={2}>
        <Grid container alignItems="center" className={classes.card} spacing={2}>
          <Grid item xs={3}>
            <EditUserModal id={request.id} orgId={orgId}>
              {onClick => (
                <div onClick={onClick}>
                  <Typography className={classes.editUser}>
                    {request.firstname + ' ' + request.lastname}
                  </Typography>
                  {error && (
                    <Typography className={classes.errorText}>Something went wrong</Typography>
                  )}
                </div>
              )}
            </EditUserModal>
          </Grid>
          <Grid item xs={3}>
            <Typography>{request.jobTitle}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.emailContainer}>
              <a className={classes.emailLink} href={`mailto:${request.email}`}>
                {request.email}
              </a>
            </Typography>
          </Grid>
          <Grid className={classes.flexEnd} item xs={2}>
            <AccountMembershipRequestsActions
              executeApprove={executeApprove}
              setOpen={setOpen}
              userId={request.id}
            />
          </Grid>
        </Grid>
      </Grid>
      <Backdrop classes={{ root: classes.backdropRoot }} open={loading} />
      <Hidden xsUp={!loading}>
        <CircularProgress className={classes.progressCircle} color="primary" size={60} />
      </Hidden>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  backdropRoot: {
    bottom: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 0
  },
  bold: {
    fontWeight: 'bold'
  },
  card: {
    padding: theme.spacing(2)
  },
  editUser: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  emailContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  emailLink: {
    color: theme.palette.primary.main
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray8.main
  },
  errorText: {
    color: 'red'
  },
  progressCircle: {
    left: 'calc(50% - 30px)',
    position: 'absolute',
    top: 'calc(50% - 30px)'
  },
  root: {
    position: 'relative'
  }
}))
