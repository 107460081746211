import React, { useState } from 'react'
import { Backdrop, CircularProgress, Hidden, Typography, Grid, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LockIcon from '@material-ui/icons/Lock'

import makeStyles from '@material-ui/core/styles/makeStyles'

import GroupActivationRequestsActions from './GroupActivationRequestsActions'
import RequestSignatureSuccessModal from '../../../ProjectGroups/Admin/RequestSignatureSuccessModal'
import { useMutation } from '../../../hooks'
import { DENY_GROUP_ACTIVATION_REQUEST } from '../mutations'
import { GET_ORGANIZATION_USER_PROJECT_GROUP_REQUESTS } from '../queries'
import {
  ADD_PROJECT_GROUP_PARTICIPATION_REQUEST,
  GET_ORGANIZATION_PROJECT_GROUPS
} from '../../../ProjectGroups'

export default function GroupActivationRequestsCard({
  isOrgAdmin,
  onResult,
  orgAdminId,
  orgId,
  request,
  setOpen,
  userProjectGroups
}) {
  const classes = useStyles()

  const [error, setError] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [successModalName, setSuccessModalName] = useState(request.name)
  const [successSupportMustVerify, setSuccessSupportMustVerify] = useState(false)
  const [successJoinAsIndividual, setSuccessJoinAsIndividual] = useState(true)
  const [joinedProjectGroupId, setJoinedProjectGroupId] = useState()

  const onRequestSignatureSuccess = (joinAsIndividual, projectGroupId) => {
    setSuccessJoinAsIndividual(joinAsIndividual)
    setJoinedProjectGroupId(projectGroupId)
    setSuccessModalOpen(true)
  }

  const activateUnrestrictedOnCompleted = data => {
    const result = data.addProjectGroupParticipationRequest.result

    if (result) {
      onResult('activated')

      if (
        !userProjectGroups.some(
          o => o.projectGroupId === data.addProjectGroupParticipationRequest.projectGroupId
        )
      ) {
        setSuccessSupportMustVerify(true)
        onRequestSignatureSuccess(true, data.addProjectGroupParticipationRequest.projectGroupId)
      }
    } else {
      setError(true)
    }
  }

  const activateUnrestricted = projectGroupId => {
    executeApprove({
      values: {
        projectGroupId: projectGroupId,
        organizationId: orgId,
        type: 'requestSupportVerify'
      }
    })
  }

  const denyOnCompleted = data => {
    const result = data.denyGroupActivationRequest.result

    if (result) {
      onResult('denied')
    } else {
      setError(true)
    }
  }

  const denyRequest = projectGroupId => {
    const data = {
      id: orgId,
      values: {
        projectGroupId: projectGroupId,
        organizationId: orgId
      }
    }

    executeDeny(data)
  }

  const refetchQueries = [
    {
      query: GET_ORGANIZATION_PROJECT_GROUPS,
      variables: { id: orgId }
    }
  ]

  const {
    execute: executeApprove,
    loading: approveLoading,
    error: activateUnrestrictedError
  } = useMutation(ADD_PROJECT_GROUP_PARTICIPATION_REQUEST, {
    onCompleted: activateUnrestrictedOnCompleted,
    awaitRefetchQueries: true,
    refetchQueries: refetchQueries
  })

  const { execute: executeDeny, loading: denyLoading, error: denyError } = useMutation(
    DENY_GROUP_ACTIVATION_REQUEST,
    {
      onCompleted: denyOnCompleted,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ORGANIZATION_USER_PROJECT_GROUP_REQUESTS,
          variables: { id: orgId }
        }
      ]
    }
  )

  if (denyError || activateUnrestrictedError) {
    setOpen(true)
  }

  return (
    <div className={classes.root}>
      <RequestSignatureSuccessModal
        type="Project Group"
        agreementType="charter"
        isOrgAdmin={isOrgAdmin}
        orgId={orgId}
        projectGroupId={joinedProjectGroupId}
        projectGroupName={successModalName}
        successModalOpen={successModalOpen}
        successJoinAsIndividual={successJoinAsIndividual}
        supportMustVerify={successSupportMustVerify}
        setSuccessSupportMustVerify={setSuccessSupportMustVerify}
      />
      <Grid container alignItems="center" direction="column" className={classes.card} spacing={2}>
        <Grid container alignItems="center" className={classes.card} spacing={2}>
          <Grid item xs={8}>
            <Typography>{request.name}</Typography>
            {error && <Typography className={classes.errorText}>Something went wrong</Typography>}
          </Grid>
          <Grid container item justify="center" xs={2}>
            <Typography>{request.count > 0 ? request.count : null}</Typography>
          </Grid>
          <Grid className={classes.flexEnd} item xs={2}>
            {request.pending ? (
              <Typography className={classes.pendingText}>Pending Signature</Typography>
            ) : request.count > 0 ? (
              <GroupActivationRequestsActions
                denyRequest={denyRequest}
                onRequestSignatureSuccess={value => {
                  onRequestSignatureSuccess(value, request.projectGroupId)
                }}
                orgAdminId={orgAdminId}
                request={request}
                setSuccessModalName={setSuccessModalName}
                type="projectGroup"
              />
            ) : null}
          </Grid>
        </Grid>
        {request.subgroups && request.subgroups.length > 0 && (
          <Grid container alignItems="center" className={classes.card} spacing={2}>
            <Grid
              classes={{ root: classes.subgroupsBanner }}
              container
              item
              direction="row"
              xs={12}
            >
              <Typography className={classes.bold}>Subgroups</Typography>
              <Tooltip
                arrow
                title="Subgroups will be activated upon parent group activation. Restricted Subgroups may require additional steps."
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            </Grid>
            {request.subgroups.map((s, i) => (
              <React.Fragment key={i}>
                <Grid
                  container
                  direction="row"
                  classes={{ root: classes.subgroupName }}
                  item
                  xs={8}
                >
                  <Grid className={classes.subNameContainer} container item>
                    <Grid item>
                      {s.restricted ? (
                        <Tooltip arrow title="Restricted">
                          <LockIcon className={classes.lockIcon} />
                        </Tooltip>
                      ) : (
                        <div className={classes.spacer} />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography
                        className={s.pending && s.supportMustVerify ? classes.pendingVerify : null}
                      >
                        {s.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item justify="center" xs={2}>
                  <Typography
                    className={s.pending && s.supportMustVerify ? classes.pendingVerify : null}
                  >
                    {s.count}
                  </Typography>
                </Grid>
                <Grid className={classes.flexEnd} item xs={2}>
                  {s.pending ? (
                    s.supportMustVerify ? (
                      <Typography className={classes.pendingText}>Pending Verification</Typography>
                    ) : (
                      <Typography className={classes.pendingText}>Pending Signature</Typography>
                    )
                  ) : request.count === 0 ? (
                    <GroupActivationRequestsActions
                      activateUnrestricted={activateUnrestricted}
                      denyRequest={denyRequest}
                      onRequestSignatureSuccess={value =>
                        onRequestSignatureSuccess(value, s.projectGroupId)
                      }
                      orgAdminId={orgAdminId}
                      request={s}
                      setSuccessModalName={setSuccessModalName}
                      type="subGroup"
                    />
                  ) : null}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Grid>
      <Backdrop classes={{ root: classes.backdropRoot }} open={denyLoading || approveLoading} />
      <Hidden xsUp={!denyLoading && !approveLoading}>
        <CircularProgress className={classes.progressCircle} color="primary" size={60} />
      </Hidden>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  backdropRoot: {
    bottom: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 0
  },
  bold: {
    fontWeight: 'bold'
  },
  card: {
    padding: theme.spacing(2)
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray8.main
  },
  errorText: {
    color: 'red'
  },
  iconBlank: {
    color: 'gray',
    fontSize: 40
  },
  infoIcon: {
    color: theme.palette.primary.main,
    marginLeft: 10
  },
  lockIcon: {
    marginRight: 5
  },
  pendingText: {
    fontSize: 11,
    color: theme.palette.redPending.main
  },
  pendingVerify: {
    opacity: '50%'
  },
  progressCircle: {
    left: 'calc(50% - 30px)',
    position: 'absolute',
    top: 'calc(50% - 30px)'
  },
  root: {
    position: 'relative'
  },
  spacer: {
    width: 30
  },
  subgroupsBanner: {
    paddingLeft: '20px !important'
  },
  subgroupName: {
    paddingLeft: '40px !important'
  },
  subNameContainer: {
    flexWrap: 'nowrap'
  }
}))
