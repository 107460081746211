import React from 'react'
import clsx from 'clsx'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  externalIconLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    display: 'inline-flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  externalIcon: {
    fontSize: 15,
    marginLeft: 5
  },
  borderBottom: {
    borderBottom: `1px solid #d2d2d2`
  }
}))

function isExternal(url) {
  var host = window.location.hostname

  var linkHost = (function(url) {
    if (/^mailto?/.test(url)) {
      return true
    }

    if (/^https?:\/\//.test(url)) {
      var parser = document.createElement('a')
      parser.href = url

      return parser.hostname
    } else {
      return window.location.hostname
    }
  })(url)

  return host !== linkHost
}

const LinkField = React.forwardRef(
  (
    {
      children,
      hideIcon,
      icon,
      to,
      className,
      iconClassName,
      label,
      labelOutsideSpan,
      linkClasses,
      hideBorder,
      forceExternal = false,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()

    let url = to
    if (forceExternal) {
      // if we want to force the link to be external we must ensure it starts with http
      var prefix = 'http'
      if (url.substr(0, prefix.length) !== prefix) {
        url = 'http://' + url
      }
    }

    return isExternal(url) || forceExternal ? (
      <a
        href={url}
        {...rest}
        className={clsx([hideIcon ? classes.link : classes.externalIconLink, className])}
      >
        <span className={clsx({ [classes.borderBottom]: !hideBorder })}>
          {children}
          {label}
        </span>
        {!hideIcon &&
          (icon || <OpenInNewIcon className={clsx(classes.externalIcon, iconClassName)} />)}
        {labelOutsideSpan}
      </a>
    ) : (
      <Link
        to={to}
        innerRef={ref}
        className={
          className || clsx(classes.link, linkClasses, { [classes.borderBottom]: !hideBorder })
        }
        {...rest}
      >
        {children}
        {label}
      </Link>
    )
  }
)

export default LinkField

export { isExternal }
