import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import useImpersonation from '../Organization/Impersonation'
import { roles } from '../utils/roleHelper'

export const GET_SESSION = gql`
  query {
    session {
      expiresAt
      user {
        id
        roles {
          roleId
          scopes
        }
        signUpStep
        email
        emailValidated
        stayLoggedIn
      }
    }
  }
`

export const GET_USER_ORGANIZATIONS = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      organizations {
        organizationId
        status
        requestResent
      }
    }
  }
`

export const GET_USER_DATA = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      billingAlertConfirmed
      email
      firstname
      lastname
      signUpStep
      roles {
        roleId
      }
    }
  }
`

export const GET_ORGANIZATION_DATA = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      approvalStatus
      name
    }
  }
`

export const VERIFY_TOKEN_RESET_PASSWORD = gql`
  query($input: TokenVerifyInput!) {
    verifyResetPasswordToken(input: $input) {
      result
      email
    }
  }
`

export function getSessionQueries(userId) {
  return [
    {
      query: GET_SESSION
    },
    {
      query: GET_USER_ORGANIZATIONS,
      variables: { id: userId }
    },
    { query: GET_USER_DATA, variables: { id: userId } }
  ]
}

export function useSession(options) {
  const { client, loading, error, data, networkStatus, fetchMore, refetch } = useQuery(
    GET_SESSION,
    options
  )

  const { impersonation } = useImpersonation()

  const {
    loading: impersonateLoading,
    error: impersonateError,
    networkStatus: impersonateNetworkStatus,
    fetchMore: impersonateFetchMore,
    data: { user: impersonationUserData } = {}
  } = useQuery(GET_USER_DATA, {
    variables: { id: impersonation },
    skip: !impersonation
  })

  const sessionData = {
    clearStore: client.clearStore,
    error,
    fetchMore,
    isOrgAdmin: false,
    isPrimaryContact: false,
    isReadOnlySystemAdmin: false,
    isExchangeListingsAdmin: false,
    isSignUp: false,
    isSystemAdmin: false,
    loading,
    networkStatus,
    refetchSession: refetch,
    session: null,
    stayLoggedIn: null,
    userId: null
  }

  if (data && data.session) {
    sessionData.isAuthed = !error && !!data.session
    sessionData.session = data.session

    if (data.session.user) {
      const { user } = data.session

      sessionData.userId = user.id
      sessionData.stayLoggedIn = user.stayLoggedIn

      if (impersonation) {
        sessionData.session = {
          user: impersonationUserData,
          email: user.email,
          expiresAt: data.session.expiresAt
        }
      }

      if (user.roles && sessionData.isAuthed) {
        sessionData.isReadOnlySystemAdmin = user.roles.find(
          r => r.roleId === roles.readOnlySystemAdmin
        )
        sessionData.isSystemAdmin = user.roles.find(r => r.roleId === roles.systemAdmin)
        sessionData.isExchangeListingsAdmin = user.roles.find(
          r => r.roleId === roles.exchangeListingsAdmin
        )

        if (impersonation && impersonationUserData) {
          sessionData.isSignUp =
            impersonationUserData.signUpStep &&
            impersonationUserData.signUpStep >= 0 &&
            impersonationUserData.signUpStep < 12 // 12 = Welcome Modal
        } else {
          sessionData.isOrgAdmin = user.roles.find(r => r.roleId === roles.orgMemberAdmin)
          sessionData.isPrimaryContact = user.roles.find(r => r.roleId === roles.orgPrimaryContact)
          sessionData.isSignUp = user.signUpStep && user.signUpStep >= 0 && user.signUpStep < 11
        }
      }
    }
  }

  if (impersonation) {
    sessionData.error = impersonateError
    sessionData.fetchMore = impersonateFetchMore
    sessionData.isAuthed = true
    sessionData.isImpersonating = true
    sessionData.loading = impersonateLoading || !impersonationUserData
    sessionData.networkStatus = impersonateNetworkStatus
    sessionData.stayLoggedIn = true
    sessionData.userId = impersonation

    if (impersonationUserData && impersonationUserData.roles) {
      sessionData.isOrgAdmin = impersonationUserData.roles.find(
        r => r.roleId === roles.orgMemberAdmin
      )
      sessionData.isPrimaryContact = impersonationUserData.roles.find(
        r => r.roleId === roles.orgPrimaryContact
      )
    }
  }

  return sessionData
}

export function useUserOrganization(parameters) {
  const { loading, error, data } = useQuery(GET_USER_ORGANIZATIONS, parameters)

  let orgId, orgStatusPending, membershipStatus, requestResent

  if (data && data.user && data.user.organizations.length > 0) {
    // for MVP only one org will ever be returned
    orgId = data.user.organizations[0].organizationId
    orgStatusPending = data.user.organizations[0].status === 'requested'
    membershipStatus = data.user.organizations[0].status
    requestResent = data.user.organizations[0].requestResent
  }
  return {
    loading,
    error,
    organization: {
      orgId,
      orgStatusPending,
      membershipStatus,
      requestResent
    }
  }
}

export function useOrganization(parameters) {
  const { loading, error, data } = useQuery(GET_ORGANIZATION_DATA, parameters)

  return {
    loading,
    error,
    organization: data && data.organization
  }
}

export function useUser(parameters) {
  const { loading, error, data } = useQuery(GET_USER_DATA, parameters)
  return {
    loading,
    error,
    user: data && data.user
  }
}
