import React, { forwardRef } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles({
  small: {
    borderRadius: 3.5,
    padding: '9px 15px',
    fontSize: 12,
    fontWeight: 600
  },
  medium: {
    borderRadius: 5.28,
    padding: '12px 20px',
    fontSize: 16,
    fontWeight: 600
  },
  large: {
    borderRadius: 7.26,
    padding: '17px 28px',
    fontSize: 22,
    fontWeight: 600
  },
  link: {
    color: '#FFF',
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

const RoundedButton = forwardRef(
  (
    {
      children,
      testid,
      tooltip,
      tooltipLink,
      tooltipLinkOpen,
      placement,
      disabled,
      onClick,
      onLinkClick,
      size,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()

    return tooltip && tooltipLink ? (
      <Tooltip
        title={
          <React.Fragment>
            <div>
              {tooltip}{' '}
              <div className={classes.link} onClick={onLinkClick}>
                {tooltipLink}
              </div>
            </div>
          </React.Fragment>
        }
        placement={placement || 'top'}
        disableHoverListener
        open={tooltipLinkOpen}
        interactive
      >
        <Button
          onClick={onClick}
          disabled={disabled}
          className={size ? classes[size] : classes.medium}
          variant="contained"
          color="primary"
          fullWidth
          ref={ref}
          {...rest}
        >
          {children}
        </Button>
      </Tooltip>
    ) : tooltip ? (
      <Tooltip title={tooltip} placement={placement || 'top'}>
        <Button
          onClick={onClick}
          disabled={disabled}
          className={size ? classes[size] : classes.medium}
          variant="contained"
          color="primary"
          fullWidth
          ref={ref}
          {...rest}
        >
          {children}
        </Button>
      </Tooltip>
    ) : (
      <Button
        onClick={onClick}
        disabled={disabled}
        className={size ? classes[size] : classes.medium}
        variant="contained"
        color="primary"
        fullWidth
        ref={ref}
        {...rest}
      >
        {children}
      </Button>
    )
  }
)

export default RoundedButton
