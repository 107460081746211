import React, { useState } from 'react'
import MuiPhoneInput from 'material-ui-phone-number'
import { PhoneNumberUtil } from 'google-libphonenumber'

import config from '../config'

export default function PhoneNumberInput({
  currentCountry = 'US',
  error,
  errorHelperText,
  helperText,
  hideLabel,
  inputRef,
  inputType,
  label,
  name,
  onBlur = () => null,
  onChange = () => null,
  onFocus = () => null,
  setField,
  setFieldError,
  validateOnBlur = false,
  validator,
  value,
  ...rest
}) {
  const phoneUtil = PhoneNumberUtil.getInstance()

  if (!value) {
    value = `+${phoneUtil.getCountryCodeForRegion(countryOverride(currentCountry.toLowerCase()))}`
  }

  // Initial state changes on rerender
  const [showError, setShowError] = useState(value ? true : false)

  const validate = value => {
    if (validator) {
      const { required, regex, regexMessage } = validator

      if (!value && required) {
        return setFieldError(name, `${label} is required`)
      }

      try {
        if (
          config.CLIENT_ENV === 'production'
            ? !phoneUtil.isValidNumber(phoneUtil.parse(value))
            : !phoneUtil.isPossibleNumber(phoneUtil.parse(value))
        ) {
          return setFieldError(name, `${label} must be a valid phone number`)
        }
      } catch (e) {
        if (e.message.includes('did not seem') || e.message.includes('is too short')) {
          return setFieldError(name, `${label} must be a valid phone number`)
        } else {
          throw e
        }
      }

      if (value && regex && !regex.test(value)) {
        return setFieldError(name, regexMessage)
      }
    }
  }

  return (
    <MuiPhoneInput
      disableAreaCodes
      fullWidth
      countryCodeEditable={false}
      defaultCountry={countryOverride(currentCountry.toLowerCase())}
      error={showError && error}
      helperText={showError && error ? errorHelperText : helperText}
      label={!hideLabel && label}
      margin="normal"
      name={name}
      placeholder={hideLabel && label}
      ref={inputRef}
      value={value}
      variant="outlined"
      onBlur={(e, ...props) => {
        if (value && !showError) {
          setShowError(true)
        }

        if (validateOnBlur) {
          validate(e.target.value)
        }

        onBlur(e, ...props)
      }}
      onChange={(value, ...props) => {
        setField(name, value)

        if (!validateOnBlur) {
          validate(value)
        }

        onChange(value, ...props)
      }}
      onFocus={(...props) => {
        if (value && !showError) {
          setShowError(true)
        }

        onFocus(...props)
      }}
      {...rest}
    />
  )
}

const countryOverride = country => {
  const override = [
    { prev: 'cc', next: 'au' },
    { prev: 'cx', next: 'au' },
    { prev: 'hm', next: 'au' },
    { prev: 'gg', next: 'gb' },
    { prev: 'im', next: 'gb' },
    { prev: 'je', next: 'gb' },
    { prev: 'aq', next: 'nf' },
    { prev: 'tf', next: 'fr' },
    { prev: 'yt', next: 're' },
    { prev: 'pn', next: 'nz' },
    { prev: 'gs', next: 'fk' },
    { prev: 'sj', next: 'no' },
    { prev: 'bv', next: 'br' },
    { prev: 'um', next: 'io' },
    { prev: 'eh', next: 'ma' },
    { prev: 'ax', next: 'fi' }
  ].find(o => o.prev === country)

  if (override) {
    return override.next
  }

  if (country.length !== 2) {
    return 'us'
  }

  return country
}
