import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'

const InputTextField = forwardRef(
  (
    {
      inputType,
      name,
      touchOnChange = true,
      validateOnBlur = true,
      label,
      validator,
      gridWidth,
      icon,
      disableMinHeight,
      hideLabel,
      ...rest
    },
    ref
  ) => {
    const useStyles = makeStyles(theme => ({
      textField: {
        minHeight: disableMinHeight ? 0 : 73,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          height: 'auto'
        }
      }
    }))

    const classes = useStyles()

    function validate(value, values, event) {
      if (validator) {
        const {
          required,
          minLength,
          maxLength,
          regex,
          regexMessage,
          mustMatchKey,
          mustMatchMessage,
          cantMatchKey,
          cantMatchMessage,
          caseInsensitive
        } = validator

        if (!value.replace(/\s/g, '').length && value !== '') {
          return `${label} can not be only whitespace`
        }

        if ((required && !value) || (!value && values[mustMatchKey])) {
          return `${label} is required`
        } else if (!required && value === '') {
          return true // if not required and is empty don't do rest of validation
        }
        if (value === values[cantMatchKey]) {
          return cantMatchMessage
        }
        if (value.length < minLength) {
          return `${label} must be at least ${minLength} characters`
        }
        if (value.length > maxLength) {
          return `${label} must be shorter than ${maxLength} characters`
        }
        if (regex && !regex.test(value)) {
          return regexMessage
        }
        if (value !== values[mustMatchKey]) {
          if (caseInsensitive) {
            if (
              value &&
              values[mustMatchKey] &&
              value.toUpperCase() !== values[mustMatchKey].toUpperCase()
            ) {
              return mustMatchMessage
            }
          } else {
            return mustMatchMessage
          }
        }
        if (value === values[cantMatchKey]) {
          return cantMatchMessage
        }
      }
    }

    return (
      <div className={classes.textField}>
        <TextField
          {...inputType({
            name,
            validate,
            validateOnBlur,
            touchOnChange
          })}
          label={!hideLabel && label}
          placeholder={label}
          name={name}
          margin="normal"
          fullWidth
          color="secondary"
          variant="outlined"
          inputRef={ref}
          {...rest}
        />
      </div>
    )
  }
)

export default InputTextField
