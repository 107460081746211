import gql from 'graphql-tag'

export const GET_ORGANIZATION_DATA = gql`
  query($input: GetOrganizationsInput) {
    organizations(input: $input) {
      id
      name
      joinDate
      approvalStatus
      billingExempt
      billingExemptReason
      logo
      website
      users {
        id
      }
      membership {
        membershipLevelId
        renewalDate
      }
      organizationRoles {
        authorizedSigners {
          user {
            id
            firstname
            lastname
            email
          }
          email
          firstname
          lastname
          jobTitle
        }
        organizationAdmin {
          id
          email
          firstname
          lastname
        }
      }
      authRepId
    }
  }
`

export const GET_ACTIVE_PROJECT_GROUPS = gql`
  query {
    projectGroups {
      id
      name
    }
  }
`
