import React, { useCallback, useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import InviteMembersModal from './InviteMembersModal'
import { InputTextFieldNew, Progress, StatusDropdown } from '../../common'
import { useMutation, useQuery } from '../../hooks'
import { useSession } from '../../auth/queries'
import { searchTable } from '../../utils'
import { UPDATE_INVITATION } from './mutations'
import { GET_MEMBER_TABLE_ORGANIZATION_DATA } from '../ManageMembers/queries'

const useStyles = makeStyles(theme => ({
  toolbar: {
    marginBottom: 40
  }
}))

export const inviteStatusLookup = {
  0: `invited`,
  1: `accepted`,
  2: `cancelled`
}

export default function ManageInvitations(props) {
  const orgId = props.match.params.id

  const { loading: loadingSession, isSystemAdmin, isImpersonating } = useSession()
  const canModify = !isImpersonating || (isImpersonating && isSystemAdmin)

  const [invitationData, setInvitationData] = useState([])
  const [formState, { text }] = useFormState({ search: '' })

  let invitationDataSetter = useCallback(setInvitationData)

  const updateInvitationOnComplete = updateInvitationData => {
    if (updateInvitationData) {
      setInvitationData(currentState => {
        let newState = [...currentState]
        updateInvitationData.updateOrganizationInvitation.forEach(({ email, sentDate, status }) => {
          let updateInvitation = newState.find(m => m.email === email)
          updateInvitation.status = status
          updateInvitation.sentDate = sentDate
        })

        let message = ''

        if (updateInvitationData.updateOrganizationInvitation[0].status === 'invited') {
          message = 'Invitations Resent'
        } else {
          message = 'Invitations Cancelled'
        }
        enqueueSnackbar(message, {
          variant: 'success',
          autoHideDuration: 2500
        })
        return newState
      })
    }
  }

  const parameters = { variables: { id: orgId } }
  const {
    data: { organization } = {},
    loading: organizationQueryLoading,
    error: organizationQueryError
  } = useQuery(GET_MEMBER_TABLE_ORGANIZATION_DATA, parameters)
  const { execute: updateInvitationExecute } = useMutation(UPDATE_INVITATION, {
    onCompleted: updateInvitationOnComplete
  })

  const invitations = organization ? organization.invitations : {}
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  useEffect(() => {
    // get organization query
    if (!organizationQueryLoading && invitations) {
      setInvitationData(invitations)
    }
  }, [organizationQueryLoading, invitations])

  if (loadingSession || organizationQueryLoading) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  if (organizationQueryError) {
    return (
      <Typography role="alert" color="error">
        Error: {organizationQueryError.message}
      </Typography>
    )
  }

  function updateInvitation(orgId, data, status) {
    const emails = data.map(u => u.email)
    updateInvitationExecute({
      values: {
        organizationId: orgId,
        emails: emails,
        status: status
      }
    })
  }

  const columnDef = [
    {
      title: 'Name',
      field: 'firstName',
      searchable: true,
      customFilterAndSearch: (searchText, row, columnDef) => {
        let value = row.firstName + ' ' + row.lastName

        return value
          .toString()
          .toUpperCase()
          .includes(searchText.toUpperCase())
      },
      render: rowData => {
        if (rowData.firstName && rowData.lastName) {
          return `${rowData.firstName} ${rowData.lastName}`
        }
        if (rowData.firstName) {
          return rowData.firstName
        }
        if (rowData.lastName) {
          return rowData.lastName
        }
        return ''
      }
    },
    { title: 'Email', field: 'email', searchable: true }, // todo click open modal (real users only)
    {
      title: 'Status',
      field: 'statusValue',
      lookup: inviteStatusLookup,
      defaultFilter: ['0'], // invited
      defaultSort: 'desc',
      render: rowData => {
        return <StatusDropdown value={rowData.statusValue} name={rowData.status} options={null} />
      }
    }
  ]

  let formatedInvitationData = searchTable(
    invitationData,
    formState.values.search.trim().replace(/\s\s+/g, ' '),
    columnDef
  ).map((obj, index) => {
    const value = Object.keys(inviteStatusLookup).find(
      key => inviteStatusLookup[key] === obj.status
    )

    return { ...obj, statusValue: value }
  })

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Manage Invitations
        </Typography>
      </Grid>
      {canModify && (
        <InviteMembersModal orgId={orgId} addInvites={invitationDataSetter}>
          Invite Participants
        </InviteMembersModal>
      )}
      <Grid item className={classes.toolbar} xs={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <InputTextFieldNew
              autoComplete="new-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              inputType={text}
              label="Search"
              name="search"
              value={formState.values.search}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          columns={columnDef}
          data={formatedInvitationData}
          actions={
            canModify && [
              rowData => ({
                icon: 'autorenew',
                tooltip: 'Resend Invitation',
                onClick: (event, data) => updateInvitation(orgId, data, 'invited')
              }),
              rowData => ({
                icon: 'clear',
                tooltip: 'Cancel Invitation',
                onClick: (event, data) => updateInvitation(orgId, data, 'cancelled')
              })
            ]
          }
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 10,
            showTitle: false,
            search: false,
            selection: canModify,
            draggable: false
          }}
          components={{
            Toolbar: props => {
              if (props.selectedRows.length > 0) {
                return <MTableToolbar {...props} />
              }
              return null
            }
          }}
        />
      </Grid>
    </React.Fragment>
  )
}
