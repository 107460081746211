import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { formatLongDateTime } from '../../../utils/dateTime'

const PendingSignatureSection = ({ sentDate }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.section}>
      <Typography className={classes.message}>
        Notification sent on: {formatLongDateTime(sentDate)}
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  section: {
    display: 'block'
  },
  message: {
    fontSize: 14
  }
}))

export default PendingSignatureSection
