import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormHelperText } from '@material-ui/core'

const CheckboxField = ({
  className,
  disabled = false,
  error,
  errorHelperText,
  fieldClass,
  fullWidth,
  helperText,
  inputType,
  label,
  labelPlacement,
  name,
  showError = false,
  testid,
  validator,
  value,
  ...rest
}) => {
  const classes = useStyles()

  const text = error ? showError && errorHelperText : helperText

  return (
    <div className={className}>
      <FormControlLabel
        disabled={Boolean(disabled)}
        control={
          <Checkbox
            {...inputType({
              name: name,
              touchOnChange: true,
              validate: (value, values, event) => {
                if (validator) {
                  const { required } = validator

                  if (required && !values[name]) {
                    return `Checkbox is required`
                  }
                }
              },
              validateOnBlur: false
            })}
            className={classes.textField}
            color="primary"
            inputProps={{ 'data-testid': testid }}
            margin="normal"
            value={value}
            {...rest}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        value="top"
      />
      {text && <FormHelperText>{text}</FormHelperText>}
    </div>
  )
}

const useStyles = makeStyles({
  textField: {
    height: 45
  }
})

export default CheckboxField
