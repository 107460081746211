import gql from 'graphql-tag'

export const CREATE_STRIPE_SESSION = gql`
  mutation($input: CreateCheckoutSessionInput) {
    createCheckoutSession(input: $input) {
      paid
      result
      stripeSessionId
    }
  }
`
