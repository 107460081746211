import React from 'react'
import Typography from '@material-ui/core/Typography'
import PersonOutlineIcon from '@material-ui/icons/PersonOutlined'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import gql from 'graphql-tag'

import Progress from '../common/Progress'
import BorderLinearProgress from '../common/BorderLinearProgress'
import { useQuery } from '../hooks'
import { useSession } from '../auth/queries'
import LinkField from '../common/LinkField'
import { getImageUrl } from '../utils'

export default function UserCard({ userId, orgId }) {
  const classes = useStyles()
  const { loading: loadingSession } = useSession()
  const { data: { organization: { name: orgName } = {} } = {}, loading: orgLoading } = useQuery(
    GET_ORG,
    {
      variables: { id: orgId },
      skip: !orgId
    }
  )
  const {
    data: { user: { firstname, logo, lastname, percentageComplete, jobTitle } = {} } = {},
    loading: userLoading
  } = useQuery(GET_USER, { variables: { id: userId }, skip: !userId })

  const name = `${firstname} ${lastname}`.trim()

  const loading = orgLoading || userLoading || loadingSession
  if (loading) {
    return <Progress />
  }

  if (!loading && !userId) {
    return null
    // OPTION To show a join an org card of some sort here??
  }

  return (
    <Paper>
      <div data-testid="UserCardSection" className={classes.container}>
        <div className={classes.avatar}>
          {logo ? (
            <Avatar alt="Profile Icon" src={getImageUrl(logo)} className={classes.bigAvatar} />
          ) : (
            <Avatar alt="Organization Icon" className={classes.bigAvatar}>
              <div className={classes.icon}>
                <PersonOutlineIcon className={classes.iconBlank} />
              </div>
            </Avatar>
          )}
        </div>
        <Typography variant="h5">{name}</Typography>
        {jobTitle && <Typography>{jobTitle}</Typography>}
        {orgName && <Typography>{orgName}</Typography>}
      </div>
      <div data-testid="UserCardPercentageSection" className={classes.subSection}>
        <Typography variant="subtitle2">Profile {percentageComplete}% complete</Typography>
        <BorderLinearProgress className={classes.progressBar} value={percentageComplete} />
        {percentageComplete !== 100 && (
          <Typography>
            Complete your
            <LinkField linkClasses={classes.link} label="Profile" to={`/user/${userId}`} />
          </Typography>
        )}
      </div>
    </Paper>
  )
}

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      firstname
      lastname
      jobTitle
      logo
      percentageComplete
    }
  }
`

export const GET_ORG = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      name
    }
  }
`

const useStyles = makeStyles(theme => ({
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    width: 125,
    height: 125,
    borderRadius: 125,
    border: '1px solid gray',
    overflow: 'hidden'
  },
  name: {
    margin: 'auto 15px',
    fontSize: '0.875rem'
  },
  iconBlank: {
    fontSize: 100,
    position: 'relative',
    top: 12,
    left: 12,
    color: 'gray'
  },
  progressBar: {
    height: 20,
    margin: '5px 0'
  },
  gray: {
    color: theme.palette.gray.main
  },
  subSection: {
    borderTop: `1px solid ${theme.palette.borderGray.main}`,
    padding: 15
  },
  link: {
    margin: '0 5px'
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    margin: 10,
    width: 125,
    height: 125,
    borderRadius: 125,
    border: '1px solid gray',
    color: 'gray',
    backgroundColor: 'white'
  }
}))
