import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Grid, Typography, Icon } from '@material-ui/core'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'

import LinkField from '../common/LinkField'
import { useFormStyles, useQuery, useMutation } from '../hooks'
import { CONTACT_SUPPORT_URL } from '../utils/constants'

import SignatureRequestModal from './SignatureRequestModal'
import { REMOVE_AUTHORIZED_SIGNER } from './mutations'
import {
  StatusSection,
  PendingSignatureSection,
  WeRecievedYourSignature
} from '../Organization/Agreements/StatusSections'
import { GET_ORG } from '../Organization/Profile/queries'
import { GET_ORGANIZATION_GPA_DATA } from '../Organization/Agreements/queries'
import {
  REVOKE_PARTICIPATION_AGREEMENT_FOR_SIGNATURE,
  SEND_GPA_REMINDER
} from '../Organization/Agreements/mutations'

export default function SignaturePending({ org, updateUser }) {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [modalOpen, setModalOpen] = useState({ isOpen: false, cancel: false })

  const {
    loading,
    data: { organization }
  } = useQuery(GET_ORGANIZATION_GPA_DATA, {
    variables: {
      id: org.id
    },
    skip: !org.id
  })

  const { data: sendGPAReminderData, execute: sendGPAReminder } = useMutation(SEND_GPA_REMINDER, {})

  const { execute: removeSigner } = useMutation(REMOVE_AUTHORIZED_SIGNER, {
    onCompleted: ({ removeAuthorizedSigner }) =>
      removeAuthorizedSigner.result ? updateUser(8) : console.log(removeAuthorizedSigner),
    refetchQueries: [{ query: GET_ORG, variables: { id: org.id } }]
  })

  const signer = organization ? organization.organizationRoles.authorizedSigners[0] : null

  const { data: revokeAgreementData, execute: revokeAgreement } = useMutation(
    REVOKE_PARTICIPATION_AGREEMENT_FOR_SIGNATURE,
    {
      onCompleted: data => {
        if (data.revokeParticipationAgreementSignature.envelopeId) {
          updateUser(8)
          removeSigner({
            id: org.id,
            values: signer.user
              ? {
                  userId: signer.user.userId
                }
              : {
                  email: signer.email,
                  firstname: signer.firstname,
                  lastname: signer.lastname,
                  jobTitle: signer.jobTitle
                }
          })
        }
      }
    }
  )

  useEffect(() => {
    if (sendGPAReminderData && sendGPAReminderData.sendGPAReminder.success) {
      enqueueSnackbar('Reminder Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [sendGPAReminderData, enqueueSnackbar])

  useEffect(() => {
    if (revokeAgreementData && revokeAgreementData.revokeParticipationAgreementSignature.success) {
      enqueueSnackbar('Participation Agreement Revoked', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [revokeAgreementData, enqueueSnackbar])

  const latestAgreement = organization ? getLatestGPAAgreement(organization.signedAgreements) : {}

  let isSigned = !!(latestAgreement && latestAgreement.signedDate)

  let emailToSend = ''

  if (signer) {
    if (signer.user) {
      emailToSend = signer.user.email
    } else {
      emailToSend = signer.email
    }
  }

  function handleSubmit() {
    if (modalOpen.cancel) {
      revokeAgreement({
        values: {
          organizationId: organization.id,
          envelopeId: latestAgreement.envelopeId
        }
      })
    } else {
      sendGPAReminder({
        values: {
          organizationId: organization.id,
          envelopeId: latestAgreement.envelopeId
        }
      })
    }
  }

  if (!organization || loading) {
    return null
  }

  let paidTier = false

  if (org && org.membership && org.membership.membershipLevel) {
    paidTier = org.membership.membershipLevel.price > 0
  }

  return (
    <>
      <form>
        <div className={clsx(formClasses.header, classes.header)}>
          <Typography role="heading" variant="h3" className={formClasses.heading}>
            {!isSigned
              ? 'Signature Pending'
              : paidTier
              ? "What's next?"
              : 'Countersignature Pending'}
          </Typography>
          <Typography
            className={isSigned && paidTier ? classes.subHeadingCenter : classes.subHeading}
          >
            {!isSigned ? (
              'Signature is pending. Signing the GPA will allow your organization to participate in TIP. Without this signed agreement your organization cannot participate in TIP.'
            ) : paidTier ? (
              'If your application has been approved, an invoice will be sent to your Billing Contact'
            ) : (
              <>
                We’ve received the TIP General Participation Agreement signature. TIP will
                countersign as soon as possible, <b>usually within two business days.</b> Check your
                email for a notification from TIP.
              </>
            )}
          </Typography>
          {isSigned &&
            paidTier &&
            (organization.organizationRoles.billingContact.user ? (
              <LinkField
                color="primary"
                label={organization.organizationRoles.billingContact.user.email}
                to={`mailto:${organization.organizationRoles.billingContact.user.email}`}
                hideIcon={true}
              />
            ) : (
              <LinkField
                color="primary"
                label={organization.organizationRoles.billingContact.email}
                to={`mailto:${organization.organizationRoles.billingContact.email}`}
                hideIcon={true}
              />
            ))}
        </div>

        <div className={classes.pendingSignature}>
          {!isSigned && (
            <StatusSection
              hideBorder
              email={emailToSend}
              sentDate={latestAgreement ? new Date(latestAgreement.sentDate) : null}
              colors={['orange']}
              icons={['av_timer']}
              titles={['Pending Signature']}
              Sections={[PendingSignatureSection]}
              SubSection={
                <Grid container>
                  <Grid item xs={12} className={classes.actionLink}>
                    <Icon className={classes.smallIcon}>mail_outline</Icon>
                    <LinkField
                      label="Resend Docusign notification email"
                      to="#"
                      onClick={() =>
                        setModalOpen({
                          isOpen: true,
                          cancel: false
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.actionLink}>
                    <Icon className={classes.smallIcon}>cancel</Icon>
                    <LinkField
                      label="Cancel signature request"
                      to="#"
                      onClick={() =>
                        setModalOpen({
                          isOpen: true,
                          cancel: true
                        })
                      }
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
          {isSigned && !paidTier && (
            <StatusSection
              hideBorder
              agreement={latestAgreement}
              colors={['orange', 'blue']}
              icons={['av_timer', 'check_circle_outline']}
              titles={['Pending TIP Signature', "We've Recieved Your Signature"]}
              Sections={[PendingSignatureSection, WeRecievedYourSignature]}
              sentDate={latestAgreement.signedDate}
              signedDate={latestAgreement.signedDate}
              signedBy={latestAgreement.signedBy}
            />
          )}
        </div>
        {isSigned ? (
          paidTier ? (
            <>
              <Typography variant="body2">
                Your access to TIP begins after you've paid. Your 60 days to pay begin on your
                invoice date. If you believe your organization has paid and you still can't log in,
                please contact{' '}
                <LinkField
                  color="primary"
                  label={'TIP Support'}
                  to={CONTACT_SUPPORT_URL}
                  hideIcon={true}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                .
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body2">
                If your application has been denied you will receive an email notification.
              </Typography>
            </>
          ) : (
            <Typography className={classes.helpLink} component="p" variant="caption">
              Need help?{' '}
              <LinkField
                color="primary"
                label={'Contact TIP Support'}
                to={CONTACT_SUPPORT_URL}
                hideIcon={true}
              />
            </Typography>
          )
        ) : (
          <Typography component="p" variant="caption">
            You can check your participation status later by logging in to TIP with your email and
            password
          </Typography>
        )}
      </form>
      <SignatureRequestModal
        isOpen={modalOpen.isOpen}
        setOpen={setModalOpen}
        handleSubmit={handleSubmit}
        cancel={modalOpen.cancel}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  divider: {
    marginTop: 25,
    marginBottom: 25
  },
  header: {
    marginBottom: 20
  },
  subHeading: {
    marginBottom: 5,
    textAlign: 'left'
  },
  subHeadingCenter: {
    fontSize: 18,
    marginBottom: 5,
    textAlign: 'center'
  },
  smallIcon: {
    marginRight: 10,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  pendingSignature: {
    textAlign: 'left',
    marginBottom: 25
  },
  actionLink: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7
  },
  helpLink: {
    fontWeight: 'bold',
    marginTop: 10
  }
}))

const getLatestGPAAgreement = agreements => {
  // need to make sure the agreement is a GPA agreement.
  const gpaAgreements = agreements.filter(c => c.agreementType === 'GPA' || !c.agreementType)
  const foundAgreement = gpaAgreements && gpaAgreements[gpaAgreements.length - 1]

  return foundAgreement
}
