import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useSnackbar } from 'notistack'

import defaultCompanyLogo from '../assets/images/Company_Icon.png'
import { useMutation } from '../hooks'
import { UPLOAD_FILE, REMOVE_FILE } from './queries'
import ErrorSection from '../common/ErrorSection'
import { getImageUrl, errorMessages } from '../utils'
import ImageCropperModal from './ImageCropperModal'

import 'react-image-crop/dist/ReactCrop.css'

const styles = makeStyles(theme => ({
  avatar: {
    margin: 10
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80
  },
  inputfile: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  },
  button: {
    textTransform: 'inherit',
    fontSize: '.8rem'
  },
  profileInfo: {
    marginLeft: 7
  },
  container: {
    display: 'flex'
  },
  errorContainer: {
    marginLeft: 89
  },
  fullSizeAvatar: {
    height: 'calc(100% + 12px)',
    width: 'calc(100% + 12px)'
  }
}))

export default function LogoInput({
  type,
  changeText,
  removeText,
  parentId,
  logo = null,
  value = null,
  onUpdate,
  children,
  avatarClass,
  disabled,
  isReadOnly = false
}) {
  const classes = styles()

  const options = {
    update: onUpdate
  }

  if (!logo && value) {
    logo = value
  }

  const [image, setImage] = useState(logo ? getImageUrl(logo) : null)
  const { execute: uploadFile, data, error: addError } = useMutation(UPLOAD_FILE, options)
  const {
    execute: removeFile,
    data: { removeFile: fileRemoved } = {},
    error: removeError
  } = useMutation(REMOVE_FILE, options)
  const fileInputRef = useRef()
  const { enqueueSnackbar } = useSnackbar()

  const [src, setSrc] = useState(null)
  const [cropperOpen, setCropperOpen] = useState(false)

  let errorMessage

  useEffect(() => {
    if (logo) {
      setImage(getImageUrl(logo))
    } else {
      setImage(null)
    }
  }, [logo])

  useEffect(() => {
    if (data && data.uploadFile) {
      setImage(getImageUrl(data.uploadFile.name))
      setCropperOpen(false)
      enqueueSnackbar('Photo uploaded', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [data, enqueueSnackbar])

  useEffect(() => {
    if (fileRemoved) {
      setImage(null)
      enqueueSnackbar('Photo removed', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [fileRemoved, enqueueSnackbar])

  if (addError || removeError) {
    console.error(addError || removeError)
    errorMessage = errorMessages.TIPSupport
  }

  function handleChange({
    target: {
      validity,
      files: [file]
    }
  }) {
    if (validity.valid && file) {
      errorMessage = null
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        setSrc(reader.result)
      })

      reader.readAsDataURL(file)

      setCropperOpen(true)
    }
  }

  function handleRemove() {
    setImage(null)
    removeFile({ values: { type: type, id: parentId } })
  }

  // return a promise that resolves with a File instance
  function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
    return fetch(url)
      .then(function(res) {
        return res.arrayBuffer()
      })
      .then(function(buf) {
        return new File([buf], filename, { type: mimeType })
      })
  }

  function handleUpload(blob) {
    urltoFile(blob, 'a.png').then(function(file) {
      uploadFile({ values: { file: file, type: type, id: parentId } })
    })
  }

  function handleClick() {
    fileInputRef.current.click()
  }

  return (
    <div>
      <div className={classes.container}>
        {image ? (
          type === 'organization' ? (
            <Avatar
              alt="Organization Logo"
              src={image}
              imgProps={{ className: avatarClass }}
              className={classes.bigAvatar}
            />
          ) : (
            <Avatar alt="Profile Icon" src={image} className={classes.bigAvatar} />
          )
        ) : type === 'profile' ? (
          <Avatar alt="Profile Icon" className={classes.bigAvatar}>
            <AccountCircleIcon className={classes.fullSizeAvatar} />
          </Avatar>
        ) : (
          <Avatar alt="Profile Icon" src={defaultCompanyLogo} className={classes.bigAvatar} />
        )}
        <div>
          <div className={classes.profileInfo}>{children}</div>
          <input
            className={classes.inputfile}
            ref={fileInputRef}
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            onChange={handleChange}
          />
          {!isReadOnly && (
            <>
              <Button
                color="primary"
                className={classes.button}
                onClick={handleClick}
                disabled={disabled}
              >
                {changeText}
              </Button>
              {logo && (
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={handleRemove}
                  disabled={disabled}
                >
                  {removeText}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {errorMessage && (
        <div className={classes.errorContainer}>
          <ErrorSection includeSupport={false}>{errorMessage}</ErrorSection>
        </div>
      )}

      <ImageCropperModal
        handleUpload={handleUpload}
        open={cropperOpen}
        allowPng={type === 'organization'}
        src={src}
        handleClose={cancel => {
          if (cancel) {
            fileInputRef.current.value = ''
          }

          setCropperOpen(false)
        }}
      />
    </div>
  )
}
