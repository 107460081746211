import gql from 'graphql-tag'

export const CHANGE_ORGANIZATION_STATUS = gql`
  mutation($id: ID!, $input: OrganizationStatusInput!) {
    editOrganizationStatus(id: $id, input: $input) {
      result
      id
      status
      showOnPublicSite
    }
  }
`

export const REMOVE_ORGANIZATION_ADMIN = gql`
  mutation($input: OrganizationAdminInput!) {
    removeOrganizationAdmin(input: $input) {
      approvalStatus
      name
      result
      id
    }
  }
`

export const CHANGE_ORGANIZATION_PARTICIPATION_TIER = gql`
  mutation($id: ID!, $input: OrganizationParticipationTierInput!) {
    editOrganizationParticipationTier(id: $id, input: $input) {
      id
      membership {
        membershipLevelId
        status
        startDate
        renewalDate
        paid
      }
      result
      membershipLevel {
        id
        name
        price
        atlassianAccountsAllowed
        canAccessRFI
        canAccessTSS
        canAccessProjectGroups
        canAccessSoftwareGroups
      }
    }
  }
`

export const UPDATE_BILLING_EXEMPT_REASON = gql`
  mutation($id: ID!, $input: OrganizationBillingExemptInput!) {
    updateBillingExemptReason(id: $id, input: $input) {
      billingExempt
      billingExemptReason
      orgId
      result
    }
  }
`
