import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'

import RoundedButton from '../common/RoundedButton'
import Progress from '../common/Progress'
import { useMutation } from '../hooks'
import { useSession } from '../auth/queries'
import { TooltipHelper } from '../SignUp/GetStartedInfo'
import {
  ADD_PROJECT_GROUP_PARTICIPATION_REQUEST,
  GET_ORGANIZATION_PROJECT_GROUPS,
  GET_USER_PROJECT_GROUPS
} from '../ProjectGroups'

const ConfirmJoinDialog = ({
  canAccess,
  children,
  handleJoinProjectGroup,
  isAvailable,
  isOrgAdmin,
  joinLoading,
  onParticipationRequestSuccess,
  openToMembers,
  orgAdminId,
  orgId,
  overrideButton,
  projectGroupName,
  parentIsAvailable,
  parentProjectGroupId,
  parentProjectGroupName,
  parentSelectedOrJoined,
  projectGroupId,
  restricted,
  supportMustVerify,
  type
}) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { userId } = useSession()

  const updateProjectGroups = (
    cache,
    { data: { addProjectGroupParticipationRequest: { result } = {} } }
  ) => {
    if (result) {
      let statusType

      if (isAvailable === false) {
        statusType = 'requestParticipation'
      } else {
        statusType = 'requestAccess'
      }

      setOpen(false)
      onParticipationRequestSuccess(statusType, supportMustVerify, restricted)
    } else {
      console.log('error')
      // setErrorMessage('Something went wrong.')
    }
  }

  let refetchQueries
  if (isOrgAdmin) {
    refetchQueries = [
      {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: orgId }
      }
    ]
  } else {
    refetchQueries = [
      {
        query: GET_USER_PROJECT_GROUPS,
        variables: { id: userId }
      }
    ]
  }

  const { execute, loading: requestLoading } = useMutation(
    ADD_PROJECT_GROUP_PARTICIPATION_REQUEST,
    {
      update: updateProjectGroups,
      awaitRefetchQueries: true,
      refetchQueries: refetchQueries
    }
  )

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleSubmit() {
    if (isOrgAdmin) {
      if (supportMustVerify) {
        execute({
          values: {
            projectGroupId: projectGroupId,
            organizationId: orgId,
            type: 'requestSupportVerify'
          }
        })
      } else {
        handleJoinProjectGroup(projectGroupId)
      }
    } else if ((isAvailable === false && !parentProjectGroupId) || parentIsAvailable === false) {
      execute({
        values: {
          userId: userId,
          projectGroupId: projectGroupId,
          organizationId: orgId,
          type: 'requestParticipation'
        }
      })
    } else if ((restricted && !isAvailable) || (supportMustVerify && !isAvailable)) {
      execute({
        values: {
          userId: userId,
          projectGroupId: projectGroupId,
          organizationId: orgId,
          type: 'requestParticipation'
        }
      })
    } else if (restricted && !openToMembers) {
      if (orgAdminId === userId) {
        handleJoinProjectGroup(projectGroupId, parentProjectGroupId)
      } else {
        execute({
          values: {
            userId: userId,
            projectGroupId: projectGroupId,
            organizationId: orgId,
            type: 'requestAccess'
          }
        })
      }
    } else {
      handleJoinProjectGroup(projectGroupId, parentProjectGroupId)
    }
  }

  return (
    <>
      {overrideButton ? (
        children(handleClickOpen)
      ) : (
        <div>
          {!canAccess && (
            <Typography paragraph className={classes.notAvailableLabel}>
              <TooltipHelper
                iconClass={classes.notAvailableIcon}
                label="Not available at your tier"
                labelFirst={false}
                title="To access this group, your organization must upgrade its participation tier."
              />
            </Typography>
          )}
          <RoundedButton
            className={classes.selectionButton}
            disabled={!canAccess}
            size="small"
            fullWidth={false}
            onClick={handleClickOpen}
          >
            {isOrgAdmin ? 'Activate Group' : 'Join Group'}
          </RoundedButton>
        </div>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth={'md'}
        onClose={handleClose}
        className={classes.margin}
      >
        <DialogTitle className={classes.titleRow}>
          Confirmation
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.margin}>
          <Typography className={classes.bold}>Confirm selection</Typography>

          {parentProjectGroupId ? (
            <Typography>
              Please confirm you wish to join the{' '}
              <span className={classes.bold}>{projectGroupName}</span> subgroup.{' '}
              {!parentSelectedOrJoined && (
                <>
                  You will also automatically join its parent group, the{' '}
                  <span className={classes.bold}>{parentProjectGroupName}</span> Project Group.
                </>
              )}
            </Typography>
          ) : (
            <Typography>
              Please confirm you wish to join the{' '}
              <span className={classes.bold}>{projectGroupName}</span> Project Group.
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <RoundedButton
            className={clsx(classes.button, classes.cancelButton)}
            fullWidth={false}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className={clsx(classes.button, classes.confirmButton)}
            fullWidth={false}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            {joinLoading || requestLoading ? (
              <Progress classes={{ colorPrimary: classes.progress }} size={30} delay={0} />
            ) : (
              'Confirm'
            )}
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  actionButtons: {
    padding: 25,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  bold: {
    fontWeight: 600
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  cancelButton: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      marginRight: 0
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  closeIcon: {
    marginLeft: 5,
    cursor: 'pointer'
  },
  confirmButton: {
    width: 105
  },
  notAvailableLabel: {
    color: '#2A5589',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  notAvailableIcon: {
    verticalAlign: 'bottom'
  },
  progress: {
    color: 'white'
  },
  select: {
    minWidth: 300,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 240
    }
  },
  selectionButton: {
    fontWeight: 'bold',
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: 'auto'
    }
  },
  titleRow: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}))

export default ConfirmJoinDialog
