import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import RichTextEditor from 'react-rte'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { InputTextFieldBak } from '../common'
import RoundedButton from '../common/RoundedButton'
import ErrorSection from '../common/ErrorSection'
import Progress from '../common/Progress'
import ConfirmModal from './ConfirmModal'
import { useMutation } from '../hooks'
import { SEND_CUSTOM_ORG_BULK_EMAIL } from './mutations'

export default function EmailModal({ sender, toList, children }) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [formState, { text }] = useFormState()

  const isDisabled = !formState.values.subject || !formState.values.content

  const { execute, error, loading, data: { sendOrgBulkEmail: data } = {} } = useMutation(
    SEND_CUSTOM_ORG_BULK_EMAIL
  )
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // on success show snack bar
    if (error && !loading) {
      setConfirmOpen(false)
    }
    if (data && !error && !loading) {
      // close modals
      setOpen(false)
      setConfirmOpen(false)

      enqueueSnackbar('Emails Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [data, error, loading, enqueueSnackbar])

  let errorMessage
  if (error) {
    errorMessage = 'Something went wrong.'
  }

  function handleClickOpen() {
    formState.setField('content', null)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleCloseConfirm() {
    setConfirmOpen(false)
  }

  function onChange(val) {
    formState.setField('content', val)
  }

  function sendEmail() {
    const sendTo = [...toList, sender]
    const obj = {
      sender,
      toList: sendTo,
      subject: formState.values.subject,
      body: formState.values.content.toString('html')
    }
    execute({ values: obj })
  }

  function confirmSendEmail() {
    // set confirm true
    setConfirmOpen(true)
  }

  return (
    <>
      {children(handleClickOpen)}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth={'md'}
        onClose={handleClose}
        className={classes.margin}
      >
        <DialogTitle className={classes.titleRow}>
          Email Participants
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.margin}>
          {errorMessage && <ErrorSection includeSupport>{errorMessage}</ErrorSection>}
          <div className={classes.message}>
            <CheckCircleOutlineIcon className={classes.checkIcon} />
            Mail will be sent to {toList.length} participants
          </div>
          <div className={classes.subjectContainer}>
            <InputTextFieldBak
              name="subject"
              label="Enter your email subject"
              inputType={text}
              validator={{
                required: true,
                maxLength: 140
              }}
              error={formState.errors.subject ? true : false}
              helperText={!!formState.errors.subject ? 'Must be shorter than 140 characters.' : ''}
            />
          </div>
          <div>
            <RichTextEditor
              value={formState.values.content || RichTextEditor.createEmptyValue()}
              onChange={onChange}
              editorClassName={classes.textEditor}
            />
          </div>
          <Typography className={classes.note}>
            Note: you will also be sent a copy of this email
          </Typography>
        </DialogContent>
        <DialogActions className={clsx(classes.actionButtons, classes.margin)}>
          <RoundedButton fullWidth={false} onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </RoundedButton>
          <RoundedButton
            fullWidth={false}
            onClick={confirmSendEmail}
            variant="contained"
            color="primary"
            disabled={loading || isDisabled}
          >
            {loading ? <Progress size={25} delay={0} /> : 'Submit'}
          </RoundedButton>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={confirmOpen}
        count={toList.length}
        handleConfirm={sendEmail}
        handleClose={handleCloseConfirm}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  titleRow: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
    marginRight: 5
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3rem',
    padding: 10,
    marginTop: 30,
    backgroundColor: theme.palette.gray5.main,
    color: theme.palette.gray.main
  },
  subjectContainer: {
    marginTop: 20,
    marginBottom: 30
  },
  textEditor: {
    height: 200 // min-height will auto grow unlike height... but you can't click in it to focus with rte
  },
  actionButtons: {
    marginTop: 20,
    marginBottom: 30
  },
  margin: {
    marginLeft: 30,
    marginRight: 30
  },
  note: {
    color: theme.palette.gray.main,
    textAlign: 'right',
    fontSize: '.8rem',
    marginTop: 10
  }
}))
