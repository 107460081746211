import React from 'react'
import { Switch } from 'react-router-dom'

import { ProtectedRoute, ProtectedOrgRoute } from '../auth'
import { ORGANIZATION_APPROVED } from '../auth/constants'
import { EditUser, ProjectGroups } from './'

function UserRouter({ match }) {
  return (
    <Switch>
      <ProtectedOrgRoute
        path={`${match.path}/:id/project-groups/:orgId`}
        requiredRoles={['0', '4', '5']} //system admin, org admin
        requiredStates={[ORGANIZATION_APPROVED]}
        component={ProjectGroups}
      />
      <ProtectedRoute
        path={`${match.path}/:id`}
        requiredRoles={['0', '6']} //system admin or registered user
        component={EditUser}
      />
    </Switch>
  )
}

export default UserRouter
