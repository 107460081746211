import React, { useRef } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable from 'material-table'

import { columnDef } from './helpers'

const SyncIntegrationErrorList = ({ containsErrorData = false, syncResults = { results: [] } }) => {
  const classes = useStyles()
  const filterValues = useRef({})

  const errorResults = syncResults.results.reduce((data, current) => {
    if (current.status === 'Error') {
      data.push({
        deprovisionError: !!current.deprovisionedBy,
        error: current.error,
        hubId: current.hubId,
        hubName: current.hubName,
        hubType: current.hubType,
        integrationId: current.integrationId
      })
    }

    current.subData.forEach(s => {
      if (s.status === 'Error') {
        data.push({
          deprovisionError: !!s.deprovisionedBy,
          error: s.error,
          hubId: s.hubId,
          hubName: s.hubName,
          hubType: s.hubType,
          integrationId: s.integrationId,
          parent: {
            hubId: current.hubId,
            hubName: current.hubName,
            hubType: current.hubType
          }
        })
      }
    })

    return data
  }, [])

  return (
    <ExpansionPanel square className={classes.expansionPanel} elevation={0}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Sync Errors</Typography>
        {containsErrorData && (
          <div className={classes.notificationCountWrapper}>
            {errorResults.length > 0 ? (
              <div className={classes.notificationCount}>{errorResults.length}</div>
            ) : (
              <ErrorIcon className={classes.errorIcon} />
            )}
          </div>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={4}>
          <Grid item xs>
            {containsErrorData && errorResults.length === 0 && (
              <Typography>
                Records with errors have been identified. Press "Load Results" to view data.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={columnDef({
                filter: [
                  { field: 'hubId' },
                  { field: 'integrationId' },
                  { field: 'hubName' },
                  { field: 'hubType' },
                  { field: 'parentHubId' },
                  { field: 'parentHubName' },
                  { field: 'parentHubType' },
                  { field: 'error' }
                ],
                filterValues
              })}
              components={{
                Toolbar: props => {
                  props.columns.forEach(c => {
                    filterValues.current[c.field] = c.tableData.filterValue || null
                  })

                  return null
                }
              }}
              data={errorResults}
              localization={{
                body: {
                  emptyDataSourceMessage:
                    containsErrorData && errorResults.length === 0
                      ? "Press 'Load Results' to view data"
                      : 'No records to display'
                }
              }}
              options={{
                draggable: false,
                filterCellStyle: {
                  verticalAlign: 'bottom'
                },
                filtering: true,
                pageSize: 5,
                showTitle: false
              }}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const useStyles = makeStyles(theme => ({
  errorIcon: {
    color: theme.palette.dashboardNotification.main,
    fontSize: 25
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`
  },
  notificationCount: {
    alignItems: 'center',
    background: theme.palette.dashboardNotification.main,
    borderRadius: 6,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 25,
    padding: '2px 8px'
  },
  notificationCountWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: 'auto'
  }
}))

export default SyncIntegrationErrorList
