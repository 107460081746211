import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-use-form-state'

import { isFormSubmitDisabled, inputRegexes } from '../../utils'
import { InputTextFieldBak } from '../../common'
import RoundedButton from '../../common/RoundedButton'

export default function ChairInput({
  onSave,
  onCancel,
  showCancel,
  value = {},
  inputId,
  users,
  selectedUserId,
  isReadOnly,
  ...rest
}) {
  const signer = {
    userId: value.userId,
    firstname: value.firstname,
    email: value.email,
    lastname: value.lastname,
    company: value.company
  }

  const classes = useStyles()
  const [formState, inputTypes] = useFormState({
    email: signer.email,
    firstname: signer.firstname,
    lastname: signer.lastname,
    company: signer.company,
    userId: signer.userId
  })

  let disableSubmit = isFormSubmitDisabled(inputFields, formState)

  function onSubmit() {
    let obj

    obj = {
      firstname: formState.values.firstname,
      lastname: formState.values.lastname,
      company: formState.values.company,
      email: formState.values.email
    }

    onSave(obj)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.radioContent}>
          {Object.entries(inputFields).map(([name, { InputComponent, inputType, ...args }]) => (
            <Grid key={name} item {...args.gridWidth}>
              <InputComponent
                key={name}
                inputType={inputTypes[inputType]}
                {...args}
                error={formState.errors[name] ? true : false}
                helperText={formState.errors[name]}
                disabled={isReadOnly}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={2} className={classes.button}>
          <RoundedButton fullWidth={false} disabled={disableSubmit} onClick={onSubmit}>
            Add
          </RoundedButton>
        </Grid>
        {showCancel && ( // can't cancel if it is the first signer being added
          <Grid item xs={2} className={classes.button}>
            <RoundedButton fullWidth={false} variant="outlined" onClick={onCancel}>
              Cancel
            </RoundedButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  radioContent: {
    paddingLeft: 33
  },
  button: {
    marginTop: 20,
    marginRight: 10,
    display: 'inline-block'
  }
}))

const inputFields = {
  firstname: {
    InputComponent: InputTextFieldBak,
    gridWidth: { xs: 12, sm: 6 },
    name: 'firstname',
    label: 'First Name',
    inputType: 'text',
    validator: {
      required: true
    },
    variant: 'outlined'
  },
  lastname: {
    InputComponent: InputTextFieldBak,
    gridWidth: { xs: 12, sm: 6 },
    name: 'lastname',
    label: 'Last Name',
    inputType: 'text',
    validator: {
      required: true
    },
    variant: 'outlined'
  },
  company: {
    InputComponent: InputTextFieldBak,
    gridWidth: { xs: 12, sm: 12 },
    name: 'company',
    label: 'Company',
    inputType: 'text',
    validator: {
      required: true
    },
    variant: 'outlined'
  },
  email: {
    InputComponent: InputTextFieldBak,
    gridWidth: { xs: 12 },
    name: 'email',
    label: 'Email',
    inputType: 'email',
    validator: {
      required: true,
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email'
    },
    variant: 'outlined'
  }
}
