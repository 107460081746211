import React from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import WarningIcon from '@material-ui/icons/Warning'

import Footer from './Footer'
import LinkField from './common/LinkField'
import { SUPPORT_EMAIL } from './utils/constants'

const styles = theme => ({
  paragraph: { marginTop: 40, fontSize: '1rem' },
  container: {
    width: '80vw',
    margin: 'auto'
  },
  image: {
    maxWidth: '100%',
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5
    }
  },
  title: {
    fontSize: '1.3rem'
  },
  helperText: {
    fontSize: '1rem'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1
  },
  warning: {
    display: 'flex',
    alignItems: 'center'
  },
  warningIcon: {
    color: '#cfcfcf',
    fontSize: '6rem',
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem'
    }
  },
  warningLabel: {
    color: '#cfcfcf',
    display: 'inline',
    textTransform: 'uppercase',
    fontSize: '6rem',
    fontWeight: 100,
    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem'
    }
  },
  goHomeBtn: {
    marginTop: 50,
    fontSize: '.7rem'
  }
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, info: '', error: '' }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, info, error })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { classes } = this.props
    if (this.state.hasError) {
      let errorMessage = this.state.error.message
      // You can render any custom fallback UI

      let body = `Repro Steps:
      \n1. {PLEASE TELL US WHAT YOU DID TO GET HERE}
      \n\n----------------------------------------
      \nThe Error: \n${errorMessage}`
      body = encodeURIComponent(body)
      return (
        <div className={classes.root}>
          <main className={classes.content}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <Grid item xs={12} className={classes.warning}>
                    <WarningIcon className={classes.warningIcon} />{' '}
                    <Typography className={classes.warningLabel}>Error</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom className={classes.title}>
                      Sorry, something went wrong :(
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom className={classes.helperText}>
                      Please try again or{' '}
                      <LinkField
                        color="primary"
                        label={'click here to Contact TIP Support.'}
                        to={`mailto:${SUPPORT_EMAIL}?cc=tipdevops@launchcg.com&body=${body}&subject=TIP%20-Something%20Went%20Wrong`}
                        hideIcon={true}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.paragraph}>
                    <span>
                      Please list the steps you took to get here in your email, the error will be
                      automatically added.
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </main>
          <Footer />
        </div>
      )
    }
    return this.props.children
  }
}

export default withStyles(styles)(ErrorBoundary)
