import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline'
import clsx from 'clsx'
import equal from 'fast-deep-equal'
import { makeStyles, lighten } from '@material-ui/core/styles'
import { useFormState } from 'react-use-form-state'

import OrgMemberDropdown from './OrgMemberDropdown'
import RoundedButton from '../../common/RoundedButton'
import Progress from '../../common/Progress'
import ErrorSection from '../../common/ErrorSection'
import { EDIT_ORG } from './mutations'
import { isFormSubmitDisabled } from '../../utils'
import { history } from '../../store'
import { useMutation } from '../../hooks'
import { tabStyles } from '../../User/Profile/UserInfo'
import { resetStore } from '../../middleware/api'

function OrganizationRoles({
  organization = {},
  activeTab,
  onFormChange,
  onCompleted,
  onUpdate,
  users,
  userId,
  isSystemAdmin,
  isReadOnly = false
}) {
  const tabClasses = tabStyles()
  const classes = useStyles()

  const roles = {
    exchangeListingAdminId: organization.organizationRoles.exchangeListingAdminId || null,
    organizationAdminId: organization.organizationRoles.organizationAdminId || null,
    primaryContactId: organization.organizationRoles.primaryContactId || null
  }
  const [formState] = useFormState(roles)

  const {
    loading,
    execute: handleSubmit,
    data: { editOrganization = {} } = {},
    error
  } = useMutation(EDIT_ORG, {
    onCompleted: ({ editOrganization }) => onCompleted(editOrganization),
    update: onUpdate
  })

  const hasUnsavedChanges = !equal(formState.values, roles)

  let disableSubmit = isFormSubmitDisabled({}, formState, true)
  let errorMessage

  useEffect(() => {
    if (editOrganization && editOrganization.organizationRoles) {
      if (
        // if not a system admin the user needs to be reset and put on their member dashboard
        !isSystemAdmin &&
        (editOrganization.organizationRoles &&
          editOrganization.organizationRoles.organizationAdmin &&
          editOrganization.organizationRoles.organizationAdmin.id !== userId)
      ) {
        resetStore().then(history.push('/'))
      }
    }
  }, [editOrganization, loading, error, userId, isSystemAdmin])

  useEffect(() => {
    if (activeTab) {
      onFormChange(hasUnsavedChanges)
    }
  }, [hasUnsavedChanges, onFormChange, activeTab])

  if (error) {
    errorMessage = 'Something went wrong.'
  }

  return (
    <form>
      <Grid container className={clsx({ [classes.readOnly]: isReadOnly })}>
        <Hidden className={classes.desktop} only={['xs']}>
          <Grid container spacing={1} className={classes.header}>
            <Grid item>
              <Typography paragraph>
                By assigning individuals to the roles listed below, you certify that they are
                authorized to represent your organization and to perform the duties associated with
                the indicated roles.
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography className={classes.columnTitle}>
                Primary Roles <span className={classes.subtitle}>(one user per role)</span>
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography className={classes.columnTitle}>
                Users <span className={classes.subtitle}>(can have multiple roles)</span>
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
        {Object.entries(orgRolesFields(userId, roles.organizationAdminId))
          .filter(item => item[1].hidden !== true)
          .map(([name, { InputComponent, inputType, title, subtitle, ...args }]) => (
            <Grid container key={name} className={classes.organizationRolesContainer}>
              <Grid item {...args.gridWidth} className={classes.section}>
                <CheckCircleIcon
                  className={clsx(classes.icon, {
                    [classes.added]: formState.values[name]
                  })}
                />
                <div>
                  <Typography variant="h6">{title}</Typography>
                  <Typography
                    variant="subtitle1"
                    className={clsx(classes.subtitle, classes.smallSubtitle)}
                  >
                    {subtitle}
                  </Typography>
                </div>
              </Grid>
              <Grid item {...args.gridWidth}>
                <InputComponent
                  key={name}
                  value={formState.values[name]}
                  inputId={name}
                  users={users}
                  selectedUserId={formState.values[name]}
                  {...args}
                  onChange={value => {
                    formState.setField(name, value)
                  }}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          ))}
        {!isReadOnly && (
          <Grid container justify="center">
            <Grid item xs={12} sm={8} className={tabClasses.buttonContainer}>
              <RoundedButton
                onClick={() => {
                  handleSubmit({
                    id: organization.id,
                    values: { organizationRoles: formState.values }
                  })
                }}
                disabled={!hasUnsavedChanges || loading || disableSubmit}
                color="primary"
                fullWidth={true}
                className={tabClasses.roundedButton}
              >
                {loading ? <Progress size={25} delay={0} /> : 'Update Roles'}
              </RoundedButton>
              {errorMessage && <ErrorSection>{errorMessage}</ErrorSection>}
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  organizationRolesContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '25px 0px',
    borderBottom: '2px solid ' + theme.palette.borderGray.main
  },
  columnTitle: {
    color: theme.palette.gray.main,
    fontWeight: 500
  },
  subtitle: {
    fontWeight: 100,
    color: theme.palette.gray.main,
    paddingRight: 30,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    }
  },
  smallSubtitle: {
    fontSize: '.8rem'
  },
  header: {
    borderBottom: '2px solid ' + theme.palette.borderGray.main
  },
  icon: {
    color: lighten(theme.palette.gray.main, 0.8),
    fontSize: 60,
    marginRight: 10
  },
  added: {
    color: lighten(theme.palette.gray.main, 0.2)
  },
  section: {
    display: 'flex'
  }
}))

const orgRolesFields = (userId, adminId) => ({
  organizationAdminId: {
    InputComponent: OrgMemberDropdown,
    confirmChangeMessage:
      userId === adminId
        ? "Are you sure you want to remove yourself as the Account admin? Doing so will remove your admin privileges and you'll become a regular Organization participant."
        : 'Are you sure you want to change the Account admin? Doing so will remove the admin privileges for the current Organization admin and they will become a regular Organization participant.',
    gridWidth: { xs: 12, sm: 6 },
    name: 'organizationAdminId',
    label: 'Email address',
    title: 'Account admin',
    subtitle:
      'The Account Admin is an individual employed by Applicant, who will have day-to-day responsibility for maintaining and managing the Applicant’s participation in TIP Project Groups and activities and who will have access to TIP resources as determined by the Applicant’s Participation classification.'
  },
  primaryContactId: {
    InputComponent: OrgMemberDropdown,
    gridWidth: { xs: 12, sm: 6 },
    name: 'primaryContactId',
    label: 'Email address',
    title: 'Organization primary contact',
    subtitle:
      'The primary contact receives all official communications from TIP, particularly those related to their organization’s participation, such as new policies and specification reviews.'
  },
  exchangeListingAdminId: {
    InputComponent: OrgMemberDropdown,
    gridWidth: { xs: 12, sm: 6 },
    name: 'exchangeListingAdminId',
    label: 'Email address',
    title: 'TIP Exchange listings admin',
    subtitle:
      'The Exchange listings administrator is responsible for managing their organization’s presence on the TIP Exchange website, including product listings and the organization contact information shown on the site.',
    isClearable: true,
    hidden: true
  }
})

export default OrganizationRoles
