import React from 'react'
import Select from 'react-select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'

export default function OrgMemberDropdown({
  isClearable,
  selectedUserId,
  users,
  inputId,
  onChange,
  confirmChangeMessage,
  disabled = false
}) {
  const classes = useStyles()
  const theme = useTheme()

  let suggestions = users.map(({ firstname, lastname, id, email }) => ({
    value: id,
    label: `${firstname} ${lastname} - ${email}`,
    email,
    firstname,
    lastname
  }))

  const selectedUser = selectedUserId ? suggestions.find(s => s.value === selectedUserId) : null
  const [single, setSingle] = React.useState(selectedUser)
  const [open, setOpen] = React.useState(false)

  const toLowerCaseOrBlank = text => {
    if (text) {
      return text.toLowerCase()
    } else {
      return ''
    }
  }

  // order alphabetically
  suggestions = suggestions.sort(function(a, b) {
    var aName = toLowerCaseOrBlank(a.firstname)
    var bName = toLowerCaseOrBlank(b.firstname)

    return aName.localeCompare(bName)
  })

  function handleChangeSingle() {
    onChange(single ? single.value : null)
  }

  function handleConfirmation() {
    handleChangeSingle()
    setOpen(false)
  }

  function handleClose() {
    setSingle(selectedUser)
    setOpen(false)
  }

  function confirmSingleChange(item) {
    setSingle(item)
    if (confirmChangeMessage) {
      setOpen(true)
    } else {
      onChange(item ? item.value : null)
    }
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  }

  function customFilter(option, searchText) {
    if (
      toLowerCaseOrBlank(option.data.firstname).startsWith(searchText.toLowerCase()) ||
      toLowerCaseOrBlank(option.data.lastname).startsWith(searchText.toLowerCase()) ||
      toLowerCaseOrBlank(option.data.email).startsWith(searchText.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmChangeMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmation} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Select
        classes={classes}
        styles={selectStyles}
        inputId={inputId}
        TextFieldProps={{
          label: 'Search for user (name or email)',
          InputLabelProps: {
            htmlFor: inputId,
            shrink: true
          }
        }}
        filterOption={customFilter}
        options={suggestions}
        components={components}
        value={single}
        onChange={confirmSingleChange}
        isClearable={isClearable}
        isDisabled={disabled}
      />
    </div>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      <div>
        <Typography>
          {props.data.firstname} {props.data.lastname}
        </Typography>
        <Typography className={props.selectProps.classes.subtext}>{props.data.email}</Typography>
      </div>
    </MenuItem>
  )
}

const components = {
  Control,
  Option
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  subtext: {
    color: theme.palette.gray.main,
    fontSize: '.8rem'
  }
}))
