import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import BillingContactInput from './BillingContactInput'
import { GET_ORG } from './queries'
import { EDIT_BILLING_ADMIN } from './mutations'
import { useMutation } from '../../hooks'

export default function BillingContact({
  organization = {},
  activeTab,
  onFormChange,
  onCompleted,
  onUpdate,
  users,
  userId,
  isSystemAdmin,
  isReadOnly = false
}) {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)

  const { execute: handleEditBillingContact } = useMutation(EDIT_BILLING_ADMIN, {
    refetchQueries: [{ query: GET_ORG, variables: { id: organization.id } }]
  })

  if (Object.keys(organization).length === 0) {
    return null
  }

  let billingContact = organization.organizationRoles.billingContact

  if (billingContact && billingContact.user) {
    billingContact = billingContact.user
  }

  return (
    <form>
      <Grid container>
        <Grid item className={classes.textContainer} xs={12}>
          <Typography paragraph>
            Please provide the contact information of a financial representative in your
            organization who is authorized to process TIP Participation billing.
          </Typography>
        </Grid>
        {(editing || !billingContact.email) && !isReadOnly ? (
          <Grid item xs={12} lg={8} className={classes.addForm}>
            <BillingContactInput
              users={users}
              gridWidth={{ xs: 12, sm: 6 }}
              name="billingContact"
              label="Email address"
              title="Billing Contact"
              onSave={obj => {
                setEditing(false)
                handleEditBillingContact({ id: organization.id, values: obj })
              }}
              showCancel={editing}
              onCancel={() => setEditing(false)}
              value={billingContact}
            />
          </Grid>
        ) : billingContact.email ? (
          <Grid container item xs={12} lg={8} className={classes.billingContact}>
            <Grid item xs>
              <Typography className={classes.billingContactName}>
                {billingContact.firstname} {billingContact.lastname}
              </Typography>
              <Typography>{billingContact.email}</Typography>
              <Typography>{billingContact.jobTitle}</Typography>
            </Grid>
            {!isReadOnly && (
              <Grid item xs="auto">
                <Tooltip title="Edit Billing Contact">
                  <IconButton
                    style={{ marginTop: 16 }} // MUI textfelds have margin top 16
                    onClick={() => setEditing(true)}
                    aria-label="Edit"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>No billing contact has been assigned</Typography>
          </Grid>
        )}
      </Grid>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  addForm: {
    border: `1px solid ${theme.palette.borderGray.main}`,
    margin: '20px 0px',
    padding: 20,
    borderRadius: 5
  },
  billingContact: {
    color: theme.palette.gray.main,
    padding: 20,
    margin: '15px 0px',
    borderRadius: 5,
    backgroundColor: theme.palette.gray5.main
  },
  billingContactName: {
    fontWeight: 'bold'
  },
  textContainer: {
    marginBottom: 16
  }
}))
