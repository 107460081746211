import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import { CONTACT_SUPPORT_URL } from '../utils/constants'
import BillingAlert from '../Organization/Billing/BillingAlert'
import LinkField from '../common/LinkField'
import config from '../config'
import { useSession, useUser, useUserOrganization, useOrganization } from '../auth/queries'
import { useQuery } from '../hooks'
import { GET_USER_PROJECT_GROUPS } from '../ProjectGroups'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 20
  },
  footer: {
    padding: 20,
    backgroundColor: theme.palette.gray3.main
  }
}))

const OrgWelcomeMessage = ({ organization }) => {
  return (
    <div>
      <Typography>
        The TIP Participation account for {organization.name} has been {organization.approvalStatus}
        . If you have any questions, please{' '}
        <LinkField
          color="primary"
          label="contact TIP Support"
          hideIcon={true}
          to={CONTACT_SUPPORT_URL}
        />
      </Typography>
    </div>
  )
}

const UserWelcomeMessage = ({ user, organization, membershipStatus, projectGroupCount }) => {
  let message
  switch (membershipStatus) {
    case 'requested': // membership is 'pending'
      message = (
        <>
          Your TIP Participation request to {organization.name} is pending. If you have any
          questions, please contact the Account Administrator or{' '}
          <LinkField color="primary" label="TIP Support" hideIcon={true} to={CONTACT_SUPPORT_URL} />
          .
        </>
      )
      break
    case 'approved':
      message = (
        <>
          Welcome! You are now a TIP participant as part of the {organization.name} account. Be sure
          to select the{' '}
          <LinkField
            color="primary"
            label="Project Groups"
            to={`/user/${user.id}/project-groups/${organization.id}`}
          />{' '}
          you wish to participate in.
        </>
      )
      break
    case 'suspended':
      message = `Your TIP Participation account with ${
        organization.name
      } has been suspended. If you have any questions, please contact the Account Administrator.`

      break
    case 'declined':
      message = `Your TIP Participation request to ${
        organization.name
      } has been declined. If you have any questions, please contact the Account Administrator.`

      break
    case 'deactivated':
      message = `Your TIP Participation account has been deactivated. If you have any questions, please contact the Account Administrator.`
      break
    default:
      break
  }

  if (membershipStatus === 'approved' && projectGroupCount > 0) {
    // active is approved and in at least one project group
    message = (
      <>
        Thank you for joining TIP Project Group(s). Be sure to visit{' '}
        <LinkField
          color="primary"
          label="Workplace"
          target="_blank"
          rel="noopener noreferrer"
          hideIcon={true}
          to={config.WORKPLACE_SSO_URL}
        />{' '}
        to see Project Group messages, participate in events, and connect with other Project Group
        participants.
      </>
    )
  }
  return (
    <div>
      <Typography>{message}</Typography>
    </div>
  )
}

export default function WelcomeMessage() {
  const { loading, userId, isOrgAdmin } = useSession()

  const { loading: userLoading, user } = useUser({
    variables: { id: userId },
    skip: !userId
  })

  const { loading: userOrgLoading, organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { orgId, membershipStatus } = organizationUser

  const { loading: orgLoading, organization } = useOrganization({
    variables: { id: orgId },
    skip: !orgId
  })

  const { data: { findProjectGroupsByUserId: userProjectGroups = [] } = {} } = useQuery(
    GET_USER_PROJECT_GROUPS,
    { variables: { id: userId }, skip: !userId || membershipStatus !== 'approved ' }
  )

  const classes = useStyles()

  if (loading || userLoading || userOrgLoading || orgLoading) {
    return null
  }

  return (
    <>
      <div data-testid="WelcomeMessage" className={classes.content}>
        {user && user.billingAlertConfirmed
          ? null
          : false && <BillingAlert isOrgAdmin={isOrgAdmin} orgId={orgId} userId={userId} />}
        <Typography gutterBottom variant="h4">
          Welcome to your Telecom Infra Project Account
        </Typography>
        {isOrgAdmin ? (
          <OrgWelcomeMessage organization={organization} />
        ) : (
          <UserWelcomeMessage
            organization={organization}
            user={user}
            membershipStatus={membershipStatus}
            projectGroupCount={userProjectGroups.length}
          />
        )}
      </div>
    </>
  )
}
