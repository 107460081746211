import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'

import {
  Typography,
  Grid,
  Icon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'

import LinkField from '../../../common/LinkField'
import {
  CancelAgreementConfirmationModal,
  RequestSignatureModel,
  RequestSignatureSuccessModal
} from '../../../ProjectGroups/Admin'
import { getAgreementUrl } from '../../../utils/cdnHelper'
import { useMutation } from '../../../hooks'
import {
  CancelledSignatureSection,
  CompletedSection,
  PendingSignatureSection,
  StatusSection
} from '../StatusSections'

import { SEND_PROJECT_GROUP_AGREEMENT_REMINDER } from './queries'

export default function ProjectGroupAgreement({ organizationId, agreement, isReadOnly }) {
  const [expanded, setExpanded] = useState(false)
  const [requestSignatureSuccessModalOpen, setRequestSignatureSuccessModalOpen] = useState(false)
  const [forceRequestSignatureModal, setForceRequestSignatureModal] = useState(false)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const onRequestSignatureSuccess = () => {
    setRequestSignatureSuccessModalOpen(true)
  }

  const {
    envelopeId,
    projectGroup,
    sentTo,
    sentDate,
    signedDate,
    signedBy,
    cancelled,
    cancelledDate
  } = agreement

  const isSigned = signedDate

  let icon = isSigned ? 'check_circle' : 'warning_icon'
  let color = isSigned ? 'blue' : 'orange'

  if (cancelled) {
    icon = 'not_interested'
    color = 'gray'
  }

  const { execute: sendProjectAgreementReminder } = useMutation(
    SEND_PROJECT_GROUP_AGREEMENT_REMINDER,
    {
      onCompleted: data => {
        if (data.sendProjectGroupReminder.result) {
          // actual result here
          enqueueSnackbar('Notification Sent', {
            variant: 'success',
            autoHideDuration: 4500
          })
        }
      }
    }
  )

  const agreementPdfUrl = agreement && isSigned ? agreement.storageUrl : ''

  const charterUrl = projectGroup.charterAgreement
    ? getAgreementUrl(projectGroup.charterAgreement.fileName)
    : projectGroup.agreement
    ? getAgreementUrl(projectGroup.agreement.fileName)
    : ''

  if (charterUrl === '') {
    console.log(
      `ProjectGroup: ${projectGroup.name} ${projectGroup.id}, is missing agreement information`
    )
  }

  function openRequestSignatureModal() {
    setForceRequestSignatureModal(true)
  }

  return (
    <>
      <RequestSignatureModel
        onSuccess={onRequestSignatureSuccess}
        projectGroupName={projectGroup.name}
        projectGroupId={projectGroup.id}
        subTitle="Would you like to send a new signature requested?"
        agreementLink={charterUrl}
        agreementLinkText="Review Project Group Charter"
        type="Project Group"
        forceOpen={forceRequestSignatureModal}
        cancelButtonText="Skip this"
      />
      <RequestSignatureSuccessModal
        type="Project Group"
        agreementType="charter"
        successModalOpen={requestSignatureSuccessModalOpen}
        projectGroupName={projectGroup.name}
      />
      <ExpansionPanel
        defaultExpanded={false}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        className={clsx(classes.panel, { [classes.headerExpanded]: expanded })}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.title}>{projectGroup.name}</Typography>
          <div className={classes.statusIconContainer}>
            <Icon className={clsx(classes.statusIcon, classes[`${color}Icon`])}>{icon}</Icon>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.pgRowContainer}>
          <div>
            {cancelled ? (
              <StatusSection
                email={sentTo.email}
                date={cancelledDate}
                colors={['gray']}
                icons={['not_interested']}
                message=""
                titles={['Signature request cancelled']}
                Sections={[CancelledSignatureSection]}
                isReadOnly={isReadOnly}
                SubSection={
                  !isReadOnly && (
                    <Grid container>
                      <RequestSignatureModel
                        onSuccess={onRequestSignatureSuccess}
                        projectGroupName={projectGroup.name}
                        projectGroupId={projectGroup.id}
                        subTitle="Would you like to send a new signature requested?"
                        agreementLink={charterUrl}
                        agreementLinkText="Review Project Group Charter"
                        type="Project Group"
                      >
                        {onClick => (
                          <Grid item xs={12} className={classes.actionLink}>
                            <Icon className={classes.smallIcon}>mail_outline</Icon>
                            <LinkField
                              label="Send new signature request"
                              to="#"
                              onClick={onClick}
                            />
                          </Grid>
                        )}
                      </RequestSignatureModel>
                    </Grid>
                  )
                }
              />
            ) : (
              <>
                {!isSigned && (
                  <StatusSection
                    isReadOnly={isReadOnly}
                    email={sentTo.email}
                    sentDate={sentDate}
                    colors={['orange']}
                    icons={['av_timer']}
                    message="Signature is pending. Signing the Charter will allow your organization to participate in the project group. Without this signed agreement your organization cannot participate in the project group."
                    titles={['Pending Signature']}
                    Sections={[PendingSignatureSection]}
                    SubSection={
                      !isReadOnly && (
                        <Grid container>
                          <CancelAgreementConfirmationModal
                            organizationId={organizationId}
                            projectGroupId={projectGroup.id}
                            envelopeId={envelopeId}
                            onSuccess={openRequestSignatureModal}
                          >
                            {onClick => (
                              <Grid item xs={12} className={classes.actionLink}>
                                <Icon className={classes.smallIcon}>not_interested</Icon>
                                <LinkField
                                  onClick={onClick}
                                  to="#"
                                  label="Cancel signature request"
                                />
                              </Grid>
                            )}
                          </CancelAgreementConfirmationModal>

                          <Grid item xs={12} className={classes.actionLink}>
                            <Icon className={classes.smallIcon}>mail_outline</Icon>
                            <LinkField
                              label="Resend Docusign notification email"
                              to="#"
                              onClick={() =>
                                sendProjectAgreementReminder({
                                  values: {
                                    organizationId: organizationId,
                                    projectGroupId: projectGroup.id,
                                    envelopeId: agreement.envelopeId,
                                    subGroup:
                                      agreement.agreementType === 'ProjectGroupAgreement'
                                        ? true
                                        : false
                                  }
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      )
                    }
                  />
                )}
                {isSigned && (
                  <StatusSection
                    isReadOnly={isReadOnly}
                    colors={['blue']}
                    icons={['check_circle']}
                    titles={['Complete']}
                    Sections={[CompletedSection]}
                    text="Signed on"
                    date={signedDate}
                    email={signedBy}
                  />
                )}
              </>
            )}
          </div>

          {agreementPdfUrl && (
            <div>
              <Grid item xs={12} className={classes.downloadPdfLink}>
                <Icon className={classes.downloadIcon}>get_app</Icon>
                <LinkField
                  label={`${projectGroup.name} Charter`}
                  to={agreementPdfUrl}
                  hideIcon={true}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Grid>
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  actionLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    fontSize: 12
  },
  smallIcon: {
    marginRight: 10,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  pgRowContainer: {
    flexDirection: 'column'
  },
  headerExpanded: {
    background: `#FDFDFD 0% 0% no-repeat padding-box`
  },
  panel: {
    border: 'none',
    boxShadow: 'none'
  },

  statusIconContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex'
  },
  statusIcon: {
    fontSize: 18
  },
  orangeIcon: {
    color: `#EB6F53`
  },
  blueIcon: {
    color: theme.palette.primary.main
  },
  grayIcon: {
    color: '#8A8A8A'
  },
  downloadPdfLink: {
    marginTop: 18,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20
    }
  },
  downloadIcon: {
    marginRight: 5,
    fontSize: 24,
    color: theme.palette.primary.main
  }
}))
