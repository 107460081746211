import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core/'
import BusinessIcon from '@material-ui/icons/Business'
import clsx from 'clsx'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'

import { OrganizationAutocomplete, Progress, RoundedButton } from '../common'
// import CreateNewOrgAffilliate from './CreateNewOrgAffilliate'
import { useMutation, useFormStyles } from '../hooks'
import { errorMessages } from '../utils'
import { useSession, getSessionQueries } from '../auth/queries'

export default function SelectOrganization({ updateUser }) {
  const formClasses = useFormStyles()
  const classes = useStyles()

  const [
    formState,
    {
      // radio,
      text
    }
  ] = useFormState({ organization: {} })
  const [
    disableSubmitFromChild
    // setDisableSubmitFromChild
  ] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorOrgName, setErrorOrgName] = useState('')

  const { loading: loadingSession, userId } = useSession()

  const newOrg = formState.values.organization.label && !formState.values.organization.id

  const options = userId
    ? {
        refetchQueries: getSessionQueries(userId),
        awaitRefetchQueries: true
      }
    : {}

  const { loading: joinOrgLoading, execute: requestToJoin, error: joinOrgError } = useMutation(
    REQUEST_TO_JOIN_ORGANIZATION,
    {
      ...options,
      onCompleted: data => data.requestToJoinOrganization.result && updateUser(5)
    }
  )

  const { loading: createOrgLoading, execute: createOrg, error: createOrgError } = useMutation(
    CREATE_ORGANIZATION,
    {
      ...options,
      onCompleted: data => data.createOrganization.result && updateUser(4)
    }
  )

  useEffect(() => {
    if (joinOrgError) {
      console.error(joinOrgError.graphQLErrors[0].message)

      setErrorMessage(errorMessages.TIPSupport)
    } else if (createOrgError) {
      console.error(createOrgError.graphQLErrors[0].message)

      if (
        createOrgError.graphQLErrors[0].message ===
        'Organization name exists in our system, please contact support'
      ) {
        setErrorMessage(
          `An organization named ${errorOrgName} exists in our system, please contact support`
        )
      } else {
        setErrorMessage(errorMessages.TIPSupport)
      }
    }
  }, [createOrgError, joinOrgError, errorOrgName])

  if (loadingSession) {
    return <Progress />
  }

  function chooseOrganization(e) {
    e.preventDefault()

    // Add support for affiliate fields

    setErrorOrgName(formState.values.organization.label)

    if (newOrg) {
      createOrg({
        values: {
          organization: formState.values.organization.label
        }
      })
    } else {
      requestToJoin({
        values: {
          orgId: formState.values.organization.id,
          userId
        }
      })
    }
  }

  const inputs = {
    organization: {
      InputComponent: OrganizationAutocomplete,
      inputType: text,
      label: 'Organization Name',
      name: 'organization',
      validator: {
        required: true
      },
      setField: formState.setField
    }
    // isAffilliate: {
    //   visible: newOrg,
    //   InputComponent: CreateNewOrgAffilliate,
    //   gridWidth: { xs: 12 },
    //   inputType: radio,
    //   name: 'isAffilliate',
    //   testid: 'createNewOrgCheckbox',
    //   labelPlacement: 'end',
    //   validator: {
    //     required: newOrg
    //   },
    //   setFormState: setFormStateCallback,
    //   setDisableSubmitFromChild
    // }
  }

  let disableSubmit = !formState.values.organization.id && !formState.values.organization.label

  return (
    <>
      <div className={classes.header}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          <span className={classes.orgIconWrapper}>
            <BusinessIcon className={classes.orgIcon} fontSize="large" />
          </span>{' '}
          Organization Profile
        </Typography>
        <Typography paragraph>
          Enter the name of your organization. If it is already a TIP participant, it will appear in
          the dropdown list.
        </Typography>
      </div>
      {errorMessage && (
        <Typography role="alert" color="error">
          {errorMessage}
        </Typography>
      )}
      <form onSubmit={chooseOrganization}>
        <Grid container className={formClasses.spacingBottom} justify="center" spacing={2}>
          {Object.entries(inputs).map(
            ([name, { gridWidth = { xs: 12 }, InputComponent, visible = true, ...args }]) =>
              visible && (
                <Grid item key={name} {...gridWidth}>
                  <InputComponent
                    error={
                      typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                    }
                    errorHelperText={formState.errors[name]}
                    value={formState.values[name]}
                    {...args}
                  />
                </Grid>
              )
          )}
        </Grid>
        <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
          {joinOrgLoading || createOrgLoading ? (
            <div className={formClasses.progress}>
              <Progress size={30} />
            </div>
          ) : (
            <RoundedButton
              fullWidth
              size="medium"
              onClick={chooseOrganization}
              disabled={disableSubmit || disableSubmitFromChild}
              data-testid="JoinOrganizationBtn"
              type="submit"
            >
              {newOrg ? 'Next: Organization Contact Info' : 'Next: Summary'}
            </RoundedButton>
          )}
        </div>
        <div className={classes.captionWrapper}>
          <Typography variant="caption">Your progress will be saved</Typography>
        </div>
      </form>
    </>
  )
}

export const REQUEST_TO_JOIN_ORGANIZATION = gql`
  mutation($input: OrganizationUserInput!) {
    requestToJoinOrganization(input: $input) {
      data
      result
    }
  }
`
export const CREATE_ORGANIZATION = gql`
  mutation($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      result
      data
    }
  }
`

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginBottom: 0
  },
  captionWrapper: {
    paddingLeft: theme.spacing(1.5),
    textAlign: 'start'
  },
  orgIcon: {
    fontSize: '1.625rem',
    verticalAlign: 'top'
  },
  orgIconWrapper: {
    border: '1px solid black',
    borderRadius: '50%',
    display: 'inline-block',
    height: 36,
    padding: 4,
    verticalAlign: 'bottom',
    width: 36
  }
}))
