import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import LinkField from '../../../common/LinkField'
import GroupActivationRequestsCard from './GroupActivationRequestsCard'
import { useQuery } from '../../../hooks'
import {
  GET_ORGANIZATION_USER_PROJECT_GROUP_REQUESTS,
  GET_ORG,
  GET_ORGANIZATION_PROJECT_GROUPS,
  GET_USER_PROJECT_GROUPS
} from '../queries'

export default function GroupActivationRequests({ isOrgAdmin, orgId, setOpen }) {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { organization },
    loading: organizationQueryLoading
  } = useQuery(GET_ORG, { variables: { id: orgId } })

  const {
    loading: projectGroupRequestsLoading,
    data: { userProjectGroupRequests: pgRequests = [] } = {}
  } = useQuery(GET_ORGANIZATION_USER_PROJECT_GROUP_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: { id: orgId }
  })

  const {
    data: { findProjectGroupsByOrganizationId: organizationProjectGroups = [] } = {}
  } = useQuery(GET_ORGANIZATION_PROJECT_GROUPS, { variables: { id: orgId } })

  const orgAdminId =
    organization &&
    organization.organizationRoles &&
    organization.organizationRoles.organizationAdmin
      ? organization.organizationRoles.organizationAdmin.id
      : null

  const {
    loading: userProjectGroupsLoading,
    data: { findProjectGroupsByUserId: userProjectGroups = [] } = {}
  } = useQuery(GET_USER_PROJECT_GROUPS, {
    variables: {
      id: orgAdminId
    },
    skip: !orgAdminId
  })

  const onResult = useCallback(
    text => {
      enqueueSnackbar(`Request ${text}`, {
        variant: 'success',
        autoHideDuration: 4500
      })
    },
    [enqueueSnackbar]
  )

  if (organizationQueryLoading || projectGroupRequestsLoading || userProjectGroupsLoading) {
    return null
  }

  let totalRequests = 0

  if (pgRequests) {
    pgRequests.forEach(pg => {
      if (pg.subgroups && pg.subgroups.length > 0) {
        const requests = pg.subgroups.reduce((a, { count }) => a + count, 0) + pg.count

        totalRequests += requests

        pg.subgroups.forEach(sg => {
          const orgSubGroup = organizationProjectGroups.find(
            opg => opg.projectGroupId === sg.projectGroupId
          )
          if (orgSubGroup && orgSubGroup.pending) {
            sg.pending = true
          }
        })
      } else {
        totalRequests += pg.count
      }
      const orgGroup = organizationProjectGroups.find(
        opg => opg.projectGroupId === pg.projectGroupId
      )
      if (orgGroup && orgGroup.pending) {
        pg.pending = true
      }
    })
  }

  return (
    <ExpansionPanel defaultExpanded className={classes.expansionPanel} elevation={0}>
      <ExpansionPanelSummary
        classes={{
          root: classes.panelSummaryRoot,
          content: classes.panelSummaryContent,
          expandIcon: classes.expandIcon
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container alignItems="center">
          <Typography
            className={clsx([
              classes.title,
              { [classes.titleHasRequests]: pgRequests.length === 0 }
            ])}
          >
            Group activation requests
          </Typography>
          {pgRequests.length > 0 && (
            <div className={classes.notificationCountWrapper}>
              <div className={classes.notificationCount}>{totalRequests}</div>
            </div>
          )}
          {projectGroupRequestsLoading && (
            <CircularProgress className={classes.progressCircle} color="primary" size={25} />
          )}
          <LinkField
            className={classes.linkStyle}
            to={`/organization/${orgId}/project-groups`}
            label="Manage project groups"
          />
        </Grid>
        <Grid container>
          <Typography>
            Unactivated groups that your organization participants would like to participate in.
          </Typography>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.container}>
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12}>
              <Divider />
              <Grid container className={classes.headerWrapper} spacing={2}>
                <Grid item xs={8}>
                  <Typography className={classes.header}>Group name</Typography>
                </Grid>
                <Grid container item justify="center" xs={2}>
                  <Typography className={classes.header}># of requests</Typography>
                </Grid>
                <Grid className={classes.flexEnd} item xs={2}>
                  <Typography className={classes.header}>Actions</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          {pgRequests.map((request, i) => (
            <Grid item key={i} xs={12}>
              <Divider />
              {!organization || organizationQueryLoading ? (
                <CircularProgress className={classes.progressCircle} color="primary" size={25} />
              ) : (
                <GroupActivationRequestsCard
                  isOrgAdmin={isOrgAdmin}
                  orgAdminId={organization.organizationRoles.organizationAdmin.id}
                  onResult={onResult}
                  orgId={orgId}
                  request={request}
                  setOpen={setOpen}
                  userProjectGroups={userProjectGroups}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0
  },
  expandIcon: {
    position: 'relative',
    bottom: '10px'
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`,
    margin: '0 !important',
    marginBottom: '32px !important'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray12.main
  },
  headerWrapper: {
    padding: theme.spacing(2)
  },
  linkStyle: {
    color: theme.palette.secondary.main
  },
  notificationCount: {
    alignItems: 'center',
    background: theme.palette.dashboardNotification.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: 25,
    justifyContent: 'center',
    width: 25
  },
  notificationCountWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: 'auto'
  },
  panelSummaryContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  panelSummaryRoot: {
    background: theme.palette.gray13.main
  },
  progressCircle: {
    left: 'calc(50% - 30px)',
    position: 'absolute',
    top: 'calc(50% - 30px)'
  },
  title: {
    fontWeight: 'bold'
  },
  titleHasRequests: {
    marginRight: 'auto'
  }
}))
