import gql from 'graphql-tag'

export const SEND_PARTICIPATION_AGREEMENT_FOR_SIGNATURE = gql`
  mutation($input: RequestParticipationAgreementSignatureInput!) {
    requestParticipationAgreementSignature(input: $input) {
      success
      signatureAgreement {
        name
        envelopeId
        sentDate
        signedDate
        signedBy
        counterSignedBy
        sentTo {
          firstname
          lastname
          email
        }
        counterSignedDate
        storageUrl
      }
    }
  }
`

export const REVOKE_PARTICIPATION_AGREEMENT_FOR_SIGNATURE = gql`
  mutation($input: RevokeParticipationAgreementSignatureInput!) {
    revokeParticipationAgreementSignature(input: $input) {
      success
      envelopeId
    }
  }
`

export const SEND_GPA_REMINDER = gql`
  mutation($input: SendGPAReminderInput!) {
    sendGPAReminder(input: $input) {
      success
    }
  }
`

export const REFRESH_DOCUSIGN_STATUS = gql`
  mutation($input: RefreshDocusignStatusInput!) {
    refreshDocusignStatus(input: $input) {
      success
      envelopeId
      error
      status {
        completedDateTime
        deliveredDateTime
        envelopeId
        signingLocation
        sentDateTime
        status
        statusChangedTime
      }
    }
  }
`

export const SIMULATE_PAYMENT = gql`
  mutation($id: ID!) {
    simulatePayment(id: $id) {
      success
    }
  }
`
