import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { formatLongDateTime } from '../../../utils/dateTime'

export default function CancelledSignatureSection({ date }) {
  const classes = useStyles()

  return (
    <Grid container className={classes.section}>
      <Typography className={classes.message}>
        Request cancelled on {formatLongDateTime(date)}
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'block'
  },
  message: {
    fontSize: 14
  }
}))
