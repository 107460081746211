import React, { useReducer, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RestrictedSubGroupRequestsActions from './RestrictedSubGroupRequestsActions'
import RestrictedSubGroupRequestsDialog from './RestrictedSubGroupRequestsDialog'
import EditUserModal from '../../../User/Profile'
import { useMutation } from '../../../hooks'
import { getImageUrl } from '../../../utils'
import {
  APPROVE_RESTRICTED_SUB_GROUP_REQUEST,
  DENY_RESTRICTED_SUB_GROUP_REQUEST
} from '../mutations'
import { GET_RESTRICTED_SUB_GROUP_REQUESTS } from '../queries'

const RestrictedSubGroupRequestsCard = ({ onResult, orgId, request, isReadOnly, setErrorOpen }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)

  // Manually rerender component
  const [, forceRerender] = useReducer(state => {
    return state + 1
  }, 0)

  const editUserOnUpdate = (cache, { data: result }) => {
    const { getRestrictedSubGroupRequests: restrictedRequests = [] } = cache.readQuery({
      query: GET_RESTRICTED_SUB_GROUP_REQUESTS,
      variables: { id: orgId }
    })

    const { editUser, uploadFile } = result

    const updatedData = [...restrictedRequests]

    if (editUser) {
      const { firstname, id, jobTitle, lastname, logo } = editUser

      restrictedRequests.forEach((request, index) => {
        if (request.userId === id) {
          updatedData[index].firstname = firstname
          updatedData[index].jobTitle = jobTitle
          updatedData[index].lastname = lastname
          updatedData[index].logo = logo
        }
      })
    } else if (uploadFile) {
      const { id, name } = uploadFile

      restrictedRequests.forEach((request, index) => {
        if (request.userId === id) {
          updatedData[index].logo = name
        }
      })
    }

    if (editUser || uploadFile) {
      cache.writeQuery({
        query: GET_RESTRICTED_SUB_GROUP_REQUESTS,
        data: { getRestrictedSubGroupRequests: updatedData }
      })

      forceRerender()
    }
  }

  const approveOnCompleted = data => {
    const result = data.approveRestrictedSubGroupRequest.result

    if (result) {
      onResult('approved')
    } else {
      setError(true)
    }
  }

  const denyOnCompleted = data => {
    const result = data.denyRestrictedSubGroupRequest.result

    if (result) {
      onResult('denied')
    } else {
      setError(true)
    }
  }

  const { execute: executeApprove, loading: approveLoading, error: approveError } = useMutation(
    APPROVE_RESTRICTED_SUB_GROUP_REQUEST,
    {
      onCompleted: approveOnCompleted,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_RESTRICTED_SUB_GROUP_REQUESTS,
          variables: { id: orgId }
        }
      ]
    }
  )

  const { execute: executeDeny, loading: denyLoading, error: denyError } = useMutation(
    DENY_RESTRICTED_SUB_GROUP_REQUEST,
    {
      onCompleted: denyOnCompleted,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_RESTRICTED_SUB_GROUP_REQUESTS,
          variables: { id: orgId }
        }
      ]
    }
  )

  const data = {
    id: orgId,
    values: {
      email: request.email,
      firstname: request.firstname,
      groupId: request.groupId,
      groupName: request.groupName,
      lastname: request.lastname,
      requestId: request.requestId,
      userId: request.userId
    }
  }

  const approveRequest = () => {
    executeApprove(data)
  }

  const denyRequest = () => {
    executeDeny(data)
  }

  if (approveError || denyError) {
    setErrorOpen(true)
  }

  return (
    <div className={classes.root}>
      <RestrictedSubGroupRequestsDialog open={open} setOpen={setOpen} denyRequest={denyRequest} />
      <Grid container alignItems="center" className={classes.card} spacing={2}>
        <Grid container item alignItems="center" xs={5}>
          <Grid item className={classes.avatarWrapper}>
            {request.logo ? (
              <Avatar
                alt="Profile Icon"
                className={classes.avatar}
                src={getImageUrl(request.logo)}
              />
            ) : (
              <Avatar alt="Proifle Icon" className={classes.avatarBlank}>
                <PersonOutlinedIcon className={classes.iconBlank} />
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <EditUserModal id={request.userId} onUpdate={editUserOnUpdate} returnUrl={'/'}>
              {onClick => (
                <Typography className={classes.nameLink} onClick={onClick}>
                  {`${request.firstname} ${request.lastname}`.trim()}
                </Typography>
              )}
            </EditUserModal>
            <Typography variant="caption">
              {request.jobTitle}
              <br />
              {request.orgName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Hidden mdUp>
            <Typography className={classes.header}>Sub-group</Typography>
          </Hidden>
          <Typography>{request.groupName}</Typography>
          {error && <Typography className={classes.errorText}>Something went wrong</Typography>}
        </Grid>
        <Grid className={classes.flexEnd} item xs={2}>
          <RestrictedSubGroupRequestsActions executeApprove={approveRequest} setOpen={setOpen} />
        </Grid>
      </Grid>
      <Backdrop classes={{ root: classes.backdropRoot }} open={approveLoading || denyLoading} />
      <Hidden xsUp={!approveLoading && !denyLoading}>
        <CircularProgress className={classes.progressCircle} color="primary" size={60} />
      </Hidden>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(7),
    width: theme.spacing(7)
  },
  avatarBlank: {
    background: 'white',
    borderRadius: '50%',
    border: '1px solid gray',
    height: theme.spacing(7),
    width: theme.spacing(7)
  },
  avatarWrapper: {
    marginRight: theme.spacing(2)
  },
  backdropRoot: {
    bottom: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 0
  },
  buttonApprove: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.linkHover.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white'
    }
  },
  buttonApproveFull: {
    background: theme.palette.approveButton.background,
    color: theme.palette.gray8.main,
    fontWeight: 'bold'
  },
  buttonApproveFullIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonDeny: {
    border: `1px solid ${theme.palette.denyButton.main}`,
    color: theme.palette.denyButton.main,
    '&:hover': {
      background: theme.palette.denyButton.hover,
      border: `1px solid ${theme.palette.denyButton.main}`,
      color: 'white'
    }
  },
  buttonDenyFull: {
    background: theme.palette.denyButton.background,
    color: theme.palette.gray8.main,
    fontWeight: 'bold'
  },
  buttonDenyFullIcon: {
    border: `1px solid ${theme.palette.denyButton.main}`,
    borderRadius: '50%',
    color: theme.palette.denyButton.main,
    marginRight: theme.spacing(1)
  },
  card: {
    padding: theme.spacing(2)
  },
  errorText: {
    color: 'red'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray8.main
  },
  iconBlank: {
    color: 'gray',
    fontSize: 40
  },
  nameLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.linkHover.main,
      cursor: 'pointer'
    }
  },
  progressCircle: {
    left: 'calc(50% - 30px)',
    position: 'absolute',
    top: 'calc(50% - 30px)'
  },
  root: {
    position: 'relative'
  }
}))

export default RestrictedSubGroupRequestsCard
