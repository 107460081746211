import gql from 'graphql-tag'

export const GET_LATEST_SYNC_RESULT_INFO = gql`
  query($input: getLatestSyncResultsInput) {
    getLatestSyncResultInfo(input: $input) {
      data {
        containsErrorData
        containsMultipleData
        containsNonExemptDeprovisionData
        createdDate
        executionMilliseconds
        id
        trigger
        updatedDate
      }
      dataNew
    }
  }
`

export const GET_SYNC_RESULTS = gql`
  query($id: ID) {
    getSyncResults(id: $id) {
      createdDate
      executionMilliseconds
      id
      integrationType
      results {
        deprovisionedBy
        error
        exemptFromDeprovision
        hubId
        hubName
        hubType
        integrationId
        lastUpdatedDate
        status
        subData {
          deprovisionedBy
          error
          exemptFromDeprovision
          hubId
          hubName
          hubType
          integrationId
          lastUpdatedDate
          status
        }
      }
      trigger
      triggeredBy
      updatedDate
    }
  }
`

export const GET_SLACK_INVITE_URL = gql`
  query {
    getSlackInviteUrl
  }
`
