import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

function SimpleDatePicker({ label, formField, selectedDate, handleDateChange, validateDate }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        emptyLabel={label}
        format="MM/dd/yyyy"
        onChange={val => {
          if (validateDate(formField, val)) {
            handleDateChange(formField, val)
          } else {
            console.log('cannot validate')
          }
        }}
        value={selectedDate}
      />
    </MuiPickersUtilsProvider>
  )
}

export default SimpleDatePicker
