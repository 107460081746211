import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export default function ProjectGroupAddedModal({
  onClose,
  projectGroups = [],
  isOrg = false,
  bodyText,
  okURL,
  okLabel,
  subTitleText
}) {
  const [open, setOpen] = React.useState(true)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
    onClose()
  }

  let projectGroupsCopy = projectGroups.map((group, key) => (
    <Typography className={classes.bold} key={key}>
      {group}
    </Typography>
  ))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Success
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <div>
            <Typography className={classes.subTitleText}>{subTitleText}</Typography>
            <Typography>{bodyText}</Typography>
            {projectGroupsCopy}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} className={classes.button} classes={{ root: classes.root }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  outlined: {
    color: theme.palette.primary.main
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  subTitleText: {
    fontWeight: 600
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
}))
