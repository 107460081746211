import React from 'react'
import { makeStyles } from '@material-ui/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles({
  root: {},
  formControl: {
    margin: 5,
    minWidth: 300,
    maxWidth: 877,
    maxHeight: 400
  },
  selectEmpty: {
    marginTop: 5
  },
  selectAndGrow: {
    textAlign: 'center',
    maxWidth: 1080
  }
})

const MultiSelectDropDownField = ({
  inputType,
  name,
  label,
  handleChange,
  options = [],
  required,
  value
}) => {
  const classes = useStyles()
  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Select multiple {...inputType(name)} value={value} onChange={handleChange}>
          {name !== 'badgeRecords'
            ? Object.entries(options).map(([key, { name, id }]) => (
                <MenuItem key={key} value={id} className={classes.selectAndGrow}>
                  {name}
                </MenuItem>
              ))
            : Object.entries(options.badgeRecords).map(
                ([key, { id, badgeId, pgId, testingEvent, dateAwardedMonth, dateAwardedYear }]) => (
                  <MenuItem key={key} value={id} className={classes.selectAndGrow}>
                    {`${testingEvent} | 
                  ${options.projectGroups.find(x => x.id === pgId).name} : 
                  ${options.badges.find(x => x.id === badgeId).name} - 
                  ${dateAwardedMonth} ${dateAwardedYear}`}
                  </MenuItem>
                )
              )}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiSelectDropDownField
