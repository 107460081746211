import gql from 'graphql-tag'

export const SAVE_OFFERING = gql`
  mutation($input: SaveOfferingInput!) {
    saveOffering(input: $input) {
      id
      name
      organization {
        id
        description
      }
      shortDescription
      longDescription
      showOnMarketplace
      previewOnMarketplace
      ocpCertified
      githubLink
      videoUrls
      type
      searchTags
      projectGroups {
        id
        name
      }
      categories {
        name
        id
      }
      badges {
        name
        id
      }
      badgeRecords {
        id
      }
      media {
        type
        imageName
        thumbName
      }
      tags {
        name
        id
      }
    }
  }
`
