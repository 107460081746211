import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import LogoInput from '../../Logo'

const styles = makeStyles(theme => ({
  profileMeta: {
    marginTop: 20,
    display: 'block',
    fontSize: '1.2rem'
  },
  subMeta: {
    fontWeight: 100,
    fontSize: '.9rem'
  },
  margin: {
    margin: theme.spacing(1)
  },
  button: {
    color: '#317e88',
    cursor: 'pointer',
    display: 'inline-block',
    width: '100px'
  },
  percentageComplete: {
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  },
  avatar: {
    height: 'auto',
    width: '100%'
  }
}))

const Header = ({ type, parentId, logo, name, onUpdate, percentageComplete, isReadOnly }) => {
  const classes = styles()

  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        <LogoInput
          changeText={logo ? 'Change Logo' : 'Add Logo'}
          removeText="Remove Logo"
          onUpdate={onUpdate}
          logo={logo}
          type={type}
          parentId={parentId}
          avatarClass={classes.avatar}
          isReadOnly={isReadOnly}
        >
          <Typography className={classes.profileMeta} variant="h6">
            {name}
          </Typography>
        </LogoInput>
      </Grid>
    </Grid>
  )
}

export default Header
