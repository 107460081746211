import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { InputTextFieldNew, RoundedButton } from '../../common'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export default function BillingExemptModal({
  billingExemptCurrent,
  formState,
  handleUpdateExemptReason,
  open,
  orgId,
  radio,
  text,
  setBillingExemptCurrent,
  setOpen
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    formState.setField('exemptReason', null)
    formState.setField('otherText', '')
    setBillingExemptCurrent('')
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Billing Exempt
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <RadioGroup {...radio('exemptReason')} value={formState.values.exemptReason}>
          <Typography variant="body2" className={classes.radioHeader}>
            What is the reason for the billing exemption?
          </Typography>
          <FormControlLabel value="tipFoundation" control={<Radio />} label="TIP Foundation" />
          <FormControlLabel
            value="boardOfDirectors"
            control={<Radio />}
            label="Board of Directors"
          />
          <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
          <FormControlLabel value="subsidiary" control={<Radio />} label="Subsidiary" />
          <FormControlLabel value="none" control={<Radio />} label="None (turn off exemption)" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
        {formState.values.exemptReason === 'other' && (
          <InputTextFieldNew inputType={text} name="otherText" value={formState.values.otherText} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <RoundedButton
          fullWidth={false}
          onClick={() => {
            if (formState.values.exemptReason === 'other') {
              handleUpdateExemptReason({
                id: orgId,
                values: { exemptReason: formState.values.otherText }
              })
            } else {
              handleUpdateExemptReason({
                id: orgId,
                values: { exemptReason: formState.values.exemptReason }
              })
            }

            handleClose()
          }}
          className={classes.button}
          classes={{ root: classes.root }}
          disabled={
            !formState.values.exemptReason ||
            formState.values.exemptReason === billingExemptCurrent ||
            (formState.values.exemptReason === 'other' && !formState.values.otherText)
          }
        >
          Submit
        </RoundedButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  selectionButton: {
    width: 200
  },
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  outlined: {
    color: theme.palette.red.main,
    border: `thin solid ${theme.palette.red.main}`
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20,
    paddingLeft: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 30
    }
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  warningText: {
    marginBottom: 5
  }
}))
