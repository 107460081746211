import React from 'react'
import gql from 'graphql-tag'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import queryString from 'query-string'

import { useMutation } from './hooks'

export default function ApproveOrganizationProjectGroupMembership({ match, location }) {
  const projectGroupId = match.params.projectGroupId
  const organizationId = match.params.organizationId

  const { orgName, pgName } = queryString.parse(location.search)

  const { execute, data, error } = useMutation(ADD_ORGANIZATION_TO_ORAN_ALLIANCE_GROUP)

  if (data && data.addOrganizationToOranAllianceGroup.result) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography paragraph>Succesfully Approved!</Typography>
          <Typography paragraph>
            no emails were sent so please email the org as you wish. (Future: we send an email)
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (error) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography paragraph>Error! :(</Typography>
          <Typography paragraph>{error.graphQLErrors[0].message}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography paragraph>
          Are you sure you wish to approve <b>{orgName}</b> into <b>{pgName}</b>?
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            execute({
              values: {
                projectGroupId,
                organizationId
              }
            })
          }
        >
          Yes!
        </Button>
        <br />
        <br />
        <br />
        (currently no way to reject, future thing)
      </Grid>
    </Grid>
  )
}

const ADD_ORGANIZATION_TO_ORAN_ALLIANCE_GROUP = gql`
  mutation addOrganizationToOranAllianceGroup($input: addOrganizationToOranAllianceInput!) {
    addOrganizationToOranAllianceGroup(input: $input) {
      data
      result
    }
  }
`
