import React from 'react'
import { Switch } from 'react-router-dom'

import { ProtectedRoute } from './auth'
import ManageAgreements from './Agreements/ManageAgreements'
import ManageProjectGroups from './ProjectGroups/ManageProjectGroups'
import { ManageOrganizations, SysAdminManageMembers } from './Organization'
import SysAdminManageBilling from './Organization/Billing/SysAdminManageBilling'
import { ManageIntegrations } from './Integrations'
import { roles } from './utils/roleHelper'

function AdminRouter({ match }) {
  return (
    <Switch>
      <ProtectedRoute
        path={`${match.path}/manage-members`}
        requiredRoles={[roles.systemAdmin, roles.readOnlySystemAdmin]} // system admin
        component={SysAdminManageMembers}
      />
      <ProtectedRoute
        path={`${match.path}/manage-billing`}
        requiredRoles={[roles.systemAdmin, roles.readOnlySystemAdmin]} // system admin
        component={SysAdminManageBilling}
      />
      <ProtectedRoute
        path={`${match.path}/manage-organizations`}
        requiredRoles={[roles.systemAdmin, roles.readOnlySystemAdmin]} // system admin
        component={ManageOrganizations}
      />
      <ProtectedRoute
        path={`${match.path}/manage-project-groups`}
        requiredRoles={[roles.systemAdmin, roles.readOnlySystemAdmin]} // system admin
        component={ManageProjectGroups}
      />
      <ProtectedRoute
        path={`${match.path}/manage-agreements`}
        requiredRoles={[roles.systemAdmin, roles.readOnlySystemAdmin]} // system admin
        component={ManageAgreements}
      />
      <ProtectedRoute
        path={`${match.path}/manage-integrations`}
        requiredRoles={[roles.systemAdmin, roles.readOnlySystemAdmin]} // system admin
        component={ManageIntegrations}
      />
    </Switch>
  )
}

export default AdminRouter
