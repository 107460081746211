import React, { useState } from 'react'
import { Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'

import SlackInvite from './SlackInvite'
import SyncIntegration from './SyncIntegration'

const ManageIntegrations = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Integrations
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          variant="scrollable"
          onChange={(e, newValue) => setTabValue(newValue)}
        >
          {integrationSections.map(({ title = '' }, index) => (
            <Tab key={index} label={title} />
          ))}
        </Tabs>
        {integrationSections.map(
          ({ componentProps = {}, SyncComponent = SyncIntegration }, index) =>
            tabValue === index ? (
              <div className={classes.integrationContainer} index={index} key={index}>
                <SyncComponent {...componentProps} />
              </div>
            ) : null
        )}
      </Grid>
    </Grid>
  )
}

const integrationSections = [
  {
    componentProps: {
      integrationType: 'Atlassian'
    },
    title: 'Atlassian'
  },
  {
    componentProps: {
      includeMultipleList: false,
      integrationType: 'Idp'
    },
    title: 'Auth0/User Registration'
  },
  {
    componentProps: {
      integrationType: 'Github'
    },
    title: 'Github'
  },
  {
    componentProps: {
      integrationType: 'Mailgun'
    },
    title: 'Mailgun'
  },
  {
    componentProps: {
      deprovisionProps: {
        disableActions: true
      },
      integrationType: 'Salesforce'
    },
    title: 'Salesforce'
  },
  {
    SyncComponent: SlackInvite,
    title: 'Slack'
  },
  {
    componentProps: {
      includeDeprovisionList: false,
      includeMultipleList: false,
      integrationType: 'Software Renewal'
    },
    title: 'Software Renewal'
  }
]

const useStyles = makeStyles(theme => ({
  integrationContainer: {
    marginTop: 32
  }
}))

export default ManageIntegrations
