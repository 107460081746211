import React from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  box: {
    border: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 20,
    height: 100
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: 15
  },
  number: {
    fontSize: 30
  },
  icon: {
    fontSize: 70
  },
  clickable: {
    cursor: 'pointer'
  }
}))

export default function InfoBlock({ label, number, icon, color, details, onClick }) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.box, { [classes.clickable]: onClick })} onClick={onClick}>
      <div className={classes.info}>
        <div className={classes.left}>
          <div className={classes.label}>{label}</div>
          <div className={classes.number}>{number}</div>
        </div>
      </div>
    </div>
  )
}
