import React, { memo, useEffect, useReducer } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ProjectGroupCard from './ProjectGroupCard'
import { GET_ORG_BILLING_DATA } from '../Organization/Billing/queries'
import { GET_MEMBERSHIP_LEVEL } from '../Organization/Profile/queries'
import { useQuery } from '../hooks'
import { Progress } from '../common'

const tableTitleId = 'tableTitle'

export default function ProjectGroupList({
  filteredProjectGroups = [],
  handleJoinProjectGroups,
  handleLeaveProjectGroup,
  isOrgAdmin,
  joinLoading,
  leaveGroupLoading,
  onParticipationRequestSuccess,
  organizationAgreements,
  orgId,
  orgAdminId,
  projectGroups = [],
  showSuccessModal,
  isReadOnly = false
}) {
  const classes = useStyles()

  const [expandedList, dispatchExpandedList] = useReducer(
    (state, action) => {
      let newState = []
      switch (action.type) {
        case 'CHANGE':
          newState = Array.from(state)

          newState[action.index] = action.isExpanding

          return newState
        case 'CHANGE_ALL':
          for (let i = 0; i < action.length; i++) {
            newState.push(action.isExpanding)
          }

          return newState
        case 'INIT':
          return expandedListInit(action.length)
        default:
          return []
      }
    },
    filteredProjectGroups.length,
    expandedListInit
  )

  const {
    data: { organization: { membership: orgMembership = {} } = {} } = {},
    loading: orgLoading
  } = useQuery(GET_ORG_BILLING_DATA, {
    variables: { id: orgId }
  })

  const { data: { membershipLevel = {} } = {}, loading: membershipLoading } = useQuery(
    GET_MEMBERSHIP_LEVEL,
    {
      skip: !orgMembership.membershipLevelId,
      variables: { id: orgMembership.membershipLevelId }
    }
  )

  useEffect(() => {
    if (expandedList.length !== filteredProjectGroups.length) {
      dispatchExpandedList({
        length: filteredProjectGroups.length,
        type: 'INIT'
      })
    }
  }, [expandedList.length, filteredProjectGroups.length])

  const isShowAll = expandedList.some(p => !p)

  if (orgLoading || membershipLoading) {
    return <Progress />
  }

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table} aria-labelledby={tableTitleId}>
        <TableHead />
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Grid container>
                <Grid item xs>
                  <Typography id={tableTitleId}>
                    Showing {filteredProjectGroups.length} of {projectGroups.length}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    className={classes.openAllButton}
                    endIcon={isShowAll ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    variant="text"
                    onClick={() =>
                      dispatchExpandedList({
                        length: filteredProjectGroups.length,
                        isExpanding: isShowAll,
                        type: 'CHANGE_ALL'
                      })
                    }
                  >
                    {isShowAll ? 'Show all' : 'Hide all'}
                  </Button>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          {filteredProjectGroups.map(({ isAvailable, name, new: isNew, ...rest }, index) => (
            <TableRow key={name} role="checkbox" tabIndex={-1}>
              <TableCell className={classes.tableCell}>
                <ProjectGroupCardMemo
                  canAccessProjectGroups={membershipLevel.canAccessProjectGroups}
                  canAccessSoftwareGroups={membershipLevel.canAccessSoftwareGroups}
                  dispatchExpandedList={dispatchExpandedList}
                  expanded={expandedList[index] || false}
                  handleJoinProjectGroup={handleJoinProjectGroups}
                  handleLeaveGroup={handleLeaveProjectGroup}
                  index={index}
                  isAvailable={isOrgAdmin || isAvailable}
                  isNew={isNew}
                  isOrgAdmin={isOrgAdmin}
                  isReadOnly={isReadOnly}
                  joinLoading={joinLoading}
                  leaveGroupLoading={leaveGroupLoading}
                  name={name}
                  onParticipationRequestSuccess={onParticipationRequestSuccess}
                  orgAdminId={orgAdminId}
                  orgId={orgId}
                  organizationAgreements={organizationAgreements}
                  showSuccessModal={showSuccessModal}
                  {...rest}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const ProjectGroupCardMemo = memo(
  props => <ProjectGroupCard {...props} />,
  (previous, next) => {
    if (Object.keys(previous).length === Object.keys(next).length) {
      let isSame = true

      Object.entries(previous).forEach(p => {
        if (isSame && (!next.hasOwnProperty(p[0]) || p[1] !== next[p[0]])) {
          isSame = false
        }
      })

      return isSame
    } else {
      return false
    }
  }
)

const expandedListInit = length => {
  const newState = []

  for (let i = 0; i < length; i++) {
    newState.push(false)
  }

  return newState
}

const useStyles = makeStyles(theme => ({
  openAllButton: {
    margin: '-6px 0',
    textTransform: 'none'
  },
  table: {
    minWidth: 750,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      tableLayout: 'fixed'
    }
  },
  tableCell: {
    border: 'none',
    padding: theme.spacing(1)
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  title: { fontSize: '1.2rem' }
}))
