import gql from 'graphql-tag'

export const GET_ORGANIZATION_GPA_DATA = gql`
  query getOrganizationGPAData($id: ID!) {
    organization(id: $id) {
      id
      name
      organizationRoles {
        authorizedSigners {
          user {
            id
            firstname
            lastname
            email
            jobTitle
          }
          firstname
          lastname
          email
          jobTitle
        }
        billingContact {
          user {
            id
            email
          }
          email
        }
      }
      signedAgreements {
        name
        envelopeId
        sentDate
        signedDate
        counterSignedBy
        signedBy
        agreementType
        sentTo {
          firstname
          lastname
          email
        }
        counterSignedDate
        storageUrl
      }
      membership {
        paid
      }
    }
  }
`
