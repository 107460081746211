import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-use-form-state'
import {
  Avatar,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'

import { InputTextFieldBak, Progress, RoundedButton } from '../../common'
import { getImageUrl } from '../../utils'

import { GET_APPROVED_MEMBERS_NOT_IN_PROJECT_GROUP } from './queries'
import { useQuery } from '../../hooks'

export default function ApprovedOrgMembersModal({ handleClose, handleConfirm, orgId, pgId }) {
  const classes = useStyles()
  const theme = useTheme()

  const {
    data: { getApprovedMembersNotInProjectGroup: approvedMembers = [] } = {},
    loading
  } = useQuery(GET_APPROVED_MEMBERS_NOT_IN_PROJECT_GROUP, {
    variables: { input: { groupId: pgId, orgId } },
    fetchPolicy: 'network-only'
  })

  const [formState, { text }] = useFormState({ message: '', users: [] })
  const [searchText, setSearchText] = useState('')

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    approvedMembers.forEach((member, index) => {
      if (!member.avatar) {
        if (member.logo) {
          approvedMembers[index].avatar = (
            <Avatar className={classes.avatar} src={getImageUrl(member.logo)} />
          )
        } else {
          approvedMembers[index].avatar = (
            <Avatar
              className={classes.avatar}
              style={{
                background: `hsl(${360 * Math.random()}, 80%, 50%)`
              }}
            >
              {member.firstname.substring(0, 1).toUpperCase()}
              {member.lastname.substring(0, 1).toUpperCase()}
            </Avatar>
          )
        }
      }
    })
  }, [approvedMembers, classes.avatar])

  const startsWith = (a, b) => a.toLowerCase().startsWith(b.toLowerCase())

  const filterOptions = (options, { inputValue }) => {
    const filteredOptions = options
      .filter(option => !formState.values.users.some(user => user.id === option.id))
      .filter(option => {
        if (inputValue) {
          return (
            startsWith(option.email, inputValue) ||
            startsWith(`${option.firstname} ${option.lastname}`, inputValue) ||
            startsWith(option.lastname, inputValue)
          )
        } else {
          return true
        }
      })

    filteredOptions.sort((a, b) => {
      let result = a.firstname.localeCompare(b.firstname)

      if (result === 0) {
        result = a.lastname.localeCompare(b.lastname)
      }

      if (result === 0) {
        result = a.email.localeCompare(b.email)
      }

      return result
    })

    return filteredOptions
  }

  const boldStartsWith = text => {
    const searchTextSegments = searchText.split(' ')

    for (const segment of searchTextSegments) {
      if (segment && startsWith(text, segment)) {
        return (
          <>
            <b>{text.substring(0, segment.length)}</b>
            {text.substring(segment.length)}
          </>
        )
      }
    }

    return text
  }

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={true}>
      <DialogTitle className={classes.dialogTitle}>
        Invite Organization Participants
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <div className={classes.progress}>
            <Progress size={100} delay={0} />
          </div>
        ) : (
          <>
            <div className={classes.container}>
              <Typography>Invite participants to join this Project Group</Typography>
              <Autocomplete
                autoHighlight
                disableClearable
                multiple
                classes={{
                  endAdornment: classes.autocompleteEndAdornment,
                  inputRoot: classes.autocompleteInputRoot
                }}
                className={classes.autocomplete}
                filterOptions={filterOptions}
                inputValue={searchText}
                name="users"
                options={approvedMembers}
                rows={2}
                value={formState.values.users}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    multiline
                    margin="normal"
                    placeholder={formState.values.users.length === 0 ? 'Name or email' : ''}
                    rows={2}
                    variant="outlined"
                  />
                )}
                renderOption={option => (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>{option.avatar}</Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {boldStartsWith(option.firstname)} {boldStartsWith(option.lastname)}
                      </Typography>
                      <Typography variant="caption">{boldStartsWith(option.email)}</Typography>
                    </Grid>
                  </Grid>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      avatar={option.avatar}
                      className={classes.chip}
                      deleteIcon={<CloseIcon className={classes.chipDeleteIcon} />}
                      label={`${option.firstname} ${option.lastname}`}
                      size="small"
                      variant="outlined"
                    />
                  ))
                }
                onChange={(e, newValue) => {
                  formState.setField('users', newValue)
                }}
                onInputChange={(e, newValue) => setSearchText(newValue)}
              />
            </div>
            <div className={classes.container}>
              <Typography>Personalize your invite message (optional)</Typography>
              <InputTextFieldBak
                multiline
                inputType={text}
                name="message"
                rows={2}
                value={formState.values.message}
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <RoundedButton
          className={classes.button}
          fullWidth={false}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </RoundedButton>
        <RoundedButton
          className={classes.button}
          disabled={formState.values.users.length === 0}
          fullWidth={false}
          onClick={() => handleConfirm(formState.values)}
        >
          Send Invite
        </RoundedButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: theme.spacing(1)
    }
  },
  autocompleteEndAdornment: {
    display: 'none'
  },
  autocompleteInputRoot: {
    alignItems: 'flex-start'
  },
  avatar: {
    color: 'white'
  },
  chip: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:hover': {
      background: '#F7FEFF',
      border: '1px solid #3BA9B6',
      color: '#3BA9B6',
      '& .MuiChip-deleteIcon': {
        color: '#3BA9B6'
      }
    }
  },
  chipDeleteIcon: {
    fontSize: '1rem',
    marginLeft: 0,
    marginRight: 4
  },
  closeButton: {
    color: 'white',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  dialogActions: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3)
  },
  dialogContent: {
    padding: `0 ${theme.spacing(5)}px`,
    paddingTop: theme.spacing(1)
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  progress: {
    textAlign: 'center',
    padding: 20
  }
}))
