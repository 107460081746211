import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import { InputTextFieldNew, Progress, RoundedButton } from '../common'
import { GET_SLACK_INVITE_URL } from './queries'
import { UPDATE_SLACK_INVITE_URL } from './mutations'
import { useSession } from '../auth/queries'
import { useMutation, useQuery } from '../hooks'
import { inputRegexes } from '../utils'

const SlackInvite = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { isReadOnlySystemAdmin } = useSession()
  const [formState, { text }] = useFormState({
    inviteUrl: ''
  })

  const {
    data: { getSlackInviteUrl: currentSlackInviteUrl = '' } = {},
    loading: currentSlackInviteUrlLoading
  } = useQuery(GET_SLACK_INVITE_URL)

  const {
    execute: updateSlackInviteUrlExecute,
    loading: updateSlackInviteUrlLoading
  } = useMutation(UPDATE_SLACK_INVITE_URL, {
    onCompleted: ({ updateSlackInviteUrl }) => {
      if (updateSlackInviteUrl) {
        formState.resetField('inviteUrl', '')
        enqueueSnackbar('Slack invite url updated for all project groups', {
          autoHideDuration: 4500,
          variant: 'success'
        })
      }
    },
    refetchQueries: [{ query: GET_SLACK_INVITE_URL }]
  })

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        {currentSlackInviteUrlLoading ? (
          <Grid container item alignContent="center" justify="center" xs={12} sm={6}>
            <Grid item xs="auto">
              <Progress />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs>
            <Typography>Current: {currentSlackInviteUrl}</Typography>
          </Grid>
        )}
      </Grid>
      {!isReadOnlySystemAdmin && (
        <>
          <Grid item xs={12} sm={6}>
            <InputTextFieldNew
              error={
                typeof formState.validity.inviteUrl !== 'undefined' && !formState.validity.inviteUrl
              }
              errorHelperText={formState.errors.inviteUrl}
              inputType={text}
              label="Slack Invite URL"
              margin="none"
              name="inviteUrl"
              touched={formState.touched.inviteUrl}
              validator={{
                regex: inputRegexes.domain,
                regexMessage: 'URL must be a valid URL'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RoundedButton
              disabled={
                !formState.values.inviteUrl ||
                !formState.validity.inviteUrl ||
                updateSlackInviteUrlLoading
              }
              onClick={() =>
                updateSlackInviteUrlExecute({
                  values: {
                    inviteUrl: formState.values.inviteUrl
                  }
                })
              }
            >
              {updateSlackInviteUrlLoading ? <Progress /> : 'Update Slack Invite URL'}
            </RoundedButton>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default SlackInvite
