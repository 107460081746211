import React, { useState, Fragment } from 'react'
import gql from 'graphql-tag'

import TIPDialog from '../../common/TIPDialog'
import { useQuery } from '../../hooks'
import OrganizationInfo from './OrganizationInfo'
import { GET_ORG } from './queries'
import { dropdownElevation, DropdownElevationContext } from '../../common/CheckboxDropdown'

const EditOrganizationForm = ({
  id,
  match,
  returnUrl,
  children,
  onUpdate = (
    cache,
    {
      data: {
        removeFile: { id: removeFileId } = {},
        uploadFile: { name: uploadFileName = '', id: uploadFileId } = {}
      } = {}
    } = {}
  ) => {
    if (removeFileId || uploadFileId) {
      cache.writeFragment({
        id: `Organization:${id}`,
        fragment: gql`
          fragment org on Organization {
            logo
          }
        `,
        data: {
          __typename: 'Organization',
          logo: uploadFileName
        }
      })
    }
  },
  tabIndex
}) => {
  id = id || match.params.id
  // IF rendered by a Route treat as a page instead of modal
  const isModal = !(match && match.params.id)

  const [open, setOpen] = useState(false)

  const { data, error, loading } = useQuery(GET_ORG, {
    variables: { id }
  })

  if (!onUpdate) {
    onUpdate = (cache, { data: result }) => {
      if (result.uploadFile) {
        const { organization } = cache.readQuery({
          query: GET_ORG,
          variables: { id }
        })

        const { name } = result.uploadFile
        const newOrg = { ...organization }

        newOrg.logo = name

        cache.writeQuery({
          query: GET_ORG,
          data: { organization: newOrg }
        })
      }
    }
  }

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  const { organization: organizationData } = data || {}

  let organization = {}
  let orgUsers = []

  if (organizationData) {
    organization = {
      id: organizationData.id,
      name: organizationData.name,
      description: organizationData.description,
      type: organizationData.type,
      serviceOfferings: organizationData.serviceOfferings,
      operatingRegions: organizationData.operatingRegions,
      phoneNumber: organizationData.phoneNumber,
      website: organizationData.website,
      logo: organizationData.logo,
      workTitle: organizationData.workTitle,
      emailAddress: organizationData.emailAddress,
      billingAddress: organizationData.billingAddress,
      shippingAddress: organizationData.shippingAddress,
      membership: organizationData.membership,
      organizationRoles: {
        organizationAdminId: organizationData.organizationRoles.organizationAdmin
          ? organizationData.organizationRoles.organizationAdmin.id
          : null,
        exchangeListingAdminId: organizationData.organizationRoles.exchangeListingAdmin
          ? organizationData.organizationRoles.exchangeListingAdmin.id
          : null,
        primaryContactId: organizationData.organizationRoles.primaryContact
          ? organizationData.organizationRoles.primaryContact.id
          : null,
        billingContact: organizationData.organizationRoles.billingContact
          ? organizationData.organizationRoles.billingContact.user
            ? organizationData.organizationRoles.billingContact.user
            : organizationData.organizationRoles.billingContact
          : null,
        legalDesignee: organizationData.organizationRoles.legalDesignee
          ? organizationData.organizationRoles.legalDesignee.user
            ? organizationData.organizationRoles.legalDesignee.user
            : organizationData.organizationRoles.legalDesignee
          : null,
        authorizedSigners: organizationData.organizationRoles.authorizedSigners
      },
      capabilities: organizationData.capabilities,
      percentageComplete: organizationData.percentageComplete,
      showOnPublicSite:
        organizationData.showOnPublicSite === null ||
        organizationData.showOnPublicSite === undefined
          ? false
          : organizationData.showOnPublicSite,
      hideFromOrganizationSelect: !!organizationData.hideFromOrganizationSelect
    }

    orgUsers = organizationData.users.reduce((acc, user, index) => {
      if (user.status !== 'requested') {
        acc.push({
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          id: user.id
        })
      }

      return acc
    }, [])

    organization.users = orgUsers
  }

  return (
    <Fragment>
      {isModal ? (
        <Fragment>
          {children(handleClickOpen)}
          <TIPDialog open={open} handleClose={handleClose}>
            <DropdownElevationContext.Provider value={dropdownElevation.raised}>
              {!loading && !error && (
                <OrganizationInfo
                  returnUrl={returnUrl}
                  organization={organization}
                  onUpdate={onUpdate}
                  tabIndex={tabIndex}
                />
              )}
            </DropdownElevationContext.Provider>
          </TIPDialog>
        </Fragment>
      ) : (
        <div>
          {!loading && !error && (
            <OrganizationInfo organization={organization} onUpdate={onUpdate} />
          )}
        </div>
      )}
    </Fragment>
  )
}
export default EditOrganizationForm
