import React, { useRef } from 'react'

import MaterialTable from 'material-table'
import { Typography, IconButton, Grid, Divider, Tooltip } from '@material-ui/core'
import { Create } from '@material-ui/icons'

import { makeStyles } from '@material-ui/styles'

import { useQuery } from '../../hooks'
import { useSession } from '../../auth'
import { GET_ORGANIZATION_OFFERING_DATA, GET_ALL_OFFERINGS_DATA } from './queries'
import Progress from '../../common/Progress'
import RoundedButton from '../../common/RoundedButton'
import EditOfferingForm from './EditOfferingForm'

export default function ManageOfferings({ match }) {
  const orgId = match.params.id
  const classes = useStyles()
  const filterValues = useRef({})
  const {
    isExchangeListingsAdmin,
    isImpersonating,
    isOrgAdmin,
    isReadOnlySystemAdmin,
    isSystemAdmin
  } = useSession()

  const readOnly = Boolean(
    isReadOnlySystemAdmin &&
      ((!isSystemAdmin && !isOrgAdmin && !isExchangeListingsAdmin) || isImpersonating)
  )

  const { data: { organization = {} } = {}, loading: loadingOfferingData } = useQuery(
    GET_ORGANIZATION_OFFERING_DATA,
    {
      variables: { id: orgId },
      skip: !orgId
    }
  )

  // WRITE FRAGMENT

  const {
    data: { offerings = [] } = [],
    loading: loadingOfferingsData,
    error: errorOfferingsData
  } = useQuery(GET_ALL_OFFERINGS_DATA, {
    skip: orgId
  })

  // if org admin (or impersonating) load org specific offerings; ELSE load all offerings in db
  const offeringsData = organization.offerings || offerings

  if (loadingOfferingData || loadingOfferingsData) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  if (errorOfferingsData) {
    console.error(errorOfferingsData)
  }

  const toLowerCaseOrBlank = text => {
    if (text) {
      return text.toLowerCase()
    } else {
      return ''
    }
  }

  const sortByOrgName = (a, b) => {
    let aName = toLowerCaseOrBlank(a.organization ? a.organization.name : '')
    let bName = toLowerCaseOrBlank(b.organization ? b.organization.name : '')
    return aName.localeCompare(bName)
  }

  const editOfferingFormProps = ({ rowData } = {}) => {
    if (rowData) {
      const rowOrgId = orgId
        ? orgId
        : rowData.organization && rowData.organization.id
        ? rowData.organization.id
        : null

      return {
        isReadOnly: readOnly,
        offeringId: rowData.id,
        org: {
          id: rowOrgId
        },
        showAllOrgs: !orgId
      }
    }

    return {
      isReadOnly: readOnly,
      org: {
        id: orgId,
        name: organization.name || null,
        logo: ''
      },
      showAllOrgs: !orgId
    }
  }

  const columnDef = [
    {
      title: 'Organization',
      searchable: true,
      defaultSort: 'asc',
      field: 'orgName',
      customSort: sortByOrgName,
      defaultFilter: filterValues.current.orgName || '',
      customFilterAndSearch: (searchText, row, columnDef) => {
        if (row.organization) {
          let value = row.organization.name

          return value
            .toString()
            .toUpperCase()
            .includes(searchText.toUpperCase())
        } else {
          return false
        }
      },
      hidden: Boolean(orgId),
      render: rowData => (
        <div className={classes.hover}>
          <EditOfferingForm {...editOfferingFormProps({ rowData })} className={classes.hover}>
            {onClick => (
              <div onClick={onClick}>
                {!orgId && rowData.organization ? rowData.organization.name : organization.name}
              </div>
            )}
          </EditOfferingForm>
        </div>
      )
    },
    {
      title: 'Name',
      searchable: true,
      defaultSort: 'desc',
      field: 'name',
      defaultFilter: filterValues.current.name || '',
      render: rowData => (
        <div className={classes.hover}>
          <EditOfferingForm {...editOfferingFormProps({ rowData })} className={classes.hover}>
            {onClick => <div onClick={onClick}>{rowData.name}</div>}
          </EditOfferingForm>
        </div>
      )
    },
    {
      hidden: readOnly,
      render: rowData => (
        <EditOfferingForm {...editOfferingFormProps({ rowData })}>
          {onClick => (
            <Tooltip title="Edit">
              <IconButton onClick={onClick}>
                <Create />
              </IconButton>
            </Tooltip>
          )}
        </EditOfferingForm>
      )
    }
  ]

  return (
    <Grid container>
      <Grid item xs={12} className={classes.header}>
        <Typography gutterBottom variant="h4">
          Manage Offerings
        </Typography>{' '}
      </Grid>
      {!readOnly && (
        <Grid item xs={12}>
          <EditOfferingForm {...editOfferingFormProps()}>
            {onClick => (
              <RoundedButton fullWidth={false} onClick={onClick} color="primary">
                Add New
              </RoundedButton>
            )}
          </EditOfferingForm>
        </Grid>
      )}
      <Grid item xs={12} className={classes.divider}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {offeringsData.length > 0 ? (
          <Grid container>
            <Grid item xs={12}>
              <MaterialTable
                title="Manage Offerings"
                columns={columnDef}
                data={offeringsData}
                options={{
                  filtering: true,
                  pageSize: 10,
                  showTitle: false,
                  draggable: false
                }}
                components={{
                  Toolbar: props => {
                    props.columns.forEach(c => {
                      const field = c.field
                      const filterValue = c.tableData.filterValue
                      if (filterValue) {
                        filterValues.current[field] = filterValue
                      } else if (filterValues.current[field]) {
                        filterValues.current[field] = null
                      }
                    })
                    return null
                  }
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography>
            You have no offerings, add one by clicking the add new button above! <br />* note you
            will currently have to refresh this page after adding to see it appear
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))
