import gql from 'graphql-tag'

export const EDIT_ORG = gql`
  mutation($id: ID!, $input: OrganizationUpdateInput!) {
    editOrganization(id: $id, input: $input) {
      id
      name
      description
      phoneNumber
      website
      logo
      type
      serviceOfferings
      operatingRegions
      workTitle
      emailAddress
      billingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      organizationRoles {
        organizationAdmin {
          id
          email
          firstname
          lastname
        }
        exchangeListingAdmin {
          id
          email
          firstname
          lastname
        }
        primaryContact {
          id
          email
          firstname
          lastname
        }
        billingContact {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
        authorizedSigners {
          user {
            id
            email
            firstname
            lastname
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
      capabilities
      percentageComplete
      showOnPublicSite
      hideFromOrganizationSelect
    }
  }
`

export const ADD_AUTHORIZED_SIGNER = gql`
  mutation($id: ID!, $input: AuthorizedSignerInput!) {
    addAuthorizedSigner(id: $id, input: $input) {
      user {
        id
        email
        firstname
        lastname
      }
      email
      firstname
      lastname
      jobTitle
    }
  }
`

export const REMOVE_AUTHORIZED_SIGNER = gql`
  mutation($id: ID!, $input: AuthorizedSignerInput!) {
    removeAuthorizedSigner(id: $id, input: $input) {
      result
      userId
      email
    }
  }
`

export const REMOVE_LEGAL_DESIGNEE = gql`
  mutation($id: ID!, $input: LegalDesigneeInput!) {
    removeLegalDesignee(id: $id, input: $input) {
      result
      userId
      email
    }
  }
`

export const EDIT_BILLING_ADMIN = gql`
  mutation($id: ID!, $input: BillingContactInput!) {
    editBillingContact(id: $id, input: $input)
  }
`

export const EDIT_AUTHORIZED_SIGNER = gql`
  mutation($id: ID!, $input: AuthorizedSignerInput!) {
    editAuthorizedSigner(id: $id, input: $input) {
      user {
        id
        email
        firstname
        lastname
      }
      email
      firstname
      lastname
      jobTitle
    }
  }
`

export const ADD_BILLING_CONTACT = gql`
  mutation($id: ID!, $input: BillingContactInput!) {
    addBillingContact(id: $id, input: $input) {
      user {
        id
        email
        firstname
        lastname
      }
      email
      firstname
      lastname
      jobTitle
    }
  }
`

export const EDIT_LEGAL_DESIGNEE = gql`
  mutation($id: ID!, $input: LegalDesigneeInput!) {
    editLegalDesignee(id: $id, input: $input)
  }
`

export const ADD_LEGAL_DESIGNEE = gql`
  mutation($id: ID!, $input: LegalDesigneeInput!) {
    addLegalDesignee(id: $id, input: $input) {
      user {
        id
        email
        firstname
        lastname
      }
      email
      firstname
      lastname
      jobTitle
    }
  }
`

export const ADD_MEMBERSHIP_TIER = gql`
  mutation($id: ID!, $input: MembershipTierInput!) {
    addMembershipTier(id: $id, input: $input) {
      membershipLevelId
      status
      startDate
      renewalDate
    }
  }
`
