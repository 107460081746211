import React, { useEffect, useCallback, useState } from 'react'
import { useFormState } from 'react-use-form-state'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'

import { InputTextFieldBak } from '../common'
import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'
import ErrorSection from '../common/ErrorSection'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../utils'
import { getSessionQueries } from './queries'
import { GET_NAV_ITEMS } from '../Navigation/queries'
import { ACTIVATE_NEW_EMAIL, VERIFY_TOKEN_ACTIVATE_EMAIL } from './mutations'
import { history } from '../store'

export default function ActivateNewEmail({ location }) {
  const [formState, { email, password }] = useFormState()
  const [error, setError] = useState()
  const [currentEmail, setCurrentEmail] = useState()
  const [userId, setUserId] = useState()
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const {
    loading: verifyTokenLoading,
    execute: handleVerifyToken,
    data: verifyTokenResult,
    error: verifyTokenError
  } = useMutation(VERIFY_TOKEN_ACTIVATE_EMAIL)

  const options = {
    refetchQueries: [...getSessionQueries(userId), { query: GET_NAV_ITEMS }],
    awaitRefetchQueries: true
  }

  const {
    loading: activateNewEmailLoading,
    execute: handleActivateNewEmail,
    data: {
      activateNewEmail: { result: activateNewEmailResult, data: activateNewEmailData } = {}
    } = {},
    error: activateNewEmailError
  } = useMutation(ACTIVATE_NEW_EMAIL, options)

  useEffect(() => {
    if (!activateNewEmailLoading && activateNewEmailResult) {
      enqueueSnackbar('Your new email address has been successfully activated', {
        variant: 'success',
        autoHideDuration: 4500
      })

      // refresh session query, then redirect on callback
      history.push('/')
    }
  }, [activateNewEmailResult, activateNewEmailLoading, enqueueSnackbar])

  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  let errorMessage = false

  const handleVerifyTokenCallback = useCallback(handleVerifyToken, [])

  function handleSubmit(e) {
    e.preventDefault()
    handleActivateNewEmail({
      id: userId,
      values: {
        ...formState.values,
        currentEmail,
        token
      }
    })
  }

  useEffect(() => {
    if (token) {
      handleVerifyTokenCallback({ values: { token } })
    }
  }, [token, handleVerifyTokenCallback])

  useEffect(() => {
    if (
      verifyTokenResult &&
      verifyTokenResult.verifyActivateNewEmailToken &&
      !formState.values.email
    ) {
      // if we verifed a token set our form email state
      if (!verifyTokenResult.verifyActivateNewEmailToken.result) {
        setError(verifyTokenResult.verifyActivateNewEmailToken.data)
      } else {
        formState.setField('email', verifyTokenResult.verifyActivateNewEmailToken.email)
        setCurrentEmail(verifyTokenResult.verifyActivateNewEmailToken.currentEmail)
        setUserId(verifyTokenResult.verifyActivateNewEmailToken.userId)
      }
    }
  }, [verifyTokenResult, formState])

  if (verifyTokenLoading) {
    // if loading return spinner
    return <Progress />
  }

  if (verifyTokenError) {
    // if error validating token, show error
    errorMessage = 'Your token is invalid or expired.'
  }

  if (activateNewEmailError) {
    // if error during activate, show error
    if (activateNewEmailError.graphQLErrors[0].message) {
      console.error(activateNewEmailError.graphQLErrors[0].message)
      errorMessage = errorMessages.TIPSupport
    } else {
      errorMessage = activateNewEmailData
    }
  }

  const inputs = {
    email: {
      gridWidth: { xs: 12 },
      name: 'email',
      label: 'Email',
      inputType: email,
      autoFocus: true,
      validator: {
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      },
      variant: 'outlined',
      disabled: true
    },
    password: {
      gridWidth: { xs: 12 },
      name: 'password',
      label: 'Password',
      inputType: password,
      validator: {
        required: true
      },
      touchOnChange: true,
      variant: 'outlined'
    }
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  if (error) {
    errorMessage = error
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={7} lg={6} className={formClasses.container}>
          <div className={formClasses.header}>
            <Typography role="heading" variant="h3" className={formClasses.heading}>
              Activate New Email Address
            </Typography>
            <Typography paragraph className={formClasses.description}>
              For security purposes please confirm your new email and password.
            </Typography>
          </div>
          {errorMessage ? (
            <ErrorSection includeSupport>{errorMessage}</ErrorSection>
          ) : (
            <>
              <Grid className={formClasses.spacingBottom} container spacing={2}>
                {Object.entries(inputs).map(([name, args]) => (
                  <Grid key={name} item {...args.gridWidth}>
                    <InputTextFieldBak
                      key={name}
                      {...args}
                      error={formState.errors[name] ? true : false}
                      helperText={formState.errors[name]}
                    />
                  </Grid>
                ))}
              </Grid>
              <RoundedButton
                className={classes.signInButton}
                color="primary"
                variant="contained"
                size="large"
                onClick={handleSubmit}
                disabled={activateNewEmailLoading || disableSubmit}
                type="submit"
              >
                {activateNewEmailLoading ? (
                  <Progress size={30} delay={0} />
                ) : (
                  'Activate New Email Address'
                )}
              </RoundedButton>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  signInButton: {
    width: '100%',
    marginBottom: 0
  }
}))
