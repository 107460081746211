import React, { useState } from 'react'
import { Button, makeStyles, Menu, MenuItem, Toolbar, Tooltip } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { history } from '../store'
import { useSession } from '../auth/queries'
import { systemAdminToolbarHeight } from './'

export default function SystemAdminToolbar() {
  const classes = useStyles()

  const {
    loading,
    isSystemAdmin,
    isReadOnlySystemAdmin,
    isImpersonating,
    isExchangeListingsAdmin
  } = useSession()

  const [anchorSystemAdminEl, setSystemAdminAnchorEl] = useState(null)
  const systemAdminOpen = Boolean(anchorSystemAdminEl)

  if (
    loading ||
    (!isReadOnlySystemAdmin && !isSystemAdmin && !isExchangeListingsAdmin) ||
    isImpersonating ||
    history.location.pathname === '/sli-notice'
  ) {
    return null
  }

  let label = ''
  let tooltip = ''

  const roles = [
    {
      isRole: Boolean(isReadOnlySystemAdmin),
      name: 'Read-Only System Admin',
      abbrev: 'ROSA'
    },
    {
      isRole: Boolean(isSystemAdmin),
      name: 'System Admin',
      abbrev: 'SA'
    },
    {
      isRole: Boolean(isExchangeListingsAdmin),
      name: 'Exchange Marketing Manager Admin',
      abbrev: 'EMM'
    }
  ].filter(r => r.isRole)

  if (roles.length > 1) {
    label = roles.reduce((prev, cur) => {
      if (prev.length > 0) {
        prev += ', '
      }

      prev += cur.abbrev

      return prev
    }, '')
    tooltip = roles.reduce((prev, cur) => {
      if (prev.length > 0) {
        prev += ', '
      }

      prev += cur.name

      return prev
    }, '')
  } else {
    label = roles[0].name
    tooltip = roles[0].name
  }

  return (
    <Toolbar data-testid="SystemAdminBar" classes={{ root: classes.navContainerRootSytemAdmin }}>
      <div className={clsx(classes.grow, classes.systemAdminNav)}>
        <Tooltip title={tooltip}>
          <Button
            aria-label="System Admin Menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={event => {
              if (isReadOnlySystemAdmin || isSystemAdmin) {
                setSystemAdminAnchorEl(event.target)
              }
            }}
          >
            <span>{label}</span>
            {(isReadOnlySystemAdmin || isSystemAdmin) && <ExpandMoreIcon />}
          </Button>
        </Tooltip>
        {(isReadOnlySystemAdmin || isSystemAdmin) && (
          <Menu
            id="menu-appbar"
            anchorEl={anchorSystemAdminEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={systemAdminOpen}
            onClose={() => setSystemAdminAnchorEl(null)}
          >
            <MenuItem
              component={Link}
              onClick={() => setSystemAdminAnchorEl(null)}
              to="/admin/manage-organizations"
            >
              Manage organizations
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={() => setSystemAdminAnchorEl(null)}
              to="/admin/manage-members"
            >
              Manage participants
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={() => setSystemAdminAnchorEl(null)}
              to="/admin/manage-billing"
            >
              Manage billing
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={() => setSystemAdminAnchorEl(null)}
              to="/admin/manage-project-groups"
            >
              Manage project groups
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={() => setSystemAdminAnchorEl(null)}
              to="/admin/manage-agreements"
            >
              Manage agreements
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={() => setSystemAdminAnchorEl(null)}
              to="/admin/manage-integrations"
            >
              Manage integrations
            </MenuItem>
          </Menu>
        )}
      </div>
    </Toolbar>
  )
}

export const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  navContainerRootSytemAdmin: {
    borderTop: '2px solid ' + theme.palette.gray2.main,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    minHeight: systemAdminToolbarHeight,
    height: systemAdminToolbarHeight
  },
  systemAdminNav: {
    backgroundColor: theme.palette.gray2.main,
    height: '100%'
  }
}))
