import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Typography, Grid, Icon } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import { format } from 'date-fns'

import RoundedButton from '../../common/RoundedButton'
import LinkField from '../../common/LinkField'
import { useQuery, useMutation } from '../../hooks'
import Progress from '../../common/Progress'
import ErrorSection from '../../common/ErrorSection'
import { errorMessages } from '../../utils'
import { useSession } from '../../auth/queries'
import { DashboardChecklist } from '../FirstUseLanding/FirstUseLanding'
import landingUseStyles from '../FirstUseLanding/landingStyles'

import { GET_ORGANIZATION_GPA_DATA } from './queries'
import {
  SEND_PARTICIPATION_AGREEMENT_FOR_SIGNATURE,
  REVOKE_PARTICIPATION_AGREEMENT_FOR_SIGNATURE,
  SEND_GPA_REMINDER,
  REFRESH_DOCUSIGN_STATUS
} from './mutations'

import {
  CompletedSection,
  PendingSignatureSection,
  StatusSection,
  SendAgreementSection,
  WeRecievedYourSignature
} from './StatusSections'

export default function ParticipationAgreement({ organizationId, isReadOnly, match, location }) {
  const parameters = { variables: { id: organizationId } }
  const { isSystemAdmin, loading: sessionLoading, session, userId } = useSession()

  const [signer, setSigner] = useState({})
  const [showingAuthRepDropdown, setShowingAuthRepDropdown] = useState(false)

  function selectSigner(event) {
    if (event) {
      const email = event.target.value

      let signer = organization.organizationRoles.authorizedSigners.find(x =>
        x.user ? x.user.email === email : x.email === email
      )

      if (signer.user) {
        signer = signer.user
      }

      setSigner(signer)
    } else {
      setSigner({})
    }
  }

  const {
    loading: organizationLoading,
    data: { organization = {} },
    error: orgError
  } = useQuery(GET_ORGANIZATION_GPA_DATA, parameters)

  const { data, execute: sendGPA, error: sendGPAError, loading: sendGpaLoading } = useMutation(
    SEND_PARTICIPATION_AGREEMENT_FOR_SIGNATURE,
    {
      update: (cache, data) => addParticipationAgreeementToCache(cache, data, organizationId)
    }
  )

  const {
    data: revokeAgreementData,
    execute: revokeAgreement,
    error: revokeGPAError
  } = useMutation(REVOKE_PARTICIPATION_AGREEMENT_FOR_SIGNATURE, {
    onCompleted: () => setSigner({}),
    refetchQueries: [
      {
        onCompleted: true,
        query: GET_ORGANIZATION_GPA_DATA,
        variables: { id: organizationId }
      }
    ]
  })

  const { data: sendGPAReminderData, execute: sendGPAReminder } = useMutation(SEND_GPA_REMINDER, {})
  const {
    loading: statusLoading,
    data: docusignData,
    execute: refreshDocusignStatus
  } = useMutation(REFRESH_DOCUSIGN_STATUS, {})

  const landingClasses = landingUseStyles()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (data && data.requestParticipationAgreementSignature.success) {
      enqueueSnackbar('Signature request has been sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [data, enqueueSnackbar])

  useEffect(() => {
    if (revokeAgreementData) {
      if (revokeAgreementData.revokeParticipationAgreementSignature.success) {
        enqueueSnackbar('Participation Agreement Revoked', {
          variant: 'success',
          autoHideDuration: 4500
        })
      } else if (errorMessage !== errorMessages.TIPSupport) {
        setErrorMessage(errorMessages.TIPSupport)
      }
    }
  }, [revokeAgreementData, enqueueSnackbar, errorMessage])

  useEffect(() => {
    if (sendGPAReminderData && sendGPAReminderData.sendGPAReminder.success) {
      enqueueSnackbar('Reminder Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [sendGPAReminderData, enqueueSnackbar])

  async function handleSendSignatureRequest() {
    if (signer.id) {
      sendGPA({
        values: { organizationId: organization.id, userId: signer.id }
      })
    } else {
      sendGPA({
        values: { organizationId: organization.id, email: signer.email }
      })
    }
  }

  if (organizationLoading) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  if (orgError || sendGPAError || revokeGPAError) {
    console.error(orgError || sendGPAError || revokeGPAError)
    setErrorMessage(errorMessages.TIPSupport)
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const latestAgreement = getLatestGPAAgreement(organization.signedAgreements)

  let isSigned = !!(latestAgreement && latestAgreement.signedDate)
  let isCountersigned = !!(latestAgreement && latestAgreement.counterSignedDate)
  let agreementPdfUrl = latestAgreement && isCountersigned ? latestAgreement.storageUrl : ''

  const icon = 'warning_icon'
  const color = 'orange'

  const docusignSuccess = docusignData && docusignData.refreshDocusignStatus.success
  const docusignRequestError = docusignData && docusignData.refreshDocusignStatus.error
  const docusignStatus = docusignData && docusignData.refreshDocusignStatus.status

  if (docusignStatus && docusignStatus.status === 'completed') {
    isSigned = true
    isCountersigned = true
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <div className={classes.content}>
          <div className={classes.header}>
            <Icon className={classes.icon}>description</Icon>
            <Typography className={classes.agreementTitle} variant="h4">
              General Participation Agreement
            </Typography>
            {!isCountersigned && (
              <div className={classes.statusIconContainer}>
                <Icon className={clsx(classes.statusIcon, classes[`${color}Icon`])}>{icon}</Icon>
              </div>
            )}
          </div>
          {latestAgreement ? (
            <>
              {isSigned ? (
                isCountersigned ? (
                  <StatusSection
                    colors={['blue']}
                    icons={['check_circle']}
                    titles={['Complete']}
                    Sections={[CompletedSection]}
                    date={latestAgreement.counterSignedDate}
                    email={latestAgreement.counterSignedBy}
                  />
                ) : (
                  <StatusSection
                    agreement={latestAgreement}
                    colors={['orange', 'blue']}
                    icons={['av_timer', 'check_circle_outline']}
                    titles={['Pending TIP Signature', "We've Recieved Your Signature"]}
                    Sections={[PendingSignatureSection, WeRecievedYourSignature]}
                    sentDate={latestAgreement.signedDate}
                    signedDate={latestAgreement.signedDate}
                    signedBy={latestAgreement.signedBy}
                  />
                )
              ) : (
                <StatusSection
                  email={latestAgreement.sentTo.email}
                  sentDate={latestAgreement.sentDate}
                  colors={['orange']}
                  icons={['av_timer']}
                  message="Signature is pending. Signing the GPA will allow your organization to 
                  participate in TIP. Without this signed agreement your organization cannot 
                  participate in TIP."
                  titles={['Pending Signature']}
                  Sections={[PendingSignatureSection]}
                  SubSection={
                    !isReadOnly && (
                      <Grid container>
                        <Grid item xs={12} className={classes.actionLink}>
                          <Icon className={classes.smallIcon}>mail_outline</Icon>
                          <LinkField
                            label="Resend Docusign notification email"
                            to="#"
                            onClick={() =>
                              sendGPAReminder({
                                values: {
                                  organizationId: organization.id,
                                  envelopeId: latestAgreement.envelopeId
                                }
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.actionLink}>
                          <Icon className={classes.smallIcon}>cancel</Icon>
                          <LinkField
                            label="Cancel and notify new Authorized Representative(s)"
                            to="#"
                            onClick={() =>
                              revokeAgreement({
                                values: {
                                  organizationId: organization.id,
                                  envelopeId: latestAgreement.envelopeId
                                }
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    )
                  }
                />
              )}
              {isSystemAdmin && latestAgreement.envelopeId && (
                <div className={classes.refreshButtonContainer}>
                  <RoundedButton
                    onClick={() =>
                      refreshDocusignStatus({
                        values: {
                          envelopeId: latestAgreement.envelopeId,
                          organizationId: organization.id
                        }
                      })
                    }
                    disabled={statusLoading}
                    color="primary"
                    fullWidth={false}
                    className={classes.refreshButton}
                  >
                    Refresh Docusign Status
                  </RoundedButton>
                  {docusignData &&
                    (statusLoading ? (
                      <div className={classes.docusignProgress}>
                        <Progress size={25} delay={false} />
                      </div>
                    ) : docusignSuccess ? (
                      <Typography>
                        Status: {toTitleCase(docusignStatus.status)}{' '}
                        {docusignStatus.status === 'completed' &&
                          ` - ${format(
                            new Date(docusignStatus.completedDateTime),
                            'MMMM do, yyyy HH:mm a z'
                          )}`}
                      </Typography>
                    ) : (
                      <Typography>{docusignRequestError}</Typography>
                    ))}
                </div>
              )}
            </>
          ) : (
            <StatusSection
              colors={['orange']}
              handleSendSignatureRequest={handleSendSignatureRequest}
              icons={['warning_icon']}
              message="Please send the General Participation Agreement to an Authorized Representative for
              signature. Signing the GPA will allow your organization to participate in TIP. Without
              this signed agreement your organization cannot participate in TIP."
              orgId={organizationId}
              Sections={[SendAgreementSection]}
              selectSigner={selectSigner}
              sendGpaLoading={sendGpaLoading}
              showingAuthRepDropdown={showingAuthRepDropdown}
              setShowingAuthRepDropdown={setShowingAuthRepDropdown}
              signer={signer}
              titles={[
                showingAuthRepDropdown && !signer.email
                  ? 'Select an authorized representative'
                  : 'Signature request has not been sent'
              ]}
              isReadOnly={isReadOnly}
            />
          )}
          {errorMessage && <ErrorSection>{errorMessage}</ErrorSection>}
          {agreementPdfUrl && (
            <Grid item xs={12} className={classes.downloadPdfLink}>
              <Icon className={classes.downloadIcon}>get_app</Icon>
              <LinkField
                label="General Participation Agreement"
                to={agreementPdfUrl}
                hideIcon={true}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Grid>
          )}
        </div>
      </Grid>
      {!latestAgreement && !sessionLoading && (
        <DashboardChecklist
          editModal
          classes={landingClasses}
          containerProps={{
            className: classes.checklistSpacer,
            item: true,
            xs: 12,
            lg: 6
          }}
          hoverButton={false}
          location={location}
          orgId={match.params.id}
          session={session}
          showGPA={false}
          showRoles={false}
          showPGs={false}
          userId={userId}
        />
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  actionLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    fontSize: 12
  },
  checklistSpacer: {
    marginLeft: 0,
    marginTop: 8,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 8,
      marginTop: -12
    }
  },
  smallIcon: {
    marginRight: 10,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  downloadPdfLink: {
    marginTop: 18,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20
    }
  },
  downloadIcon: {
    marginRight: 5,
    fontSize: 24,
    color: theme.palette.primary.main
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20
    }
  },
  statusIconContainer: {
    flexGrow: 1,
    textAlign: 'right'
  },
  statusIcon: {
    fontSize: 24,
    marginRight: 5,

    [theme.breakpoints.down('xs')]: {
      marginRight: 20
    }
  },
  orangeIcon: {
    color: `#EB6F53`
  },
  icon: {
    marginRight: 10,
    fontSize: 24,
    color: '#737373'
  },
  agreementTitle: {
    fontSize: 16,
    fontWeight: 600
  },
  content: {
    padding: 20,
    background: '#FDFDFD 0% 0% no-repeat padding-box',
    border: '1px solid #E6E6E6',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  progress: {
    textAlign: 'center',
    padding: 20
  },
  agreementInfoLabel: {
    paddingBottom: 6
  },
  cancelAgreementButton: {
    marginTop: 10,
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  sendReminderButton: {
    marginTop: 10,
    marginLeft: 10,
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  refreshButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    fontWeight: 600
  },
  refreshButton: {
    marginRight: 15
  },
  docusignProgress: {
    marginLeft: 40
  }
}))

const addParticipationAgreeementToCache = (cache, payload, organizationId) => {
  const { organization } = cache.readQuery({
    query: GET_ORGANIZATION_GPA_DATA,
    variables: { id: organizationId }
  })

  const { signatureAgreement } = payload.data.requestParticipationAgreementSignature

  if (signatureAgreement) {
    const nextOrganization = { ...organization }
    nextOrganization.signedAgreements.push(signatureAgreement)
    cache.writeQuery({
      query: GET_ORGANIZATION_GPA_DATA,
      data: { organization: nextOrganization }
    })
  }
}

const getLatestGPAAgreement = agreements => {
  // need to make sure the agreement is a GPA agreement.
  const gpaAgreements = agreements.filter(c => c.agreementType === 'GPA' || !c.agreementType)
  const foundAgreement = gpaAgreements && gpaAgreements[gpaAgreements.length - 1]

  return foundAgreement
}
