import { format, differenceInMilliseconds } from 'date-fns'

const DATE_FORMAT = 'yyyy-MM-dd'
const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm a'
const LONG_DATE_TIME_FORMAT = 'MMMM dd yyyy hh:mm a z'

export const formatDate = date =>
  date instanceof Date ? format(date, DATE_FORMAT) : format(new Date(date), DATE_FORMAT)

export const formatDateTime = date =>
  date instanceof Date ? format(date, DATE_TIME_FORMAT) : format(new Date(date), DATE_TIME_FORMAT)

export const formatLongDateTime = date =>
  date instanceof Date
    ? format(date, LONG_DATE_TIME_FORMAT)
    : format(new Date(date), LONG_DATE_TIME_FORMAT)

export const getMilisecondsFromNow = date => {
  if (!date) {
    return 0
  }

  const now = new Date().getTime()
  return differenceInMilliseconds(date, now)
}

export const getMinutesFromNow = date => {
  const now = new Date().getTime()
  const time = date instanceof Date ? date.getTime() : new Date(date).getTime()
  return Math.floor((time - now) / 1000 / 60)
}

export const isMoreThanXMinutesFromNow = (date, minutes) => {
  const minutesFromNow = Number.parseInt(minutes) * 60 * 1000
  return getMinutesFromNow(date) > minutesFromNow
}
