import gql from 'graphql-tag'

export const VERIFY_CHECKOUT_SESSION = gql`
  query($input: VerifyCheckoutSessionInput) {
    verifyCheckoutSession(input: $input) {
      result
    }
  }
`

export const GET_PARTICIPATION_TIER_PRICES = gql`
  query {
    membershipLevels {
      id
      price
      changeRequestPrices {
        id
        previousTier
        price
      }
    }
  }
`
