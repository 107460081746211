import React, { useState } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Typography,
  Tooltip
} from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import WarningIcon from '@material-ui/icons/Warning'

import RequestSignatureModel from './Admin/RequestSignatureModel'
import RequestSignatureSuccessModal from './Admin/RequestSignatureSuccessModal'
import ProjectGroupAdminOptions from './Admin/ProjectGroupAdminOptions'
import CancelAgreementConfirmationModal from './Admin/CancelAgreementConfirmationModal'
import { LinkField, RoundedButton, Status } from '../common'
import ConfirmLeaveDialog from './ConfirmLeaveDialog'
import ConfirmJoinDialog from './ConfirmJoinDialog'
import ConfirmCancelPendingDialog from './ConfirmCancelPendingDialog'
import SubGroupCard from './SubGroupCard'
import { getAgreementUrl } from '../utils/cdnHelper'
import { TooltipHelper } from '../SignUp/GetStartedInfo'

export default function ProjectGroupCard({
  approvalStatus,
  canAccessProjectGroups,
  canAccessSoftwareGroups,
  charterAgreement,
  description,
  detailLink,
  dispatchExpandedList,
  expanded,
  githubTeam,
  handleLeaveGroup,
  handleJoinProjectGroup,
  id,
  index,
  isAvailable,
  isNew,
  isOrgAdmin,
  isReadOnly,
  jira,
  joinedDate,
  joinLoading,
  joinedOrganizations = 0,
  joinedPg = false,
  leaveGroupLoading,
  name,
  onClick,
  onParticipationRequestSuccess,
  organizationAgreements,
  orgAdminId,
  orgId,
  pending,
  restricted,
  subProjectGroups = [],
  softwareProjectGroup
}) {
  const classes = useStyles()
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const onRequestSignatureSuccess = () => {
    setSuccessModalOpen(true)
  }

  let joinedDateString = isOrgAdmin ? 'Activated' : 'Joined'

  if (joinedPg) {
    if (joinedDate) {
      let date = new Date(joinedDate)
      const year = date.getFullYear()
      const month = date.toLocaleString('default', { month: 'long' })
      const day = date.getDate().toString()

      joinedDateString += ` ${month} ${day}, ${year}`
    }
  }

  let charterLocation = null

  if (charterAgreement) {
    charterLocation = getAgreementUrl(charterAgreement.fileName)
  }

  const canAccess = softwareProjectGroup ? canAccessSoftwareGroups : canAccessProjectGroups

  return (
    <>
      <RequestSignatureSuccessModal
        type="Project Group"
        agreementType="charter"
        isOrgAdmin={isOrgAdmin}
        onParticipationRequestSuccess={onParticipationRequestSuccess}
        orgId={orgId}
        projectGroupId={id}
        projectGroupName={name}
        successModalOpen={successModalOpen}
        successJoinAsIndividual={true}
      />
      <ExpansionPanel
        expanded={expanded}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(e, isExpanding) => dispatchExpandedList({ index, isExpanding, type: 'CHANGE' })}
      >
        <ExpansionPanelSummary
          classes={{
            content: classes.expansionSummaryContent
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography className={classes.name}>{name}</Typography>
            </Grid>
            <Grid item xs="auto">
              {isOrgAdmin ? (
                joinedPg && !pending ? (
                  <div className={classes.joinedIndicatorContainer}>
                    <span className={classes.joinedIndicator} />
                    <Typography>Activated</Typography>
                  </div>
                ) : (
                  <div className={classes.joinedIndicatorContainer}>
                    <span className={classes.notJoinedIndicator} />
                    <Typography>Not activated</Typography>
                  </div>
                )
              ) : (
                joinedPg &&
                !pending && (
                  <div className={classes.joinedIndicatorContainer}>
                    <span className={classes.joinedIndicator} />
                    <Typography>Joined</Typography>
                  </div>
                )
              )}
            </Grid>
            {isOrgAdmin &&
              (canAccess ? (
                <Grid item xs="auto" onClick={e => e.stopPropagation()}>
                  <ProjectGroupAdminOptions
                    cancelOptions={{
                      organizationId: orgId,
                      projectGroupId: id
                    }}
                    id={id}
                    joinedPg={joinedPg}
                    joinOptions={{
                      onSuccess: onRequestSignatureSuccess,
                      projectGroupName: name,
                      projectGroupId: id,
                      agreementLink: charterLocation,
                      agreementLinkText: 'Review Project Group Charter',
                      type: 'Project Group'
                    }}
                    leaveOptions={{
                      projectGroupName: name,
                      id,
                      isOrgAdmin,
                      leaveGroupLoading: leaveGroupLoading,
                      orgId,
                      type: 'Project Group',
                      onConfirm: handleLeaveGroup
                    }}
                    orgId={orgId}
                    pending={pending}
                  />
                </Grid>
              ) : (
                <div className={classes.projectGroupAdminOptionsSpacer} />
              ))}
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <div className={classes.heading}>
                {githubTeam && (
                  <Status
                    icon="github"
                    label="GitHub"
                    color="white"
                    styles={classes.status}
                    tooltip=""
                  />
                )}
                {jira && (
                  <Status
                    icon="jira"
                    label="Jira Software"
                    color="white"
                    styles={classes.status}
                    tooltip=""
                  />
                )}
                {isOrgAdmin && pending ? (
                  <Status
                    icon="warning"
                    label="Participation is pending charter signature."
                    color="orangeSeeThrough"
                    styles={classes.status}
                    tooltip=""
                  />
                ) : (
                  <>
                    {joinedPg && !pending ? (
                      <Status
                        icon="checkCircle"
                        label={`${joinedDateString}`}
                        color="blue"
                        styles={classes.status}
                      />
                    ) : (
                      isOrgAdmin && (
                        <Status
                          icon="openCircle"
                          label={`Not ${isOrgAdmin ? 'Activated' : 'Joined'}`}
                          color="gray"
                          styles={classes.status}
                          tooltip="This Project Group is not available for participation by individual participants because your organization has not activated it. Click “Activate Group” to begin the activation process."
                        />
                      )
                    )}
                    {isNew && (
                      <Status icon="new" label="New" color="yellow" styles={classes.status} />
                    )}
                    {restricted && (
                      <Status
                        icon="lock"
                        label="Restricted"
                        color="salmon"
                        styles={classes.status}
                      />
                    )}
                  </>
                )}
              </div>
              <Typography className={classes.description}>{description}</Typography>
              <div className={classes.footer}>
                <div className={classes.links}>
                  {detailLink && (
                    <LinkField
                      to={detailLink}
                      hideBorder={false}
                      className={classes.link}
                      forceExternal
                      label="Details"
                      target="_blank"
                      name={name}
                      linkClasses={classes.link}
                    />
                  )}
                  {charterLocation && (
                    <LinkField
                      to={charterLocation}
                      className={classes.link}
                      label="Project Charter"
                      target="_blank"
                    />
                  )}
                </div>
              </div>
            </Grid>
            {!isReadOnly && (
              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12} className={classes.buttonContainer}>
                    {isOrgAdmin ? (
                      joinedPg ? (
                        pending ? (
                          <CancelAgreementConfirmationModal
                            organizationId={orgId}
                            projectGroupId={id}
                          >
                            {onClick => (
                              <div className={classes.pendingButtonContainer}>
                                <div className={classes.pendingLogo}>
                                  <WarningIcon className={classes.warning} />
                                  <Tooltip
                                    title="This group will be activated once the project charter has been signed."
                                    placement="top"
                                    arrow
                                  >
                                    <Typography className={classes.pending}>
                                      Pending signature
                                    </Typography>
                                  </Tooltip>
                                </div>
                                <RoundedButton
                                  className={classes.selectionButton}
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  fullWidth={false}
                                  onClick={onClick}
                                >
                                  Cancel
                                </RoundedButton>
                              </div>
                            )}
                          </CancelAgreementConfirmationModal>
                        ) : (
                          <ConfirmLeaveDialog
                            projectGroupName={name}
                            id={id}
                            leaveGroupLoading={leaveGroupLoading}
                            type="Project Group"
                            isOrgAdmin={isOrgAdmin}
                            orgId={orgId}
                            onConfirm={handleLeaveGroup}
                          />
                        )
                      ) : (
                        <RequestSignatureModel
                          onSuccess={onRequestSignatureSuccess}
                          projectGroupName={name}
                          projectGroupId={id}
                          agreementLink={charterLocation}
                          agreementLinkText="Review Project Group Charter"
                          type="Project Group"
                        >
                          {onClick => (
                            <div>
                              {!canAccess && (
                                <Typography paragraph className={classes.notAvailableLabel}>
                                  <TooltipHelper
                                    iconClass={classes.notAvailableIcon}
                                    label="Not available at your tier"
                                    labelFirst={false}
                                    title="To access this group, your organization must upgrade its participation tier."
                                  />
                                </Typography>
                              )}
                              <RoundedButton
                                className={classes.selectionButton}
                                disabled={!canAccess}
                                size="small"
                                fullWidth={false}
                                onClick={onClick}
                              >
                                Activate Group
                              </RoundedButton>
                            </div>
                          )}
                        </RequestSignatureModel>
                      )
                    ) : joinedPg ? (
                      <ConfirmLeaveDialog
                        projectGroupName={name}
                        id={id}
                        leaveGroupLoading={leaveGroupLoading}
                        type="Project Group"
                        isOrgAdmin={isOrgAdmin}
                        orgId={orgId}
                        onConfirm={handleLeaveGroup}
                      />
                    ) : approvalStatus === 'requestParticipation' ? (
                      <div className={classes.pendingButtonContainer}>
                        <div className={classes.pendingLogo}>
                          <WarningIcon className={classes.warning} />
                          <Tooltip
                            title="Access will be granted pending activation by your account administrator."
                            placement="top"
                            arrow
                          >
                            <Typography className={classes.pending}>Pending activation</Typography>
                          </Tooltip>
                        </div>
                        <ConfirmCancelPendingDialog
                          id={id}
                          leaveGroupLoading={leaveGroupLoading}
                          isOrgAdmin={isOrgAdmin}
                          onConfirm={() => handleLeaveGroup(id, true)}
                          orgId={orgId}
                          projectGroupName={name}
                          type="Project Group"
                        />
                      </div>
                    ) : (
                      <ConfirmJoinDialog
                        canAccess={canAccess}
                        handleJoinProjectGroup={handleJoinProjectGroup}
                        isAvailable={isAvailable}
                        isOrgAdmin={isOrgAdmin}
                        joinLoading={joinLoading}
                        onParticipationRequestSuccess={onParticipationRequestSuccess}
                        orgId={orgId}
                        projectGroupName={name}
                        projectGroupId={id}
                        type="Project Group"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.sortedJoinedOrganizations}>
                      <BusinessIcon className={classes.businessIcon} />
                      <Typography className={classes.memberOrganizations}>
                        {joinedOrganizations} Participant Organizations
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {subProjectGroups.length > 0 && (
              <Grid item xs={12}>
                <div className={classes.subGroupWrapper}>
                  <Typography>
                    <span className={classes.bold}>
                      Subgroup{subProjectGroups.length > 1 && 's'}
                    </span>{' '}
                    ({subProjectGroups.length})
                  </Typography>
                  <Grid container className={classes.subGroupList}>
                    {subProjectGroups.map((pg, index) => {
                      return (
                        <Grid className={classes.subGroupCard} key={index} item xs={12}>
                          <SubGroupCard
                            {...pg}
                            canAccess={canAccess}
                            isAvailable={isOrgAdmin || pg.isAvailable}
                            isNew={pg.new}
                            isOrgAdmin={isOrgAdmin}
                            isReadOnly={isReadOnly}
                            joinLoading={joinLoading}
                            joinedPg={pg.joinedPg}
                            leaveGroupLoading={leaveGroupLoading}
                            handleJoinProjectGroup={handleJoinProjectGroup}
                            handleLeaveGroup={handleLeaveGroup}
                            onClick={event => {
                              onClick(pg.isAvailable, pg.id)
                            }}
                            organizationAgreements={organizationAgreements}
                            orgAdminId={orgAdminId}
                            orgId={orgId}
                            pending={pg.pending}
                            parentAgreement={charterAgreement}
                            parentIsAvailable={isAvailable}
                            parentPending={pending}
                            parentProjectGroupId={pg.parentProjectGroupId}
                            parentProjectGroupName={name}
                            parentSelectedOrJoined={joinedPg}
                            parentClasses={classes}
                            onParticipationRequestSuccess={onParticipationRequestSuccess}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              </Grid>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  businessIcon: {
    paddingRight: 5,
    color: theme.palette.gray9.main
  },
  button: {
    fontSize: '1.3rem',
    paddingLeft: 5
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center'
    }
  },
  container: {
    boxShadow: '0px 2px 4px #e2e2e2',
    backgroundColor: 'white'
  },
  description: {
    fontSize: '.9rem'
  },
  expansionPanelDetails: {
    paddingBottom: theme.spacing(3)
  },
  expansionPanelSummary: {
    minHeight: '68px !important',
    paddingLeft: 18,
    paddingRight: 28
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    paddingTop: 5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 0
    }
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  joinedIndicator: {
    background: theme.palette.indicatorGreen.main,
    borderRadius: '50%',
    display: 'inline-block',
    height: 10,
    marginRight: 10,
    width: 10
  },
  joinedIndicatorContainer: {
    width: 150,
    '& > *': {
      display: 'inline-block'
    }
  },
  link: {
    marginRight: 20,
    color: theme.palette.secondary.main
  },
  links: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'right'
  },
  memberOrganizations: {
    fontSize: '.8rem'
  },
  name: {
    color: theme.palette.darkGray.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    marginRight: 5
  },
  notAvailableLabel: {
    color: '#2A5589',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  notAvailableIcon: {
    verticalAlign: 'bottom'
  },
  notJoinedIndicator: {
    height: 10,
    width: 10,
    background: theme.palette.navHoverLightGray.main,
    borderRadius: '50%',
    marginRight: 10
  },
  pending: {
    fontWeight: 'bold',
    marginLeft: 10
  },
  pendingButtonContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  pendingLogo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    justifyContent: 'center'
  },
  projectGroupAdminOptionsSpacer: {
    width: 30
  },
  section: {
    padding: '20px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important'
    }
  },
  selectionButton: {
    fontWeight: 'bold',
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: 'auto'
    }
  },
  sortedJoinedOrganizations: {
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.gray8.main,
    fontSize: '12px',
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: 5,
      marginBottom: 20
    }
  },
  status: {
    marginRight: 10
  },
  subGroupCard: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12
    }
  },
  subGroupList: {
    marginTop: 20
  },
  subGroupWrapper: {
    padding: '20px 30px',
    border: `1px solid ${theme.palette.borderGray.main}`,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderLeft: `none`,
      borderBottom: `none`,
      borderRight: `none`
    }
  },
  warning: {
    color: theme.palette.denyButton.main
  }
}))
