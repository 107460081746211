import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  status: {
    padding: 10,
    paddingLeft: 15,
    borderRadius: '20px !important',
    margin: 5,
    textTransform: 'capitalize',
    fontSize: 14,
    color: theme.palette.gray.main,
    minWidth: 130
  },
  label: {
    minWidth: 155
  },
  small: {
    padding: 5,
    paddingLeft: 5,
    borderRadius: '10px !important'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  full: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  associate: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  software: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  approved: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  active: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  accepted: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  none: {
    backgroundColor: theme.palette.deactivated.main,
    ' &:focus': {
      backgroundColor: theme.palette.deactivated.main
    }
  },
  deactivated: {
    backgroundColor: theme.palette.deactivated.main,
    ' &:focus': {
      backgroundColor: theme.palette.deactivated.main
    }
  },
  blocked: {
    backgroundColor: theme.palette.suspended.main,
    ' &:focus': {
      backgroundColor: theme.palette.suspended.main
    }
  },
  suspended: {
    backgroundColor: theme.palette.suspended.main,
    ' &:focus': {
      backgroundColor: theme.palette.suspended.main
    }
  },
  cancelled: {
    backgroundColor: theme.palette.suspended.main,
    ' &:focus': {
      backgroundColor: theme.palette.suspended.main
    }
  },
  'pre-approved': {
    backgroundColor: theme.palette.pending.main,
    ' &:focus': {
      backgroundColor: theme.palette.pending.main
    }
  },
  pending: {
    backgroundColor: theme.palette.pending.main,
    ' &:focus': {
      backgroundColor: theme.palette.pending.main
    }
  },
  requested: {
    backgroundColor: theme.palette.pending.main,
    ' &:focus': {
      backgroundColor: theme.palette.pending.main
    }
  },
  inprogress: {
    backgroundColor: theme.palette.pending.main,
    ' &:focus': {
      backgroundColor: theme.palette.pending.main
    }
  },
  nofly: {
    backgroundColor: theme.palette.nofly.main,
    border: '1px solid gray',
    ' &:focus': {
      backgroundColor: theme.palette.nofly.main
    }
  },
  registered: {
    backgroundColor: theme.palette.nofly.main,
    border: '1px solid gray',
    ' &:focus': {
      backgroundColor: theme.palette.nofly.main
    }
  },
  invited: {
    backgroundColor: theme.palette.invited.main,
    ' &:focus': {
      backgroundColor: theme.palette.invited.main
    }
  },
  declined: {
    backgroundColor: theme.palette.lead.main,
    ' &:focus': {
      backgroundColor: theme.palette.lead.main
    }
  },
  lead: {
    backgroundColor: theme.palette.lead.main,
    ' &:focus': {
      backgroundColor: theme.palette.lead.main
    }
  },
  icon: {
    right: 10
  },
  // for status
  yellow: {
    backgroundColor: theme.palette.pending.main,
    ' &:focus': {
      backgroundColor: theme.palette.pending.main
    }
  },
  green: {
    backgroundColor: theme.palette.success.main,
    ' &:focus': {
      backgroundColor: theme.palette.success.main
    }
  }
}))

export default function StatusDropdown({ options, value, name, onChange }) {
  const classes = useStyles()

  const colorName = name ? name.replace(/\s/g, '') : null

  return options ? (
    <FormControl variant="outlined" fullWidth>
      <Select
        onChange={onChange}
        classes={{ icon: classes.icon }}
        input={
          <InputBase
            value={value}
            className={clsx(classes.statusContainer)}
            inputProps={{
              className: clsx(classes.status, classes[colorName])
            }}
          />
        } // classes here for color
        fullWidth
      >
        {Object.entries(options).map(([index, obj]) => {
          return (
            <MenuItem key={index} value={index} className={classes.capitalize}>
              {obj}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  ) : (
    <div className={clsx(classes.status, classes.label, classes[colorName])}>{name}</div>
  )
}
