import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import gql from 'graphql-tag'

import { LinkField } from '../common'
import { CONFIRM_G5_ALERT } from './mutations'
import { useMutation } from '../hooks'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    marginBottom: 15
  },
  alert: {
    // background: '#FFFBE5',
    border: '1px solid #B5D3F7',
    fontWeight: 'bold'
  },
  supportLink: {
    display: 'inline'
  }
}))

export default function G5Alert({ userId }) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const { execute } = useMutation(CONFIRM_G5_ALERT, {
    update: (cache, { data: { confirmG5Alert: { result, data } = {} } = {} }) => {
      if (result) {
        cache.writeFragment({
          id: `User:${data}`,
          fragment: gql`
            fragment user on User {
              g5AlertConfirmed
            }
          `,
          data: {
            __typename: 'User',
            g5AlertConfirmed: true
          }
        })
      }
    }
  })

  function confirmG5Alert() {
    setOpen(false)
    execute({
      id: userId
    })
  }

  return (
    open && (
      <div className={classes.root}>
        <Alert className={classes.alert} severity="info" onClose={confirmG5Alert}>
          OpenRAN MoU Group Requirements are NOW available.{' '}
          <LinkField
            hideIcon
            className={classes.supportLink}
            label="Learn more"
            onClick={confirmG5Alert}
            to={'https://telecominfraproject.com/OpenRAN-MoU-Group/'}
          />
        </Alert>
      </div>
    )
  )
}
