import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Route, Switch, Redirect } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { Cookies } from 'react-cookie-banner/lib'

import { useScrollRestoration, useGoogleAnalytics } from './utils'
import Home from './Home'
import MaintenancePage from './Home/MaintenancePage'
import Glossies from './Home/Glossies'
import Footer from './Footer'
import { Header, LeftNav, NavSpacer } from './Navigation'
import StayLoggedIn from './auth/StayLoggedIn'
import { SignUp, SignUpMain, AcceptInvitation, SignaturePending } from './SignUp'
import { roles } from './utils/roleHelper'
import { useMutation, useQuery } from './hooks'
import {
  ActivateNewEmail,
  AnonymousRoute,
  AuthIdleTimer,
  Blocked,
  DevAuthorizeEnv,
  ForgotPassword,
  Login,
  Logout,
  ProtectedRoute,
  ResetPassword,
  useSession
} from './auth'
import { REFRESH_SESSION } from './auth/mutations'
import { GET_SESSION } from './auth/queries'
import { Router as UserRouter } from './User'
import { Router as OrganizationRouter, OrganizationDocuments } from './Organization'
import AdminRouter from './AdminRouter'
import CookieBanner from './CookieBanner'
import config from './config'
import './captcha.css'
import ApproveOrganizationProjectGroupMembership from './ApproveOrganizationProjectGroupMembership'
import Payment from './Payment'

import { ManageOfferings } from './Organization'

export const GET_MAINTENANCE_STATUS = gql`
  query {
    maintenanceStatus {
      maintenanceModeOn
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1
  },
  mainContent: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 60px 5px'
    }
  }
}))

const App = () => {
  loadReCaptcha(config.RECAPTCHA_PUBKEY)

  useScrollRestoration()
  useGoogleAnalytics()
  const classes = useStyles()

  const {
    data: { maintenanceStatus = {} }
  } = useQuery(GET_MAINTENANCE_STATUS)

  const { session } = useSession()

  const { execute: refreshSession, loading: refreshSessionLoading } = useMutation(REFRESH_SESSION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_SESSION }]
  })

  const cookies = new Cookies()
  const [authorized, setAuthorized] = useState(
    ['dev', 'demo', 'production'].includes(config.CLIENT_ENV) ||
      cookies.get('hubAuthorized') === 'true'
  )

  const [refreshTimeout, setRefreshTimeout] = useState()

  useEffect(() => {
    if (session && session.expiresAt && !refreshSessionLoading) {
      const timeRemaining = session.expiresAt - Date.now() - 1000 * 60 * 30

      if (!refreshTimeout) {
        if (timeRemaining > 0) {
          setRefreshTimeout(setTimeout(() => refreshSession(), timeRemaining))
        } else {
          refreshSession()
        }
      }
    }
  }, [refreshSession, refreshSessionLoading, refreshTimeout, session])

  if (!authorized) {
    return <DevAuthorizeEnv setAuthorized={setAuthorized} />
  }

  if (maintenanceStatus.maintenanceModeOn) {
    return <MaintenancePage />
  }

  return (
    <AuthIdleTimer>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <main className={classes.content}>
          <LeftNav />
          {/* main component to render */}
          <div className={classes.mainContent}>
            <NavSpacer />
            <Switch>
              <ProtectedRoute path="/" exact component={Home} />
              <AnonymousRoute path="/forgot-password" component={ForgotPassword} />
              <AnonymousRoute path="/get-started" component={SignUp} />
              <Route path="/sli-notice" component={StayLoggedIn} />
              <Route path="/payment" component={Payment} />
              <Route path="/login" component={Login} />
              <Route path="/sign-up" component={SignUpMain} />
              <Route path="/accept-invitation" component={AcceptInvitation} />
              <Route path="/logout" component={Logout} />
              <Route path="/signature-pending" component={SignaturePending} />
              <Route path="/password-reset" component={ResetPassword} />
              <Route path="/activate-new-email" component={ActivateNewEmail} />
              <Route path="/organization" component={OrganizationRouter} />
              <Route path="/documents" component={OrganizationDocuments} />
              <Route path="/user" component={UserRouter} />
              <Route path="/admin" component={AdminRouter} />
              <Route path="/blocked" component={Blocked} />
              <Route path="/glossies" component={Glossies} />
              <ProtectedRoute path={`/tip-exchange`} component={ManageOfferings} />

              <ProtectedRoute
                path="/projectGroup/:projectGroupId/approve-membership/:organizationId"
                requiredRoles={[roles.systemAdmin]}
                component={ApproveOrganizationProjectGroupMembership}
              />
              <Redirect to="/" />
            </Switch>
          </div>
        </main>
        <Footer />
      </div>
      <CookieBanner />
    </AuthIdleTimer>
  )
}

export default App
