import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_PROJECT_GROUPS = gql`
  query {
    projectGroups(includeAll: true) {
      id
      name
    }
  }
`

function useProjectGroups(parameters) {
  const { loading, error, data } = useQuery(GET_PROJECT_GROUPS, parameters)
  return {
    loading,
    error,
    projectGroups: data && data.projectGroups
  }
}

export { useProjectGroups }
