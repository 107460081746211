import React, { useState } from 'react'
import { Prompt } from 'react-router-dom'
import PreventTransitionPrompt from './PreventTransitionPrompt'

export default function RouteGuard({ title, message, when, navigate }) {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  function showModal(location) {
    setModalVisible(true)
    setLastLocation(location)
  }

  function closeModal() {
    setModalVisible(false)
  }

  function handleBlockedNavigation(nextLocation) {
    if (!confirmedNavigation) {
      showModal(nextLocation)
      return false
    }
  }

  function handleConfirmNavigationClick() {
    closeModal()
    setConfirmedNavigation(true)
    navigate(lastLocation.pathname)
  }

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <PreventTransitionPrompt
        title={title}
        message={message}
        visible={modalVisible}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  )
}
