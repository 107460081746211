import React, { useState, useEffect } from 'react'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'

import { useSession } from '../auth/queries'

import Progress from '../common/Progress'
import { InputTextFieldNew } from '../common'
import RoundedButton from '../common/RoundedButton'
import { inputRegexes, isFormSubmitDisabled } from '../utils'
import { useFormStyles, useMutation } from '../hooks'

import { GET_ORG } from '../Organization/Profile/queries'
import { ADD_AUTHORIZED_SIGNER } from '../Organization/Profile/mutations'
import { TooltipHelper } from './GetStartedInfo'

export default function ParticipationAgreement({ updateUser }) {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const [authRepSaved, setAuthRepSaved] = useState(false)

  const { isOrgAdmin, session } = useSession()

  let orgId
  if (isOrgAdmin) {
    orgId = isOrgAdmin.scopes[0].split(':')[1]
  }

  const { loading, execute: handleAddSigner } = useMutation(ADD_AUTHORIZED_SIGNER, {
    onCompleted: data =>
      data.addAuthorizedSigner.user
        ? data.addAuthorizedSigner.user.email && updateUser(9)
        : data.addAuthorizedSigner.email && updateUser(9),
    refetchQueries: [{ query: GET_ORG, variables: { id: orgId } }]
  })

  const participationAgreementFields = {
    firstName: '',
    lastName: '',
    email: '',
    workTitle: ''
  }

  const [formState, { radio, text, email }] = useFormState(participationAgreementFields)

  const authRepInputs = {
    firstName: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12, sm: 6 },
      name: 'firstName',
      label: 'First name',
      inputType: text,
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    lastName: {
      InputComponent: InputTextFieldNew,
      gridWidth: { xs: 12, sm: 6 },
      name: 'lastName',
      label: 'Last name',
      inputType: text,
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    email: {
      InputComponent: InputTextFieldNew,
      name: 'email',
      label: 'Email',
      inputType: email,
      validateOnBlur: false,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      }
    },
    workTitle: {
      InputComponent: InputTextFieldNew,
      name: 'workTitle',
      label: 'Work title',
      inputType: text,
      validateOnBlur: false,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    }
  }

  function clearAuthRepFields() {
    for (let input in authRepInputs) {
      formState.clearField(authRepInputs[input].name)
    }
    setAuthRepSaved(false)
  }

  function handleSave() {
    let values = {}
    if (formState.values.authorizedRep === 'yes') {
      values = { userId: session.user.id }
    } else {
      values = {
        firstname: formState.values.firstName,
        lastname: formState.values.lastName,
        jobTitle: formState.values.workTitle,
        email: formState.values.email
      }
    }

    handleAddSigner({
      id: orgId,
      values: values
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const disableSubmit = isFormSubmitDisabled(authRepInputs, formState)

  return (
    <form>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Participation Agreement
        </Typography>
        <Typography paragraph className={classes.subHeading}>
          Provide the contact information of the organization Authorized{' '}
          <span className={classes.noWrap}>
            Representative
            <TooltipHelper
              iconClass={classes.tooltipIcon}
              label=""
              title={
                'The Authorized Representative is an individual employed by Applicant who has the authority to bind Applicant.  All legal notices from TIP will be sent to the Authorized Representative of record based on the contact information provided by the Applicant as set forth in the TIP General Participation Agreement.'
              }
            />
          </span>
        </Typography>
      </div>
      <Grid container className={formClasses.spacingBottom} justify="center">
        <Grid item>
          <RadioGroup {...radio('authorizedRep')} value={formState.values.authorizedRep}>
            <Typography paragraph variant="body2" className={classes.radioHeader}>
              Are you the organization Authorized Representative?
            </Typography>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
      </Grid>

      {formState.values.authorizedRep === 'no' ? (
        <Grid
          className={clsx(formClasses.spacingBottom, classes.authRepForm)}
          container
          spacing={2}
        >
          {authRepSaved ? (
            <div className={classes.authRepOverviewContainer}>
              <div className={classes.authRepOverviewInfo}>
                <Typography variant="body1" className={classes.overviewName}>
                  {formState.values.firstName + ' ' + formState.values.lastName}
                </Typography>
                <Typography variant="body1">{formState.values.email}</Typography>
                <Typography variant="body1">{formState.values.workTitle}</Typography>
              </div>
              <div className={classes.authRepOverviewDelete}>
                <RoundedButton
                  color="primary"
                  variant="outlined"
                  fullWidth={false}
                  onClick={() => setAuthRepSaved(false)}
                >
                  Edit
                </RoundedButton>
              </div>
            </div>
          ) : (
            <>
              <Typography variant="body1" className={classes.authRepFormHeader}>
                Enter the contact information of an Authorized Representative
              </Typography>
              {Object.entries(authRepInputs).map(
                ([name, { gridWidth = { xs: 12 }, InputComponent, ...args }]) => (
                  <Grid key={name} item {...gridWidth}>
                    <InputComponent
                      {...args}
                      error={
                        typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                      }
                      errorHelperText={formState.errors[name]}
                      inputProps={{
                        style: { background: '#FFFFFF' }
                      }}
                      value={formState.values[name]}
                    />
                  </Grid>
                )
              )}
              {!disableSubmit && (
                <Grid className={classes.authRepButtonContainer} item xs={12}>
                  <RoundedButton
                    className={classes.cancelButton}
                    color="primary"
                    variant="outlined"
                    fullWidth={false}
                    onClick={clearAuthRepFields}
                  >
                    Cancel
                  </RoundedButton>
                  <RoundedButton
                    color="primary"
                    fullWidth={false}
                    onClick={() => setAuthRepSaved(true)}
                  >
                    Save
                  </RoundedButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      ) : null}

      <div className={clsx(formClasses.buttonContainer, classes.buttonContainer)}>
        <RoundedButton
          onClick={handleSave}
          disabled={
            formState.values.authorizedRep === 'yes'
              ? false
              : formState.values.authorizedRep === 'no'
              ? !authRepSaved
              : true
          }
          fullWidth
          size="medium"
          color="primary"
        >
          {loading ? (
            <div className={formClasses.progress}>
              <Progress size={20} />
            </div>
          ) : (
            'Next: Review And Send'
          )}
        </RoundedButton>
        <FormHelperText className={classes.helperText}>Your progress will be saved</FormHelperText>
      </div>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  subHeading: {
    fontSize: 18
  },
  buttonContainer: {
    flexDirection: 'column'
  },
  helperText: {
    marginLeft: 10
  },
  stepCountText: {
    color: theme.palette.gray9.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  radioHeader: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  authRepForm: {
    background: theme.palette.gray10.main,
    border: `1px solid ${theme.palette.gray11.main}`,
    borderRadius: 3,
    padding: '5px 10px'
  },
  authRepFormHeader: {
    padding: 8
  },
  authRepOverviewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  authRepButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  authRepOverviewInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 5,
    position: 'relative'
  },
  cancelButton: {
    marginRight: '10px'
  },
  overviewName: {
    fontWeight: 'bold'
  },
  authRepOverviewDelete: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    paddingRight: 10
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.gray8.main
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tooltipIcon: {
    color: theme.palette.primary.main
  }
}))
