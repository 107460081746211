import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import { Typography, IconButton } from '@material-ui/core'

import RoundedButton from '../../common/RoundedButton'
import ErrorSection from '../../common/ErrorSection'
import LinkField from '../../common/LinkField'
import Progress from '../../common/Progress'
import config from '../../config'

import { useSession, useUserOrganization } from '../../auth/queries'
import { GET_AUTHORIZED_SIGNERS_DATA, GET_ORG_DATA_FOR_SIGNATURE } from './queries'
import { SEND_PROJECT_GROUP_AGREEMENT } from './mutations'
import { useMutation, useQuery } from '../../hooks'
import { GET_ORGANIZATION_PROJECT_GROUPS } from '../../ProjectGroups'
import { GET_PROJECT_GROUP_AGREEMENTS } from '../../Organization/Agreements/ProjectGroupAgreements/queries'

export default function RequestSignatureModel({
  projectGroupId,
  projectGroupName,
  agreementLink,
  onSuccess,
  agreementLinkText = 'Review Agreement',
  type = 'Sub-Group',
  agreementLabel = 'Charter',
  subTitle,
  children,
  cancelButtonText = 'Cancel',
  forceOpen = false,
  restricted = true,
  supportMustVerify = false
}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(forceOpen)
  const [errorMessage, setErrorMessage] = useState(null)
  const { userId } = useSession()

  useEffect(() => {
    setOpen(forceOpen)
  }, [forceOpen])

  const { organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { orgId } = organizationUser

  // get authorized signers
  const { data: { organization: { organizationRoles = {} } = {} } = {} } = useQuery(
    GET_AUTHORIZED_SIGNERS_DATA,
    {
      variables: { id: orgId },
      skip: !orgId
    }
  )

  let authSigner =
    organizationRoles.authorizedSigners && organizationRoles.authorizedSigners.length > 0
      ? organizationRoles.authorizedSigners[0]
      : {}

  if (authSigner.user) {
    authSigner = authSigner.user
  }

  const {
    data: { organization: { name, shippingAddress = {}, website, phoneNumber } = {} } = {}
  } = useQuery(GET_ORG_DATA_FOR_SIGNATURE, {
    variables: { id: orgId },
    skip: !orgId
  })

  const camelToSentence = text => text.replace(/([A-Z])/g, ' $1').toLowerCase()

  let orgInfoComplete = true
  let orgInfoErrorMessageFront = `The organization ${name} is missing these fields:`
  const orgInfoErrorMessageBack =
    'Please complete the organization profile before trying to send agreement.'

  for (let prop in shippingAddress) {
    if (Object.prototype.hasOwnProperty.call(shippingAddress, prop) && !shippingAddress[prop]) {
      let comma = ''

      if (!orgInfoComplete) {
        comma = ','
      }

      orgInfoErrorMessageFront += `${comma} ${camelToSentence(prop)}`
      orgInfoComplete = false
    }
  }

  if (!website) {
    let comma = ''

    if (!orgInfoComplete) {
      comma = ','
    }

    orgInfoErrorMessageFront += `${comma} website`
    orgInfoComplete = false
  }

  if (!phoneNumber) {
    let comma = ''

    if (!orgInfoComplete) {
      comma = ','
    }

    orgInfoErrorMessageFront += `${comma} phone number`
    orgInfoComplete = false
  }

  if (!name) {
    let comma = ''

    if (!orgInfoComplete) {
      comma = ','
    }

    orgInfoErrorMessageFront += `${comma} name`
    orgInfoComplete = false
  }

  orgInfoErrorMessageFront += ';'

  const noSigners =
    !organizationRoles.authorizedSigners || organizationRoles.authorizedSigners.length === 0

  let signerInfoComplete = true
  let signerInfoErrorMessageFront = `This user is missing these fields:`
  const signerInfoErrorMessageBack =
    'Please complete the user profile before trying to send agreement.'

  for (let prop in authSigner) {
    if (
      Object.prototype.hasOwnProperty.call(authSigner, prop) &&
      !authSigner[prop] &&
      prop !== 'user'
    ) {
      let comma = ''

      if (!signerInfoComplete) {
        comma = ','
      }

      signerInfoErrorMessageFront += `${comma} ${camelToSentence(prop)}`
      signerInfoComplete = false
    }
  }

  signerInfoErrorMessageFront += ';'

  const updateProjectGroups = (
    cache,
    { data: { requestProjectGroupAgreementSignature: { success, projectGroupId } = {} } }
  ) => {
    if (success) {
      setOpen(false)

      onSuccess()
    } else {
      setErrorMessage('Something went wrong.')
    }
  }

  const { execute, error, loading } = useMutation(SEND_PROJECT_GROUP_AGREEMENT, {
    update: updateProjectGroups,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: orgId }
      },
      {
        query: GET_PROJECT_GROUP_AGREEMENTS,
        variables: { id: orgId }
      }
    ]
  })

  useEffect(() => {
    if (error && errorMessage !== 'Something went wrong.') {
      setErrorMessage('Something went wrong.')
    }
  }, [error, errorMessage])

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function sendRequest() {
    let sendObj = {
      organizationId: orgId,
      projectGroupId
    }

    if (authSigner.id) {
      sendObj.userId = authSigner.id
    } else {
      sendObj.email = authSigner.email
    }

    execute({
      values: sendObj
    })
  }

  return (
    <>
      {children && children(handleClickOpen)}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        className={classes.margin}
      >
        <DialogTitle className={classes.titleRow}>
          Signature is required to join
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.margin}>
          <Grid container>
            <Grid item xs={12}>
              {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}

              {supportMustVerify ? (
                <>
                  <Typography paragraph>Two steps are required to join this group:</Typography>
                  <Typography>
                    <b>1) Charter signature.</b> Your organization’s Authorized Representative must
                    sign the {!restricted ? 'parent ' : ''}group’s charter.
                  </Typography>
                  <Typography paragraph>
                    <b>2) O-RAN Alliance participation verification.</b> TIP will confirm your
                    organization’s participation in O-RAN Alliance.
                  </Typography>
                </>
              ) : (
                <Typography paragraph>
                  To join the <span className={classes.bold}>{projectGroupName}</span> {type}, your
                  organization’s Authorized Representative must sign its {agreementLabel}. Click
                  “Send Document” to send the document for signature.
                </Typography>
              )}
            </Grid>
            {errorMessage && (
              <Grid item xs={12} className={classes.errorSection}>
                <ErrorSection includeSupport>{errorMessage}</ErrorSection>
              </Grid>
            )}
            <Grid item xs={12}>
              {orgInfoComplete ? (
                noSigners ? (
                  <Typography role="alert" color="error">
                    There is no Authorized Signer associated with your organization.
                    <br /> Please assign an Authorized Signer and return to this screen.
                  </Typography>
                ) : (
                  <div className={classes.notificationContainer}>
                    <Typography>
                      Docusign will notify{' '}
                      <span className={classes.bold}>
                        {authSigner.firstname} {authSigner.lastname}
                      </span>{' '}
                      {authSigner.email}
                    </Typography>
                  </div>
                )
              ) : (
                <Typography role="alert" color="error">
                  {orgInfoErrorMessageFront}
                  <br />
                  {orgInfoErrorMessageBack}
                </Typography>
              )}
            </Grid>

            {signerInfoComplete ? null : (
              <Grid item xs={12}>
                <Typography role="alert" color="error">
                  {signerInfoErrorMessageFront}
                  <br />
                  {signerInfoErrorMessageBack}
                </Typography>
              </Grid>
            )}
            {agreementLink && (
              <Grid item xs={12} className={classes.agreementLink}>
                <LinkField
                  to={agreementLink}
                  className={classes.link}
                  label={agreementLinkText}
                  target="_blank"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <div className={classes.docusignLogoContainer}>
            <img
              alt="DocuSign Logo"
              className={classes.docusignLogo}
              src={`${config.MEDIA_CDN_DOMAIN}/media/docusign_logo.png`}
            />
          </div>
          <RoundedButton
            className={clsx(classes.button, classes.cancelButton)}
            fullWidth={false}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            {cancelButtonText}
          </RoundedButton>
          <RoundedButton
            className={classes.button}
            fullWidth={false}
            onClick={sendRequest}
            variant="contained"
            color="primary"
            disabled={
              loading || !signerInfoComplete || noSigners || !orgInfoComplete || !authSigner.email
            }
          >
            {loading ? <Progress size={25} delay={0} /> : 'Send Document'}
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  titleRow: {
    marginBottom: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  notificationContainer: {
    display: 'flex',
    background: theme.palette.gray5.main,
    padding: 15,
    alignItems: 'center'
  },
  closeIcon: {
    marginLeft: 5,
    cursor: 'pointer'
  },
  select: {
    minWidth: 300,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 240
    }
  },
  subtext: {
    color: theme.palette.gray.main,
    fontSize: '.8rem'
  },
  shrink: {
    bottom: 10,
    position: 'relative'
  },
  agreementLink: {
    marginTop: 30
  },
  docusignLogoContainer: {
    flex: 1
  },
  docusignLogo: {
    marginLeft: -25,
    width: 200,
    height: 'auto'
  },
  cancelButton: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      marginRight: 0
    }
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  actionButtons: {
    padding: 25,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  errorSection: {
    marginBottom: 20
  },
  bold: {
    fontWeight: 600
  },
  subTitle: {
    fontWeight: 600,
    marginBottom: 10
  }
}))
