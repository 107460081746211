import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { getImageUrl } from '../../../utils'

const OrganizationVerificationRequestsDialog = ({ children, logo }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {children(() => setOpen(true))}
      <Dialog open={open} onClose={handleClose}>
        {logo ? (
          <Avatar alt="Profile Icon" className={classes.avatar} src={getImageUrl(logo)} />
        ) : (
          <Avatar alt="Proifle Icon" className={classes.avatarBlank}>
            <PersonOutlinedIcon className={classes.iconBlank} />
          </Avatar>
        )}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 500,
    width: 500,
    cursor: 'pointer'
  },
  avatarBlank: {
    background: 'white',
    borderRadius: '50%',
    border: '1px solid gray',
    height: 500,
    width: 500,
    cursor: 'pointer'
  },
  iconBlank: {
    color: 'gray',
    fontSize: 40
  }
}))

export default OrganizationVerificationRequestsDialog
