import gql from 'graphql-tag'

export const DEPROVISION_INTEGRATION = gql`
  mutation($input: deprovisionIntegrationInput!) {
    deprovisionIntegration(input: $input)
  }
`

export const SAVE_EXEMPT_LIST = gql`
  mutation($input: deprovisionIntegrationInput!) {
    saveExemptList(input: $input)
  }
`

export const SYNC_INTEGRATION = gql`
  mutation($input: syncIntegrationInput!) {
    syncIntegration(input: $input)
  }
`

export const UPDATE_SLACK_INVITE_URL = gql`
  mutation($input: updateSlackInviteUrlInput!) {
    updateSlackInviteUrl(input: $input)
  }
`
