import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_OFFERING_BADGES = gql`
  query {
    badges {
      id
      name
      description
      iconFile
      badgeFile
      order
    }
  }
`

const GET_OFFERING_BADGE_RECORDS = gql`
  query {
    badgeRecords {
      id
      badgeId
      pgId
      name: id
      dateAwardedMonth
      dateAwardedYear
      useCase
      testingEvent
      lab
      documents {
        docType
        title
        link
        accessTier
      }
    }
  }
`

const GET_OFFERING_TAGS = gql`
  query getOfferingTags {
    tags {
      id
      name
    }
  }
`

const GET_OFFERING_TYPES = gql`
  query {
    types {
      id
      name
    }
  }
`

const GET_OFFERING_CATEGORIES = gql`
  query {
    categories {
      id
      name
    }
  }
`

function useOfferingTags(parameters) {
  const { loading, error, data } = useQuery(GET_OFFERING_TAGS, parameters)

  return {
    loading,
    error,
    tags: data && data.tags
  }
}

function useOfferingTypes(parameters) {
  const { loading, error, data } = useQuery(GET_OFFERING_TYPES, parameters)

  return {
    loading,
    error,
    types: data && data.types
  }
}

function useOfferingBadges(parameters) {
  const { loading, error, data } = useQuery(GET_OFFERING_BADGES, parameters)
  return {
    loading,
    error,
    badges: data && data.badges
  }
}

function useOfferingBadgeRecords(parameters) {
  const { loading, error, data } = useQuery(GET_OFFERING_BADGE_RECORDS, parameters)
  return {
    loading,
    error,
    badgeRecords: data && data.badgeRecords
  }
}

function useOfferingCategories(parameters) {
  const { loading, error, data } = useQuery(GET_OFFERING_CATEGORIES, parameters)
  return {
    loading,
    error,
    categories: data && data.categories
  }
}

export {
  useOfferingBadges,
  useOfferingBadgeRecords,
  useOfferingCategories,
  useOfferingTags,
  useOfferingTypes
}
