import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import DescriptionIcon from '@material-ui/icons/Description'
import Typography from '@material-ui/core/Typography'
import CheckboxField from '../common/CheckboxField'
import config from '../config'
import './glossies.css'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  subContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: 15
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    pointerEvents: 'none'
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
    paddingTop: 10,
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 7
    },
    [theme.breakpoints.down('xs')]: {
      flex: 2
    }
  },
  thumbnailImage: {
    height: 200,
    width: 130,
    border: '1px solid #D2D2D2',
    boxShadow: '0px 3px 15px 0px rgba(0,0,0,0.15)',
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  },
  documentTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      display: 'flex',
      alignItems: 'center'
    }
  },
  documentDescription: {
    fontSize: 14,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  pgTitle: {
    fontWeight: 'bold'
  },
  selectLabel: {
    color: '#414141',
    fontSize: 12,
    opacity: 1,
    letterSpacing: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  subSpacer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  subListContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
    paddingLeft: 20
  },
  imageSpacer: {
    width: 130
  },
  subDocumentHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  subDocumentTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}))

let isMobile = false

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  isMobile = true
}

const GlossyDocumentEntry = ({
  description,
  formState,
  image,
  inputType,
  name,
  projectGroup,
  selected,
  subDocuments
}) => {
  const classes = useStyles()
  const selectAllName = 'selectAll' + name

  function handleDocumentSelect(name) {
    formState.setField(name, !formState.values[name])
  }

  function handleSelectAllSubDocuments(e) {
    formState.setField(selectAllName, !formState.values[selectAllName])
    subDocuments.forEach(sd => {
      if (formState.values[selectAllName]) {
        formState.setField(sd.name, false)
      } else {
        formState.setField(sd.name, true)
      }
    })
  }

  const selectThisLabel = (
    <span className={classes.selectLabel}>{isMobile ? 'Select' : 'Select this'}</span>
  )

  const SubDocumentEntry = ({ name }) => {
    return (
      <div className="subdocument-container">
        <CheckboxField
          name={name}
          label={name}
          inputType={inputType}
          value={formState.values[name]}
        />
      </div>
    )
  }

  return (
    <Grid container className={classes.container} spacing={0}>
      <Grid
        container
        className={classes.content}
        spacing={0}
        style={{
          background: selected ? '#F6F9FA' : '#FFF',
          border: selected ? '1px solid #D6E9EC' : '1px solid #F6F6F6'
        }}
        onClick={() => handleDocumentSelect(name)}
      >
        {!isMobile ? (
          <Grid container item spacing={0} className={classes.selectionContainer}>
            <div
              style={{ background: selected ? '#EBF3F4' : '#F6F6F6 0% 0% no-repeat padding-box' }}
              className="checkbox-container"
            >
              <CheckboxField name={name} label={selectThisLabel} inputType={inputType} />
            </div>
            <div
              style={{
                background: `url(${
                  config.MEDIA_CDN_DOMAIN
                }/media/glossies/${image}) top center no-repeat`,
                backgroundSize: 'cover'
              }}
              className={classes.thumbnailImage}
            />
          </Grid>
        ) : null}

        <Grid container item spacing={0} className={classes.descriptionContainer}>
          {isMobile ? (
            <div
              style={{
                width: 100,
                marginBottom: 15,
                background: selected ? '#EBF3F4' : '#F6F6F6 0% 0% no-repeat padding-box'
              }}
              className="checkbox-container"
            >
              <CheckboxField name={name} label={selectThisLabel} inputType={inputType} />
            </div>
          ) : null}
          <Typography className={classes.documentTitle}>
            {name}{' '}
            {isMobile ? <DescriptionIcon style={{ color: '#737373', marginLeft: 10 }} /> : null}
          </Typography>
          <Typography className={classes.documentDescription}>{description}</Typography>
          {projectGroup ? <Typography>ASSOCIATED PROJECT GROUP</Typography> : null}
          <Typography className={classes.pgTitle}>{projectGroup}</Typography>
        </Grid>
      </Grid>
      {subDocuments ? (
        <Grid container className={classes.subContent} spacing={0}>
          {!isMobile ? (
            <Grid container item spacing={0} className={classes.subSpacer}>
              <div className={classes.imageSpacer} />
            </Grid>
          ) : null}
          <Grid container item spacing={0} className={classes.subListContainer}>
            <Typography className={classes.subDocumentHeader}>
              Learn more about the different DCSG hardware and software combinations in production.
            </Typography>
            <div className="select-all-container" onClick={handleSelectAllSubDocuments}>
              <CheckboxField name={selectAllName} label="Select all" inputType={inputType} />
            </div>
            {subDocuments.map(d => (
              <SubDocumentEntry key={d.name} name={d.name} />
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default GlossyDocumentEntry
