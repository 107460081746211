import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import TIPDialog from '../common/TIPDialog'
import ProjectGroupInfo from './ProjectGroupInfo'

const EditProjectGroupForm = ({
  id,
  projectGroups = [],
  returnUrl,
  children,
  onUpdate,
  add = false
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  let projectGroup = projectGroups.find(pg => {
    return pg.id === id
  })

  const projectGroupData = projectGroup || {}

  const onSuccess = close => {
    enqueueSnackbar('Changes Saved', {
      variant: 'success',
      autoHideDuration: 4500
    })

    if (close) {
      setOpen(false)
    }
  }

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <div>
      {children(handleClickOpen)}
      <TIPDialog open={open} handleClose={handleClose}>
        <ProjectGroupInfo
          add={add}
          returnUrl={returnUrl}
          projectGroup={projectGroupData}
          onUpdate={onUpdate}
          onSuccess={onSuccess}
        />
      </TIPDialog>
    </div>
  )
}
export default EditProjectGroupForm
