import React, { forwardRef, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'

import { ErrorSection, InputTextFieldNew, Progress, RoundedButton } from '../../../common'
import ResetPasswordButton from '../ResetPasswordButton'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../../../utils'
import { CHANGE_USER_PASSWORD, CHANGE_USER_EMAIL } from '../mutations'
import { useMutation } from '../../../hooks'

export default function Login({
  activeTab,
  userId,
  email,
  isSelf,
  isReadOnly = false,
  tabLoopStart,
  tabLoopEnd,
  tabLoopSubmit
}) {
  const [formState, inputTypes] = useFormState({
    email,
    newEmail: '',
    currentPassword: '',
    password: '',
    confirmPassword: ''
  })
  const classes = styles()

  const {
    loading: changeEmailLoading,
    execute: handleEmailChange,
    data: {
      sendChangedEmailAddressEmails: { result: changeEmailResult, data: changeEmailData } = {}
    } = {},
    error: changeEmailError
  } = useMutation(CHANGE_USER_EMAIL)

  const {
    loading: changePasswordLoading,
    execute: handlePasswordChange,
    data: { changePassword: { result: changePasswordResult, data: changePasswordData } = {} } = {},
    error: changePasswordError
  } = useMutation(CHANGE_USER_PASSWORD)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // if they leave the password tab clear out any password
    const touchedForm = Object.keys(formState.touched).some(name => {
      return formState.touched[name]
    })

    if (!activeTab && touchedForm) {
      formState.reset()
    }
  }, [activeTab, formState])

  useEffect(() => {
    if (changePasswordResult) {
      // clear form on success
      enqueueSnackbar('Password Change', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [changePasswordResult, enqueueSnackbar])

  useEffect(() => {
    if (changeEmailResult) {
      // clear form on success
      enqueueSnackbar('Email change request sent.', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [changeEmailResult, enqueueSnackbar])

  useEffect(() => {
    // Manually update confirmEmail validation when changing email
    const password = formState.values.password
    const confirmPassword = formState.values.confirmPassword
    const confirmPasswordValidity =
      formState.validity.confirmPassword || !formState.errors.confirmPassword

    if (confirmPassword !== password && confirmPasswordValidity) {
      formState.setFieldError('confirmPassword', 'Passwords must match.')
    } else if (confirmPassword === password && !confirmPasswordValidity) {
      formState.setField('confirmPassword', confirmPassword)
    }
  }, [formState])

  const disableEmailChange = isFormSubmitDisabled(emailFields(), formState)
  let errorChangeEmailMessage

  const disablePasswordChange = isFormSubmitDisabled(passwordFields(), formState, true)
  let errorChangePasswordMessage

  // password messages
  if (!changePasswordResult) {
    errorChangePasswordMessage = changePasswordData
  }

  if (changePasswordError) {
    if (changePasswordError.graphQLErrors[0].message === 'Invalid password.') {
      errorChangePasswordMessage = errorMessages.TIPSupport = 'Invalid Password.'
    } else {
      // Other errors
      errorChangePasswordMessage = errorMessages.TIPSupport
    }
  }

  // email messages
  if (!changeEmailResult) {
    errorChangeEmailMessage = changeEmailData
  }

  if (changeEmailError) {
    errorChangeEmailMessage = errorMessages.TIPSupport
  }

  return (
    <form>
      <Grid container spacing={2}>
        {!isReadOnly && (
          <Grid item xs={12} md={6} className={classes.section}>
            <Typography>Email</Typography>
            <Grid container spacing={2}>
              {Object.entries(emailFields(tabLoopStart)).map(
                ([name, { gridWidth = { xs: 12 }, InputComponent, inputType, ...args }]) => (
                  <Grid key={name} item {...gridWidth}>
                    <InputComponent
                      {...args}
                      error={
                        typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                      }
                      errorHelperText={formState.errors[name]}
                      inputType={inputTypes[inputType]}
                      value={formState.values[name]}
                    />
                  </Grid>
                )
              )}
            </Grid>
            {
              <div className={classes.colFooter}>
                {errorChangeEmailMessage && (
                  <ErrorSection includeSupport={false}>{errorChangeEmailMessage}</ErrorSection>
                )}
                <div className={classes.changeButtonContainer}>
                  <RoundedButton
                    onClick={() => {
                      formState.clear()
                      formState.setField('email', email)
                      handleEmailChange({
                        id: userId,
                        values: {
                          currentEmail: formState.values.email,
                          email: formState.values.newEmail
                        }
                      })
                    }}
                    disabled={changeEmailLoading || disableEmailChange}
                    color="primary"
                    fullWidth={false}
                    className={classes.changeEmailButton}
                  >
                    {changeEmailLoading ? <Progress size={24} /> : 'Change Email'}
                  </RoundedButton>
                </div>
              </div>
            }
          </Grid>
        )}
        <Grid item xs={12} md={6} className={clsx(classes.section, classes.borderLeft)}>
          <Typography className={classes.changePasswordText}>Change Password</Typography>
          {isSelf ? (
            <>
              <input type="hidden" name="username" autoComplete="username" readOnly value={email} />
              <Grid container spacing={2}>
                {Object.entries(passwordFields(tabLoopEnd)).map(
                  ([
                    name,
                    { gridWidth = { xs: 12 }, helperText, InputComponent, inputType, ...args }
                  ]) => (
                    <Grid key={name} item {...gridWidth}>
                      <InputComponent
                        {...args}
                        error={
                          typeof formState.validity[name] !== 'undefined' &&
                          !formState.validity[name]
                        }
                        errorHelperText={formState.errors[name]}
                        helperText={helperText}
                        inputType={inputTypes[inputType]}
                        value={formState.values[name]}
                      />
                    </Grid>
                  )
                )}
              </Grid>
              <div className={classes.colFooter}>
                {errorChangePasswordMessage && (
                  <ErrorSection includeSupport={false}>{errorChangePasswordMessage}</ErrorSection>
                )}
                <div className={classes.changeButtonContainer}>
                  <RoundedButton
                    onClick={() => {
                      formState.clear()
                      formState.setField('email', email)
                      handlePasswordChange({
                        id: userId,
                        values: {
                          currentPassword: formState.values.currentPassword,
                          password: formState.values.confirmPassword
                        }
                      })
                    }}
                    disabled={changePasswordLoading || disablePasswordChange}
                    color="primary"
                    fullWidth={false}
                    className={classes.changePasswordButton}
                    ref={tabLoopSubmit}
                  >
                    {changePasswordLoading ? <Progress size={24} /> : 'Reset Password'}
                  </RoundedButton>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.resetButtonContainer}>
              <ResetPasswordButton isReadOnly={isReadOnly} email={email} />
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

const emailFields = tabLoopStart =>
  tabLoopStart
    ? {
        email: {
          disabled: true,
          InputComponent: InputTextFieldNew,
          inputType: 'text',
          label: 'Organization Email Address',
          name: 'email',
          validator: {}
        },
        newEmail: {
          InputComponent: InputForwardRef,
          inputType: 'text',
          label: 'Change Email Address',
          name: 'newEmail',
          ref: tabLoopStart,
          validator: {
            cantMatchKey: 'email',
            cantMatchMessage: 'New Email must be different',
            required: true,
            regex: inputRegexes.email,
            regexMessage: 'Must be a valid email'
          }
        }
      }
    : {
        email: {},
        newEmail: {
          validator: {
            required: true,
            regex: inputRegexes.email,
            regexMessage: 'Must be a valid email'
          }
        }
      }

const passwordFields = tabLoopEnd =>
  tabLoopEnd
    ? {
        currentPassword: {
          InputComponent: InputTextFieldNew,
          inputType: 'password',
          label: 'Current Password',
          name: 'currentPassword',
          validator: {
            required: true
          }
        },
        password: {
          autoComplete: 'new-password',
          helperText: 'Create a strong password to protect your TIP Account.',
          InputComponent: InputTextFieldNew,
          inputType: 'password',
          label: 'New Password',
          name: 'password',
          validator: {
            required: true,
            regex: inputRegexes.password,
            regexMessage:
              'Must be 8 characters long and have at least one letter, number, and symbol.',
            cantMatchKey: 'currentPassword',
            cantMatchMessage: 'New password must be different from old password'
          }
        },
        confirmPassword: {
          autoComplete: 'new-password',
          helperText: 'Re-type your password.',
          InputComponent: InputForwardRef,
          inputType: 'password',
          label: 'Confirm New Password',
          name: 'confirmPassword',
          ref: tabLoopEnd,
          validator: {
            required: true,
            requiredMatch: true,
            mustMatchKey: 'password',
            mustMatchMessage: 'Passwords must match.'
          }
        }
      }
    : {
        currentPassword: {
          validator: {
            required: true
          }
        },
        password: {
          validator: {
            required: true,
            regex: inputRegexes.password,
            regexMessage:
              'Must be 8 characters long and have at least one letter, number, and symbol.',
            cantMatchKey: 'currentPassword',
            cantMatchMessage: 'New password must be different from old password'
          }
        },
        confirmPassword: {
          validator: {
            required: true,
            requiredMatch: true,
            mustMatchKey: 'password',
            mustMatchMessage: 'Passwords must match.'
          }
        }
      }

const InputForwardRef = forwardRef((props, ref) => <InputTextFieldNew {...props} inputRef={ref} />)

const styles = makeStyles(theme => ({
  resetButtonContainer: {
    marginTop: 20,
    textAlign: 'center'
  },
  changeButtonContainer: {
    textAlign: 'right',
    flexGrow: '1'
  },
  changePasswordButton: {
    borderRadius: 5,
    width: 180,
    height: 40
  },
  changePasswordText: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    }
  },
  passwordFooter: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center'
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.palette.borderGray.main}`,
    [theme.breakpoints.down('xs')]: {
      borderLeft: 'none'
    }
  }
}))
