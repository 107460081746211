import gql from 'graphql-tag'

export const GET_ORGANIZATION_DATA = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      approvalStatus
      logo
      website
      name
      emailAddress
      phoneNumber
      type
      serviceOfferings
      operatingRegions
      affiliateOf
      membership {
        membershipLevel(id: $id) {
          id
          name
          price
          atlassianAccountsAllowed
          canAccessRFI
          canAccessTSS
          canAccessProjectGroups
          canAccessSoftwareGroups
        }
        status
        startDate
        renewalDate
        paid
      }
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
    }
  }
`

export const GET_ORGANIZATION_LIST = gql`
  query($idList: [String]) {
    publicOrganizations(idList: $idList) {
      id
      name
      shippingAddress {
        address1
        address2
        city
        state
        country
        postalCode
      }
    }
  }
`

export const GET_MEMBERSHIP_REQUEST_SENT_DATE = gql`
  query($input: getMembershipRequestSentDateInput) {
    getMembershipRequestSentDate(input: $input) {
      emailSentDate
      emailResentCount
    }
  }
`
