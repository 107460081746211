import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import OrganizationIcon from '@material-ui/icons/LocationCityOutlined'
import KeyIcon from '@material-ui/icons/VpnKey'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import SignIcon from '@material-ui/icons/Ballot'
import DesignateIcon from '@material-ui/icons/HowToReg'
import GroupsIcon from '@material-ui/icons/SupervisorAccount'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutline'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SomethingWentWrongModal from './SomethingWentWrongModal'

import { GET_USER, GET_ORG, GET_ORGANIZATION_PROJECT_GROUPS } from './queries'
import { useSession } from '../../auth/queries'
import { useQuery } from '../../hooks'
import BillingAlert from '../Billing/BillingAlert'
import EditUserModal from '../../User/Profile'
import EditOrganizationModal from '../Profile'
import useStyles from './landingStyles'
import LandingCard from './LandingCard'
import AccountMembershipRequests from './AccountMembershipRequests'
import GroupActivationRequests from './GroupActivationRequests'
import RestrictedSubGroupRequests from './RestrictedSubGroupRequests'
import NoSign from '@material-ui/icons/NotInterested'
import { roles } from '../../utils/roleHelper'

const nonApprovedOrgStatus = ['in progress', 'lead', 'pending']

const UserProfile = ({ classes, editModal, hoverButton, location, organization, user }) => {
  let checkSign = CheckCircleOutlinedIcon
  let hideEditButton = false
  let iconClass = classes.iconNotDone
  let redirect = `/user/${user.id}`

  if (user.percentageComplete === 100) {
    checkSign = CheckCircleIcon
    hideEditButton = true
    iconClass = classes.iconDone
    redirect = ''
  }

  return (
    <LandingCard
      user={user}
      organization={organization}
      topText={' 1. Your personal profile'}
      bottomText={`Your profile is ${user.percentageComplete}% complete`}
      Icon={PersonIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      hideEditButton={hideEditButton}
      hoverButton={hoverButton}
      editModal={editModal}
      ModalComponent={({ children }) => (
        <EditUserModal
          returnUrl={location.pathname}
          id={user.id}
          orgId={organization.id}
          onUpdate={() => null}
        >
          {onClick => children(onClick)}
        </EditUserModal>
      )}
    />
  )
}
const OrganizationProfile = ({ classes, editModal, hoverButton, location, organization, user }) => {
  let checkSign = CheckCircleOutlinedIcon
  let hideEditButton = false
  let iconClass = classes.iconNotDone
  let redirect = `/organization/${organization.id}`

  if (organization.percentageComplete === 100) {
    checkSign = CheckCircleIcon
    hideEditButton = true
    iconClass = classes.iconDone
    redirect = ''
  }

  return (
    <LandingCard
      topText={' 2. Your organization profile'}
      bottomText={`Your organization is ${organization.percentageComplete}% complete`}
      Icon={OrganizationIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      hideEditButton={hideEditButton}
      hoverButton={hoverButton}
      editModal={editModal}
      ModalComponent={({ children }) => (
        <EditOrganizationModal
          returnUrl={location.pathname}
          id={organization.id}
          onUpdate={() => null}
        >
          {onClick => children(onClick)}
        </EditOrganizationModal>
      )}
    />
  )
}

const AuthorizedSigner = ({ classes, editModal, hoverButton, location, organization, user }) => {
  let checkSign = CheckCircleOutlinedIcon
  let hideEditButton = false
  let iconClass = classes.iconNotDone
  let redirect = `/organization/${organization.id}`

  if (organization.organizationRoles.authorizedSigners.filter(a => a.user || a.email).length > 0) {
    checkSign = CheckCircleIcon
    hideEditButton = true
    iconClass = classes.iconDone
    redirect = ''
  }

  return (
    <LandingCard
      topText={'3. Authorized representative(s)'}
      bottomText={
        organization.organizationRoles.authorizedSigners.filter(a => a.user || a.email).length > 0
          ? 'Authorized representative assigned'
          : 'Designate an authorized respresentative'
      }
      Icon={KeyIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      hideEditButton={hideEditButton}
      hoverButton={hoverButton}
      editModal={editModal}
      ModalComponent={({ children }) => (
        <EditOrganizationModal
          returnUrl={location.pathname}
          id={organization.id}
          onUpdate={() => null}
          tabIndex={2}
        >
          {onClick => children(onClick)}
        </EditOrganizationModal>
      )}
    />
  )
}

const BillingContact = ({ classes, editModal, hoverButton, location, organization }) => {
  let checkSign = CheckCircleOutlinedIcon
  let hideEditButton = false
  let iconClass = classes.iconNotDone
  let redirect = `/organization/${organization.id}`

  if (
    organization.organizationRoles.billingContact.user ||
    organization.organizationRoles.billingContact.email
  ) {
    checkSign = CheckCircleIcon
    hideEditButton = true
    iconClass = classes.iconDone
    redirect = ''
  }

  return (
    <LandingCard
      topText={'4. Billing Contact'}
      bottomText={
        organization.organizationRoles.billingContact.user ||
        organization.organizationRoles.billingContact.email
          ? 'Billing contact assigned'
          : 'Designate a billing contact'
      }
      Icon={CreditCardIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      hideEditButton={hideEditButton}
      hoverButton={hoverButton}
      editModal={editModal}
      ModalComponent={({ children }) => (
        <EditOrganizationModal
          returnUrl={location.pathname}
          id={organization.id}
          onUpdate={() => null}
          tabIndex={3}
        >
          {onClick => children(onClick)}
        </EditOrganizationModal>
      )}
    />
  )
}

const SignForm = ({ user, organization, classes, hoverButton }) => {
  let checkSign
  let redirect
  let bottomText
  let disabled = false
  let iconClass

  if (
    // all these fields are required for the gpa to be signed
    organization.organizationRoles.authorizedSigners.length === 0 ||
    !organization.name ||
    !organization.website ||
    !organization.phoneNumber ||
    !organization.shippingAddress.address1 ||
    !organization.shippingAddress.city ||
    !organization.shippingAddress.country ||
    !organization.shippingAddress.postalCode ||
    !organization.shippingAddress.state
  ) {
    disabled = true
  }

  if (organization.approvalStatus === 'approved') {
    iconClass = classes.iconDone
    checkSign = CheckCircleIcon
    bottomText = `Request a signature from an authorized representative.`
  } else if (disabled) {
    iconClass = classes.iconNotDone
    checkSign = NoSign
    bottomText = `Steps 1, 2, and 3 are required to complete this step.`
  } else {
    iconClass = classes.iconNotDone
    checkSign = CheckCircleOutlinedIcon
    redirect = `/organization/${organization.id}/agreements`
    bottomText = `Request a signature from an authorized representative.`
  }
  return (
    <LandingCard
      topText={'4. Sign participation agreement'}
      bottomText={bottomText}
      Icon={SignIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      disabled={disabled}
      hoverButton={hoverButton}
    />
  )
}

const UserRoles = ({ user, organization, classes, hoverButton }) => {
  let iconClass
  let checkSign
  let redirect
  let bottomText
  let disabled

  if (nonApprovedOrgStatus.includes(organization.approvalStatus)) {
    iconClass = classes.iconNotDone
    checkSign = NoSign
    bottomText = `Participation agreement signature required.`
    disabled = true
  } else if (
    organization.organizationRoles.organizationAdmin &&
    organization.organizationRoles.authorizedSigners.length > 0 &&
    organization.organizationRoles.primaryContact
  ) {
    iconClass = classes.iconDone
    checkSign = CheckCircleIcon
    bottomText = `Assign individuals to help manage your organization account.`
  } else {
    iconClass = classes.iconNotDone
    checkSign = CheckCircleOutlinedIcon
    redirect = `/organization/${organization.id}`
    bottomText = `Assign individuals to help manage your organization account.`
  }
  return (
    <LandingCard
      topText={'5. Assign primary account roles'}
      bottomText={bottomText}
      Icon={DesignateIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      disabled={disabled}
      hoverButton={hoverButton}
    />
  )
}

const ProjectGroups = ({ user, organization, projectGroups, classes, session, hoverButton }) => {
  let iconClass
  let checkSign
  let redirect
  let bottomText
  let disabled
  const userRoles = session ? session.user.roles : []

  if (projectGroups.length) {
    iconClass = classes.iconDone
    checkSign = CheckCircleIcon
    redirect = null
    bottomText = `Select projects groups for organization participation.`
  } else if (nonApprovedOrgStatus.includes(organization.approvalStatus)) {
    iconClass = classes.iconNotDone
    checkSign = NoSign
    bottomText = `Primary account roles must be assigned.`
    disabled = true
  } else {
    iconClass = classes.iconNotDone
    checkSign = CheckCircleOutlinedIcon
    redirect = `/organization/${organization.id}/project-groups`
    bottomText = `Select projects groups for organization participation.`
  }

  return (
    <LandingCard
      topText={'6. Approve project group'}
      bottomText={bottomText}
      Icon={GroupsIcon}
      iconClass={iconClass}
      CheckSign={checkSign}
      redirectPath={redirect}
      disabled={disabled}
      hideEditButton={!userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId))}
      hoverButton={hoverButton}
    />
  )
}

export const DashboardChecklist = ({
  classes,
  containerProps = {},
  editModal = false,
  hoverButton = true,
  location,
  orgId,
  session,
  showUser = true,
  showOrg = true,
  showAuthRep = true,
  showBillingContact = true,
  showGPA = true,
  showRoles = true,
  showPGs = true,
  userId
}) => {
  const {
    data: { organization },
    loading: organizationQueryLoading
  } = useQuery(GET_ORG, { variables: { id: orgId } })

  const {
    data: { user },
    loading: userQueryLoading
  } = useQuery(GET_USER, { variables: { id: userId } })

  const {
    loading: organizationProjectGroupsLoading,
    data: { findProjectGroupsByOrganizationId: projectGroups = [] } = {}
  } = useQuery(GET_ORGANIZATION_PROJECT_GROUPS, { variables: { id: orgId } })

  if (organizationQueryLoading || userQueryLoading || organizationProjectGroupsLoading) {
    return null
  }

  return (
    <Grid container direction="column" spacing={3} {...containerProps}>
      {showUser && (
        <Grid item>
          <UserProfile
            classes={classes}
            editModal={editModal}
            hoverButton={hoverButton}
            location={location}
            organization={organization}
            user={user}
          />
        </Grid>
      )}
      {showOrg && (
        <Grid item>
          <OrganizationProfile
            classes={classes}
            editModal={editModal}
            hoverButton={hoverButton}
            location={location}
            organization={organization}
            user={user}
          />
        </Grid>
      )}
      {showAuthRep && (
        <Grid item>
          <AuthorizedSigner
            classes={classes}
            editModal={editModal}
            hoverButton={hoverButton}
            location={location}
            organization={organization}
            user={user}
          />
        </Grid>
      )}
      {showBillingContact && (
        <Grid item>
          <BillingContact
            classes={classes}
            editModal={editModal}
            hoverButton={hoverButton}
            location={location}
            organization={organization}
            user={user}
          />
        </Grid>
      )}
      {showGPA && (
        <Grid item>
          <SignForm
            user={user}
            organization={organization}
            classes={classes}
            hoverButton={hoverButton}
          />
        </Grid>
      )}
      {showRoles && (
        <Grid item>
          <UserRoles
            user={user}
            organization={organization}
            classes={classes}
            hoverButton={hoverButton}
          />
        </Grid>
      )}
      {showPGs && (
        <Grid item>
          <ProjectGroups
            classes={classes}
            hoverButton={hoverButton}
            organization={organization}
            projectGroups={projectGroups}
            session={session}
            user={user}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default function OrgFirstUseLanding({ userId, orgId }) {
  const { loading, session } = useSession()

  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const {
    data: { user },
    loading: userQueryLoading
  } = useQuery(GET_USER, { variables: { id: userId } })

  if (loading || userQueryLoading) {
    return null
  }

  const userRoles = session ? session.user.roles : []
  const isOrgAdmin = userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId))

  return (
    !loading &&
    (isOrgAdmin ? (
      <>
        {user && user.billingAlertConfirmed
          ? null
          : false && <BillingAlert isOrgAdmin={isOrgAdmin} orgId={orgId} userId={userId} />}
        <SomethingWentWrongModal open={open} setOpen={setOpen} />
        <AccountMembershipRequests orgId={orgId} setOpen={setOpen} />
        <GroupActivationRequests isOrgAdmin={isOrgAdmin} orgId={orgId} setOpen={setOpen} />
        <RestrictedSubGroupRequests orgId={orgId} setOpen={setOpen} />
      </>
    ) : (
      <>
        <Typography gutterBottom variant="h4">
          Welcome to your TIP Participation account dashboard
        </Typography>
        <Typography paragraph>
          Please complete the TIP Participation application for your organization.
        </Typography>
        <Typography paragraph>Here are the steps to follow:</Typography>
        <DashboardChecklist classes={classes} orgId={orgId} session={session} userId={userId} />
      </>
    ))
  )
}
