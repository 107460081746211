import gql from 'graphql-tag'

export const ADD_ORGANIZATION_TO_PROJECT_GROUP = gql`
  mutation($input: OrganizationProjectGroupInput!) {
    addOrganizationToProjectGroup(input: $input) {
      result
      data
    }
  }
`

export const REMOVE_ORGANIZATION_FROM_PROJECT_GROUP = gql`
  mutation($input: OrganizationProjectGroupLeaveInput!) {
    removeOrganizationFromProjectGroup(input: $input) {
      result
      data
    }
  }
`

export const ADD_USER_TO_PROJECT_GROUP = gql`
  mutation($input: UserProjectGroupInput!) {
    addUserToProjectGroup(input: $input) {
      result
      data
    }
  }
`

export const ADD_PROJECT_GROUP_PARTICIPATION_REQUEST = gql`
  mutation($input: ProjectGroupRequestInput!) {
    addProjectGroupParticipationRequest(input: $input) {
      result
      data
      type
      projectGroupId
      userId
    }
  }
`

export const REMOVE_USER_FROM_PROJECT_GROUP = gql`
  mutation($input: UserProjectGroupLeaveInput!) {
    removeUserFromProjectGroup(input: $input) {
      result
      data
    }
  }
`

export const EDIT_PROJECT_GROUP = gql`
  mutation($id: ID!, $input: ProjectGroupUpdateInput!) {
    updateProjectGroup(id: $id, input: $input) {
      id
      agreement {
        id
        name
      }
      atlassianGroup
      charterAgreement {
        id
        name
      }
      charterTitle
      createdDate
      description
      description
      detailLink
      githubTeam
      graduated
      jira
      name
      new
      parentProjectGroupId
      restricted
      sfId
      status
      supportMustVerify
      openToMembers
      updatedBy
      updatedDate
      workplaceId
      approvedOrgs
    }
  }
`

export const CREATE_PROJECT_GROUP = gql`
  mutation($input: ProjectGroupCreationInput!) {
    addProjectGroup(input: $input) {
      id
      agreement {
        id
        name
      }
      atlassianGroup
      charterAgreement {
        id
        name
      }
      charterTitle
      createdDate
      description
      description
      detailLink
      githubTeam
      graduated
      jira
      name
      restricted
      sfId
      status
      supportMustVerify
      openToMembers
      updatedBy
      updatedDate
      workplaceId
      approvedOrgs
    }
  }
`
export const SEND_ADD_PROJECT_GROUP_UPDATE_EMAILS = gql`
  mutation($input: SendAddProjectGroupUpdateEmailInput) {
    sendAddProjectGroupUpdateEmails(input: $input) {
      result
    }
  }
`
export const SEND_GRADUATED_PROJECT_GROUP_UPDATE_EMAILS = gql`
  mutation($input: SendGraduatedProjectGroupUpdateEmailInput) {
    sendGraduatedProjectGroupUpdateEmails(input: $input) {
      result
    }
  }
`

export const ATLASSIAN_CREATE_SECURITY_GROUP = gql`
  mutation($input: AtlassianCreateSecurityGroupInput!) {
    atlassianCreateSecurityGroup(input: $input) {
      result
      atlassianGroupId
      error
    }
  }
`
