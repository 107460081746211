import React, { useEffect, useRef } from 'react'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import { getAgreementUrl } from '../utils'
import RoundedButton from './RoundedButton'

const styles = makeStyles(theme => ({
  preview: {
    width: '100%'
  },
  imageBox: {
    border: '1px solid gray',
    margin: 5,
    maxWidth: 416,
    padding: 5
  }
}))

export default function InputFileField({
  acceptTypes,
  handleChange,
  label,
  name,
  preview,
  showPreview = false,
  showRemove = true,
  validator,
  value
}) {
  const classes = styles()
  const inputRef = useRef(null)

  useEffect(() => {
    if (!value.file && inputRef.current.value) {
      inputRef.current.value = ''
    }
  }, [value])

  return (
    <div className={showPreview && classes.imageBox}>
      <div>{validator && validator.required ? `${label}*` : label}</div>
      <Grid container>
        <Grid item xs>
          <input
            ref={inputRef}
            accept={acceptTypes}
            className={classes.inputfile}
            type="file"
            onChange={({
              target: {
                validity,
                files: [file]
              }
            }) => {
              if (validity.valid && file) {
                handleChange({
                  file,
                  removed: value.removed
                })
              }
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={() =>
              handleChange({
                file: null,
                removed: value.removed
              })
            }
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      {showPreview && preview && (
        <div>
          <hr />
          <Grid container alignContent="space-between">
            <Grid item xs={6}>
              <Typography>Current PDF:</Typography>
              <a href={getAgreementUrl(preview)} rel="noopener noreferrer" target="_blank">
                Open in new tab
              </a>
            </Grid>
            {showRemove && (
              <Grid item xs={6}>
                <RoundedButton
                  onClick={() =>
                    handleChange({
                      file: value.file,
                      removed: true
                    })
                  }
                >
                  Remove PDF
                </RoundedButton>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </div>
  )
}
