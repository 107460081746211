import React from 'react'
import { withRouter } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/styles'

import { MobileNav, DesktopNav, SystemAdminToolbar, ImpersonationToolbar } from '.'
import { useSession, useUser } from '../auth/queries'
import { GET_NAV_ITEMS } from './queries'
import { useQuery } from '../hooks'

function Header(props) {
  const classes = useStyles()

  const {
    loading,
    session,
    isAuthed,
    isOrgAdmin,
    isSystemAdmin,
    isReadOnlySystemAdmin,
    isExchangeListingsAdmin,
    isImpersonating,
    userId
  } = useSession()

  const { data: { nav = [] } = [] } = useQuery(GET_NAV_ITEMS)

  const { user = {} } = useUser({
    variables: { id: userId },
    skip: !userId
  })

  const signUpStep = user && user.signUpStep ? user.signUpStep : null

  if (loading) {
    return null
  }

  const canViewLookerDashboards = isSystemAdmin || isReadOnlySystemAdmin || isExchangeListingsAdmin

  return (
    <div>
      <AppBar className={classes.appBar} position="fixed">
        <Hidden className={classes.desktop} only={['xs', 'sm']}>
          <Toolbar classes={{ root: classes.navContainerRoot }}>
            <DesktopNav
              isAuthed={isAuthed}
              session={session}
              headerNavItems={nav || []}
              isOrgAdmin={isOrgAdmin}
              isImpersonating={isImpersonating}
              isSystemAdmin={isSystemAdmin || isReadOnlySystemAdmin}
              canViewLookerDashboards={canViewLookerDashboards}
              pathName={props.location.pathname}
              signUpStep={signUpStep}
            />
          </Toolbar>
        </Hidden>

        <Hidden only={['md', 'lg', 'xl']}>
          <Toolbar classes={{ root: classes.navContainerRootMobile }}>
            <MobileNav
              headerNavItems={nav || []}
              session={session}
              isOrgAdmin={isOrgAdmin}
              pathName={props.location.pathname}
              signUpStep={signUpStep}
            />
          </Toolbar>
        </Hidden>
      </AppBar>

      <div className={classes.subHeader}>
        <SystemAdminToolbar />
        <ImpersonationToolbar />
      </div>
    </div>
  )
}

export const toolbarHeight = 66
export const systemAdminToolbarHeight = 60

export const useStyles = makeStyles(theme => ({
  subHeader: {
    zIndex: theme.zIndex.drawer,
    position: 'fixed',
    top: 60,
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  utilContainerRoot: {
    minHeight: 30,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: 'rgb(234, 234, 234)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  utilAlertRoot: {
    color: 'rgb(107,107,107)'
  },
  desktop: {
    paddingLeft: 20,
    paddingRight: 20
  },
  navContainerRoot: {
    backgroundColor: theme.palette.navDarkGray.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    height: toolbarHeight,
    zIndex: theme.zIndex.drawer + 1
  },
  navContainerRootMobile: {
    backgroundColor: theme.palette.navDarkGray.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    height: toolbarHeight
  },
  navContainerLogo: {
    height: 30,
    width: 'auto'
  },
  icon: {
    marginRight: 5
  },
  logoutIcon: {
    marginLeft: 15,
    marginRight: 5
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff'
  },
  noHover: {
    cursor: 'pointer',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
      cursor: 'pointer'
    },
    borderBottom: `1px solid white`
  },
  item: {
    textDecoration: 'none !important',
    textTransform: 'initial',
    fontSize: 14,
    color: 'white',
    padding: 0
  },
  becomeMemberButton: {
    borderRadius: 5,
    padding: '9px 3px 9px 7px',
    margin: 0,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '14px',
    marginLeft: 0,
    textTransform: 'uppercase',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  menuButton: {
    padding: '20px 15px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.navHoverGray.main,
      boxShadow: `inset 0 -4px 0 ${theme.palette.navHoverLightGray.main} !important`
    }
  },
  popoverClasses: {
    marginTop: 1,
    top: '65px !important',
    width: 'auto',
    backgroundColor: theme.palette.navDarkGray.main,
    border: `1px solid ${theme.palette.navDarkGray.main}`,
    color: theme.palette.borderGray.main,
    maxHeight: 'none !important',
    overflow: 'visible',
    borderRadius: 0
  },
  listClasses: {
    color: 'purple !important'
  }
}))

export default withRouter(Header)
