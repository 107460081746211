import React, { useState } from 'react'
import { Button, Divider, makeStyles, Paper, Typography } from '@material-ui/core'
import Cookies from 'universal-cookie'
import { useFormState } from 'react-use-form-state'

import { InputTextFieldNew, RoundedButton } from '../common'
import { useMutation } from '../hooks'
import { AUTHORIZE_ENV } from './mutations'

const useStyles = makeStyles(theme => ({
  retryButton: {
    marginTop: theme.spacing(2)
  },
  passwordWrapper: {
    padding: `0 ${theme.spacing(2)}px`
  },
  root: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 350,
    textAlign: 'center'
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}))

const DevAuthorizeEnv = ({ setAuthorized }) => {
  const classes = useStyles()

  const [formState, { text }] = useFormState({ password: '' })

  const [failed, setFailed] = useState(false)

  const { execute: handleAuthorizeEnv } = useMutation(AUTHORIZE_ENV, {
    onCompleted: ({ authorizeEnv }) => {
      setAuthorized(authorizeEnv)

      if (authorizeEnv) {
        const date = new Date()
        const cookies = new Cookies()

        // 24h duration
        date.setTime(date.getTime() + 86400000)

        cookies.set('hubAuthorized', true, {
          path: '/',
          expires: date
        })
      } else {
        setFailed(true)
      }
    }
  })

  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} variant="h4">
        {failed ? 'Access Denied' : 'Password Required For Access'}
      </Typography>
      <Divider variant="middle" />
      {failed ? (
        <Button
          className={classes.retryButton}
          variant="outlined"
          onClick={() => {
            formState.reset()

            setFailed(false)
          }}
        >
          Retry
        </Button>
      ) : (
        <form
          onSubmit={e => {
            e.preventDefault()

            handleAuthorizeEnv({ values: { ...formState.values } })
          }}
        >
          <div className={classes.passwordWrapper}>
            <InputTextFieldNew
              inputType={text}
              label="Password"
              name="password"
              value={formState.values.password}
            />
          </div>
          <RoundedButton disabled={!formState.values.password} fullWidth={false} type="submit">
            Submit
          </RoundedButton>
        </form>
      )}
    </Paper>
  )
}

export default DevAuthorizeEnv
