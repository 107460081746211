import gql from 'graphql-tag'

export const UPDATE_INVITATION = gql`
  mutation($input: OrganizationInviteUpdateInput) {
    updateOrganizationInvitation(input: $input) {
      email
      status
      sentDate
    }
  }
`
export const SEND_ORGANIZATION_INVITES = gql`
  mutation($input: OrganizationInviteInput) {
    generateOrganizationInvitation(input: $input) {
      email
      firstName
      lastName
      status
      sentDate
    }
  }
`
