import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import RoundedButton from '../../common/RoundedButton'

export default function ConfirmRemoveOrgModal({ open, handleClose, handleConfirm }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        Please Confirm
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <WarningIcon className={classes.warningIcon} />
          <div>
            <Typography>
              Are you sure you wish to disconnect this user from the organization?
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <RoundedButton
          fullWidth={false}
          variant="outlined"
          onClick={handleClose}
          className={classes.button}
        >
          Cancel
        </RoundedButton>
        <RoundedButton onClick={handleConfirm} fullWidth={false} className={classes.button}>
          Confirm
        </RoundedButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  leaveButton: {
    flex: 1,
    textAlign: 'right',
    textTransform: 'none'
  },
  bold: {
    fontWeight: 600
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  warningIcon: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
}))
