import React from 'react'

import MaterialTable from 'material-table'
import { Typography, IconButton, Grid, Divider, Tooltip } from '@material-ui/core'
import { Create } from '@material-ui/icons'

import { makeStyles } from '@material-ui/styles'

import { useAgreements, GET_ALL_AGREEMENTS } from '../utils/index'
import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'
import EditAgreementForm from './EditAgreementForm'
import { useSession } from '../auth/queries'
import { useQuery } from '../hooks'
import { GET_TERMINATION_BUTTON_VISIBILITY } from './queries'
import BigRedButton from './BigRedButton'

export default function ManageAgreements() {
  const classes = useStyles()

  const { loading, agreements } = useAgreements()
  const { isSystemAdmin } = useSession()
  const canAddNewAgreements = isSystemAdmin // making a const because other roles may be able to eventually

  const {
    data: { showTerminationButton = {} } = {},
    loading: terminationButtonVisibilityLoading
  } = useQuery(GET_TERMINATION_BUTTON_VISIBILITY)

  const agreementUpdate = (cache, payload) => {
    const { agreements } = cache.readQuery({
      query: GET_ALL_AGREEMENTS
    })

    const agreement = payload.data.saveAgreement
    if (agreement) {
      const newAgreements = [...agreements]

      newAgreements.push(agreement)
      cache.writeQuery({
        query: GET_ALL_AGREEMENTS,
        data: { agreements: newAgreements }
      })
    }
  }

  if (loading) {
    return (
      <div className={classes.progress}>
        <Progress size={100} delay={0} />
      </div>
    )
  }

  const columnDef = [
    {
      title: 'Name',
      searchable: true,
      defaultSort: 'desc',
      field: 'name',
      render: rowData => {
        return (
          <EditAgreementForm
            agreementId={rowData.id}
            onUpdate={agreementUpdate}
            isReadOnly={!canAddNewAgreements}
          >
            {onClick => (
              <div className={classes.clickableColumn} onClick={onClick}>
                {rowData.name}
              </div>
            )}
          </EditAgreementForm>
        )
      }
    },
    {
      title: 'Type',
      field: 'type'
    },
    {
      title: 'Version',
      field: 'version'
    },
    {
      render: rowData => {
        return (
          <EditAgreementForm isReadOnly={!canAddNewAgreements} agreementId={rowData.id}>
            {onClick => (
              <Tooltip title="Edit">
                <IconButton onClick={onClick}>
                  <Create />
                </IconButton>
              </Tooltip>
            )}
          </EditAgreementForm>
        )
      }
    }
  ]

  return (
    <Grid container>
      <Grid item xs>
        <Grid item xs={12} className={classes.header}>
          <Typography gutterBottom variant="h4">
            Manage Agreements
          </Typography>
        </Grid>
        {canAddNewAgreements && (
          <Grid item xs={12}>
            <EditAgreementForm agreementId={null} onUpdate={agreementUpdate}>
              {onClick => (
                <RoundedButton fullWidth={false} onClick={onClick} color="primary">
                  Add New
                </RoundedButton>
              )}
            </EditAgreementForm>
          </Grid>
        )}
      </Grid>
      {!terminationButtonVisibilityLoading && showTerminationButton.showButton && (
        <Grid item xs="auto">
          <BigRedButton />
        </Grid>
      )}
      <Grid item xs={12} className={classes.divider}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <MaterialTable
              title="Manage Agreements"
              columns={columnDef}
              data={agreements}
              draggable={false}
              options={{
                filtering: true,
                pageSize: 10,
                showTitle: false
              }}
              components={{
                Toolbar: () => {
                  return null
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  clickableColumn: {
    cursor: 'pointer'
  }
}))
