import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import makeStyles from '@material-ui/core/styles/makeStyles'

import LinkField from '../../../common/LinkField'
import OrganizationVerificationRequestsCard from './OrganizationVerificationRequestsCard'
import { useQuery } from '../../../hooks'
import { GET_SUPPORT_MUST_VERIFY_REQUESTS } from '../../../Organization/FirstUseLanding/queries'

const OrganizationVerificationRequests = ({ orgId }) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const {
    loading,
    data: { getSupportMustVerifyRequests: supportMustVerifyRequests = [] } = {}
  } = useQuery(GET_SUPPORT_MUST_VERIFY_REQUESTS, {
    fetchPolicy: 'network-only'
  })

  const onResult = useCallback(
    text => {
      enqueueSnackbar(`Request ${text}`, {
        variant: 'success',
        autoHideDuration: 4500
      })
    },
    [enqueueSnackbar]
  )

  if (loading) {
    return null
  }

  return (
    <ExpansionPanel defaultExpanded className={classes.expansionPanel} elevation={0}>
      <ExpansionPanelSummary
        classes={{
          root: classes.panelSummaryRoot,
          content: classes.panelSummaryContent,
          expandIcon: classes.expandIcon
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container direction="column">
          <Grid container item direction="row">
            <Typography className={classes.title}>ORAN Alliance verification requests</Typography>
            {supportMustVerifyRequests.length > 0 ? (
              <div className={classes.notificationCountWrapper}>
                <div className={classes.notificationCount}>{supportMustVerifyRequests.length}</div>
              </div>
            ) : null}
          </Grid>
          <Grid item>
            <LinkField
              className={classes.linkStyle}
              to="https://www.o-ran.org/membership"
              label="ORAN Participation Site"
              target="_blank"
              hideIcon
              onClick={e => e.stopPropagation()}
            />
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.container}>
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12}>
              <Divider />
              <Grid container className={classes.headerWrapper} spacing={2}>
                <Grid item xs={4}>
                  <Typography className={classes.header}>Organization Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.header}>Website</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.header}>Logo</Typography>
                </Grid>
                <Grid className={classes.flexEnd} item xs={2}>
                  <Typography className={classes.header}>Actions</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          {supportMustVerifyRequests.map((request, i) => (
            <Grid item key={i} xs={12}>
              <Divider />
              <OrganizationVerificationRequestsCard
                onResult={onResult}
                orgId={orgId}
                request={request}
              />
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0
  },
  expandIcon: {
    position: 'relative'
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`,
    margin: '0 !important',
    marginBottom: '32px !important'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray12.main
  },
  headerWrapper: {
    padding: theme.spacing(2)
  },
  linkStyle: {
    color: theme.palette.secondary.main
  },
  notificationCount: {
    alignItems: 'center',
    background: theme.palette.dashboardNotification.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: 25,
    justifyContent: 'center',
    width: 25
  },
  notificationCountWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  panelSummaryContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  panelSummaryRoot: {
    background: theme.palette.gray13.main
  },
  root: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(3)
  },
  title: {
    fontWeight: 'bold'
  }
}))

export default OrganizationVerificationRequests
