import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import LinkField from '../common/LinkField'
import Progress from '../common/Progress'

import { useMutation } from '../hooks'
import { CONTACT_SUPPORT_URL } from '../utils/constants'
import { GET_USER_DATA } from '../Home/queries'
import { EDIT_USER_PROFILE } from './mutations'
import RoundedButton from '../common/RoundedButton'

export default function MembershipDeclinedModal({
  userId,
  orgName,
  requestResent,
  isOpen,
  setOpen,
  handleSubmit,
  joinOrgLoading,
  resetToSelectOrg,
  updateUserLoading
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const { execute: updateUser } = useMutation(EDIT_USER_PROFILE, {
    refetchQueries: [{ query: GET_USER_DATA, variables: { id: userId } }]
  })

  function handleClose() {
    setOpen(false)

    updateUser({
      id: userId,
      values: { signUpStep: null }
    })
  }

  let isMobile = false

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        <Typography className={classes.headerText}>Participation request declined</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.warningContainer}>
          <div className={classes.warning}>
            <WarningIcon className={classes.warningIcon} />
            <Typography className={classes.warningText}>
              Your request to join the TIP participation account of {orgName} has been declined.
            </Typography>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <div className={classes.actionBox} style={{ width: isMobile ? '100%' : 250 }}>
            <Typography className={classes.actionTitle}>Re-send request</Typography>
            <Typography className={classes.actionText}>
              {requestResent
                ? 'You have already re-submitted your participation request. Contact TIP Support for more information.'
                : 'You may re-send your request to the Account Administrator.'}
            </Typography>
            {requestResent ? (
              <LinkField
                className={classes.supportLink}
                color="primary"
                label={'Contact TIP Support'}
                to={CONTACT_SUPPORT_URL}
                hideIcon={true}
                hideBorder={true}
              />
            ) : (
              <RoundedButton
                className={classes.button}
                fullWidth={false}
                color="primary"
                onClick={handleSubmit}
              >
                {joinOrgLoading ? <Progress size={30} delay={0} /> : 'Re-send'}
              </RoundedButton>
            )}
          </div>
          <div className={classes.actionBox} style={{ width: isMobile ? '100%' : 250 }}>
            <Typography className={classes.actionTitle}>Select a different organization</Typography>
            <Typography className={classes.actionText}>
              You may request participation with a different organization. You must be an employee
              of that organization.
            </Typography>
            <RoundedButton
              className={classes.button}
              fullWidth={false}
              color="primary"
              onClick={() => resetToSelectOrg()}
            >
              {updateUserLoading ? <Progress size={30} delay={0} /> : 'Go to selection'}
            </RoundedButton>
          </div>
        </div>
        <Typography className={classes.closeText}>
          Skip this.{' '}
          <span className={classes.closeWindowLink} onClick={handleClose}>
            Close window
          </span>
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 860,
    paddingBottom: 50
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    marginTop: 'auto'
  },
  buttonRoot: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  header: {
    paddingTop: 60,
    textAlign: 'center'
  },
  headerText: {
    fontSize: 34,
    fontWeight: 600
  },
  dialogContent: {
    padding: '0 100px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px'
    }
  },
  warningContainer: {
    padding: '0 50px'
  },
  warning: {
    display: 'flex',
    background: '#FFF8F2 0% 0% no-repeat padding-box',
    border: '1px solid #F1C1B7',
    padding: 20,
    marginBottom: 20
  },
  warningIcon: {
    color: '#EA7055'
  },
  warningText: {
    marginLeft: 15
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.actionBoxes.background,
    boxShadow: '0 0 5px #00000029',
    border: `1px solid ${theme.palette.gray7.main}`,
    textAlign: 'center',
    padding: 25,
    borderRadius: '6px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20
    }
  },
  actionTitle: {
    fontWeight: 600,
    marginBottom: 10
  },
  actionText: {
    fontSize: 14,
    marginBottom: 15
  },
  closeText: {
    textAlign: 'center',
    marginTop: 20
  },
  closeWindowLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  supportLink: {
    marginTop: 'auto'
  }
}))
