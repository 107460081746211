import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { makeStyles } from '@material-ui/styles'

import GroupActivationRequestSignatureModal from '../../../ProjectGroups/Admin/GroupActivationRequestSignatureModal'
import { getAgreementUrl } from '../../../utils/cdnHelper'

export default function GroupActivationRequestsActions({
  activateUnrestricted,
  denyRequest,
  onRequestSignatureSuccess,
  orgAdminId,
  request,
  setSuccessModalName,
  type
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let charterLocation = null

  if (request.projectGroup.charterAgreement) {
    charterLocation = getAgreementUrl(request.projectGroup.charterAgreement.fileName)
  }

  return (
    <div>
      <GroupActivationRequestSignatureModal
        agreementLink={charterLocation}
        agreementLinkText="Review Project Group Charter"
        onSuccess={onRequestSignatureSuccess}
        open={open}
        orgAdminId={orgAdminId}
        projectGroupName={request.name}
        projectGroupId={request.projectGroupId}
        requestUserId={request.userId}
        setOpen={setOpen}
        setSuccessModalName={setSuccessModalName}
        type="Project Group"
      />
      <IconButton aria-label="admin options" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="org-verification-request-options"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            if (!request.restricted && type === 'subGroup') {
              activateUnrestricted(request.projectGroupId)
            } else {
              setOpen(true)
              handleClose()
            }
          }}
        >
          <PowerSettingsNewIcon className={classes.icon} />
          <Typography className={classes.option}>Activate</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            denyRequest(request.projectGroupId)
            handleClose()
          }}
        >
          <RemoveCircleOutlineIcon className={classes.icon} />
          <Typography className={classes.option}>Ignore</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export const useStyles = makeStyles(theme => ({
  option: {
    fontSize: '.9rem',
    color: theme.palette.gray8.main
  },
  icon: {
    marginRight: 10,
    color: theme.palette.gray8.main
  }
}))
