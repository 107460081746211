import React from 'react'
import { Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

import EditOrganizationModal from '../Profile'
import { formatDate } from '../../utils/dateTime'
import config from '../../config'

const PreApprovedListCard = ({
  getTierLabel = () => null,
  location = {},
  org: {
    id: orgId,
    name: orgName = '',
    signedAgreements: signedAgreements = [],
    membership: { membershipLevelId: levelId = '', renewalDate } = {},
    organizationRoles: {
      organizationAdmin: orgAdmin = {},
      authorizedSigners: orgAuthReps = []
    } = {}
  } = {},
  TooltipHelper = () => null
}) => {
  const classes = useStyles()

  const gpa = signedAgreements[0]
  const signedDate = gpa.signedDate ? formatDate(gpa.signedDate) : null
  const tierLabel = getTierLabel({ membershipLevelId: levelId })

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.headerContainer}
        spacing={1}
        xs={12}
      >
        <>
          <Grid item xs={12} sm={3}>
            <EditOrganizationModal id={orgId} returnUrl={location.pathname}>
              {onClick => (
                <TooltipHelper
                  componentProps={{
                    className: classes.editOrgLink,
                    onClick
                  }}
                  content={orgName}
                />
              )}
            </EditOrganizationModal>
          </Grid>
          <Grid item xs={12} sm={1}>
            <TooltipHelper content={tierLabel.short} tooltipTitle={tierLabel.long} />
          </Grid>
          <Grid item xs={12} sm={2}>
            {orgAdmin && <TooltipHelper content={orgAdmin.firstname + ' ' + orgAdmin.lastname} />}
          </Grid>
          <Grid item xs={12} sm={2}>
            {orgAuthReps.length > 0 && (
              <TooltipHelper
                content={
                  orgAuthReps[0].user
                    ? orgAuthReps[0].user.firstname + ' ' + orgAuthReps[0].user.lastname
                    : orgAuthReps[0].firstname + ' ' + orgAuthReps[0].lastname
                }
              />
            )}
          </Grid>
          <Grid item xs={5} sm={2}>
            {signedDate ? <TooltipHelper content={signedDate} /> : ''}
          </Grid>
          <Grid item xs={3} sm={1}>
            {renewalDate && <Typography className={classes.paidPill}>CC Paid</Typography>}
          </Grid>
          <Grid item xs={3} sm={1}>
            {gpa.envelopeId && (
              <TooltipHelper
                componentProps={{
                  onClick: () => window.open(`${config.DOCUSIGN_URL}/${gpa.envelopeId}`, '_blank')
                }}
                content={<GetAppIcon className={classes.docusignIcon} />}
                TextComponent={IconButton}
                tooltipTitle="Docusign"
              />
            )}
          </Grid>
        </>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  docusignIcon: {
    backgroundColor: '#ffd228',
    borderRadius: 4,
    color: '#e42815',
    fontSize: 29
  },
  editOrgLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  errorSection: {
    color: theme.palette.error.main
  },
  headerContainer: {
    padding: theme.spacing(2)
  },
  menuIcon: {
    color: theme.palette.gray8.main,
    marginRight: 10
  },
  menuText: {
    fontSize: '.9rem',
    color: theme.palette.gray8.main
  },
  paidPill: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    color: 'white',
    fontSize: 14,
    padding: '4px 8px',
    width: 'fit-content'
  }
}))

export default PreApprovedListCard
