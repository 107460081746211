function isEmailBlacklisted(email, domains) {
  /*
  if (config.CLIENT_ENV !== 'production' &&      ) {
    // will always return false unless in production and
    // the INCLUDE_BLOCKED_DOMAINS is explicitly set to 'TRUE' in the .env file default is 'FALSE.
    // INCLUDE_BLOCKED_DOMAINS is needed to test the function without commenting out code.
    return false
  }
  */
 
  if (email.length < 3) return false // only check if minimum length is satisfied.
  const domainToCheck = email.includes('@') ? email.split('@')[1] : email
  return domains.includes(domainToCheck)
}

export default isEmailBlacklisted
