import React from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/Help'

import RoundedButton from '../../common/RoundedButton'

export default function ResendEmailModal({ handleClose, handleConfirm }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  return (
    <Dialog fullScreen={fullScreen} open={true}>
      <DialogTitle className={classes.header} id="">
        Resend Email?
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContentText}>
          <HelpIcon className={classes.helpIcon} />
          <div>
            <Typography>
              Are you sure you wish to send an email to all approved participants not in the
              project?
            </Typography>
          </div>
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <RoundedButton
          fullWidth={false}
          variant="outlined"
          onClick={handleClose}
          className={classes.button}
        >
          Cancel
        </RoundedButton>
        <RoundedButton onClick={() => handleConfirm()} fullWidth={false} className={classes.button}>
          Send
        </RoundedButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  helpIcon: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    marginRight: 20
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
}))
