import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

import LinkField from '../../common/LinkField'
import { CONFIRM_BILLING_ALERT } from './mutations'
import { GET_USER } from '../FirstUseLanding/queries'
import { useMutation } from '../../hooks'
import { CONTACT_SUPPORT_URL } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    marginBottom: 15
  },
  alert: {
    background: '#FFFBE5',
    border: '1px solid #EAD160',
    fontWeight: 'bold'
  },
  supportLink: {
    display: 'inline'
  },
  supportLinkIcon: {
    fontSize: '1.2rem',
    verticalAlign: 'text-top'
  }
}))

export default function BillingAlert({ isOrgAdmin, orgId, userId }) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const { execute } = useMutation(CONFIRM_BILLING_ALERT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_USER, variables: { id: userId } }]
  })

  function confirmBillingAlert() {
    setOpen(false)
    execute({
      id: userId
    })
  }

  return open ? (
    isOrgAdmin ? (
      <div className={classes.root}>
        <Alert className={classes.alert} severity="warning" onClose={confirmBillingAlert}>
          TIP Requires participation dues beginning February 8th. Please go to the{' '}
          <LinkField
            onClick={confirmBillingAlert}
            label="Account page"
            to={`/organization/${orgId}/billing`}
          />{' '}
          to check that your Billing Contact and Account Admin on file with TIP are correct.
        </Alert>
      </div>
    ) : (
      <div className={classes.root}>
        <Alert className={classes.alert} severity="warning" onClose={confirmBillingAlert}>
          TIP Requires participation dues beginning February 8th. If you need to reassign either the
          Account Admin or the Billing Contact please contact{' '}
          <LinkField
            className={classes.supportLink}
            iconClassName={classes.supportLinkIcon}
            onClick={confirmBillingAlert}
            label="TIP Support"
            to={CONTACT_SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
          />
        </Alert>
      </div>
    )
  ) : null
}
