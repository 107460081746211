import React, { useState, Fragment } from 'react'
import gql from 'graphql-tag'

import { useQuery } from '../../hooks'
import TIPDialog from '../../common/TIPDialog'
import { useUserOrganization } from '../../auth/queries'
import UserInfo from './UserInfo'
import { GET_USER, GET_ORG } from './queries'
import { dropdownElevation, DropdownElevationContext } from '../../common/CheckboxDropdown'

const EditUser = ({
  id,
  match,
  children,
  onUpdate = (
    cache,
    {
      data: {
        removeFile: { id: removeFileId } = {},
        uploadFile: { name: uploadFileName = '', id: uploadFileId } = {}
      } = {}
    } = {}
  ) => {
    if (removeFileId || uploadFileId) {
      cache.writeFragment({
        id: `User:${id}`,
        fragment: gql`
          fragment user on User {
            logo
          }
        `,
        data: {
          __typename: 'User',
          logo: uploadFileName
        }
      })
    }
  },
  returnUrl
}) => {
  id = id || match.params.id

  // IF rendered by a Route treat as a page instead of modal
  const isModal = !(match && match.params.id)

  const [open, setOpen] = useState(false)

  const { data, error, loading } = useQuery(GET_USER, {
    variables: { id }
  })

  const { loading: userLoading, organization } = useUserOrganization({
    variables: { id: id },
    skip: !id
  })

  const { orgId } = organization

  const { data: { organization: organizationResult } = {}, loading: orgLoading } = useQuery(
    GET_ORG,
    {
      variables: { id: orgId },
      skip: !orgId
    }
  )

  const orgName = organizationResult ? organizationResult.name : ''

  if (loading || userLoading || (orgId && orgLoading)) {
    return children ? children(handleClickOpen) : null
  }

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  const { user: userData } = data || {}

  let user = {}

  if (userData) {
    user = {
      id: userData.id,
      atlassianId: userData.atlassianId,
      firstname: userData.firstname,
      lastname: userData.lastname,
      jobTitle: userData.jobTitle,
      phoneNumber: userData.phoneNumber,
      email: userData.email,
      githubId: userData.githubId,
      logo: userData.logo,
      shippingAddress: userData.shippingAddress,
      slackUserId: userData.slackUserId,
      percentageComplete: userData.percentageComplete,
      organizationName: orgName,
      organizationId: orgId
    }
  }

  return (
    <Fragment>
      {isModal ? (
        <Fragment>
          {children(handleClickOpen)}
          <TIPDialog open={open} handleClose={handleClose}>
            <DropdownElevationContext.Provider value={dropdownElevation.raised}>
              {!loading && !error && (
                <UserInfo returnUrl={returnUrl} user={user} onUpdate={onUpdate} />
              )}
            </DropdownElevationContext.Provider>
          </TIPDialog>
        </Fragment>
      ) : (
        <div>{!loading && !error && <UserInfo user={user} onUpdate={onUpdate} />}</div>
      )}
    </Fragment>
  )
}
export default EditUser
