export const signUpStepHelper = {
  1: {
    name: 'Create Account',
    virtualPage: 'create-account'
  },
  2: {
    name: 'Create Profile',
    virtualPage: 'create-profile'
  },
  3: {
    name: 'Select Organization',
    virtualPage: 'select-organization'
  },
  4: {
    name: 'Create Org Profile',
    virtualPage: 'create-org-profile'
  },
  5: {
    name: 'Summary',
    virtualPage: 'summary'
  },
  6: {
    name: 'Billing Information',
    virtualPage: 'billing-information'
  },
  7: {
    name: 'Participation Tiers',
    virtualPage: 'participation-tiers'
  },
  8: {
    name: 'Participation Agreement',
    virtualPage: 'participation-agreement'
  },
  9: {
    name: 'Review And Send Agreement',
    virtualPage: 'review-and-send-agreement'
  },
  10: {
    name: 'Signature Pending',
    virtualPage: 'signature-pending'
  },
  11: {
    name: 'Request Pending',
    virtualPage: 'request-pending'
  },
  12: {
    name: 'Welcome Modal',
    virtualPage: 'welcome-modal'
  }
}
