import React from 'react'
import MUIRichTextEditor from 'mui-rte'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import theme from '../../theme'

// using React.memo as we do not want the component to update after the initial render
// value serves as a default value only, editor state is self contained and we update form state onChange
const OfferingDescriptionRichText = React.memo(
  props => {
    const { handleChange, label, name, validator, value } = props
    const defaultTheme = createMuiTheme()

    Object.assign(defaultTheme, {
      overrides: {
        MUIRichTextEditor: {
          root: {
            border: `1px solid ${theme.palette.borderGray.main}`,
            color: '#414141',
            '& div[data-block]': {
              marginBottom: '1em'
            }
          },
          container: {
            minHeight: 300
          },
          editorContainer: {
            height: '100%',
            borderTop: `1px solid ${theme.palette.borderGray.main}`,
            padding: 15
          }
        }
      }
    })

    const handleContentChange = editorState => {
      const contentState = editorState.getCurrentContent()
      const plainText = editorState.getCurrentContent().getPlainText()
      const converted = convertToRaw(contentState)
      handleChange(plainText, converted)
    }

    let content

    if (value) {
      const contentHTML = convertFromHTML(value)
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      )
      content = JSON.stringify(convertToRaw(state))
    } else {
      content = ''
    }

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <MUIRichTextEditor
          controls={['bulletList']}
          draftEditorProps={{ stripPastedStyles: true }}
          id={name}
          // we do not want user to be able to do anything that isn't allowed in the controls of the editor. Only bulleting is allowed.
          keyCommands={[
            {
              key: 66, // remove ability to bold text with key command
              name: 'no-bolding',
              callback: editorState => editorState
            },
            {
              key: 73, // remove ability to italicize text with key command
              name: 'no-italics',
              callback: editorState => editorState
            },
            {
              key: 85, // remove ability to underline text with key command
              name: 'no-underline',
              callback: editorState => editorState
            }
          ]}
          label={label}
          maxLength={validator.maxLength}
          onChange={handleContentChange}
          value={content}
        />
      </MuiThemeProvider>
    )
  },
  (prevProps, nextProps) => true
)

export default OfferingDescriptionRichText
