import gql from 'graphql-tag'

export const SEND_PROJECT_GROUP_AGREEMENT = gql`
  mutation requestProjectGroupAgreementSignature(
    $input: RequestProjectGroupAgreementSignatureInput
  ) {
    requestProjectGroupAgreementSignature(input: $input) {
      success
      projectGroupId
    }
  }
`

export const CANCEL_PROJECT_GROUP_AGREEMENT = gql`
  mutation revokeProjectGroupAgreementSignature($input: RevokeProjectGroupAgreementInput) {
    revokeProjectGroupAgreementSignature(input: $input) {
      result
      data
    }
  }
`

export const SEND_ORG_MEMBERS_INVITATION_EMAIL = gql`
  mutation sendOrgMembersInvitationEmail($input: SendMemberInvitationInput!) {
    sendOrgMembersInvitationEmail(input: $input) {
      data
      result
    }
  }
`

export const RESEND_TO_ORG_APPROVED_MEMBERS = gql`
  mutation resendToOrgApprovedMembers($input: ResendToOrgApprovedMembersInput!) {
    resendToOrgApprovedMembers(input: $input) {
      data
      result
    }
  }
`
export const ADD_PROJECT_GROUP_CHAIR = gql`
  mutation($id: ID!, $input: ProjectGroupChairInput!) {
    addProjectGroupChair(id: $id, input: $input) {
      email
      firstname
      lastname
      company
    }
  }
`

export const REMOVE_PROJECT_GROUP_CHAIR = gql`
  mutation($id: ID!, $input: ProjectGroupChairInput!) {
    removeProjectGroupChair(id: $id, input: $input) {
      result
      data
    }
  }
`

export const REQUEST_PROJECT_GROUP_SUPPORT_MUST_VERIFY = gql`
  mutation($input: requestPGSupportVerifyInput!) {
    requestProjectGroupSupportMustVerify(input: $input) {
      success
      projectGroupId
    }
  }
`
