import React, { useState } from 'react'
import { IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'

import { useMutation } from '../../hooks'
import { Progress } from '../../common'
import { GET_RENEWING_ORGS } from './queries'
import { CHANGE_ORGANIZATION_STATUS } from '../ManageOrganizations/mutations'
import { INCREMENT_RENEWAL_DATE } from './mutations'

const RenewingOrgsListActions = ({ organization = {}, setErrorState = () => null }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState('')

  const { execute: executeChangeOrgStatus, loading: loadingChangeOrgStatus } = useMutation(
    CHANGE_ORGANIZATION_STATUS,
    {
      onCompleted: ({ editOrganizationStatus = {} }) => {
        if (editOrganizationStatus.result) {
          if (editOrganizationStatus.status === selectedStatus) {
            enqueueSnackbar(`${organization.name} has been successfully ${selectedStatus}.`, {
              variant: 'success',
              autoHideDuration: 4500
            })
          }
        }
      },
      onError: e => {
        console.error(e)
        setErrorState()
      },
      update: (
        cache,
        { data: { editOrganizationStatus: { result, id, status, showOnPublicSite } = {} } }
      ) => {
        if (result) {
          if (status === 'deactivated') {
            const orgData = cache.readQuery({ query: GET_RENEWING_ORGS })

            const updatedOrgData = [...orgData.getRenewingOrgs]

            updatedOrgData.splice(updatedOrgData.findIndex(org => org.id === organization.id), 1)

            cache.writeQuery({
              query: GET_RENEWING_ORGS,
              data: {
                getRenewingOrgs: updatedOrgData
              }
            })
          }

          cache.writeFragment({
            id: `Organization:${organization.id}`,
            fragment: gql`
              fragment org on Organization {
                approvalStatus
                showOnPublicSite
              }
            `,
            data: {
              __typename: 'Organization',
              approvalStatus: status,
              showOnPublicSite
            }
          })
        }
      }
    }
  )

  const {
    execute: executeIncrementRenewalDate,
    loading: loadingIncrementRenewalDate
  } = useMutation(INCREMENT_RENEWAL_DATE, {
    onCompleted: ({ incrementRenewalDate = {} }) => {
      if (incrementRenewalDate.result) {
        enqueueSnackbar(`${organization.name} renewal date has been successfully updated.`, {
          variant: 'success',
          autoHideDuration: 4500
        })
      }
    },
    onError: e => {
      console.error(e)
      setErrorState()
    },
    update: (
      cache,
      { data: { incrementRenewalDate: { approvalStatus, renewalDate, result } = {} } }
    ) => {
      if (result) {
        const orgData = cache.readQuery({ query: GET_RENEWING_ORGS })

        const updatedOrgData = [...orgData.getRenewingOrgs]

        updatedOrgData.splice(updatedOrgData.findIndex(org => org.id === organization.id), 1)

        cache.writeQuery({
          query: GET_RENEWING_ORGS,
          data: {
            getRenewingOrgs: updatedOrgData
          }
        })

        cache.writeFragment({
          id: `Organization:${organization.id}`,
          fragment: gql`
            fragment org on Organization {
              approvalStatus
              membership {
                renewalDate
              }
            }
          `,
          data: {
            __typename: 'Organization',
            approvalStatus,
            membership: {
              __typename: 'OrganizationMembership',
              renewalDate
            }
          }
        })
      }
    }
  })

  if (loadingChangeOrgStatus || loadingIncrementRenewalDate) {
    return <Progress />
  }

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            if (organization.approvalStatus !== 'approved') {
              setSelectedStatus('approved')
              executeChangeOrgStatus({
                id: organization.id,
                values: {
                  status: 'approved',
                  beforeStatus: organization.approvalStatus
                }
              })
            }
            executeIncrementRenewalDate({
              id: organization.id
            })
            setAnchorEl(null)
          }}
        >
          <MonetizationOnIcon className={classes.menuIcon} />
          <Typography className={classes.menuText}>Paid</Typography>
        </MenuItem>
        {organization.approvalStatus === 'approved' && (
          <MenuItem
            onClick={() => {
              setSelectedStatus('suspended')
              executeChangeOrgStatus({
                id: organization.id,
                values: {
                  status: 'suspended',
                  beforeStatus: organization.approvalStatus
                }
              })
              setAnchorEl(null)
            }}
          >
            <RemoveCircleIcon className={classes.menuIcon} />
            <Typography className={classes.menuText}>Suspend</Typography>
          </MenuItem>
        )}
        {organization.approvalStatus === 'suspended' && (
          <MenuItem
            onClick={() => {
              setSelectedStatus('deactivated')
              executeChangeOrgStatus({
                id: organization.id,
                values: {
                  status: 'deactivated',
                  beforeStatus: organization.approvalStatus
                }
              })
              setAnchorEl(null)
            }}
          >
            <CancelIcon className={classes.menuIcon} />
            <Typography className={classes.menuText}>Deactivate</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  menuIcon: {
    color: theme.palette.gray8.main,
    marginRight: 10
  },
  menuText: {
    fontSize: '.9rem',
    color: theme.palette.gray8.main
  }
}))

export default RenewingOrgsListActions
