import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import OrganizationVerificationRequestsDialog from './OrganizationVerificationRequestsDialog'
import OrganizationVerificationRequestsActions from './OrganizationVerificationRequestsActions'
import { useMutation } from '../../../hooks'
import { getImageUrl } from '../../../utils'
import defaultCompanyLogo from '../../../assets/images/Company_Icon.png'
import { APPROVE_SUPPORT_MUST_VERIFY_REQUEST, DENY_SUPPORT_MUST_VERIFY_REQUEST } from '../mutations'
import { GET_SUPPORT_MUST_VERIFY_REQUESTS } from '../../../Organization/FirstUseLanding/queries'

const OrganizationVerificationRequestsCard = ({ onResult, request }) => {
  const classes = useStyles()

  const [error, setError] = useState(false)

  const approveOnCompleted = data => {
    const result = data.approveSupportMustVerifyRequest.result

    if (result) {
      onResult('approved')
    } else {
      setError(true)
    }
  }

  const denyOnCompleted = data => {
    const result = data.denySupportMustVerifyRequest.result

    if (result) {
      onResult('denied')
    } else {
      setError(true)
    }
  }

  const data = {
    values: {
      projectGroupId: request.projectGroupId,
      organizationId: request.organizationId
    }
  }

  const approveRequest = () => {
    executeApprove(data)
  }

  const denyRequest = () => {
    executeDeny(data)
  }

  const { execute: executeApprove, loading: approveLoading } = useMutation(
    APPROVE_SUPPORT_MUST_VERIFY_REQUEST,
    {
      onCompleted: approveOnCompleted,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SUPPORT_MUST_VERIFY_REQUESTS
        }
      ]
    }
  )

  const { execute: executeDeny, loading: denyLoading } = useMutation(
    DENY_SUPPORT_MUST_VERIFY_REQUEST,
    {
      onCompleted: denyOnCompleted,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SUPPORT_MUST_VERIFY_REQUESTS
        }
      ]
    }
  )

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" className={classes.card} spacing={2}>
        <Grid item xs={4}>
          <Typography>{request.name}</Typography>
          {error && <Typography className={classes.errorText}>Something went wrong</Typography>}
        </Grid>
        <Grid item xs={4}>
          <Typography>{request.website}</Typography>
        </Grid>
        <Grid container item alignItems="center" xs={2}>
          <Grid item>
            <OrganizationVerificationRequestsDialog logo={request.logo}>
              {onClick =>
                request.logo ? (
                  <Avatar
                    alt="Profile Icon"
                    className={classes.avatar}
                    src={getImageUrl(request.logo)}
                    onClick={onClick}
                  />
                ) : (
                  <Avatar
                    alt="Profile Icon"
                    src={defaultCompanyLogo}
                    className={classes.avatarBlank}
                  />
                )
              }
            </OrganizationVerificationRequestsDialog>
          </Grid>
        </Grid>
        <Grid className={classes.flexEnd} item xs={2}>
          <OrganizationVerificationRequestsActions
            executeApprove={approveRequest}
            executeDeny={denyRequest}
          />
        </Grid>
      </Grid>
      <Backdrop classes={{ root: classes.backdropRoot }} open={approveLoading || denyLoading} />
      <Hidden xsUp={!approveLoading && !denyLoading}>
        <CircularProgress className={classes.progressCircle} color="primary" size={60} />
      </Hidden>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    cursor: 'pointer'
  },
  avatarBlank: {
    background: 'white',
    borderRadius: '50%',
    border: '1px solid gray',
    height: theme.spacing(7),
    width: theme.spacing(7)
  },
  avatarWrapper: {
    marginRight: theme.spacing(2)
  },
  backdropRoot: {
    bottom: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 0
  },

  card: {
    padding: theme.spacing(2)
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray8.main
  },
  errorText: {
    color: 'red'
  },
  iconBlank: {
    color: 'gray',
    fontSize: 40
  },
  progressCircle: {
    left: 'calc(50% - 30px)',
    position: 'absolute',
    top: 'calc(50% - 30px)'
  },
  root: {
    position: 'relative'
  }
}))

export default OrganizationVerificationRequestsCard
