import React, { useState } from 'react'
import { makeStyles, MenuItem, TextField, Typography, useTheme } from '@material-ui/core'
import Select from 'react-select'

const countryList = require('country-list')

countryList.overwrite([
  {
    code: 'TW',
    name: 'Taiwan'
  },
  {
    code: 'US',
    name: 'United States'
  }
])

export const Countries = countryList
  .getData()
  .sort(function(a, b) {
    if (a.name < b.name || a.code === 'US') {
      return -1
    }

    if (a.name > b.name || b.code === 'US') {
      return 1
    }

    return 0
  })
  .map(({ code, name }) => ({
    value: code,
    label: name
  }))

const CountryDropdown = ({
  autoComplete,
  error,
  errorHelperText,
  helperText,
  hideLabel,
  includePhone = false,
  InputProps,
  inputType,
  label,
  margin,
  menuPlacement = 'top',
  name,
  onBlur = () => null,
  onChange = () => null,
  onFocus = () => null,
  setField,
  setFieldError,
  size,
  value,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [showError, setShowError] = useState(value ? true : false)

  // TODO - REPLACE WITH VIRTUALIZED MUI AUTOCOMPLETE
  return (
    <Select
      classes={classes}
      components={customComponents}
      inputId="select-country"
      isClearable={true}
      menuPlacement={menuPlacement}
      name={name}
      options={Countries}
      onBlur={(...props) => {
        if (value && !showError) {
          setShowError(true)
        }

        onBlur(...props)
      }}
      onChange={(e, ...props) => {
        if (e) {
          if (value !== e.value) {
            setField(name, e.value)

            if (includePhone) {
              setField('phoneNumber', '')
              setFieldError('phoneNumber', `Phone number is required`)
            }
          }
        } else {
          setField(name, '')
          setFieldError(name, `${label} is required`)
        }

        onChange(e, ...props)
      }}
      onFocus={(...props) => {
        if (value && !showError) {
          setShowError(true)
        }

        onFocus(...props)
      }}
      styles={{
        input: base => ({
          ...base,
          color: theme.palette.text.primary,
          '& input': {
            font: 'inherit'
          }
        }),
        menu: base => ({
          ...base,
          zIndex: 2
        })
      }}
      TextFieldProps={{
        autoComplete,
        error: showError && error,
        helperText: showError && error ? errorHelperText : helperText,
        InputLabelProps: {
          htmlFor: 'select-country',
          shrink: true
        },
        InputProps,
        inputType,
        label: !hideLabel && label,
        margin: margin || 'normal',
        placeholder: label,
        size,
        variant: 'outlined'
      }}
      value={Countries.find(c => c.value === value || c.label === value)}
      {...rest}
    />
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  // TODO - REPLACE WITH INPUTTEXTFIELD
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      TextFieldProps: {
        InputProps: textFieldInputProps,
        inputRef: textFieldInputRef,
        inputType: textFieldInputType,
        ...restTextFieldProps
      }
    }
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        },
        ...textFieldInputProps
      }}
      inputRef={textFieldInputRef}
      {...restTextFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      <Typography>{props.label}</Typography>
    </MenuItem>
  )
}

const customComponents = {
  Control,
  Option
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0
  },
  input: {
    display: 'flex',
    height: 'auto',
    '& div': {
      padding: 0
    }
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  subtext: {
    color: theme.palette.gray.main,
    fontSize: '.8rem'
  }
}))

export default CountryDropdown
