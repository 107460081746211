import React, { useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-use-form-state'
import { Redirect } from 'react-router-dom'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { ErrorSection, InputTextFieldNew, LinkField, Progress, RoundedButton } from '../common'
import { resetStore } from '../middleware/api'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../utils'
import { useSession } from './queries'
import { LOGIN_USER } from './mutations'
import { VERIFY_TOKEN } from '../SignUp/mutations.js'

export default function Login({ location = {} }) {
  const [formState, inputTypes] = useFormState({ email: '', password: '' })
  const { loading, execute: loginUser, data, error } = useMutation(LOGIN_USER)
  const { isAuthed, loading: loadingSession } = useSession()
  const formClasses = useFormStyles()
  const classes = useStyles()

  const {
    loading: verifyTokenLoading,
    execute: handleVerifyToken,
    data: verifyTokenResult,
    error: verifyTokenError
  } = useMutation(VERIFY_TOKEN)

  useEffect(() => {
    if (!loading && data) {
      resetStore()
    }
  }, [data, loading])

  const [errorMessage, setErrorMessage] = useState('')

  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const handleVerifyTokenCallback = useCallback(handleVerifyToken, [])

  function handleSubmit(e) {
    e.preventDefault()
    formState.values.email = formState.values.email.trim()
    loginUser({
      values: {
        ...formState.values,
        token
      }
    })
  }

  useEffect(() => {
    if (token) {
      handleVerifyTokenCallback({ values: { token } })
    }
  }, [token, handleVerifyTokenCallback])

  useEffect(() => {
    if (verifyTokenResult && verifyTokenResult.verifyToken && !formState.values.email) {
      // if we verifed a token set our form email state
      formState.setField('email', verifyTokenResult.verifyToken.email)
    }
  }, [verifyTokenResult, formState])

  useEffect(() => {
    // if error validating token, show error

    if (verifyTokenError) {
      setErrorMessage('Something went wrong.')
    }
  }, [verifyTokenError])

  useEffect(() => {
    // if error during log in, show error

    if (error) {
      if (error.graphQLErrors[0].message === 'Error: Wrong email or password') {
        // Incorrect email/password
        setErrorMessage('Something went wrong.')
      } else {
        // Other errors
        console.error(error.graphQLErrors[0].message)

        setErrorMessage(errorMessages.TIPSupport)
      }
    }
  }, [error])

  if ((data && data.login && data.login.result && isAuthed) || (isAuthed && !loading)) {
    if (location.state && location.state.from && location.state.from.pathname) {
      return <Redirect to={location.state.from.pathname} />
    }

    if (data && data.login) {
      const loggedIn = JSON.parse(data.login.data)

      if (loggedIn.notice) {
        return <Redirect to="/sli-notice" />
      }
    }

    return <Redirect to="/" />
  }

  if (loadingSession || verifyTokenLoading) {
    // if loading return spinner
    return <Progress />
  }

  const inputs = inputFields()
  const disableSubmit = isFormSubmitDisabled(inputs, formState)
  const isSessionExpiredState = window.location.search.includes('session_expired')

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justify="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={6}
          className={clsx(formClasses.container, classes.container)}
        >
          <div className={formClasses.header}>
            <Typography role="heading" variant="h3" className={formClasses.heading}>
              Login
            </Typography>
            <Typography paragraph className={formClasses.description}>
              Enter your email address and password.
            </Typography>
          </div>
          {errorMessage && <ErrorSection includeSupport>{errorMessage}</ErrorSection>}
          {isSessionExpiredState && (
            <ErrorSection>
              You have been logged out due to inactivity, please log in again.
            </ErrorSection>
          )}
          <Grid container className={formClasses.spacingBottom} spacing={2}>
            {Object.entries(inputs).map(([name, { inputType, ...rest }]) => (
              <Grid item key={name} xs={12}>
                <InputTextFieldNew
                  error={
                    typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                  }
                  errorHelperText={formState.errors[name]}
                  inputType={inputTypes[inputType]}
                  value={formState.values[name]}
                  {...rest}
                />
              </Grid>
            ))}
          </Grid>
          <RoundedButton
            color="primary"
            data-testid="LoginBtn" // for tests only
            disabled={loading || disableSubmit}
            size="large"
            type="submit"
            variant="contained"
          >
            {loading ? <Progress size={30} delay={0} /> : 'Sign In'}
          </RoundedButton>
          <div className={formClasses.buttonContainer}>
            <LinkField
              className={classes.linkStyle}
              to={'/forgot-password'}
              label="Forgot password?"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

const inputFields = () => ({
  email: {
    autoFocus: true,
    inputType: 'email',
    label: 'Email',
    name: 'email',
    validator: {
      required: true,
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email'
    }
  },
  password: {
    error: false,
    helperText: '',
    inputType: 'password',
    label: 'Password',
    name: 'password',
    validator: {
      required: true
    }
  }
})

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 150px',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  linkStyle: {
    color: theme.palette.secondary.main
  }
}))
