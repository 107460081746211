import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import { useQuery, useMutation } from '../hooks'
import { useSession } from '../auth/queries'
import { SuccessModal, ErrorSection, Progress, InputTextFieldNew } from '../common'
import ProjectGroupAddedModal from '../ProjectGroups/ProjectGroupAddedModal'
import RequestPendingModal from '../ProjectGroups/RequestPendingModal'

import {
  ProjectGroupList,
  GET_VISIBLE_PARENT_PROJECT_GROUPS,
  GET_ORGANIZATION_PROJECT_GROUPS,
  ADD_ORGANIZATION_TO_PROJECT_GROUP,
  REMOVE_ORGANIZATION_FROM_PROJECT_GROUP
} from '../ProjectGroups'
import { GET_PROJECT_GROUP_AGREEMENTS } from '../Organization/Agreements/ProjectGroupAgreements/queries'

export default function OrganizationProjectGroups({ match }) {
  const { userId } = useSession()
  const orgId = match.params.id

  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [successModalData, setSuccessModalData] = useState({})
  const [requestPendingStatus, setRequestPendingStatus] = useState('')
  const [requestPendingSupportMustVerify, setRequestPendingSupportMustVerify] = useState(false)
  const [requestPendingRestricted, setRequestPendingRestricted] = useState(false)
  const [openRequestPendingModal, setOpenRequestPendingModal] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const {
    loading: organizationAgreementsLoading,
    data: { organization: { signedAgreements: organizationAgreements = [] } = {} }
  } = useQuery(GET_PROJECT_GROUP_AGREEMENTS, {
    variables: { id: orgId },
    fetchPolicy: 'network-only'
  })

  const { isImpersonating, isSystemAdmin } = useSession()
  const canModifyPgs = !isImpersonating || (isImpersonating && isSystemAdmin)

  const {
    loading: projectGroupsLoading,
    data: { visibleProjectGroups: allProjectGroups = [] } = {}
  } = useQuery(GET_VISIBLE_PARENT_PROJECT_GROUPS, { variables: { id: orgId } })

  const {
    loading: organizationProjectGroupsLoading,
    data: { findProjectGroupsByOrganizationId: organizationProjectGroups = [] } = {}
  } = useQuery(GET_ORGANIZATION_PROJECT_GROUPS, { variables: { id: orgId } })
  const classes = useStyles()
  const [formState, { text }] = useFormState({ search: '' })
  const [openProjectGroupAddedModal, setOpenProjectGroupAddedModal] = useState(false)
  const [projectGroupsAdded, setProjectGroupsAdded] = useState([])

  const orgProjectGroupArray = organizationProjectGroups.reduce((acc, orgPg) => {
    acc[orgPg.projectGroupId] = {
      joined: true,
      joinedDate: orgPg.joinedDate,
      pending: !!orgPg.pending,
      approvalStatus: orgPg.approvalStatus
    }

    return acc
  }, {})

  const showSuccessModal = data => {
    setSuccessModalData(data)
    setSuccessModalOpen(true)
  }

  const projectGroupList = allProjectGroups.map(pg => {
    let results = pg

    if (orgProjectGroupArray[pg.id]) {
      results.joinedPg = orgProjectGroupArray[pg.id].joined
      results.joinedDate = orgProjectGroupArray[pg.id].joinedDate
      results.pending = orgProjectGroupArray[pg.id].pending
    } else {
      results.joinedPg = false
      results.joinedDate = null
      results.pending = false
    }

    if (results.subProjectGroups.length > 0) {
      results.subProjectGroups = results.subProjectGroups.map(sub => {
        let results = sub

        if (orgProjectGroupArray[sub.id]) {
          results.joinedPg = orgProjectGroupArray[sub.id].joined
          results.joinedDate = orgProjectGroupArray[sub.id].joinedDate
          results.pending = orgProjectGroupArray[sub.id].pending
          results.approvalStatus = orgProjectGroupArray[sub.id].approvalStatus
        } else {
          results.joinedPg = false
          results.joinedDate = null
          results.pending = false
        }

        return results
      })
    }

    return results
  })

  const joinProjectGroupUpdate = (
    store,
    { data: { addOrganizationToProjectGroup: { result, data } = {} } }
  ) => {
    if (result) {
      const query = {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: orgId }
      }

      const storeData = store.readQuery(query)
      storeData.findProjectGroupsByOrganizationId = storeData.findProjectGroupsByOrganizationId.concat(
        data.map(projectGroupId => ({ projectGroupId: projectGroupId, joinedDate: Date.now() }))
      )

      let pgNames = data.map(pgId => {
        let pg = projectGroupList.find(pgl => pgl.id === pgId)

        if (pg) {
          return pg.name
        } else {
          let pgName
          projectGroupList.forEach(pg => {
            if (pg.subProjectGroups.length > 0) {
              pg = pg.subProjectGroups.find(pgs => pgs.id === pgId)

              if (pg) {
                pgName = pg.name
              }
            }
          })

          return pgName
        }
      })

      // show a modal of the project groups names that were just added/joined
      setProjectGroupsAdded(pgNames)
      setOpenProjectGroupAddedModal(true)
    }
  }

  const leaveProjectGroupUpdate = (
    store,
    { data: { removeOrganizationFromProjectGroup: { result, data } = {} } }
  ) => {
    if (result) {
      const query = {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: orgId }
      }

      const storeData = store.readQuery(query)

      const index = storeData.findProjectGroupsByOrganizationId
        .map(function(x) {
          return x.projectGroupId
        })
        .indexOf(data)

      storeData.findProjectGroupsByOrganizationId.splice(index, 1)
    }
  }

  const addProjectGroupOptions = {
    update: joinProjectGroupUpdate
  }

  const leaveProjectGroupOptions = {
    update: leaveProjectGroupUpdate,
    refetchQueries: [
      {
        query: GET_ORGANIZATION_PROJECT_GROUPS,
        variables: { id: orgId }
      }
    ],
    awaitRefetchQueries: true,
    onCompleted: result => {
      try {
        const pgId = result.removeOrganizationFromProjectGroup.data

        let foundPg = projectGroupList.find(pgl => pgl.id === pgId)
        let type = 'Project Group'

        if (!foundPg) {
          projectGroupList.forEach(pg => {
            if (pg.subProjectGroups.length > 0) {
              const subPg = pg.subProjectGroups.find(pgs => pgs.id === pgId)
              if (subPg) {
                foundPg = subPg
                type = 'Sub Group'
              }
            }
          })
        }

        setSuccessModalData({
          title: 'Success',
          body: (
            <Typography>
              Your organization is no longer a participant of the{' '}
              <span className={classes.bold}>
                {foundPg.name} {type}
              </span>
              .
            </Typography>
          )
        })

        setSuccessModalOpen(true)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const { execute: executeAddProjectGroups, error: addProjectGroupError } = useMutation(
    ADD_ORGANIZATION_TO_PROJECT_GROUP,
    addProjectGroupOptions
  )

  const {
    execute: executeLeaveProjectGroup,
    error: leaveProjectGroupError,
    loading: leaveGroupLoading
  } = useMutation(REMOVE_ORGANIZATION_FROM_PROJECT_GROUP, leaveProjectGroupOptions)

  if (projectGroupsLoading || organizationProjectGroupsLoading || organizationAgreementsLoading) {
    return <Progress />
  }

  let errorMessage

  if (addProjectGroupError) {
    errorMessage = 'Error joining project groups'
  }

  if (leaveProjectGroupError) {
    errorMessage = 'Error leaving project groups'
  }

  const filteredProjectGroupList = projectGroupList
    .filter(
      pg =>
        pg.name.toLowerCase().includes(
          formState.values.search
            .toLowerCase()
            .trim()
            .replace(/\s\s+/g, ' ')
        ) ||
        pg.subProjectGroups.find(spg =>
          spg.name.toLowerCase().includes(
            formState.values.search
              .toLowerCase()
              .trim()
              .replace(/\s\s+/g, ' ')
          )
        )
    )
    .sort((a, b) => {
      if (a.softwareProjectGroup === b.softwareProjectGroup) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      }

      if (a.softwareProjectGroup) {
        return -1
      }

      return 1
    })

  const projectGroupAddedModal = openProjectGroupAddedModal ? (
    <ProjectGroupAddedModal
      onClose={() => setOpenProjectGroupAddedModal(false)}
      projectGroups={projectGroupsAdded}
      bodyText="Your organization is now a participant of the selected project group(s), which means individual employees may now join and participate. If you would like to participate, visit the My project groups page and select the project groups of interest to you."
      okURL={`/user/${userId}/project-groups/${orgId}`}
      okLabel="Go to my project groups"
    />
  ) : null

  return (
    <>
      <SuccessModal
        open={successModalOpen}
        onClose={() => {
          setSuccessModalOpen(false)
        }}
        {...successModalData}
      />
      <div>
        {projectGroupAddedModal}
        <Grid container className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Manage project groups
            </Typography>
            <Typography paragraph>
              Search or select project groups below to join. Project Group participation is required
              to join Sub-Groups.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.searchBar}>
            <InputTextFieldNew
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              inputType={text}
              label="Search"
              name="search"
              value={formState.values.search}
            />
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <ErrorSection>{errorMessage}</ErrorSection>
            </Grid>
          )}
          {openRequestPendingModal && (
            <RequestPendingModal
              isOpen={openRequestPendingModal}
              onClose={() => setOpenRequestPendingModal(false)}
              restricted={requestPendingRestricted}
              setRequestPendingRestricted={setRequestPendingRestricted}
              setRequestPendingSupportMustVerify={setRequestPendingSupportMustVerify}
              supportMustVerify={requestPendingSupportMustVerify}
              type={requestPendingStatus}
            />
          )}
          <Grid item xs={12} className={classes.availableProjectGroupList}>
            <ProjectGroupList
              acceptTermsRequired={true}
              isOrgAdmin={true}
              orgId={orgId}
              projectGroups={projectGroupList}
              filteredProjectGroups={filteredProjectGroupList}
              handleJoinProjectGroups={id =>
                executeAddProjectGroups({
                  values: {
                    organizationId: orgId,
                    projectGroupIds: [id]
                  }
                })
              }
              handleLeaveProjectGroup={id =>
                executeLeaveProjectGroup({
                  values: {
                    organizationId: orgId,
                    projectGroupId: id
                  }
                })
              }
              leaveGroupLoading={leaveGroupLoading}
              onParticipationRequestSuccess={(type, supportMustVerify, restricted) => {
                setRequestPendingStatus(type)
                setRequestPendingSupportMustVerify(supportMustVerify)
                setRequestPendingRestricted(restricted)
                setOpenRequestPendingModal(true)
                enqueueSnackbar('Request notification has been sent', {
                  variant: 'success',
                  autoHideDuration: 2500
                })
              }}
              organizationAgreements={organizationAgreements}
              showSuccessModal={showSuccessModal}
              isReadOnly={!canModifyPgs}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  availableProjectGroupList: {
    padding: '20px 10px',
    backgroundColor: lighten(theme.palette.gray3.main, 0.6),
    border: `1px solid ${theme.palette.borderGray.main}`,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      border: 'none'
    }
  },
  table: {
    minWidth: 750,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      tableLayout: 'fixed'
    }
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableCell: {
    border: 'none',
    padding: '10px 0px',
    paddingRight: '0 !important'
  },
  searchBar: {
    marginBottom: 50
  },
  pageContainer: {
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      padding: 5
    }
  },
  title: { fontSize: '1.2rem' },
  collapsible: { cursor: 'pointer', fontSize: '.9rem' },
  bold: {
    fontWeight: 600
  }
}))
