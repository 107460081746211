import gql from 'graphql-tag'

export const GET_SINGLE_AGREEMENT = gql`
  query getAgreement($id: ID!) {
    agreement(id: $id) {
      id
      name
      type
      notes
      version
      versionDate
      fileName
      templateId
    }
  }
`

export const GET_TERMINATION_BUTTON_VISIBILITY = gql`
  query {
    showTerminationButton {
      showButton
      success
    }
  }
`
