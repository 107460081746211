import React, { useState } from 'react'
import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ReCaptcha } from 'react-recaptcha-v3'

import Progress from '../common/Progress'
import GetStarted from './GetStarted'
import GetStartedInfo from './GetStartedInfo'
import CheckYourInbox from './CheckYourInbox'
import EmailResent from './EmailResent'
import { useFormStyles } from '../hooks'
import config from '../config'

export default function SignUp() {
  const [verifyCaptcha, setVerifyCaptcha] = useState(false)
  const [email, setEmail] = useState()
  const [emailResent, setEmailResent] = useState()
  const formClasses = useFormStyles()
  const classes = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let renderSection = 'get-started'

  if (email) {
    renderSection = 'check-your-inbox'
  }

  if (emailResent) {
    renderSection = 'email-resent'
  }

  function resetState() {
    setEmailResent(false)
    setEmail()
  }

  function verifyCallback(recaptchaToken) {
    setVerifyCaptcha(true)
  }

  if (!verifyCaptcha) {
    return (
      <div>
        <ReCaptcha
          sitekey={config.RECAPTCHA_PUBKEY}
          action="action_name"
          verifyCallback={verifyCallback}
        />
        <Progress size={30} delay={2} />
      </div>
    )
  } else {
    const isGetStarted = renderSection === 'get-started'

    return (
      <>
        <Grid container className={classes.root} justify="center">
          <Grid
            item
            className={isMobile ? classes.container : formClasses.container}
            xs={12}
            md={isGetStarted ? 5 : 7}
            lg={isGetStarted ? 5 : 6}
          >
            {isGetStarted && <GetStarted setEmail={email => setEmail(email)} />}
            {renderSection === 'check-your-inbox' && (
              <CheckYourInbox
                email={email}
                setEmail={() => setEmail()}
                setEmailResent={() => setEmailResent(true)}
              />
            )}
            {renderSection === 'email-resent' && <EmailResent resetState={resetState} />}
          </Grid>
          {isGetStarted && <GetStartedInfo />}
        </Grid>
        {!isMobile && (
          <Typography className={classes.recaptchaRemark}>
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              href="https://policies.google.com/privacy"
              className={classes.recaptchaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://policies.google.com/terms"
              className={classes.recaptchaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            apply.
          </Typography>
        )}
      </>
    )
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(5),
    textAlign: 'center'
  },
  recaptchaRemark: {
    fontSize: 12,
    marginTop: theme.spacing(6),
    textAlign: 'center'
  },
  recaptchaLink: {
    color: '#737373'
  },
  root: {
    [theme.breakpoints.only('sm')]: {
      margin: -16,
      marginBottom: -48,
      width: 'calc(100% + 32px)'
    },
    [theme.breakpoints.only('xs')]: {
      margin: -5,
      marginBottom: -60,
      width: 'calc(100% + 10px)'
    }
  }
}))
