import React from 'react'
import { makeStyles, Chip } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  }
}))

const filter = createFilterOptions()

export default function TIPChip({
  id,
  data,
  onDelete,
  onAdd,
  showAutoCompleteInput,
  autoCompleteOptions = {}
}) {
  const classes = styles()
  const [value, setValue] = React.useState(null)

  return (
    <div className={classes.root}>
      {showAutoCompleteInput ? (
        <Autocomplete
          {...autoCompleteOptions}
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setValue({
                title: newValue
              })
              // TODO: may need to add the following line in the future:
              // onAdd(newValue)
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              // The parent component is repsonsible for adding the value to its own variable,
              //   and updating the props: data and autoComplete.options
              onAdd(newValue.inputValue)
              setValue({
                title: ''
              })
            } else {
              setValue(newValue)
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`
              })
            }

            return filtered
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id={id}
          options={autoCompleteOptions.options || []}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue
            }
            // Regular option
            return option.title
          }}
          renderOption={option => option.title}
          style={{ width: '100%' }}
          freeSolo
          renderInput={params => (
            <TextField {...params} label={autoCompleteOptions.label} variant="outlined" />
          )}
        />
      ) : null}
      <ul className={classes.chipContainer}>
        {data.map((data, index) => (
          <li key={data.key || index}>
            <Chip
              label={data.label || data}
              onDelete={() => {
                // The parent component is repsonsible for deleting the value from its own variable,
                //   and updating the props: data and autoComplete.options
                onDelete(data)
              }}
              className={classes.chip}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
