import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'

import TIPDialog from '../common/TIPDialog'
import { InputTextFieldNew } from '../common'
import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'
import ErrorSection from '../common/ErrorSection'
import DropDownField from '../common/DropDownField'
import InputFileField from '../common/InputFileField'
import { isFormSubmitDisabled } from '../utils'
import { SAVE_AGREEMENT } from './mutations'
import { GET_SINGLE_AGREEMENT } from './queries'
import { useMutation, useQuery } from '../hooks'

const types = [
  { name: 'GPA', value: 'GPA' },
  { name: 'Project Group Agreement', value: 'ProjectGroupAgreement' },
  { name: 'Project Group Charter', value: 'ProjectGroupCharter' }
]
const EditAgreementForm = ({ agreementId, children, onUpdate, isReadOnly = false }) => {
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [formState, { text }] = useFormState({
    name: '',
    templateId: '',
    agreementPdf: {
      file: null,
      removed: false
    },
    type: '',
    version: '',
    notes: ''
  })
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const options = {
    update: onUpdate
  }

  const { execute: saveAgreement, data, loading, error } = useMutation(SAVE_AGREEMENT, options)

  const {
    data: { agreement = {} } = {},
    error: getAgreementError,
    loading: loadingAgreement
  } = useQuery(GET_SINGLE_AGREEMENT, {
    variables: { id: agreementId },
    skip: !agreementId || !open
  })

  useEffect(() => {
    // on success show snack bar
    if (data && !error && !loading) {
      // close modals
      setOpen(false)

      enqueueSnackbar('Agreement Saved', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [data, error, loading, enqueueSnackbar])

  useEffect(() => {
    if (agreement && !getAgreementError && !loadingAgreement && !loaded) {
      if (!agreement.id) {
        return
      }

      formState.setField('name', agreement.name)
      formState.setField('type', agreement.type)
      formState.setField('notes', agreement.notes)
      formState.setField('version', agreement.version)
      formState.setField('templateId', agreement.templateId)

      setLoaded(true)
    }
  }, [agreement, getAgreementError, loadingAgreement, loaded, formState])

  function handleClickOpen() {
    formState.setField('name', '')
    formState.setField('templateId', '')
    formState.setField('agreementPdf', {
      file: null,
      removed: false
    })
    formState.setField('type', '')
    formState.setField('version', '')
    formState.setField('notes', '')
    setLoaded(false)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
    setLoaded(false)
  }

  function handleSubmit() {
    let sendObj = {
      name: formState.values.name,
      templateId: formState.values.templateId,
      type: formState.values.type,
      notes: formState.values.notes,
      version: formState.values.version,
      file: formState.values.agreementPdf.file
    }

    if (agreementId) {
      sendObj.agreementId = agreementId
    }

    saveAgreement({ values: sendObj })
  }

  if (loadingAgreement) {
    return null
  }

  var errorMessage = ''
  const agreementFields = {
    name: {
      InputComponent: InputTextFieldNew,
      name: 'name',
      label: 'Agreement Name*',
      inputType: text,
      autoFocus: true,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 100
      }
    },
    templateId: {
      InputComponent: InputTextFieldNew,
      name: 'templateId',
      label: 'Template Id*',
      inputType: text,
      validator: {
        required: true,
        minLength: 2
      },
      helperText: 'This must be the template Id from docusign'
    },
    agreementPdf: {
      InputComponent: InputFileField,
      gridWidth: { xs: 4 },
      name: 'agreementPdf',
      label: 'Agreement PDF*',
      acceptTypes: 'application/pdf',
      handleChange: e => formState.setField('agreementPdf', e),
      preview: !formState.values.agreementPdf.removed && agreement ? agreement.fileName : null,
      showPreview: true,
      showRemove: false,
      validator: {
        required: !formState.values.fileName
      }
    },
    type: {
      InputComponent: DropDownField,
      name: 'type',
      label: 'Type*',
      options: types.map(t => t.name),
      value: formState.values.type ? types.find(c => c.value === formState.values.type).name : '',
      handleChange: e => {
        let val = types.find(c => c.name === e.target.value).value
        formState.setField('type', val)
      },
      validator: {
        required: true
      }
    },
    version: {
      InputComponent: InputTextFieldNew,
      name: 'version',
      label: 'Version Number*',
      inputType: text,
      validator: {
        required: true
      }
    },
    notes: {
      InputComponent: InputTextFieldNew,
      name: 'notes',
      label: 'Notes',
      inputType: text,
      multiline: true,
      rows: 2,
      validator: {
        required: false,
        minLength: 2,
        maxLength: 1000
      }
    }
  }
  const disableSubmit =
    isFormSubmitDisabled(agreementFields, formState, true) ||
    (!formState.values.agreementPdf.file && agreement && !agreement.fileName)

  return (
    <>
      {children(handleClickOpen)}
      <TIPDialog open={open} handleClose={handleClose}>
        <Grid container className={classes.modal}>
          <Grid item xs={12} className={classes.header}>
            {agreementId ? (
              <Typography variant="h3">Edit Agreement</Typography>
            ) : (
              <Typography variant="h3">Create Agreement</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <form className={classes.form}>
                <Grid container spacing={2} className={clsx({ [classes.readOnly]: isReadOnly })}>
                  {Object.entries(agreementFields).map(
                    ([
                      name,
                      { gridWidth = { xs: 12 }, InputComponent, hidden, helperText, ...args }
                    ]) =>
                      !hidden && (
                        <Grid key={name} item {...gridWidth}>
                          <InputComponent
                            {...args}
                            error={
                              typeof formState.validity[name] !== 'undefined' &&
                              !formState.validity[name]
                            }
                            errorHelperText={formState.errors[name]}
                            disabled={isReadOnly}
                            value={formState.values[name]}
                          />
                        </Grid>
                      )
                  )}
                  {!isReadOnly && (
                    <Grid container justify="flex-end">
                      <Grid item xs={12} sm={5} className={classes.buttonContainer}>
                        <RoundedButton
                          onClick={handleSubmit}
                          disabled={loading || disableSubmit}
                          color="primary"
                          fullWidth={true}
                          className={classes.roundedButton}
                        >
                          {loading ? <Progress size={25} delay={0} /> : 'Save'}
                        </RoundedButton>
                        {errorMessage && <ErrorSection>{errorMessage}</ErrorSection>}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </TIPDialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  readOnly: {
    cursor: 'not-allowed !important',
    '& *': {
      pointerEvents: 'none'
    }
  },
  header: {
    marginBottom: 20
  },
  modal: {
    width: 900,
    maxWidth: '100vw'
  },
  form: {
    width: '100%',
    margin: 20,
    marginBottom: 30
  },
  buttonContainer: {
    marginTop: 20
  }
}))

export default EditAgreementForm
