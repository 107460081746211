import gql from 'graphql-tag'

export const SEND_CUSTOM_ORG_BULK_EMAIL = gql`
  mutation sendOrgBulkEmail($input: CustomEmailInput!) {
    sendOrgBulkEmail(input: $input) {
      data
      result
    }
  }
`

export const SEND_BASIC_EMAIL = gql`
  mutation sendBasicEmail($input: CustomEmailInput!) {
    sendBasicEmail(input: $input) {
      data
      result
    }
  }
`
