import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Hidden from '@material-ui/core/Hidden'

import { history } from '../store'
import { toolbarHeight, systemAdminToolbarHeight } from '.'
import { useUserOrganization, useSession, useUser } from '../auth/queries'
import { useQuery } from '../hooks'
import { roles } from '../utils/roleHelper'
import { GET_ORGANIZATION_DATA_NAV } from './queries'
import config from '../config'
import { LinkField, Status } from '../common'

const drawerWidth = 300

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  nested: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 45,
    color: theme.palette.gray.main,
    textDecoration: 'none',
    ' &:hover': {
      color: theme.palette.gray.main,
      textDecoration: 'none'
    }
  },
  nestedListItemText: {
    fontSize: 15
  },
  active: {
    color: theme.palette.gray.main
  },
  listContainer: {
    paddingLeft: 20
  },
  listItemContainer: {
    padding: '15px 15px 15px 30px',
    borderBottom: '1px solid lightgray'
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    color: 'black'
  },
  drawerPaperAdmin: {
    width: drawerWidth,
    paddingTop: toolbarHeight + systemAdminToolbarHeight,
    position: 'static'
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: toolbarHeight,
    position: 'static'
  },
  selected: {
    borderRight: '5px solid #BEBEBE',
    background: '#F6F6F6 0% 0% no-repeat padding-box !important',
    mixBlendMode: 'multiply',
    opacity: 1
  },
  pill: {
    marginLeft: 12
  }
}))

export function getNavItems({
  orgId,
  userId,
  userRoles = [],
  membershipStatus,
  organization = {},
  isSystemAdmin,
  pathName,
  signUpStep,
  classes = {}
}) {
  // if user is not in an organization they must join or create one.

  if (!orgId) {
    return []
  }

  const blockedStatuses = ['lead', 'suspended', 'in progress', 'pending', 'declined', 'deactivated']
  const signUpPaths = ['/get-started', '/sign-up']
  const { approvalStatus: orgApprovalStatus } = organization
  const membershipDeclined = !!(membershipStatus === 'declined')
  
  // is user in the middle of a sign-up flow?
  const isSignUp =
    signUpStep && signUpStep < 12 && signUpPaths.some(path => pathName) ? true : false

  return [
    {
      name: 'dashboard',
      label: 'Dashboard',
      to: '/',
      hidden: !userRoles.some(r => [roles.registeredUser].includes(r.roleId)) || isSignUp
    },
    {
      name: 'profile',
      label: 'My profile',
      to: `/user/${userId}`,
      hidden: !userRoles.some(r => [roles.registeredUser].includes(r.roleId)) || isSignUp
    },
    {
      name: 'projectGroups',
      label: 'My project groups',
      to: `/user/${userId}/project-groups/${orgId}`,
      hidden:
        !userRoles.some(r => [roles.orgMember].includes(r.roleId)) ||
        !['approved', 'active', 'suspended'].includes(membershipStatus) ||
        membershipDeclined ||
        isSignUp,
      // hidden: !['approved', 'active', 'suspended'].includes(membershipStatus),
      disabled: membershipStatus === 'suspended' || blockedStatuses.includes(orgApprovalStatus)
    },
    {
      name: 'resources',
      label: 'Resources',
      hidden: !userRoles.some(r => [roles.registeredUser].includes(r.roleId)) || isSignUp,
      subItems: [
        {
          name: 'resources01',
          label: 'Documents',
          to: `/documents`
        },
        {
          name: 'resources04',
          label: 'Atlassian',
          target: '_blank',
          rel: 'noopener noreferrer',
          to: `${config.ATLASSIAN_URL}`
        },
        {
          label: 'MoU Req. Docs',
          name: 'resources05',
          Pill: <Status color="yellow" icon="new" label="New" styles={classes.pill} />,
          to: `https://telecominfraproject.com/OpenRAN-MoU-Group/`
        }
      ]
    },
    {
      name: 'organizationAdmin',
      label: 'Account admin',
      hidden:
        !userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId)) ||
        membershipDeclined ||
        isSignUp,
      disabled: blockedStatuses.includes(orgApprovalStatus),
      subItems: [
        {
          name: 'organizationProfile',
          label: 'Organization profile',
          to: `/organization/${orgId}`,
          hidden:
            !userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId)) ||
            membershipDeclined ||
            isSignUp
        },
        {
          name: 'organizationProjectGroups',
          label: 'Manage project groups',
          to: `/organization/${orgId}/project-groups`,
          hidden:
            !userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId)) ||
            membershipDeclined ||
            isSignUp,
          disabled: blockedStatuses.includes(orgApprovalStatus)
        },
        {
          name: 'agreements',
          label: 'Agreements',
          to: `/organization/${orgId}/agreements`,
          hidden:
            !userRoles.some(r =>
              [roles.orgPrimaryContact, roles.orgMemberAdmin].includes(r.roleId)
            ) ||
            membershipDeclined ||
            isSignUp
        },
        {
          name: 'manageMembers',
          label: 'Manage participants',
          to: `/organization/${orgId}/manage-members`
        },
        {
          name: 'manageInvites',
          label: 'Invite participants',
          to: `/organization/${orgId}/manage-invites`
        },
        {
          name: 'billing',
          label: 'Account',
          to: `/organization/${orgId}/billing`
        }
      ]
    },
    {
      name: 'tipExchange',
      label: 'TIP Exchange',
      to:
        isSystemAdmin ||
        userRoles.some(r => roles.exchangeListingsAdmin === r.roleId) ||
        (userRoles.some(r => roles.readOnlySystemAdmin === r.roleId) &&
          userRoles.every(r => roles.orgMemberAdmin !== r.roleId))
          ? '/tip-exchange'
          : `/organization/${orgId}/tip-exchange`,
      hidden: !userRoles.some(r =>
        [
          roles.systemAdmin,
          roles.readOnlySystemAdmin,
          roles.exchangeListingsAdmin,
          roles.orgMemberAdmin
        ].includes(r.roleId)
      ),
      disabled:
        orgApprovalStatus !== 'approved' &&
        !userRoles.some(r => [roles.exchangeListingsAdmin].includes(r.roleId))
    }
  ]
}

export default function LeftNav() {
  const {
    loading,
    isAuthed,
    isSystemAdmin,
    isReadOnlySystemAdmin,
    isExchangeListingsAdmin,
    userId,
    isImpersonating
  } = useSession()

  const [open, setOpen] = useState(new Set())
  const classes = useStyles()

  const { loading: userOrgLoading, organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { loading: userLoading, user = {} } = useUser({
    variables: { id: userId },
    skip: !userId
  })

  // for impersonation purposes we must get the user roles, not use the session roles
  const userRoles = user.roles
  const { orgId, membershipStatus } = organizationUser

  const { data: { organization = {} } = {}, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_DATA_NAV,
    { variables: { id: orgId }, skip: !orgId }
  )

  const pathName = history.location.pathname
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (orgId) {
      function setInitialSelected() {
        let selected
        switch (pathName) {
          case '/':
            selected = 'dashboard'
            break
          case `/user/${userId}`:
            selected = 'profile'
            break
          case `/user/${userId}/project-groups/${orgId}`:
            selected = 'projectGroups'
            break
          case '/documents':
            selected = 'resources01'
            break
          case `${config.EXCHANGE_URL}/rfi`:
            selected = 'resources02'
            break
          case `${config.WORKPLACE_SSO_URL}`:
            selected = 'resources03'
            break
          case `${config.ATLASSIAN_URL}`:
            selected = 'resources04'
            break
          case `/organization/${orgId}`:
            selected = 'organizationProfile'
            break
          case `/organization/${orgId}/project-groups`:
            selected = 'organizationProjectGroups'
            break
          case `/organization/${orgId}/agreements`:
            selected = 'agreements'
            break
          case `/organization/${orgId}/billing`:
            selected = 'billing'
            break
          case `/organization/${orgId}/manage-members`:
            selected = 'manageMembers'
            break
          case `/organization/${orgId}/manage-invites`:
            selected = 'manageInvites'
            break
          case `/organization/${orgId}/tip-exchange`:
            selected = 'tipExchange'
            break
          case '/looker-dashboard/83':
            selected = 'tipMembership'
            break
          case '/looker-dashboard/80':
            selected = 'tipOrganizations'
            break
          case '/looker-dashboard/81':
            selected = 'tipProjectGroups'
            break
          default:
            selected = 'dashboard'
            break
        }
        return selected
      }
      setSelected(setInitialSelected())
    }
  }, [orgId, pathName, userId])

  function handleClick(name) {
    const isOpen = open.has(name)
    let newSet = open

    if (isOpen) {
      newSet.delete(name)
    } else {
      newSet.add(name)
    }

    setOpen(new Set(newSet))
  }

  const handleClickCallback = useCallback(handleClick, [])

  useEffect(() => {
    const navItems = getNavItems({ orgId })
    const path = window.location.pathname

    navItems.forEach(n => {
      if (n.subItems && n.subItems.some(s => s.to === path)) {
        handleClickCallback(n.name)
      }
    })
  }, [handleClickCallback, orgId])

  if (
    !orgId ||
    !isAuthed ||
    (user.signUpStep && user.signUpStep < 12 && !isImpersonating) ||
    loading ||
    userOrgLoading ||
    userLoading ||
    organizationLoading ||
    (history.location.pathname === '/glossies' || history.location.pathname === '/sli-notice')
  ) {
    return null
  }

  return (
    <Hidden only={['xs', 'sm']}>
      <Drawer
        data-testid="LeftNavigation"
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper:
            isSystemAdmin || isImpersonating || isReadOnlySystemAdmin || isExchangeListingsAdmin
              ? classes.drawerPaperAdmin
              : classes.drawerPaper
        }}
      >
        <List className={classes.listContainer}>
          {getNavItems({
            orgId,
            userId,
            userRoles,
            membershipStatus,
            organization,
            isSystemAdmin,
            classes
          }).map(({ name, label, roles, to, subItems, hidden, disabled }, index) => {
            if (hidden) {
              return false
            }

            let isOpen = open.has(name)

            if (name === 'organizationAdmin' && pathName === `/organization/${orgId}/billing`) {
              isOpen = true
            }

            return subItems ? (
              <div key={index}>
                <ListItem
                  button
                  onClick={() => handleClick(name)}
                  classes={{ root: classes.listItemContainer }}
                  disabled={disabled}
                >
                  <ListItemText primary={label} classes={{ root: classes.listItemText }} />
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* foreach item in subnav */}
                    {subItems.map(
                      ({ headerProps, hidden, label, name, Pill, to, ...rest }, index) => {
                        if (hidden) {
                          return false
                        }

                        return (
                          <ListItem
                            key={index}
                            button
                            classes={{
                              selected: classes.selected
                            }}
                            className={classes.nested}
                            component={LinkField}
                            hideIcon={!!Pill}
                            label={
                              <ListItemText
                                classes={{
                                  root: classes.listItemText,
                                  primary: classes.nestedListItemText
                                }}
                                primary={label}
                              />
                            }
                            labelOutsideSpan={Pill}
                            onClick={() => setSelected(name)}
                            {...rest}
                            selected={selected === name ? true : false}
                            to={to}
                          />
                        )
                      }
                    )}
                  </List>
                </Collapse>
              </div>
            ) : (
              <ListItem
                key={index}
                button
                classes={{ root: classes.listItemContainer, selected: classes.selected }}
                component={Link}
                disabled={disabled}
                onClick={() => setSelected(name)}
                selected={selected === name ? true : false}
                style={{ borderTop: index === 0 ? '1px solid #EAEAEA' : null }}
                to={to}
              >
                <ListItemText primary={label} classes={{ root: classes.listItemText }} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </Hidden>
  )
}
