import React, { useEffect, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import { Redirect } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import Progress from '../common/Progress'
import { useMutation, useFormStyles } from '../hooks'
import RoundedButton from '../common/RoundedButton'
import LinkField from '../common/LinkField'
import { useSession, getSessionQueries } from '../auth/queries'
import { CONTACT_SUPPORT_URL, SUPPORT_EMAIL } from '../utils/constants'

export default function AcceptInvitation(props) {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { loading: loadingSession, userId } = useSession()

  // if user is logged in we will need to refetch their data on join
  const options = userId
    ? {
        refetchQueries: getSessionQueries(userId),
        awaitRefetchQueries: true
      }
    : {}

  const {
    loading: verifyTokenLoading,
    execute: handleVerifyToken,
    data: verifyTokenResult,
    error: verifyTokenError
  } = useMutation(VERIFY_ACTIVATION_TOKEN, options)

  const params = new URLSearchParams(props.location.search)
  const token = params.get('token')
  let error = false

  const handleVerifyTokenCallback = useCallback(handleVerifyToken, [])

  useEffect(() => {
    handleVerifyTokenCallback({ values: { token } })
  }, [token, handleVerifyTokenCallback])

  if (verifyTokenLoading || loadingSession) {
    return <Progress />
  }

  if (!token) {
    // if user ended up on this page without a token, they don't belong here. Redirect to home
    return <Redirect to="/" />
  }

  if (verifyTokenResult && verifyTokenResult.verifyActivationToken.data === 'AUTOACCEPTED') {
    return <Redirect to="/" />
  }

  if (verifyTokenError) {
    // if error validating token display error to use
    error = 'Your token is invalid or expired.'
  }

  if (!verifyTokenError && verifyTokenResult && !verifyTokenResult.verifyActivationToken.result) {
    error = verifyTokenResult.verifyActivationToken.data
  }

  const { organizationName, senderName } = verifyTokenResult
    ? verifyTokenResult.verifyActivationToken
    : {}

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={12} md={7} lg={6} className={formClasses.container}>
        <div data-testid="AcceptInvitationContainer" className={classes.container}>
          <div className={clsx(formClasses.header, classes.header)}>
            <Typography role="heading" variant="h3" className={formClasses.heading}>
              Your invitation to TIP
            </Typography>
            {senderName && (
              <Typography paragraph>
                You are invited to join Telecom Infra Project (TIP)
                <br /> as part of {organizationName}.
              </Typography>
            )}
          </div>
          {error ? (
            <div className={classes.alert}>
              <Typography role="alert" color="error">
                {error}
              </Typography>

              <Button
                color="primary"
                className={classes.button}
                href="mailto:support@telecominfraproject.com"
              >
                Click here to Contact Support
              </Button>
            </div>
          ) : (
            <div className={classes.buttonContainer}>
              <RoundedButton
                component={Link}
                data-testid="AcceptInvitationViaLoginBtn"
                to={`/sign-up?token=${token}&acceptedInvite=true`}
                color="primary"
                className={classes.button}
              >
                Create Account
              </RoundedButton>
              <Button
                component={Link}
                to={`/login?token=${token}`}
                color="primary"
                className={clsx(classes.button, classes.signIn)}
              >
                Already a TIP participant? Sign In
              </Button>
            </div>
          )}
        </div>
        <div className={classes.supportContainer}>
          <Typography className={classes.supportText}>
            This page will expire after 36 hours.
          </Typography>
          <Typography className={classes.supportText}>
            Questions?{' '}
            <LinkField
              color="primary"
              label={'Contact TIP Support'}
              to={CONTACT_SUPPORT_URL}
              hideIcon={true}
            />{' '}
            or email <br />
            <LinkField
              color="primary"
              label={'support@telecominfraproject.com'}
              to={`mailto:${SUPPORT_EMAIL}`}
              hideIcon={true}
            />
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export const VERIFY_ACTIVATION_TOKEN = gql`
  mutation($input: ActivationTokenInput!) {
    verifyActivationToken(input: $input) {
      data
      result
      senderName
      organizationName
    }
  }
`

const useStyles = makeStyles(theme => ({
  spacer: {
    margin: 10,
    fontSize: 20
  },
  button: {
    textTransform: 'unset',
    fontSize: '1rem',
    marginBottom: 2
  },
  signIn: {
    marginTop: 10,
    fontSize: '.9rem'
  },
  supportContainer: {
    marginTop: 30,
    paddingTop: 20,
    borderTop: `1px solid ${theme.palette.borderGray.main}`
  },
  supportText: {
    marginTop: 20,
    color: theme.palette.gray.main,
    fontSize: '.8rem'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    paddingTop: 20,
    margin: 'auto',
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))
