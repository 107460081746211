import gql from 'graphql-tag'

export const EDIT_USER_PROFILE = gql`
  mutation($id: ID!, $input: UserUpdateInput!) {
    editUser(id: $id, input: $input) {
      id
      shippingAddress {
        city
        state
        postalCode
        country
      }
    }
  }
`

export const EDIT_ORGANIZATION_PROFILE = gql`
  mutation($id: ID!, $input: OrganizationProfileUpdateInput!) {
    editOrganizationProfile(id: $id, input: $input) {
      id
      phoneNumber
      website
      type
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation($input: AccountCreationInput!) {
    createAccount(input: $input) {
      data
      result
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation($input: TokenVerifyInput!) {
    verifyToken(input: $input) {
      result
      email
    }
  }
`

export const SEND_REQUEST_TO_JOIN_ORGANIZATION_EMAIL = gql`
  mutation($input: OrganizationUserInput!) {
    sendRequestToJoinOrganizationEmail(input: $input) {
      success
    }
  }
`

export const REMOVE_AUTHORIZED_SIGNER = gql`
  mutation($id: ID!, $input: AuthorizedSignerInput!) {
    removeAuthorizedSigner(id: $id, input: $input) {
      result
    }
  }
`
