import React, { useEffect, useReducer, useRef, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable from 'material-table'
import { useSnackbar } from 'notistack'
import equal from 'fast-deep-equal'

import { Progress, RoundedButton } from '../common'
import { DEPROVISION_INTEGRATION, SAVE_EXEMPT_LIST } from './mutations'
import { columnDef } from './helpers'
import { useSession } from '../auth/queries'
import { useMutation } from '../hooks'

const SyncIntegrationDeprovisionList = ({
  containsNonExemptDeprovisionData = false,
  disableActions = false,
  syncResults = { results: [] },
  title = '',
  triggerSyncResultInfoRefetch = () => null
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { userId, isReadOnlySystemAdmin } = useSession()
  const classes = useStyles()
  const filterValues = useRef({})
  const [confirmDeprovisionOpen, setConfirmDeprovisionOpen] = useState(false)
  const [deprovisionState, deprovisionDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'set_exempt':
          if (!(action.integrationId || action.hubId)) {
            throw new Error('Missing data to update state')
          }

          const updatedState = [...state]

          updatedState[
            updatedState.findIndex(s =>
              action.integrationId
                ? s.integrationId === action.integrationId
                : s.hubId === action.hubId
            )
          ].exempt = action.exempt

          return updatedState
        case 'set_exempt_all':
          return state.map(s => ({ ...s, exempt: true }))
        case 'set_exempt_none':
          return state.map(s => ({ ...s, exempt: false }))
        case 'set_state':
          return reducerInit(action.results)
        case 'reset_state':
          return []
        default:
          throw new Error(`Unexpected reducer action ${action}`)
      }
    },
    [],
    reducerInit
  )

  const { execute: deprovisionExecute, loading: deprovisionLoading } = useMutation(
    DEPROVISION_INTEGRATION,
    {
      onCompleted: ({ deprovisionIntegration }) => {
        if (deprovisionIntegration) {
          enqueueSnackbar(
            `${title} Sync: Deprovision queued successfully. To be notified when the task has completed, please remain on this page`,
            {
              autoHideDuration: 4500,
              variant: 'success'
            }
          )
        } else {
          enqueueSnackbar(`${title} Sync: Unable to queue deprovision`, {
            autoHideDuration: 4500,
            variant: 'warning'
          })
        }
      },
      onError: e => {
        console.error(e)

        enqueueSnackbar(`${title} Sync: Unable to queue deprovision`, {
          autoHideDuration: 4500,
          variant: 'warning'
        })
      }
    }
  )

  const { execute: saveExemptExecute, loading: saveExemptLoading } = useMutation(SAVE_EXEMPT_LIST, {
    onCompleted: ({ saveExemptList }) => {
      if (saveExemptList) {
        triggerSyncResultInfoRefetch()
        enqueueSnackbar(
          `${title} Sync: Exempt data saved successfully. To be notified when the task has completed, please remain on this page`,
          {
            autoHideDuration: 4500,
            variant: 'success'
          }
        )
      } else {
        enqueueSnackbar(`${title} Sync: Unable to save exempt data`, {
          autoHideDuration: 4500,
          variant: 'warning'
        })
      }
    },
    onError: e => {
      console.error(e)

      enqueueSnackbar(`${title} Sync: Unable to save exempt data`, {
        autoHideDuration: 4500,
        variant: 'warning'
      })
    }
  })

  useEffect(() => {
    if (syncResults.results.length > 0) {
      deprovisionDispatch({
        results: syncResults.results,
        type: 'set_state'
      })
    } else {
      deprovisionDispatch({
        type: 'reset_state'
      })
    }
  }, [syncResults])

  const filterList = [
    { field: 'hubId' },
    { field: 'integrationId' },
    { field: 'hubName' },
    { field: 'hubType' },
    { field: 'parentHubId', title: 'Deprovision From Hub Id' },
    { field: 'parentHubName', title: 'Deprovision From Hub Name' },
    { field: 'parentHubType', title: 'Deprovision From Hub Type' }
  ]

  if (!disableActions) {
    filterList.push({ field: 'exempt' })
  }

  return (
    <ExpansionPanel square className={classes.expansionPanel} elevation={0}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Account Deprovisioning</Typography>
        {(containsNonExemptDeprovisionData ||
          deprovisionState.filter(s => !s.exempt).length > 0) && (
          <div className={classes.notificationCountWrapper}>
            {deprovisionState.filter(s => !s.exempt).length > 0 ? (
              <div className={classes.notificationCount}>
                {deprovisionState.filter(s => !s.exempt).length}
              </div>
            ) : (
              <ErrorIcon className={classes.errorIcon} />
            )}
          </div>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={4}>
          {!isReadOnlySystemAdmin && !disableActions && (
            <>
              <Grid item xs="auto">
                <RoundedButton
                  disabled={
                    deprovisionLoading || deprovisionState.filter(d => !d.exempt).length === 0
                  }
                  fullWidth={false}
                  onClick={() => setConfirmDeprovisionOpen(true)}
                >
                  {deprovisionLoading ? <Progress /> : `Execute Deprovision`}
                </RoundedButton>
                <Dialog
                  open={confirmDeprovisionOpen}
                  onClose={() => setConfirmDeprovisionOpen(false)}
                >
                  <DialogTitle className={classes.dialogTitle}>
                    Please confirm deprovision
                    <IconButton
                      className={classes.dialogCloseButton}
                      onClick={() => setConfirmDeprovisionOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <Typography>
                      Are you sure you want to deprovision{' '}
                      {deprovisionState.filter(s => !s.exempt).length} record(s)?
                    </Typography>
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <RoundedButton
                      className={classes.button}
                      fullWidth={false}
                      variant="outlined"
                      onClick={() => setConfirmDeprovisionOpen(false)}
                    >
                      Cancel
                    </RoundedButton>
                    <RoundedButton
                      className={classes.button}
                      fullWidth={false}
                      onClick={() => {
                        deprovisionExecute({
                          values: {
                            exemptList: deprovisionState.reduce((previous, current) => {
                              if (current.exempt) {
                                const exemptRecord = {
                                  hubId: current.hubId,
                                  hubType: current.hubType,
                                  integrationId: current.integrationId
                                }

                                if (current.hubType === 'User' && current.deprovisionFrom) {
                                  exemptRecord.exemptIn = current.deprovisionFrom.hubId
                                }

                                previous.push(exemptRecord)
                              }

                              return previous
                            }, []),
                            syncResultsId: syncResults.id,
                            userId
                          }
                        })
                        setConfirmDeprovisionOpen(false)
                      }}
                    >
                      Confirm
                    </RoundedButton>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs="auto">
                <RoundedButton
                  disabled={
                    deprovisionLoading ||
                    saveExemptLoading ||
                    deprovisionState.length === 0 ||
                    equal(
                      deprovisionState.map(d => ({
                        exempt: d.exempt,
                        hubId: d.hubId,
                        integrationId: d.integrationId
                      })),
                      reducerInit(syncResults.results).map(d => ({
                        exempt: d.exempt,
                        hubId: d.hubId,
                        integrationId: d.integrationId
                      }))
                    )
                  }
                  fullWidth={false}
                  onClick={() =>
                    saveExemptExecute({
                      values: {
                        exemptList: deprovisionState.reduce((previous, current) => {
                          if (current.exempt) {
                            const exemptRecord = {
                              hubId: current.hubId,
                              hubType: current.hubType,
                              integrationId: current.integrationId
                            }

                            if (current.deprovisionFrom) {
                              exemptRecord.exemptIn = current.deprovisionFrom.hubId
                            }

                            previous.push(exemptRecord)
                          }

                          return previous
                        }, []),
                        syncResultsId: syncResults.id,
                        userId
                      }
                    })
                  }
                >
                  {deprovisionLoading ? <Progress /> : `Save Exempt Data`}
                </RoundedButton>
              </Grid>
            </>
          )}
          <Grid item xs>
            {containsNonExemptDeprovisionData && deprovisionState.length === 0 && (
              <Typography>
                Records to deprovision have been identified. Press "Load Results" to view data.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={columnDef({
                deprovisionDispatch,
                deprovisionState,
                filter: filterList,
                filterValues
              })}
              components={{
                Toolbar: props => {
                  props.columns.forEach(c => {
                    filterValues.current[c.field] = c.tableData.filterValue || null
                  })

                  return null
                }
              }}
              data={deprovisionState}
              localization={{
                body: {
                  emptyDataSourceMessage:
                    containsNonExemptDeprovisionData && deprovisionState.length === 0
                      ? "Press 'Load Results' to view data"
                      : 'No records to display'
                }
              }}
              options={{
                draggable: false,
                filterCellStyle: {
                  verticalAlign: 'bottom'
                },
                filtering: true,
                pageSize: 5,
                showTitle: false
              }}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const reducerInit = results =>
  results.reduce((data, current) => {
    if (current.status === 'Deprovision') {
      data.push({
        exempt: current.exemptFromDeprovision || false,
        hubId: current.hubId,
        hubName: current.hubName,
        hubType: current.hubType,
        integrationId: current.integrationId
      })
    } else {
      current.subData.forEach(s => {
        if (s.status === 'Deprovision') {
          data.push({
            exempt: s.exemptFromDeprovision || false,
            hubId: s.hubId,
            hubName: s.hubName,
            hubType: s.hubType,
            integrationId: s.integrationId,
            parent: {
              hubId: current.hubId,
              hubName: current.hubName,
              hubType: current.hubType
            }
          })
        }
      })
    }

    return data
  }, [])

const useStyles = makeStyles(theme => ({
  dialogActions: {
    padding: 25,
    paddingBottom: 15
  },
  dialogCloseButton: {
    color: 'white',
    position: 'absolute',
    right: 8,
    top: 8
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginBottom: 20
  },
  errorIcon: {
    color: theme.palette.dashboardNotification.main,
    fontSize: 25
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`
  },
  notificationCount: {
    alignItems: 'center',
    background: theme.palette.dashboardNotification.main,
    borderRadius: 6,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 25,
    padding: '2px 8px'
  },
  notificationCountWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: 'auto'
  }
}))

export default SyncIntegrationDeprovisionList
