import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { formatLongDateTime } from '../../../utils/dateTime'

import config from '../../../config'

export default function PendingSignatureSection({ sentDate, email }) {
  const classes = useStyles()

  return (
    <Grid container className={classes.section}>
      <Typography className={classes.message}>
        Notification sent on: {formatLongDateTime(sentDate)}
      </Typography>
      {email && <Typography className={classes.message}>To: {email}</Typography>}
      <img
        alt="DocuSign Logo"
        className={classes.docusignLogo}
        src={`${config.MEDIA_CDN_DOMAIN}/media/docusign_new.png`}
      />
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'block'
  },
  message: {
    fontSize: 14
  },
  docusignLogo: {
    marginTop: 15,
    marginBottom: 5,
    width: 90,
    height: 'auto'
  }
}))
