import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 20
  },
  footer: {
    padding: 20,
    backgroundColor: theme.palette.gray3.main
  },
  gridContainer: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center'
    }
  },
  bigAvatar: {
    margin: 10,
    width: 125,
    height: 125,
    borderRadius: 125,
    border: `1px solid ${theme.palette.borderGray.main}`,
    color: 'gray',
    backgroundColor: 'white'
  },
  cardTextContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconDone: {
    width: 50,
    height: 50,
    borderRadius: 70,
    border: `1px solid ${theme.palette.borderGray.main}`,
    marginRight: 12,
    overflow: 'hidden',
    color: 'white',
    background: theme.palette.primary.main
  },
  iconNotDone: {
    width: 50,
    height: 50,
    borderRadius: 70,
    border: `1px solid ${theme.palette.borderGray.main}`,
    marginRight: 12,
    overflow: 'hidden',
    color: theme.palette.primary.main,
    background: 'white'
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 80,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 60
    }
  },
  check: {
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.primary.main
  },
  noSign: {
    width: 20,
    height: 20,
    borderRadius: 10,
    color: theme.palette.gray3.main
  },
  noSignHover: {
    width: 20,
    height: 20,
    borderRadius: 10,
    color: 'red'
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 800
  },
  paperHover: {
    background: `rgba(197, 239, 247, 0.2)`
  },
  paperDisabled: {
    padding: theme.spacing(2),
    maxWidth: 800,
    opacity: 0.6
  },
  paperTextTop: {
    'margin-left': 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  },
  paperTextBot: {
    'margin-left': 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  },
  paperTextBotHover: {
    color: 'red'
  },
  paperButton: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: 0
    }
  },
  paperButtonContainer: {
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('xs')]: {
      right: -20
    }
  },
  paperButtonContainerNoHover: {},
  link: {
    'text-decoration': 'none'
  }
}))

export default useStyles
