import gql from 'graphql-tag'

import { GET_ORGANIZATION_PROJECT_GROUPS } from '../../ProjectGroups/queries'

const GET_ORG = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      name
      description
      website
      logo
      percentageComplete
      phoneNumber
      website
      emailAddress
      approvalStatus
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      organizationRoles {
        organizationAdmin {
          id
        }
        exchangeListingAdmin {
          id
        }
        primaryContact {
          id
        }
        billingContact {
          user {
            id
          }
          email
          firstname
          lastname
          jobTitle
        }
        authorizedSigners {
          user {
            id
          }
          email
          firstname
          lastname
          jobTitle
        }
      }
    }
  }
`

const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      firstname
      lastname
      jobTitle
      email
      logo
      shippingAddress {
        address1
        address2
        city
        state
        postalCode
        country
      }
      percentageComplete
      billingAlertConfirmed
    }
  }
`

const GET_ORGANIZATION_USER_PROJECT_GROUP_REQUESTS = gql`
  query($id: ID!) {
    userProjectGroupRequests(id: $id) {
      count
      parentProjectGroupId
      projectGroupId
      projectGroup {
        charterAgreement {
          id
          name
          fileName
        }
      }
      name
      restricted
      supportMustVerify
      userId
      subgroups {
        count
        name
        parentProjectGroupId
        projectGroupId
        projectGroup {
          charterAgreement {
            id
            name
            fileName
          }
        }
        restricted
        supportMustVerify
        userId
      }
    }
  }
`

const GET_RESTRICTED_SUB_GROUP_REQUESTS = gql`
  query($id: ID!) {
    getRestrictedSubGroupRequests(id: $id) {
      email
      firstname
      groupId
      groupName
      jobTitle
      lastname
      logo
      orgName
      requestId
      userId
    }
  }
`

const GET_SUPPORT_MUST_VERIFY_REQUESTS = gql`
  query {
    getSupportMustVerifyRequests {
      id
      projectGroupId
      organizationId
      name
      website
      logo
    }
  }
`

const GET_USER_PROJECT_GROUPS = gql`
  query($id: ID!) {
    findProjectGroupsByUserId(id: $id) {
      projectGroupId
      joinedDate
      approvalStatus
    }
  }
`

export {
  GET_USER,
  GET_ORG,
  GET_ORGANIZATION_PROJECT_GROUPS,
  GET_ORGANIZATION_USER_PROJECT_GROUP_REQUESTS,
  GET_RESTRICTED_SUB_GROUP_REQUESTS,
  GET_SUPPORT_MUST_VERIFY_REQUESTS,
  GET_USER_PROJECT_GROUPS
}
