import gql from 'graphql-tag'

export const REQUEST_GLOSSIES_EMAIL = gql`
  mutation($input: GlossyRequestInput!) {
    requestGlossiesEmail(input: $input) {
      result
    }
  }
`

export const REMOVE_DECLINED_USER_FROM_ORG = gql`
  mutation($input: OrganizationUserRemovalInput) {
    removeDeclinedUserFromOrganization(input: $input) {
      id
      userId
    }
  }
`

export const CONFIRM_G5_ALERT = gql`
  mutation($id: ID!) {
    confirmG5Alert(id: $id) {
      result
      data
    }
  }
`
