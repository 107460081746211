import gql from 'graphql-tag'

export const SEND_PROJECT_GROUP_AGREEMENT_REMINDER = gql`
  mutation($input: ProjectGroupReminderInput!) {
    sendProjectGroupReminder(input: $input) {
      result
    }
  }
`

export const GET_PROJECT_GROUP_AGREEMENTS = gql`
  query charterAgreements($id: ID!) {
    organization(id: $id) {
      id
      signedAgreements {
        id
        agreementType
        cancelled
        cancelledDate
        counterSignedBy
        counterSignedDate
        envelopeId
        name
        referenceId
        sentDate
        signedBy
        signedDate
        sentTo {
          firstname
          lastname
          email
        }
        storageUrl
      }
    }
  }
`

export const GET_ALL_PROJECT_GROUPS_FOR_AGREEMENTS = gql`
  query getProjectGroupNamesForAgreements {
    projectGroups(includeAll: true) {
      id
      name
      charterAgreement {
        id
        name
        fileName
      }
      agreement {
        id
        name
        fileName
      }
    }
  }
`
