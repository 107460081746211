import React from 'react'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import { getImageUrl } from '../utils'
import RoundedButton from './RoundedButton'

const styles = makeStyles(theme => ({
  preview: {
    width: '100%'
  },
  imageBox: {
    border: '1px solid gray',
    margin: 5,
    padding: 5
  }
}))

export default function InputImageField({
  name,
  preview,
  label,
  acceptTypes,
  handleChange,
  validator,
  value
}) {
  const classes = styles()

  return (
    <div className={classes.imageBox}>
      <div>{validator && validator.required ? `${label}*` : label}</div>
      <Grid container>
        <Grid item xs>
          <input
            accept={acceptTypes}
            className={classes.inputfile}
            id={label}
            type="file"
            onChange={({
              target: {
                validity,
                files: [file]
              }
            }) => {
              if (validity.valid && file) {
                handleChange({
                  file,
                  removed: value.removed
                })
              }
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={() => {
              handleChange({
                file: null,
                removed: value.removed
              })
              document.getElementById(label).value = ''
            }}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      {/* if has preview and is an image */}
      {preview && (
        <div>
          <hr />
          <Typography>Current image:</Typography>
          <img alt="product" className={classes.preview} src={getImageUrl(preview.thumbName)} />
          <RoundedButton
            onClick={() =>
              handleChange({
                file: value.file,
                removed: true
              })
            }
          >
            Remove Image
          </RoundedButton>
        </div>
      )}
    </div>
  )
}
