import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import WidthContainer from '../common/WidthContainer'

export default function Blocked() {
  const classes = styles()
  return (
    <WidthContainer classNames={classes.root}>
      <Typography role="heading" variant="h3" className={classes.heading}>
        TIP Participation is not active
      </Typography>
      <Typography paragraph className={classes.paragraph}>
        We are sorry, but according to our records your TIP Participation is not active. As a
        result, access to TIP resources such as Project Group materials, Workplace, and GitHub is
        not available.
      </Typography>
      <Typography paragraph className={classes.description}>
        If you have any questions, please contact TIP Participation Support at{' '}
        <a href="mailto:support@telecominfraproject.com">support@telecominfraproject.com</a>
      </Typography>
      <Typography paragraph className={classes.description}>
        - The TIP Participation Team
      </Typography>
    </WidthContainer>
  )
}

const styles = makeStyles(theme => ({
  root: {},
  heading: {
    marginTop: 48,
    marginBottom: 18
  },
  paragraph: {}
}))
