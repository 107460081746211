import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'

export default function ConfirmCancelPendingDialog({
  leaveGroupLoading,
  onConfirm,
  projectGroupName,
  type,
  isOrgAdmin = false,
  orgId,
  id
}) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }
  function handleConfirm() {
    onConfirm(id, true)
  }

  return (
    <div className={classes.leaveButton}>
      <RoundedButton
        className={classes.selectionButton}
        color="primary"
        variant="outlined"
        size="small"
        fullWidth={false}
        onClick={handleClickOpen}
      >
        Cancel
      </RoundedButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.header} id="responsive-dialog-title">
          Are you sure?
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentText}>
            <WarningIcon className={classes.warningIcon} />
            <div>
              <Typography className={classes.bold}>Please confirm this action</Typography>
              <Typography>
                Your participation in this group is pending
                {type === 'Project Group'
                  ? ' activation of the group by your Account Administrator. '
                  : ' approval by your Account Administrator. '}
                Do you wish to cancel your request?
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClose}
            variant="outlined"
            className={isOrgAdmin ? classes.buttonOrgAdminAlt : classes.buttonAlt}
            classes={{ outlined: classes.outlined }}
          >
            No
          </Button>
          <Button
            fullWidth={false}
            onClick={handleConfirm}
            className={isOrgAdmin ? classes.buttonOrgAdmin : classes.button}
            classes={{ root: classes.root }}
          >
            {leaveGroupLoading ? (
              <Progress classes={{ colorPrimary: classes.progress }} size={30} delay={0} />
            ) : (
              'Yes, cancel request'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: 20,
    marginRight: 20,
    paddingLeft: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 30
    }
  },
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.red.main
    }
  },
  buttonAlt: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  buttonOrgAdmin: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.red.main
    }
  },
  buttonOrgAdminAlt: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10,
    height: 40
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  header: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  leaveButton: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    }
  },
  outlined: {
    color: theme.palette.red.main,
    border: `thin solid ${theme.palette.red.main}`
  },
  progress: {
    color: 'white'
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.red.main
  },
  selectionButton: {
    width: 200
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  }
}))
