import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useTheme } from '@material-ui/core/styles'

import { RoundedButton, Progress } from './index'

export default function TIPDialog({
  children,
  title,
  bodyText,
  open,
  rootClass,
  contentClass,
  paperClass,
  handleClose, // aka cancel
  showClose,
  closeLabel = 'Close',
  handleConfirm,
  showConfirm,
  confirmLabel = 'Ok',
  loadingConfirm,
  dialogActions
}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth={'md'}
      onClose={handleClose}
      classes={{ root: rootClass ? rootClass : classes.root, paper: paperClass }}
    >
      <DialogTitle className={classes.titleRoot}>
        {title && title}
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={contentClass || classes.contentRoot}>
        {bodyText ? (
          <Grid container justify="center">
            <Grid item xs={12}>
              <Typography paragraph>{bodyText}</Typography>
            </Grid>
          </Grid>
        ) : (
          children
        )}
      </DialogContent>
      {dialogActions || showClose || showConfirm ? (
        <DialogActions className={classes.actionsRoot}>
          {showClose ? (
            <RoundedButton
              onClick={handleClose}
              variant="outlined"
              classes={{ outlined: classes.outlined }}
            >
              {closeLabel}
            </RoundedButton>
          ) : null}
          {showConfirm ? (
            <RoundedButton
              color="primary"
              disabled={loadingConfirm}
              variant="contained"
              onClick={handleConfirm}
            >
              {loadingConfirm ? <Progress delay={0} size={25} /> : confirmLabel}
            </RoundedButton>
          ) : null}
          {dialogActions}
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    color: `red !important`
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  titleRoot: {
    marginBottom: 15
  },
  contentRoot: {
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  actionsRoot: {
    marginBottom: 20,
    paddingRight: 30,
    paddingLeft: 30
  },
  outlined: {
    color: theme.palette.primary.main
  }
}))
