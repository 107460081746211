import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import { useFormStyles, useQuery } from '../hooks'
import { VERIFY_CHECKOUT_SESSION } from './queries'
import { Progress } from '../common'
import CheckoutForm from './CheckoutForm'
import Success from './Success'

const Main = ({ location }) => {
  const formClasses = useFormStyles()
  const classes = useStyles()

  const stripeSessionId = new URLSearchParams(location.search).get('session_id')

  const { loading: verifyCheckoutSessionLoading } = useQuery(VERIFY_CHECKOUT_SESSION, {
    onCompleted: ({ verifyCheckoutSession: { result } = {} } = {}) => {
      if (result) {
        // Checkout session verified as paid
        setPaymentComplete(true)
      }
    },
    onError: e => {
      // Error thrown in resolver
      console.error(e)
    },
    skip: !stripeSessionId,
    variables: {
      input: {
        stripeSessionId
      }
    }
  })

  /*
    Show success page

    Stripe checkout session redirects back here with 
    the session_id querystring param set 

    Alternatively, CheckoutForm can manually trigger 
    the success page if the invoice returns as already paid
  */
  const [paymentComplete, setPaymentComplete] = useState(false)

  return (
    <Grid container justify="center">
      <Grid item className={clsx(formClasses.container, classes.container)} xs={12} md={7} lg={6}>
        {verifyCheckoutSessionLoading ? (
          <Progress />
        ) : paymentComplete ? (
          <Success />
        ) : (
          <CheckoutForm setPaymentComplete={setPaymentComplete} />
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '75px 150px'
  }
}))

export default Main
