import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'

import { DropdownElevationContext } from './CheckboxDropdown'

const autocompleteService = { current: null }
const placesService = { current: null }

const PlacesAutocomplete = ({
  autoComplete,
  currentCity = '',
  currentCountry = '',
  currentPostalCode = '',
  currentState = '',
  error,
  errorHelperText,
  helperText,
  hideLabel = false,
  includePhone = false,
  InputProps,
  inputType,
  inputRef,
  label,
  margin = 'normal',
  name,
  onBlur = () => null,
  onChange = () => null,
  onInputChange = () => null,
  placeholder,
  setField,
  setFieldError,
  size = 'medium',
  validator,
  value,
  ...rest
}) => {
  const elevation = useContext(DropdownElevationContext)
  const classes = useStyles({ zIndex: elevation.zIndex })
  const autoRef = useRef(null)

  const [options, setOptions] = useState([])
  const [showError, setShowError] = useState(value ? true : false)

  const fetchPlaces = useMemo(
    () =>
      throttle((input, callback) => {
        if (autocompleteService && autocompleteService.current) {
          try {
            autocompleteService.current.getPlacePredictions(input, callback)
          } catch (e) {
            console.error(e)
          }
        }
      }, 200),
    []
  )

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }

    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        (inputRef || autoRef).current
      )
    }

    const trimmed = value.trim().replace(/\s\s/g, ' ')

    if ((!autocompleteService.current || trimmed === '') && options.length > 0) {
      setOptions([])
    } else if (trimmed !== '') {
      fetchPlaces({ input: trimmed, types: ['address'] }, result => {
        if (result) {
          let arraysEqual = result.length === options.length

          if (arraysEqual) {
            result.forEach(value => {
              if (arraysEqual && !options.find(o => o.description === value.description)) {
                arraysEqual = false
              }
            })
          }

          if (!arraysEqual) {
            setOptions(result)
          }
        } else if (options.length > 0) {
          setOptions([])
        }
      })
    }
  }, [autoRef, fetchPlaces, inputRef, options, value])

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      freeSolo
      includeInputInList
      classes={{
        popper: classes.popper
      }}
      id="google-map-search"
      inputValue={value}
      getOptionLabel={option => option.description || option}
      options={options}
      value={value}
      renderInput={({ InputProps: TextFieldInputProps, ...props }) => (
        <TextField
          {...props}
          fullWidth
          error={showError && error}
          helperText={showError && error ? errorHelperText : helperText}
          InputProps={{
            ...TextFieldInputProps,
            ...InputProps
          }}
          inputRef={inputRef || autoRef}
          label={!hideLabel && label}
          margin={margin}
          placeholder={value.length === 0 ? label : ''}
          size={size}
          variant="outlined"
        />
      )}
      renderOption={option => (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            {parse(
              option.structured_formatting.main_text,
              option.structured_formatting.main_text_matched_substrings.map(match => [
                match.offset,
                match.offset + match.length
              ])
            ).map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
            <Typography color="textSecondary" variant="body2">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      )}
      onBlur={(...props) => {
        const trimmed = props[0].target.value.trim().replace(/\s\s+/g, ' ')

        if (trimmed !== props[0].target.value) {
          props[0].target.value = trimmed

          setField(name, trimmed)
          onChange(...props)
        }
        if (value && !showError) {
          setShowError(true)
        }

        onBlur(...props)
      }}
      onChange={(e, newValue, ...props) => {
        if (newValue.place_id) {
          placesService.current.getDetails(
            {
              placeId: newValue.place_id
            },
            place => {
              setField(
                name,
                place.address_components
                  .reduce(
                    (total, currentValue) =>
                      total +
                      (currentValue.types.includes('street_number') ||
                      currentValue.types.includes('route')
                        ? ' ' + currentValue.long_name
                        : ''),
                    ''
                  )
                  .trim()
              )

              const city =
                (
                  place.address_components.find(
                    a => a.types.includes('locality') || a.types.includes('postal_town')
                  ) || {}
                ).long_name || ''
              const state =
                (
                  place.address_components.find(a =>
                    a.types.includes('administrative_area_level_1')
                  ) || {}
                ).short_name || ''
              const country =
                (place.address_components.find(a => a.types.includes('country')) || {})
                  .short_name || ''
              const postalCode =
                (place.address_components.find(a => a.types.includes('postal_code')) || {})
                  .long_name || ''

              if (city !== currentCity) {
                setField('city', city)

                if (!city) {
                  setFieldError('city', 'City is required')
                }
              }

              if (state !== currentState) {
                setField('state', state)

                if (!state) {
                  setFieldError('state', 'State is required')
                }
              }

              if (country !== currentCountry) {
                setField('country', country)

                if (includePhone) {
                  setField('phoneNumber', '')
                  setFieldError('phoneNumber', 'Phone number is required')
                }

                if (!country) {
                  setFieldError('country', 'Country is required')
                }
              }

              if (postalCode !== currentPostalCode) {
                setField('postalCode', postalCode)

                if (!postalCode) {
                  setFieldError('postalCode', 'PostalCode is required')
                }
              }
            }
          )
        } else {
          setField(name, '')
          setFieldError(name, 'Address 1 is required')
        }

        onChange(e, newValue, ...props)
      }}
      onInputChange={(e, newValue, ...props) => {
        if (!options.find(o => o.description === newValue)) {
          setField(name, newValue)

          if (!newValue) {
            setFieldError(name, 'Address 1 is required')
          }

          if (newValue && newValue.replace(/\s/g, '').length === 0) {
            setFieldError(name, 'Headquarters address cannot contain only whitespace')
          }
        }

        onInputChange(e, newValue, ...props)
      }}
      {...rest}
    />
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  popper: ({ zIndex }) => ({
    zIndex
  })
}))

export default PlacesAutocomplete
