import React, { createContext, forwardRef, useContext, useEffect, useRef, useState } from 'react'
import {
  Checkbox,
  Chip,
  Grid,
  List,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'

const CheckboxDropdown = ({
  disableMinHeight,
  error,
  errorHelperText,
  helperText,
  hideLabel,
  InputProps,
  inputType,
  label,
  margin = 'normal',
  name,
  onBlur = () => null,
  onChange = () => null,
  onClose = () => null,
  onFocus = () => null,
  onOpen = () => null,
  options,
  setField,
  setFieldError,
  size = 'medium',
  validator,
  validateOnBlur,
  value,
  ...rest
}) => {
  const elevation = useContext(DropdownElevationContext)
  const classes = useStyles({ size, zIndex: elevation.zIndex })

  const showOther = options.includes('Other')

  const autocompleteInputRef = useRef(null)
  const otherInputRef = useRef(null)
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [otherChecked, setOtherChecked] = useState(
    showOther && value.filter(v => !options.includes(v)).length > 0
  )
  const [otherFocused, setOtherFocused] = useState(false)
  const [otherValue, setOtherValue] = useState(
    (showOther && value.filter(v => !options.includes(v)).find(v => v)) || ''
  )
  const [showError, setShowError] = useState(value ? true : false)

  useEffect(() => {
    if (otherFocused) {
      const deepParentNode = otherInputRef.current.parentNode.parentNode.parentNode

      deepParentNode.scrollTop = deepParentNode.scrollHeight - deepParentNode.offsetHeight
    }
  }, [otherFocused])

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      classes={{
        inputRoot: classes.inputRoot,
        popper: classes.popper
      }}
      getOptionSelected={(option, value) =>
        option === 'Other' && !options.includes(value) ? otherChecked : option === value
      }
      ListboxComponent={showOther ? ListboxComponent : undefined}
      ListboxProps={
        showOther
          ? {
              autocompleteInputRef,
              label,
              name,
              options,
              otherChecked,
              otherInputRef,
              otherValue,
              setAutocompleteOpen,
              setField,
              setFieldError,
              setOtherChecked,
              setOtherFocused,
              setOtherValue,
              showOther,
              value
            }
          : undefined
      }
      open={autocompleteOpen}
      options={options}
      PaperComponent={PaperComponent}
      renderInput={({
        inputProps,
        InputProps: TextFieldInputProps,
        onFocus: TextFieldOnFocus = () => null,
        ...props
      }) => (
        <TextField
          {...props}
          fullWidth
          className={classes.textField}
          error={showError && error}
          helperText={showError && error ? errorHelperText : helperText}
          InputProps={{
            ...TextFieldInputProps,
            ...InputProps,
            inputProps: {
              ...inputProps,
              className: classes.textFieldInput,
              readOnly: true
            }
          }}
          inputRef={autocompleteInputRef}
          label={!hideLabel && label}
          margin={margin || 'normal'}
          placeholder={value.length === 0 ? label : ''}
          size={size}
          variant="outlined"
          onFocus={(...props) => {
            setAutocompleteOpen(true)

            TextFieldOnFocus(...props)
          }}
        />
      )}
      renderOption={(option, { selected }) => (
        <Tooltip arrow title={option}>
          <Typography noWrap className={classes.checkboxContainer}>
            <Checkbox checked={selected} />
            {option}
          </Typography>
        </Tooltip>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip arrow key={index} title={option}>
            <Chip
              {...getTagProps({ index })}
              className={classes.chip}
              deleteIcon={<ClearIcon />}
              label={option}
              size="small"
              onDelete={
                option === otherValue
                  ? () => {
                      setOtherChecked(false)
                      setOtherValue('')

                      value.splice(value.indexOf(otherValue), 1)

                      setField(name, value.sort())

                      if (value.length === 0) {
                        setFieldError(name, `${label} is required`)
                      }
                    }
                  : getTagProps({ index }).onDelete
              }
            />
          </Tooltip>
        ))
      }
      value={value.sort()}
      onBlur={(...props) => {
        if (value.length > 0 && !showError) {
          setShowError(true)
        }

        onBlur(...props)
      }}
      onChange={(e, newValue, reason, ...props) => {
        if (reason === 'clear') {
          setOtherChecked(false)
          setOtherValue('')
        }

        setField(name, newValue.sort())

        if (validator && validator.required && newValue.length === 0) {
          setFieldError(name, `${label} is required`)
        }

        autocompleteInputRef.current.focus()

        onChange(e, newValue, reason, ...props)
      }}
      onClose={(e, reason, ...props) => {
        if (!showOther || reason !== 'blur' || (reason === 'blur' && !otherFocused)) {
          setAutocompleteOpen(false)
        }

        onClose(e, reason, ...props)
      }}
      onFocus={(...props) => {
        if (value.length > 0 && !showError) {
          setShowError(true)
        }

        onFocus(...props)
      }}
      onOpen={(...props) => {
        setAutocompleteOpen(true)

        onOpen(...props)
      }}
      {...rest}
    />
  )
}

const ListboxComponent = forwardRef(
  (
    {
      autocompleteInputRef,
      children,
      label,
      name,
      options,
      otherChecked,
      otherInputRef,
      otherValue,
      setAutocompleteOpen,
      setField,
      setFieldError,
      setOtherChecked,
      setOtherFocused,
      setOtherValue,
      showOther,
      value,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()

    children.splice(-1, 1)

    return (
      <List
        {...rest}
        ref={ref}
        onMouseDown={e => {
          if (e.target.type === 'text') {
            setOtherFocused(true)
          } else {
            e.preventDefault()
          }
        }}
      >
        {children}
        <Grid container className={classes.checkboxContainerOther}>
          <Grid item xs="auto">
            <Checkbox
              checked={otherChecked}
              disabled={!otherValue || otherValue.replace(/\s/g, '').length === 0}
              onChange={e => {
                const newValue = value.filter(v => options.includes(v))
                const checked = e.target.checked

                if (checked) {
                  newValue.push(otherValue.trim().replace(/\s+/g, ' '))
                }

                setField(name, newValue.sort())
                setOtherChecked(checked)

                if (newValue.length === 0) {
                  setFieldError(name, `${label} is required`)
                }

                autocompleteInputRef.current.focus()
              }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              margin="none"
              placeholder="Other (please specify)"
              ref={otherInputRef}
              size="small"
              value={otherValue}
              variant="outlined"
              onBlur={e => {
                const newOtherValue = otherValue.trim().replace(/\s+/g, ' ')

                if (otherChecked && otherValue && !value.includes(otherValue)) {
                  const newArrayValue = options.filter(o => value.includes(o))

                  newArrayValue.push(newOtherValue)

                  setField(name, newArrayValue.sort())
                }

                setOtherValue(newOtherValue)
                setOtherFocused(false)

                if (e.relatedTarget !== autocompleteInputRef.current) {
                  setAutocompleteOpen(false)
                }
              }}
              onChange={e => {
                const newValue = e.target.value

                setOtherValue(newValue)

                if (!newValue || newValue.replace(/\s/g, '').length === 0) {
                  setOtherChecked(false)
                  setField(name, options.filter(o => value.includes(o)).sort())
                }
              }}
            />
          </Grid>
        </Grid>
      </List>
    )
  }
)

const PaperComponent = forwardRef(({ children, className, ...rest }, ref) => {
  const classes = useStyles()

  return (
    <Paper {...rest} className={clsx(className, classes.paper)} ref={ref}>
      <Typography className={classes.listSubheader} variant="body2">
        Please select all that apply
      </Typography>
      {children}
    </Paper>
  )
})

export const dropdownElevation = {
  normal: {
    zIndex: 1101
  },
  raised: {
    zIndex: 1301
  }
}

export const DropdownElevationContext = createContext(dropdownElevation.normal)

const useStyles = makeStyles(theme => ({
  checkboxContainer: {
    padding: `0 ${theme.spacing(2)}px`
  },
  checkboxContainerOther: {
    padding: `${theme.spacing(1)}px`,
    paddingLeft: theme.spacing(4)
  },
  chip: {
    borderRadius: 2,
    margin: 2,
    maxWidth: '100%',
    zIndex: 1
  },
  inputRoot: ({ size }) => ({
    minHeight: size === 'small' ? 40 : 56
  }),
  popper: ({ zIndex }) => ({
    zIndex
  }),
  listSubheader: {
    backgroundColor: theme.palette.gray.main,
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  paper: {
    marginBottom: theme.spacing(2)
  },
  textFieldInput: {
    position: 'absolute',
    zIndex: 0
  }
}))

export default CheckboxDropdown
