import React, { useState } from 'react'
import { Grid, InputAdornment, lighten, makeStyles, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useFormState } from 'react-use-form-state'
import { useSnackbar } from 'notistack'

import { useQuery, useMutation } from '../hooks'
import { SuccessModal, ErrorSection, Progress, InputTextFieldNew } from '../common'
import ProjectGroupAddedModal from '../ProjectGroups/ProjectGroupAddedModal'
import RequestPendingModal from '../ProjectGroups/RequestPendingModal'
import config from '../config'
import {
  ProjectGroupList,
  GET_VISIBLE_PARENT_PROJECT_GROUPS,
  GET_ORGANIZATION_PROJECT_GROUPS,
  GET_USER_PROJECT_GROUPS,
  ADD_USER_TO_PROJECT_GROUP,
  REMOVE_USER_FROM_PROJECT_GROUP
} from '../ProjectGroups'
import { useSession } from '../auth/queries'
import { GET_USER, GET_ORG } from '../User/Profile/queries'
import { GET_PROJECT_GROUP_AGREEMENTS } from '../Organization/Agreements/ProjectGroupAgreements/queries'

export default function ProjectGroups({ match }) {
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [successModalData, setSuccessModalData] = useState({})
  const [successModalType, setSuccessModalType] = useState('')

  const orgId = match.params.orgId
  const userId = match.params.id

  const { userId: trueUserId, isImpersonating, isSystemAdmin } = useSession()

  const canModifyPgs = (!isImpersonating && trueUserId === userId) || isSystemAdmin

  const { enqueueSnackbar } = useSnackbar()

  const orgParameters = { variables: { id: orgId } }
  const {
    data: { organization },
    loading: organizationQueryLoading
  } = useQuery(GET_ORG, orgParameters)

  const {
    loading: organizationAgreementsLoading,
    data: { organization: { signedAgreements: organizationAgreements = [] } = {} }
  } = useQuery(GET_PROJECT_GROUP_AGREEMENTS, {
    variables: { id: orgId },
    fetchPolicy: 'network-only'
  })

  const {
    loading: projectGroupsLoading,
    data: { visibleProjectGroups: projectGroups = [] } = {}
  } = useQuery(GET_VISIBLE_PARENT_PROJECT_GROUPS, { variables: { id: orgId } })
  const {
    loading: organizationProjectGroupsLoading,
    data: { findProjectGroupsByOrganizationId: organizationProjectGroups = [] } = {}
  } = useQuery(GET_ORGANIZATION_PROJECT_GROUPS, { variables: { id: orgId } })
  const {
    loading: userProjectGroupsLoading,
    data: { findProjectGroupsByUserId: userProjectGroups = [] } = {}
  } = useQuery(GET_USER_PROJECT_GROUPS, { variables: { id: userId } })

  const classes = useStyles()
  const [formState, { text }] = useFormState({ search: '' })
  const [openProjectGroupAddedModal, setOpenProjectGroupAddedModal] = useState(false)
  const [openRequestPendingModal, setOpenRequestPendingModal] = useState(false)
  const [requestPendingStatus, setRequestPendingStatus] = useState('')
  const [requestPendingSupportMustVerify, setRequestPendingSupportMustVerify] = useState(false)
  const [requestPendingRestricted, setRequestPendingRestricted] = useState(false)
  const [projectGroupsAdded, setProjectGroupsAdded] = useState([])
  const [workplaceLink, setWorkPlaceLink] = useState('')
  const [addedType, setAddedType] = useState('')

  const orgProjectGroupArray = organizationProjectGroups.map(orgPg => orgPg.projectGroupId)
  const userProjectGroupArray = userProjectGroups.reduce((acc, pg) => {
    acc[pg.projectGroupId] = {
      joined: true,
      joinedDate: pg.joinedDate,
      approvalStatus: pg.approvalStatus
    }

    return acc
  }, {})

  const projectGroupList = projectGroups.map(pg => {
    const orgPG = organizationProjectGroups.find(o => o.projectGroupId === pg.id)
    let results = {
      ...pg,
      isAvailable: orgProjectGroupArray.includes(pg.id) && (orgPG && !orgPG.pending) // only availble to join if the org has approved the group and is NOT pending
    }

    if (userProjectGroupArray[pg.id]) {
      if (
        userProjectGroupArray[pg.id].approvalStatus === 'requestParticipation' ||
        userProjectGroupArray[pg.id].approvalStatus === 'requestAccess'
      ) {
        results.joinedPg = false
        results.joinedDate = null
      } else {
        results.joinedPg = userProjectGroupArray[pg.id].joined
        results.joinedDate = userProjectGroupArray[pg.id].joinedDate
      }
      results.approvalStatus = userProjectGroupArray[pg.id].approvalStatus
    } else {
      results.joinedPg = false
      results.joinedDate = null
    }

    if (results.subProjectGroups.length > 0) {
      results.subProjectGroups = results.subProjectGroups.map(pg => {
        let results = pg

        if (userProjectGroupArray[pg.id]) {
          if (
            userProjectGroupArray[pg.id].approvalStatus === 'requestParticipation' ||
            userProjectGroupArray[pg.id].approvalStatus === 'requestParticipationOran' ||
            userProjectGroupArray[pg.id].approvalStatus === 'requestAccess'
          ) {
            results.joinedPg = false
            results.joinedDate = null
          } else {
            results.joinedPg = userProjectGroupArray[pg.id].joined
            results.joinedDate = userProjectGroupArray[pg.id].joinedDate
          }
          results.approvalStatus = userProjectGroupArray[pg.id].approvalStatus
        } else {
          results.joinedPg = false
          results.joinedDate = null
        }

        if (orgProjectGroupArray.includes(pg.id)) {
          let orgPG = organizationProjectGroups.find(
            projectGroup => projectGroup.projectGroupId === pg.id
          )

          if (!orgPG.pending) {
            results.isAvailable = true
          }

          if (orgPG.pending && orgPG.approvalStatus === 'requestSupportVerify') {
            results.isAvailable = false
          }
        } else {
          results.isAvailable = false
        }

        return results
      })
    }

    return results
  })

  const {
    execute: executeAddProjectGroups,
    loading: joinLoading,
    error: addProjectGroupError
  } = useMutation(ADD_USER_TO_PROJECT_GROUP, {
    update: (store, { data: { addUserToProjectGroup: { result, data } = {} } }) => {
      if (result) {
        const query = {
          query: GET_USER_PROJECT_GROUPS,
          variables: { id: userId }
        }

        const storeData = store.readQuery(query)

        storeData.findProjectGroupsByUserId = storeData.findProjectGroupsByUserId.concat(
          data.map(projectGroupId => ({ projectGroupId: projectGroupId, joinedDate: Date.now() }))
        )

        let pgNames = data.map((pgId, i) => {
          let pg = projectGroupList.find(pgl => pgl.id === pgId)

          if (pg) {
            setWorkPlaceLink(`${config.WORKPLACE_URL}/groups/${pg.workplaceId}`)
            if (i === 0) {
              setAddedType('Project Group')
            }

            return pg.name
          } else {
            let pgName
            projectGroupList.forEach(pg => {
              if (pg.subProjectGroups.length > 0) {
                pg = pg.subProjectGroups.find(pgs => pgs.id === pgId)

                if (pg) {
                  pgName = pg.name
                  if (i === 0) {
                    setAddedType('subgroup')
                  }
                }
              }
            })

            return pgName
          }
        })

        // show a modal of the project groups names that were just added/joined
        setProjectGroupsAdded(pgNames)
        setOpenProjectGroupAddedModal(true)
      }
    },
    refetchQueries: [
      {
        onCompleted: true,
        query: GET_USER,
        variables: { id: userId },
        options: { fetchPolicy: 'network-only' }
      },
      {
        onCompleted: true,
        query: GET_USER_PROJECT_GROUPS,
        variables: { id: userId },
        options: { fetchPolicy: 'network-only' }
      }
    ]
  })

  const {
    execute: executeLeaveProjectGroup,
    error: leaveProjectGroupError,
    loading: leaveGroupLoading
  } = useMutation(REMOVE_USER_FROM_PROJECT_GROUP, {
    update: (store, { data: { removeUserFromProjectGroup: { result, data } = {} } }) => {
      if (result) {
        const query = {
          query: GET_USER_PROJECT_GROUPS,
          variables: { id: userId }
        }

        const storeData = store.readQuery(query)
        let foundPg = projectGroupList.find(pgl => pgl.id === data)

        // if leaving parent, clear subgroups as well
        if (foundPg && foundPg.subProjectGroups && foundPg.subProjectGroups.length > 0) {
          const ids = foundPg.subProjectGroups.map(s => s.id)
          ids.push(data)

          for (let i = 0; i < ids.length; i++) {
            const index = storeData.findProjectGroupsByUserId
              .map(function(x) {
                return x.projectGroupId
              })
              .indexOf(ids[i])
            storeData.findProjectGroupsByUserId.splice(index, 1)
          }
        } else {
          const index = storeData.findProjectGroupsByUserId
            .map(function(x) {
              return x.projectGroupId
            })
            .indexOf(data)
          storeData.findProjectGroupsByUserId.splice(index, 1)
        }
      }
    },
    onCompleted: result => {
      try {
        const pgId = result.removeUserFromProjectGroup.data

        let foundPg = projectGroupList.find(pgl => pgl.id === pgId)
        let type = 'Project Group'

        if (!foundPg) {
          projectGroupList.forEach(pg => {
            if (pg.subProjectGroups.length > 0) {
              const subPg = pg.subProjectGroups.find(pgs => pgs.id === pgId)

              if (subPg) {
                foundPg = subPg
                type = 'Sub Group'
                subPg.approvalStatus = null
              }
            }
          })
        } else {
          // if leaving parent, sub project groups move to not approved
          foundPg.subProjectGroups.forEach(s => {
            s.approvalStatus = null
          })
        }

        if (successModalType === 'request') {
          setSuccessModalData({
            title: 'Cancellation',
            subtitleText: `Your request has been cancelled`,
            body: (
              <Typography>
                The group participation request you sent previously has been cancelled.
              </Typography>
            )
          })
        } else {
          setSuccessModalData({
            title: 'Success',
            subtitleText: `You have left a ${type}`,
            body: (
              <Typography>
                You have left the{' '}
                <span className={classes.bold}>
                  {foundPg.name} {type}
                </span>
                . You no longer have access to related activities, tools, and resources.
              </Typography>
            )
          })
        }

        setSuccessModalOpen(true)
        setSuccessModalType('')
      } catch (e) {}
    }
  })

  if (
    projectGroupsLoading ||
    userProjectGroupsLoading ||
    organizationProjectGroupsLoading ||
    organizationAgreementsLoading ||
    organizationQueryLoading
  ) {
    return <Progress />
  }

  let errorMessage

  if (addProjectGroupError) {
    errorMessage = 'Error joining Project Groups'
  }

  if (leaveProjectGroupError) {
    errorMessage = 'Error leaving Project Groups'
  }

  const orgAdminId = organization ? organization.organizationRoles.organizationAdmin.id : null

  return (
    <>
      <SuccessModal
        open={successModalOpen}
        onClose={() => {
          setSuccessModalOpen(false)
        }}
        {...successModalData}
      />
      {openProjectGroupAddedModal && (
        <ProjectGroupAddedModal
          onClose={() => setOpenProjectGroupAddedModal(false)}
          subTitleText="You have joined the group"
          bodyText={
            projectGroupsAdded[0] ? (
              <>
                Congratulations, you have joined the{' '}
                <Typography className={classes.strong} component={'span'}>
                  {projectGroupsAdded[0]}
                </Typography>{' '}
                {addedType}{' '}
                {projectGroupsAdded.length > 1 ? (
                  <>
                    and it's parent group, the{' '}
                    <Typography className={classes.strong} component={'span'}>
                      {projectGroupsAdded[1]}
                    </Typography>{' '}
                    Project Group
                  </>
                ) : null}
                . You now have access to related activities, events, and resources. Please check
                your inbox for a welcome email with more information.
              </>
            ) : (
              ''
            )
          }
          okURL={workplaceLink}
          okLabel="Visit This Group On Workplace"
        />
      )}
      {openRequestPendingModal && (
        <RequestPendingModal
          isOpen={openRequestPendingModal}
          onClose={() => setOpenRequestPendingModal(false)}
          restricted={requestPendingRestricted}
          setRequestPendingRestricted={setRequestPendingRestricted}
          setRequestPendingSupportMustVerify={setRequestPendingSupportMustVerify}
          supportMustVerify={requestPendingSupportMustVerify}
          type={requestPendingStatus}
        />
      )}
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12}>
          <Typography variant="h4">My project groups</Typography>
          <Typography paragraph>
            Search or select project groups below to join. Project group participation is required
            to join Sub-Groups.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.searchBar}>
          <InputTextFieldNew
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            inputType={text}
            label="Search"
            name="search"
            value={formState.values.search}
            variant="outlined"
          />
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <ErrorSection>{errorMessage}</ErrorSection>
          </Grid>
        )}
        <Grid item xs={12} className={classes.availableProjectGroupList}>
          <ProjectGroupList
            isOrgAdmin={false}
            projectGroups={projectGroupList}
            filteredProjectGroups={projectGroupList
              .filter(
                pg =>
                  pg.name.toLowerCase().includes(formState.values.search.toLowerCase()) ||
                  pg.subProjectGroups.find(spg =>
                    spg.name.toLowerCase().includes(
                      formState.values.search
                        .toLowerCase()
                        .trim()
                        .replace(/\s\s+/g, ' ')
                    )
                  )
              )
              .sort((a, b) => {
                if (a.softwareProjectGroup === b.softwareProjectGroup) {
                  return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                }

                if (a.softwareProjectGroup) {
                  return -1
                }

                return 1
              })}
            handleJoinProjectGroups={(id, parentProjectGroupId) =>
              executeAddProjectGroups({
                values: {
                  userId: userId,
                  projectGroupIds: [id],
                  parentProjectGroupId: parentProjectGroupId
                }
              })
            }
            handleLeaveProjectGroup={(id, request = false) => {
              if (request) {
                setSuccessModalType('request')
              } else {
                setSuccessModalType('leave')
              }
              executeLeaveProjectGroup({
                values: {
                  userId: userId,
                  projectGroupId: id
                }
              })
            }}
            joinLoading={joinLoading}
            leaveGroupLoading={leaveGroupLoading}
            organizationAgreements={organizationAgreements}
            orgAdminId={orgAdminId}
            orgId={orgId}
            onParticipationRequestSuccess={(type, supportMustVerify, restricted) => {
              setRequestPendingStatus(type)
              setRequestPendingSupportMustVerify(supportMustVerify)
              setRequestPendingRestricted(restricted)
              setOpenRequestPendingModal(true)
              enqueueSnackbar('Request notification has been sent', {
                variant: 'success',
                autoHideDuration: 2500
              })
            }}
            isReadOnly={!canModifyPgs}
          />
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  joinedProjectGroupList: {
    padding: '20px 10px',
    border: `1px solid ${theme.palette.borderGray.main}`,
    marginBottom: 30,
    backgroundColor: lighten(theme.palette.gray3.main, 0.6)
  },
  availableProjectGroupList: {
    backgroundColor: theme.palette.gray10.main,
    border: `1px solid ${theme.palette.borderGray.main}`,
    padding: '20px 10px'
  },
  table: {
    minWidth: 750,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      tableLayout: 'fixed'
    }
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableCell: {
    border: 'none',
    padding: '10px 0px',
    paddingRight: '0 !important'
  },
  searchBar: {
    marginBottom: 50
  },
  pageContainer: {
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      padding: 5
    }
  },
  title: { fontSize: '1.2rem' },
  collapsible: { cursor: 'pointer', fontSize: '.9rem' },
  strong: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  outlined: {
    color: theme.palette.primary.main
  },
  bold: {
    fontWeight: 600
  }
}))
