import gql from 'graphql-tag'

const APPROVE_RESTRICTED_SUB_GROUP_REQUEST = gql`
  mutation($id: ID!, $input: RestrictedSubGroupInput!) {
    approveRestrictedSubGroupRequest(id: $id, input: $input) {
      data
      result
    }
  }
`

const DENY_RESTRICTED_SUB_GROUP_REQUEST = gql`
  mutation($id: ID!, $input: RestrictedSubGroupInput!) {
    denyRestrictedSubGroupRequest(id: $id, input: $input) {
      data
      result
    }
  }
`

const APPROVE_SUPPORT_MUST_VERIFY_REQUEST = gql`
  mutation approveSupportMustVerifyRequest($input: supportMustVerifyRequestInput!) {
    approveSupportMustVerifyRequest(input: $input) {
      data
      result
    }
  }
`
const DENY_SUPPORT_MUST_VERIFY_REQUEST = gql`
  mutation denySupportMustVerifyRequest($input: supportMustVerifyRequestInput!) {
    denySupportMustVerifyRequest(input: $input) {
      data
      result
    }
  }
`

const DENY_GROUP_ACTIVATION_REQUEST = gql`
  mutation($id: ID!, $input: GroupActivationInput!) {
    denyGroupActivationRequest(id: $id, input: $input) {
      data
      result
    }
  }
`

export {
  APPROVE_RESTRICTED_SUB_GROUP_REQUEST,
  DENY_RESTRICTED_SUB_GROUP_REQUEST,
  APPROVE_SUPPORT_MUST_VERIFY_REQUEST,
  DENY_SUPPORT_MUST_VERIFY_REQUEST,
  DENY_GROUP_ACTIVATION_REQUEST
}
