import React from 'react'
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Hidden,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import PreApprovedListCard from './PreApprovedListCard'
import { useQuery } from '../../hooks'
import { Progress } from '../../common'
import { GET_PRE_APPROVED_ORGS } from './queries'

const PreApprovedList = ({ getTierLabel, location, TooltipHelper }) => {
  const classes = useStyles()

  const { data: { getPreApprovedOrgs: orgData = [] } = {}, loading: orgLoading } = useQuery(
    GET_PRE_APPROVED_ORGS
  )

  if (orgLoading) {
    return <Progress />
  }

  return (
    <ExpansionPanel
      classes={{ expanded: classes.expansionPanelExpanded }}
      className={classes.expansionPanel}
      elevation={0}
    >
      <ExpansionPanelSummary
        classes={{ root: classes.expansionPanelSummaryRoot }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container direction="column">
          <Grid container item>
            <Grid item>
              <Typography className={classes.title}>New Organizations Payments</Typography>
            </Grid>
            {orgData.length > 0 && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.notificationCount}
                justify="center"
              >
                <Grid item>{orgData.length}</Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Typography>
              New Organizations that are due to pay their participation tiers before becoming
              participants. Please register payment by counter-signing the GPA.
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container item className={classes.headerContainer} xs={12}>
              <Grid item xs={3}>
                <Typography className={classes.header}>Name</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.header}>Tier</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.header}>Account Admin</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.header}>Auth Rep</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.header}>GPA Sign Date</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.header}>Paid</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.header}>Docusign</Typography>
              </Grid>
            </Grid>
          </Hidden>
          {orgData.map((org, i) => (
            <PreApprovedListCard
              getTierLabel={getTierLabel}
              key={i}
              location={location}
              org={org}
              TooltipHelper={TooltipHelper}
            />
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const useStyles = makeStyles(theme => ({
  columnOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  expansionPanel: {
    border: `1px solid ${theme.palette.gray7.main}`,
    marginBottom: 32
  },
  expansionPanelDetails: {
    padding: 0
  },
  expansionPanelExpanded: {
    marginTop: '0 !important'
  },
  expansionPanelSummaryRoot: {
    background: theme.palette.gray13.main
  },
  headerContainer: {
    padding: theme.spacing(2)
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.gray12.main
  },
  notificationCount: {
    background: theme.palette.dashboardNotification.main,
    borderRadius: '50%',
    color: 'white',
    height: 25,
    marginLeft: theme.spacing(2),
    width: 25
  },
  title: {
    fontWeight: 'bold'
  }
}))

export default PreApprovedList
