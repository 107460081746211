import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import equal from 'fast-deep-equal'
import { useSnackbar } from 'notistack'

import { useFormStyles, useMutation } from '../hooks'
import { isFormSubmitDisabled } from '../utils'
import { Progress, RoundedButton } from '../common'

const SummaryProfileForm = ({
  createOrg = true,
  title,
  id,
  dataObj,
  dataFields,
  createInputFields,
  saveMutationData,
  setEditingParent
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const formClasses = useFormStyles()
  const classes = useStyles()

  const formStateInit = {}

  dataFields.fields.forEach(value => (formStateInit[value.name ? value.name : value] = ''))

  if (dataFields.addressFields) {
    dataFields.addressFields.forEach(value => (formStateInit[value] = ''))
  }

  if (dataFields.arrayFields) {
    dataFields.arrayFields.forEach(value => {
      formStateInit[value] = []
    })
  }

  const [formState, inputTypes] = useFormState(formStateInit)

  const [isEditing, setIsEditing] = useState(false)
  const [objState, setObjState] = useState({})
  const [showCancelModal, setShowCancelModal] = useState(false)

  const dataObjString = JSON.stringify(dataObj)

  const { execute: saveChanges, loading: saveLoading } = useMutation(saveMutationData.mutation, {
    onCompleted: () => {
      enqueueSnackbar('Your changes have been saved.', {
        variant: 'success',
        autoHideDuration: 4500
      })

      setIsEditing(false)
    },
    refetchQueries: [saveMutationData.refetchQuery]
  })

  useEffect(() => {
    setEditingParent(isEditing)
  }, [isEditing, setEditingParent])

  useEffect(() => {
    const data = {}

    dataFields.fields.forEach(value => {
      const key = value.name ? value.name : value

      data[key] = dataObj[key] || ''
    })

    if (dataFields.addressFields) {
      dataFields.addressFields.forEach(
        value =>
          (data[value] =
            dataObj.shippingAddress && dataObj.shippingAddress[value]
              ? dataObj.shippingAddress[value]
              : '')
      )
    }

    if (dataFields.arrayFields) {
      dataFields.arrayFields.forEach(value => {
        data[value] = dataObj[value].length > 0 ? dataObj[value] : []
      })
    }

    setObjState(data)
  }, [dataFields.addressFields, dataFields.fields, dataFields.arrayFields, dataObj, dataObjString])

  const inputFields = createInputFields(objState, formState)

  const disableSave =
    isFormSubmitDisabled(
      {
        ...inputFields.name.inputs,
        ...(inputFields.location ? inputFields.location.inputs : null),
        ...inputFields
      },
      formState
    ) || equal(objState, formState.values)

  return (
    <>
      <Dialog open={showCancelModal}>
        <DialogTitle className={classes.cancelModalHeader}>Alert</DialogTitle>
        <DialogContent className={classes.cancelModalContent}>
          <div className={classes.cancelModalContentText}>
            <Typography>
              Are you sure you wish to cancel? This will undo any changes you made.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            variant="outlined"
            onClick={() => {
              setShowCancelModal(false)
              setIsEditing(false)

              if (Object.keys(formState.validity).some(key => !formState.validity[key])) {
                Object.entries(objState).forEach(([name, value]) => formState.setField(name, value))
              }
            }}
          >
            Yes
          </RoundedButton>
          <RoundedButton fullWidth={false} onClick={() => setShowCancelModal(false)}>
            No
          </RoundedButton>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography paragraph className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {createOrg &&
          (isEditing ? (
            <>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    !equal(objState, formState.values)
                      ? setShowCancelModal(true)
                      : setIsEditing(false)
                  }
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disableElevation
                  color="primary"
                  disabled={disableSave}
                  variant="contained"
                  onClick={() =>
                    saveChanges({
                      id,
                      values: {
                        shippingAddress: dataFields.addressFields.reduce(
                          (obj, value) => ({
                            ...obj,
                            [value]: formState.values[value]
                          }),
                          {}
                        ),
                        ...dataFields.fields.reduce(
                          (obj, value) =>
                            value.ignoreOnSubmit
                              ? obj
                              : {
                                  ...obj,
                                  [value]: formState.values[value]
                                },
                          {}
                        ),
                        ...(dataFields.objectFields &&
                          dataFields.objectFields.reduce(
                            (obj, value) => ({
                              ...obj,
                              [value.name]: value.fieldsToClone.reduce(
                                (nestedObj, nestedValue) => ({
                                  ...nestedObj,
                                  [nestedValue]: formState.values[value.name][nestedValue]
                                }),
                                {}
                              )
                            }),
                            {}
                          ))
                      }
                    })
                  }
                >
                  {saveLoading ? (
                    <div className={formClasses.progress}>
                      <Progress size={20} />
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  if (!equal(objState, formState.values)) {
                    Object.entries(objState).forEach(([name, value]) =>
                      formState.setField(name, value)
                    )
                  }

                  setIsEditing(true)
                }}
              >
                Edit
              </Button>
            </Grid>
          ))}
      </Grid>
      {Object.entries(inputFields).map(([name, value]) => (
        <Grid container className={classes.container} key={name} spacing={2}>
          {createOrg && (
            <Grid item xs={4}>
              <Typography>{value.label}</Typography>
            </Grid>
          )}
          {createInput(value, isEditing, { objState, formState, inputTypes, classes })}
        </Grid>
      ))}
    </>
  )
}

const createInput = (value, isEditing, restProps) => {
  if (isEditing) {
    if (value.inputs) {
      return (
        <Grid container item spacing={2} xs>
          {Object.entries(value.inputs).map(([, nestedValue]) =>
            createInput(nestedValue, isEditing, restProps)
          )}
        </Grid>
      )
    }

    if (value.InputComponent) {
      const { gridWidth = { xs: 8 }, InputComponent, inputType, name, ...restValue } = value
      const { formState, inputTypes, classes } = restProps

      return (
        <Grid item key={name} {...gridWidth}>
          <InputComponent
            {...restValue}
            hideLabel
            error={typeof formState.validity[name] !== 'undefined' && !formState.validity[name]}
            errorHelperText={formState.errors[name]}
            InputProps={{
              classes: {
                root: classes.inputRoot
              }
            }}
            inputType={inputTypes[inputType]}
            margin="none"
            name={name}
            size="small"
            value={formState.values ? formState.values[name] : ''}
          />
        </Grid>
      )
    }
  }

  const { className, display, gridWidth = { xs: 8 }, name } = value
  const { objState, classes } = restProps

  return (
    <Grid item {...gridWidth}>
      <Typography className={clsx(className, classes.item)}>
        {display ? display : objState[name]}
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  cancelModalActions: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  cancelModalContent: {
    display: 'flex'
  },
  cancelModalContentText: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  cancelModalHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  container: {
    marginBottom: theme.spacing(1)
  },
  inputRoot: {
    backgroundColor: 'white'
  },
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  title: {
    // height: theme.spacing(5),
    fontWeight: 'bold'
  }
}))

export default SummaryProfileForm
