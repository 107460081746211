import React, { useState } from 'react'
import { Grid, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'

import { isFormSubmitDisabled, inputRegexes } from '../../utils'
import { InputTextFieldNew, RoundedButton } from '../../common'
import OrgMemberDropdown from './OrgMemberDropdown'

export default function LegalDesigneeInput({
  onSave,
  onCancel,
  showCancel,
  value = { userId: '', firstname: '', lastname: '', email: '', jobTitle: '' },
  inputId,
  users,
  selectedUserId,
  ...rest
}) {
  if (value.user) {
    value.userId = value.user.id
  }

  const signer = {
    userId: value.userId,
    firstname: value.firstname,
    email: value.email,
    lastname: value.lastname,
    jobTitle: value.jobTitle
  }

  const [legalDesigneeRadio, setLegalDesigneeRadio] = useState(signer.email ? 'manual' : 'dropdown')

  const classes = useStyles()
  const [formState, inputTypes] = useFormState({
    email: signer.email,
    firstname: signer.firstname,
    lastname: signer.lastname,
    jobTitle: signer.jobTitle,
    userId: signer.userId
  })

  const inputs = inputFields()

  let disableSubmit = isFormSubmitDisabled(inputs, formState)
  if (legalDesigneeRadio === 'dropdown' && formState.values.userId) {
    disableSubmit = false
  }

  function handleChange(event) {
    const type = event.target.value
    setLegalDesigneeRadio(type)
  }

  function handleDropdownSelect(value) {
    formState.setField('userId', value)
  }

  function onSubmit() {
    let obj
    if (legalDesigneeRadio === 'manual') {
      obj = {
        firstname: formState.values.firstname,
        lastname: formState.values.lastname,
        jobTitle: formState.values.jobTitle,
        email: formState.values.email
      }
    } else {
      obj = {
        userId: formState.values.userId
      }
    }
    onSave(obj)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8} lg={9} xl={8}>
        <RadioGroup
          aria-label="Select Authorized Signer"
          name="authorizedSignerRadio"
          className={classes.group}
          value={legalDesigneeRadio}
          onChange={handleChange}
        >
          <FormControlLabel value="dropdown" control={<Radio />} label="Search for User" />
          {legalDesigneeRadio === 'dropdown' && (
            <div className={classes.radioContent}>
              <OrgMemberDropdown
                inputId={inputId}
                users={users}
                selectedUserId={formState.values.userId}
                onChange={handleDropdownSelect}
                {...rest}
              />
            </div>
          )}
          <FormControlLabel value="manual" control={<Radio />} label="Enter email and name" />
          {legalDesigneeRadio === 'manual' && (
            <Grid container spacing={2} className={classes.radioContent}>
              {Object.entries(inputs).map(
                ([name, { gridWidth = { xs: 12 }, InputComponent, inputType, ...args }]) => (
                  <Grid item key={name} {...gridWidth}>
                    <InputComponent
                      {...args}
                      error={
                        typeof formState.validity[name] !== 'undefined' && !formState.validity[name]
                      }
                      errorHelperText={formState.errors[name]}
                      inputType={inputTypes[inputType]}
                      margin="none"
                      value={formState.values[name]}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}
        </RadioGroup>
        <Grid item xs={6} className={classes.button}>
          <RoundedButton fullWidth={false} disabled={disableSubmit} onClick={onSubmit}>
            Save
          </RoundedButton>
        </Grid>
        {showCancel && ( // can't cancel if it is the first signer being added
          <Grid item xs={6} className={classes.button}>
            <RoundedButton fullWidth={false} variant="outlined" onClick={onCancel}>
              Cancel
            </RoundedButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const inputFields = () => ({
  firstname: {
    InputComponent: InputTextFieldNew,
    gridWidth: { xs: 12, sm: 6 },
    name: 'firstname',
    label: 'First Name',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  lastname: {
    InputComponent: InputTextFieldNew,
    gridWidth: { xs: 12, sm: 6 },
    name: 'lastname',
    label: 'Last Name',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  jobTitle: {
    InputComponent: InputTextFieldNew,
    name: 'jobTitle',
    label: 'Work Title',
    inputType: 'text',
    validator: {
      required: true
    }
  },
  email: {
    InputComponent: InputTextFieldNew,
    name: 'email',
    label: 'Email',
    inputType: 'email',
    validator: {
      required: true,
      regex: inputRegexes.email,
      regexMessage: 'Must be a valid email'
    }
  }
})

const useStyles = makeStyles(theme => ({
  radioContent: {
    paddingLeft: 33
  },
  button: {
    marginTop: 20,
    marginRight: 10,
    display: 'inline-block'
  }
}))
