import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'

import RoundedButton from '../../common/RoundedButton'
import { useMutation } from '../../hooks'
import { SEND_RESET_PASSWORD_EMAIL } from '../../auth/ForgotPassword'

const PasswordResetAdmin = ({ email, isReadOnly = false }) => {
  const { data: { sendResetPasswordEmail: data } = {}, execute: handleSubmit } = useMutation(
    SEND_RESET_PASSWORD_EMAIL
  )
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (data && data.result) {
      enqueueSnackbar('Email Sent', {
        variant: 'success',
        autoHideDuration: 4500
      })
    }
  }, [data, enqueueSnackbar])

  return (
    <RoundedButton
      onClick={e => {
        handleSubmit({ values: { email } })
      }}
      color="primary"
      fullWidth={false}
      disabled={isReadOnly}
    >
      Send Password Reset Email to User
    </RoundedButton>
  )
}
export default PasswordResetAdmin
