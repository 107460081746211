import React, { useState, useEffect } from 'react'
import { Divider, makeStyles, Typography } from '@material-ui/core'

import { useSession } from '../auth/queries'
import { inputRegexes } from '../utils'
import {
  CheckboxDropdown,
  CountryDropdown,
  InputTextFieldNew,
  PlacesAutocomplete,
  PhoneNumberInput,
  Progress,
  RoundedButton
} from '../common'
import { useFormStyles } from '../hooks'
import { GET_USER_DATA } from '../Home/queries'
import { GET_ORGANIZATION_DATA } from '../Navigation/queries'
import { EDIT_ORGANIZATION_PROFILE, EDIT_USER_PROFILE } from './mutations'
import SummaryProfileForm from './SummaryProfileForm'
// import SummaryAffiliateInput from './SummaryAffiliateInput'

const countryList = require('country-list')

const Summary = ({ acceptedInvite, org, orgLoading, updateUser, user, userLoading }) => {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { userId } = useSession()

  const [editingUser, setEditingUser] = useState(false)
  const [editingOrg, setEditingOrg] = useState(false)

  const joinOrg = org.approvalStatus === 'approved'

  // const { data: { organization: affiliateOrg = {} } = {} } = useQuery(GET_ORGANIZATION_DATA, {
  //   variables: { id: organization.affiliateOf },
  //   skip: !organization.affiliateOf
  // })

  // useEffect(() => {
  //   if (!JOIN_ORG && affiliateOrg.name && organization.affiliateOfLabel !== affiliateOrg.name) {
  //     organization.affiliateOfLabel = affiliateOrg.name
  //   }
  // }, [affiliateOrg, organization.affiliateOfLabel])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Typography className={formClasses.heading} variant="h3">
        Summary
      </Typography>
      <Typography paragraph>Please review and verify the information.</Typography>
      <div className={classes.contentContainer}>
        {userLoading ? (
          <div className={formClasses.progress}>
            <Progress />
          </div>
        ) : (
          <SummaryProfileForm
            title="Your Profile"
            id={userId}
            dataObj={user}
            dataFields={{
              fields: [
                'firstname',
                'lastname',
                { name: 'email', ignoreOnSubmit: true },
                'jobTitle'
              ],
              addressFields: ['country', 'city', 'state', 'postalCode']
            }}
            createInputFields={(...props) => userInputFields(classes, ...props)}
            saveMutationData={{
              mutation: EDIT_USER_PROFILE,
              refetchQuery: {
                query: GET_USER_DATA,
                variables: { id: userId }
              }
            }}
            setEditingParent={setEditingUser}
          />
        )}
        <Divider className={classes.divider} />
        {orgLoading ? (
          <div className={formClasses.progress}>
            <Progress />
          </div>
        ) : (
          <SummaryProfileForm
            createOrg={!joinOrg}
            title={
              joinOrg
                ? acceptedInvite
                  ? 'Joining the TIP participation account of'
                  : 'Requesting to join TIP participation of'
                : 'Organization Profile'
            }
            id={org.id}
            dataObj={org}
            dataFields={
              joinOrg
                ? { fields: [{ name: 'name', ignoreOnSubmit: true }] }
                : {
                    fields: [
                      'name',
                      // 'affiliateOf',
                      // { name: 'affiliateOfLabel', ignoreOnSubmit: true },
                      'type',
                      'serviceOfferings',
                      'operatingRegions',
                      'phoneNumber',
                      'website'
                    ],
                    addressFields: [
                      'country',
                      'address1',
                      'address2',
                      'city',
                      'state',
                      'postalCode'
                    ],
                    arrayFields: ['type', 'serviceOfferings', 'operatingRegions']
                  }
            }
            createInputFields={(...props) =>
              joinOrg ? orgJoinInputFields(classes) : orgCreateInputFields(classes, ...props)
            }
            saveMutationData={{
              mutation: EDIT_ORGANIZATION_PROFILE,
              refetchQuery: {
                query: GET_ORGANIZATION_DATA,
                variables: { id: org.id }
              }
            }}
            setEditingParent={setEditingOrg}
          />
        )}
      </div>
      <RoundedButton
        fullWidth
        disabled={editingUser || editingOrg}
        size="medium"
        onClick={() => {
          if (joinOrg) {
            if (acceptedInvite) {
              // Invited user
              updateUser(12)
            } else {
              // Request to join org
              updateUser(11)
            }
          } else {
            // Affiliate request
            //updateUser(6)

            // New org GPA
            updateUser(6)
          }
        }}
      >
        {joinOrg ? 'Confirm Profile and Continue' : 'Next: Billing Information'}
      </RoundedButton>
    </>
  )
}

const userInputFields = (classes, objState, formState) => ({
  name: {
    display: `${objState.firstname} ${objState.lastname}`,
    label: 'Name',
    inputs: {
      firstname: {
        gridWidth: { xs: 6 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'First Name',
        name: 'firstname',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      },
      lastname: {
        gridWidth: { xs: 6 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'Last Name',
        name: 'lastname',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      }
    }
  },
  email: {
    className: classes.grayLabel,
    label: 'Email',
    name: 'email'
  },
  jobTitle: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Job Title',
    name: 'jobTitle',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 50
    }
  },
  location: {
    display: (
      <>
        {objState.country && countryList.getName(objState.country)}
        <br />
        {objState.city}, {objState.state} {objState.postalCode}
      </>
    ),
    label: 'Location',
    inputs: {
      country: {
        includePhone: true,
        gridWidth: { xs: 12 },
        InputComponent: CountryDropdown,
        inputType: 'text',
        label: 'Country of Residence',
        menuPlacement: 'bottom',
        name: 'country',
        setField: formState.setField,
        setFieldError: formState.setFieldError
      },
      city: {
        gridWidth: { xs: 12 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'City',
        name: 'city',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      },
      state: {
        gridWidth: { xs: 5 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'State / Province',
        name: 'state',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      },
      postalCode: {
        gridWidth: { xs: 7 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'ZIP / Postal Code',
        name: 'postalCode',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      }
    }
  }
})

const orgJoinInputFields = classes => ({
  name: {
    className: classes.grayLabel,
    label: 'Name',
    name: 'name'
  }
})

const orgCreateInputFields = (classes, objState, formState) => ({
  name: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Name',
    name: 'name',
    validator: {
      required: true,
      minLength: 2,
      maxLength: 50
    }
  },
  /* 
    affiliateOf dropdown fully functional in this commit
  */
  // affiliateOf: {
  //   affiliate: true,
  //   display: objState.affiliateOfLabel ? objState.affiliateOfLabel : 'None',
  //   InputComponent: SummaryAffiliateInput,
  //   inputType: 'text',
  //   label: 'Affiliate of',
  //   name: 'affiliateOf',
  //   setField: formState.setField,
  //   setFieldError: formState.setFieldError
  // },
  type: {
    className: classes.checkboxDropdown,
    display: objState.type && objState.type.length > 0 ? objState.type.sort().join(', ') : 'None',
    helperText: 'select all that apply',
    InputComponent: CheckboxDropdown,
    inputType: 'checkbox',
    label: 'Type',
    name: 'type',
    options: [
      'Academic Institution',
      'Alliance or Association',
      'Consultancy',
      'Enterprise',
      'Semiconductor Vendor',
      'Government Agency',
      'Hardware Vendor',
      'Industry Analyst',
      'Infrastructure Provider or Owner',
      'Non-Government Agency',
      'Press',
      'Reseller or Technology Provider',
      'Research and Development',
      'Software or App Development',
      'System Integrator',
      'Telecom or Network Operator',
      'Venture Capital / Investment',
      'Webscale',
      'Other'
    ],
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  serviceOfferings: {
    className: classes.checkboxDropdown,
    display:
      objState.serviceOfferings && objState.serviceOfferings.length > 0
        ? objState.serviceOfferings.sort().join(', ')
        : 'None',
    helperText: 'select all that apply',
    InputComponent: CheckboxDropdown,
    inputType: 'checkbox',
    label: 'Service offerings',
    name: 'serviceOfferings',
    options: [
      'Automation',
      'Chip Manufacturing',
      'Computer Component Manufacturing',
      'Consulting Services',
      'Fiber Provider',
      'Hardware Design',
      'Installation Services',
      'Membership Services (e.g. Events, Convening, Reports)',
      'Mobile Device Manufacturing',
      'Network Management & Operations',
      'Policy Development / Advocacy',
      'Professional Services / Project Management',
      'Routers',
      'SIP Trunking',
      'Software Development',
      'Switches',
      'Test & Compliance',
      'VoIP'
    ],
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  operatingRegions: {
    className: classes.checkboxDropdown,
    display:
      objState.operatingRegions && objState.operatingRegions.length > 0
        ? objState.operatingRegions.sort().join(', ')
        : 'None',
    helperText: 'select all that apply',
    InputComponent: CheckboxDropdown,
    inputType: 'checkbox',
    label: 'Operating regions',
    name: 'operatingRegions',
    options: [
      'Africa',
      'APAC - Australia and New Zealand',
      'APAC - North Asia',
      'APAC - South and Southeast Asia',
      'Europe',
      'LATAM',
      'Middle East',
      'North America'
    ],
    validator: {
      required: true
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  location: {
    display: (
      <>
        {objState.country && countryList.getName(objState.country)}
        <br />
        {objState.address1}
        <br />
        {objState.address2 && (
          <>
            {objState.address2}
            <br />
          </>
        )}
        {objState.city}, {objState.state} {objState.postalCode}
      </>
    ),
    label: 'Location',
    inputs: {
      country: {
        gridWidth: { xs: 12 },
        includePhone: true,
        InputComponent: CountryDropdown,
        inputType: 'text',
        label: 'Country of Residence',
        menuPlacement: 'bottom',
        name: 'country',
        setField: formState.setField,
        setFieldError: formState.setFieldError
      },
      address1: {
        currentCity: formState.values.city,
        currentCountry: formState.values.country,
        currentPostalCode: formState.values.postalCode,
        currentState: formState.values.state,
        gridWidth: { xs: 12 },
        includePhone: true,
        InputComponent: PlacesAutocomplete,
        inputType: 'text',
        label: 'Address',
        name: 'address1',
        setField: formState.setField,
        setFieldError: formState.setFieldError
      },
      address2: {
        autoComplete: 'address-level2',
        gridWidth: { xs: 12 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'Apartment / Suite # (Optional)',
        name: 'address2'
      },
      city: {
        gridWidth: { xs: 12 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'City',
        name: 'city',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      },
      state: {
        gridWidth: { xs: 5 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'State / Province',
        name: 'state',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      },
      postalCode: {
        autoComplete: 'postal-code',
        gridWidth: { xs: 7 },
        InputComponent: InputTextFieldNew,
        inputType: 'text',
        label: 'ZIP / Postal Code',
        name: 'postalCode',
        validator: {
          required: true,
          minLength: 2,
          maxLength: 50
        }
      }
    }
  },
  phoneNumber: {
    currentCountry: formState.values.country,
    InputComponent: PhoneNumberInput,
    inputType: 'text',
    label: 'Phone',
    name: 'phoneNumber',
    validator: {
      required: true,
      regex: inputRegexes.phone,
      regexMessage: 'Must be a valid phone number'
    },
    setField: formState.setField,
    setFieldError: formState.setFieldError
  },
  website: {
    InputComponent: InputTextFieldNew,
    inputType: 'text',
    label: 'Website',
    name: 'website',
    validator: {
      required: true,
      maxLength: 50,
      regex: inputRegexes.domain,
      regexMessage: 'Must be a valid domain'
    }
  }
})

const useStyles = makeStyles(theme => ({
  checkboxDropdown: {
    whiteSpace: 'normal'
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    },
    [theme.breakpoints.down('sm')]: {
      borderColor: 'transparent',
      marginTop: 0
    }
  },
  contentContainer: {
    backgroundColor: '#F6F6F6',
    border: '1px solid #D2D2D2',
    borderRadius: 3,
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3),
    textAlign: 'start',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  divider: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  grayLabel: {
    color: theme.palette.gray9.main
  },
  header: {
    fontWeight: 'bold'
  },
  stepCountText: {
    color: theme.palette.gray9.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  }
}))

export default Summary
