import React, { useState } from 'react'
import {
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { useSnackbar } from 'notistack'

import { useMutation } from '../../hooks'
import { CHANGE_ORGANIZATION_STATUS } from '../ManageOrganizations/mutations'
import { GET_ORGS_FOR_PRE_APPROVE, GET_PRE_APPROVED_ORGS } from './queries'
import { LinkField, Progress } from '../../common'
import { Countries } from '../../common/CountryDropdown'

const PreApproveOrganizations = ({
  getTierLabel = () => null,
  isReadOnlySystemAdmin,
  org: {
    id: orgId,
    name: orgName = '',
    website: orgWebsite = '',
    shippingAddress: { country: orgCountry = 'US' } = {},
    membership: { membershipLevelId: levelId = '' } = {},
    organizationRoles: {
      organizationAdmin: orgAdmin = {},
      authorizedSigners: orgAuthReps = []
    } = {}
  } = {},
  TooltipHelper = () => null
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const {
    error: changeOrgStatusError,
    execute: executeChangeOrgStatus,
    loading: changeOrgStatusLoading
  } = useMutation(CHANGE_ORGANIZATION_STATUS, {
    awaitRefetchQueries: true,
    onCompleted: ({ editOrganizationStatus }) => {
      if (editOrganizationStatus.result) {
        if (editOrganizationStatus.status === 'pre-approved') {
          enqueueSnackbar(`${orgName} has been successfully pre-approved.`, {
            variant: 'success',
            autoHideDuration: 4500
          })
        } else if (editOrganizationStatus.status === 'declined') {
          enqueueSnackbar(`${orgName} has been successfully declined.`, {
            variant: 'success',
            autoHideDuration: 4500
          })
        }
      }
    },
    onError: e => console.error(e),
    refetchQueries: [
      {
        query: GET_ORGS_FOR_PRE_APPROVE
      },
      {
        query: GET_PRE_APPROVED_ORGS
      }
    ]
  })

  const tierLabel = getTierLabel({ membershipLevelId: levelId })

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.headerContainer}
        xs={12}
        spacing={1}
      >
        {changeOrgStatusError ? (
          <Grid item xs={12}>
            <Typography className={classes.errorSection}>
              Encountered an error changing {orgName}'s status
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={3}>
              {orgName && <TooltipHelper content={orgName} />}
            </Grid>
            <Grid item xs={12} sm={1}>
              <TooltipHelper content={tierLabel.short} tooltipTitle={tierLabel.long} />
            </Grid>
            <Grid item xs={12} sm={2}>
              {orgAdmin && <TooltipHelper content={orgAdmin.email} />}
            </Grid>
            <Grid item xs={12} sm={2}>
              {orgAuthReps.length > 0 && (
                <TooltipHelper content={orgAuthReps[0].email || orgAuthReps[0].user.email} />
              )}
            </Grid>
            <Grid item xs={5} sm={2}>
              {orgWebsite && (
                <TooltipHelper
                  componentProps={{
                    forceExternal: true,
                    target: '_blank',
                    to: orgWebsite
                  }}
                  content={orgWebsite}
                  TextComponent={LinkField}
                />
              )}
            </Grid>
            <Grid item xs={isReadOnlySystemAdmin ? 7 : 5} sm={isReadOnlySystemAdmin ? 2 : 1}>
              {orgCountry && (
                <TooltipHelper content={Countries.find(c => c.value === orgCountry).label} />
              )}
            </Grid>
            {!isReadOnlySystemAdmin && (
              <Grid item xs={2} sm={1}>
                {changeOrgStatusLoading ? (
                  <Progress />
                ) : (
                  <>
                    <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      getContentAnchorEl={null}
                      open={Boolean(anchorEl)}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem
                        onClick={() => {
                          executeChangeOrgStatus({
                            id: orgId,
                            values: {
                              status: 'pre-approved',
                              beforeStatus: 'in progress'
                            }
                          })
                          setAnchorEl(null)
                        }}
                      >
                        <ThumbUpIcon className={classes.menuIcon} />
                        <Typography className={classes.menuText}>Pre-approve</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          executeChangeOrgStatus({
                            id: orgId,
                            values: {
                              status: 'declined',
                              beforeStatus: 'in progress'
                            }
                          })
                          setAnchorEl(null)
                        }}
                      >
                        <ThumbDownIcon className={classes.menuIcon} />
                        <Typography className={classes.menuText}>Decline</Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  errorSection: {
    color: theme.palette.error.main
  },
  headerContainer: {
    padding: theme.spacing(2)
  },
  menuIcon: {
    color: theme.palette.gray8.main,
    marginRight: 10
  },
  menuText: {
    fontSize: '.9rem',
    color: theme.palette.gray8.main
  }
}))

export default PreApproveOrganizations
