import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'

import { ErrorSection, Progress, RoundedButton } from '../../../../common'
import { useMutation } from '../../../../hooks'
import { JOIN_SLACK_CHANNELS, LEAVE_SLACK_CHANNELS } from '../../mutations'

export default function Slack({ user }) {
  const { id: userId, slackUserId } = user
  const classes = useStyles()
  const [slackUserLinked, setSlackUserLinked] = useState(!!slackUserId)
  const [error, setError] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const { execute: joinSlackChannelsRequest, loading: joinSlackChannelsLoading } = useMutation(
    JOIN_SLACK_CHANNELS,
    {
      onCompleted: data => {
        if (data.joinSlackChannels && data.joinSlackChannels.result) {
          enqueueSnackbar(data.joinSlackChannels.data, {
            variant: 'success',
            autoHideDuration: 4500
          })
          if (data.joinSlackChannels.data === 'Joined Slack conversations') {
            setSlackUserLinked(true)
          }
        } else {
          setError('Failed to send Slack invite')
        }
      }
    }
  )

  const { execute: leaveSlackChannelsRequest, loading: leaveSlackChannelsLoading } = useMutation(
    LEAVE_SLACK_CHANNELS,
    {
      onCompleted: data => {
        if (data) {
          setSlackUserLinked(false)
          enqueueSnackbar('You have left Slack', {
            variant: 'success',
            autoHideDuration: 4500
          })
        } else {
          setError('Failed to leave Slack')
        }
      }
    }
  )

  function joinSlackChannels() {
    setError(null)
    joinSlackChannelsRequest({
      id: userId
    })
  }

  function leaveSlackChannels() {
    setError(null)
    leaveSlackChannelsRequest({
      id: userId
    })
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} className={clsx(classes.atlassian)}>
        <Typography className={classes.title}>Slack Connection</Typography>
        {!slackUserLinked && (
          <Typography paragraph>
            Not connected. Click Join Slack and we'll send a message to your work email to finish
            enrollment.
          </Typography>
        )}
        {slackUserLinked && (
          <Typography paragraph>
            You have joined Slack for your project groups. Log into Slack using your work email.
          </Typography>
        )}
      </Grid>

      {!slackUserLinked && (
        <Grid item xs={12} sm={6}>
          <div className={classes.align}>
            <RoundedButton
              className={classes.button}
              fullWidth={false}
              color="primary"
              variant="outlined"
              onClick={joinSlackChannels}
              disabled={joinSlackChannelsLoading}
            >
              Join Slack
            </RoundedButton>
            {joinSlackChannelsLoading && <Progress size={20} delay={0} />}
          </div>
        </Grid>
      )}
      {slackUserLinked && (
        <Grid item xs={12} sm={6}>
          <div className={classes.align}>
            <RoundedButton
              className={classes.button}
              fullWidth={false}
              color="danger"
              variant="outlined"
              onClick={leaveSlackChannels}
              disabled={leaveSlackChannelsLoading}
            >
              Leave Slack
            </RoundedButton>
            {leaveSlackChannelsLoading && <Progress size={20} delay={0} />}
          </div>
        </Grid>
      )}

      {error && (
        <Grid item xs={12} className={classes.errorSection}>
          <ErrorSection includeSupport>{error}</ErrorSection>
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  message: { marginRight: 5 },
  linkSection: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },

  align: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  button: {
    marginRight: 5,
    fontWeight: 600
  },
  errorSection: {
    marginTop: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  }
}))
