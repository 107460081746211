import React, { useEffect, useState } from 'react'
import { AppBar, Grid, makeStyles, Tab, Tabs, useTheme } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'

import { useSession } from '../../auth/queries'
import { history } from '../../store'
import RouteGuard from '../../RouteGuard'
import { ConnectedAccounts, Login, UserProfile } from './Tabs'
import Header from './Header'
import PreventTransitionPrompt from '../../RouteGuard/PreventTransitionPrompt'
import { ImpersonateUserField } from '../../Organization/Impersonation'
import { TabLoop } from '../../common'

function getDefinedObject(obj) {
  return Object.entries(obj).reduce((acc, [name, val]) => {
    if (val && name !== '__typename') {
      if (typeof val === 'object' && val instanceof Object) {
        acc[name] = getDefinedObject(val)
      } else {
        acc[name] = val
      }
    }
    return acc
  }, {})
}

const UserInfo = ({ user, onUpdate, returnUrl }) => {
  const { userId, isImpersonating, isSystemAdmin } = useSession()
  const theme = useTheme()

  const isSelf = !isImpersonating && userId === user.id
  const canEditUser = isSelf || isSystemAdmin

  const [activeTabId, setActiveTabId] = useState(0)
  const [desiredActiveTabId, setDesiredActiveTabId] = useState(0)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [path, setPath] = useState(null)
  const userValues = getDefinedObject(user)

  const classes = tabStyles()

  useEffect(() => {
    if (path) {
      // used to redirect the user after confirm dialog
      history.push(path)
    }
  }, [path])

  const sections = [
    {
      label: 'My Profile',
      component: (
        <TabLoop>
          <UserProfile
            activeTab={activeTabId === 0}
            isReadOnly={!canEditUser}
            onUpdate={onUpdate}
            setHasUnsavedChanges={setHasUnsavedChanges}
            user={userValues}
          />
        </TabLoop>
      )
    },
    {
      label: 'Connected Accounts',
      component: (
        <TabLoop>
          <ConnectedAccounts
            activeTab={activeTabId === 1}
            user={user}
            isSelf={isSelf}
            email={user.email}
            isReadOnly={!canEditUser}
          />
        </TabLoop>
      )
    },
    {
      label: 'Login',
      component: (
        <TabLoop>
          <Login
            activeTab={activeTabId === 2}
            email={user.email}
            isReadOnly={!canEditUser}
            isSelf={isSelf}
            userId={user.id}
          />
        </TabLoop>
      )
    }
  ]

  const title = 'Unsaved Changes'
  const message = 'Are you sure you want to leave?'

  return (
    <>
      <PreventTransitionPrompt
        title={title}
        message={message}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onConfirm={() => {
          setActiveTabId(desiredActiveTabId)
          setHasUnsavedChanges(false)
          setModalVisible(false)
        }}
      />
      <RouteGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        title={title}
        message={message}
        when={hasUnsavedChanges}
        // Navigate function
        navigate={path => {
          setPath(path)
        }}
      />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={8}>
            <Header
              parentId={user.id}
              type="profile"
              logo={user.logo}
              firstname={user.firstname}
              lastname={user.lastname}
              orgName={user.organizationName}
              orgId={user.organizationId}
              isSystemAdmin={isSystemAdmin}
              percentageComplete={user.percentageComplete}
              jobTitle={user.jobTitle}
              onUpdate={onUpdate}
              email={user.email}
              isSelf={isSelf}
              isReadOnly={!canEditUser}
            />
          </Grid>

          {!isSelf && returnUrl && (
            <Grid item xs={4} className={classes.impersonateContainer}>
              <ImpersonateUserField returnUrl={returnUrl} userId={user.id} />
            </Grid>
          )}
        </Grid>

        <div className={classes.tabRoot}>
          <AppBar position="relative" color="inherit">
            <Tabs
              value={activeTabId}
              onChange={(event, newValue) => {
                if (hasUnsavedChanges) {
                  setModalVisible(true)
                  setDesiredActiveTabId(newValue)
                } else {
                  setActiveTabId(newValue)
                }
              }}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabRoot}
            >
              {sections.map(section => {
                return (
                  <Tab
                    classes={{ root: classes.tabTitle }}
                    key={section.label}
                    label={section.label}
                  />
                )
              })}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeTabId}
            onChangeIndex={index => {
              if (hasUnsavedChanges) {
                setModalVisible(true)
                setDesiredActiveTabId(index)
              } else {
                setActiveTabId(index)
              }
            }}
          >
            {sections.map(section => (
              <div className={classes.tabContainer} key={section.label}>
                {section.component}
              </div>
            ))}
          </SwipeableViews>
        </div>
      </div>
    </>
  )
}

export const tabStyles = makeStyles(theme => ({
  buttonContainer: {
    textAlign: 'center'
  },
  flexColumn: {
    flexDirection: 'column'
  },
  impersonateContainer: {
    textAlign: 'right'
  },
  readOnly: {
    cursor: 'not-allowed !important',
    '& *': {
      pointerEvents: 'none'
    }
  },
  removeOrgButton: {
    marginTop: 10
  },
  root: {
    flexGrow: 1
  },
  roundedButton: {
    marginTop: 20,
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  tabContainer: { padding: 8 * 3 },
  tabRoot: {
    backgroundColor: 'white',
    borderBottom: '1px solid #e8e8e8',
    boxShadow: 'none',
    maxWidth: 690
  },
  tabTitle: {
    textTransform: 'none',
    fontSize: 16,
    minWidth: 'unset',
    padding: '0px 20px',
    [theme.breakpoints.down('xs')]: {
      width: '25%',
      fontSize: 10,
      padding: 0
    }
  }
}))

export default UserInfo
