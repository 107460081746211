import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const GET_BLOCKED_DOMAINS = gql`
  query {
    allBlockedDomains
  }
`

const IS_BLOCKED_DOMAIN = gql`
  query isBlockedDomain($domain: String!) {
    isBlockedDomain(domain: $domain)
  }
`

function useGetAllBlockedDomains() {
  const { loading, error, data } = useQuery(GET_BLOCKED_DOMAINS)
  return {
    blockedDomainsLoading: loading,
    blockedDomainsError: error,
    blockedDomains: data
  }
}

function useIsBlockedDomain(domain) {
  const { loading, error, data } = useQuery(IS_BLOCKED_DOMAIN, {
    variables: { domain }
  })
  return { blockedDomainLoading: loading, blockedDomainError: error, blockedDomainData: data }
}

export { useGetAllBlockedDomains, useIsBlockedDomain }
